import React from 'react';
import styled from 'styled-components';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import * as routes from 'routes/index';
import EditorMainView from './EditorMainView';
import EditorMenuWithState from './EditorMenuWithState';
import EditorSidebarWithState from './EditorSidebarWithState';
import {colorGrey400} from '../../../styles/theme';

const EditorPage = ({activeTab, messageId, followUpStatus, isLoading}) => (
    <div className="l-editor">
        <LoadingWrapper loading={isLoading}>
            <EditorViewContainer>
                <LeftContainer>
                    <EditorMenuContainer>
                        <EditorMenuWithState
                            activeTab={activeTab}
                            messageId={messageId}
                            isLoading={isLoading}
                        />
                    </EditorMenuContainer>
                    <EditorContainer>
                        <EditorMainView
                            activeTab={activeTab}
                            messageId={messageId}
                            followUpStatus={followUpStatus}
                        />
                    </EditorContainer>
                </LeftContainer>
                <RightContainer>
                    <EditorSidebarWithState
                        messageId={messageId}
                        canEditFromCurrentTab={
                            activeTab === routes.editorForMessageType() ||
                            activeTab === routes.editorForMessageId()
                        }
                    />
                </RightContainer>
            </EditorViewContainer>
        </LoadingWrapper>
        <style jsx>
            {`
                @import '../../../lib/sass/kit';

                .l-editor {
                    height: 100%;

                    &__sidebar {
                        position: fixed;
                        right: 0;
                        top: $editor-header-height;
                        z-index: $z-header;
                    }

                    &__content {
                        padding-top: $editor-header-height;
                        height: 100%;
                    }

                    &__component {
                        padding-right: $editor-sidebar-width;
                    }

                    :global(.title) {
                        @include editor-title;
                        resize: none;
                        background: none;
                        color: $text-color-dark;

                        &::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: ${colorGrey400};
                            opacity: 1; /* Firefox */
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: ${colorGrey400};
                        }

                        &::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: ${colorGrey400};
                        }
                    }

                    &__switch {
                        display: flex;
                        align-items: center;
                        top: $editor-header-height + 3rem;
                        position: absolute;
                    }
                }
            `}
        </style>
    </div>
);

const EditorViewContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const LeftContainer = styled.div`
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const RightContainer = styled.div`
    width: 36rem;
    height: 100%;
`;

const EditorMenuContainer = styled.div`
    width: 70%;
    align-self: center;
    @media (max-width: 1700px) {
        width: 85%;
    }
`;

const EditorContainer = styled.div(
    ({theme}) => `
    width: 70%;
    align-self: center;
    padding-top: ${theme.spacingHuge}rem;
    @media (max-width: 1700px) {
        width: 85%;
        padding-top: ${theme.spacingLargest}rem;
    }
`,
);

export default EditorPage;
