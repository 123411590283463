import React from 'react';
import {Translate} from 'react-localize-redux';
import Modal from 'modals/Modal/ModalWithState';
import UsersActivityWithRedux from './UsersActivityWithRedux';
import UsersLeaderboardWithRedux from './UsersLeaderboardWithRedux';

const UsersActivityModal = () => (
    <Modal
        name="usersActivity"
        medium
        noPadding
        menu={[
            {label: <Translate id="dashboard.summary_activity" />},
            {label: <Translate id="dashboard.leaderboard" />},
        ]}>
        {({activeTab}) => (
            <div className="content">
                {(() => {
                    switch (activeTab) {
                        case 0:
                            return <UsersActivityWithRedux />;
                        case 1:
                        default:
                            return <UsersLeaderboardWithRedux />;
                    }
                })()}
                <style jsx>
                    {`
                        .content :global(.list) {
                            min-height: 50rem;
                        }
                    `}
                </style>
            </div>
        )}
    </Modal>
);

export default UsersActivityModal;
