import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    MESSAGES_LIST_REQUEST,
    MESSAGES_LIST_SUCCESS,
    MESSAGES_LIST_FAILURE,
    MESSAGES_VIEW_REQUEST,
    MESSAGES_VIEW_SUCCESS,
    MESSAGES_VIEW_FAILURE,
    MESSAGES_CREATE_REQUEST,
    MESSAGES_CREATE_SUCCESS,
    MESSAGES_CREATE_FAILURE,
    MESSAGES_UPDATE_REQUEST,
    MESSAGES_UPDATE_SUCCESS,
    MESSAGES_UPDATE_FAILURE,
    MESSAGES_DELETE_REQUEST,
    MESSAGES_DELETE_SUCCESS,
    MESSAGES_DELETE_FAILURE,
    MESSAGES_PUBLISH_REQUEST,
    MESSAGES_PUBLISH_SUCCESS,
    MESSAGES_PUBLISH_FAILURE,
    FETCH_SURVEY_EXPORT_REQUEST,
    FETCH_SURVEY_EXPORT_SUCCESS,
    FETCH_SURVEY_EXPORT_FAILURE,
} from '../../actions/messages';

const isFetchingSurveyExport = (state = false, action) => {
    switch (action.type) {
        case FETCH_SURVEY_EXPORT_REQUEST:
            return true;
        case FETCH_SURVEY_EXPORT_SUCCESS:
        case FETCH_SURVEY_EXPORT_FAILURE:
            return false;
        default:
            return state;
    }
};

const listActions = [
    MESSAGES_LIST_REQUEST,
    MESSAGES_LIST_SUCCESS,
    MESSAGES_LIST_FAILURE,
];
const deleteActions = [
    MESSAGES_DELETE_REQUEST,
    MESSAGES_DELETE_SUCCESS,
    MESSAGES_DELETE_FAILURE,
];

const messages = combineReducers({
    isFetchingSurveyExport,
    byId: createById({
        listActions,
        viewActions: [
            MESSAGES_VIEW_REQUEST,
            MESSAGES_VIEW_SUCCESS,
            MESSAGES_VIEW_FAILURE,
        ],
        createActions: [
            MESSAGES_CREATE_REQUEST,
            MESSAGES_CREATE_SUCCESS,
            MESSAGES_CREATE_FAILURE,
        ],
        updateActions: [
            [
                MESSAGES_UPDATE_REQUEST,
                MESSAGES_UPDATE_SUCCESS,
                MESSAGES_UPDATE_FAILURE,
            ],
            [
                MESSAGES_PUBLISH_REQUEST,
                MESSAGES_PUBLISH_SUCCESS,
                MESSAGES_PUBLISH_FAILURE,
            ],
        ],
        deleteActions,
    }),
    paginated: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
    }),
    paginatedDrafts: paginate({
        listActions,
        deleteActions,
        listType: 'view-drafts',
        requestFilterKey: 'filters',
    }),
    paginatedSentMessages: paginate({
        listActions,
        deleteActions,
        listType: 'view-sent-messages',
        requestFilterKey: 'filters',
    }),
    paginatedArchivedMessages: paginate({
        listActions,
        listType: 'view-archived-messages',
        requestFilterKey: 'filters',
    }),
    paginatedSentDrafts: paginate({
        listActions,
        listType: 'view-sent-drafts',
        requestFilterKey: 'filters',
    }),
    paginatedSuperUserDrafts: paginate({
        listActions,
        listType: 'view-super-user-drafts',
        requestFilterKey: 'filters',
    }),
    paginatedIntegrationDrafts: paginate({
        listActions,
        listType: 'view-integration-drafts',
        requestFilterKey: 'filters',
    }),
    paginatedPlanning: paginate({
        listActions,
        deleteActions,
        listType: 'planning',
    }),
    paginatedDashboard: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'dashboard',
    }),
    paginatedSearchMessages: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'search',
    }),
});

export default messages;
