import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {REGULAR} from 'lib/helpers/sizeHelper';
import SearchInput from 'lib/components/SearchInput';
import {getById} from 'lib/store/reducers/helpers/byId';
import {FAILURE} from 'lib/store/actions/notifications';
import CustomBlocks, {ATOMIC} from 'lib/constants/customBlocks';
import {
    TYPE_VIDEO,
    TYPE_IMAGE,
    TYPE_FILE,
} from 'lib/store/actions/media/internal';
import actions from 'store/actions';
import Button from 'components/util/Button';
import MediaGallery from 'components/MediaGallery';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';

const InternalMediaSidebar = ({onImportComplete}) => {
    const fileInputRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const {addBlock, updateBlockWithKey} = useManageEditorBlocks();
    const {editor} = useEditorState();
    const {translate} = useTranslate();
    const media = useSelector(({media: mediaFromState}) => mediaFromState);
    const {
        internal: {
            byId: mediaItems,
            paginated: {pages, pageCount, filters, isFetching},
        },
    } = media;
    const maxFileUpload = useSelector(
        ({
            company: {
                data: {max_file_upload},
            },
        }) => max_file_upload,
    );

    const dispatch = useDispatch();
    const addNotification = notification =>
        dispatch(actions.notifications.addNotification(notification));
    const fetchInternalMediaItems = fetchFilters =>
        dispatch(actions.media.internal.fetchInternalMediaItems(fetchFilters));
    const uploadNewMediaItem = formData =>
        dispatch(actions.media.internal.uploadNewMediaItem(formData));

    const onUploadClick = () => {
        const {current} = fileInputRef;
        if (typeof current !== 'undefined') {
            current.value = '';
            current.click();
        }
    };
    const handleUploadNewMediaItem = async formData => {
        const {sidebarData} = editor;
        setIsUploading(true);

        try {
            await uploadNewMediaItem(formData);
            fetchInternalMediaItems({
                filters: {
                    query: '',
                    recentlyUsed: 'DESC',
                    type: sidebarData && (sidebarData.subType || ''),
                },
            });
            setIsUploading(false);
        } catch (error) {
            setIsUploading(false);
        }
    };
    const onFileChange = files => {
        let uploadFiles = files;

        // image file type supports multi file uploads
        if (uploadFiles.length && filters.type !== TYPE_IMAGE) {
            uploadFiles = [files[0]];
        }

        if (uploadFiles.length) {
            // check all files on size
            // uploadFiles is a FileList https://developer.mozilla.org/en-US/docs/Web/API/FileList, not an array
            Array.from(uploadFiles).forEach(uploadFile => {
                if (uploadFile.size >= maxFileUpload) {
                    addNotification({
                        type: FAILURE,
                        content: {
                            key: 'notifications.file_too_big',
                        },
                    });
                    // stop before upload
                    return;
                }

                const formData = new FormData();
                formData.append('media_file', uploadFile);

                handleUploadNewMediaItem(formData);
            });
        }
    };
    const onQueryChange = newFilters => {
        fetchInternalMediaItems({
            filters: Object.assign({}, filters, newFilters),
        });
    };
    const onSearchQueryChange = query => {
        onQueryChange({query});
    };
    const fetchNextInternalMediaItemsPage = () => {
        const {sidebarData} = editor;

        if (!isFetching) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetchInternalMediaItems({
                    filters: {
                        query: filters.query || '',
                        recentlyUsed: 'DESC',
                        type: sidebarData && (sidebarData.subType || ''),
                    },
                    page,
                });
            }
        }
    };
    const onMediaItemClick = async mediaItem => {
        const {sidebarData: {blockKey} = {}} = editor;
        onImportComplete();

        const blockData = {
            type: CustomBlocks.MEDIA_BLOCK,
            mediaItemId: mediaItem.id,
            size: REGULAR,
        };

        if (!blockKey) {
            await addBlock(blockData, ATOMIC);
        } else {
            await updateBlockWithKey(blockData, blockKey);
        }
        dispatch(actions.editor.setActiveSidebar(''));
    };

    useEffect(() => {
        const {sidebarData} = editor;
        fetchInternalMediaItems({
            filters: {
                query: filters.query || '',
                recentlyUsed: 'DESC',
                type: sidebarData && (sidebarData.subType || ''),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = [];
    if (pages.length) {
        pages.forEach(page => {
            page.forEach(mediaItemId => {
                const mediaItem = getById(mediaItems, mediaItemId);
                items.push(mediaItem);
            });
        });
    }
    const acceptTypes = (() => {
        switch (filters.type) {
            case TYPE_VIDEO:
                return '.ogv,.webm,.weba,.mp4,.wmv';
            case TYPE_IMAGE:
                return 'image/*';
            default:
                return '*';
        }
    })();

    return (
        <>
            <Top>
                <Button
                    className="button full-width"
                    isLoading={isUploading}
                    onClick={onUploadClick}>
                    {translate(
                        `editor.sidebar.upload_${filters.type || 'media'}`,
                    )}
                </Button>

                {filters.type === TYPE_VIDEO && (
                    <AllowedExtensions>
                        <span className="font-weight-bold">
                            {translate('editor.sidebar.allowed_extensions')}{' '}
                        </span>
                        <span className="font-italic">
                            .ogv, .webm, .weba, .mp4, .wmv
                        </span>
                    </AllowedExtensions>
                )}

                {filters.type === TYPE_FILE && (
                    <AllowedExtensions>
                        <span className="font-weight-bold">
                            {translate(
                                'editor.sidebar.suggested_file_extensions',
                            )}{' '}
                        </span>
                    </AllowedExtensions>
                )}

                {filters.type === TYPE_IMAGE && (
                    <AllowedExtensions>
                        <span className="font-weight-bold">
                            {translate('editor.sidebar.allowed_extensions')}{' '}
                        </span>
                        <span className="font-italic">.jpg, .png, .gif</span>
                    </AllowedExtensions>
                )}

                <form>
                    <input
                        type="file"
                        name="media_file"
                        style={{display: 'none'}}
                        ref={fileInputRef}
                        onChange={e => {
                            onFileChange(e.target.files);
                        }}
                        accept={acceptTypes}
                        multiple={filters.type === TYPE_IMAGE}
                    />
                </form>
            </Top>
            <Title>{translate('editor.sidebar.recent_media')}</Title>
            <SearchInput
                placeholder={translate('editor.sidebar.search_images')}
                value={filters.query}
                onChange={onSearchQueryChange}
            />
            <MediaGallery
                isFetching={isFetching}
                media={items}
                onListEnd={fetchNextInternalMediaItemsPage}
                onMediaClick={onMediaItemClick}
                removable
            />
        </>
    );
};

const Title = styled.h2(
    ({theme}) => `
    margin-bottom: ${theme.spacing}rem;
`,
);

const Top = styled.div(
    ({theme}) => `
    margin-bottom: ${theme.spacingLargest}rem;
`,
);

const AllowedExtensions = styled.span(
    ({theme}) => `
    display: block;
    margin-top: ${theme.spacingSmall}rem;
    font-size: ${theme.fontSizeSmaller}rem;
`,
);

export default InternalMediaSidebar;
