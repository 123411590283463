import {useSelector} from 'react-redux';
import {EditorState} from 'draft-js';
import {
    STATUS_NEW,
    STATUS_PROPOSAL,
    STATUS_PUBLISHED,
    STATUS_PUBLISHING,
} from 'lib/store/actions/messages/index';
import {getSurveys} from 'lib/helpers/getMessageContentHelper';
import {
    ROGER_STAFF,
    ADMIN,
    SUPER_USER_ADVANCED,
    SUPER_ADMIN,
} from 'util/getUserRoleHelper';
import useRoles from 'components/hooks/useRoles';

const useEditorState = () => {
    const {userRole} = useRoles();
    const editor = useSelector(({editor: editorFromState}) => editorFromState);

    const {body, title, status, currentLanguage} = editor;
    const editorState = body[currentLanguage] || EditorState.createEmpty();
    const editorTitle = title[currentLanguage];
    const canEdit =
        editor.status === STATUS_NEW ||
        editor.status === STATUS_PROPOSAL ||
        userRole === ROGER_STAFF ||
        userRole === ADMIN ||
        userRole === SUPER_ADMIN ||
        userRole === SUPER_USER_ADVANCED;
    const isAlreadyPublished =
        status === STATUS_PUBLISHING || status === STATUS_PUBLISHED;

    const contentState = editorState?.getCurrentContent();
    const blockArray =
        contentState?.getBlocksAsArray().map(block => block.toJS()) || [];
    const hasSurveys = blockArray ? !!getSurveys(blockArray).length : false;

    return {
        editor,
        canEdit,
        hasSurveys,
        editorTitle,
        editorState,
        isAlreadyPublished,
    };
};

export default useEditorState;
