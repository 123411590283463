import React from 'react';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';
import {connect} from 'react-redux';
import format from 'date-fns/format';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import colors from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {getMonthData, LAST_30} from 'util/analytics';
import actions from 'store/actions';
import downloadHelper from 'lib/helpers/downloadHelper';
import BarChartWrapper from './BarChartWrapper';
import Widget from './Widget';
import Count from '../Count';

const ContentPopularity = ({
    translate,
    langCode,
    popularity,
    isFetching,
    fetchContentPopularityExport,
    fetchingContentPopularityExport,
    showNotification,
    ...props
}) => {
    const color = colors.chart.orange.dark;
    const data = popularity.map(({read = 0}) => read).reverse();
    const monthPopularity = getMonthData(popularity, LAST_30);

    return (
        <Widget
            title={translate('dashboard.content_popularity')}
            onArrowClick={() =>
                downloadHelper(fetchContentPopularityExport, showNotification)
            }
            fetchingExport={fetchingContentPopularityExport}
            {...props}>
            <LoadingWrapper loading={isFetching}>
                {popularity && popularity.length ? (
                    <Count
                        color={color}
                        number={monthPopularity ? monthPopularity.read || 0 : 0}
                        text={translate(
                            'dashboard.read_articles_last_30_days',
                            {
                                month: format(
                                    new Date(),
                                    'MMMM',
                                    getDateLocale(langCode),
                                ),
                            },
                        )}
                    />
                ) : (
                    <p>{translate('global.no_data_available')}</p>
                )}
                <BarChartWrapper data={data} color={color} />
            </LoadingWrapper>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    popularity: state.analytics.messages.popularity,
    isFetching: state.analytics.messages.isFetchingPopularity,
    langCode: getActiveLanguage(state.localize).code,
    fetchingContentPopularityExport:
        state.analytics.messages.fetchingContentPopularityExport,
});

const mapDispatchToProps = dispatch => ({
    fetchContentPopularityExport: () =>
        dispatch(actions.analytics.messages.fetchContentPopularityExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentPopularity);
