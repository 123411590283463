import React from 'react';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import EditorSidebarInformation from './EditorSidebarInformation';

const EditorSidebarInformationWithState = () => {
    const {editor, isAlreadyPublished} = useEditorState();
    const {translate, activeLanguage} = useTranslate();
    const {publishedAt, contentType} = editor;
    const locale = getDateLocale(activeLanguage);

    return (
        <EditorSidebarInformation
            locale={locale}
            translate={translate}
            contentType={contentType}
            publishedAt={publishedAt}
            isAlreadyPublished={isAlreadyPublished}
        />
    );
};

export default EditorSidebarInformationWithState;
