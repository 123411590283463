import React from 'react';
import * as theme from 'lib/styles/theme';
import * as colors from 'lib/styles/colors';

const SimpleTabMenu = ({activeTab, onChange, menu}) => (
    <nav className="tabs">
        <ul>
            {menu.map((menuItem, index) => {
                const key = menuItem.key || index;

                return (
                    <li className="item" key={key}>
                        <button
                            className={`tab ${activeTab === key ? 'isActive' : ''}`}
                            onClick={() => onChange(key)}>
                            {menuItem.label}
                        </button>
                    </li>
                );
            })}
        </ul>

        <style jsx>
            {`
                ul {
                    display: flex;
                }

                .tabs,
                ul,
                .tab {
                    height: 100%;
                }

                .tabs {
                    .item {
                        list-style-type: none;
                        margin: 0;
                    }
                }

                .tab {
                    background-color: transparent;
                    border: 0;
                    color: ${colors.brandingDefaults.pureWhite};
                    margin-right: 3rem;
                    text-transform: uppercase;
                    font-weight: ${theme.fwBold};
                    font-size: 1.6rem;
                    opacity: 0.5;
                    position: relative;
                    transition: opacity ${theme.defaultTransition};

                    &:after {
                        content: '';
                        position: absolute;
                        height: 0.2rem;
                        width: 0;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                        background-color: ${colors.brandingDefaults.pureWhite};
                        transition: width ${theme.defaultTransition};
                    }

                    &:hover,
                    &.isActive {
                        opacity: 1;

                        &:after {
                            width: 100%;
                        }
                    }
                }
            `}
        </style>
    </nav>
);

export default SimpleTabMenu;
