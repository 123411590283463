import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import {MODAL_IMPORT_ERRORS} from 'lib/store/actions/modals';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import Modal from 'modals/Modal/ModalWithState';

const ImportErrorsModal = ({importError, buttonActions}) => {
    if (importError === null) {
        return null;
    }

    const {
        payload: {errors},
    } = importError;
    const menu = [];
    if (typeof errors.missing_columns !== 'undefined') {
        menu.push({
            key: 'missingColumns',
            label: <Translate id="users.missingColumnsTab" />,
        });
    } else {
        menu.push({
            key: 'rowErrors',
            label: <Translate id="users.rowErrorsTab" />,
        });
    }

    return (
        <Modal
            name={MODAL_IMPORT_ERRORS}
            menu={menu}
            buttonActions={buttonActions}>
            {({activeTab}) => {
                if (
                    activeTab === 'missingColumns' &&
                    typeof errors.missing_columns !== 'undefined'
                ) {
                    return (
                        <>
                            <h2 className="title">
                                <Translate id="users.missingColumns" />
                            </h2>
                            {errors.missing_columns.map(missingColumn => (
                                <p className="missingColumn">{missingColumn}</p>
                            ))}

                            <style jsx>
                                {`
                                    .title {
                                        margin-bottom: ${theme.spacingLargest}rem;
                                    }
                                    .missingColumn {
                                        font-weight: ${theme.fwBold};
                                    }
                                `}
                            </style>
                        </>
                    );
                }
                if (
                    activeTab === 'rowErrors' &&
                    typeof errors !== 'undefined'
                ) {
                    const rows = Object.keys(errors);
                    return (
                        <>
                            <h2 className="title">
                                <Translate id="users.rowErrors" />
                            </h2>
                            {rows.map((row, index) => (
                                <div
                                    className={`errorRow${index === rows.length - 1 ? '' : ' errorBorder'}`}>
                                    <p className="rowNumber">{row}</p>
                                    <ul>
                                        {errors[row].map(error => (
                                            <li>
                                                <p>{error}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}

                            <style jsx>
                                {`
                                    .title {
                                        margin-bottom: ${theme.spacingLargest}rem;
                                    }
                                    .errorRow {
                                        display: flex;
                                        margin-top: ${theme.spacingLarge}rem;
                                    }
                                    .errorBorder {
                                        border-bottom: solid
                                            ${colors.brandingDefaults.grey[350]}
                                            0.1rem;
                                    }
                                    .errorRow ul {
                                        list-style-type: circle;
                                    }
                                    .rowNumber {
                                        width: 15%;
                                        font-weight: ${theme.fwBold};
                                    }
                                `}
                            </style>
                        </>
                    );
                }
                return null;
            }}
        </Modal>
    );
};

ImportErrorsModal.propTypes = {
    importError: PropTypes.shape({
        payload: PropTypes.shape({errors: PropTypes.any}),
    }),
    buttonActions: PropTypes.arrayOf(PropTypes.any),
};

ImportErrorsModal.defaultProps = {
    importError: {},
    buttonActions: [],
};
export default ImportErrorsModal;
