import React from 'react';
import Event from './Event';

const EventStar = () => (
    <g fill="currentColor">
        <path d="M9.79262819,15.8053713 L9.43063671,18.0459649 C9.38130002,18.3522884 9.50190083,18.6609758 9.74253537,18.8437848 C9.98354797,19.0262 10.3035749,19.0506271 10.566893,18.9068225 L12.4999085,17.8552761 L14.432924,18.9070195 C14.5472869,18.9688752 14.6726134,19 14.7966168,19 C14.9589931,19 15.1209914,18.947009 15.2576597,18.8439818 C15.4984833,18.6611727 15.6194621,18.3524854 15.5697474,18.046162 L15.208134,15.8055683 L16.7646028,14.2144592 C16.97745,13.9971764 17.0532508,13.6729266 16.9619495,13.3778318 C16.8702702,13.0829339 16.62491,12.86703 16.330402,12.8217217 L14.1735756,12.4884103 L13.202153,10.4536739 C13.0703995,10.1759144 12.7974409,10 12.4997195,10 C12.2025651,10 11.9297956,10.1759144 11.7969078,10.4536739 L10.8264304,12.4884103 L8.66960401,12.8215247 C8.37452897,12.866833 8.13030286,13.0827369 8.03843454,13.3776348 C7.94656621,13.6727296 8.022178,13.9973734 8.23540327,14.2142622 L9.79262819,15.8053713 Z" />

        <Event />
    </g>
);

export default EventStar;
