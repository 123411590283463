import React from 'react';
import parse from 'date-fns/parse';
import {Tooltip} from 'react-tippy';
import {pluralize} from 'lib/helpers/locale';
import {withLocalize} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';
import * as colors from 'lib/styles/colors';
import Icon from 'lib/components/Icon';
import MessageInfo from '../MessageInfo/MessageInfo';
import useRoles from 'components/hooks/useRoles';

const Message = ({
    message,
    messageIndex,
    styling,
    users,
    color,
    langCode,
    history,
    translate,
    messageRef,
    defaultLanguage,
}) => {
    const {isAdmin} = useRoles();
    return (
        <Tooltip
            position="top"
            trigger="click"
            html={[
                <MessageInfo
                    key={messageIndex}
                    icon={message.icon}
                    name={message.name}
                    title={message.title}
                    type={message.messageType}
                    audience={translate(pluralize('users.user_count', users), {
                        users,
                    })}
                    time={parse(message.scheduled_at)}
                    langCode={langCode}
                    onNameClick={() => {
                        history.push(`/editor/messages/${message.id}`);
                    }}
                    color={color}
                    defaultLanguage={defaultLanguage}
                    isAdmin={isAdmin}
                />,
            ]}
            interactive
            theme="custom">
            <div
                className={`message ${message.status}`}
                ref={messageRef}
                style={styling}>
                <span className="icon">
                    <Icon name={message.icon} size={12} />
                </span>
                <span className="message-name">{message.name}</span>

                <style jsx>
                    {`
                        .message {
                            background-color: ${colors.brand.base};
                            color: ${colors.text.light};
                        }

                        .message-name {
                            padding: 0.7rem 0.5rem;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }

                        .icon {
                            display: flex;
                            align-items: center;
                            width: 3.5rem;
                            flex-basis: 3.5rem;
                            flex-shrink: 0;
                            justify-content: center;
                            border-right: solid 0.1rem ${colors.pureWhite};
                        }

                        .draft {
                            border: dashed 0.1rem ${colors.brand.base};
                            background-color: transparent;
                            color: ${colors.brand.base};
                        }

                        .draft .icon {
                            border-style: dashed;
                            border-color: inherit;
                        }
                    `}
                </style>
            </div>
        </Tooltip>
    );
};

export default withLocalize(withRouter(Message));
