import {
    USERS_UPDATE_FAILURE,
    USERS_UPDATE_SUCCESS,
    USERS_CREATE_FAILURE,
    USERS_CREATE_SUCCESS,
} from '../../actions/users';

import {SET_MODAL_VISIBLE, MODAL_USER_DETAIL_ADMIN} from '../../actions/modals';
import {
    USERS_ME_UPDATE_FAILURE,
    USERS_ME_UPDATE_SUCCESS,
} from '../../actions/users/me';

export default (state = [], action) => {
    switch (action.type) {
        case SET_MODAL_VISIBLE:
            if (action.payload.modal === MODAL_USER_DETAIL_ADMIN) {
                return [];
            }
            return state;
        case USERS_CREATE_FAILURE:
        case USERS_UPDATE_FAILURE:
        case USERS_ME_UPDATE_FAILURE:
            return action.payload.errors || [];
        case USERS_CREATE_SUCCESS:
        case USERS_UPDATE_SUCCESS:
        case USERS_ME_UPDATE_SUCCESS:
            return [];
        default:
            return state;
    }
};
