import React from 'react';
import styled from 'styled-components';
import {MANUAL} from 'lib/constants/messages';
import WeekInfo from './WeekInfo';
import DayWithState from './Day/DayWithState';
import './MonthCalendar.css';

const MonthCalendar = ({
    data,
    month,
    weekLength,
    langCode,
    startNewMessage,
}) => (
    <Container className="month-calendar">
        <div className="month-calendar__info">
            {data.map((week, weekIndex) => (
                <WeekInfo
                    data={week.info}
                    week={weekIndex + 1}
                    key={weekIndex}
                />
            ))}
        </div>
        <div className="month-calendar__content">
            {Array.from(Array(weekLength), (x, index) => index).map(weekDay => (
                <div className="month-calendar__col" key={weekDay}>
                    {data.map(week => {
                        const day = week.days[weekDay];
                        const messages = day.messages.filter(
                            ({type}) => type === MANUAL,
                        );

                        return (
                            <DayWithState
                                key={day.date}
                                month={month}
                                day={{...day, messages}}
                                dayOfTheWeek={weekDay}
                                langCode={langCode}
                                startNewMessage={startNewMessage}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    </Container>
);

const Container = styled.div(
    ({theme}) => `
    height: 100%;
    background: ${theme.colorWhite};
    border-radius: ${theme.borderRadius}rem;
    box-shadow: ${theme.defaultShadow};
    overflow: hidden;
`,
);

export default MonthCalendar;
