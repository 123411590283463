import React from 'react';
import {useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import actions from 'store/actions';
import {STATUS_ACTIVE, STATUS_INACTIVE} from 'util/constants';
import useEditorState from 'components/hooks/useEditorState';
import EditorSidebarSwitch from './EditorSidebarSwitch';

const EditorSidebarAutomatedMessageActiveToggleWithState = ({canEdit}) => {
    const {
        editor: {contentId, status},
    } = useEditorState();

    const dispatch = useDispatch();
    const activateAutomatedMessage = message =>
        dispatch(actions.automatedMessages.activateAutomatedMessage(message));
    const deactivateAutomatedMessage = message =>
        dispatch(actions.automatedMessages.deactivateAutomatedMessage(message));
    const setEditorStatus = newStatus =>
        dispatch(actions.editor.setStatus(newStatus));

    const onAutomatedMessageActiveChange = async active => {
        if (active && status !== STATUS_ACTIVE) {
            try {
                await activateAutomatedMessage({id: contentId});
                setEditorStatus(STATUS_ACTIVE);
            } catch (error) {
                //
            }
        } else if (!active && status === STATUS_ACTIVE) {
            try {
                await deactivateAutomatedMessage({id: contentId});
                setEditorStatus(STATUS_INACTIVE);
            } catch (error) {
                //
            }
        }
    };

    if (!contentId) {
        return null;
    }

    return (
        <EditorSidebarSwitch
            canEdit={canEdit}
            value={status === STATUS_ACTIVE}
            onChange={onAutomatedMessageActiveChange}
            label={<Translate id="editor.automated_message_active" />}
        />
    );
};

export default EditorSidebarAutomatedMessageActiveToggleWithState;
