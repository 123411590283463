import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M0.842593 1.19186C1.37082 0.479209 2.13104 0 3.00346 0H20.9965C21.8705 0 22.6308 0.479027 23.1588 1.19202C23.6864 1.9045 24 2.86897 24 3.91479V20.0852C24 21.1327 23.6865 22.0972 23.1587 22.8094C22.6305 23.5222 21.8702 24 20.9965 24H10.045C10.0073 24 9.97084 23.995 9.93603 23.9857H3.00346C2.13139 23.9857 1.37112 23.5077 0.842684 22.7953C0.314534 22.0833 0 21.1187 0 20.0709V3.91479C0 2.86865 0.314606 1.90419 0.842593 1.19186ZM10.1539 23.1062H20.9965C21.5451 23.1062 22.0702 22.8082 22.4719 22.2661C22.874 21.7234 23.1349 20.954 23.1349 20.0852V3.91479C23.1349 3.0479 22.8741 2.27842 22.4718 1.73514C22.0699 1.19236 21.5447 0.893788 20.9965 0.893788H3.00346C2.45719 0.893788 1.93195 1.19217 1.52938 1.7353C1.12657 2.27874 0.865052 3.04822 0.865052 3.91479V20.0709C0.865052 20.9394 1.12664 21.7088 1.52929 22.2516C1.93165 22.7941 2.45685 23.0919 3.00346 23.0919H10.045C10.0826 23.0919 10.1191 23.0969 10.1539 23.1062Z" />
            <path d="M8.04928 7.4113C8.23083 7.5717 8.25216 7.8538 8.09691 8.04138L4.82529 11.9945L8.121 15.9728C8.27633 16.1603 8.25514 16.4424 8.07366 16.6029C7.89219 16.7634 7.61915 16.7415 7.46381 16.554L3.92748 12.2853C3.78896 12.118 3.7889 11.8715 3.92734 11.7042L7.43945 7.46051C7.5947 7.27293 7.86773 7.2509 8.04928 7.4113Z" />
            <path d="M15.9161 7.41132C16.0977 7.2509 16.3707 7.27292 16.526 7.46049L20.0623 11.7328C20.2007 11.9001 20.2007 12.1466 20.0622 12.3138L16.5501 16.554C16.3948 16.7415 16.1217 16.7634 15.9402 16.6029C15.7587 16.4424 15.7375 16.1603 15.8929 15.9728L19.1644 12.0232L15.8685 8.04141C15.7133 7.85384 15.7346 7.57174 15.9161 7.41132Z" />
            <path d="M14.4608 5.59814C14.6828 5.68932 14.7912 5.94916 14.7029 6.17852L10.0905 18.166C10.0022 18.3954 9.75075 18.5074 9.52877 18.4162C9.30679 18.325 9.19838 18.0652 9.28663 17.8358L13.8991 5.84832C13.9873 5.61897 14.2388 5.50696 14.4608 5.59814Z" />
        </g>
    );
}
