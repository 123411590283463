import React, {useState} from 'react';
import {addNewBlock} from 'medium-draft';
import {useSelector, useDispatch} from 'react-redux';
import CustomBlocks, {ATOMIC} from 'lib/constants/customBlocks';
import {modalForSurveyType, MODAL_CONFIRM} from 'lib/store/actions/modals';
import actions from 'store/actions';
import {
    saveSurvey,
    getModalUiTranslationKeys,
    syncBodyWithNewSurvey,
    syncNewSurveyWithLanguages,
} from 'util/surveys';
import useTranslate from './useTranslate';
import useEditorState from './useEditorState';
import useContentSaver from './useContentSaver';
import {throwIfInvalidApiResponse} from 'util/errorHelper';

const useSurveyEditor = () => {
    const [isSaving, setIsSaving] = useState(false);
    const {translate} = useTranslate();
    const {saveContent} = useContentSaver();
    const {editor, editorState} = useEditorState();
    const survey = useSelector(({surveyEditor}) => surveyEditor);
    const {currentLanguage} = editor;

    const dispatch = useDispatch();
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));
    const setContent = content =>
        dispatch(actions.surveyEditor.setContent(content));
    const onContentChange = newContent =>
        setContent({...survey.content, ...newContent});
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));

    const onSave = async resetAnswers => {
        setIsSaving(true);
        try {
            const isNewSurvey = !survey.id;
            let surveyToCreate = survey;

            // Reset option index to fix buggy edit
            if (surveyToCreate.type === 'multiple_choice') {
                surveyToCreate.options = surveyToCreate.options.map(
                    (option, index) => ({...option, value: index}),
                );
            }

            if (isNewSurvey) {
                surveyToCreate = syncNewSurveyWithLanguages(
                    surveyToCreate,
                    editor.availableLanguages,
                    currentLanguage,
                );
            }
            const result = await saveSurvey(
                {
                    ...surveyToCreate,
                    available_languages: editor.availableLanguages,
                },
                resetAnswers,
            );
            throwIfInvalidApiResponse(result);
            const {payload} = result;
            const createdSurveyId = payload.ids;

            if (isNewSurvey) {
                const newEditorBody = {...editor.body};
                newEditorBody[currentLanguage] = addNewBlock(
                    editorState,
                    ATOMIC,
                    {
                        type: CustomBlocks.SURVEY_BLOCK,
                        surveyId: createdSurveyId,
                    },
                );
                // Sync survey to other languages
                const syncedEditorBody = syncBodyWithNewSurvey(
                    newEditorBody,
                    currentLanguage,
                    createdSurveyId,
                );
                await saveContent({...editor, body: syncedEditorBody});
            }

            setIsSaving(false);
            const modal = modalForSurveyType(survey.type);
            setModalVisibility(modal, false);
            setModalVisibility(MODAL_CONFIRM, false);
        } catch (e) {
            setIsSaving(false);
        }
    };

    const createBaseForm = () => {
        const question = (
            <div className="form__group" key="1">
                <label className="label" htmlFor="yes_no_question">
                    {translate('editor.form.question.label')}
                </label>
                <input
                    className="input"
                    type="text"
                    id="yes_no_question"
                    placeholder={translate('editor.form.question.placeholder')}
                    value={
                        typeof survey.content.question !== 'undefined'
                            ? survey.content.question[currentLanguage] || ''
                            : ''
                    }
                    onChange={e => {
                        const updatedQuestion = {...survey.content.question};
                        updatedQuestion[currentLanguage] = e.target.value;
                        onContentChange({question: updatedQuestion});
                    }}
                />
            </div>
        );

        const {description} = survey.content;
        const descriptionInput = (
            <div className="form__group" key="2">
                <label className="label" htmlFor="yes_no_question">
                    {translate('editor.form.description.label')}
                </label>
                <textarea
                    className="textarea"
                    type="text"
                    id="yes_no_question"
                    placeholder={translate(
                        'editor.form.description.placeholder',
                    )}
                    value={
                        description === null ||
                        typeof description === 'undefined' ||
                        typeof description[currentLanguage] === 'undefined'
                            ? ''
                            : description[currentLanguage]
                    }
                    onChange={e => {
                        const updatedDescription =
                            description === null ? {} : {...description};
                        updatedDescription[currentLanguage] = e.target.value;
                        onContentChange({description: updatedDescription});
                    }}
                />
            </div>
        );

        return [question, descriptionInput];
    };

    return {
        translate,
        survey,
        baseForm: createBaseForm(),
        language: editor.currentLanguage,
        onContentChange,
        isSaving,
        onSave: () => {
            let hasAnswers = false;

            if (survey && survey.answer_stats) {
                let answers;
                if (typeof survey.answer_stats === 'object') {
                    answers = Object.values(survey.answer_stats);
                } else {
                    answers = survey.answer_stats;
                }

                if (Array.isArray(answers)) {
                    hasAnswers = answers.some(answer => answer);
                }
            }

            if (hasAnswers) {
                setModalVisibility(MODAL_CONFIRM, true);
                setModalData({
                    title: translate('editor.update_poll_text'),
                    buttonActions: [
                        {
                            label: translate('editor.update_poll'),
                            callBack: onSave,
                            classes: 'warning',
                        },
                        {
                            label: translate('editor.reset_answers'),
                            callBack: () => {
                                onSave(true);
                            },
                            classes: 'danger',
                        },
                    ],
                });
            } else {
                onSave();
            }
        },
        ...getModalUiTranslationKeys(survey.id),
    };
};

export default useSurveyEditor;
