import React from 'react';
import {Translate} from 'react-localize-redux';
import Modal from 'modals/Modal/ModalWithState';
import AdminMessageReadsByWeekdayAndHourWithState from './AdminMessageReadsByWeekdayAndHourWithState';

const AdminMessageReadsByWeekdayAndHourModal = () => (
    <Modal
        name="adminMessagesReadByWeekdayAndHour"
        noPadding
        menu={[{label: <Translate id="dashboard.message_reads_by_weekday" />}]}>
        <AdminMessageReadsByWeekdayAndHourWithState />
    </Modal>
);

export default AdminMessageReadsByWeekdayAndHourModal;
