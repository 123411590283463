import React from 'react';
import Icon from 'lib/components/Icon';
import styled from 'styled-components';
import classNames from 'classnames';

const CloseButton = ({onClick, size = 24, className}) => (
    <Button
        className={classNames('close-button', className)}
        onClick={onClick}
        size={size}>
        <Icon name="add" size={size} />
    </Button>
);

const Button = styled.button(
    ({size}) => `
    background: none;
    border: 0;
    width: ${size / 10}rem;
    height: ${size / 10}rem;
    cursor: pointer;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transform: rotate(45deg);
    }

    &:focus {
        outline: none;
    }

`,
);

export default CloseButton;
