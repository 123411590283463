import React from 'react';
import {
    TRIGGER_TIMER,
    TRIGGER_MESSAGE_READ,
    TRIGGER_AUTOMATED_MESSAGE_READ,
    TRIGGER_WORK_ANNIVERSARY,
} from 'util/constants';
import MessageSelect from './MessageSelectWithRedux';
import AutomatedMessageSelect from './AutomatedMessageSelectWithRedux';
import RecurrentSelect from './RecurrentSelect';
import WorkAnniversary from './WorkAnniversary';

const Triggerconfig = ({canEdit, trigger, onChange}) => {
    if (!canEdit) {
        return null;
    }

    switch (trigger.name) {
        case TRIGGER_MESSAGE_READ:
            return <MessageSelect />;
        case TRIGGER_AUTOMATED_MESSAGE_READ:
            return <AutomatedMessageSelect />;
        case TRIGGER_TIMER:
            return <RecurrentSelect onChange={onChange} trigger={trigger} />;
        case TRIGGER_WORK_ANNIVERSARY:
            return <WorkAnniversary onChange={onChange} trigger={trigger} />;
        default:
            return null;
    }
};

export default Triggerconfig;
