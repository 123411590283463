import React from 'react';
import format from 'date-fns/format';
import {Translate} from 'react-localize-redux';
import SimpleTabMenu from 'lib/components/SimpleTabMenu';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import * as theme from 'styles/theme';
import Button from 'components/util/Button';

const EditorMenu = ({
    menu,
    langCode,
    isLoading,
    activeTab,
    lastSavedAt,
    onTabChange,
    onCloseEditor,
}) => {
    const locale = getDateLocale(langCode);
    const date = format(lastSavedAt, 'dd DD/MM', locale);
    const time = format(lastSavedAt, 'HHumm', locale);

    return (
        <div className="editor-menu">
            <div className="editor-menu-container">
                {!isLoading && (
                    <div className="menu">
                        <SimpleTabMenu
                            activeTab={activeTab}
                            onChange={onTabChange}
                            menu={menu}
                        />
                    </div>
                )}
                <div className="right">
                    {lastSavedAt && (
                        <div className="last-saved">
                            <Translate id="editor.last_saved" />
                            <br />
                            <span className="time">
                                <span className="text-uppercase">{date}</span> -{' '}
                                {time}
                            </span>
                        </div>
                    )}
                    <Button warning ghost onClick={onCloseEditor}>
                        <Translate id="global.close" />
                    </Button>
                </div>
            </div>

            <style jsx>
                {`
                    @import '../../../lib/sass/kit';

                    .editor-menu {
                        width: 100%;
                        border-bottom: 1px solid ${theme.colorGrey200};

                        .editor-menu-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: $editor-header-height;
                        }

                        .right {
                            right: 3rem;
                            display: flex;
                            align-items: center;
                        }

                        :global(.close) {
                            filter: drop-shadow(0 0.1rem 0 $pure-white);
                        }
                    }

                    .last-saved {
                        margin-left: auto;
                        margin-right: 3.3rem;
                        text-align: right;
                        text-transform: uppercase;
                        font-size: 1.2rem;
                        font-weight: $fw-bold;
                        line-height: 1.6rem;
                        color: ${theme.colorTextGrey};

                        .time {
                            text-transform: none;
                        }
                    }

                    .menu {
                        height: 6rem;
                        align-self: flex-end;
                    }
                `}
            </style>
        </div>
    );
};

export default EditorMenu;
