import React, {forwardRef} from 'react';
import {useDispatch} from 'react-redux';
import actions from 'store/actions';
import useEditorState from 'components/hooks/useEditorState';
import RogerEditor from './RogerEditor';
import {cleanUpAtomicBlocksWithoutNewLine} from './Utils/editor';

const RogerEditorWithState = forwardRef((_, editorRef) => {
    const {editor, canEdit, editorState} = useEditorState();
    const dispatch = useDispatch();
    const setEditorState = state =>
        dispatch(actions.editor.setEditorState(state));

    const onEditorChange = state => {
        const newState = cleanUpAtomicBlocksWithoutNewLine(state);
        const newEditorState = editor.body;
        newEditorState[editor.currentLanguage] = newState;
        setEditorState(newEditorState);
    };

    return (
        <RogerEditor
            canEdit={canEdit}
            editorState={editorState}
            onChange={onEditorChange}
            ref={editorRef}
        />
    );
});

export default RogerEditorWithState;
