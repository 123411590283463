import React from 'react';
import Icon from 'lib/components/Icon';
import CustomBlocks, {ATOMIC} from 'lib/constants/customBlocks';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';
import Button from './ButtonHOC';

const ChooseChannelsButton = ({close, ...otherProps}) => {
    const {addBlock} = useManageEditorBlocks();

    return (
        <Button
            {...otherProps}
            type="channel"
            onClick={() => {
                close();
                const blockData = {
                    type: CustomBlocks.CHOOSE_CHANNELS_BLOCK,
                };

                setTimeout(() => {
                    addBlock(blockData, ATOMIC);
                }, 200);
            }}>
            <Icon name="list" />
        </Button>
    );
};

export default ChooseChannelsButton;
