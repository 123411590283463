import React from 'react';
import classNames from 'classnames';
import Icon from 'lib/components/Icon';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';
import {brandingDefaults} from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import InfoTooltip from '../../lib/components/InfoTooltip';

export default ({
    children,
    title,
    subTitle,
    header,
    type,
    onClick,
    onArrowClick,
    onHeaderClick,
    fetchingExport,
    className,
    tooltip,
}) => (
    <Branding.Consumer>
        {colors => (
            <div
                className={classNames(
                    'widget',
                    type,
                    {clickable: onClick},
                    className,
                )}
                onClick={() => {
                    if (onClick) onClick();
                }}>
                <div className="widget-container">
                    <div
                        className={classNames('widget-header', {
                            clickable: onHeaderClick,
                        })}
                        onClick={() => {
                            if (onHeaderClick) onHeaderClick();
                        }}>
                        {header}
                        {title && (
                            <h3 className="title">
                                {title}
                                {tooltip && <InfoTooltip info={tooltip} />}
                            </h3>
                        )}
                        {subTitle && <h3 className="subtitle">{subTitle}</h3>}
                        {onArrowClick && (
                            <div className="fetchExport">
                                <LoadingWrapper
                                    loading={fetchingExport}
                                    size={3}>
                                    <button
                                        className="arrow"
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (onArrowClick) onArrowClick();
                                        }}>
                                        <Icon name="download" />
                                    </button>
                                </LoadingWrapper>
                            </div>
                        )}
                    </div>
                    <div className="content">{children}</div>
                </div>

                <style jsx>
                    {`
                        .widget {
                            height: 100%;
                            position: relative;
                        }

                        .dark-header .widget-header {
                            background-color: ${colors.brand[900]};
                            color: ${colors.text.light};
                            padding-bottom: 2rem;
                        }

                        .dark-header {
                            .arrow {
                                color: ${colors.pureWhite};
                            }
                        }

                        .clickable {
                            cursor: pointer;
                        }

                        .widget-container {
                            background-color: ${colors.pureWhite};
                            border-radius: ${theme.borderRadiusLarge}rem;
                            width: 100%;
                            height: 100%;
                            box-shadow: ${theme.defaultShadow};
                            overflow: hidden;

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }

                        .widget-header,
                        .content {
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }

                        .widget-header {
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            color: ${brandingDefaults.brand[900]};
                            position: relative;
                        }

                        .title {
                            font-weight: ${theme.fwBold};
                            font-size: ${theme.fontSizeLarge}rem;
                        }

                        .subtitle {
                            font-weight: ${theme.fwBold};
                            font-size: 1.4rem;
                            margin-top: 1rem;
                            display: block;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                            white-space: nowrap;
                        }

                        .fetchExport {
                            position: absolute;
                            padding: 1rem 1.5rem 0.5rem 0.5rem;
                            right: 0;
                            top: 0;
                        }

                        .arrow {
                            border: 0;
                            color: ${brandingDefaults.brand[900]};
                            background: none;
                        }

                        .count {
                            max-width: 19.4rem;
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);
