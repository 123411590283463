/* eslint-disable no-underscore-dangle */
import {CompositeDecorator} from 'draft-js';
import SmartTagEntity, {
    SMART_TAG,
} from '../../../components/Editor/SmartTags/SmartTagEntity';

const link = require('medium-draft/lib/components/entities/link');

const interopRequireDefault = obj =>
    obj && obj.__esModule ? obj : {default: obj};
const linkComponent = interopRequireDefault(link).default;

const smartTagStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === SMART_TAG
        );
    }, callback);
};

const decorator = new CompositeDecorator([
    {
        strategy: smartTagStrategy,
        component: SmartTagEntity,
    },
    {
        strategy: link.findLinkEntities,
        component: linkComponent,
    },
]);

export default decorator;
