import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    LIST_AUDIENCE_FOR_MESSAGE_FAILURE,
    LIST_AUDIENCE_FOR_MESSAGE_SUCCESS,
    LIST_AUDIENCE_FOR_MESSAGE_REQUEST,
    FETCH_AUDIENCE_EXPORT_REQUEST,
    FETCH_AUDIENCE_EXPORT_SUCCESS,
    FETCH_AUDIENCE_EXPORT_FAILURE,
} from '../../actions/users/audience';
import {
    FETCH_MESSAGE_READS_BY_HOUR_REQUEST,
    FETCH_MESSAGE_READS_BY_HOUR_SUCCESS,
    FETCH_MESSAGE_READS_BY_HOUR_FAILURE,
} from '../../actions/analytics/messages';
import list from '../helpers/list';

const isFetchingAudienceExport = (state = false, action) => {
    switch (action.type) {
        case FETCH_AUDIENCE_EXPORT_REQUEST:
            return true;
        case FETCH_AUDIENCE_EXPORT_SUCCESS:
        case FETCH_AUDIENCE_EXPORT_FAILURE:
            return false;
        default:
            return state;
    }
};

const audience = combineReducers({
    isFetchingAudienceExport,
    byId: createById({
        listActions: [
            LIST_AUDIENCE_FOR_MESSAGE_FAILURE,
            LIST_AUDIENCE_FOR_MESSAGE_SUCCESS,
            LIST_AUDIENCE_FOR_MESSAGE_REQUEST,
        ],
    }),
    paginated: paginate({
        listActions: [
            LIST_AUDIENCE_FOR_MESSAGE_REQUEST,
            LIST_AUDIENCE_FOR_MESSAGE_SUCCESS,
            LIST_AUDIENCE_FOR_MESSAGE_FAILURE,
        ],
        requestFilterKey: 'filters',
    }),
    readsByHour: list({
        listActions: [
            FETCH_MESSAGE_READS_BY_HOUR_REQUEST,
            FETCH_MESSAGE_READS_BY_HOUR_SUCCESS,
            FETCH_MESSAGE_READS_BY_HOUR_FAILURE,
        ],
    }),
});

export default audience;
