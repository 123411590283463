import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import styled from 'styled-components';
import Dropdown from 'components/Dropdown';
import PropTypes from 'prop-types';
import {actions} from '../../../store';
import Icon from '../Icon';
import {
    SURVEY_TYPE_FREE_TEXT,
    SURVEY_TYPE_OPTION,
} from '../../constants/customBlocks';

const ChoiceEdit = ({
    survey,
    labels,
    index,
    option,
    addRef,
    inputRefs,
    currentLanguage,
}) => {
    const dispatch = useDispatch();
    const addDisabled = !(survey.options.length < labels.length);
    const deleteDisabled = !(survey.options.length > 1);
    const translate = useSelector(state => getTranslate(state.localize));
    const setOptions = options => {
        dispatch(actions.surveyEditor.setOptions(options));
    };
    const DEFAULT_OPTION = {
        label: {currentLanguage: ''},
        value: '',
        type: SURVEY_TYPE_OPTION,
    };

    const setFocus = key => {
        const input = inputRefs[key];

        if (input) {
            input.focus();
        }
    };

    const onAdd = key => {
        const options = [...survey.options];

        if (options.length < 26) {
            // add a new option at index and set focus to new field
            options.splice(key + 1, 0, DEFAULT_OPTION);
            setOptions(options);
        }
    };

    const onDelete = key => {
        const options = [...survey.options];

        if (options.length > 1) {
            // remove option at index and set focus to field above
            options.splice(key, 1);
            setOptions(options);
            setFocus(key - 1);
        }
    };

    const handleKeyDown = (e, key) => {
        // const options = [...survey.options]

        switch (e.key) {
            case 'Enter':
                onAdd(key);
                break;
            // case 'Backspace':
            //     const defaultOptionWasSelected = !options[key].label[currentLanguage].length
            //     if (defaultOptionWasSelected) {
            //         e.preventDefault()
            //         onDelete(key)
            //     }
            //     break
            case 'ArrowDown':
                e.preventDefault();
                setFocus(key + 1);
                break;
            case 'ArrowUp':
                e.preventDefault();
                setFocus(key - 1);
                break;
            default:
                break;
        }
    };

    const updateType = type => {
        const options = [...survey.options];
        options[index] = {...options[index], type};
        setOptions(options);
    };

    const isOptionType =
        (option.type || SURVEY_TYPE_OPTION) === SURVEY_TYPE_OPTION;

    const placeholderLabel = isOptionType
        ? translate('editor.form.choices.placeholder')
        : translate('editor.form.choices.placeholder_free_text');

    return (
        <>
            <Dropdown
                key={isOptionType ? 1 : 2}
                value={option.type || SURVEY_TYPE_OPTION}
                onChange={value => {
                    updateType(value);
                }}>
                <option selected={isOptionType} value={SURVEY_TYPE_OPTION}>
                    {translate('editor.form.choices.option')}
                </option>
                <option selected={!isOptionType} value={SURVEY_TYPE_FREE_TEXT}>
                    {translate('editor.form.choices.free_text')}
                </option>
            </Dropdown>
            <input
                className="input"
                type="text"
                placeholder={placeholderLabel}
                value={option.label[currentLanguage] || ''}
                onChange={e => {
                    const options = [...survey.options];
                    const updatedLabel = {...option.label};
                    updatedLabel[currentLanguage] = e.target.value;
                    options[index] = {
                        label: updatedLabel,
                        value: index,
                        type: option.type || SURVEY_TYPE_OPTION,
                    };
                    setOptions(options);
                }}
                onKeyDown={e => handleKeyDown(e, index)}
                ref={ref => addRef(ref)}
            />

            <Actions>
                <Add
                    className={`${addDisabled ? 'disabled' : ''}`}
                    onClick={() => onAdd(index)}
                    disabled={addDisabled}>
                    <Icon name="add" />
                </Add>
                <Delete
                    className={`${deleteDisabled ? 'disabled' : ''}`}
                    onClick={() => onDelete(index)}
                    disabled={deleteDisabled}>
                    <Icon name="add" />
                </Delete>
            </Actions>

            <style jsx>
                {`
                    .dropdown {
                        width: 145px;
                        margin: 0 1rem;
                    }
                `}
            </style>
        </>
    );
};

const Actions = styled.div(
    ({theme}) => `
    display: flex;
    align-items: center;
    margin-left: ${theme.spacing}rem;
`,
);

const Action = styled.button`
    border: 0;
    font-size: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`;

const Add = styled(Action)(
    ({theme}) => `
    color: ${theme.success.base};
    margin-right: .8rem;
`,
);

const Delete = styled(Action)(
    ({theme}) => `
    color: ${theme.danger.base};
    transform: rotate(45deg);
`,
);

ChoiceEdit.propTypes = {
    survey: PropTypes.shape({
        options: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
            }),
        ),
    }).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    index: PropTypes.number.isRequired,
    option: PropTypes.shape({label: PropTypes.string, type: PropTypes.string})
        .isRequired,
    addRef: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired,
    inputRefs: PropTypes.objectOf(PropTypes.instanceOf(Element)),
};

ChoiceEdit.defaultProps = {
    inputRefs: {},
};
export default ChoiceEdit;
