import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M6.375 12L4.125 14.25L1.875 12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.125 12C4.125 12.694 4.211 13.366 4.36 14.015"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.125 12C22.125 7.029 18.096 3 13.125 3C8.154 3 4.125 7.029 4.125 12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.125 21C15.5119 21 17.8011 20.0518 19.489 18.364C21.1768 16.6761 22.125 14.3869 22.125 12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.75403 17.155C6.5819 18.3424 7.68449 19.3122 8.9679 19.9816C10.2513 20.6511 11.6775 21.0005 13.125 21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.496 14.871L12.844 12.698V7.985"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
