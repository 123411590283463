import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getActiveLanguage, getTranslate} from 'react-localize-redux';
import {actions} from 'store/index';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {
    getMonthData,
    parseOptions,
    LAST_30,
    parseChartData,
} from 'util/analytics';
import State from 'lib/components/State';
import UsersActivity from './UsersActivity';

const UsersActivityWithRedux = () => {
    const userActivity = useSelector(
        state => state.analytics.userActivity.activity,
    );
    const isLoading = useSelector(
        state => state.analytics.userActivity.isLoading,
    );
    const isLoadingDetail = useSelector(
        state => state.analytics.userActivity.isLoadingDetail,
    );
    const userActivityDetail = useSelector(
        state => state.analytics.userActivity.activityDetail,
    );
    const langCode = useSelector(
        state => getActiveLanguage(state.localize).code,
    );
    const hasFetchedUserActivity = useSelector(
        state => state.analytics.userActivity.hasFetchedActivity,
    );
    const hasFetchedActivityDetail = useSelector(
        state => state.analytics.userActivity.hasFetchedActivityDetail,
    );
    const translate = useSelector(state => getTranslate(state.localize));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasFetchedUserActivity) {
            dispatch(actions.analytics.userActivity.fetchUserActivity());
        }
        if (!hasFetchedActivityDetail) {
            dispatch(
                actions.analytics.userActivity.fetchUserActivityDetail(
                    'active',
                ),
            );
        }
    }, [dispatch, hasFetchedUserActivity, hasFetchedActivityDetail]);

    const dateOptions = parseOptions(
        userActivity,
        getDateLocale(langCode),
        () => translate('dashboard.last_30_days'),
    );

    const chartData = parseChartData(
        userActivity,
        'not_active',
        getDateLocale(langCode),
        () => translate('dashboard.last_30_days_short'),
    );

    const mostActiveUser = userActivityDetail[0];

    return (
        <State
            initialState={{
                activePeriod: LAST_30,
            }}>
            {({state, setState}) => {
                let selectedDate = {};
                if (state.activePeriod) {
                    selectedDate = getMonthData(
                        userActivity,
                        state.activePeriod,
                    );
                }

                return (
                    <UsersActivity
                        dateOptions={dateOptions}
                        activePeriod={state.activePeriod}
                        isLoading={isLoading}
                        mostActiveUser={mostActiveUser}
                        selectedDate={selectedDate}
                        isLoadingDetail={isLoadingDetail}
                        chartData={chartData}
                        onPeriodChange={month =>
                            setState({activePeriod: month})
                        }
                    />
                );
            }}
        </State>
    );
};

export default UsersActivityWithRedux;
