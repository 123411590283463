/* eslint-disable import/extensions */
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import countriesDE from '../locale/de/countries.json';
import countriesEN from '../locale/en/countries.json';
import countriesES from '../locale/es/countries.json';
import countriesFR from '../locale/fr/countries.json';
import countriesIT from '../locale/it/countries.json';
import countriesNL from '../locale/nl/countries.json';
import countriesPL from '../locale/pl/countries.json';
import countriesPT from '../locale/pt/countries.json';
import countriesTR from '../locale/tr/countries.json';

import {DE, EN, ES, FR, IT, NL, PL, PT, TR} from '../helpers/locale';

const TRANSLATIONS = {
    [DE]: countriesDE,
    [EN]: countriesEN,
    [ES]: countriesES,
    [FR]: countriesFR,
    [IT]: countriesIT,
    [NL]: countriesNL,
    [PL]: countriesPL,
    [PT]: countriesPT,
    [TR]: countriesTR,
};

export const transformMobile = value => {
    if (value !== null && typeof value === 'string') {
        if (value.length < 3) {
            return '';
        }
        if (value.length > 1 && value[0] !== '+') {
            return `+${value}`;
        }
    }
    return value;
};

const PhoneInput = ({
    locale,
    defaultCountry = 'be',
    enableLongNumbers = true,
    ...otherProps
}) => (
    <ReactPhoneInput
        country={defaultCountry}
        localization={TRANSLATIONS[locale]}
        enableLongNumbers={enableLongNumbers}
        {...otherProps}
    />
);

export default PhoneInput;
