export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';
export const GET_ACTIVE_MENU_ITEM = 'GET_ACTIVE_MENU_ITEM';

export const STOCK = 'STOCK';
export const INTERNAL = 'INTERNAL';

export const MESSAGES = 'MESSAGES';
export const STREAM = 'STREAM';

export const SURVEY = 'SURVEY';

export const setActiveMenuItem = (menuKey, activeMenuItem) => ({
    type: SET_ACTIVE_MENU_ITEM,
    payload: {
        menuKey,
        activeMenuItem,
    },
});

export const getActiveMenuItem = menuKey => ({
    type: GET_ACTIVE_MENU_ITEM,
    payload: {
        menuKey,
    },
});

export default {
    setActiveMenuItem,
    getActiveMenuItem,
};
