import React from 'react';

export default function () {
    return (
        <path
            d="M19 6.94H12.529C12.3659 6.93998 12.2052 6.90005 12.0611 6.82371C11.917 6.74737 11.7937 6.63692 11.702 6.502L10.297 4.437C10.2053 4.30241 10.0821 4.19224 9.93818 4.11608C9.79423 4.03992 9.63386 4.00007 9.471 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V8.94C21 8.40957 20.7893 7.90086 20.4142 7.52579C20.0391 7.15071 19.5304 6.94 19 6.94V6.94Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
