import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getById} from 'lib/store/reducers/helpers/byId';
import actions from 'store/actions';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import SuperUserSelect from 'components/EditorSidebar/SuperUserSelect';

const getSuperUserOptions = (superUsers, users) =>
    superUsers.map(userId => {
        const user = getById(users, userId);
        return {
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
        };
    });

// eslint-disable-next-line react/prop-types
const EditorSidebarSuperUserSelectWithState = ({canEdit}) => {
    const [error, setError] = useState();
    const {editor} = useEditorState();
    const {saveContent} = useContentSaver();
    const users = useSelector(({users: {byId}}) => byId);
    const {isFetching, ids: superUsers} = useSelector(
        ({users: {superUsers: superUsersFromState}}) => superUsersFromState,
    );

    const dispatch = useDispatch();
    const fetchSuperUsers = () => dispatch(actions.users.fetchSuperUsers());
    const updateAccessibleFor = async accessibleFor => {
        await dispatch(actions.editor.setAccessibleFor(accessibleFor));
        saveContent({...editor, accessibleFor});
    };

    useEffect(() => {
        try {
            fetchSuperUsers();
        } catch (e) {
            setError(e.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canEdit) {
        return null;
    }

    return (
        <SuperUserSelect
            error={error}
            options={getSuperUserOptions(superUsers, users)}
            isFetching={isFetching}
            selectedSuperUsers={editor.accessibleFor}
            onChange={selected => {
                let mapped = [];
                if (selected !== null) {
                    mapped = selected.map(({value}) => value);
                }
                updateAccessibleFor(mapped);
            }}
        />
    );
};

export default EditorSidebarSuperUserSelectWithState;
