import React from 'react';
import {Translate} from 'react-localize-redux';
import Select from 'components/util/Select';
import Form from 'components/util/Form';

const {LabelGroup} = Form;

const TriggerOptions = ({canEdit, value, options, onChange}) => {
    const allOptions =
        options &&
        options.reduce((acc, option) => [...acc, ...option.options], []);
    const selectedOption =
        allOptions &&
        allOptions.find(({value: optionValue}) => optionValue === value);

    return (
        <>
            <LabelGroup label={<Translate id="editor.form.trigger.label" />}>
                <Translate>
                    {({translate}) =>
                        canEdit ? (
                            <Select
                                inputId="default_trigger"
                                value={value}
                                placeholder={translate(
                                    'editor.form.trigger.placeholder',
                                )}
                                options={options}
                                onChange={onChange}
                            />
                        ) : (
                            <div>{selectedOption && selectedOption.label}</div>
                        )
                    }
                </Translate>
            </LabelGroup>
        </>
    );
};

export default TriggerOptions;
