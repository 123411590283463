import React from 'react';
import Icon from 'lib/components/Icon';
import Button from './ButtonHOC';

const ImageButton = props => (
    <Button {...props} type="media" subType="image">
        <Icon name="image" size="26" />
    </Button>
);

export default ImageButton;
