import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle(
    ({theme}) => `
    body {
        background: ${theme.colorBackground};
        color: ${theme.colorText};
    }

    #root > .loader {
        height: 100%;
        display: flex;
    }
`,
);
