import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M16.1069 1.81844C13.6751 1.81844 11.7037 3.78983 11.7037 6.22166C11.7037 8.65349 13.6751 10.6249 16.1069 10.6249C18.5387 10.6249 20.5101 8.65349 20.5101 6.22166C20.5101 3.78983 18.5387 1.81844 16.1069 1.81844ZM10.8853 6.22166C10.8853 3.33782 13.2231 1 16.1069 1C18.9908 1 21.3286 3.33782 21.3286 6.22166C21.3286 9.10551 18.9908 11.4433 16.1069 11.4433C13.2231 11.4433 10.8853 9.10551 10.8853 6.22166Z" />
            <path d="M5.70945 6.71273C4.05508 6.71273 2.71395 8.05386 2.71395 9.70823C2.71395 11.3626 4.05508 12.7037 5.70945 12.7037C7.36382 12.7037 8.70495 11.3626 8.70495 9.70823C8.70495 8.05386 7.36382 6.71273 5.70945 6.71273ZM1.89551 9.70823C1.89551 7.60185 3.60307 5.89429 5.70945 5.89429C7.81583 5.89429 9.52339 7.60185 9.52339 9.70823C9.52339 11.8146 7.81583 13.5222 5.70945 13.5222C3.60307 13.5222 1.89551 11.8146 1.89551 9.70823Z" />
            <path
                fillRule="evenodd"
                d="M11.2122 13.9936C10.9252 13.9932 10.641 14.0494 10.3757 14.159C10.1105 14.2686 9.86943 14.4294 9.66638 14.6322C9.46332 14.835 9.30223 15.0758 9.19232 15.3409C9.08241 15.606 9.02584 15.8902 9.02584 16.1772V22.0765C9.02584 22.0913 9.0317 22.1054 9.04214 22.1159C9.05258 22.1263 9.06673 22.1322 9.08149 22.1322H23.126C23.1407 22.1322 23.1549 22.1263 23.1653 22.1159C23.1758 22.1054 23.1816 22.0913 23.1816 22.0765V16.1837C23.1816 15.6029 22.9509 15.0458 22.5401 14.6351C22.1294 14.2243 21.5723 13.9936 20.9915 13.9936L11.2122 13.9936ZM11.213 13.1751C10.8185 13.1747 10.4278 13.252 10.0632 13.4026C9.69858 13.5532 9.36718 13.7743 9.08802 14.0531C8.80885 14.3319 8.58738 14.663 8.43628 15.0275C8.28517 15.392 8.2074 15.7826 8.2074 16.1772V22.0765C8.2074 22.3083 8.29949 22.5307 8.46341 22.6946C8.62734 22.8585 8.84967 22.9506 9.08149 22.9506H23.126C23.3578 22.9506 23.5801 22.8585 23.744 22.6946C23.908 22.5307 24.0001 22.3083 24.0001 22.0765V16.1837C24.0001 15.3858 23.6831 14.6206 23.1189 14.0563C22.5546 13.4921 21.7894 13.1751 20.9915 13.1751H11.213Z"
            />
            <path d="M4.12315 14.2097L4.12495 14.2097L6.99604 14.2097C7.15087 14.2097 7.29244 14.297 7.36185 14.4354C7.43125 14.5738 7.4166 14.7396 7.324 14.8636C6.8968 15.436 6.66581 16.131 6.66539 16.8453V20.97C6.66539 21.196 6.48218 21.3792 6.25617 21.3792H1.06397C0.781792 21.3792 0.511165 21.2671 0.31163 21.0676C0.112096 20.868 1.91947e-10 20.5974 1.91947e-10 20.3152V18.351C-1.05369e-05 17.2558 0.433813 16.2052 1.20654 15.429C1.97926 14.6529 3.02796 14.2145 4.12315 14.2097ZM4.12587 15.0281C3.24743 15.0322 2.40634 15.3839 1.78653 16.0065C1.16652 16.6292 0.818434 17.4722 0.818442 18.351V20.3152C0.818442 20.3804 0.844311 20.4428 0.890356 20.4889C0.936402 20.5349 0.998854 20.5608 1.06397 20.5608H5.84695V16.845C5.84732 16.2119 5.993 15.5907 6.26861 15.0281H4.12587Z" />
        </g>
    );
}
