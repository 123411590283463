import {schema} from 'normalizr';
import {CALL_API} from '../../constants/api';
import {GET, POST, PUT, DELETE} from '../services';

export const TEMPLATE_SCHEMA = new schema.Entity('template');

export const TEMPLATES_LIST_REQUEST = 'TEMPLATES_LIST_REQUEST';
export const TEMPLATES_LIST_SUCCESS = 'TEMPLATES_LIST_SUCCESS';
export const TEMPLATES_LIST_FAILURE = 'TEMPLATES_LIST_FAILURE';

export const fetchTemplates = ({
    page,
    limit,
    filters = {},
    filters: {query, roger, appDomain} = {},
}) => {
    const queryParams = {
        query,
        page,
        limit,
    };

    if (typeof roger !== 'undefined') {
        queryParams.roger = roger;
    }

    if (typeof appDomain !== 'undefined') {
        queryParams.app_domain = appDomain;
    }

    return {
        [CALL_API]: {
            endpoint: '/templates',
            method: GET,
            query: queryParams,
            filters,
            types: [
                TEMPLATES_LIST_REQUEST,
                TEMPLATES_LIST_SUCCESS,
                TEMPLATES_LIST_FAILURE,
            ],
            schema: [TEMPLATE_SCHEMA],
        },
    };
};

export const TEMPLATES_VIEW_REQUEST = 'TEMPLATES_VIEW_REQUEST';
export const TEMPLATES_VIEW_SUCCESS = 'TEMPLATES_VIEW_SUCCESS';
export const TEMPLATES_VIEW_FAILURE = 'MESSAGE_VIEW_FAILURE';

export const fetchTemplate = templateId => ({
    [CALL_API]: {
        endpoint: `/templates/${templateId}`,
        method: GET,
        types: [
            TEMPLATES_VIEW_REQUEST,
            TEMPLATES_VIEW_SUCCESS,
            TEMPLATES_VIEW_FAILURE,
        ],
        schema: TEMPLATE_SCHEMA,
    },
});

export const TEMPLATES_CREATE_REQUEST = 'TEMPLATES_CREATE_REQUEST';
export const TEMPLATES_CREATE_SUCCESS = 'TEMPLATES_CREATE_SUCCESS';
export const TEMPLATES_CREATE_FAILURE = 'TEMPLATES_CREATE_FAILURE';

export const createTemplate = template => ({
    [CALL_API]: {
        endpoint: '/templates',
        method: POST,
        body: template,
        types: [
            TEMPLATES_CREATE_REQUEST,
            TEMPLATES_CREATE_SUCCESS,
            TEMPLATES_CREATE_FAILURE,
        ],
        schema: TEMPLATE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.template_created',
            },
        },
    },
});

export const TEMPLATES_UPDATE_REQUEST = 'TEMPLATES_UPDATE_REQUEST';
export const TEMPLATES_UPDATE_SUCCESS = 'TEMPLATES_UPDATE_SUCCESS';
export const TEMPLATES_UPDATE_FAILURE = 'TEMPLATES_UPDATE_FAILURE';

export const updateTemplate = template => ({
    [CALL_API]: {
        endpoint: `/templates/${template.id}`,
        method: PUT,
        body: template,
        types: [
            TEMPLATES_UPDATE_REQUEST,
            TEMPLATES_UPDATE_SUCCESS,
            TEMPLATES_UPDATE_FAILURE,
        ],
        schema: TEMPLATE_SCHEMA,
    },
});

export const TEMPLATES_DELETE_REQUEST = 'TEMPLATES_DELETE_REQUEST';
export const TEMPLATES_DELETE_SUCCESS = 'TEMPLATES_DELETE_SUCCESS';
export const TEMPLATES_DELETE_FAILURE = 'TEMPLATES_DELETE_FAILURE';

export const deleteTemplate = template => ({
    [CALL_API]: {
        endpoint: `/templates/${template.id}`,
        method: DELETE,
        body: template,
        types: [
            TEMPLATES_DELETE_REQUEST,
            TEMPLATES_DELETE_SUCCESS,
            TEMPLATES_DELETE_FAILURE,
        ],
        schema: TEMPLATE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.template_deleted',
            },
        },
    },
});

export default {
    fetchTemplates,
    fetchTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
};
