import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M4.36 23.016c-.141 0-.235-.047-.329-.094a.748.748 0 0 1-.375-.656V.703c0-.281.14-.515.375-.656.235-.14.516-.094.75.047l15.797 10.781c.188.14.328.375.328.61 0 .234-.14.468-.328.609L4.781 22.875a.756.756 0 0 1-.422.14zm0 0"
        />
    );
}
