import customBlocks, {ATOMIC} from '../constants/customBlocks';

export const getHeader = blocks => {
    if (!blocks) return undefined;

    const mediaBlock = blocks.find(
        block => block.type === 'atomic' && block.data.type === 'MediaBlock',
    );

    return mediaBlock && mediaBlock.data && mediaBlock.data.mediaItemId;
};

export const getDescription = blocks => {
    if (!blocks) return undefined;

    const firstParagraph = blocks.find(block => block.type === 'unstyled');

    if (firstParagraph) {
        return firstParagraph.text;
    }
    return undefined;
};

export const getSurveys = blocks => {
    if (!blocks) return undefined;

    return blocks.filter(block => {
        if (
            block.type === ATOMIC &&
            block.data.type === customBlocks.SURVEY_BLOCK
        ) {
            return block;
        }
        return false;
    });
};

export default {
    getHeader,
    getDescription,
    getSurveys,
};
