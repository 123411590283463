import React from 'react';

const CheckRoundChecked = () => (
    <path
        d="M12 0a12 12 0 1012 12A12.035 12.035 0 0012 0zm-2 17.414L4.586 12 6 10.586l4 4 8-8L19.414 8 10 17.414z"
        fill="currentColor"
    />
);

export default CheckRoundChecked;
