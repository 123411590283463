const computeMonthlyOnThe = onThe => {
    const repeat = {};

    switch (onThe.which) {
        case 'first':
            repeat.bysetpos = 1;
            break;
        case 'second':
            repeat.bysetpos = 2;
            break;
        case 'third':
            repeat.bysetpos = 3;
            break;
        case 'fourth':
            repeat.bysetpos = 4;
            break;
        case 'last':
            repeat.bysetpos = -1;
            break;
        default:
            break;
    }

    switch (onThe.day) {
        case 'monday':
            repeat.byweekday = [0];
            break;
        case 'tuesday':
            repeat.byweekday = [1];
            break;
        case 'wednesday':
            repeat.byweekday = [2];
            break;
        case 'thursday':
            repeat.byweekday = [3];
            break;
        case 'friday':
            repeat.byweekday = [4];
            break;
        case 'saturday':
            repeat.byweekday = [5];
            break;
        case 'sunday':
            repeat.byweekday = [6];
            break;
        case 'day':
            repeat.byweekday = [0, 1, 2, 3, 4, 5, 6];
            break;
        case 'weekday':
            repeat.byweekday = [0, 1, 2, 3, 4];
            break;
        case 'weekend_day':
            repeat.byweekday = [5, 6];
            break;
        default:
            break;
    }

    return repeat;
};

export default computeMonthlyOnThe;
