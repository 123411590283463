/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';

import Datetime from 'react-datetime';
import Select from 'components/util/Select';
import classnames from 'classnames';
import PhoneInput from 'lib/components/PhoneInput';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';

const DATE_FORMAT = 'DD/MM/YYYY';

const UserDetailBlock = ({
    onChange,
    translatedLabel,
    label,
    value,
    type = 'text',
    editable,
    options,
    isWork,
    isPrivate,
    placeholder,
    multi,
    required,
    id,
}) => {
    const translate = useSelector(state => getTranslate(state.localize));
    const langCode = useSelector(
        state => getActiveLanguage(state.localize).code,
    );
    const errors = useSelector(state => state.formErrors.user);
    const defaultCountry = useSelector(
        state => state.company.data.default_country,
    );

    // Set placeholer
    const parsedPlaceholder = (() => {
        switch (type) {
            case 'email':
                return 'roger@meetroger.io';
            case 'tel':
                return '+32 472 78 90 00';
            default:
                // Use label as placeholder
                return translate(`users.form.${label}.label`);
        }
    })();

    // Set intput field
    const content = (() => {
        if (editable) {
            switch (type) {
                case 'select':
                    return (
                        <Select
                            className="field"
                            inputId={id || label}
                            options={options}
                            value={value}
                            onChange={option => onChange(option)}
                            clearable={false}
                            isMulti={multi}
                        />
                    );
                case 'textarea':
                    return (
                        <textarea
                            className="textarea field"
                            id={id || label}
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            placeholder={placeholder || parsedPlaceholder}
                        />
                    );
                case 'date':
                    return (
                        <Datetime
                            className="field"
                            id={id || label}
                            dateFormat={DATE_FORMAT}
                            timeFormat={false}
                            inputProps={{
                                placeholder: `${format(new Date(), DATE_FORMAT)}`,
                            }}
                            initialValue={value || ''}
                            onChange={date => onChange(date)}
                        />
                    );
                case 'tel':
                    return (
                        <PhoneInput
                            className="field"
                            locale={langCode}
                            id={id || label}
                            tabIndex={0}
                            value={value}
                            defaultCountry={defaultCountry}
                            placeholder={translate('auth.phone_example')}
                            onChange={phone => onChange(phone)}
                        />
                    );
                default:
                    return (
                        <input
                            className="field input"
                            type={type}
                            id={id || label}
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            placeholder={placeholder || parsedPlaceholder}
                            autoComplete="off"
                        />
                    );
            }
        } else {
            switch (type) {
                case 'tel':
                    return (
                        <a className={`type-${type}`} href={`tel:${value}`}>
                            {value}
                        </a>
                    );
                case 'email':
                    return (
                        <a className={`type-${type}`} href={`mailto:${value}`}>
                            {value}
                        </a>
                    );
                default:
                    return <div className={`type-${type}`}>{value}</div>;
            }
        }
    })();

    return (
        <Branding.Consumer>
            {colors => (
                <div
                    className={classnames('form-group user-detail-block', {
                        error: errors[id || label],
                    })}>
                    <label
                        className={classnames('label', {isRequired: required})}
                        htmlFor={id || label}>
                        {translatedLabel ||
                            translate(`users.form.${label}.label`)}{' '}
                        {(isWork || isPrivate) && (
                            <span className="text-muted font-weight-light small">
                                {translate(
                                    `users.form.${isWork ? 'work' : 'private'}`,
                                )}
                            </span>
                        )}
                        {required && <span className="required">*</span>}
                    </label>

                    {content}

                    <style jsx>
                        {`
                            @import '../../../lib/sass/kit';

                            .user-detail-block {
                                margin-bottom: 3rem;

                                .label {
                                    margin-bottom: 1rem;
                                    position: relative;

                                    &.isRequired {
                                        padding-right: 1rem;
                                    }
                                }

                                :global(.type-email) {
                                    word-wrap: break-word;
                                }

                                :global(a) {
                                    display: block;
                                    color: ${colors.brand.base};
                                    transition: $default-transition color;

                                    &:hover {
                                        color: ${colors.brand[600]};
                                    }
                                }
                            }

                            .required {
                                color: ${colors.danger.base};
                                font-size: 2rem;
                                position: absolute;
                                top: 0;
                                right: 0;
                            }

                            .error {
                                .label {
                                    color: ${colors.danger.base};
                                }

                                :global(.Select-control),
                                :global(.field),
                                :global(input) {
                                    border-color: ${colors.danger.base};
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

UserDetailBlock.propTypes = {
    onChange: PropTypes.func.isRequired,
    translatedLabel: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    editable: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.any),
    isWork: PropTypes.bool,
    isPrivate: PropTypes.bool,
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    required: PropTypes.bool,
    id: PropTypes.string,
};
UserDetailBlock.defaultProps = {
    value: '',
    editable: false,
    required: false,
    isWork: false,
    isPrivate: false,
    multi: false,
    placeholder: '',
    id: '',
    type: '',
    translatedLabel: '',
    options: [],
};

export default UserDetailBlock;
