import React from 'react';

const FileAdd = () => (
    <path
        fill="currentColor"
        d="M4,0 L20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M6,6 L6,8.4 L18,8.4 L18,6 L6,6 Z M6,10.8 L6,13.2 L18,13.2 L18,10.8 L6,10.8 Z M6,15.6 L6,18 L18,18 L18,15.6 L6,15.6 Z"
    />
);

export default FileAdd;
