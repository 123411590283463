import React from 'react';

const getPath = (x, y, width, height) => {
    const radius = 4;

    return `M${x},${y}
          L${x + width - radius},${y}
          C${x + width - radius},${y} ${x + width},${y} ${x + width},${y + radius}
          L${x + width},${y + height}
          L${x},${y + height}
          L${x},${y + radius}
          C${x},${y + radius} ${x},${y} ${x + radius},${y}
          Z`;
};

export default props => {
    const {fill, x, y, width, height, fillOpacity} = props;

    return (
        <path
            d={getPath(x, y, width, height)}
            stroke="none"
            fill={fill}
            fillOpacity={fillOpacity}
        />
    );
};
