import * as React from 'react';

const RogerIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        fill="none"
        {...props}>
        <path
            fill="#64748B"
            d="M2.557 8.501c.436.415.472 1.11.056 1.544-.416.434-1.114.47-1.55.056-.437-.415-.473-1.11-.056-1.544a1.123 1.123 0 0 1 1.55-.056ZM14.208.347c-.416.434-.38 1.13.056 1.543.436.414 1.134.378 1.55-.056.417-.434.38-1.129-.055-1.543-.436-.414-1.135-.378-1.55.056Zm9.639 23.619c5.676-5.603 5.493-14.749-.298-20.12a14.021 14.021 0 0 0-4.44-2.786c-.544-.203-1.149.11-1.279.668a.932.932 0 0 0 .593 1.098 12.034 12.034 0 0 1 3.837 2.411c4.995 4.64 5.151 12.543.262 17.385-4.5 4.44-11.765 4.655-16.518.503a11.98 11.98 0 0 1-4.062-10.017c.028-.464-.264-.904-.732-1.012a.948.948 0 0 0-1.167.87 13.893 13.893 0 0 0 4.69 11.554c5.46 4.845 13.895 4.604 19.114-.554Zm-4.93-1.015a1.073 1.073 0 0 0 1.051-1.132 1.074 1.074 0 0 0-1.137-1.046 1.073 1.073 0 0 0-1.05 1.132c.02.593.54 1.066 1.136 1.046Zm-4.826-19.04a10.069 10.069 0 0 0-3.614.621.961.961 0 0 0-.483 1.4.961.961 0 0 0 1.153.383c.908-.341 1.911-.503 2.936-.503 4.227.048 7.786 3.318 8.155 7.503a8.243 8.243 0 0 1-1.408 5.386c-.268.39-.216.932.167 1.236l.052.031c.411.35 1.062.264 1.362-.178a10.085 10.085 0 0 0 1.765-6.5c-.402-5.233-4.814-9.327-10.085-9.38ZM8.24 8.138a.951.951 0 0 0 .16-1.2.972.972 0 0 0-1.477-.181 10.163 10.163 0 0 0-3.111 7.8c.262 5.24 4.565 9.465 9.847 9.639.625.025 1.226-.013 1.816-.094a.965.965 0 0 0 .785-1.173.958.958 0 0 0-1.057-.714c-.288.052-.59.08-.907.086-4.573.167-8.416-3.404-8.563-7.95A8.041 8.041 0 0 1 8.24 8.139Zm11.245 9.135a6.306 6.306 0 0 0-.722-7.425.96.96 0 0 0-1.562.191.943.943 0 0 0 .127 1.069c1.265 1.428 1.532 3.59.44 5.32-1.186 1.886-3.657 2.594-5.664 1.642a4.387 4.387 0 0 1-2.208-2.292.951.951 0 0 0-.91-.582.938.938 0 0 0-.838 1.318 6.338 6.338 0 0 0 3.181 3.281c2.928 1.399 6.5.277 8.156-2.522Zm-5.798-9.08a1.086 1.086 0 0 0 .465 1.48c.545.282 1.204.08 1.487-.464a1.086 1.086 0 0 0-.464-1.48 1.112 1.112 0 0 0-1.488.463ZM7.874 11.84a1.086 1.086 0 0 0 .464 1.48c.546.283 1.204.08 1.488-.462a1.086 1.086 0 0 0-.465-1.48c-.55-.264-1.208-.062-1.487.462Z"
        />
    </svg>
);
export default RogerIcon;
