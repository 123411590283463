import {List} from 'immutable';
import {EditorState, ContentBlock, genKey, convertToRaw} from 'draft-js';
import {ATOMIC} from 'lib/constants/customBlocks';

export const normalizeSelectedIndex = (selectedIndex, max) => {
    let index = selectedIndex % max;
    if (index < 0) {
        index += max;
    }
    return index;
};

export const hasEntityAtSelection = editorState => {
    const selection = editorState.getSelection();
    if (!selection.getHasFocus()) {
        return false;
    }

    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selection.getStartKey());
    return !!block.getEntityAt(selection.getStartOffset() - 1);
};

export const getTypeaheadRange = editorState => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) {
        return null;
    }

    if (hasEntityAtSelection(editorState)) {
        return null;
    }

    const range = selection.getRangeAt(0);
    let text = range.startContainer.textContent;

    // Remove text that appears after the cursor..
    text = text.substring(0, range.startOffset);

    // ..and before the typeahead token.
    const index = text.lastIndexOf('@');
    if (index === -1) {
        return null;
    }
    text = text.substring(index);

    return {
        text,
        start: index,
        end: range.startOffset,
    };
};

export const insertNewEmptyBlockAfterBlockIfNeeded = (
    contentState,
    block,
    selection,
) => {
    const blockMap = contentState.getBlockMap();
    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(v => v === block);
    const blocksAfter = blockMap
        .toSeq()
        .skipUntil(v => v === block)
        .rest();
    const hasNoBlocksAfter = blocksAfter.count() === 0;

    const firstBlockAfter = blocksAfter.toSeq().first();
    const firstBlockAfterIsUnstyled =
        firstBlockAfter && firstBlockAfter.getType() !== 'unstyled';

    if (firstBlockAfterIsUnstyled || hasNoBlocksAfter) {
        const newBlockKey = genKey();
        const newBlocks = [
            [block.getKey(), block],
            [
                newBlockKey,
                new ContentBlock({
                    key: newBlockKey,
                    type: 'unstyled',
                    text: '',
                    characterList: List(),
                }),
            ],
        ];
        const newBlockMap = blocksBefore
            .concat(newBlocks, blocksAfter)
            .toOrderedMap();
        return contentState.merge({
            blockMap: newBlockMap,
            selectionBefore: selection,
            selectionAfter: selection,
        });
    }
    return contentState;
};

export const cleanUpAtomicBlocksWithoutNewLine = editorState => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const {blocks} = convertToRaw(contentState);
    const newContentState2 = blocks.reduce((acc, block) => {
        const currentBlock2 = contentState.getBlockForKey(block.key);
        if (currentBlock2.getType() === ATOMIC) {
            return insertNewEmptyBlockAfterBlockIfNeeded(
                acc,
                currentBlock2,
                selection,
            );
        }
        return acc;
    }, contentState);

    return EditorState.push(editorState, newContentState2, 'insert-fragment');
};
