import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import useFormState, {createFormValidation} from 'use-form-state';
import {Translate} from 'react-localize-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import actions from 'store/actions';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import useRoles from 'components/hooks/useRoles';
import ContentView from 'components/util/ContentView';
import RogerLogo from 'assets/logo.svg';
import Button from 'components/util/Button';
import ColorPicker from 'components/util/ColorPicker';
import ErrorMessage from 'components/util/ErrorMessage';
import Loading from 'lib/components/Loading';
import getUrlBySize, {REGULAR} from 'lib/helpers/sizeHelper';
import Icon from 'lib/components/Icon';
import RogerIcon from 'lib/icons/RogerIcon';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import Image from './Image';
import AdminInboxConfigSettings from './AdminInboxConfigSettings';
import {
    InputContainer,
    Label,
    TimeZone,
    Container,
    GroupTop,
    GroupTitle,
    Banner,
    Logo,
    GroupDescription,
    SettingsGroup,
    SettingsInputGroup,
} from './settings.styled';

const validation = createFormValidation([
    {
        path: 'brandingColor',
        validate: value =>
            value !== null &&
            value !== '' &&
            value.length === 7 &&
            value[0] === '#',
        message: <Translate id="Please use a valid color." />,
    },
    {
        path: 'timezone',
        validate: value => value !== null,
        message: <Translate id="Please select a timezone." />,
    },
]);

const Settings = () => {
    const history = useHistory();
    const location = useLocation();
    const company = useSelector(state => state.company.data);
    const timezones = useSelector(state => state.timezones);
    const {settings, max_file_upload: maxFileUpload} = company;

    const dispatch = useDispatch();

    const showFailureByKey = (key, params) =>
        dispatch(actions.notifications.showFailureByKey(key, params));
    const showSuccess = text =>
        dispatch(actions.notifications.showSuccess(text));
    const updateCompany = newData =>
        dispatch(actions.company.updateCompany(newData));
    const fetchTimezones = useCallback(
        () => dispatch(actions.timezones.fetchTimezones()),
        [dispatch],
    );

    const {isSuperAdmin} = useRoles();
    const [adminInbox, setAdminInbox] = useState({});
    const uploadImage = formData =>
        dispatch(actions.media.internal.uploadNewMediaItem(formData));
    const hasAdminInboxEnabled = useSelector(
        state => state.company.data?.settings?.admin_inbox?.enabled,
    );
    const formState = useFormState(
        {
            brandingColor: settings.branding_color,
            timezone: settings.timezone,
            logo: settings.logo,
            banner: settings.banner,
        },
        {validation},
    );
    const {values, handleChange} = formState;

    const [isSaving, setIsSaving] = useState(false);
    const [skipBlock, setSkipBlock] = useState(false);
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const timezonesOptions = timezones.data;
    const makeNewSettingsPayload = () => {
        const {timezone, brandingColor, logo, banner} = values;
        return {
            settings: {
                timezone,
                branding_color: brandingColor,
                ...(!isEmpty(adminInbox) && {admin_inbox: adminInbox}),
            },
            logo_file:
                logo !== null && typeof logo === 'object' ? logo.id : null,
            banner_file:
                banner !== null && typeof banner === 'object'
                    ? banner.id
                    : null,
        };
    };
    const onSave = async () => {
        if (formState.validate()) {
            setIsSaving(true);
            try {
                await updateCompany(makeNewSettingsPayload());
                showSuccess(<Translate id="Settings saved successfully" />);
            } catch ({message}) {
                showFailureByKey('notifications.general_error', {
                    error: message || '',
                });
            }
            setIsSaving(false);
        }
    };

    const handleImage = async (file, type) => {
        if (file.size >= maxFileUpload) {
            showFailureByKey('notifications.file_too_big');
            return null;
        }

        const formData = new FormData();
        formData.append('media_file', file);
        formData.append('media_type', type);
        const {
            payload: {data, ids},
        } = await uploadImage(formData);
        return data[ids];
    };

    const onBannerChange = async file => {
        setIsUploadingBanner(true);
        try {
            const banner = await handleImage(file, 'banner');
            if (banner !== null) {
                handleChange('banner', banner);
            }
        } catch ({message}) {
            handleChange('banner', null);
            showFailureByKey('notifications.general_error', {
                error: message || '',
            });
        }
        setIsUploadingBanner(false);
    };

    const onLogoChange = async file => {
        setIsUploadingLogo(true);
        try {
            const logo = await handleImage(file, 'logo');
            if (logo !== null) {
                handleChange('logo', logo);
            }
        } catch ({message}) {
            handleChange('logo', null);
            showFailureByKey('notifications.general_error', {
                error: message || '',
            });
        }
        setIsUploadingLogo(false);
    };

    useEffect(() => {
        fetchTimezones();
    }, [fetchTimezones]);

    useEffect(() => {
        const setModalVisibility = (modal, visibility) =>
            dispatch(actions.modals.setModalVisibility(modal, visibility));
        const setModalData = data =>
            dispatch(actions.modals.setConfirmModalData(data));
        // Function to open modal
        const openModal = newPath => {
            setModalVisibility(MODAL_CONFIRM, true);
            setModalData({
                title: <Translate id="global.onAbandonChangedForm" />,
                buttonActions: [
                    {
                        label: <Translate id="global.understood_continue" />,
                        callBack: async () => {
                            await setSkipBlock(true);

                            setTimeout(() => history.push(newPath));
                            setModalVisibility(MODAL_CONFIRM, false);
                        },
                    },
                ],
            });
        };

        const unblock = history.block(newLocation => {
            const snakeCasedObj = omitBy(
                mapKeys(values, (value, key) => snakeCase(key)),
                isNil,
            );
            const existingSettings = omitBy(
                pick(settings, Object.keys(snakeCasedObj)),
                isNil,
            );
            if (
                newLocation.pathname !== location.pathname &&
                !isEqual(snakeCasedObj, existingSettings) &&
                !skipBlock
            ) {
                openModal(newLocation.pathname);
                return false;
            }
            return true;
        });
        return () => {
            unblock();
        };
    }, [dispatch, history, location, settings, values, skipBlock]);

    return (
        <ContentView
            title={<Translate id="global.settings" />}
            wrapHasOverflow
            noContentPadding
            headerComponents={
                <Button
                    disabled={isUploadingBanner || isUploadingLogo}
                    isLoading={isSaving}
                    className="button success"
                    onClick={onSave}>
                    <Translate id="global.save" />
                </Button>
            }>
            <Container>
                <SettingsGroup>
                    <GroupTop>
                        <GroupTitle>
                            <RogerIcon />
                            <Translate id="global.branding" />
                        </GroupTitle>
                        <GroupDescription>
                            <Translate id="This information will be displayed publicly so be careful what you share." />
                        </GroupDescription>
                    </GroupTop>
                    <SettingsInputGroup>
                        <Label>
                            <Translate id="company.color.base" />
                        </Label>
                        <InputContainer>
                            <ColorPicker
                                color={values.brandingColor}
                                onChange={value =>
                                    handleChange('brandingColor', value)
                                }
                            />
                            <ErrorMessage
                                error={
                                    formState?.getErrorMessages(
                                        'brandingColor',
                                    )[0]
                                }
                            />
                        </InputContainer>
                    </SettingsInputGroup>

                    <SettingsInputGroup>
                        <Label>
                            <Translate id="company.logo" />
                        </Label>
                        <Logo>
                            {isUploadingLogo ? (
                                <Loading size={80} />
                            ) : (
                                <Image
                                    image={getUrlBySize(values.logo, REGULAR)}
                                    defaultImage={RogerLogo}
                                    onChange={onLogoChange}
                                />
                            )}
                        </Logo>
                    </SettingsInputGroup>

                    <SettingsInputGroup>
                        <Label>
                            <Translate id="company.banner" />
                        </Label>
                        <Banner>
                            {isUploadingBanner ? (
                                <Loading size={80} />
                            ) : (
                                <Image
                                    image={getUrlBySize(values.banner, REGULAR)}
                                    onChange={onBannerChange}
                                    noDefaultText={
                                        <Translate id="company.noBannerDefault" />
                                    }
                                />
                            )}
                        </Banner>
                    </SettingsInputGroup>
                </SettingsGroup>
                <SettingsGroup>
                    <GroupTop>
                        <GroupTitle>
                            <Icon name="clock" />
                            <Translate id="Date & Time" />
                        </GroupTitle>
                        <GroupDescription>
                            <Translate id="When you schedule a message we'll use the company timezone as a reference." />
                            <Translate id="For example when sending out an automatic or scheduled message." />
                        </GroupDescription>
                    </GroupTop>
                    <SettingsInputGroup>
                        <Label>
                            <Translate id="Timezone" />
                        </Label>
                        <InputContainer>
                            {timezones.isFetching ? (
                                <Loading size={30} />
                            ) : (
                                <>
                                    <TimeZone
                                        clearable={false}
                                        value={values.timezone}
                                        options={timezonesOptions}
                                        onChange={({value}) =>
                                            handleChange('timezone', value)
                                        }
                                    />
                                    <ErrorMessage
                                        error={
                                            formState?.getErrorMessages(
                                                'timezone',
                                            )[0]
                                        }
                                    />
                                </>
                            )}
                        </InputContainer>
                    </SettingsInputGroup>
                </SettingsGroup>

                {hasAdminInboxEnabled && isSuperAdmin && (
                    <AdminInboxConfigSettings onChange={setAdminInbox} />
                )}
            </Container>
        </ContentView>
    );
};

export default Settings;
