import {getById} from 'lib/store/reducers/helpers/byId';
import {CONTENT_TYPE_AUTOMATED_MESSAGES} from 'lib/store/actions/editor';
import {store} from 'store';
import actions from 'store/actions';
import {editorBodyToRaw} from './editorBody';

const buildAutomatedMessageFromCurrentEditorState = editor => {
    const {
        contentId,
        contentType,
        title,
        body,
        appDomain,
        audienceFilters,
        visibility,
        trigger,
        delay,
        sendReminders,
        accessibleFor,
        enableComments,
        availableLanguages,
    } = editor;

    const message = {
        id: contentType === CONTENT_TYPE_AUTOMATED_MESSAGES ? contentId : null,
        title,
        app_domain: appDomain.slug,
        audience_filters: audienceFilters,
        body: editorBodyToRaw(body),
        send_reminders: sendReminders,
        visibility,
        trigger,
        delay,
        accessible_for: accessibleFor,
        enable_comments: enableComments,
        available_languages: availableLanguages,
    };

    return message;
};

export const saveAutomatedMessage = async (
    editor,
    history,
    redirectOnCreate = false,
    dispatch,
) => {
    const message = buildAutomatedMessageFromCurrentEditorState(editor);
    let payload = null;
    let shouldRedirectToMessage = false;
    if (!message.id) {
        if (redirectOnCreate) {
            shouldRedirectToMessage = true;
        }
        ({payload} = await dispatch(
            actions.automatedMessages.createAutomatedMessage(message),
        ));
    } else {
        ({payload} = await dispatch(
            actions.automatedMessages.updateAutomatedMessage(message),
        ));
    }

    if (payload.ids && payload.ids.length) {
        const messageId = payload.ids;
        dispatch(
            actions.editor.loadAutomatedMessageIntoEditor(
                payload.data[messageId],
            ),
        );
        if (shouldRedirectToMessage) {
            history.push(`/editor/automated-messages/${messageId}`);
        }
    }
    if (payload !== null) {
        message.id = payload.ids;
    }
    return message;
};

const getAutomatedMessageFromStore = messageId => {
    const {byId} = store.getState().automatedMessages;
    return getById(byId, messageId);
};

export const prepareAutomatedMessageForEditor = async (messageId, dispatch) => {
    let automatedMessage = getAutomatedMessageFromStore(messageId);
    if (!automatedMessage) {
        const {payload} = await dispatch(
            actions.automatedMessages.fetchAutomatedMessage(messageId),
        );
        automatedMessage = payload.data[messageId];
    }
    return getAutomatedMessageFromStore(messageId);
};
