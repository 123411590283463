import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {DAYS} from 'util/constants';
import Select from 'components/util/Select';

const RepeatMonthlyOnThe = ({
    mode,
    onThe,
    hasMoreModes,
    handleChange,
    translate,
}) => {
    const isActive = mode === 'on the';

    return (
        <div
            className={`form-group react-rule__flex ${!isActive ? 'opacity-50' : ''}`}>
            <div className="react-rule__col">
                {hasMoreModes && (
                    <input
                        type="radio"
                        name="repeat.monthly.mode"
                        aria-label="Repeat monthly on the"
                        value="on the"
                        checked={isActive}
                        onChange={handleChange}
                    />
                )}
            </div>
            <div className="react-rule__col noWrap">
                {translate('editor.recurrence.on_the')}
            </div>

            <div className="react-rule__col fullWidth">
                <Select
                    name="repeat.monthly.onThe.which"
                    aria-label="Repeat monthly on the which"
                    value={onThe.which}
                    isDisabled={!isActive}
                    onChange={handleChange}
                    options={[
                        {
                            value: 'first',
                            label: translate('editor.recurrence.first'),
                        },
                        {
                            value: 'second',
                            label: translate('editor.recurrence.second'),
                        },
                        {
                            value: 'third',
                            label: translate('editor.recurrence.third'),
                        },
                        {
                            value: 'fourth',
                            label: translate('editor.recurrence.fourth'),
                        },
                        {
                            value: 'last',
                            label: translate('editor.recurrence.last'),
                        },
                    ]}
                />
            </div>

            <div className="react-rule__col fullWidth">
                <Select
                    name="repeat.monthly.onThe.day"
                    aria-label="Repeat monthly on the day"
                    value={onThe.day}
                    isDisabled={!isActive}
                    onChange={handleChange}
                    options={DAYS.map(day => ({
                        value: day,
                        label: translate(`editor.recurrence.${day}`),
                    }))}
                />
            </div>
        </div>
    );
};
RepeatMonthlyOnThe.propTypes = {
    mode: PropTypes.oneOf(['on', 'on the']).isRequired,
    onThe: PropTypes.shape({
        which: PropTypes.oneOf(['first', 'second', 'third', 'fourth', 'last'])
            .isRequired,
        day: PropTypes.oneOf(DAYS).isRequired,
    }).isRequired,
    hasMoreModes: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatMonthlyOnThe);
