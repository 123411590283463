import React, {useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';

import ContentView from 'components/util/ContentView';
import {Translate} from 'react-localize-redux';
import Table from 'components/Table/Table';
import {useParams, useHistory} from 'react-router-dom';
import Modal from 'modals/Modal/Modal';
import useTranslate from 'components/hooks/useTranslate';
import {startCase, capitalize} from 'lodash';
import useMountEffect from 'components/hooks/useMountEffect';
import Loading from 'lib/components/Loading';
import {useAdminInbox} from './adminInbox.data';
import {
    Wrapper,
    AdminInboxTextCellWrapper,
    AnonymousSpan,
    ModalBody,
    ModalContentWrapper,
    ModalTitle,
    UserDetailRow,
    TextCellWrapper,
    DetailSmall,
} from './AdminInbox.styled';
import IconForAdminInboxMessageType from './components/IconForAdminInboxMessageType';
import Attachments from './components/Attachments';

const AdminInboxPage = () => {
    const adminInbox = useAdminInbox();
    const params = useParams();
    const [activeMessage, setActiveMessage] = useState();
    const {translate} = useTranslate();
    const history = useHistory();
    useMountEffect(() => {
        adminInbox.getData();
    });
    useEffect(() => {
        if (params.inboxMessageId) {
            setActiveMessage(
                adminInbox.data.find(
                    message => message.uuid === params.inboxMessageId,
                ),
            );
            adminInbox.getMessageDetails(params.inboxMessageId).then(result => {
                if (result && result.payload) {
                    setActiveMessage(result.payload.data);
                }
            });
        } else {
            setActiveMessage(null);
        }
    }, [adminInbox, params.inboxMessageId]);

    const columns = [
        {
            label: '',
            width: '4rem',
            renderer: IconForAdminInboxMessageType,
        },
        {
            renderer: (content, data) => (
                <AdminInboxTextCellWrapper>
                    <h4>{content.title}</h4>
                    <span>{content.body}</span>
                </AdminInboxTextCellWrapper>
            ),
            grow: true,
            label: translate('global.message', null, {
                onMissingTranslation: () => 'Message',
            }),
            width: '100%',
        },
        {
            renderer: (content, data) => <small>{content || ''}</small>,
            label: translate('global.category', null, {
                onMissingTranslation: () => 'Category',
            }),
            width: '10rem',
        },
        {
            renderer: (content, data) => {
                if (content === null) {
                    return (
                        <AnonymousSpan>
                            <Translate
                                id="global.anonymous"
                                options={{
                                    onMissingTranslation: () => 'Anonymous',
                                }}
                            />
                        </AnonymousSpan>
                    );
                }
                return (
                    <TextCellWrapper>
                        <span>
                            {content.first_name} {content.last_name}
                        </span>
                    </TextCellWrapper>
                );
            },
            grow: true,
            label: translate('global.user', null, {
                onMissingTranslation: () => 'User',
            }),
            width: '15rem',
        },
        {
            renderer: (content, data) => format(content, 'DD/MM/YY'),
            grow: true,
            label: translate('global.date', null, {
                onMissingTranslation: () => 'Date',
            }),
            width: '10rem',
        },
    ];
    const tableRows = useMemo(
        () =>
            adminInbox?.data?.map(message => ({
                data: [
                    // Message to render the icon
                    {data: message, content: message},
                    {
                        data: message,
                        content: message,
                    },
                    {data: message, content: message.sub_type},
                    {data: message, content: message.created_by},
                    {data: message, content: message.created_at},
                ],
                onClick: () => {
                    history.push(
                        `${history.location.pathname.replace(/\/$/, '')}/${message.uuid}`,
                    );
                },
                className: 'tableRow',
            })),
        [adminInbox.data, history],
    );
    return (
        <ContentView
            title={
                <Translate
                    id="global.admin_inbox"
                    options={{onMissingTranslation: () => 'Admin Inbox'}}
                />
            }>
            <Wrapper>
                {adminInbox.loading ? (
                    <Loading loading size={30} />
                ) : (
                    <Table
                        rows={tableRows}
                        useFixed
                        onTableEnd={adminInbox.getData}
                        columns={columns}
                        emptyTitle={translate('global.no_messages', null, {
                            onMissingTranslation: () => 'No messages',
                        })}
                    />
                )}
            </Wrapper>

            {!!activeMessage && (
                <Modal
                    visible={activeMessage}
                    onClose={() => {
                        history.push(
                            history.location.pathname.replace(
                                activeMessage.uuid,
                                '',
                            ),
                        );
                    }}
                    icon={activeMessage.type}
                    title={translate(activeMessage.type, null, {
                        onMissingTranslation: () =>
                            capitalize(startCase(activeMessage.type)),
                    })}>
                    <ModalContentWrapper>
                        <UserDetailRow>
                            <ModalTitle>{activeMessage.title} </ModalTitle>
                            {activeMessage.sub_type && (
                                <DetailSmall>
                                    {activeMessage.sub_type}
                                </DetailSmall>
                            )}
                        </UserDetailRow>
                        <UserDetailRow>
                            {activeMessage.created_by !== null && (
                                <p>
                                    {activeMessage.created_by.first_name}{' '}
                                    {activeMessage.created_by.last_name}
                                </p>
                            )}
                            <p>
                                {format(activeMessage.created_at, 'DD/MM/YY')}
                            </p>
                        </UserDetailRow>
                        <ModalBody>{activeMessage.body}</ModalBody>
                        <Attachments files={activeMessage.files} />
                    </ModalContentWrapper>
                </Modal>
            )}
        </ContentView>
    );
};

export default AdminInboxPage;
