import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M21.188 1.453c-1.079 0-1.97.89-1.97 2.016v.937L3.985 9.375c-.093-.797-.75-1.453-1.546-1.453A1.58 1.58 0 0 0 .843 9.516v4.453c0 .89.703 1.594 1.594 1.594.796 0 1.453-.657 1.546-1.454l1.22.422c-.141.14-.282.375-.376.61-.094.421-.094.843.094 1.265.187.375.516.657.937.797l5.579 1.832c.14.094.328.094.468.094.703 0 1.266-.426 1.5-1.125.047-.145.047-.52 0-.8l5.813 1.874v.938c0 1.125.89 2.02 1.968 2.02 1.079 0 1.97-.895 1.97-2.02V3.469c0-1.125-.891-2.016-1.97-2.016zM12.75 17.531c-.094.375-.563.61-.938.469l-5.578-1.781c-.187-.047-.328-.188-.468-.375a.914.914 0 0 1-.047-.61.901.901 0 0 1 .703-.515l5.906 1.875c.14.093.328.187.375.328.094.187.14.375.047.61zm0 0"
        />
    );
}
