import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M11.9989 1.79168C11.7556 1.79168 11.5168 1.85703 11.3073 1.9809C11.0979 2.10477 10.9256 2.28261 10.8084 2.49585C10.8069 2.49859 10.8054 2.50131 10.8038 2.50401C9.30274 5.08809 7.79958 7.67218 6.29431 10.2563C4.54619 13.269 2.79492 16.2797 1.04047 19.2882C1.03716 19.2939 1.03371 19.2994 1.03013 19.3049C0.916988 19.4785 0.84183 19.674 0.809585 19.8787C0.77734 20.0833 0.788737 20.2925 0.843028 20.4924L0.843295 20.4934C1.00492 21.0947 1.50043 21.467 2.25972 21.467H11.9713L21.6487 21.448H21.6495H21.6502H21.9895C22.1661 21.4339 22.3382 21.3846 22.4955 21.3028C22.6566 21.219 22.7987 21.1029 22.913 20.9618C23.0274 20.8208 23.1114 20.6577 23.16 20.4827C23.2071 20.3133 23.2199 20.1362 23.1978 19.9619C23.1192 19.6335 22.9862 19.3205 22.8043 19.036C22.8013 19.0312 22.7984 19.0264 22.7956 19.0215C19.5972 13.5137 16.3968 8.00792 13.1943 2.50427C13.1926 2.50148 13.191 2.49867 13.1895 2.49585C13.0723 2.28261 12.9 2.10477 12.6905 1.9809C12.4811 1.85703 12.2423 1.79168 11.9989 1.79168ZM10.9043 1.29949C11.2358 1.10343 11.6138 1 11.9989 1C12.3841 1 12.7621 1.10343 13.0936 1.29949C13.4236 1.49468 13.6954 1.77456 13.8808 2.11009C17.0814 7.61026 20.2798 13.1125 23.4761 18.6169C23.7073 18.9805 23.8753 19.3805 23.9731 19.8002C23.9757 19.8117 23.9779 19.8233 23.9796 19.835C24.0198 20.1224 24.0005 20.415 23.9228 20.6946C23.8452 20.9742 23.7108 21.2348 23.5281 21.4603C23.3454 21.6857 23.1183 21.8712 22.8608 22.0051C22.6034 22.139 22.3212 22.2185 22.0317 22.2387C22.0225 22.2393 22.0134 22.2397 22.0042 22.2397H21.6495L11.9728 22.2587H2.25972C1.20148 22.2587 0.348072 21.7001 0.0788811 20.6994C-0.0045445 20.3919 -0.0220387 20.0702 0.0275538 19.7554C0.0766374 19.4439 0.190339 19.1462 0.361307 18.8813C2.11394 15.8758 3.86341 12.8683 5.60973 9.85864L5.61006 9.85806C7.11448 7.27544 8.61679 4.69281 10.117 2.11019C10.3024 1.77462 10.5743 1.4947 10.9043 1.29949Z" />
            <path d="M12.2985 17.8955C12.2985 18.0604 12.1649 18.194 12 18.194C11.8351 18.194 11.7015 18.0604 11.7015 17.8955C11.7015 17.7307 11.8351 17.597 12 17.597C12.1649 17.597 12.2985 17.7307 12.2985 17.8955Z" />
            <path d="M12.0037 8.23645C12.2223 8.23645 12.3996 8.41367 12.3996 8.63229V15.5231C12.3996 15.7417 12.2223 15.9189 12.0037 15.9189C11.7851 15.9189 11.6079 15.7417 11.6079 15.5231V8.63229C11.6079 8.41367 11.7851 8.23645 12.0037 8.23645Z" />
        </g>
    );
}
