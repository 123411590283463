import React from 'react';
import PropTypes from 'prop-types';
import AnonymousIcon from 'lib/icons/AnonymousIcon';
import IdeaIcon from 'lib/icons/IdeaIcon';
import RogerIcon from 'lib/icons/RogerIcon';

const IconForAdminInboxMessageType = ({type}) => {
    if (type === 'whistle_blower') {
        return <AnonymousIcon title="Anonymous message" />;
    }
    if (type === 'idea_box') {
        return <IdeaIcon />;
    }
    return <RogerIcon />;
};
IconForAdminInboxMessageType.propTypes = {
    type: PropTypes.string.isRequired,
};
export default IconForAdminInboxMessageType;
