import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import * as routes from 'routes/index';
import {isSuperUser} from 'util/getUserRoleHelper';

const AuthorizedPage = ({isAuthenticated, userRole, children, location}) => {
    if (!isAuthenticated) {
        return <Redirect to={routes.login()} />;
    }

    if (!userRole) {
        return null;
    }

    if (isSuperUser(userRole) && location.pathname === routes.home()) {
        return <Redirect to={routes.drafts()} />;
    }

    return children;
};

export default withRouter(AuthorizedPage);
