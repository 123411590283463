import React from 'react';
import List from 'components/List/List';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import PropTypes from 'prop-types';

const columns = [
    {
        key: 1,
        renderer: content => (
            <div
                className="avatar --small"
                style={{backgroundImage: `url(${content})`}}
            />
        ),
    },
    {key: 2},
    {key: 3, grow: true},
];

const UsersLeaderboard = ({userActivityDetail}) => {
    const rows = userActivityDetail.map(
        ({avatar, first_name: firstName, last_name: lastName}, userIndex) => ({
            key: userIndex,
            data: [
                getUrlBySize(avatar, THUMB),
                `${userIndex + 1}.`,
                `${firstName} ${lastName}`,
            ].map((content, key) => ({key, content})),
        }),
    );
    return (
        <List
            rows={rows}
            columns={columns}
            active={0}
            widths={['3rem', '2rem', '100%']}
        />
    );
};
UsersLeaderboard.propTypes = {
    userActivityDetail: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
        }),
    ).isRequired,
};

export default UsersLeaderboard;
