import React from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'components/util/Select';
import RepeatYearly from './RepeatYearly';
import RepeatMonthly from './RepeatMonthly';
import RepeatWeekly from './RepeatWeekly';
import RepeatDaily from './RepeatDaily';
import RepeatHourly from './RepeatHourly';

const Repeat = ({
    repeat: {frequency, yearly, monthly, weekly, daily, hourly},
    handleChange,
    translate,
}) => {
    const isOptionSelected = option => frequency === option;

    return (
        <>
            <div className="react-rule__block">
                <div className="form-group">
                    <label htmlFor="Repeat frequency" className="label">
                        {translate('editor.recurrence.repeat')}
                    </label>
                    <Select
                        name="repeat.frequency"
                        id="Repeat frequency"
                        value={frequency}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'Yearly',
                                label: translate('editor.recurrence.yearly'),
                            },
                            {
                                value: 'Monthly',
                                label: translate('editor.recurrence.monthly'),
                            },
                            {
                                value: 'Weekly',
                                label: translate('editor.recurrence.weekly'),
                            },
                            {
                                value: 'Daily',
                                label: translate('editor.recurrence.daily'),
                            },
                            {
                                value: 'Hourly',
                                label: translate('editor.recurrence.hourly'),
                            },
                        ]}
                    />
                </div>
            </div>

            <div className="react-rule__block">
                {isOptionSelected('Yearly') && (
                    <RepeatYearly yearly={yearly} handleChange={handleChange} />
                )}
                {isOptionSelected('Monthly') && (
                    <RepeatMonthly
                        monthly={monthly}
                        handleChange={handleChange}
                    />
                )}
                {isOptionSelected('Weekly') && (
                    <RepeatWeekly weekly={weekly} handleChange={handleChange} />
                )}
                {isOptionSelected('Daily') && (
                    <RepeatDaily daily={daily} handleChange={handleChange} />
                )}
                {isOptionSelected('Hourly') && (
                    <RepeatHourly hourly={hourly} handleChange={handleChange} />
                )}
            </div>
        </>
    );
};

Repeat.propTypes = {
    repeat: PropTypes.shape({
        frequency: PropTypes.oneOf([
            'Yearly',
            'Monthly',
            'Weekly',
            'Daily',
            'Hourly',
        ]).isRequired,
        yearly: PropTypes.object.isRequired,
        monthly: PropTypes.object.isRequired,
        weekly: PropTypes.object.isRequired,
        daily: PropTypes.object.isRequired,
        hourly: PropTypes.object.isRequired,
        options: PropTypes.shape({
            frequency: PropTypes.arrayOf(
                PropTypes.oneOf([
                    'Yearly',
                    'Monthly',
                    'Weekly',
                    'Daily',
                    'Hourly',
                ]),
            ),
            yearly: PropTypes.oneOf(['on', 'on the']),
            monthly: PropTypes.oneOf(['on', 'on the']),
        }).isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Repeat);
