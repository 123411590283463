import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from './Loading';
import * as theme from '../styles/theme';
import * as media from '../styles/media';
import {BrandingContext as Branding} from './providers/BrandingProvider';
import Icon from './Icon';

const Button = ({
    children,
    className,
    isInActive,
    small,
    transparent,
    noBorder,
    success,
    danger,
    warning,
    disabled,
    onClick,
    ghost,
    isLoading,
    icon,
    type,
    ...props
}) => (
    <Branding.Consumer>
        {colors => (
            <button
                type="button"
                className={classNames(
                    'button',
                    {
                        isInActive,
                        small,
                        transparent,
                        noBorder,
                        success,
                        danger,
                        warning,
                        disabled,
                        ghost,
                        isLoading,
                    },
                    type,
                    className,
                )}
                onClick={() => {
                    if (!disabled && typeof onClick === 'function') onClick();
                }}
                {...props}>
                {isLoading && (
                    <div className="loading">
                        <Loading color={colors.text.light} />
                    </div>
                )}

                {typeof icon !== 'undefined' && (
                    <div className="icon">
                        <Icon name={icon} />
                    </div>
                )}

                {children}

                <style jsx>
                    {`
                        .button {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            border: 0;
                            border-radius: 0.4rem;
                            padding: 0.95rem 1rem;
                            background-color: ${colors.brand.base};
                            cursor: pointer;
                            transition-property: background, opacity, border,
                                padding;
                            transition-duration: 0.4s;
                            font-size: 1.6rem;
                            line-height: 1;
                            text-transform: uppercase;
                            text-align: center;
                            color: ${colors.text.light};
                            font-weight: ${theme.fwBold};
                            white-space: pre-wrap;

                            @media (${media.forMediumAndUp}) {
                                &:hover,
                                &:focus {
                                    background-color: ${colors.brand[600]};
                                    text-decoration: none;
                                    color: ${colors.text.light};
                                    outline: none;
                                }
                            }
                        }

                        .isInActive {
                            opacity: 0.5;
                        }

                        .isLoading {
                            padding-right: 3.5rem;
                        }

                        .small {
                            font-size: 1.4rem;
                            padding: 0.5rem 0.7rem;
                        }

                        .transparent {
                            background-color: transparent;
                            color: ${colors.brand.base};
                        }

                        .noBorder {
                            border: none;
                        }

                        .button.danger {
                            background-color: ${colors.danger.base};

                            @media (${media.forMediumAndUp}) {
                                &:hover,
                                &:focus {
                                    background-color: ${colors.danger.dark};
                                }
                            }
                        }

                        .button.success {
                            background-color: ${colors.success.base};

                            @media (${media.forMediumAndUp}) {
                                &:hover,
                                &:focus {
                                    background-color: ${colors.success.dark};
                                }
                            }
                        }

                        .button.warning {
                            background-color: ${colors.warning.base};

                            @media (${media.forMediumAndUp}) {
                                &:hover,
                                &:focus {
                                    background-color: ${colors.warning.dark};
                                }
                            }
                        }

                        .button.disabled {
                            @media (${media.forMediumAndUp}) {
                                &,
                                &:hover,
                                &:focus {
                                    color: ${colors.text.light};
                                    background-color: ${colors.grey[400]};
                                    cursor: default;
                                }
                            }
                        }

                        .button.ghost {
                            background-color: transparent;
                            border: solid 0.1rem ${colors.brand.base};
                            color: ${colors.text.dark};
                            font-weight: ${theme.fwMedium};
                        }

                        .loading {
                            height: 3rem;
                            width: 3rem;
                            position: absolute;
                            right: 0.5rem;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        .loading :global(.loading) {
                            height: 100%;
                        }

                        .button .icon {
                            margin-right: ${theme.spacing}rem;
                        }

                        .button.whatsApp,
                        .button.whatsApp:hover {
                            background: #25d366;
                        }
                    `}
                </style>
            </button>
        )}
    </Branding.Consumer>
);

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isInActive: PropTypes.bool,
    small: PropTypes.bool,
    transparent: PropTypes.bool,
    noBorder: PropTypes.bool,
    success: PropTypes.bool,
    danger: PropTypes.bool,
    warning: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    ghost: PropTypes.bool,
    isLoading: PropTypes.bool,
    icon: PropTypes.string,
    type: PropTypes.string,
};
Button.defaultProps = {
    children: null,
    className: '',
    isInActive: false,
    small: false,
    transparent: false,
    noBorder: false,
    success: false,
    danger: false,
    warning: false,
    disabled: false,
    onClick: () => {},
    ghost: false,
    isLoading: false,
    icon: '',
    type: '',
};
export default Button;
