import React from 'react';
import * as theme from 'styles/theme';
import classNames from 'classnames';

const OptionWithIcon = ({
    data,
    children,
    innerProps,
    isSelected,
    isFocused,
}) => (
    <div
        className={classNames('optionWithIcon', {isSelected, isFocused})}
        {...innerProps}>
        <div className="icon">{data.icon}</div>
        <div>{children}</div>

        <style jsx>
            {`
                .optionWithIcon {
                    display: flex;
                    align-items: center;
                    padding: ${`${theme.spacingSmaller}rem ${theme.spacing}rem`};
                    cursor: pointer;
                }

                .optionWithIcon:hover,
                .optionWithIcon.isFocused {
                    background-color: #deebff;
                }

                .optionWithIcon.isSelected {
                    background-color: #2684ff;
                    color: #fff;
                }

                .icon {
                    margin-right: ${theme.spacing}rem;
                }
            `}
        </style>
    </div>
);

export default OptionWithIcon;
