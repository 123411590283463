import {convertToRaw} from 'draft-js';
import decorator from 'lib/store/reducers/helpers/entities';
import {createRogerEditorState} from 'lib/store/reducers/helpers/editor';

export const editorBodyToRaw = editorBody =>
    Object.keys(editorBody).reduce((acc, language) => {
        acc[language] = convertToRaw(editorBody[language].getCurrentContent());
        return acc;
    }, {});

export const rawBodyToEditorBody = rawBody =>
    Object.keys(rawBody).reduce((acc, language) => {
        acc[language] = createRogerEditorState(rawBody[language], decorator);
        return acc;
    }, {});
