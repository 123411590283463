import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {GET, POST, DELETE, PUT} from '../../services';

export const MESSAGE_SCHEMA = new schema.Entity('message');

export const AUTOMATED_MESSAGES_LIST_REQUEST =
    'AUTOMATED_MESSAGES_LIST_REQUEST';
export const AUTOMATED_MESSAGES_LIST_SUCCESS =
    'AUTOMATED_MESSAGES_LIST_SUCCESS';
export const AUTOMATED_MESSAGES_LIST_FAILURE =
    'AUTOMATED_MESSAGES_LIST_FAILURE';

export const fetchAutomatedMessages = (
    {page, limit, filters = {}, filters: {query} = {}},
    listType = null,
) => ({
    [CALL_API]: {
        endpoint: '/auto-messages',
        method: GET,
        query: {
            query,
            page,
            limit,
        },
        filters,
        types: [
            AUTOMATED_MESSAGES_LIST_REQUEST,
            AUTOMATED_MESSAGES_LIST_SUCCESS,
            AUTOMATED_MESSAGES_LIST_FAILURE,
        ],
        listType,
        schema: [MESSAGE_SCHEMA],
    },
});

export const AUTOMATED_MESSAGES_VIEW_REQUEST =
    'AUTOMATED_MESSAGES_VIEW_REQUEST';
export const AUTOMATED_MESSAGES_VIEW_SUCCESS =
    'AUTOMATED_MESSAGES_VIEW_SUCCESS';
export const AUTOMATED_MESSAGES_VIEW_FAILURE =
    'AUTOMATED_MESSAGES_VIEW_FAILURE';

export const fetchAutomatedMessage = messageId => ({
    [CALL_API]: {
        endpoint: `/auto-messages/${messageId}`,
        method: GET,
        types: [
            AUTOMATED_MESSAGES_VIEW_REQUEST,
            AUTOMATED_MESSAGES_VIEW_SUCCESS,
            AUTOMATED_MESSAGES_VIEW_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
    },
});

export const AUTOMATED_MESSAGES_CREATE_REQUEST =
    'AUTOMATED_MESSAGES_CREATE_REQUEST';
export const AUTOMATED_MESSAGES_CREATE_SUCCESS =
    'AUTOMATED_MESSAGES_CREATE_SUCCESS';
export const AUTOMATED_MESSAGES_CREATE_FAILURE =
    'AUTOMATED_MESSAGES_CREATE_FAILURE';

export const createAutomatedMessage = message => ({
    [CALL_API]: {
        endpoint: '/auto-messages',
        method: POST,
        body: message,
        types: [
            AUTOMATED_MESSAGES_CREATE_REQUEST,
            AUTOMATED_MESSAGES_CREATE_SUCCESS,
            AUTOMATED_MESSAGES_CREATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.automated_message_created',
            },
        },
    },
});

export const AUTOMATED_MESSAGES_UPDATE_REQUEST =
    'AUTOMATED_MESSAGES_UPDATE_REQUEST';
export const AUTOMATED_MESSAGES_UPDATE_SUCCESS =
    'AUTOMATED_MESSAGES_UPDATE_SUCCESS';
export const AUTOMATED_MESSAGES_UPDATE_FAILURE =
    'AUTOMATED_MESSAGES_UPDATE_FAILURE';

export const updateAutomatedMessage = message => ({
    [CALL_API]: {
        endpoint: `/auto-messages/${message.id}`,
        method: PUT,
        body: message,
        types: [
            AUTOMATED_MESSAGES_UPDATE_REQUEST,
            AUTOMATED_MESSAGES_UPDATE_SUCCESS,
            AUTOMATED_MESSAGES_UPDATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
    },
});

export const AUTOMATED_MESSAGES_DELETE_REQUEST =
    'AUTOMATED_MESSAGES_DELETE_REQUEST';
export const AUTOMATED_MESSAGES_DELETE_SUCCESS =
    'AUTOMATED_MESSAGES_DELETE_SUCCESS';
export const AUTOMATED_MESSAGES_DELETE_FAILURE =
    'AUTOMATED_MESSAGES_DELETE_FAILURE';

export const deleteAutomatedMessage = message => ({
    [CALL_API]: {
        endpoint: `/auto-messages/${message.id}`,
        method: DELETE,
        types: [
            AUTOMATED_MESSAGES_DELETE_REQUEST,
            AUTOMATED_MESSAGES_DELETE_SUCCESS,
            AUTOMATED_MESSAGES_DELETE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.automated_message_deleted',
            },
        },
    },
});

export const AUTOMATED_MESSAGE_ACTIVATE_REQUEST =
    'AUTOMATED_MESSAGE_ACTIVATE_REQUEST';
export const AUTOMATED_MESSAGE_ACTIVATE_SUCCESS =
    'AUTOMATED_MESSAGE_ACTIVATE_SUCCESS';
export const AUTOMATED_MESSAGE_ACTIVATE_FAILURE =
    'AUTOMATED_MESSAGE_ACTIVATE_FAILURE';

export const activateAutomatedMessage = message => ({
    [CALL_API]: {
        endpoint: `/auto-messages/${message.id}/activate`,
        method: POST,
        types: [
            AUTOMATED_MESSAGE_ACTIVATE_REQUEST,
            AUTOMATED_MESSAGE_ACTIVATE_SUCCESS,
            AUTOMATED_MESSAGE_ACTIVATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.automated_message_activated',
            },
        },
    },
});

export const AUTOMATED_MESSAGE_DEACTIVATE_REQUEST =
    'AUTOMATED_MESSAGE_DEACTIVATE_REQUEST';
export const AUTOMATED_MESSAGE_DEACTIVATE_SUCCESS =
    'AUTOMATED_MESSAGE_DEACTIVATE_SUCCESS';
export const AUTOMATED_MESSAGE_DEACTIVATE_FAILURE =
    'AUTOMATED_MESSAGE_DEACTIVATE_FAILURE';

export const deactivateAutomatedMessage = message => ({
    [CALL_API]: {
        endpoint: `/auto-messages/${message.id}/deactivate`,
        method: POST,
        types: [
            AUTOMATED_MESSAGE_DEACTIVATE_REQUEST,
            AUTOMATED_MESSAGE_DEACTIVATE_SUCCESS,
            AUTOMATED_MESSAGE_DEACTIVATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.automated_message_deactivated',
            },
        },
    },
});

export default {
    fetchAutomatedMessages,
    fetchAutomatedMessage,
    createAutomatedMessage,
    updateAutomatedMessage,
    deleteAutomatedMessage,
    activateAutomatedMessage,
    deactivateAutomatedMessage,
};
