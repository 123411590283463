import React from 'react';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {
    TYPE_RSVP,
    TYPE_RATING,
    TYPE_OPINION_SCALE,
    TYPE_HAPPINESS_POLL,
    TYPE_MULTIPLE_CHOICE,
    TYPE_YES_NO_QUESTION,
} from 'lib/store/actions/surveys';
import {getById} from 'lib/store/reducers/helpers/byId';
import {modalForSurveyType} from 'lib/store/actions/modals';
import {actions} from 'store';
import {getSurveyInCurrentLanguage} from 'util/surveys';
import useEditorState from 'components/hooks/useEditorState';
import RsvpBlock from './RsvpBlock';
import Survey404 from './Survey404';
import SurveyClosed from './SurveyClosed';
import RatingScaleBlock from './RatingScaleBlock';
import OpinionScaleBlock from './OpinionScaleBlock';
import HappinessPollBlock from './HappinessPollBlock';
import YesNoQuestionBlock from './YesNoQuestionBlock';
import MultipleChoiceBlock from './MultipleChoiceBlock';

const getSurveyBlockFromType = (survey, interactive, acceptAnswers) => {
    if (!survey) {
        return Survey404;
    }
    if (interactive && !acceptAnswers) {
        return SurveyClosed;
    }
    switch (survey.type) {
        case TYPE_HAPPINESS_POLL:
            return HappinessPollBlock;
        case TYPE_MULTIPLE_CHOICE:
            return MultipleChoiceBlock;
        case TYPE_OPINION_SCALE:
            return OpinionScaleBlock;
        case TYPE_RATING:
            return RatingScaleBlock;
        case TYPE_RSVP:
            return RsvpBlock;
        case TYPE_YES_NO_QUESTION:
            return YesNoQuestionBlock;
        default:
            return Survey404;
    }
};

const SurveyBlock = ({
    surveys,
    surveyId,
    fetchSurvey,
    fetchUserSurvey,
    messageId,
    isAuthenticated,
    deleteSurvey,
    removeBlock,
    interactive,
    setSurveyResponse,
    setModalVisibility,
    setActiveSidebar,
    startEditingSurvey,
    match,
    messages,
    ...props
}) => {
    const {editor, acceptAnswers} = useEditorState();
    const surveyFromState = getById(surveys, surveyId);
    const survey = getSurveyInCurrentLanguage(
        surveyFromState,
        editor.currentLanguage,
    );

    const onEdit = () => {
        if (survey) {
            startEditingSurvey(surveyFromState);
            setActiveSidebar('');
            setModalVisibility(modalForSurveyType(surveyFromState.type), true);
        }
    };

    const onDelete = () => {
        if (survey) {
            removeBlock(survey.id);
            deleteSurvey(survey);
        } else {
            removeBlock(surveyId);
        }
    };

    const onChange = option => {
        if (interactive) {
            setSurveyResponse(
                messageId,
                surveyId,
                option,
                survey,
                isAuthenticated,
            );
        }
    };

    const CustomSurveyBlock = getSurveyBlockFromType(
        surveyFromState,
        interactive,
        acceptAnswers,
    );

    return (
        <CustomSurveyBlock
            {...props}
            interactive={interactive}
            survey={survey}
            onEdit={onEdit}
            onDelete={onDelete}
            onChange={onChange}
        />
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.accessToken !== null,
    translate: getTranslate(state.localize),
    surveys: state.surveys ? state.surveys.byId : state.userSurveys.byId,
    messages: state.userMessages
        ? state.userMessages.byId
        : state.messages.byId,
    messageId: state.editor.contentId,
});

const mapDispatchToProps = dispatch => ({
    fetchSurvey: id => dispatch(actions.surveys.fetchSurvey(id)),
    fetchUserSurvey: (userMessageId, surveyId, authenticated) =>
        dispatch(
            actions.userSurveys.fetchSurvey(
                userMessageId,
                surveyId,
                authenticated,
            ),
        ),
    setSurveyResponse: (
        messageId,
        surveyId,
        option,
        survey,
        isAuthenticated,
    ) => {
        const newSurvey = survey;

        if (!newSurvey.response) {
            newSurvey.response = {};
        }
        newSurvey.response.option = option;

        dispatch(
            actions.userSurveys.setSurveyResponse(
                messageId,
                surveyId,
                option,
                isAuthenticated,
            ),
        );
    },
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setActiveSidebar: sidebarType =>
        dispatch(actions.editor.setActiveSidebar(sidebarType)),
    startEditingSurvey: survey =>
        dispatch(actions.surveyEditor.startEditingSurvey(survey)),
    deleteSurvey: survey => dispatch(actions.surveys.deleteSurvey(survey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyBlock);
