import {parse, isEqual, format} from 'date-fns';
import {getById} from 'lib/store/reducers/helpers/byId';
import {CONTENT_TYPE_MESSAGES} from 'lib/store/actions/editor';
import {store} from 'store';
import actions from 'store/actions';
import {STATUS_DRAFT} from 'lib/store/actions/messages/index';
import {isEmpty} from 'lodash';
import {editorBodyToRaw} from './editorBody';
import {DATE_WITHOUT_TIMEZONE} from './constants';
import {
    STATUS_SCHEDULED,
    STATUS_PROPOSAL,
} from '../lib/store/actions/messages/index';

export const isValidMessage = message => {
    const {
        title,
        app_domain: appDomain,
        available_languages: availableLanguages,
    } = message;
    return (
        appDomain &&
        appDomain.length &&
        title &&
        availableLanguages.every(
            availableLanguage =>
                typeof title[availableLanguage] !== 'undefined',
        )
    );
};

const buildMessageFromCurrentEditorState = (editor, messages) => {
    const {
        contentId,
        contentType,
        title,
        body,
        appDomain,
        acceptAnswers,
        enableComments,
        audienceFilters,
        visibility,
        scheduledAt,
        sendReminders,
        availableLanguages,
        status,
    } = editor;
    const withScheduledAt =
        status === STATUS_DRAFT ||
        status === STATUS_SCHEDULED ||
        status === STATUS_PROPOSAL;
    const originalMessage = getById(messages.byId, contentId);
    let originalScheduledAt = null;
    if (originalMessage && originalMessage.scheduled_at) {
        originalScheduledAt = parse(originalMessage.scheduled_at);
    }

    const message = {
        id: contentType === CONTENT_TYPE_MESSAGES ? contentId : null,
        title,
        app_domain: appDomain.slug,
        accept_answers: acceptAnswers,
        enable_comments: enableComments,
        audience_filters: audienceFilters,
        visibility,
        body: editorBodyToRaw(body),
        send_reminders: sendReminders,
        available_languages: availableLanguages,
    };

    const hasSameScheduledAtAsOriginal = isEqual(
        scheduledAt,
        originalScheduledAt,
    );
    if (scheduledAt && (withScheduledAt || !hasSameScheduledAtAsOriginal)) {
        message.scheduled_at = format(scheduledAt, DATE_WITHOUT_TIMEZONE);
    } else if (!scheduledAt && originalScheduledAt) {
        message.scheduled_at = null;
    }

    return message;
};

export const saveMessage = async (
    editor,
    messages,
    history,
    redirectOnCreate = false,
    dispatch,
) => {
    const message = buildMessageFromCurrentEditorState(editor, messages);
    let payload = null;
    let shouldRedirectToMessage = false;
    if (isEmpty(message.title)) {
        return null;
    }
    if (!message.id) {
        if (redirectOnCreate) {
            shouldRedirectToMessage = true;
        }
        ({payload} = await dispatch(actions.messages.createMessage(message)));
    } else {
        ({payload} = await dispatch(actions.messages.updateMessage(message)));
    }

    if (payload.ids && payload.ids.length) {
        const messageId = payload.ids;
        dispatch(actions.editor.loadMessageIntoEditor(payload.data[messageId]));
        if (shouldRedirectToMessage) {
            history.push(`/editor/messages/${messageId}`);
        }
    }
    if (payload !== null) {
        message.id = payload.ids;
    }
    return message;
};

const getMessageFromStore = messageId => {
    const {byId} = store.getState().messages;
    return getById(byId, messageId);
};

export const prepareMessageForEditor = async (messageId, dispatch) => {
    let message = getMessageFromStore(messageId);
    if (!message) {
        const {payload} = await dispatch(
            actions.messages.fetchMessage(messageId),
        );
        message = payload.data[messageId];
    }
    return getMessageFromStore(messageId);
};
