import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M7.25 18.25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 0" />
            <path d="M23 16.75v-2.727c0-.347-.102-.699-.273-1L20.824 9.75a1.979 1.979 0 00-1.722-1H3.75c-.75 0-1.426.477-1.648 1.176l-.028.05a19.686 19.686 0 00-1 4.973L1 16.75c-.273 0-.5.227-.5.5v.5c0 .273.227.5.5.5h2.5a2.225 2.225 0 012.324-2.176c1.2.028 2.153 1 2.176 2.176h7.75a2.225 2.225 0 012.324-2.176c1.2.028 2.153 1 2.176 2.176H23c.273 0 .5-.227.5-.5v-.5c0-.273-.227-.5-.5-.5zM9 12c0 .148-.102.25-.25.25H4.176c-.153 0-.25-.102-.25-.25v-.05c.05-.325.097-.598.176-.876a.725.725 0 01.722-.574H8.75c.148 0 .25.102.25.25zm6.25 0c0 .148-.102.25-.25.25h-4.75A.238.238 0 0110 12v-1.25c0-.148.102-.25.25-.25H15c.148 0 .25.102.25.25zm5.176.25H16.5a.238.238 0 01-.25-.25v-1.25c0-.148.102-.25.25-.25h2.898c.278 0 .528.148.653.375l.574 1c.074.125.023.273-.102.352-.023.023-.046.023-.097.023zm0 0" />
            <path d="M19.5 18.25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 0M19.426 5.625C17.75 4.648 15.199 4 12 4c-2.926 0-5.8.55-7.477 1.398v1.176c1.204.602 3 1.051 5.028 1.278v-1c0-.176.148-.329.324-.329.176 0 .324.153.324.329v1.046c.602.051 1.227.079 1.852.079.449 0 .898-.028 1.324-.051V6.824c0-.176.148-.324.324-.324.176 0 .324.148.324.324v1.051c2.301-.2 4.153-.773 5.454-1.55.199-.098.273-.376.148-.552-.102-.046-.148-.125-.2-.148zm-12.102.676H5.727a.335.335 0 01-.329-.324c0-.176.153-.329.329-.329h1.597c.176 0 .324.153.324.329 0 .171-.171.324-.324.324zm0 0" />
        </g>
    );
}
