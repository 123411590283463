import {combineReducers} from 'redux';

import {
    FETCH_USER_ONBOARDING_REQUEST,
    FETCH_USER_ONBOARDING_SUCCESS,
    FETCH_USER_ONBOARDING_FAILURE,
    FETCH_USER_ACTIVITY_SUCCESS,
    FETCH_USER_ACTIVITY_REQUEST,
    FETCH_USER_ACTIVITY_FAILURE,
    FETCH_USER_ACTIVITY_DETAIL_SUCCESS,
    FETCH_USER_ACTIVITY_DETAIL_REQUEST,
    FETCH_USER_ACTIVITY_DETAIL_FAILURE,
    FETCH_USER_ACTIVITY_EXPORT_REQUEST,
    FETCH_USER_ACTIVITY_EXPORT_SUCCESS,
    FETCH_USER_ACTIVITY_EXPORT_FAILURE,
    FETCH_ONBOARDING_EXPORT_REQUEST,
    FETCH_ONBOARDING_EXPORT_SUCCESS,
    FETCH_ONBOARDING_EXPORT_FAILURE,
} from '../../actions/analytics/userActivity';

export default () => {
    const onBoarding = (state = null, action) => {
        switch (action.type) {
            case FETCH_USER_ONBOARDING_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const hasFetchedOnboarding = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ONBOARDING_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    const isFetchingOnBoardingExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_ONBOARDING_EXPORT_REQUEST:
                return true;
            case FETCH_ONBOARDING_EXPORT_SUCCESS:
            case FETCH_ONBOARDING_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const activity = (state = [], action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const hasFetchedActivity = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    const isLoading = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_REQUEST:
            case FETCH_USER_ONBOARDING_REQUEST:
                return true;
            case FETCH_USER_ACTIVITY_SUCCESS:
            case FETCH_USER_ACTIVITY_FAILURE:
            case FETCH_USER_ONBOARDING_SUCCESS:
            case FETCH_USER_ONBOARDING_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const activityDetail = (state = [], action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_DETAIL_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const hasFetchedActivityDetail = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_DETAIL_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    const isLoadingDetail = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_DETAIL_REQUEST:
                return true;
            case FETCH_USER_ACTIVITY_DETAIL_SUCCESS:
            case FETCH_USER_ACTIVITY_DETAIL_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const isFetchingUserActivityExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_USER_ACTIVITY_EXPORT_REQUEST:
                return true;
            case FETCH_USER_ACTIVITY_EXPORT_SUCCESS:
            case FETCH_USER_ACTIVITY_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    return combineReducers({
        onBoarding,
        hasFetchedOnboarding,
        activity,
        hasFetchedActivity,
        activityDetail,
        hasFetchedActivityDetail,
        isLoading,
        isLoadingDetail,
        isFetchingUserActivityExport,
        isFetchingOnBoardingExport,
    });
};
