import {SET_INPUT_DATA} from '../actions/embedModal';

const INITIAL_STATE = {
    data: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_INPUT_DATA:
            return {...state, data: action.payload.data};
        default:
            return state;
    }
};
