import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import actions from 'store/actions';
import UserList from 'components/util/UserList';

const UserListWithData = ({audienceFilters, parentId}) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(
        ({audience}) => audience.paginated.isFetching,
    );
    const isFailed = useSelector(({audience}) => audience.paginated.isFailed);
    const pages = useSelector(({audience}) => audience.paginated.pages);
    const filters = useSelector(({audience}) => audience.paginated.filters);
    const pageCount = useSelector(({audience}) => audience.paginated.pageCount);
    const audience = useSelector(state => state.audience.byId);
    const getAudienceFromSegment = params =>
        dispatch(
            actions.audience.getAudienceFromSegment(
                {...params, audienceFilters},
                parentId,
            ),
        );

    const DEFAULT_FILTER = {
        query: filters.query || '',
        sort: filters.sort || [],
    };

    const emptyAudienceFilters = !audienceFilters || !audienceFilters.length;

    const onQueryChange = (newFilters, page = 1) => {
        getAudienceFromSegment({
            filters: {...DEFAULT_FILTER, ...filters, ...newFilters},
            page,
        });
    };

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                onQueryChange(DEFAULT_FILTER, page);
            }
        }
    };

    const [isFetchingInitialData, setFetchingInitialData] = useState(true);

    const fetchInitialData = async () => {
        setFetchingInitialData(true);
        await onQueryChange();
        setFetchingInitialData(false);
    };

    useEffect(() => {
        fetchInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audienceFilters]);

    return (
        <UserList
            audience={emptyAudienceFilters ? {} : audience}
            isFetchingInitialData={isFetchingInitialData}
            isFetching={isFetching}
            pages={emptyAudienceFilters ? [] : pages}
            filters={filters}
            onTableEnd={onTableEnd}
            onQueryChange={onQueryChange}
        />
    );
};

UserListWithData.propTypes = {
    audienceFilters: PropTypes.arrayOf(PropTypes.object),
    parentId: PropTypes.string.isRequired,
};
UserListWithData.defaultProps = {
    audienceFilters: [],
};
export default UserListWithData;
