import React from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';

import {STOCK, INTERNAL} from 'lib/store/actions/tabMenu';
import UnsplashSidebar from './UnsplashSidebar';
import InternalMediaSidebar from './InternalMediaSidebar';

const MediaSidebar = ({activeMenuItem, setActiveSidebar}) => {
    switch (activeMenuItem) {
        case STOCK:
            return (
                <UnsplashSidebar
                    onImportComplete={() => setActiveSidebar('')}
                />
            );
        case INTERNAL:
        default:
            return (
                <InternalMediaSidebar
                    onImportComplete={() => setActiveSidebar('')}
                />
            );
    }
};

const mapStateToProps = state => ({
    activeMenuItem: state.tabMenu.activeMenuItem,
    internal: state.media.internal,
});

const mapDispatchToProps = dispatch => ({
    getMediaInternal: (search, page) =>
        dispatch(actions.media.internal.getMediaInternal(search, page)),
    setActiveSidebar: sidebarType =>
        dispatch(actions.editor.setActiveSidebar(sidebarType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaSidebar);
