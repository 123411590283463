import React from 'react';
import CustomBlocks from 'lib/constants/customBlocks';
import SurveyBlock from 'lib/components/Editor/Surveys/SurveyBlock';
import EmbedBlock from 'lib/components/Editor/EmbedBlock/EmbedBlock';
import MediaWrapper from 'lib/components/Editor/MediaWrapper/MediaWrapper';
import ChooseChannelsWrapper from 'lib/components/Editor/ChooseChannels/ChooseChannelsWrapper';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';

const AtomicBlock = ({block, blockProps}) => {
    const {removeBlock} = useManageEditorBlocks();
    const dataMap = block.getData();
    const type = dataMap.get('type');
    const {canEdit} = blockProps;

    const onRemoveBlock = (customType, surveyId) => {
        removeBlock(block, customType, surveyId);
    };

    return (() => {
        switch (type) {
            case CustomBlocks.MEDIA_BLOCK:
                return (
                    <MediaWrapper
                        canEdit={canEdit}
                        block={block}
                        mediaItemId={dataMap.get('mediaItemId')}
                        size={dataMap.get('size')}
                        removeBlock={onRemoveBlock}
                    />
                );
            case CustomBlocks.SURVEY_BLOCK:
                return (
                    <SurveyBlock
                        canEdit={canEdit}
                        block={block}
                        surveyId={dataMap.get('surveyId')}
                        removeBlock={id =>
                            onRemoveBlock(CustomBlocks.SURVEY_BLOCK, id)
                        }
                    />
                );
            case CustomBlocks.EMBED_BLOCK:
                return (
                    <EmbedBlock
                        canEdit={canEdit}
                        interactive={canEdit}
                        block={block}
                        embedUrl={dataMap.get('embedUrl')}
                        removeBlock={onRemoveBlock}
                    />
                );
            case CustomBlocks.CHOOSE_CHANNELS_BLOCK:
                return (
                    <ChooseChannelsWrapper
                        canEdit={canEdit}
                        removeBlock={onRemoveBlock}
                    />
                );
            default:
                return <p>Unsupported block type</p>;
        }
    })();
};

export default AtomicBlock;
