import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {GET, DELETE} from '../../services/index';

export const MESSAGE_COMMENT_SCHEMA = new schema.Entity('messageComment');

export const MESSAGES_COMMENTS_REQUEST = 'MESSAGES_COMMENTS_REQUEST';
export const MESSAGES_COMMENTS_SUCCESS = 'MESSAGES_COMMENTS_SUCCESS';
export const MESSAGES_COMMENTS_FAILURE = 'MESSAGES_COMMENTS_FAILURE';

export const fetchMessageComments = (
    {
        page,
        limit,
        filters = {},
        filters: {query, from, to, status, sort, app_domain: appDomain} = {},
    },
    messageId,
    listType = null,
) => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/comments`,
        method: GET,
        query: {
            query,
            page,
            limit,
            from,
            to,
            status,
            sort,
            app_domain: appDomain,
        },
        listType,
        filters,
        types: [
            MESSAGES_COMMENTS_REQUEST,
            MESSAGES_COMMENTS_SUCCESS,
            MESSAGES_COMMENTS_FAILURE,
        ],
        schema: [MESSAGE_COMMENT_SCHEMA],
    },
});

export const MESSAGES_COMMENT_DELETE_REQUEST =
    'MESSAGES_COMMENT_DELETE_REQUEST';
export const MESSAGES_COMMENT_DELETE_SUCCESS =
    'MESSAGES_COMMENT_DELETE_SUCCESS';
export const MESSAGES_COMMENT_DELETE_FAILURE =
    'MESSAGES_COMMENT_DELETE_FAILURE';

export const deleteMessageComment = (messageId, commentId) => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/comments/${commentId}`,
        method: DELETE,
        types: [
            MESSAGES_COMMENT_DELETE_REQUEST,
            MESSAGES_COMMENT_DELETE_SUCCESS,
            MESSAGES_COMMENT_DELETE_FAILURE,
        ],
        schema: MESSAGE_COMMENT_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.comment_deleted',
            },
        },
    },
});

export default {
    fetchMessageComments,
    deleteMessageComment,
};
