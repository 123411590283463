import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {format} from 'date-fns';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';
import {Translate} from 'react-localize-redux';
import Icon from 'lib/components/Icon';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import {DESC} from 'lib/store/services/index';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import ArchivedMessages from './ArchivedMessages';

const ArchivedMessagesWithState = ({
    fetchMessages,
    messages,
    pages,
    pageCount,
    isFetching,
    isFailed,
    company,
    total,
}) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState({deleted_at: DESC});
    const [query, setQuery] = useState('');
    const restoreMessage = messageId =>
        dispatch(actions.messages.restoreMessage(messageId));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));

    const fetch = page =>
        fetchMessages({
            filters: {
                sort,
                query,
                isArchived: true,
            },
            page,
        });

    useEffect(() => {
        fetch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, sort]);

    const onRestore = (e, messageId) => {
        e.stopPropagation();

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: <Translate id="archived_messages.confirm_restore" />,
            buttonActions: [
                {
                    label: <Translate id="global.restore" />,
                    callBack: async () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        await restoreMessage(messageId);
                        fetch(1);
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    const columns = [
        {
            key: 'icon',
            className: 'table__icon',
            renderer: (content, data) => <Icon name={data} />,
        },
        {
            key: 'title',
            label: <Translate id="global.title" />,
        },
        {
            key: 'deleted_at',
            label: <Translate id="archived_messages.deleted_at" />,
            sortable: query === '',
        },
        {
            key: 'actions',
            label: <Translate id="global.actions" />,
            renderer: (content, data) => (
                <ButtonWithoutStyle onClick={e => onRestore(e, data)}>
                    <Icon
                        className="table__remove"
                        strokeWidth={1}
                        name="refresh"
                    />
                </ButtonWithoutStyle>
            ),
        },
    ];
    let rows = [];
    if (pages.length) {
        rows = pages.reduce(
            (accumulator, page) => [
                ...accumulator,
                ...page.map(messageId => {
                    const message = getById(messages, messageId);
                    if (!message) {
                        return null;
                    }
                    const {
                        id,
                        app_domain: appDomain,
                        title,
                        deleted_at: deletedAt,
                    } = message;

                    return {
                        key: id,
                        data: [
                            {
                                className: 'table__icon',
                                data: appDomain ? appDomain.icon : null,
                            },
                            {
                                className: 'font-weight-bold',
                                content: title[company.default_language],
                            },
                            {
                                content: deletedAt
                                    ? format(deletedAt, DATE_TIME_FORMAT)
                                    : '',
                            },
                            {
                                data: id,
                            },
                        ],
                    };
                }),
            ],
            [],
        );
    }

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            if (pages.length < pageCount) {
                fetch(pages.length + 1);
            }
        }
    };

    return (
        <ArchivedMessages
            rows={rows}
            total={total}
            columns={columns}
            onTableEnd={onTableEnd}
            isFetching={isFetching}
            onSortChange={sort => setSort(sort)}
            onSearchQueryChange={query => setQuery(query)}
            filters={{query, sort}}
        />
    );
};

const mapStateToProps = state => ({
    messages: state.messages.byId,
    isFetching: state.messages.paginatedArchivedMessages.isFetching,
    total: state.messages.paginatedArchivedMessages.total,
    isFailed: state.messages.paginatedArchivedMessages.isFailed,
    pages: state.messages.paginatedArchivedMessages.pages,
    pageCount: state.messages.paginatedArchivedMessages.pageCount,
    company: state.company.data,
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchMessages: params =>
        dispatch(
            actions.messages.fetchMessages(params, 'view-archived-messages'),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchivedMessagesWithState);
