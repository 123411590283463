import React from 'react';
import {Translate} from 'react-localize-redux';
import Form from 'components/util/Form';

const {LabelGroup} = Form;

const TriggerReceiver = ({trigger}) => {
    if (
        trigger === null ||
        typeof trigger !== 'object' ||
        typeof trigger.translated_receiver !== 'string'
    ) {
        return null;
    }

    return (
        <LabelGroup label={<Translate id="editor.form.audience.label-fixed" />}>
            <input
                type="text"
                className="form-control-plaintext"
                readOnly
                value={trigger.translated_receiver}
            />
        </LabelGroup>
    );
};

export default TriggerReceiver;
