import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16 21L15 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 21L9 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.925 17H5.075C4.52468 17 3.99689 16.7814 3.60775 16.3922C3.21862 16.0031 3 15.4753 3 14.925V5.075C3 3.929 3.929 3 5.075 3H18.924C20.071 3 21 3.929 21 5.075V14.924C21.0001 15.1966 20.9466 15.4665 20.8423 15.7184C20.7381 15.9702 20.5853 16.1991 20.3926 16.3919C20.1999 16.5847 19.9711 16.7376 19.7193 16.842C19.4675 16.9463 19.1976 17 18.925 17V17Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 13V10.273"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 13V7"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 13V9.675"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 13V7.546"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
