import React from 'react';
import * as routes from 'routes/index';
import CommentsWithState from '../messageComments/CommentsWithState';
import EditorAnswersWithState from '../editorAnswers/EditorAnswersWithState';
import EditorFollowUpWithState from '../editorFollowUp/EditorFollowUpWithState';
import EditorWithState from './EditorWithState';

const EditorMainView = ({activeTab, messageId, followUpStatus}) => {
    switch (activeTab) {
        case routes.messageComments():
            return <CommentsWithState messageId={messageId} />;
        case routes.messageAnswers():
            return <EditorAnswersWithState messageId={messageId} />;
        case routes.messageFollowUp():
            return (
                <EditorFollowUpWithState
                    messageId={messageId}
                    status={followUpStatus}
                />
            );
        default:
            return <EditorWithState />;
    }
};

export default EditorMainView;
