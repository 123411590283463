import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {MODAL_DIRECTORY_AUDIENCE} from 'lib/store/actions/modals';
import actions from 'store/actions';
import AudienceModal from 'modals/AudienceModal/AudienceModal';

const DirectoryAudienceModalWithData = ({parentId, activeDirectoryId}) => {
    const dispatch = useDispatch();
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data));
    const addDirectory = (directoryId, values) =>
        dispatch(actions.directories.createDirectory(directoryId, values));
    const updateDirectory = (directoryId, values) =>
        dispatch(actions.directories.updateDirectory(directoryId, values));

    const [segments, setSegments] = useState([]);

    const modalData = useSelector(
        state => state.modals.data[MODAL_DIRECTORY_AUDIENCE],
    );
    const isVisible = useSelector(
        state => state.modals.visibility[MODAL_DIRECTORY_AUDIENCE],
    );
    const segmentsFromProps = (modalData && modalData.audienceFilters) || [];
    const hasDefaultAudience =
        (modalData && modalData.hasDefaultAudience) || false;
    const name = (modalData && modalData.name) || '';
    const directory = (modalData && modalData.directory) || null;
    const action = (modalData && modalData.action) || null;
    const [defaultAudience, setDefaultAudience] = useState(hasDefaultAudience);
    useEffect(() => {
        if (segmentsFromProps.length > 0) {
            setSegments(segmentsFromProps);
        }
    }, [segmentsFromProps]);

    if (!isVisible) {
        return null;
    }

    return (
        <AudienceModal
            name={MODAL_DIRECTORY_AUDIENCE}
            segments={segments}
            onSegmentsChange={setSegments}
            onCancel={() => {
                setModalData(MODAL_DIRECTORY_AUDIENCE, {});
                setModalVisibility(MODAL_DIRECTORY_AUDIENCE, false);
            }}
            onUpdateClick={() => {
                // create directory
                if (action === 'create') {
                    setModalVisibility(MODAL_DIRECTORY_AUDIENCE, false);
                    addDirectory(activeDirectoryId, {
                        name,
                        audience_filters: segments,
                        default_audience_filters: defaultAudience,
                    });
                } else {
                    // = update
                    setModalVisibility(MODAL_DIRECTORY_AUDIENCE, false);
                    updateDirectory(directory.id, {
                        name: directory.name,
                        audience_filters: segments,
                        default_audience_filters: defaultAudience,
                    });
                }
            }}
            parentId={parentId && parseInt(activeDirectoryId, 10)}
            hasDefaultAudienceFilters={defaultAudience}
            onDefaultAudienceFilterChange={setDefaultAudience}
        />
    );
};

DirectoryAudienceModalWithData.propTypes = {
    parentId: PropTypes.number.isRequired,
    activeDirectoryId: PropTypes.number,
};
DirectoryAudienceModalWithData.defaultProps = {
    activeDirectoryId: undefined,
};

export default DirectoryAudienceModalWithData;
