import React, {useRef} from 'react';
import useEditorState from 'components/hooks/useEditorState';
import {useDispatch} from 'react-redux';
import EditorSidebar from './EditorSidebar';
import actions from '../../../store/actions';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const EditorSidebarWithState = ({messageId, canEditFromCurrentTab}) => {
    const {
        canEdit,
        editor: {sidebarType, sidebarData, contentType},
    } = useEditorState();
    const dispatch = useDispatch();
    const ref = useRef();
    useOnClickOutside(ref, () => {
        dispatch(actions.editor.setActiveSidebar(''));
    });

    return (
        <EditorSidebar
            containerRef={ref}
            messageId={messageId}
            sidebarData={sidebarData}
            sidebarType={sidebarType}
            contentType={contentType}
            canEdit={canEdit && canEditFromCurrentTab}
        />
    );
};

export default EditorSidebarWithState;
