import React from 'react';
import {Translate} from 'react-localize-redux';
import CustomBlock from '../CustomBlock/CustomBlock';

const Survey404 = () => (
    <CustomBlock>
        <h2>
            <Translate id="editor.survey-closed" />
        </h2>
    </CustomBlock>
);

export default Survey404;
