import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M22.406 1.5v2.906c-.89.516-1.593 1.407-2.062 2.719-.469 1.313-.703 3.047-.703 5.156v.938h3.797v8.765h-8.813v-6.562c0-3.985.656-7.125 1.969-9.469 1.265-2.297 3.234-3.797 5.812-4.453zm-14.625 0v2.906c-.89.516-1.593 1.407-2.062 2.719-.469 1.313-.703 3.047-.703 5.156v.938h3.75v8.765H0v-6.562c0-3.985.656-7.125 1.922-9.469C3.234 3.656 5.203 2.156 7.782 1.5zm0 0"
        />
    );
}
