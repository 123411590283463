import {v4} from 'uuid';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const FAILURE = 'FAILURE';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';

export const addNotification = notification => ({
    type: ADD_NOTIFICATION,
    payload: {
        notification,
        id: v4(),
    },
});

export const clearNotification = notificationId => ({
    type: CLEAR_NOTIFICATION,
    payload: {
        notificationId,
    },
});

export const showError = content =>
    addNotification({
        type: FAILURE,
        content,
    });

export const showFailure = (message, params = {}) =>
    addNotification({
        type: FAILURE,
        content: {message, params},
    });

export const showSuccess = (message, params = {}) =>
    addNotification({
        type: SUCCESS,
        content: {message, params},
    });

export const showFailureByKey = (key, params = {}) =>
    addNotification({
        type: FAILURE,
        content: {key, params},
    });

export const showSuccessByKey = (key, params = {}) =>
    addNotification({
        type: SUCCESS,
        content: {key, params},
    });

export default {
    addNotification,
    clearNotification,
    FAILURE,
    SUCCESS,
    WARNING,
    showError,
    showFailure,
    showSuccess,
    showFailureByKey,
    showSuccessByKey,
};
