import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M19 18.981H5C4.46957 18.981 3.96086 18.7703 3.58579 18.3952C3.21071 18.0201 3 17.5114 3 16.981V7.019C3 6.48857 3.21071 5.97986 3.58579 5.60479C3.96086 5.22971 4.46957 5.019 5 5.019H19C19.5304 5.019 20.0391 5.22971 20.4142 5.60479C20.7893 5.97986 21 6.48857 21 7.019V16.982C20.9997 17.5123 20.7889 18.0207 20.4139 18.3956C20.0388 18.7704 19.5303 18.981 19 18.981V18.981Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 9L12 12L7 9"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
