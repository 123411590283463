import React from 'react';
import PropTypes from 'prop-types';
import FileForm from 'components/FileForm/FileForm';
import Modal from 'modals/Modal/ModalWithState';
import {MODAL_FILE} from 'lib/store/actions/modals';

const FileModal = ({name, onSetName, title, buttonActions}) => (
    <Modal
        name={MODAL_FILE}
        title={title}
        cancelable
        buttonActions={buttonActions}>
        <FileForm name={name} onSetName={onSetName} />
    </Modal>
);
FileModal.propTypes = {
    name: PropTypes.string.isRequired,
    onSetName: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    buttonActions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FileModal;
