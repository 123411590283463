import {addNewBlock} from 'medium-draft';
import {ContentState, EditorState} from 'draft-js';
import customBlocks from 'lib/constants/customBlocks';
import {syncBodyWithRemovedSurvey} from 'util/surveys';
import useEditorState from './useEditorState';
import useContentSaver from './useContentSaver';

const useManageEditorBlocks = () => {
    const {editor, editorState} = useEditorState();
    const {saveContent} = useContentSaver();

    const addBlock = async (data, type) => {
        const newEditorBody = {...editor.body};
        newEditorBody[editor.currentLanguage] = addNewBlock(
            editorState,
            type,
            data,
        );
        await saveContent({...editor, body: newEditorBody});
    };
    const updateBlockWithKey = async (data, key) => {
        const newEditorBody = {...editor.body};
        const contentState = editorState.getCurrentContent();
        const blockArray = contentState.getBlocksAsArray();

        const newBlockArray = blockArray.map(contentBlock => {
            if (contentBlock.getKey() === key) {
                return contentBlock.merge({
                    data,
                });
            }
            return contentBlock;
        });
        const newContentState =
            ContentState.createFromBlockArray(newBlockArray);
        newEditorBody[editor.currentLanguage] = EditorState.push(
            editorState,
            newContentState,
            'change-block-data',
        );
        await saveContent({...editor, body: newEditorBody});
    };
    const removeBlock = async (block, type, surveyId) => {
        let newEditorBody = {...editor.body};
        if (type === customBlocks.SURVEY_BLOCK) {
            newEditorBody = syncBodyWithRemovedSurvey(editor.body, surveyId);
        } else {
            const blockKey = block.getKey();
            const contentState = editorState.getCurrentContent();
            const blockArray = contentState.getBlocksAsArray();
            const newBlockArray = blockArray.filter(
                contentBlock => blockKey !== contentBlock.getKey(),
            );
            const newContentState =
                ContentState.createFromBlockArray(newBlockArray);
            newEditorBody[editor.currentLanguage] = EditorState.push(
                editorState,
                newContentState,
                'remove-range',
            );
        }
        await saveContent({...editor, body: newEditorBody});
    };

    return {
        addBlock,
        updateBlockWithKey,
        removeBlock,
    };
};

export default useManageEditorBlocks;
