import css from 'styled-jsx/css';
import * as theme from 'styles/theme';

export default css`
    .actions {
        &__content {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: ${theme.colors.text.secondary};
            transition: opacity ${theme.defaultTransition};
            z-index: ${theme.zAbove};
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0.8rem;
        }

        .action,
        &__content > * {
            margin: 0 0 0 0.6rem;
        }

        &:hover &__content {
            transition: 2s ease all;
            opacity: 1;
        }
        /*  */
        &__content > * {
            transition: 0.4s ease all;
            opacity: 0.8;
            height: 2.4rem;
            transform: scale(0.98);
            &:hover {
                opacity: 1;
                transform: scale(1);
                background: rgba(0, 0, 0, 0.2);
                box-shadow: rgba(0, 0, 0, 0.45) 0 0 2rem 0px;

                @supports (
                    filter: drop-shadow(rgba(0, 0, 0, 0.5) 0 0.1rem 0.3rem)
                ) {
                    filter: drop-shadow(
                        rgba(0, 0, 0, 0.5) 0.125rem 0.25rem 0.05rem
                    );
                    background: none;
                    box-shadow: none;
                }
            }
        }

        &hover &__content:first-child:hover {
        }
    }
`;
