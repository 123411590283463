import React from 'react';
import {connect} from 'react-redux';
import ical from 'ical-generator';
import {saveAs} from 'file-saver/FileSaver';
import Icon from './Icon';
import Button from './Button';
import {postMessageToNative} from '../helpers/nativeBridge';

const IcsButton = ({
    title,
    description,
    location,
    start,
    end,
    company,
    native,
}) => {
    const generateIcs = () => {
        const event = {
            start,
            end,
            summary: title,
            description,
            location,
        };

        if (native) {
            postMessageToNative({
                type: 'add_event',
                data: event,
            });
        } else {
            const calendarBlob = ical({
                domain: 'meetroger.io',
                prodId: {company: company.name, product: 'meetroger'},
                timezone: 'Europe/Brussels',
                events: [event],
            }).toBlob();
            saveAs(calendarBlob, `${title}.ics`);
        }
    };

    return (
        <Button onClick={generateIcs} small transparent noBorder>
            <Icon name="event-add" />
        </Button>
    );
};

const mapStateToProps = state => ({
    company: state.company.data,
});

export default connect(mapStateToProps)(IcsButton);
