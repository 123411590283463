import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {brandingDefaults} from 'lib/styles/colors';
import CloseButton from 'lib/components/CloseButton';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import actions from 'store/actions';
import Switch from 'components/util/Switch';
import useTranslate from 'components/hooks/useTranslate';
import Icon from 'lib/components/Icon';

const AutomatedMessage = ({
    id,
    add,
    title,
    message,
    onClick,
    translate,
    hasChildren,
    setModalData,
    appDomain = {},
    active = false,
    setModalVisibility,
    deleteAutomatedMessage,
    activateAutomatedMessage,
    deactivateAutomatedMessage,
}) => {
    const {defaultLanguage} = useTranslate();
    const _deleteAutomatedMessage = () => {
        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('automated_messages.confirm_delete'),
            buttonActions: [
                {
                    label: translate('global.delete'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteAutomatedMessage({id});
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    const _onMessageActiveChanged = activeChanged => {
        if (activeChanged && message.status !== 'active') {
            activateAutomatedMessage(message);
        } else if (!activeChanged && message.status === 'active') {
            deactivateAutomatedMessage(message);
        }
    };

    let localeTitle = title ? title[defaultLanguage] : null;

    if (title && !localeTitle) {
        // if our current language is not supported get the next language as a fallback
        const availableTitleLocales = Object.keys(title);

        if (availableTitleLocales.length > 0) {
            const availableTitleLocale = availableTitleLocales[0];
            localeTitle = `${title[availableTitleLocale]}`;
        }
    }
    return (
        <Branding.Consumer>
            {colors => (
                <div
                    className={classNames('automated-message', {
                        hasChildren,
                        add,
                    })}
                    onClick={onClick}>
                    <div className="content">
                        {!hasChildren && id && (
                            <CloseButton
                                className="delete"
                                onClick={e => {
                                    e.stopPropagation();
                                    _deleteAutomatedMessage();
                                }}
                            />
                        )}
                        {!add && title && (
                            <>
                                <h2 className="title">{localeTitle}</h2>
                            </>
                        )}
                        {!add && (
                            <div className="automated-message-active-switch">
                                <Switch
                                    value={active}
                                    offColor={brandingDefaults.danger.base}
                                    onChange={_onMessageActiveChanged}
                                />
                            </div>
                        )}
                        {add && <Icon name="add" />}
                    </div>

                    <style jsx>
                        {`
                            @import '../../lib/sass/kit';
                            $dimension: 15rem;

                            .automated-message {
                                $c: &;
                                position: relative;
                                cursor: pointer;
                                height: $dimension;

                                .automated-message-active-switch {
                                    position: absolute;
                                    left: 1rem;
                                    bottom: 1rem;
                                }

                                &:hover :global(.delete) {
                                    display: block;
                                }

                                :global(.delete) {
                                    position: absolute;
                                    top: 0.5rem;
                                    right: 1rem;
                                    display: none;
                                    color: $pure-white;
                                }

                                &.hasChildren {
                                    &:before,
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        z-index: $z-below;

                                        background-color: ${colors.grey[400]};
                                        width: $dimension;
                                        height: $dimension;
                                        border-radius: 1rem;
                                        border: 0.1rem solid $pure-white;

                                        left: 50%;
                                        transform: translateX(-50%);
                                    }

                                    &:before {
                                        width: $dimension - 0.8rem;
                                        top: 0.6rem;
                                    }

                                    &:after {
                                        width: $dimension - 0.4rem;
                                        top: 0.3rem;
                                    }
                                }

                                &.add .content {
                                    color: ${colors.grey[400]};
                                    background-color: transparent;
                                    border: 0.2rem dashed ${colors.grey[400]};
                                    display: flex;
                                    items: center;
                                    justify-content: center;
                                }
                            }

                            .content {
                                position: relative;
                                background-color: ${appDomain.color ||
                                colors.brand[900]};
                                border-radius: 1rem;
                                border: 0.1rem solid $pure-white;

                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                width: $dimension;
                                height: $dimension;

                                z-index: $z-above;
                            }

                            .title {
                                width: 100%;
                                padding: 0.5rem 1rem;
                                font-size: 1.2rem;
                                line-height: 1.4rem;
                                font-weight: $fw-bold;
                                text-align: center;
                                text-transform: uppercase;
                                color: $text-color-light;
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
    deleteAutomatedMessage: id =>
        dispatch(actions.automatedMessages.deleteAutomatedMessage(id)),
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setModalData: data => dispatch(actions.modals.setConfirmModalData(data)),
    activateAutomatedMessage: message =>
        dispatch(actions.automatedMessages.activateAutomatedMessage(message)),
    deactivateAutomatedMessage: message =>
        dispatch(actions.automatedMessages.deactivateAutomatedMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedMessage);
