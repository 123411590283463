import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M14.4486 0.894535C10.3699 0.894535 7.06336 4.20104 7.06336 8.27982C7.06336 12.3586 10.3699 15.6651 14.4486 15.6651C18.5274 15.6651 21.8339 12.3586 21.8339 8.27982C21.8339 4.20104 18.5274 0.894535 14.4486 0.894535ZM6.16882 8.27982C6.16882 3.707 9.87582 0 14.4486 0C19.0215 0 22.7285 3.707 22.7285 8.27982C22.7285 12.8526 19.0215 16.5596 14.4486 16.5596C9.87582 16.5596 6.16882 12.8526 6.16882 8.27982Z" />
            <path d="M14.4569 4.90921C14.7039 4.90982 14.9037 5.11055 14.903 5.35757L14.8887 11.2078C14.8881 11.4548 14.6874 11.6546 14.4404 11.654C14.1934 11.6534 13.9936 11.4527 13.9942 11.2056L14.0085 5.35538C14.0091 5.10837 14.2099 4.90861 14.4569 4.90921Z" />
            <path d="M11.0781 8.27543C11.0785 8.02841 11.2791 7.82853 11.5262 7.82898L17.3728 7.83971C17.6198 7.84017 17.8197 8.04078 17.8193 8.2878C17.8188 8.53482 17.6182 8.7347 17.3712 8.73425L11.5245 8.72351C11.2775 8.72306 11.0776 8.52244 11.0781 8.27543Z" />
            <path d="M8.73728 13.2742C8.92737 13.4319 8.9536 13.7139 8.79586 13.904L8.66233 14.0649L3.18832 21.2576L3.08415 21.3987C2.92223 21.6373 2.86053 21.93 2.91255 22.2138C2.96479 22.4987 3.12746 22.7514 3.36509 22.917C3.60243 23.0754 3.89133 23.1373 4.17281 23.0899C4.45533 23.0424 4.70888 22.8883 4.8813 22.6595L4.883 22.6573L4.883 22.6573L10.4399 15.3793L10.4437 15.3744C10.4502 15.3661 10.4587 15.3539 10.4778 15.3249C10.4795 15.3222 10.4814 15.3193 10.4834 15.3163C10.5001 15.2908 10.5236 15.2548 10.5508 15.2172C10.6955 15.0169 10.975 14.9718 11.1753 15.1164C11.3755 15.2611 11.4206 15.5406 11.276 15.7409C11.2614 15.7611 11.249 15.7801 11.2312 15.8072L11.2256 15.8157C11.2075 15.8432 11.1811 15.8835 11.1492 15.9243L5.59569 23.1979C5.59539 23.1983 5.59509 23.1987 5.59478 23.1991C5.28466 23.61 4.82899 23.8866 4.32131 23.972C3.81314 24.0576 3.2915 23.9451 2.86371 23.6578L2.85847 23.6542C2.42476 23.3539 2.12781 22.8939 2.03268 22.3751C1.93755 21.8562 2.05202 21.3208 2.35098 20.8862C2.3538 20.8821 2.35668 20.878 2.35963 20.874L2.47055 20.7237L2.47449 20.7185L7.9562 13.5157C7.96 13.5107 7.96391 13.5058 7.96792 13.501L8.10746 13.3328C8.2652 13.1427 8.54718 13.1165 8.73728 13.2742Z" />
        </g>
    );
}
