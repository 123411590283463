import css from 'styled-jsx/css';

export default css`
    .md-RichEditor-root {
        box-sizing: border-box;
        position: relative;
        line-height: 1.4em;
    }

    .md-RichEditor-editor {
        cursor: text;
        margin-top: 10px;
        position: relative;
        margin: 0 auto;
    }

    .md-RichEditor-editor h3 {
        font-size: 1.3em;
        margin: 1.2em 0;
    }

    .md-RichEditor-editor .public-DraftEditor-content {
        min-height: 100px;
    }

    .md-RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
        display: none;
    }

    .md-RichEditor-editor .md-RichEditor-blockquote {
        border-left: 5px solid #4ca8de;
        color: #555;
        //font-family: 'Hoefler Text', 'Georgia', serif;
        font-size: 1.2em;
        margin: 0;
        padding: 10px 0 10px 20px;
        background-color: #e2f2ff;
    }

    .md-RichEditor-blockquote a {
        text-decoration: underline;
    }

    .public-DraftEditor-content .md-block:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    .md-RichEditor-editor .public-DraftStyleDefault-pre {
        background-color: rgba(0, 0, 0, 0.05);
        font-size: 16px;
        padding: 20px;
    }

    .public-DraftStyleDefault-unorderedListItem,
    .public-DraftStyleDefault-orderedListItem {
        margin-bottom: 10px;
    }

    .md-editor-action {
        position: fixed;
        top: 5px;
        left: 5px;
    }

    .md-editor-action button {
        display: block;
    }
`;
