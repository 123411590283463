import React from 'react';

export default function () {
    return (
        <path
            d="M7.70001 3H16.3C16.8304 3 17.3392 3.21071 17.7142 3.58579C18.0893 3.96086 18.3 4.46957 18.3 5V21L12.008 17.727L5.70001 21V5C5.70001 4.46957 5.91073 3.96086 6.2858 3.58579C6.66087 3.21071 7.16958 3 7.70001 3V3Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
