import {CALL_API, NO_OP_TYPES} from '../../constants/api';
import {GET, POST} from '../services/index';

export const MODELS = {
    COMPANY: 'company',
    USERS: 'users',
    SEGMENT_GROUP_SLUG: 'segment-group-slug',
};

export const fetchExport = type => ({
    [CALL_API]: {
        endpoint: `/user-management/export/${type}`,
        method: GET,
        types: NO_OP_TYPES,
    },
});

export const importUsers = body => ({
    [CALL_API]: {
        endpoint: '/user-management/import',
        method: POST,
        body,
        types: NO_OP_TYPES,
    },
});

export const startValidation = body => ({
    [CALL_API]: {
        endpoint: '/user-management/start-validation',
        method: POST,
        body,
        types: NO_OP_TYPES,
    },
});

export const startImport = body => ({
    [CALL_API]: {
        endpoint: '/user-management/start-import',
        method: POST,
        body,
        types: NO_OP_TYPES,
    },
});
