import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import actions from 'store/actions';
import {MODAL_FILE, MODAL_FILE_AUDIENCE} from 'lib/store/actions/modals';
import FileModal from './FileModal';

const FileModalWithData = ({translate}) => {
    const dispatch = useDispatch();
    const modalData = useSelector(state => state.modals.data[MODAL_FILE]);
    const isVisible = useSelector(state => state.modals.visibility[MODAL_FILE]);
    const action = (modalData && modalData.action) || null;
    const file = (modalData && modalData.file) || null;
    const activeDirectoryId =
        (modalData && modalData.activeDirectoryId) || null;

    const setModalData = (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const updateFile = (newActiveDirectoryId, fileId, values) =>
        dispatch(
            actions.directories.updateFile(
                newActiveDirectoryId,
                fileId,
                values,
            ),
        );
    const [name, setName] = useState((file && file.name) || '');

    useEffect(() => {
        setName((file && file.name) || '');
    }, [file]);

    if (!isVisible) {
        return null;
    }

    const fileId = (file && file.id) || null;

    const buttonActions = [];
    let title = '';

    if (action === 'create') {
        title = translate('documents.addFileTitle');
        buttonActions.push({
            classes: 'button success',
            disabled: name === '',
            label: translate('global.next'),
            callBack: () => {
                setModalData(MODAL_FILE_AUDIENCE, {
                    name,
                    file,
                    action: 'create',
                });
                setModalVisibility(MODAL_FILE_AUDIENCE, true);
                setModalVisibility(MODAL_FILE, false);
            },
        });
    } else {
        // = update
        title = translate('documents.renameFile');
        buttonActions.push({
            classes: 'button success',
            disabled: name === '',
            label: translate('global.save'),
            callBack: () => {
                updateFile(activeDirectoryId, fileId, {
                    name,
                    audience_filters: file.audience_filters,
                    default_audience_filters: file.default_audience_filters,
                });
                setModalVisibility(MODAL_FILE, false);
            },
        });
    }

    return (
        <FileModal
            name={name}
            onSetName={setName}
            title={title}
            buttonActions={buttonActions}
            file={file}
        />
    );
};
FileModalWithData.propTypes = {
    translate: PropTypes.func,
};

FileModalWithData.defaultProps = {
    translate: () => {},
};

export default withLocalize(FileModalWithData);
