import {combineReducers} from 'redux';

import {
    EMBED_CODE_REQUEST,
    EMBED_CODE_SUCCESS,
    EMBED_CODE_FAILURE,
} from '../actions/embeds';

const embeds = (state = {}, action) => {
    switch (action.type) {
        case EMBED_CODE_SUCCESS:
            return {...state, [action.payload.id]: action.payload.data};
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case EMBED_CODE_REQUEST:
            return true;
        case EMBED_CODE_SUCCESS:
        case EMBED_CODE_FAILURE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    embeds,
    isFetching,
});
