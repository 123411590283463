import React from 'react';

export default function () {
    return (
        <>
            <path
                d="M16.646 7.392a.566.566 0 0 1-.389-.176.512.512 0 0 1 0-.741l4.13-3.953a.512.512 0 0 1 .741 0 .512.512 0 0 1 0 .74l-4.13 3.989a.505.505 0 0 1-.352.141ZM13.363 4.816h-.106a.526.526 0 0 1-.388-.636l.882-3.776a.526.526 0 1 1 1.024.247l-.882 3.776a.574.574 0 0 1-.53.389ZM19.575 10.498a.53.53 0 0 1-.494-.353c-.106-.282.07-.565.353-.67l3.776-1.236c.283-.106.565.07.67.353.107.282-.07.565-.352.67l-3.776 1.236h-.177ZM.94 23.91c-.106 0-.212-.035-.353-.07-.46-.213-.706-.707-.53-1.166L6.27 4.851c.106-.353.494-.6.847-.6.106 0 .212.035.282.07h.036a19.174 19.174 0 0 1 7.058 4.624c2.012 2.118 3.565 4.624 4.448 7.341v.07a.955.955 0 0 1-.565 1.095L1.222 23.875c-.035 0-.141.035-.282.035ZM7.222 5.345l-6.035 17.4 16.764-6.282c-.847-2.506-2.294-4.836-4.164-6.777a18.87 18.87 0 0 0-6.565-4.34Zm.07-.176c0 .035 0 0 0 0Z"
                fill="#1D1D1B"
            />
            <path
                d="M13.68 19.145c-.21 0-.387-.106-.458-.282-1.659-3.318-5.153-6.953-8.33-8.612-.246-.141-.352-.459-.211-.706.141-.247.459-.353.706-.212 3.353 1.765 7.023 5.577 8.753 9.106.14.247.035.565-.247.706h-.212ZM10.045 20.274a.603.603 0 0 1-.458-.247c-1.412-2.4-3.389-4.376-5.824-5.894-.247-.141-.318-.494-.176-.741.14-.247.494-.318.74-.176 2.577 1.588 4.66 3.705 6.142 6.247a.565.565 0 0 1-.176.74c-.071.071-.142.071-.248.071ZM6.481 21.792a.503.503 0 0 1-.424-.212c-1.129-1.658-1.94-2.47-3.494-3.6-.247-.176-.282-.494-.106-.74.177-.248.495-.283.742-.107 1.694 1.236 2.506 2.083 3.74 3.847.177.247.107.565-.14.741-.106.036-.212.071-.318.071Z"
                fill="#1D1D1B"
            />
        </>
    );
}
