import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16.596 9.904C17.2087 10.5055 17.6961 11.2225 18.0302 12.0135C18.3642 12.8044 18.5382 13.6538 18.5422 14.5124C18.5461 15.371 18.3799 16.2219 18.0532 17.0159C17.7264 17.8099 17.2456 18.5313 16.6384 19.1384C16.0313 19.7456 15.3099 20.2264 14.5159 20.5532C13.7219 20.8799 12.871 21.0461 12.0124 21.0422C11.1538 21.0382 10.3044 20.8642 9.51346 20.5302C8.72249 20.1961 8.00553 19.7087 7.404 19.096C6.20351 17.8732 5.53443 16.2259 5.54231 14.5124C5.55018 12.7988 6.23437 11.1577 7.44604 9.94604C8.65771 8.73437 10.2988 8.05018 12.0124 8.04231C13.7259 8.03443 15.3732 8.70352 16.596 9.904"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 16.5V13.95"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.997 12.431C11.8488 12.4316 11.7041 12.4761 11.5811 12.5589C11.4582 12.6416 11.3625 12.759 11.3062 12.8961C11.2499 13.0332 11.2355 13.1839 11.2648 13.3291C11.2941 13.4744 11.3657 13.6078 11.4707 13.7124C11.5757 13.817 11.7094 13.8881 11.8548 13.9168C12.0002 13.9455 12.1508 13.9305 12.2877 13.8736C12.4246 13.8168 12.5415 13.7206 12.6238 13.5974C12.7061 13.4741 12.75 13.3292 12.75 13.181C12.75 13.0823 12.7305 12.9845 12.6926 12.8933C12.6547 12.8021 12.5992 12.7193 12.5293 12.6496C12.4593 12.5799 12.3763 12.5247 12.2849 12.4872C12.1936 12.4497 12.0957 12.4306 11.997 12.431"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.26898 5.848C8.47841 5.03321 8.95285 4.3112 9.61761 3.79562C10.2824 3.28003 11.0997 3.00014 11.941 3C12.4386 2.99987 12.9314 3.09778 13.3911 3.28815C13.8509 3.47852 14.2686 3.75761 14.6205 4.10948C14.9724 4.46135 15.2515 4.8791 15.4418 5.33886C15.6322 5.79862 15.7301 6.29138 15.73 6.789V9.179"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
