import React from 'react';

export default function () {
    return (
        <path
            d="M6.89324 7.16671C0.454476 8.27929 -0.60494 8.65552 0.261768 9.52223C0.41777 9.67823 3.28686 10.532 6.63725 11.4199L12.7295 13.0337L16.7273 12.0303C18.9262 11.4784 20.8489 11.0278 20.9995 11.0291C21.3077 11.0319 21.0738 16.81 20.721 17.914C20.5198 18.5437 20.602 18.626 21.4343 18.626H22.3753L22.3415 14.6092L22.3076 10.5927L23.0987 10.4035C23.5854 10.2874 23.9274 9.95543 23.9875 9.54058C24.0736 8.94886 23.7638 8.76944 21.4561 8.07582C15.8726 6.39715 14.3133 5.96996 13.888 6.00161C13.6456 6.01964 10.498 6.54397 6.89324 7.16671ZM6.01134 13.5745V15.4968L7.83083 16.3366C10.3588 17.5033 14.4376 17.5258 17.2447 16.3885L19.1433 15.6189L19.2382 13.6153C19.3297 11.6835 19.3085 11.6195 18.6443 11.8303C18.2655 11.9505 16.7789 12.3632 15.341 12.7473L12.7264 13.4454L10.2392 12.7669C8.87094 12.3939 7.35998 11.9904 6.88153 11.8705L6.01134 11.6525V13.5745Z"
            fill="currentColor"
        />
    );
}
