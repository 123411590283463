import React from 'react';
import debounce from 'lodash.debounce';
import Mount from 'lib/components/Mount';
import {getById} from 'lib/store/reducers/helpers/byId';
import useTranslate from 'components/hooks/useTranslate';
import MessageSelect from './MessageSelect';

const MessageSelectWithData = ({
    trigger,
    messages,
    messagePages,
    updateTrigger,
    fetchMessages,
    fetchMessage,
}) => {
    const {defaultLanguage} = useTranslate();
    let messageOptions = [];
    if (messagePages.length) {
        messageOptions = messagePages[0].map(messageId => {
            const message = getById(messages, messageId);
            return {
                label: message.title[defaultLanguage],
                value: message.id,
            };
        });
    }

    // Push currently selected message
    if (trigger.parameters) {
        const currentMessageId = trigger.parameters.message_id;
        if (
            messagePages.length === 0 ||
            !messagePages[0].find(messageId => messageId === currentMessageId)
        ) {
            const currentMessage = getById(messages, currentMessageId);

            if (currentMessage) {
                messageOptions.push({
                    label: currentMessage.title[defaultLanguage],
                    value: currentMessage.id,
                });
            }
        }
    }

    const fetchMessagesWithQuery = (query = '') => {
        fetchMessages({
            filters: {
                query,
            },
        });
    };

    const debouncedFetchMessages = debounce(query => {
        fetchMessagesWithQuery(query);
    }, 400);

    return (
        <Mount
            didMount={() => {
                if (
                    trigger &&
                    trigger.parameters &&
                    trigger.parameters.message_id
                ) {
                    fetchMessage(trigger.parameters.message_id);
                }
            }}>
            <MessageSelect
                trigger={trigger}
                messages={messageOptions}
                onChange={option => {
                    const parameters = (trigger && trigger.parameters) || {};
                    const newTrigger = {
                        ...trigger,
                        parameters: {
                            ...parameters,
                            message_id: option && option.value,
                        },
                    };

                    updateTrigger(newTrigger);
                }}
                fetchMessages={debouncedFetchMessages}
            />
        </Mount>
    );
};

export default MessageSelectWithData;
