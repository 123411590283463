import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MODAL_AUDIENCE} from 'lib/store/actions/modals';
import actions from 'store/actions';
import AudienceModal from './AudienceModal';

const AudienceModalWithData = () => {
    const dispatch = useDispatch();
    const modalData = useSelector(({modals}) => modals.data[MODAL_AUDIENCE]);
    const isVisible = useSelector(
        ({modals}) => modals.visibility[MODAL_AUDIENCE],
    );
    const segmentsFromProps = (modalData && modalData.audienceFilters) || [];

    const usersOnly = modalData && modalData.usersOnly;

    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data));
    const updateSegments = segments =>
        dispatch(actions.editor.setAudienceFilters(segments));

    const [segments, setSegments] = useState(segmentsFromProps);
    useEffect(() => {
        setSegments(segmentsFromProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentsFromProps.length]);

    if (!isVisible || !segments) {
        return null;
    }

    return (
        <AudienceModal
            usersOnly={usersOnly}
            segments={segments}
            onSegmentsChange={setSegments}
            onCancel={() => {
                setModalData(MODAL_AUDIENCE, {});
                setModalVisibility(MODAL_AUDIENCE, false);
            }}
            onUpdateClick={() => {
                setModalVisibility(MODAL_AUDIENCE, false);
                updateSegments(segments);
            }}
        />
    );
};
export default AudienceModalWithData;
