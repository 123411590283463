import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M17.54 10.12L13.88 6.46"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.251 21H3V17.749C3 17.484 3.105 17.229 3.293 17.042L16.627 3.707C16.7198 3.61405 16.83 3.54032 16.9513 3.49001C17.0726 3.4397 17.2027 3.4138 17.334 3.4138C17.4653 3.4138 17.5954 3.4397 17.7167 3.49001C17.838 3.54032 17.9482 3.61405 18.041 3.707L20.292 5.958C20.3849 6.05079 20.4587 6.16099 20.509 6.28231C20.5593 6.40362 20.5852 6.53367 20.5852 6.665C20.5852 6.79633 20.5593 6.92638 20.509 7.04769C20.4587 7.16901 20.3849 7.27921 20.292 7.372L6.958 20.707C6.86526 20.8 6.75506 20.8738 6.63373 20.924C6.5124 20.9743 6.38234 21.0001 6.251 21V21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
