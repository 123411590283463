import React from 'react';
import {connect} from 'react-redux';
import State from 'lib/components/State';
import getUserRoleHelper, {USER} from 'util/getUserRoleHelper';
import {mapLanguageOptions} from 'lib/helpers/locale';
import {ON_FIRST_DAY_OF_WORK} from './Onboarding';

const UserDetailFormWithState = ({initialUser = {}, company, children}) => {
    const mappedSegmentGroups =
        initialUser.segment_groups &&
        initialUser.segment_groups.reduce((accumulator, {id, segments}) => {
            const newObject = accumulator;
            newObject[id] =
                segments &&
                segments.map(({name: label, id: value}) => ({
                    label,
                    value,
                }));

            return newObject;
        }, {});

    const languageOptions = mapLanguageOptions(company && company.languages);

    const options = {
        roleOptions: company.roles.map(({label, slug}) => ({
            label,
            value: slug,
        })),
        languageOptions,
    };

    return (
        <State
            initialState={{
                user: {
                    role: getUserRoleHelper(initialUser) || USER,
                    language: company.default_language,
                    onboarding_type: ON_FIRST_DAY_OF_WORK,
                    ...initialUser,
                    segment_groups: mappedSegmentGroups || [],
                },
            }}>
            {({state, setState}) => {
                const onPropertyChange = obj => {
                    const properties = Object.keys(obj);

                    const newUser = {};
                    properties.forEach(property => {
                        const value = obj[property];
                        let internalValue = value;

                        if (Array.isArray(value)) {
                            internalValue = value.map(item => item.value);
                        }

                        if (
                            property === 'mobile' ||
                            property === 'work_mobile'
                        ) {
                            internalValue = value && value.replace(' ', '');
                        }

                        newUser[property] = internalValue;
                    });

                    setState(prevState => ({
                        user: {...prevState.user, ...newUser},
                    }));
                };

                return typeof children === 'function'
                    ? children({
                          user: state.user,
                          options,
                          onPropertyChange,
                      })
                    : children;
            }}
        </State>
    );
};

const mapStateToProps = state => ({
    company: state.company.data,
});

export default connect(mapStateToProps)(UserDetailFormWithState);
