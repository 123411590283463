import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M8.75 4.52799H7.002C6.099 4.52799 5.29 5.08199 4.973 5.91899L2.526 12.37C2.34379 12.8509 2.25028 13.3608 2.25 13.875V17.385C2.25 18.568 3.22 19.528 4.417 19.528H19.584C20.781 19.528 21.751 18.569 21.751 17.385V13.876C21.751 13.362 21.658 12.852 21.475 12.371L19.028 5.91799C18.8708 5.50771 18.5925 5.15493 18.2301 4.90649C17.8677 4.65805 17.4384 4.5257 16.999 4.52699L8.75 4.52799"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.32202 13.099H6.99602C7.40602 13.099 7.78202 13.328 7.96502 13.691L8.45002 14.65C8.63402 15.013 9.00902 15.243 9.42002 15.243H14.581C14.991 15.243 15.366 15.014 15.549 14.651L16.034 13.693C16.218 13.33 16.593 13.1 17.004 13.1H21.679"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
