import React from 'react';
import {Switch} from 'react-router-dom';
import * as routes from 'routes/index';
import LoginWithState from 'components/pages/auth/LoginWithState';
import ForgotPasswordWithState from 'components/pages/auth/ForgotPasswordWithState';
import ResetPasswordWithState from 'components/pages/auth/ResetPasswordWithState';
import EditorPageWithState from 'components/pages/editorView/EditorPageWithState';
import AuthorizedRoute from './AuthorizedRoute';
import AppRoutes from './AppRoutes';
import UnauthorizedRoute from './UnauthorizedRoute';

const Routes = ({isAuthenticated, userRole}) => (
    <Switch>
        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.editorForMessageId()}
            component={EditorPageWithState}
        />

        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.editorForMessageType()}
            component={EditorPageWithState}
        />

        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.editor()}
            component={EditorPageWithState}
        />

        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.messageAnswers()}
            component={EditorPageWithState}
        />

        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.messageFollowUp()}
            component={EditorPageWithState}
        />

        <AuthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.messageComments()}
            component={EditorPageWithState}
        />

        <UnauthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.login()}
            component={LoginWithState}
        />

        <UnauthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.forgotPassword()}
            component={ForgotPasswordWithState}
        />

        <UnauthorizedRoute
            isAuthenticated={isAuthenticated}
            userRole={userRole}
            path={routes.resetPassword()}
            component={ResetPasswordWithState}
        />

        {/* Default route when not authenticated */}
        {!isAuthenticated ? (
            <UnauthorizedRoute
                isAuthenticated={isAuthenticated}
                userRole={userRole}
                path={routes.home()}
                component={LoginWithState}
            />
        ) : (
            <AppRoutes isAuthenticated={isAuthenticated} userRole={userRole} />
        )}
    </Switch>
);

export default Routes;
