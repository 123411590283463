import React from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import MessageSelectWithData from './MessageSelectWithData';

const AutomatedMessageSelectWithRedux = ({...props}) => (
    <MessageSelectWithData {...props} />
);

const mapStateToProps = state => ({
    messages: state.automatedMessages.byId,
    messagePages: state.automatedMessages.paginatedSearchMessages.pages,
    trigger: state.editor.trigger,
});

const mapDispatchToProps = dispatch => ({
    updateTrigger: trigger => dispatch(actions.editor.setTrigger(trigger)),
    fetchMessage: id =>
        dispatch(actions.automatedMessages.fetchAutomatedMessage(id)),
    fetchMessages: params =>
        dispatch(
            actions.automatedMessages.fetchAutomatedMessages(params, 'search'),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AutomatedMessageSelectWithRedux);
