import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    CONTENT_TYPE_MESSAGES,
    CONTENT_TYPE_TEMPLATES,
    CONTENT_TYPE_AUTOMATED_MESSAGES,
} from 'lib/store/actions/editor';
import decorator from 'lib/store/reducers/helpers/entities';
import {createRogerEditorState} from 'lib/store/reducers/helpers/editor';
import actions from 'store/actions';
import useTranslate from 'components/hooks/useTranslate';
import {prepareMessageForEditor} from 'util/messages';
import {prepareTemplateForEditor} from 'util/templates';
import {prepareAutomatedMessageForEditor} from 'util/automatedMessages';
import EditorPage from './EditorPage';

const EditorPageWithState = ({match}) => {
    const {path} = match;
    const [isLoading, setIsLoading] = useState(true);
    const {status, messageId, messageType} = useParams();
    const {defaultLanguage} = useTranslate();

    const dispatch = useDispatch();
    const setEditorState = editorState => {
        dispatch(actions.editor.setEditorState(editorState));
    };
    const loadMessageIntoEditor = message =>
        dispatch(actions.editor.loadMessageIntoEditor(message));
    const loadTemplateIntoEditor = template =>
        dispatch(actions.editor.loadTemplateIntoEditor(template));
    const setCurrentLanguage = currentLanguage => {
        dispatch(actions.editor.setCurrentLanguage(currentLanguage));
    };
    const setAvailableLanguages = availableLanguages => {
        dispatch(actions.editor.setAvailableLanguages(availableLanguages));
    };
    const loadAutomatedMessageIntoEditor = automatedMessage =>
        dispatch(
            actions.editor.loadAutomatedMessageIntoEditor(automatedMessage),
        );
    const showFailure = text =>
        dispatch(actions.notifications.showFailure(text));
    const startNewAutomatedMessage = editorState =>
        dispatch(actions.editor.startNewAutomatedMessage(editorState));

    const handlePrepareMessage = async () => {
        try {
            let availableLanguages = [defaultLanguage];
            switch (messageType) {
                case CONTENT_TYPE_AUTOMATED_MESSAGES: {
                    if (messageId) {
                        const preparedAutomatedMessage =
                            await prepareAutomatedMessageForEditor(
                                messageId,
                                dispatch,
                            );
                        availableLanguages =
                            preparedAutomatedMessage.available_languages;
                        await loadAutomatedMessageIntoEditor(
                            preparedAutomatedMessage,
                        );
                    } else {
                        const editorState = {};
                        editorState[defaultLanguage] = createRogerEditorState(
                            null,
                            decorator,
                        );
                        startNewAutomatedMessage(editorState);
                    }
                    break;
                }
                case CONTENT_TYPE_TEMPLATES: {
                    const preparedTemplate = await prepareTemplateForEditor(
                        messageId,
                        dispatch,
                    );
                    await loadTemplateIntoEditor(preparedTemplate);
                    availableLanguages = preparedTemplate.available_languages;
                    break;
                }
                case CONTENT_TYPE_MESSAGES:
                default: {
                    if (messageId) {
                        const preparedMessage = await prepareMessageForEditor(
                            messageId,
                            dispatch,
                        );
                        availableLanguages =
                            preparedMessage.available_languages;
                        await loadMessageIntoEditor(preparedMessage);
                    } else {
                        const editorState = {};
                        editorState[defaultLanguage] = createRogerEditorState(
                            null,
                            decorator,
                        );
                        setEditorState(editorState);
                    }
                    break;
                }
            }
            setCurrentLanguage(defaultLanguage);
            setAvailableLanguages(availableLanguages);
        } catch ({message}) {
            showFailure(message);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await handlePrepareMessage();
            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <EditorPage
            activeTab={path}
            messageId={messageId}
            followUpStatus={status}
            isLoading={isLoading}
        />
    );
};

export default EditorPageWithState;
