import React from 'react';
import classNames from 'classnames';
import {brandingDefaults} from 'lib/styles/colors';

const Switch = ({
    value,
    onChange,
    disabled = false,
    onColor = brandingDefaults.success.base,
    offColor = brandingDefaults.grey[300],
}) => (
    <>
        <span
            className={classNames(
                'switch-base',
                value && 'switch-base-active',
                disabled && 'switch-disabled',
            )}
            onClick={e => {
                e.stopPropagation();
                if (!disabled) {
                    onChange(!value);
                }
            }}>
            <span
                aria-hidden="true"
                className={classNames(
                    'switch-toggle',
                    value && 'switch-toggle-active',
                    disabled && 'switch-disabled',
                )}
            />
        </span>
        <style jsx="true">
            {`
                .switch-base {
                    background-color: white;
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    flex-shrink: 0;
                    margin: 0;
                    height: 18px;
                    width: 36px;
                    box-shadow: 0 0 0 3px ${offColor};
                    border-color: transparent;
                    border-radius: 9999px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                }

                .switch-base-active {
                    box-shadow: 0 0 0 3px ${onColor};
                }

                .switch-disabled {
                    opacity: 0.4;
                }

                .switch-toggle {
                    display: inline-block;
                    height: 14px;
                    width: 14px;
                    border-radius: 9999px;
                    background-color: ${offColor};
                    margin-left: 0.2rem;
                    transition: all 0.5s ease;
                    transform: translate(0rem);
                }

                .switch-toggle-active {
                    background-color: ${onColor};
                    margin-left: 0rem;
                    transform: translate(20px);
                }
            `}
        </style>
    </>
);

export default Switch;
