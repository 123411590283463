import React, {useState, useEffect, useRef} from 'react';
import {ChromePicker} from 'react-color';
import styled from 'styled-components';
import useOnClickOutside from '../hooks/useOnClickOutside';

const parseColor = color => {
    let parsedColor = color === null ? '' : color.toUpperCase();

    if (parsedColor.length >= 6) {
        if (parsedColor[0] !== '#') {
            parsedColor = `#${parsedColor}`;
        }
        parsedColor = parsedColor.substr(0, 7);
    }

    return parsedColor;
};

const ColorPicker = ({color, onChange}) => {
    const [colorPickerShown, setColorPickerShown] = useState(false);
    const [selectedColor, setSelectedColor] = useState(color || '');
    const colorPickerRef = useRef();

    useEffect(() => {
        setSelectedColor(color || '');
    }, [color]);
    const parsedColor = parseColor(selectedColor);

    useOnClickOutside(colorPickerRef, () => setColorPickerShown(false));

    return (
        <Container>
            <Swatch>
                <PickerTrigger
                    onClick={() => setColorPickerShown(true)}
                    style={{backgroundColor: parsedColor}}
                />
                <Input
                    type="text"
                    value={parsedColor}
                    placeholder="#FF0000"
                    onChange={e => onChange(parseColor(e.target.value))}
                />
            </Swatch>

            {colorPickerShown && (
                <Picker ref={colorPickerRef}>
                    <ChromePicker
                        color={selectedColor}
                        onChange={newColor => {
                            onChange(newColor.hex);
                        }}
                        disableAlpha
                    />
                </Picker>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
`;

const Swatch = styled.div(
    ({theme}) => `
    display: flex;
    align-items: center;
    width: 20rem;
    border: .1rem solid ${theme.colorBorder};
    border-radius: ${theme.borderRadius}rem;
    background-color: ${theme.colors.grey[0]};
    overflow: hidden;
`,
);

const PickerTrigger = styled.div(
    ({theme}) => `
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: ${theme.borderRadius}rem;
    margin: ${theme.spacingSmall}rem;
    flex-shrink: 0;
`,
);

const Input = styled.input`
    flex-grow: 1;
    flex-shrink: 0;
    border: 0;
    background-color: transparent;
`;

const Picker = styled.div(
    ({theme}) => `
    position: absolute;
    z-index: ${theme.zAbove};
    left: 0;
    top: 2rem;
`,
);

export default ColorPicker;
