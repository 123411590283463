/* eslint-disable no-case-declarations */
import fastCompare from 'react-fast-compare';
import {LOGOUT_REQUEST} from '../../actions/auth';

const withoutEmptyKeys = obj => {
    const result = {};
    if (typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            switch (typeof obj[key]) {
                case 'string':
                    if (obj[key].length !== 0) {
                        result[key] = obj[key];
                    }
                    break;
                case 'object':
                    if (Object.keys(obj[key]).length > 0) {
                        result[key] = obj[key];
                    }
                    break;
                default:
                    result[key] = obj[key];
            }
        });
    }
    return result;
};

// Creates a reducer managing pagination, given the action types to handle,
const paginate = ({
    listActions,
    deleteActions,
    requestFilterKey,
    listType = null,
}) => {
    if (!Array.isArray(listActions) || listActions.length !== 3) {
        throw new Error(
            'Expected list actions to be an array of three elements.',
        );
    }
    if (!listActions.every(t => typeof t === 'string')) {
        throw new Error('Expected list actions to be strings.');
    }

    if (deleteActions) {
        if (!Array.isArray(deleteActions) || deleteActions.length !== 3) {
            throw new Error(
                'Expected delete actions to be an array of three elements.',
            );
        }

        if (!deleteActions.every(t => typeof t === 'string')) {
            throw new Error('Expected list actions to be strings.');
        }
    }

    const INITIAL_STATE = {
        filters: {},
        limit: 10,
        isFetching: false,
        isFailed: false,
        pages: [],
        pageCount: 0,
        total: 0,
    };

    return (state = INITIAL_STATE, action) => {
        const {type, payload} = action;

        if (deleteActions) {
            const [deleteRequestType, deleteSuccesstype, deleteFailureType] =
                deleteActions;

            switch (type) {
                case deleteSuccesstype:
                    const newPages = state.pages.map(page =>
                        page.filter(
                            objectId =>
                                objectId !== (payload.ids || payload.data.id),
                        ),
                    );
                    const mappedPages = newPages.reduce(
                        (pages, currentPage) => [
                            ...pages,
                            ...currentPage.map(page => page),
                        ],
                        [],
                    );
                    return {
                        ...state,
                        total: mappedPages.length,
                        pages: newPages,
                    };
                case deleteFailureType:
                case deleteRequestType:
                default:
            }
        }

        if (listType && action.listType !== listType) {
            return state;
        }

        const [listRequestType, listSuccessType, listFailureType] = listActions;

        switch (type) {
            case listRequestType: {
                const newLimit = payload.query.limit || 10;
                const stateFilters = withoutEmptyKeys(state.filters);
                const payloadFilters = withoutEmptyKeys(
                    payload[requestFilterKey],
                );
                if (
                    (requestFilterKey &&
                        !fastCompare(payloadFilters, stateFilters)) ||
                    newLimit !== state.limit
                ) {
                    return {
                        ...INITIAL_STATE,
                        filters: payload[requestFilterKey],
                        limit: newLimit,
                        isFetching: true,
                    };
                }
                return {
                    ...state,
                    isFetching: true,
                };
            }
            case listSuccessType: {
                const {meta} = payload;
                const {
                    current_page: currentPage,
                    last_page: lastPage,
                    total,
                } = meta || {};

                let currentState = state;
                if (currentPage < state.pages.length) {
                    currentState = {
                        ...INITIAL_STATE,
                        filters: currentState.filters,
                        limit: currentState.limit,
                    };
                }

                // Get rid of pages above pageCount or currentPage (remnants of previous fetch)
                const pages = state.pages.slice(
                    0,
                    Math.min(currentPage, lastPage - 1),
                );
                if (currentPage >= 1) {
                    pages[currentPage - 1] =
                        payload.ids || Object.keys(payload.data);
                }

                return {
                    ...currentState,
                    isFetching: false,
                    isFailed: false,
                    pageCount: lastPage,
                    total,
                    pages,
                };
            }
            case listFailureType:
                return {
                    ...state,
                    isFetching: false,
                    isFailed: true,
                };
            case LOGOUT_REQUEST:
                return INITIAL_STATE;
            default:
                return state;
        }
    };
};

export default paginate;
