import {schema} from 'normalizr';
import {CALL_API} from '../../../../constants/api';
import {POST} from '../../../services';

export const AVATAR_SCHEMA = new schema.Entity('avatar');

export const USERS_ME_AVATAR_REQUEST = 'USERS_ME_AVATAR_REQUEST';
export const USERS_ME_AVATAR_SUCCESS = 'USERS_ME_AVATAR_SUCCESS';
export const USERS_ME_AVATAR_FAILURE = 'USERS_ME_AVATAR_FAILURE';

export const setAvatar = body => ({
    [CALL_API]: {
        endpoint: '/users/me/avatar',
        method: POST,
        body,
        types: [
            USERS_ME_AVATAR_REQUEST,
            USERS_ME_AVATAR_SUCCESS,
            USERS_ME_AVATAR_FAILURE,
        ],
        schema: AVATAR_SCHEMA,
    },
});

export default {
    setAvatar,
};
