import {
    START_NEW_SURVEY,
    SET_SURVEY_CONTENT,
    SET_SURVEY_OPTIONS,
    CLEAR_ACTIVE_SURVEY,
    START_EDITING_SURVEY,
} from '../actions/surveyEditor';
import {CONTENT_TYPE_MESSAGES} from '../actions/editor';

const INITIAL_STATE = {
    contentId: null,
    contentType: CONTENT_TYPE_MESSAGES,
    id: null,
    type: '',
    content: {
        title: {},
        question: {},
        description: {},
        location: {},
    },
    options: [{label: {}, value: 0}],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SURVEY_CONTENT:
            return {...state, content: action.payload.content};
        case SET_SURVEY_OPTIONS:
            return {...state, options: action.payload.options};
        case START_NEW_SURVEY: {
            const {type} = action.payload;
            return {
                ...INITIAL_STATE,
                type,
            };
        }
        case START_EDITING_SURVEY: {
            const {survey} = action.payload;
            return {
                ...INITIAL_STATE,
                id: survey.id,
                type: survey.type,
                content: {...survey.content},
                options: [...survey.options],
            };
        }
        case CLEAR_ACTIVE_SURVEY:
            return INITIAL_STATE;
        default:
            return state;
    }
};
