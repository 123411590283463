import React from 'react';
import parse from 'date-fns/parse';
import PhoneMail from 'components/util/UserDetail/PhoneMail';
import Name from 'components/util/UserDetail/Name';
import UserDetailBlock from 'components/util/UserDetail/UserDetailBlock';
import AvatarEditor from 'lib/components/AvatarEditor';
import PropTypes from 'prop-types';
import Onboarding, {ON_FIRST_DAY_OF_WORK} from './Onboarding';

const UserDetailForm = ({
    user,
    options,
    onPropertyChange,
    onEditAvatarComplete,
    isSavingAvatar,
    isEditing,
}) => (
    <div className="row">
        {isEditing && (
            <div className="col-lg-4">
                <AvatarEditor
                    editable
                    onEditComplete={onEditAvatarComplete}
                    image={!isSavingAvatar && user.avatar && user.avatar.sizes}
                    saving={isSavingAvatar}
                />
            </div>
        )}
        <div className={isEditing ? 'col-lg-8' : 'col-12'}>
            <div className="form">
                <div className="row">
                    <div className="col-md-6">
                        <UserDetailBlock
                            label="role"
                            value={user.role}
                            type="select"
                            options={options.roleOptions}
                            onChange={({value}) =>
                                onPropertyChange({role: value})
                            }
                            editable
                        />
                    </div>
                    <div className="col-md-6">
                        <UserDetailBlock
                            label="language"
                            value={user.language}
                            type="select"
                            options={options.languageOptions}
                            onChange={({value}) =>
                                onPropertyChange({language: value})
                            }
                            editable
                        />
                    </div>
                </div>

                <Name
                    user={user}
                    onChange={obj => onPropertyChange(obj)}
                    editable
                />

                <div className="row">
                    <div className="col-md-12">
                        <UserDetailBlock
                            label="description"
                            value={user.description || ''}
                            type="textarea"
                            onChange={value =>
                                onPropertyChange({description: value})
                            }
                            editable
                        />
                    </div>
                </div>

                <PhoneMail
                    user={user}
                    onChange={obj => onPropertyChange(obj)}
                    editable
                />

                <div className="row">
                    <div className="col-md-6">
                        <UserDetailBlock
                            label="birthdate"
                            type="date"
                            value={user.birthdate && parse(user.birthdate)}
                            onChange={birthdate =>
                                onPropertyChange({birthdate})
                            }
                            editable
                        />
                    </div>
                    <div className="col-md-6">
                        <UserDetailBlock
                            label="first_day_of_work"
                            type="date"
                            value={
                                user.first_day_of_work &&
                                parse(user.first_day_of_work)
                            }
                            onChange={date =>
                                onPropertyChange({first_day_of_work: date})
                            }
                            editable
                            required={
                                user.onboarding_type === undefined ||
                                user.onboarding_type === ON_FIRST_DAY_OF_WORK
                            }
                        />
                    </div>
                </div>

                <Onboarding
                    user={user}
                    onChange={obj => onPropertyChange(obj)}
                />
            </div>
        </div>
    </div>
);

UserDetailForm.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        description: PropTypes.string,
        birthdate: PropTypes.instanceOf(Date),
        first_day_of_work: PropTypes.instanceOf(Date),
        onboarding_type: PropTypes.string,
        avatar: PropTypes.shape({
            sizes: PropTypes.object,
        }),
    }).isRequired,
    options: PropTypes.shape({
        roleOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        languageOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    onPropertyChange: PropTypes.func.isRequired,
    onEditAvatarComplete: PropTypes.func.isRequired,
    isSavingAvatar: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
};
export default UserDetailForm;
