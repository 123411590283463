import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Input from 'components/util/Input';

const RepeatHourly = ({hourly: {interval}, handleChange, translate}) => (
    <div className="form-group react-rule__flex">
        <div className="react-rule__col">
            {translate('editor.recurrence.every')}
        </div>
        <div className="react-rule__col">
            <Input
                name="repeat.hourly.interval"
                aria-label="Repeat hourly interval"
                className="form-control"
                value={interval}
                type="number"
                min="1"
                onNativeChange={handleChange}
            />
        </div>
        <div className="react-rule__col">
            {translate('editor.recurrence.hours')}
        </div>
    </div>
);
RepeatHourly.propTypes = {
    hourly: PropTypes.shape({
        interval: PropTypes.number.isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatHourly);
