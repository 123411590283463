import React from 'react';
import {Translate} from 'react-localize-redux';
import Select from 'components/util/Select';
import State from 'lib/components/State';

const MessageSelect = ({trigger, messages, fetchMessages, onChange}) => (
    <State initialState={{inputValue: ''}}>
        {({state, setState}) => (
            <div className="form__group">
                <label className="label" htmlFor="default_trigger">
                    <Translate id="Search for the message that needs to be attached" />
                </label>
                <Select
                    inputId="default_trigger"
                    value={
                        trigger &&
                        trigger.parameters &&
                        trigger.parameters.message_id
                    }
                    placeholder={<Translate id="Search message" />}
                    options={messages}
                    onChange={option => onChange(option)}
                    onInputChange={inputValue => {
                        const isNewValue = state.inputValue !== inputValue;
                        const isEmpty = inputValue === '';
                        const isOnlySpaces =
                            !isEmpty && inputValue.replace(/\s/g, '') === '';

                        if (isNewValue && !isOnlySpaces) {
                            setState({inputValue});
                            fetchMessages(inputValue);
                        }
                    }}
                    onSelectResetsInput={false}
                    isSearch
                />
            </div>
        )}
    </State>
);

export default MessageSelect;
