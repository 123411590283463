import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M0 5.672h24v12.656H0zm2.531-3.797H21.47v2.531H2.53zM.094 0h.703v.094H.094zM2.53 19.594H21.47v2.531H2.53zM.094.656h.703V.75H.094zm0 0"
        />
    );
}
