import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M0.0107422 7.18676C0.0107422 5.37713 1.32235 3.84558 3.0129 3.84558H20.9982C22.6926 3.84558 24.0004 5.37753 24.0004 7.18676V20.6588C24.0004 22.4681 22.6926 24 20.9982 24H3.0129C1.32235 24 0.0107422 22.4685 0.0107422 20.6588V7.18676ZM3.0129 4.7399C1.86731 4.7399 0.87542 5.79831 0.87542 7.18676V20.6588C0.87542 22.0473 1.86731 23.1057 3.0129 23.1057H20.9982C22.1469 23.1057 23.1357 22.0477 23.1357 20.6588V7.18676C23.1357 5.7979 22.1469 4.7399 20.9982 4.7399H3.0129Z" />
            <path d="M6.11615 14.3085C6.26508 14.1007 6.50567 13.9264 6.81026 13.9264H10.2309C10.5355 13.9264 10.7761 14.1007 10.925 14.3085C11.0743 14.5169 11.1544 14.7841 11.1544 15.0604V18.2549C11.1544 18.5311 11.0743 18.7983 10.925 19.0067C10.7761 19.2146 10.5355 19.3889 10.2309 19.3889H6.81026C6.50567 19.3889 6.26508 19.2146 6.11615 19.0067C5.96683 18.7983 5.88678 18.5311 5.88678 18.2549V15.0604C5.88678 14.7841 5.96683 14.5169 6.11615 14.3085ZM6.81088 14.841C6.78208 14.8812 6.75146 14.9574 6.75146 15.0604V18.2549C6.75146 18.3578 6.78208 18.4341 6.81088 18.4743C6.81813 18.4844 6.82389 18.4907 6.82792 18.4946H10.2133C10.2173 18.4907 10.223 18.4844 10.2303 18.4743C10.2591 18.4341 10.2897 18.3578 10.2897 18.2549V15.0604C10.2897 14.9574 10.2591 14.8812 10.2303 14.841C10.223 14.8308 10.2173 14.8245 10.2133 14.8207H6.82792C6.82389 14.8245 6.81813 14.8308 6.81088 14.841ZM6.8346 14.8156C6.83462 14.8157 6.83388 14.8162 6.83233 14.8169C6.8338 14.8158 6.83458 14.8155 6.8346 14.8156ZM10.2066 14.8156C10.2066 14.8155 10.2074 14.8158 10.2089 14.8169C10.2073 14.8162 10.2066 14.8157 10.2066 14.8156ZM10.2066 18.4997C10.2066 18.4996 10.2073 18.499 10.2089 18.4983C10.2074 18.4994 10.2066 18.4998 10.2066 18.4997ZM6.8346 18.4997C6.83458 18.4998 6.83381 18.4994 6.83233 18.4983C6.83388 18.499 6.83462 18.4996 6.8346 18.4997Z" />
            <path d="M6.31912 0C6.55789 0 6.75146 0.200201 6.75146 0.447161V5.83455C6.75146 6.08151 6.55789 6.28171 6.31912 6.28171C6.08034 6.28171 5.88678 6.08151 5.88678 5.83455V0.447161C5.88678 0.200201 6.08034 0 6.31912 0Z" />
            <path d="M17.6809 0C17.9197 0 18.1133 0.200201 18.1133 0.447161V5.83455C18.1133 6.08151 17.9197 6.28171 17.6809 6.28171C17.4422 6.28171 17.2486 6.08151 17.2486 5.83455V0.447161C17.2486 0.200201 17.4422 0 17.6809 0Z" />
            <path d="M0 9.41539C0 9.16843 0.193565 8.96823 0.432339 8.96823H23.5677C23.8064 8.96823 24 9.16843 24 9.41539C24 9.66235 23.8064 9.86255 23.5677 9.86255H0.432339C0.193565 9.86255 0 9.66235 0 9.41539Z" />
        </g>
    );
}
