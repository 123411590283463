import React from 'react';
import {Translate} from 'react-localize-redux';
import ContentView from 'components/util/ContentView';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import SearchInput from 'lib/components/SearchInput';
import {getById} from 'lib/store/reducers/helpers/byId';
import AutomatedMessage from 'components/AutomatedMessage/AutomatedMessage';

const AutomatedMessages = ({
    pages = [],
    automatedMessages,
    filters = {},
    isFetching,
    onSearchQueryChange,
    onMessageClick,
    onNewMessageClick,
    total,
}) => {
    const messages = [];
    if (pages.length > 0) {
        pages.forEach(page => {
            page.forEach(messageId => {
                const message = getById(automatedMessages, messageId);
                messages.push(message);
            });
        });
    }

    const headerComponents = [
        <Translate key="translate-search-input">
            {({translate}) => (
                <SearchInput
                    key={translate.id}
                    placeholder={translate('automated_messages.search')}
                    value={filters.query || ''}
                    onChange={query => onSearchQueryChange(query)}
                />
            )}
        </Translate>,
    ];

    return (
        <ContentView
            title={<Translate id="automated_messages.title" />}
            headerComponents={headerComponents}
            total={total}>
            <div className="l-automated-messages">
                <LoadingWrapper loading={isFetching}>
                    {messages &&
                        messages.map(
                            message =>
                                message && (
                                    <AutomatedMessage
                                        key={message.id}
                                        id={message.id}
                                        title={message.title}
                                        active={message.status === 'active'}
                                        message={message}
                                        onClick={() => onMessageClick(message)}
                                        appDomain={message.app_domain}
                                    />
                                ),
                        )}
                    <AutomatedMessage
                        add
                        onClick={() => {
                            onNewMessageClick();
                        }}
                    />
                </LoadingWrapper>
            </div>

            <style jsx>
                {`
                    .l-automated-messages {
                        overflow-y: auto;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        margin: 0 -1.5rem;
                        width: 100%;

                        :global(.automated-message) {
                            padding: 0 1.5rem;
                            margin-bottom: 3rem;
                        }
                    }
                `}
            </style>
        </ContentView>
    );
};

export default AutomatedMessages;
