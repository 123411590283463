import './SmartTagEntity.css';

import React from 'react';

export const SMART_TAG = 'SMART_TAG';

const SmartTagEntity = props => (
    <span className="smart-tag">{props.children}</span>
);

export default SmartTagEntity;
