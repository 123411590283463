import React from 'react';
import {Pie, PieChart, Cell, Label} from 'recharts';
import tinycolor from 'tinycolor2';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';

const PercentageChart = ({percentage, loading, total}) => {
    const lightColor = tinycolor(colors.brand.base).lighten(25).toRgbString();
    const data = [
        {name: 'inactive', value: 100 - percentage, color: lightColor},
        {name: 'active', value: percentage, color: colors.brand.base},
    ];

    return (
        <div className="percentageChart">
            <div className="loading">
                <LoadingWrapper loading={loading} />
            </div>

            <PieChart width={180} height={180}>
                <Pie
                    data={data}
                    dataKey="value"
                    innerRadius={75}
                    outerRadius={75}
                    startAngle={-40}
                    endAngle={220}
                    paddingAngle={
                        percentage === 0 || percentage === 100 ? 0 : 2
                    }>
                    {data.map(entry => (
                        <Cell
                            key={entry.name}
                            fill={entry.color}
                            strokeWidth={30}
                            stroke={entry.color}
                        />
                    ))}

                    <Label
                        position="center"
                        fontSize={`${theme.fontSizeLargest}rem`}
                        fontWeight={600}
                        fill={colors.brand.base}
                        opacity={loading ? 0 : 1}>
                        {`${Math.min(percentage, 100)}%`}
                    </Label>

                    <Label
                        content={({viewBox: {cx, cy}}) => (
                            <text x={cx} y={cy + 55} textAnchor="middle">
                                <tspan
                                    position="center"
                                    fontSize={`${theme.fontSizeLarge}rem`}
                                    fontWeight={600}
                                    fill={colors.text.base}
                                    opacity={loading ? 0 : 1}>
                                    {total}
                                </tspan>
                            </text>
                        )}
                    />
                </Pie>
            </PieChart>

            <style jsx>
                {`
                    .percentageChart {
                        position: relative;
                    }

                    .loading {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .percentageChart :global(text) {
                        transition: opacity ${theme.defaultTransition};
                    }
                `}
            </style>
        </div>
    );
};

export default PercentageChart;
