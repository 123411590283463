import React from 'react';

export default function () {
    return (
        <g fill="none">
            <path
                d="M18.3533 19.6154H5.69231C5.16187 19.6154 4.65317 19.4047 4.27809 19.0296C3.90302 18.6545 3.69231 18.1458 3.69231 17.6154V6.61538C3.69231 6.08495 3.90302 5.57624 4.27809 5.20117C4.65317 4.8261 5.16187 4.61538 5.69231 4.61538H18.3533C18.8837 4.61538 19.3924 4.8261 19.7675 5.20117C20.1426 5.57624 20.3533 6.08495 20.3533 6.61538V17.6154C20.3533 18.1458 20.1426 18.6545 19.7675 19.0296C19.3924 19.4047 18.8837 19.6154 18.3533 19.6154Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.68931 11.2824C9.60997 11.2824 10.3563 10.536 10.3563 9.61538C10.3563 8.69473 9.60997 7.94838 8.68931 7.94838C7.76865 7.94838 7.02231 8.69473 7.02231 9.61538C7.02231 10.536 7.76865 11.2824 8.68931 11.2824Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.3533 15.4464L16.9703 12.7394C16.8678 12.6573 16.75 12.5962 16.6239 12.5596C16.4977 12.523 16.3656 12.5116 16.235 12.5261C16.1044 12.5406 15.978 12.5806 15.8629 12.644C15.7478 12.7074 15.6464 12.7928 15.5643 12.8954L12.9923 16.1104C12.9103 16.213 12.8088 16.2984 12.6937 16.3618C12.5786 16.4251 12.4522 16.4652 12.3216 16.4797C12.1911 16.4942 12.0589 16.4828 11.9327 16.4462C11.8066 16.4096 11.6889 16.3485 11.5863 16.2664L10.2873 15.2274C10.0829 15.0639 9.82246 14.9871 9.56201 15.0134C9.30156 15.0398 9.06183 15.1672 8.89431 15.3684L5.38131 19.5844"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
