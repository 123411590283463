/* eslint-disable no-underscore-dangle */
import {applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import throttle from 'lodash/throttle';
import initLocale from 'locale/index';
import api from 'lib/store/middleware/api';
import {
    loadState,
    saveState,
    removeState,
} from 'lib/store/helpers/stateStorage';
import reducer from '../reducers';

const configureStore = () => {
    let middleware = applyMiddleware(thunk, api, createLogger);
    if (process.env.REACT_APP_DISABLE_LOG) {
        middleware = applyMiddleware(thunk, api);
    }
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(reducer, loadState(), middleware);

    store.subscribe(
        throttle(() => {
            const {accessToken, refreshToken, expiresIn, remember} =
                store.getState().auth;
            if (remember && refreshToken) {
                saveState({
                    auth: {
                        accessToken,
                        refreshToken,
                        expiresIn,
                        remember,
                    },
                });
            } else {
                removeState();
            }
        }, 1000),
    );

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(reducer);
        });
    }

    initLocale(store);

    return store;
};

export default configureStore;
