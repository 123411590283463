import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import UserDetailBlock from 'components/util/UserDetail/UserDetailBlock';
import LoadingWrapper from 'lib/components/LoadingWrapper';

const Segmentation = ({
    user,
    onChange,
    getSegmentGroups,
    isFetchingSegments,
    availableSegments,
}) => {
    const availableSegmentsValues =
        availableSegments && Object.values(availableSegments);
    const segmentGroups =
        availableSegmentsValues &&
        availableSegmentsValues.filter(
            segmentGroup => segmentGroup.segment_group_type === 'editable',
        );

    useEffect(() => {
        getSegmentGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LoadingWrapper loading={isFetchingSegments}>
            {segmentGroups &&
                segmentGroups.map(({name, id, segments}) => {
                    const options =
                        segments &&
                        segments.map(({name: label, id: value}) => ({
                            label,
                            value,
                        }));
                    let mappedValue = user.segment_groups[id];
                    if (typeof mappedValue !== 'undefined') {
                        mappedValue = mappedValue.map(({value}) => value);
                    }

                    return (
                        <UserDetailBlock
                            key={name}
                            translatedLabel={name}
                            value={mappedValue}
                            type="select"
                            options={options}
                            onChange={values =>
                                onChange({
                                    segment_groups: {
                                        ...user.segment_groups,
                                        [id]: values || [],
                                    },
                                })
                            }
                            editable
                            multi
                        />
                    );
                })}
        </LoadingWrapper>
    );
};

const mapStateToProps = ({segmentation}) => ({
    availableSegments: segmentation.segments.data,
    isFetchingSegments: segmentation.segments.isFetching,
});

const mapDispatchToProps = dispatch => ({
    getSegmentGroups: () => dispatch(actions.segmentation.getSegmentGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Segmentation);
