import {combineReducers} from 'redux';
import {
    FETCH_APP_DOMAINS_ANALYTICS_REQUEST,
    FETCH_APP_DOMAINS_ANALYTICS_SUCCESS,
    FETCH_APP_DOMAINS_ANALYTICS_FAILURE,
    FETCH_APP_DOMAINS_ANALYTICS_EXPORT_SUCCESS,
    FETCH_APP_DOMAINS_ANALYTICS_EXPORT_FAILURE,
    FETCH_APP_DOMAINS_ANALYTICS_EXPORT_REQUEST,
} from '../../actions/analytics/appDomains';

export default () => {
    const domains = (state = [], action) => {
        switch (action.type) {
            case FETCH_APP_DOMAINS_ANALYTICS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const isLoading = (state = false, action) => {
        switch (action.type) {
            case FETCH_APP_DOMAINS_ANALYTICS_REQUEST:
                return true;
            case FETCH_APP_DOMAINS_ANALYTICS_SUCCESS:
            case FETCH_APP_DOMAINS_ANALYTICS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const isFetchingAdminUsageExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_APP_DOMAINS_ANALYTICS_EXPORT_REQUEST:
                return true;
            case FETCH_APP_DOMAINS_ANALYTICS_EXPORT_SUCCESS:
            case FETCH_APP_DOMAINS_ANALYTICS_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const hasFetched = (state = false, action) => {
        switch (action.type) {
            case FETCH_APP_DOMAINS_ANALYTICS_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    return combineReducers({
        domains,
        isLoading,
        isFetchingAdminUsageExport,
        hasFetched,
    });
};
