import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import createById from '../helpers/byId';

import {
    DIRECTORIES_VIEW_REQUEST,
    DIRECTORIES_VIEW_SUCCESS,
    DIRECTORIES_VIEW_FAILURE,
    DIRECTORY_CREATE_REQUEST,
    DIRECTORY_CREATE_SUCCESS,
    DIRECTORY_CREATE_FAILURE,
    DIRECTORY_UPDATE_REQUEST,
    DIRECTORY_UPDATE_SUCCESS,
    DIRECTORY_UPDATE_FAILURE,
    DIRECTORY_DELETE_REQUEST,
    DIRECTORY_DELETE_SUCCESS,
    DIRECTORY_DELETE_FAILURE,
    FILE_CREATE_SUCCESS,
    FILE_DELETE_SUCCESS,
    FILE_UPDATE_SUCCESS,
} from '../../actions/directories';

const isFetching = (state = false, action) => {
    switch (action.type) {
        case DIRECTORIES_VIEW_REQUEST:
            return true;
        case DIRECTORIES_VIEW_SUCCESS:
        case DIRECTORIES_VIEW_FAILURE:
            return false;
        default:
            return state;
    }
};

const byIdReducer = createById({
    viewActions: [
        DIRECTORIES_VIEW_REQUEST,
        DIRECTORIES_VIEW_SUCCESS,
        DIRECTORIES_VIEW_FAILURE,
    ],
    createActions: [
        DIRECTORY_CREATE_REQUEST,
        DIRECTORY_CREATE_SUCCESS,
        DIRECTORY_CREATE_FAILURE,
    ],
    updateActions: [
        DIRECTORY_UPDATE_REQUEST,
        DIRECTORY_UPDATE_SUCCESS,
        DIRECTORY_UPDATE_FAILURE,
    ],
    deleteActions: [
        DIRECTORY_DELETE_REQUEST,
        DIRECTORY_DELETE_SUCCESS,
        DIRECTORY_DELETE_FAILURE,
    ],
});

const getDirectoryIdFromStateThatContainsFile = (state, fileId) => {
    const directoriesArray = Object.values(state);
    const directoriesArrayFiltered = directoriesArray.filter(directory => {
        const {files} = directory;
        if (typeof files === 'undefined') {
            return false;
        }

        const containsFile = files.reduce((result, file) => {
            if (result) {
                return result;
            }
            return file.id === fileId;
        }, false);
        return containsFile;
    });
    const directoryId = directoriesArrayFiltered[0].id;
    return directoryId;
};

const featureReducer = (state, action) => {
    switch (action.type) {
        case DIRECTORY_CREATE_SUCCESS: {
            const newDirectoryId = action.payload.ids;
            const parentId = action.payload.data[newDirectoryId].parent.id;

            return {
                ...state,
                [parentId]: {
                    ...state[parentId],
                    children: [
                        ...state[parentId].children,
                        action.payload.data[newDirectoryId],
                    ],
                },
            };
        }
        case DIRECTORY_UPDATE_SUCCESS: {
            const directoryId = action.payload.ids;
            const parentId = action.payload.data[directoryId].parent.id;
            const index = state[parentId].children.findIndex(
                child => child.id === directoryId,
            );

            return {
                ...state,
                [parentId]: {
                    ...state[parentId],
                    children: [
                        ...state[parentId].children.slice(0, index),
                        action.payload.data[directoryId],
                        ...state[parentId].children.slice(index + 1),
                    ],
                },
            };
        }
        case DIRECTORY_DELETE_SUCCESS: {
            const directoryId = action.payload.ids;
            const parentId = action.payload.data[directoryId].parent.id;
            const index = state[parentId].children.findIndex(
                child => child.id === directoryId,
            );

            return {
                ...state,
                [parentId]: {
                    ...state[parentId],
                    children: [
                        ...state[parentId].children.slice(0, index),
                        ...state[parentId].children.slice(index + 1),
                    ],
                },
            };
        }
        case FILE_CREATE_SUCCESS: {
            const fileId = action.payload.ids;
            const directoryId = action.payload.data[fileId].directory.id;
            const index = state[directoryId].files.findIndex(
                file => file.name > action.payload.data[fileId].name,
            );

            return {
                ...state,
                [directoryId]: {
                    ...state[directoryId],
                    files: [
                        ...state[directoryId].files.slice(0, index),
                        action.payload.data[fileId],
                        ...state[directoryId].files.slice(index),
                    ],
                },
            };
        }
        case FILE_UPDATE_SUCCESS: {
            // find directory from state that contains updated file
            const updatedFileId = action.payload.ids;
            const directoryId = getDirectoryIdFromStateThatContainsFile(
                state,
                updatedFileId,
            );
            const index = state[directoryId].files.findIndex(
                file => file.id === updatedFileId,
            );

            return {
                ...state,
                [directoryId]: {
                    ...state[directoryId],
                    files: [
                        ...state[directoryId].files.slice(0, index),
                        action.payload.data[updatedFileId],
                        ...state[directoryId].files.slice(index + 1),
                    ],
                },
            };
        }
        case FILE_DELETE_SUCCESS: {
            // find directory from state that contained deleted file
            const deletedFileId = action.payload.ids;
            const directoryId = getDirectoryIdFromStateThatContainsFile(
                state,
                deletedFileId,
            );
            const index = state[directoryId].files.findIndex(
                file => file.id === deletedFileId,
            );

            return {
                ...state,
                [directoryId]: {
                    ...state[directoryId],
                    files: [
                        ...state[directoryId].files.slice(0, index),
                        ...state[directoryId].files.slice(index + 1),
                    ],
                },
            };
        }
        default:
            return state;
    }
};

const directories = combineReducers({
    isFetching,
    byId: reduceReducers(byIdReducer, featureReducer),
});

export default directories;
