import React from 'react';
import {useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import actions from 'store/actions';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import {WARNING} from 'lib/store/actions/notifications';
import EditorSidebarSwitch from './EditorSidebarSwitch';

const EditorSidebarAcceptAnswersToggleWithState = ({canEdit}) => {
    const {editor, hasSurveys} = useEditorState();
    const {saveContent} = useContentSaver();

    const dispatch = useDispatch();
    const setAcceptAnswers = acceptAnswers =>
        dispatch(actions.editor.setAcceptAnswers(acceptAnswers));

    const onChangeAcceptAnswers = async acceptAnswers => {
        await setAcceptAnswers(acceptAnswers);
        saveContent({...editor, acceptAnswers});

        if (acceptAnswers) {
            dispatch(
                actions.notifications.addNotification({
                    type: WARNING,
                    content: {
                        key: 'notifications.enable_answers_warning',
                    },
                }),
            );
        }
    };

    if (!hasSurveys) {
        return null;
    }

    return (
        <EditorSidebarSwitch
            canEdit={canEdit}
            onChange={onChangeAcceptAnswers}
            value={editor.acceptAnswers}
            label={<Translate id="editor.accept_answers" />}
        />
    );
};

export default EditorSidebarAcceptAnswersToggleWithState;
