import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {BrandingContext as Branding} from './providers/BrandingProvider';
import Loading from './Loading';

const LoadingWrapper = ({children, loading, size}) => {
    const [delayedLoading, setDelayedLoading] = useState(false);

    useEffect(() => {
        // Set delayedLoading to true after a delay
        let timeout;
        if (loading && !delayedLoading) {
            timeout = setTimeout(() => {
                setDelayedLoading(true);
            }, 600);
        }

        // Set delayedLoading to false after loading is done
        if (!loading) {
            clearTimeout(timeout);
            setDelayedLoading(false);
        }
        return () => clearTimeout(timeout);
    }, [delayedLoading, loading, setDelayedLoading]);

    const isLoading = loading && delayedLoading;

    return loading ? (
        <Branding.Consumer>
            {colors => (
                <div className={classNames('loader', {isLoading})}>
                    <Loading
                        color={colors && colors.brand && colors.brand.base}
                    />

                    <style jsx>
                        {`
                            .loader {
                                width: ${size ? `${size}rem` : '100%'};
                                max-width: 10rem;
                                margin: 0 auto;
                                max-height: 100%;
                                text-align: center;
                                opacity: 0;
                                transition: opacity 0.6s;

                                &.isLoading {
                                    opacity: 1;
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    ) : (
        children || <div />
    );
};

export default LoadingWrapper;
