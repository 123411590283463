import React from 'react';
import UserDetailFormWithState from 'components/UserDetailForm/UserDetailFormWithState';
import UserDetailForm from 'components/UserDetailForm/UserDetailForm';
import Segmentation from 'components/UserDetailForm/Segmentation';
import {transformMobile} from 'lib/components/PhoneInput';
import Modal from 'modals/Modal/ModalWithState';
import {MODAL_USER_DETAIL_ADMIN} from 'lib/store/actions/modals';
import {
    USER,
    SUPER_USER,
    ADMIN,
    SUPER_ADMIN,
    ROGER_STAFF,
    SUPER_USER_ADVANCED,
} from 'util/getUserRoleHelper';
import {Translate} from 'react-localize-redux';
import {format} from 'date-fns';

import PropTypes from 'prop-types';
import {throwIfInvalidApiResponse} from 'util/errorHelper';

const UserModal = ({
    selectedUser,
    selectedUserRole,
    setIsSaving,
    isSaving,
    isSendingInvite,
    onSuccess,
    onSaveUser,
    onInvite,
    onImpersonate,
    onCloseModal,
    onEditAvatarComplete,
    isSavingAvatar,
    isRogerStaff,
}) => (
    <UserDetailFormWithState key={selectedUser.uuid} initialUser={selectedUser}>
        {({user, options, onPropertyChange}) => {
            const roleIsChanged = selectedUserRole !== user.role;

            const buttonActions = [
                {
                    loading: isSaving,
                    label: user.id ? (
                        <Translate id="global.save" />
                    ) : (
                        <Translate id="global.add" />
                    ),
                    callBack: async () => {
                        setIsSaving(true);

                        // Map segment groups
                        const segmentGroups = {...user.segment_groups};
                        const mappedSegmentGroups = Object.keys(
                            segmentGroups,
                        ).map(id => ({
                            id,
                            segments: segmentGroups[id].map(({value}) => ({
                                id: value,
                            })),
                        }));

                        // Format date for API
                        const userToSave = {
                            ...user,
                            work_mobile: transformMobile(user.work_mobile),
                            mobile: transformMobile(user.mobile),
                            first_day_of_work:
                                user.first_day_of_work &&
                                format(user.first_day_of_work, 'YYYY-MM-DD'),
                            onboarding_at:
                                user.onboarding_at &&
                                format(user.onboarding_at, 'YYYY-MM-DD'),
                            birthdate:
                                user.birthdate &&
                                format(user.birthdate, 'YYYY-MM-DD'),
                            segment_groups: mappedSegmentGroups,
                        };

                        try {
                            const result = await onSaveUser(userToSave);
                            throwIfInvalidApiResponse(result);
                            const {payload} = result;
                            if (roleIsChanged || !user.id) {
                                // Update selecteduser when adding new user or changing user role to anything but User
                                const userId = payload.ids;
                                const response = payload.data[userId];
                                const savedUser = {
                                    ...response,
                                    ...userToSave,
                                    segment_groups: response.segment_groups, // Unmap segment groups
                                };
                                onSuccess(savedUser);
                            } else {
                                // Clear selecteduser and close modal when updating and when selectedRole is not user
                                onSuccess(null);
                            }
                        } catch (error) {
                            //
                        }

                        setIsSaving(false);
                    },
                    classes: 'success',
                },
            ];

            const isEditing = user.id;
            const userHasAdminAccess = [
                SUPER_USER,
                SUPER_USER_ADVANCED,
                ADMIN,
                SUPER_ADMIN,
                ROGER_STAFF,
            ].includes(user.role);

            if (isEditing) {
                buttonActions.push({
                    loading: isSendingInvite,
                    disabled: roleIsChanged,
                    label: <Translate id="users.invite" />,
                    callBack: () => onInvite(user, userHasAdminAccess),
                    left: true,
                });
            }

            if (user.role !== USER && isRogerStaff) {
                buttonActions.push({
                    label: 'Impersonate',
                    callBack: () => onImpersonate(user),
                    left: true,
                });
            }

            return (
                <Modal
                    flex
                    name={MODAL_USER_DETAIL_ADMIN}
                    title={
                        isEditing ? (
                            <Translate id="users.edit" />
                        ) : (
                            <Translate id="users.add" />
                        )
                    }
                    big={isEditing}
                    cancelable
                    buttonActions={buttonActions}
                    onClose={onCloseModal}
                    menu={[
                        {key: 'data', label: <Translate id="users.data" />},
                        {
                            key: 'segmentation',
                            label: <Translate id="users.segmentation" />,
                        },
                    ]}>
                    {({activeTab}) =>
                        (() => {
                            switch (activeTab) {
                                case 'data':
                                    return (
                                        <UserDetailForm
                                            user={user}
                                            isEditing={isEditing}
                                            options={options}
                                            onPropertyChange={onPropertyChange}
                                            onEditAvatarComplete={
                                                onEditAvatarComplete
                                            }
                                            isSavingAvatar={isSavingAvatar}
                                        />
                                    );
                                default:
                                case 'segmentation':
                                    return (
                                        <div>
                                            <Segmentation
                                                user={user}
                                                onChange={onPropertyChange}
                                            />
                                        </div>
                                    );
                            }
                        })()
                    }
                </Modal>
            );
        }}
    </UserDetailFormWithState>
);

UserModal.propTypes = {
    selectedUser: PropTypes.shape({uuid: PropTypes.string}).isRequired,
    selectedUserRole: PropTypes.string.isRequired,
    setIsSaving: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isSendingInvite: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onSaveUser: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    onImpersonate: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onEditAvatarComplete: PropTypes.func.isRequired,
    isSavingAvatar: PropTypes.bool.isRequired,
    isRogerStaff: PropTypes.bool.isRequired,
};

export default UserModal;
