import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M7.504 11a1.999 1.999 0 11-4 0 1.999 1.999 0 114 0zm0 0M20.504 11a1.999 1.999 0 11-4 0 1.999 1.999 0 114 0zm0 0" />
            <path d="M21.004 6h-2.078a.904.904 0 01-.7-.3l-3.148-3.126A1.989 1.989 0 0013.652 2H5.328a2.022 2.022 0 00-1.426.574L.801 5.7C.605 5.891.5 6.152.5 6.426V9a2.01 2.01 0 002.004 2h.25a2.749 2.749 0 115.5 0h7.5a2.749 2.749 0 115.5 0h1.25c.547 0 .996-.45 1-1V8.5a2.512 2.512 0 00-2.5-2.5zM8.254 6H1.926l2.703-2.7a.975.975 0 01.7-.3h2.925zm1 0V3h4.422a.912.912 0 01.703.3l2.7 2.7zm0 0M13.254 12.75h2.5v-1h-7.5v1h2.5V21H.5v1h23.004v-1h-10.25zm0 0" />
        </g>
    );
}
