import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ImageLoader from './ImageLoader';

class ImageWrapper extends Component {
    constructor() {
        super();

        this.state = {
            smallSrcLoaded: false,
            bigSrcLoaded: false,
        };
    }

    _onSmallSrcLoaded() {
        this.setState({
            smallSrcLoaded: true,
            bigSrcLoaded: false,
        });
    }

    _onBigSrcLoaded() {
        this.setState({
            bigSrcLoaded: true,
        });
    }

    render() {
        const {
            smallSrc,
            disableProgressiveLoading,
            src,
            alt,
            retryIfNotFound,
            className,
            hideOnFail,
            ...props
        } = this.props;
        const {smallSrcLoaded, bigSrcLoaded} = this.state;

        let smallImage;
        if (!bigSrcLoaded) {
            smallImage = (
                <ImageLoader
                    {...props}
                    className={`${className || ''} is-progressive is-loading`}
                    src={smallSrc}
                    alt={alt}
                    retryIfNotFound={retryIfNotFound}
                    onLoad={() => this._onSmallSrcLoaded()}
                    hideOnFail={hideOnFail}
                />
            );
        }

        let bigImage;
        if (smallSrcLoaded) {
            bigImage = (
                <ImageLoader
                    {...props}
                    className={`${className || ''} is-progressive`}
                    src={src}
                    alt={alt}
                    retryIfNotFound={retryIfNotFound}
                    onLoad={() => this._onBigSrcLoaded()}
                    disableLoader
                    hideOnFail={hideOnFail}
                />
            );
        }

        return (
            <div className="image-wrapper">
                {smallSrc && !disableProgressiveLoading ? (
                    <Fragment>
                        {smallImage}
                        {bigImage}
                    </Fragment>
                ) : (
                    <ImageLoader
                        {...props}
                        className={className}
                        alt={alt}
                        src={src}
                        retryIfNotFound={retryIfNotFound}
                        hideOnFail={hideOnFail}
                    />
                )}

                <style jsx>
                    {`
                        .image-wrapper {
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            width: 100%;

                            :global(img.is-progressive) {
                                width: 100%;
                                max-width: none;
                            }

                            :global(img.is-loading) {
                                filter: blur(5px);
                                display: block;
                                transform: scale(1.01);
                            }
                        }
                    `}
                </style>
            </div>
        );
    }
}

ImageWrapper.propTypes = {
    smallSrc: PropTypes.string,
    disableProgressiveLoading: PropTypes.bool,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    retryIfNotFound: PropTypes.bool,
    className: PropTypes.string,
};

ImageWrapper.defaultProps = {
    smallSrc: '',
    disableProgressiveLoading: false,
    alt: '',
    retryIfNotFound: false,
    className: '',
};

export default ImageWrapper;
