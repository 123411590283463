import React from 'react';
import * as colors from 'lib/styles/colors';
import LoadingWrapper from '../../LoadingWrapper';

const LoaderBlock = ({loading, children}) =>
    loading ? (
        <div className={`loader-block ${loading ? 'isLoading' : ''}`}>
            <LoadingWrapper loading={loading}>{children}</LoadingWrapper>

            <style jsx>
                {`
                    .loader-block.isLoading {
                        width: 100%;
                        min-height: 25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: ${colors.brand[200]};

                        .loading {
                            width: auto;
                        }
                    }
                `}
            </style>
        </div>
    ) : (
        children
    );

export default LoaderBlock;
