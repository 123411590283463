import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {SUCCESS} from 'lib/store/actions/notifications';
import actions from 'store/actions';
import Button from 'components/util/Button';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';

const AutomatedMessageConfigurationSidebarButtonsWithState = ({canEdit}) => {
    const [isSaving, setIsSaving] = useState(false);
    const {translate} = useTranslate();
    const {saveContent} = useContentSaver();
    const {editor, editorTitle} = useEditorState();
    const {trigger} = editor;

    const dispatch = useDispatch();
    const showNotification = (notificationKey, type) => {
        dispatch(
            actions.notifications.addNotification({
                type,
                content: {
                    key: notificationKey,
                },
            }),
        );
    };

    const onSaveContent = async () => {
        setIsSaving(true);
        try {
            await saveContent(editor);
            showNotification(
                'notifications.automated_message_updated',
                SUCCESS,
            );
        } catch (response) {
            //
        }
        setIsSaving(false);
    };

    if (!canEdit) {
        return null;
    }

    const hasTitle = editorTitle && editorTitle.length;
    return (
        <Button
            disabled={!hasTitle || !trigger}
            isLoading={isSaving}
            tabIndex={0}
            className={`button success full-width ${!hasTitle || !trigger ? 'disabled' : ''}`}
            onClick={onSaveContent}
            ghost>
            {translate('editor.save')}
        </Button>
    );
};

export default AutomatedMessageConfigurationSidebarButtonsWithState;
