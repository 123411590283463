/* eslint-disable react/sort-comp */
import React, {useRef, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MODAL_INPUT} from 'lib/store/actions/modals';
import CustomBlocks, {ATOMIC} from 'lib/constants/customBlocks';
import actions from 'store/actions';
import Modal from 'modals/Modal/ModalWithState';
import useTranslate from 'components/hooks/useTranslate';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';
import './EmbedModal.css';

const EmbedModal = () => {
    const inputRef = useRef();
    const [inputText, setInputText] = useState('');
    const {translate} = useTranslate();
    const {addBlock, updateBlockWithKey} = useManageEditorBlocks();
    const visible = useSelector(
        ({modals: {visibility}}) => visibility[MODAL_INPUT],
    );
    const embedModalData = useSelector(({embedModal: {data}}) => data);
    const {context: block} = embedModalData;

    const dispatch = useDispatch();
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));

    useEffect(() => {
        if (visible) {
            inputRef.current.focus();
        }
    }, [visible]);
    useEffect(() => {
        setInputText(embedModalData.content);
    }, [embedModalData]);

    const addEmbedBlock = () => {
        const blockData = {
            type: CustomBlocks.EMBED_BLOCK,
            embedUrl: inputText,
        };

        if (!block) {
            addBlock(blockData, ATOMIC);
        } else {
            const blockKey = block.getKey();
            updateBlockWithKey(blockData, blockKey);
        }

        setInputText('');
        setModalVisibility(MODAL_INPUT, false);
    };

    return (
        <Modal
            name={MODAL_INPUT}
            cancelable
            buttonActions={[
                {
                    label: embedModalData.editing
                        ? translate('global.edit')
                        : translate('global.add'),
                    callBack: addEmbedBlock,
                    classes: 'success',
                },
            ]}>
            <div className="embed-modal">
                <input
                    ref={inputRef}
                    className="embed-modal__input"
                    type="text"
                    value={inputText || ''}
                    onChange={e => setInputText(e.target.value)}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            addEmbedBlock();
                        }
                    }}
                    placeholder={translate('editor.paste_embed')}
                />
                <span className="embed-modal__supported">
                    {translate('editor.embed_description')}
                </span>
            </div>
        </Modal>
    );
};

export default EmbedModal;
