/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'lib/styles/theme';
import Icon, {star} from 'lib/components/Icon';

import PropTypes from 'prop-types';

const Scale = ({value: propsValue, interactive, type, maxValue, onChange}) => {
    const [value, setValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(null);

    useEffect(() => {
        if (propsValue) {
            setValue(propsValue);
        }
    }, [propsValue]);

    const onMouseEnter = index => {
        if (!interactive) {
            return;
        }

        setHoverValue(index);
    };

    const onClick = index => {
        if (!interactive) {
            return;
        }

        setValue(index);
        onChange(index);
    };

    const onMouseLeave = () => {
        if (!interactive) {
            return;
        }

        setHoverValue(null);
    };

    const scale = [];

    const max = maxValue || 10;

    for (let i = 1; i <= max; i += 1) {
        let isActive = value >= i;
        if (hoverValue) {
            isActive = hoverValue >= i;
        }

        switch (type) {
            case 'rating':
                scale.push(
                    <div
                        key={i}
                        className={classNames('scale-item', {isActive})}
                        onMouseEnter={() => {
                            onMouseEnter(i);
                        }}
                        onClick={() => {
                            onClick(i);
                        }}>
                        <Icon name={star} size="40" />
                    </div>,
                );
                break;
            case 'opinion':
            default:
                scale.push(
                    <div
                        key={i}
                        className={classNames('scale-item', {isActive})}
                        onMouseEnter={() => {
                            onMouseEnter(i);
                        }}
                        onClick={() => {
                            onClick(i);
                        }}>
                        {i}
                    </div>,
                );
                break;
        }
    }

    return (
        <Branding.Consumer>
            {colors => (
                <div
                    className={classNames(
                        'scale-component',
                        type || 'opinion',
                        {interactive},
                    )}
                    onMouseLeave={() => {
                        onMouseLeave();
                    }}>
                    {scale}
                    <style jsx>
                        {`
                            .scale-component {
                                display: flex;
                                flex-wrap: wrap;
                                margin-top: 2rem;
                                margin-bottom: -2rem;
                            }

                            .interactive {
                                :global(.scale-item) {
                                    display: block;
                                    cursor: pointer;
                                }
                            }

                            .rating {
                                font-size: 4rem;
                            }

                            .opinion {
                                :global(.scale-item) {
                                    border-radius: 50%;
                                    width: 4rem;
                                    height: 4rem;
                                    border: solid 0.2rem ${colors.grey[400]};
                                    font-weight: ${theme.fwBold};
                                    font-size: 1.6rem;
                                    line-height: 3.8rem;
                                    text-align: center;
                                }

                                :global(.isActive) {
                                    border: solid 0.2rem ${colors.brand.base};
                                }
                            }

                            :global(.scale-item) {
                                color: ${colors.grey[400]};
                                transition:
                                    color ${theme.defaultTransition},
                                    border ${theme.defaultTransition};
                                margin-right: 1rem;
                                margin-bottom: 2rem;
                                display: none;
                            }

                            :global(.scale-item.isActive) {
                                color: ${colors.brand.base};
                                display: block;
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

Scale.propTypes = {
    value: PropTypes.number.isRequired,
    interactive: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    maxValue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Scale;
