export const SET_LAST_ROOT_LOCATION = 'SET_LAST_ROOT_LOCATION';

export const setLastRootLocation = path => ({
    type: SET_LAST_ROOT_LOCATION,
    payload: path,
});

export default {
    setLastRootLocation,
};
