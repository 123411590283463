/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {useDispatch} from 'react-redux';
import Scale from 'lib/components/Scale';
import {MODAL_OPINION_SCALE} from 'lib/store/actions/modals';
import actions from 'store/actions';
import Modal from 'modals/Modal/ModalWithState';
import useSurveyEditor from 'components/hooks/useSurveyEditor';

const OpinionScaleModal = () => {
    const {
        survey,
        baseForm,
        isSaving,
        onSave,
        translate,
        language,
        headerTitleKey,
        buttonTitleKey,
    } = useSurveyEditor();
    const dispatch = useDispatch();
    const setOptions = options =>
        dispatch(actions.surveyEditor.setOptions(options));

    const onMaxScaleChange = value => {
        const options = [];
        let counter = 1;
        while (counter <= value) {
            options.push(counter);
            counter += 1;
        }
        setOptions(options);
    };

    return (
        <Modal
            name={MODAL_OPINION_SCALE}
            icon="scale"
            title={`${translate(headerTitleKey, {name: translate('editor.survey.opinion_scale')})} (${language})`}
            cancelable
            buttonActions={[
                {
                    label: translate(buttonTitleKey),
                    loading: isSaving,
                    callBack: () => {
                        onSave();
                    },
                    classes: 'success',
                },
            ]}>
            <div className="form">
                {baseForm}
                <div className="form_group">
                    <label className="label">
                        {translate('editor.form.scale.label')}
                    </label>
                    <Scale
                        interactive
                        type="opinion"
                        value={survey.options && survey.options.length}
                        onChange={value => {
                            onMaxScaleChange(value);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default OpinionScaleModal;
