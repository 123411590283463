import {useSelector} from 'react-redux';

const useCompany = () => {
    const {data, isLoading} = useSelector(({company}) => company);
    return {
        ...data,
        isLoadingCompany: isLoading,
    };
};

export default useCompany;
