import React from 'react';
import {Translate} from 'react-localize-redux';
import {Link} from 'react-router-dom';
import * as routes from 'routes/index';
import Button from 'components/util/Button';
import Header from './Header';

const ForgotPassword = ({email, setEmail, onSubmit}) => (
    <div className="l-login">
        <div className="l-login__container">
            <Header />
            <form onSubmit={onSubmit} className="form l-login__content">
                <div className="form__group">
                    <label className="label" htmlFor="email">
                        <Translate id="auth.email" />
                    </label>
                    <input
                        id="email"
                        type="email"
                        className="input"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>

                <div className="row">
                    <div className="col-6">
                        <Link
                            to={`${routes.login()}?email=${email}`}
                            tabIndex={-1}>
                            <Button transparent>
                                <Translate id="global.cancel" />
                            </Button>
                        </Link>
                    </div>
                    <div className="col-6">
                        <Button
                            type="submit"
                            className="button success full-width">
                            <Translate id="global.send" />
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
);

export default ForgotPassword;
