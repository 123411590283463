import React from 'react';
import {connect} from 'react-redux';
import {setActiveLanguage, getLanguages} from 'react-localize-redux';
import * as theme from '../styles/theme';
import {brandingDefaults as colors} from '../styles/colors';

const LangMenu = ({languages, setLanguage}) => (
    <ul className="lang-menu">
        {languages.map(language => (
            <li
                className={`lang-menu__item ${language.active ? 'is-active' : ''}`}
                key={language.code}
                onClick={() => setLanguage(language.code)}>
                {language.code}
            </li>
        ))}

        <style jsx>
            {`
                .lang-menu {
                    z-index: 1;
                    position: fixed;
                    top: 1rem;
                    right: 1rem;
                    display: flex;

                    &__item {
                        margin: 0 0.5rem;
                        cursor: pointer;
                        text-transform: uppercase;

                        &.is-active {
                            color: ${colors.brand.base};
                            font-weight: ${theme.fwBold};
                        }
                    }
                }
            `}
        </style>
    </ul>
);

const mapStateToProps = state => ({
    languages: getLanguages(state.localize),
});

const mapDispatchToProps = dispatch => ({
    setLanguage: language => dispatch(setActiveLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangMenu);
