import React from 'react';
import {getActiveLanguage} from 'react-localize-redux';
import {connect} from 'react-redux';
import {format} from 'date-fns';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'lib/styles/theme';
import getDateLocale from 'lib/helpers/getDateLocale';
import Icon, {location as locationIcon, clock} from './Icon';

const Event = ({dateTime, location, title, langCode}) => {
    const locale = getDateLocale(langCode);

    return (
        <Branding.Consumer>
            {colors => (
                <div className="event">
                    <div className="event__date">
                        <span className="event__date__day">
                            {dateTime ? format(dateTime, 'DD', locale) : '??'}
                        </span>
                        <span className="event__date__month">
                            {dateTime ? format(dateTime, 'MMM', locale) : '??'}
                        </span>
                    </div>
                    <div className="event__info">
                        {title && <h2 className="event__title ">{title}</h2>}
                        {location && (
                            <div className="event__location">
                                <Icon size={12} name={locationIcon} />
                                {location}
                            </div>
                        )}
                        <div className="event__time">
                            <Icon size={12} name={clock} />
                            {dateTime
                                ? format(dateTime, 'HH:mm', locale)
                                : '??'}
                        </div>
                    </div>
                    <style jsx>
                        {`
                            .event {
                                display: flex;
                                margin: 1rem 0;

                                &__date {
                                    width: 4rem;
                                    height: 4rem;
                                    border-radius: 0.4rem;
                                    border: solid 0.1rem ${colors.brand.base};
                                    margin-right: 1.4rem;
                                    font-weight: ${theme.fwBold};
                                    text-align: center;
                                    line-height: 1.2rem;
                                    padding: 0.7rem 0;

                                    &__day {
                                        font-size: 1.8rem;
                                        display: block;
                                    }

                                    &__month {
                                        font-size: 1.2rem;
                                        text-transform: uppercase;
                                    }
                                }

                                &__title {
                                    display: block;
                                    font-weight: ${theme.fwBold};
                                    font-size: 1.6rem;
                                    margin-bottom: 0.2rem;
                                }

                                &__info {
                                    flex-grow: 1;
                                }

                                &__time,
                                &__location {
                                    display: inline-block;
                                    color: ${colors.grey[400]};
                                    font-size: 1.2rem;

                                    :global(svg) {
                                        margin-right: ${theme.spacingSmallest}rem;
                                    }
                                }

                                &__location {
                                    margin-right: 1.8rem;
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

const mapStateToProps = state => ({
    langCode: getActiveLanguage(state.localize).code,
});

export default connect(mapStateToProps)(Event);
