import {combineReducers} from 'redux';
import createById, {getById} from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    TEMPLATES_LIST_REQUEST,
    TEMPLATES_LIST_SUCCESS,
    TEMPLATES_LIST_FAILURE,
    TEMPLATES_VIEW_REQUEST,
    TEMPLATES_VIEW_SUCCESS,
    TEMPLATES_VIEW_FAILURE,
    TEMPLATES_CREATE_REQUEST,
    TEMPLATES_CREATE_SUCCESS,
    TEMPLATES_CREATE_FAILURE,
    TEMPLATES_UPDATE_REQUEST,
    TEMPLATES_UPDATE_SUCCESS,
    TEMPLATES_UPDATE_FAILURE,
    TEMPLATES_DELETE_REQUEST,
    TEMPLATES_DELETE_SUCCESS,
    TEMPLATES_DELETE_FAILURE,
} from '../../actions/templates';

const templates = combineReducers({
    byId: createById({
        listActions: [
            TEMPLATES_LIST_REQUEST,
            TEMPLATES_LIST_SUCCESS,
            TEMPLATES_LIST_FAILURE,
        ],
        viewActions: [
            TEMPLATES_VIEW_REQUEST,
            TEMPLATES_VIEW_SUCCESS,
            TEMPLATES_VIEW_FAILURE,
        ],
        createActions: [
            TEMPLATES_CREATE_REQUEST,
            TEMPLATES_CREATE_SUCCESS,
            TEMPLATES_CREATE_FAILURE,
        ],
        updateActions: [
            TEMPLATES_UPDATE_REQUEST,
            TEMPLATES_UPDATE_SUCCESS,
            TEMPLATES_UPDATE_FAILURE,
        ],
        deleteActions: [
            TEMPLATES_DELETE_REQUEST,
            TEMPLATES_DELETE_SUCCESS,
            TEMPLATES_DELETE_FAILURE,
        ],
    }),
    paginated: paginate({
        listActions: [
            TEMPLATES_LIST_REQUEST,
            TEMPLATES_LIST_SUCCESS,
            TEMPLATES_LIST_FAILURE,
        ],
        deleteActions: [
            TEMPLATES_DELETE_REQUEST,
            TEMPLATES_DELETE_SUCCESS,
            TEMPLATES_DELETE_FAILURE,
        ],
        requestFilterKey: 'filters',
    }),
});

export default templates;

export const getTemplatesForAppDomain = (state, appDomain) => {
    const templatesObjects = Object.keys(state).map(templateId =>
        getById(state, templateId),
    );
    return templatesObjects.filter(
        template => template.app_domain.slug === appDomain,
    );
};
