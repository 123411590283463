import React from 'react';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';
import {isSameMonth, format, isToday} from 'date-fns';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {STATUS_PUBLISHED} from 'lib/store/actions/messages/index';
import {Translate} from 'react-localize-redux';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import Icon from 'lib/components/Icon';
import Message from './Message';

const Day = ({
    day,
    month,
    dayOfTheWeek,
    langCode,
    startNewMessage,
    collapsed,
    onShowMoreClick,
    containerRef,
    messageRef,
    canAdd,
    defaultLanguage,
}) => {
    const isOtherMonth = !isSameMonth(day.date, month);
    const isWeekend = dayOfTheWeek > 4;
    return (
        <div
            className={classNames('day', {
                isOtherMonth,
                isToday: isToday(day.date),
                isWeekend,
            })}>
            <div className="content">
                <div className="date">
                    {format(day.date, 'ddd DD/MM', getDateLocale(langCode))}
                </div>

                <div
                    className={classNames('messages-container', {collapsed})}
                    ref={containerRef}>
                    <div className="messages">
                        {canAdd && (
                            <button
                                className="add"
                                type="button"
                                onClick={() => startNewMessage(day.date)}>
                                <Icon name="add" size={12} />
                            </button>
                        )}
                        {!collapsed &&
                            day.messages &&
                            day.messages.map((message, messageIndex) => {
                                const users =
                                    message.status === STATUS_PUBLISHED
                                        ? message.sent_to_count
                                        : message.audience_count;

                                const {color} = message.app_domain;
                                let styling = {backgroundColor: color};

                                if (message.status === 'draft') {
                                    styling = {
                                        borderColor: color,
                                        color,
                                    };
                                }

                                return (
                                    <Message
                                        key={messageIndex}
                                        messageIndex={messageIndex}
                                        message={message}
                                        styling={styling}
                                        users={users}
                                        color={color}
                                        messageRef={messageRef}
                                        defaultLanguage={defaultLanguage}
                                    />
                                );
                            })}
                        {day.messages &&
                            day.messages.length > 0 &&
                            collapsed && (
                                <button
                                    className="show-more"
                                    onClick={onShowMoreClick}>
                                    <Translate
                                        id="planning.show_more"
                                        data={{count: day.messages.length}}
                                    />
                                </button>
                            )}
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .day {
                        flex: 1;
                        border-top: solid 0.1rem ${theme.colorBorder};
                        border-left: solid 0.1rem ${theme.colorBorder};
                        position: relative;
                        overflow: hidden;
                    }

                    .isOtherMonth .date {
                        opacity: 0.3;
                    }

                    .isToday {
                        background-color: ${colors.grey[100]};
                    }

                    .isWeekend {
                        background-color: ${tinycolor(colors.grey[100]).lighten(
                            4,
                        )};
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        justify-content: space-between;
                    }

                    .date {
                        padding: ${theme.spacingSmall}rem;
                        padding-bottom: 0;
                        font-size: ${theme.fontSizeSmaller}rem;
                        color: ${theme.colorGrey700};
                        text-transform: capitalize;
                    }

                    .messages {
                        display: flex;
                        flex-direction: column;
                    }

                    .add,
                    .day :global(.message),
                    .show-more {
                        border-radius: 0.4rem;
                        display: flex;
                        min-height: 3.4rem;
                        cursor: pointer;
                        margin: 0.25rem 0;
                    }

                    .add {
                        display: none;
                        background-color: transparent;
                        color: ${colors.grey[400]};
                        border: 0.1rem dashed ${colors.grey[400]};
                        font-size: 1.4rem;
                        justify-content: center;
                    }

                    .show-more {
                        padding: 0.5rem 1rem;
                        border: solid 0.1rem ${colors.brand.base};
                        background-color: transparent;
                        color: ${colors.brand.base};
                        transition:
                            background-color ${theme.defaultTransition},
                            color ${theme.defaultTransition};
                        cursor: pointer;
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .show-more:hover {
                        background-color: ${colors.brand.base};
                        color: ${colors.text.light};
                    }

                    .day:hover .add {
                        display: block;
                    }

                    .messages-container {
                        overflow-y: auto;
                        padding: 0.9rem;
                        padding-top: 0;
                    }
                `}
            </style>
        </div>
    );
};

export default Day;
