import React from 'react';
import CustomBlocks from 'lib/constants/customBlocks';

import Choice from '../../Choice/Choice';
import CustomBlock from '../CustomBlock/CustomBlock';

/* @TODO @REFACTOR
 * Muliple choice originally allowed multiple answers and the values were saved differently.
 * This whole block should be refactored to use one unified response value.
 */

const MultipleChoiceBlock = ({
    translate,
    survey,
    onDelete,
    onEdit,
    interactive,
    onChange,
    canEdit,
}) => {
    const _onClick = choice => {
        let response = survey.response && survey.response.option;

        // Create new array
        if (!Array.isArray(response)) {
            response = [choice];
        } else {
            response = [choice];
        }

        onChange(response);
    };

    return (
        <CustomBlock
            type={CustomBlocks.SURVEY_BLOCK}
            label={translate('editor.survey.multiple_choice')}
            icon="multipleChoice"
            title={survey.content.question}
            description={survey.content.description}
            onEdit={() => {
                onEdit(survey);
            }}
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            {survey.options.map((option, key) => (
                <Choice
                    key={key}
                    index={key}
                    interactive={interactive}
                    option={option}
                    survey={survey}
                    onClick={clickedChoice => _onClick(clickedChoice)}
                />
            ))}
        </CustomBlock>
    );
};

export default MultipleChoiceBlock;
