import {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';

const useFetchMessageCommentsData = ({
    messageId,
    filters,
    limit = 10,
    page = 0,
}) => {
    const initialParams = useRef({
        filters,
        limit,
        page,
    });
    const dispatch = useDispatch();
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const stateMessageComments = useSelector(state => state.messageComments);
    const stateMessageCommentsById = stateMessageComments.byId;
    const stateMessageCommentsPaginated = stateMessageComments.paginated;
    const {total, pages, pageCount, isFailed, isFetching} =
        stateMessageCommentsPaginated;

    const [params, setParams] = useState({
        ...initialParams.current,
        page: pageCount,
    });

    const fetchMessageComments = queryParams =>
        dispatch(
            actions.messageComments.fetchMessageComments(
                queryParams,
                messageId,
            ),
        );

    const handleFetch = (queryParams = {}) => {
        const newParams = {
            ...params,
            ...queryParams,
        };
        setParams(newParams);
        fetchMessageComments(newParams);
    };

    const hasMorePages = pages.length < pageCount;
    const handleFetchMore = async () => {
        if (!isFetchingMore && hasMorePages) {
            setIsFetchingMore(true);
            const updatedPage = pages.length + 1 || 1;
            await fetchMessageComments({
                ...params,
                page: updatedPage,
            });
            setIsFetchingMore(false);
        }
    };

    const messageComments = pages.reduce(
        (comments, commentPage) => [
            ...comments,
            ...commentPage.map(commentId =>
                getById(stateMessageCommentsById, commentId),
            ),
        ],
        [],
    );

    useEffect(() => {
        handleFetch({page: 1});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        totalComments: total,
        isFailed,
        isFetching,
        isFetchingMore,
        messageComments,
        handleFetch,
        handleFetchMore,
        hasMorePages,
    };
};

export default useFetchMessageCommentsData;
