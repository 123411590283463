import React from 'react';
import {ResponsiveContainer, BarChart, Bar} from 'recharts';
import RoundedBar from '../RoundedBar';

const BarChartWrapper = ({data, color}) => {
    const newData = data.map(item => ({value: item}));

    return (
        <ResponsiveContainer width="100%" height={155}>
            <BarChart
                data={newData}
                margin={{
                    top: 55,
                    right: 0,
                    bottom: 0,
                    left: 0,
                }}>
                <Bar dataKey="value" fill={color} shape={<RoundedBar />} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartWrapper;
