import {schema} from 'normalizr';
import {CALL_API} from '../../constants/api';
import {POST} from '../services';

export const CALCULATE_AUDIENCE_REQUEST = 'CALCULATE_AUDIENCE_REQUEST';
export const CALCULATE_AUDIENCE_SUCCESS = 'CALCULATE_AUDIENCE_SUCCESS';
export const CALCULATE_AUDIENCE_FAILURE = 'CALCULATE_AUDIENCE_FAILURE';

export const calculateAudience = (filters, parentId = null) => ({
    [CALL_API]: {
        endpoint: '/segment-groups/audience',
        method: POST,
        body: {
            audience_filters: filters,
            ...(parentId && {parent_dir_id: parentId}),
        },
        types: [
            CALCULATE_AUDIENCE_REQUEST,
            CALCULATE_AUDIENCE_SUCCESS,
            CALCULATE_AUDIENCE_FAILURE,
        ],
    },
});

export const AUDIENCE_SCHEMA = new schema.Entity('audience');

export const LIST_AUDIENCE_FROM_SEGMENT_REQUEST =
    'LIST_AUDIENCE_FROM_SEGMENT_REQUEST';
export const LIST_AUDIENCE_FROM_SEGMENT_SUCCESS =
    'LIST_AUDIENCE_FROM_SEGMENT_SUCCESS';
export const LIST_AUDIENCE_FROM_SEGMENT_FAILURE =
    'LIST_AUDIENCE_FROM_SEGMENT_FAILURE';

export const getAudienceFromSegment = (
    {
        audienceFilters,
        page,
        limit,
        filters = {},
        filters: {query, sort, status} = {},
    },
    parentId = null,
) => ({
    [CALL_API]: {
        endpoint: '/segment-groups/audience/users',
        method: POST,
        query: {
            query,
            page,
            limit,
            sort,
            status,
        },
        body: {
            audience_filters: audienceFilters,
            ...(parentId && {parent_dir_id: parentId}),
        },
        filters,
        types: [
            LIST_AUDIENCE_FROM_SEGMENT_REQUEST,
            LIST_AUDIENCE_FROM_SEGMENT_SUCCESS,
            LIST_AUDIENCE_FROM_SEGMENT_FAILURE,
        ],
        schema: [AUDIENCE_SCHEMA],
    },
});

export default {
    calculateAudience,
    getAudienceFromSegment,
};
