import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
    CONTENT_TYPE_MESSAGES,
    CONTENT_TYPE_TEMPLATES,
    CONTENT_TYPE_AUTOMATED_MESSAGES,
} from 'lib/store/actions/editor';
import actions from 'store/actions';
import {saveMessage} from 'util/messages';
import {saveTemplate} from 'util/templates';
import {saveAutomatedMessage} from 'util/automatedMessages';

const useContentSaver = (redirectOnCreate = false) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const messages = useSelector(
        ({messages: messagesFromState}) => messagesFromState,
    );

    const saveContent = async editor => {
        dispatch(actions.editor.setEditor(editor));
        if (editor.contentType === CONTENT_TYPE_MESSAGES) {
            return saveMessage(
                editor,
                messages,
                history,
                redirectOnCreate,
                dispatch,
            );
        }
        if (editor.contentType === CONTENT_TYPE_TEMPLATES) {
            return saveTemplate(editor, dispatch);
        }
        if (editor.contentType === CONTENT_TYPE_AUTOMATED_MESSAGES) {
            return saveAutomatedMessage(
                editor,
                history,
                redirectOnCreate,
                dispatch,
            );
        }
        return null;
    };

    const publishContent = async editor => {
        const message = await saveContent(editor);
        if (message !== null) {
            await dispatch(actions.messages.publishMessage(message));
        }
    };

    const createTemplate = async editor => {
        const template = await saveTemplate(editor, dispatch);
        history.push(`/editor/templates/${template.id}`);
    };

    return {
        saveContent,
        publishContent,
        createTemplate,
    };
};

export default useContentSaver;
