import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {MODAL_AUDIENCE, MODAL_USER_LIST} from 'lib/store/actions/modals';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import actions from 'store/actions';
import {pluralize} from 'lib/helpers/locale';

const AudienceLink = ({
    users,
    audienceFilters,
    messageId,
    sentToCalculation,
    setModalVisibility,
    setModalData,
    onClick,
}) => (
    <span className="audienceLink">
        <ButtonWithoutStyle
            onClick={e => {
                e.stopPropagation();

                if (onClick) {
                    onClick();
                } else {
                    if (messageId) {
                        setModalVisibility(MODAL_USER_LIST, true);
                        setModalData(MODAL_USER_LIST, {
                            messageId,
                            sentToCalculation,
                        });
                    }

                    if (audienceFilters) {
                        setModalVisibility(MODAL_AUDIENCE, true);
                        setModalData(MODAL_AUDIENCE, {
                            audienceFilters,
                            usersOnly: true,
                        });
                    }
                }
            }}>
            <Translate
                id={pluralize('users.user_count', users)}
                data={{users}}
            />
        </ButtonWithoutStyle>

        <style jsx>
            {`
                .audienceLink {
                    display: inline-block;
                }

                .audienceLink:hover {
                    text-decoration: underline;
                }
            `}
        </style>
    </span>
);

const mapDispatchToProps = dispatch => ({
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setModalData: (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data)),
});

export default connect(null, mapDispatchToProps)(AudienceLink);
