import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M12 15.222C13.3807 15.222 14.5 14.1027 14.5 12.722C14.5 11.3413 13.3807 10.222 12 10.222C10.6193 10.222 9.5 11.3413 9.5 12.722C9.5 14.1027 10.6193 15.222 12 15.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.33105 19.778C8.49805 18.844 10.2101 18.222 12.0001 18.222"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.486 9.222C18.8667 9.222 19.986 8.10271 19.986 6.722C19.986 5.34129 18.8667 4.222 17.486 4.222C16.1053 4.222 14.986 5.34129 14.986 6.722C14.986 8.10271 16.1053 9.222 17.486 9.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.51404 9.222C7.89475 9.222 9.01404 8.10271 9.01404 6.722C9.01404 5.34129 7.89475 4.222 6.51404 4.222C5.13333 4.222 4.01404 5.34129 4.01404 6.722C4.01404 8.10271 5.13333 9.222 6.51404 9.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 13.555C3 12.755 4.467 12.222 6 12.222"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.999 19H18.278L21.706 15.572C21.799 15.4792 21.8727 15.369 21.923 15.2477C21.9733 15.1264 21.9992 14.9963 21.9992 14.865C21.9992 14.7337 21.9733 14.6036 21.923 14.4823C21.8727 14.361 21.799 14.2508 21.706 14.158L20.842 13.294C20.7492 13.2011 20.639 13.1273 20.5177 13.077C20.3964 13.0267 20.2664 13.0008 20.135 13.0008C20.0037 13.0008 19.8736 13.0267 19.7523 13.077C19.631 13.1273 19.5208 13.2011 19.428 13.294L16 16.721L15.999 19Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
