import {combineReducers} from 'redux';
import createById from './helpers/byId';
import paginate from './helpers/paginate';
import {
    LIST_AUDIENCE_FROM_SEGMENT_REQUEST,
    LIST_AUDIENCE_FROM_SEGMENT_SUCCESS,
    LIST_AUDIENCE_FROM_SEGMENT_FAILURE,
} from '../actions/audience';

const listActions = [
    LIST_AUDIENCE_FROM_SEGMENT_REQUEST,
    LIST_AUDIENCE_FROM_SEGMENT_SUCCESS,
    LIST_AUDIENCE_FROM_SEGMENT_FAILURE,
];

export default combineReducers({
    byId: createById({
        listActions,
    }),
    paginated: paginate({
        listActions,
        requestFilterKey: 'filters',
    }),
});
