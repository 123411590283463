import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M5.323 19.878L19.752 13.179C20.75 12.716 20.75 11.282 19.752 10.819L5.323 4.122C4.288 3.642 3.193 4.66 3.58 5.743L5.813 11.997L3.58 18.258C3.193 19.34 4.288 20.358 5.323 19.878Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.81006 12H20.5001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
