export const DE = 'de';
export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';
export const IT = 'it';
export const NL = 'nl';
export const PL = 'pl';
export const PT = 'pt';
export const TR = 'tr';
export const SE = 'se';
export const CN = 'cn';
export const TH = 'th';
export const IN = 'in';
export const DK = 'dk';

export const DEFAULT_LANGUAGE = EN;

export const SUPPORTED_LANGUAGES = [
    {
        code: DE,
        name: 'Deutsch',
    },
    {
        code: EN,
        name: 'English',
    },
    {
        code: ES,
        name: 'Español',
    },
    {
        code: FR,
        name: 'Français',
    },
    {
        code: IT,
        name: 'Italiano',
    },
    {
        code: NL,
        name: 'Nederlands',
    },
    {
        code: PL,
        name: 'Polskie',
    },
    {
        code: PT,
        name: 'Português',
    },
    {
        code: TR,
        name: 'Türk',
    },
    {
        code: SE,
        name: 'svenska',
    },
    {
        code: DK,
        name: 'Dansk',
    },
    {
        code: CN,
        name: '中国人',
    },
    {
        code: TH,
        name: '泰国',
    },
    {
        code: IN,
        name: '印地语',
    },
];

export const getLangByCode = code => {
    const language = SUPPORTED_LANGUAGES.find(lang => lang.code === code);
    if (typeof language !== 'undefined') {
        return language.name;
    }

    return null;
};

export const mapLanguageOptions = languages => {
    if (!languages) {
        return [];
    }

    return languages.map(value => ({
        label: getLangByCode(value),
        value,
    }));
};

export const checkLangHelper = (language, languages) =>
    languages.filter(({code}) => code === language).length > 0;

export const pluralize = (key, count) =>
    `${key}${count > 1 || count === 0 ? '.other' : '.one'}`;
