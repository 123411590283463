import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M15 3V5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 3V5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 8H19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 12.417V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H12.417"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.997 10.25C6.84879 10.2506 6.70409 10.2951 6.58114 10.3779C6.4582 10.4606 6.36254 10.578 6.30623 10.7151C6.24993 10.8522 6.2355 11.0029 6.26478 11.1482C6.29406 11.2934 6.36572 11.4268 6.47073 11.5314C6.57574 11.636 6.70938 11.7071 6.85478 11.7358C7.00019 11.7645 7.15083 11.7495 7.2877 11.6926C7.42457 11.6358 7.54153 11.5396 7.62381 11.4164C7.70609 11.2931 7.75 11.1482 7.75 11C7.75 10.9013 7.7305 10.8035 7.69262 10.7123C7.65475 10.6211 7.59923 10.5383 7.52927 10.4686C7.45931 10.3989 7.37627 10.3437 7.28493 10.3062C7.19359 10.2687 7.09574 10.2496 6.997 10.25Z"
                fill="currentColor"
            />
            <path
                d="M6.997 14.25C6.84879 14.2506 6.70409 14.2951 6.58114 14.3779C6.4582 14.4606 6.36254 14.578 6.30623 14.7151C6.24993 14.8522 6.2355 15.0029 6.26478 15.1482C6.29406 15.2934 6.36572 15.4268 6.47073 15.5314C6.57574 15.636 6.70938 15.7071 6.85478 15.7358C7.00019 15.7645 7.15083 15.7495 7.2877 15.6926C7.42457 15.6358 7.54153 15.5396 7.62381 15.4164C7.70609 15.2931 7.75 15.1482 7.75 15C7.75 14.9013 7.7305 14.8035 7.69262 14.7123C7.65475 14.6211 7.59923 14.5383 7.52927 14.4686C7.45931 14.3989 7.37627 14.3437 7.28493 14.3062C7.19359 14.2687 7.09574 14.2496 6.997 14.25Z"
                fill="currentColor"
            />
            <path
                d="M10.25 11.003C10.2506 11.1512 10.2951 11.2959 10.3779 11.4189C10.4606 11.5418 10.578 11.6375 10.7151 11.6938C10.8522 11.7501 11.0029 11.7645 11.1482 11.7352C11.2934 11.7059 11.4268 11.6343 11.5314 11.5293C11.636 11.4243 11.7071 11.2906 11.7358 11.1452C11.7645 10.9998 11.7495 10.8492 11.6926 10.7123C11.6358 10.5754 11.5396 10.4585 11.4164 10.3762C11.2931 10.2939 11.1482 10.25 11 10.25C10.9013 10.25 10.8035 10.2695 10.7123 10.3074C10.6211 10.3453 10.5383 10.4008 10.4686 10.4707C10.3989 10.5407 10.3437 10.6237 10.3062 10.7151C10.2687 10.8064 10.2496 10.9043 10.25 11.003Z"
                fill="currentColor"
            />
            <path
                d="M17 22C15.6739 22 14.4021 21.4732 13.4645 20.5355C12.5268 19.5979 12 18.3261 12 17C12 14.296 14.3 11.997 17.004 12C18.3301 12.0005 19.6016 12.5278 20.5389 13.4659C21.4763 14.4039 22.0025 15.6759 22.002 17.002C22.0015 18.3281 21.4742 19.5996 20.5361 20.5369C19.5981 21.4743 18.3261 22.0005 17 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M17 15V19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 17H15"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
