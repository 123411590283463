import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M11.625 12.001C11.6254 12.0751 11.6477 12.1474 11.6892 12.2088C11.7306 12.2702 11.7893 12.3179 11.8579 12.346C11.9264 12.3741 12.0018 12.3812 12.0744 12.3665C12.147 12.3519 12.2136 12.316 12.2659 12.2635C12.3181 12.2109 12.3536 12.1441 12.3679 12.0714C12.3822 11.9987 12.3747 11.9234 12.3463 11.855C12.3178 11.7866 12.2698 11.7282 12.2081 11.6871C12.1465 11.6459 12.0741 11.624 12 11.624C11.9004 11.6245 11.805 11.6645 11.7347 11.7351C11.6644 11.8058 11.625 11.9014 11.625 12.001"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.625 6.001C11.6254 6.07508 11.6477 6.14739 11.6892 6.20879C11.7306 6.2702 11.7893 6.31795 11.8579 6.34603C11.9264 6.3741 12.0018 6.38124 12.0744 6.36655C12.147 6.35185 12.2136 6.31598 12.2659 6.26346C12.3181 6.21094 12.3536 6.14411 12.3679 6.07143C12.3822 5.99874 12.3747 5.92344 12.3463 5.85504C12.3178 5.78663 12.2698 5.72818 12.2081 5.68706C12.1465 5.64594 12.0741 5.624 12 5.624C11.9004 5.62453 11.805 5.66448 11.7347 5.73513C11.6644 5.80577 11.625 5.90136 11.625 6.001"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.625 18.001C11.6254 18.0751 11.6477 18.1474 11.6892 18.2088C11.7306 18.2702 11.7893 18.3179 11.8579 18.346C11.9264 18.3741 12.0018 18.3812 12.0744 18.3665C12.147 18.3519 12.2136 18.316 12.2659 18.2635C12.3181 18.2109 12.3536 18.1441 12.3679 18.0714C12.3822 17.9987 12.3747 17.9234 12.3463 17.855C12.3178 17.7866 12.2698 17.7282 12.2081 17.6871C12.1465 17.6459 12.0741 17.624 12 17.624C11.9004 17.6245 11.805 17.6645 11.7347 17.7351C11.6644 17.8058 11.625 17.9014 11.625 18.001"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
