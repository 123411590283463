import React from 'react';
import {Translate} from 'react-localize-redux';
import InfiniteScroll from 'lib/components/InfiniteScroll';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import * as theme from 'styles/theme';
import MediaThumb from './MediaThumb/MediaThumb';

const MediaGallery = ({
    isFetching,
    media,
    onMediaClick,
    onListEnd,
    unsplash,
    isImporting,
    removable,
}) => (
    <div className={`media-gallery ${isImporting ? 'isImporting' : ''}`}>
        {isImporting && (
            <div className="loadingWrap">
                <LoadingWrapper loading />
            </div>
        )}

        <div className="wrap">
            <InfiniteScroll
                isFetchingMore={isFetching}
                onFetchMore={onListEnd}
                hasMore>
                {media.length ? (
                    <div className="row">
                        {media.map(item => {
                            const {photographer} = item.custom_properties;
                            const link = item.custom_properties.portfolio_link;

                            let title = item.file_name;
                            if (photographer) {
                                title = (
                                    <a
                                        target="_blank"
                                        href={link}
                                        rel="noopener noreferrer">
                                        {photographer}
                                    </a>
                                );
                            }

                            return (
                                <div className="col-6 col" key={item.id}>
                                    <MediaThumb
                                        id={item.id}
                                        title={title}
                                        type={
                                            unsplash
                                                ? 'unsplash'
                                                : item.mime_type
                                        }
                                        url={getUrlBySize(item, THUMB)}
                                        callBack={() => {
                                            if (!isImporting) {
                                                onMediaClick(item);
                                            }
                                        }}
                                        removable={removable}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    !isFetching && (
                        <div>
                            <Translate id="editor.sidebar.no_media" />
                        </div>
                    )
                )}
            </InfiniteScroll>
        </div>

        <style jsx>
            {`
                .media-gallery {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    position: relative;
                    overflow: auto;
                    margin: 0 -3rem;
                    padding: 0 3rem;
                }

                .wrap {
                    position: relative;
                }

                .loadingWrap {
                    position: absolute;
                    z-index: ${theme.zAbove};
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .media-gallery :global(.loading) {
                    width: auto;
                }

                .media-gallery :global(.media-thumb) {
                    margin-bottom: 3rem;
                }

                .col:nth-last-child(-n + 2) :global(.media-thumb) {
                    margin-bottom: 0;
                }

                .media-gallery :global(.isImporting.scroll-wrapper) {
                    opacity: 0.5;
                }
            `}
        </style>
    </div>
);

export default MediaGallery;
