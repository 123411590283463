/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {Link} from 'react-router-dom';
import Button from 'components/util/Button';
import actions from 'store/actions';
import {FAILURE} from 'lib/store/actions/notifications';
import {
    getErrorNotification,
    throwIfInvalidApiResponse,
} from 'util/errorHelper';
import Icon from 'lib/components/Icon';
import PropTypes from 'prop-types';
import Header from './Header';

const Login = ({
    email,
    setEmail,
    password,
    setPassword,
    canUseAzureADAuth,
    loginAzureAD,
}) => {
    const dispatch = useDispatch();
    const login = async (loginEmail, loginPassword) => {
        try {
            const response = await dispatch(
                actions.auth.login(loginEmail, loginPassword),
            );
            throwIfInvalidApiResponse(response);
            dispatch(actions.auth.setAccessToken(response.payload));
        } catch (error) {
            dispatch(
                actions.notifications.addNotification({
                    type: FAILURE,
                    content: {
                        ...getErrorNotification(error),
                    },
                }),
            );
        }
    };
    const setRemember = value => dispatch(actions.auth.setRemember(value));
    const isLoading = useSelector(state => state.auth.isLoading);
    const remember = useSelector(state => state.auth.remember);
    return (
        <div className="l-login">
            <div className="l-login__container">
                <Header />
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        login(email, password);
                    }}
                    className="form l-login__content">
                    <div className="form__group">
                        <label className="label" htmlFor="email">
                            <Translate id="auth.email" />
                        </label>
                        <input
                            id="email"
                            tabIndex={0}
                            type="email"
                            className="input"
                            name="email"
                            autoComplete="email"
                            value={email || ''}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="form__group">
                        <div className="l-login__label-wrapper">
                            <label className="label" htmlFor="password">
                                <Translate id="auth.password" />
                            </label>
                            <Link
                                to={`/auth/forgot-password?email=${email}`}
                                tabIndex={-1}
                                className="l-login__label-wrapper__link">
                                <Translate id="auth.forgot" />
                            </Link>
                        </div>
                        <input
                            id="password"
                            tabIndex={0}
                            type="password"
                            className="input input--password"
                            name="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <div className="checkbox">
                            <input
                                id="remember"
                                tabIndex={-1}
                                type="checkbox"
                                className="checkbox__input"
                                checked={remember}
                                onChange={e => {
                                    setRemember(e.target.checked);
                                }}
                            />
                            <label
                                className="checkbox__label"
                                htmlFor="remember">
                                <Translate id="auth.remember" />
                                <Icon name="check" size={12} />
                            </label>
                        </div>
                    </div>

                    <Button
                        isLoading={isLoading}
                        type="submit"
                        tabIndex={0}
                        className="button success full-width">
                        <Translate id="auth.login" />
                    </Button>
                </form>

                {canUseAzureADAuth && (
                    <div
                        className="form l-login__content"
                        style={{paddingTop: 0}}>
                        <p style={{textAlign: 'center'}}>
                            <strong>
                                <Translate id="segmentation.or" />
                            </strong>
                        </p>

                        <div className="form__group">
                            <Button
                                className="button full-width button--microsoft"
                                onClick={loginAzureAD}>
                                <Icon name="microsoft" />
                                <Translate id="global.microsoft" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

Login.propTypes = {
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    setPassword: PropTypes.func.isRequired,
    canUseAzureADAuth: PropTypes.bool.isRequired,
    loginAzureAD: PropTypes.func.isRequired,
};
export default Login;
