import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M12 15.1111V5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 15.8889C19 16.714 18.6722 17.5053 18.0888 18.0888C17.5053 18.6722 16.714 19 15.8889 19H8.11111C7.28599 19 6.49467 18.6722 5.91122 18.0888C5.32778 17.5053 5 16.714 5 15.8889"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.11033 12L11.9992 15.8897"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.8889 12L11.9992 15.8897"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
