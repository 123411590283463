import Select from 'components/util/Select';
import styled from 'styled-components';

export const Container = styled.div(
    ({theme}) => `
    padding: ${theme.spacingLarger}rem;
    width: 100%;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
> * {
    width: 100%;
    max-width: 100rem;
}
`,
);

export const GroupTop = styled.div(
    ({theme}) =>
        `
    border-bottom: solid 1px ${theme.colorBorder};
    background: ${theme.colors.grey[0]};
    padding: ${theme.spacingSmall}rem;
    display:flex;
    gap: ${theme.spacingSmaller}rem;
    justify-content: space-between;
    border-radius: ${theme.borderRadius}rem ${theme.borderRadius}rem 0 0`,
);

export const GroupTitle = styled.h2(
    ({theme}) => `
    font-size: ${theme.fontSizeLarge}rem;
    font-weight: 600;
    display: flex; 
    gap: ${theme.spacingSmaller}rem;
    white-space: nowrap;
    align-items: center;
    svg{
        height: ${theme.spacingLarger}rem;  
    }
`,
);

export const GroupDescription = styled.div(
    ({theme}) => `
    color: ${theme.colorTextGrey};
    font-size: ${theme.fontSizeSmallest}rem;
    line-height: 1.4;
`,
);

export const Row = styled.div(
    ({theme}) => `
    display: flex;
    margin-bottom: ${theme.spacing}rem;
    gap: ${theme.spacingLarger}rem;
    justify-content: space-between;
`,
);

export const Label = styled.label(
    ({theme, disabled}) => `
    width: 32.5rem;
    font-weight: 500;
    padding-top: ${theme.spacingSmaller}rem;
    margin: 0;
    ${disabled ? `opacity: 0.3;` : ''}
`,
);

export const InputContainer = styled.div`
    flex: 1;

    .loading {
        display: inline-block;
    }
`;

export const Logo = styled(InputContainer)`
    img {
        max-width: 30rem;
    }
`;

export const Banner = styled(InputContainer)`
    img {
        max-height: 40vh;
    }
`;

export const StyledSelect = styled(Select)`
    min-width: 24rem;
    display: inline-block;
`;

export const TimeZone = styled(StyledSelect)`
    min-width: 28rem;
`;

export const SettingsInputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: ${({theme}) => `${theme.spacing}rem`};
    ${({disabled}) => (disabled ? 'opacity: 0.3;' : '')}
    &:not(:last-child) {
        border-bottom: 1px solid ${({theme}) => theme.colors.grey[2]};
    }
`;
export const SettingsGroup = styled.div`
    display: flex;
    border-radius: ${({theme}) => `${theme.borderRadius}rem`};
    flex-direction: column;
    gap: 0.4rem;
    border: solid 1px ${({theme}) => theme.colors.grey[2]};
    margin-bottom: ${({theme}) => `${theme.spacingLarger}rem`};
    background-color: ${({theme}) => theme.colorWhite};
    box-shadow: ${({theme}) => theme.defaultShadow};
`;
