// Spacing
export const spacingSmallest = 0.4;
export const spacingSmaller = 0.8;
export const spacingSmall = 1.2;
export const spacing = 1.6;
export const spacingLarge = 2.4;
export const spacingLarger = 3.2;
export const spacingLargest = 6.4;
export const spacingHuge = 7.5;

// Font weights
export const fwExtraLight = 100;
export const fwLight = 200;
export const fwBook = 300;
export const fwNormal = 400;
export const fwMedium = 500;
export const fwSemiBold = 600;
export const fwBold = 700;
export const fwBlack = 800;
export const fwExtraBlack = 900;

export const fontFamily = '"Inter", "Helvetica Neue", Arial, sans-serif';

// Font sizes
export const fontSizeSmallest = 1;
export const fontSizeSmaller = 1.2;
export const fontSizeSmall = 1.4;
export const fontSize = 1.6;
export const fontSizeLarge = 2.2;
export const fontSizeLarger = 2.8;
export const fontSizeLargest = 3.6;
export const fontSizeHuge = 4.6;

// Other
export const defaultTransition = '.4s';
export const defaultShadow = 'rgba(0, 0, 0, 0.1) 0 .2rem .4rem .2rem';

export const borderRadius = 0.4;
export const borderRadiusLarge = 1;

export const editorSidePadding = 10;
