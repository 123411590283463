import {combineReducers} from 'redux';
import {
    FETCH_MOOD_ANALYTICS_REQUEST,
    FETCH_MOOD_ANALYTICS_SUCCESS,
    FETCH_MOOD_ANALYTICS_FAILURE,
    FETCH_MOOD_ANALYTICS_EXPORT_SUCCESS,
    FETCH_MOOD_ANALYTICS_EXPORT_FAILURE,
    FETCH_MOOD_ANALYTICS_EXPORT_REQUEST,
} from '../../actions/analytics/mood';

export default () => {
    const mood = (state = {}, action) => {
        switch (action.type) {
            case FETCH_MOOD_ANALYTICS_SUCCESS:
                return action.payload.data || {};
            default:
                return state;
        }
    };

    const average = (state = null, action) => {
        switch (action.type) {
            case FETCH_MOOD_ANALYTICS_SUCCESS: {
                const stats = action.payload.data.answer_stats;

                const highestAnswerReducer = (highest, current) =>
                    highest && highest.value > current.value
                        ? highest
                        : current;
                const {id} = stats.reduce(highestAnswerReducer, null);
                return id;
            }
            default:
                return state;
        }
    };

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case FETCH_MOOD_ANALYTICS_REQUEST:
                return true;
            case FETCH_MOOD_ANALYTICS_SUCCESS:
            case FETCH_MOOD_ANALYTICS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const isFetchingExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_MOOD_ANALYTICS_EXPORT_REQUEST:
                return true;
            case FETCH_MOOD_ANALYTICS_EXPORT_SUCCESS:
            case FETCH_MOOD_ANALYTICS_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const hasFetched = (state = false, action) => {
        switch (action.type) {
            case FETCH_MOOD_ANALYTICS_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    return combineReducers({
        mood,
        average,
        isFetching,
        isFetchingExport,
        hasFetched,
    });
};
