export const SET_SURVEY_CONTENT = 'SET_SURVEY_CONTENT';
export const SET_SURVEY_OPTIONS = 'SET_SURVEY_OPTIONS';
export const START_NEW_SURVEY = 'START_NEW_SURVEY';
export const START_EDITING_SURVEY = 'START_EDITING_SURVEY';
export const CLEAR_ACTIVE_SURVEY = 'CLEAR_ACTIVE_SURVEY';

export const setContent = content => ({
    type: SET_SURVEY_CONTENT,
    payload: {
        content,
    },
});

export const setOptions = options => ({
    type: SET_SURVEY_OPTIONS,
    payload: {
        options,
    },
});

export const startNewSurvey = type => ({
    type: START_NEW_SURVEY,
    payload: {
        type,
    },
});

export const startEditingSurvey = survey => ({
    type: START_EDITING_SURVEY,
    payload: {
        survey,
    },
});

export const clearActiveSurvey = () => ({
    type: CLEAR_ACTIVE_SURVEY,
    payload: {},
});

export default {
    setContent,
    setOptions,
    startNewSurvey,
    startEditingSurvey,
    clearActiveSurvey,
};
