import React from 'react';
import Mood from 'lib/components/Mood';
import {MODAL_HAPPINESS_POLL} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import useSurveyEditor from 'components/hooks/useSurveyEditor';
import * as theme from 'lib/styles/theme';

const HappinessPollModal = () => {
    const {
        baseForm,
        isSaving,
        onSave,
        translate,
        language,
        headerTitleKey,
        buttonTitleKey,
    } = useSurveyEditor();

    return (
        <Modal
            name={MODAL_HAPPINESS_POLL}
            icon="smiley-o"
            title={`${translate(headerTitleKey, {name: translate('editor.survey.happiness_poll')})} (${language})`}
            cancelable
            buttonActions={[
                {
                    label: translate(buttonTitleKey),
                    loading: isSaving,
                    callBack: onSave,
                    classes: 'success',
                },
            ]}>
            <div className="form">{baseForm}</div>
            <div className="d-flex justify-content-center mt-3">
                <div className="mr-2 smiley">
                    <Mood mood={0} />
                </div>
                <div className="mr-2 smiley">
                    <Mood mood={1} />
                </div>
                <div className="mr-2 smiley">
                    <Mood mood={2} />
                </div>
                <div className="mr-2 smiley">
                    <Mood mood={3} />
                </div>
            </div>
            <style jsx>
                {`
                    @media (min-width: 350px) {
                        .happiness-poll-block {
                            justify-content: flex-start;
                        }

                        .smiley {
                            margin: 0;
                            margin-right: ${theme.spacing}rem;
                            max-width: 7rem;
                            flex: 1;
                        }
                    }

                    .smiley {
                        margin: 0 ${theme.spacingSmall / 2}rem;
                        margin-bottom: ${theme.spacing}rem;
                    }
                `}
            </style>
        </Modal>
    );
};

export default HappinessPollModal;
