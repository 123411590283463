import React from 'react';

const CustomizedLabel = ({x, y, width, height, data, index, labelKey}) => {
    const value = data[index][labelKey];

    if (height < 15) {
        return null;
    }

    return (
        <g>
            <text
                x={x + width / 2}
                y={y + 12}
                fill="#fff"
                textAnchor="middle"
                dominantBaseline="middle">
                {value}
            </text>
        </g>
    );
};

export default CustomizedLabel;
