import React from 'react';

export default function () {
    return (
        <path
            d="M8.30762 0.513862C5.57242 1.45295 3.45179 3.04506 2.06211 5.20241C-1.0339 10.0093 -0.613819 15.8931 3.12096 20.0345C4.17695 21.2053 5.53378 22.2864 5.94698 22.2864C6.02425 22.2864 6.08731 19.2297 6.08731 15.4936C6.08731 9.36828 6.1286 8.5916 6.50761 7.5908C7.10532 6.01206 8.13732 4.81051 9.46618 4.14613C10.5972 3.58044 10.6936 3.57131 15.6227 3.56307L20.6304 3.55482L19.7422 2.78795C18.613 1.81299 16.7521 0.817981 15.2057 0.362137C13.3782 -0.176586 10.1087 -0.104623 8.30762 0.513862ZM10.9194 7.48296C9.94644 8.0159 9.56743 9.01135 9.46041 11.3137L9.36471 13.3746H16.5972H23.8295L23.9643 12.6505C24.1117 11.8587 23.7822 9.42709 23.3464 8.09187L23.0709 7.24769L17.299 7.1989C12.5304 7.15857 11.4214 7.20803 10.9194 7.48296ZM9.41778 20.4852V23.8079L11.0275 23.9563C14.1824 24.2468 17.6048 23.074 19.996 20.8829C21.0605 19.9077 22.7396 17.7051 22.7396 17.2843C22.7396 17.2172 19.7422 17.1622 16.0787 17.1622H9.41778V20.4852Z"
            fill="currentColor"
        />
    );
}
