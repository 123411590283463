import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import Segmentation from 'components/pages/segmentation/Segmentation';
import {MODAL_LOCALE} from 'lib/store/actions/modals';
import * as routes from 'routes/index';

const SegmentationWithState = ({
    getSegmentGroups,
    availableSegments,
    isFetchingSegments,
    showLocaleModal,
    updateSegment,
    createSegment,
    deleteSegment,
    hideModal,
    history,
    match,
}) => {
    const segmentGroups = {...availableSegments};
    delete segmentGroups.company;
    delete segmentGroups.users;

    const {groupId} = match.params;
    const firstSegmentGroupKey = Object.keys(segmentGroups)[0];

    const [selectedSegmentGroupKey, setSelectedSegmentGroupKey] = useState();
    const selectedSegmentGroup = segmentGroups[selectedSegmentGroupKey];
    const [segments, setSegments] = useState(
        selectedSegmentGroup && selectedSegmentGroup.segments,
    );

    // Update segments after switching segment
    useEffect(() => {
        if (selectedSegmentGroupKey) {
            setSegments(selectedSegmentGroup && selectedSegmentGroup.segments);
            history.push(routes.segmentationGroup(selectedSegmentGroupKey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSegmentGroupKey]);

    // Set segment to id from params or to the first segment group after updating segments
    useEffect(() => {
        if (segmentGroups[groupId]) {
            setSelectedSegmentGroupKey(groupId);
        } else if (firstSegmentGroupKey) {
            setSelectedSegmentGroupKey(firstSegmentGroupKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstSegmentGroupKey]);

    useEffect(() => {
        const updatedSegmentGroup = segmentGroups[selectedSegmentGroupKey];
        const newSegments = updatedSegmentGroup && updatedSegmentGroup.segments;

        if (newSegments) {
            setSegments(newSegments);
        } else if (firstSegmentGroupKey && !segmentGroups[groupId]) {
            // Go back to first segment group when current group is deleted
            setSelectedSegmentGroupKey(firstSegmentGroupKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentGroups]);

    // Get segments on mount
    useEffect(() => {
        if (availableSegments === null) {
            getSegmentGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openEditModal = ({translations}, segmentGroupId, segmentId) => {
        const selected = Object.values(segmentGroups).find(
            ({id}) => segmentGroupId === id,
        );
        const cantDelete = !segmentId && selected.type === 'global';

        showLocaleModal({
            translations,
            segmentGroupId,
            segmentId,
            isEditing: true,
            canDelete: !cantDelete,
        });
    };

    const onDeleteSegment = async (segmentGroupId, segmentId) => {
        try {
            await deleteSegment(segmentGroupId, segmentId);
            // Fetch segments again TODO: update redux store instead of fetching again
            await getSegmentGroups();
            hideModal();
        } catch (error) {
            //
        }
    };

    const openCreateModal = segmentGroupId => {
        showLocaleModal({segmentGroupId});
    };

    const onSave = async (isEditing, segmentGroupId, segmentId, segment) => {
        try {
            if (isEditing) {
                const res = await updateSegment(
                    segment,
                    segmentGroupId,
                    segmentId,
                );
                if (!res || res.statusCode !== 200) {
                    throw new Error(res.payload);
                }
                // Fetch segments again TODO: update redux store instead of fetching again
                await getSegmentGroups();
            } else {
                const res = await createSegment(segment, segmentGroupId);
                if (!res || res.statusCode !== 200)
                    // Fetch segments again TODO: update redux store instead of fetching again
                    await getSegmentGroups();
            }

            hideModal();
        } catch (error) {
            //
        }
    };

    return (
        <Segmentation
            isLoading={isFetchingSegments || !segmentGroups}
            segmentGroups={segmentGroups}
            segments={segments}
            selectedSegmentGroupKey={selectedSegmentGroupKey}
            changeSegmentGroup={setSelectedSegmentGroupKey}
            editSegment={openEditModal}
            onSave={onSave}
            onDelete={onDeleteSegment}
            onCreate={openCreateModal}
        />
    );
};

const mapStateToProps = state => ({
    availableSegments: state.segmentation.segments.data,
    isFetchingSegments: state.segmentation.segments.isFetching,
});

const mapDispatchToProps = dispatch => ({
    getSegmentGroups: () => dispatch(actions.segmentation.getSegmentGroups()),
    createSegment: (segment, groupId) =>
        dispatch(actions.segmentation.createSegment(segment, groupId)),
    updateSegment: (segment, groupId, segmentId) =>
        dispatch(
            actions.segmentation.updateSegment(segment, groupId, segmentId),
        ),
    deleteSegment: (groupId, segmentId) =>
        dispatch(actions.segmentation.deleteSegment(groupId, segmentId)),
    showLocaleModal: data => {
        dispatch(actions.modals.setModalData(MODAL_LOCALE, data));
        dispatch(actions.modals.setModalVisibility(MODAL_LOCALE, true));
    },
    hideModal: () =>
        dispatch(actions.modals.setModalVisibility(MODAL_LOCALE, false)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SegmentationWithState);
