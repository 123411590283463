export const READ = 'read';
export const ALL = 'all';
export const UNREAD = 'unread';
export const CHEERED = 'cheered';

export const AND = 'AND';
export const OR = 'OR';
export const NOT = 'NOT';

export const DATE_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD';

export const MONTHS = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
];
export const DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'day',
    'weekday',
    'weekend_day',
];

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';

export const TRIGGER_BIRTHDAY = '@birthday';
export const TRIGGER_TIMER = '@timer';
export const TRIGGER_FIRST_DAY_OF_WORK = '@first-day-of-work';
export const TRIGGER_MESSAGE_READ = '@message-read';
export const TRIGGER_ONBOARDING = '@onboarding';
export const TRIGGER_AUTOMATED_MESSAGE_READ = '@automated-message-read';
export const TRIGGER_WORK_ANNIVERSARY = '@work-anniversary';

export const IMPORT_HELP_URL =
    'https://help.meetroger.io/en/articles/3684456-how-to-import-users';
