import React from 'react';
import {useTheme} from 'styled-components';

const BadgeCount = ({children}) => {
    const theme = useTheme();
    const {length} = children.toString();
    const isPill = length >= 3;

    if (!children || length === 0) {
        return null;
    }

    return (
        <span className="badgeCount">
            {children}
            <style jsx>
                {`
                    .badgeCount {
                        color: ${theme.colors.white};
                        margin-left: ${theme.spacingSmall}rem;
                        background-color: ${theme.colors.primary};
                        border-radius: ${isPill ? '1rem' : '50%'};
                        font-size: 1rem;
                        line-height: 1.8rem;
                        min-width: 1.8rem;
                        height: 1.8rem;
                        text-align: center;
                    }
                `}
            </style>
        </span>
    );
};

export default BadgeCount;
