import React from 'react';
import classNames from 'classnames';
import Empty from 'components/util/Empty';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';

const ContentView = ({
    title,
    children,
    headerComponents = [],
    hasOverflow,
    wrapHasOverflow, // TODO: remove this hack (needed for dashboard segmentation and branding)
    empty,
    emptyTitle,
    breadcrumbs,
    noContentPadding = false,
    total,
}) => (
    <div className={classNames('contentWrap', {wrapHasOverflow})}>
        <div className="header">
            {typeof title !== 'undefined' && (
                <h1 className="title">
                    {title} {total ? <small>({total})</small> : null}
                </h1>
            )}
            {typeof breadcrumbs !== 'undefined' && breadcrumbs.length > 0 && (
                <div className="breadcrumbs">
                    {breadcrumbs.map((crumb, index) => {
                        const clickable = typeof crumb.onClick === 'function';

                        return (
                            <h2
                                key={index}
                                className={classNames('crumb', {clickable})}
                                onClick={() => {
                                    if (clickable) {
                                        crumb.onClick(index);
                                    }
                                }}>
                                {crumb.title}

                                {index !== breadcrumbs.length - 1 && (
                                    <span className="divider">&gt;</span>
                                )}
                            </h2>
                        );
                    })}
                </div>
            )}
            <div className="headerRight">
                {headerComponents.length
                    ? headerComponents.map(part => part)
                    : headerComponents}
            </div>
        </div>
        <div className={classNames('content', {hasOverflow})}>
            {empty ? <Empty title={emptyTitle} /> : children}
        </div>
        <style jsx>
            {`
                .contentWrap {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    flex-flow: column nowrap;
                    height: 100%;
                    padding: 4rem 0 0rem;
                }

                .wrapHasOverflow {
                    overflow: auto;
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: none;
                    margin: 0 3rem 3.4rem;
                }
                .header h1 {
                    display: flex;
                    gap: 0.25rem;
                }
                .header small {
                    font-size: 1.5rem;
                    opacity: 0.5;
                }

                .headerRight {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    flex: 1;
                    justify-content: flex-end;
                }

                .headerRight > :global(*) {
                    margin-left: 1.6rem;
                }

                // IE 11 fix
                .headerRight .button-group,
                .headerRight .search-input {
                    min-width: 27rem;
                    width: auto !important;
                }

                .header :global(.dropdown) {
                    color: ${colors.text.dark};
                }

                .header :global(.dropdown__select) {
                    font-size: 1.6rem;
                    text-align-last: right;
                }

                .header :global(.dropdown__select) option {
                    direction: rtl;
                }

                .header :global(.editor-button) {
                    margin-left: 4.4rem;
                }

                .header :global(.search-input) {
                    width: 26.5rem;
                }

                .title {
                    font-weight: 800;
                    font-size: ${theme.fontSizeHuge}rem;
                    line-height: 2.3rem;
                }

                .content {
                    ${noContentPadding ? '' : 'padding: 0 3rem 3rem;'}
                    flex: auto;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                }

                .breadcrumbs {
                    text-transform: uppercase;
                    font-size: 2rem;
                    line-height: 2.3rem;
                    color: ${colors.text.dark};
                    display: flex;
                    align-items: center;
                }

                .clickable {
                    cursor: pointer;
                    font-weight: ${theme.fwBold};
                }

                .divider {
                    font-weight: ${theme.fwNormal};
                    margin: 0 ${theme.spacingSmaller}rem;
                }

                .hasOverflow {
                    overflow: auto;
                }
            `}
        </style>
    </div>
);

export default ContentView;
