import {useSelector} from 'react-redux';
import getUserRoleHelper, {
    isSuperUser as isSuperUserHelper,
    isPublisher as isPublisherHelper,
    ROGER_STAFF,
    SUPER_ADMIN,
    ADMIN,
} from 'util/getUserRoleHelper';
import {getById} from 'lib/store/reducers/helpers/byId';

const useRoles = () => {
    const {byId, me} = useSelector(({users}) => users);
    const userRole = getUserRoleHelper(getById(byId, me));
    const isSuperUser = isSuperUserHelper(userRole);
    const isPublisher = isPublisherHelper(userRole);
    const isAdmin =
        userRole === ADMIN ||
        userRole === ROGER_STAFF ||
        userRole === SUPER_ADMIN;
    const isRogerStaff = userRole === ROGER_STAFF;
    const isSuperAdmin = userRole === SUPER_ADMIN || isRogerStaff;

    return {
        userRole,
        isSuperUser,
        isPublisher,
        isAdmin,
        isSuperAdmin,
        isRogerStaff,
    };
};

export default useRoles;
