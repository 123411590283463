import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="m23.156 20.25-2.765-5.906c-1.547 2.203-3.891 3.797-6.657 4.265l2.297 4.922c.328.657 1.219.61 1.453-.047l1.172-3.234.282-.14 3.234 1.218c.656.235 1.265-.469.984-1.078zM12 0C7.266 0 3.375 3.844 3.375 8.625c0 4.734 3.89 8.578 8.625 8.578 4.734 0 8.625-3.844 8.625-8.578C20.625 3.844 16.735 0 12 0zm4.172 7.969-1.5 1.5.328 2.11c.14.608-.563 1.124-1.125.796L12 11.391l-1.875.984c-.563.328-1.266-.188-1.125-.797l.328-2.11-1.5-1.5C7.36 7.5 7.594 6.75 8.25 6.657l2.11-.328.937-1.922c.281-.562 1.125-.562 1.406 0l.938 1.922 2.113.328c.656.094.89.844.422 1.313zM3.609 14.344.844 20.25c-.281.61.328 1.313.984 1.078l3.234-1.219.282.141 1.172 3.234c.234.657 1.125.703 1.453.047l2.297-4.922c-2.766-.468-5.11-2.062-6.657-4.265zm0 0"
        />
    );
}
