import React from 'react';
import PropTypes from 'prop-types';
import List from 'components/List/List';
import Icon from 'lib/components/Icon';

const columns = [
    {
        key: 1,
        renderer: content => <Icon name={content} />,
    },
    {key: 2},
    {key: 3, grow: true},
    {
        key: 4,
        renderer: content => (
            <div className="cheer-wrap">
                {content}
                <Icon name="cheer" />
                <style jsx>
                    {`
                        .cheer-wrap {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    `}
                </style>
            </div>
        ),
    },
];

const MostCheered = ({messages, onListEnd, isFetching, onRowClick}) => (
    <List
        rows={messages}
        columns={columns}
        active={0}
        onListEnd={onListEnd}
        isFetching={isFetching}
        onRowClick={onRowClick}
        widths={['3rem', '2rem', '100%', '7rem']}
    />
);

MostCheered.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.number,
                    content: PropTypes.string,
                }),
            ),
        }),
    ).isRequired,
    onListEnd: PropTypes.func,
    isFetching: PropTypes.bool,
    onRowClick: PropTypes.func,
};
MostCheered.defaultProps = {
    onListEnd: () => {},
    isFetching: false,
    onRowClick: () => {},
};

export default MostCheered;
