import React from 'react';

export default function () {
    return (
        <path
            d="M14 7L9 12L14 17"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
