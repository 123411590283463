import React, {useCallback, useState} from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {format, addDays} from 'date-fns';
import actions from 'store/actions';
import useRoles from 'components/hooks/useRoles';
import useTimezone from 'components/hooks/useTimezone';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import EditorSidebarSendWhen from './EditorSidebarSendWhen';

const EditorSidebarSendWhenWithState = ({canEdit}) => {
    const {isSuperUser} = useRoles();
    const {saveContent} = useContentSaver();
    const {editor, isAlreadyPublished} = useEditorState();
    const {timezone, isInCompanyTimezone} = useTimezone();
    const [internalScheduledAt, setInternalScheduledAt] = useState(
        addDays(new Date(), 1),
    );
    const scheduledAtFormatted = format(editor.scheduledAt, 'DD/MM/YYYY HH:mm');

    const dispatch = useDispatch();
    const updateScheduledAt = async scheduledAt => {
        await dispatch(actions.editor.setScheduledAt(scheduledAt));
        saveContent({...editor, scheduledAt});
    };

    const onSendNowChange = useCallback(
        async isActive => {
            const scheduledAtDate = isActive ? null : internalScheduledAt;
            await dispatch(actions.editor.setScheduledAt(scheduledAtDate));
            saveContent({...editor, scheduledAt: scheduledAtDate});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [internalScheduledAt, editor],
    );
    const onScheduledAtChange = value => {
        const scheduledAtMoment = value;
        if (value && moment.isMoment(value)) {
            updateScheduledAt(scheduledAtMoment.toDate());
            setInternalScheduledAt(scheduledAtMoment.toDate());
        } else if (value && typeof value === 'string') {
            // TODO: The date input is not valid.
        }
    };

    if (isAlreadyPublished) {
        return null;
    }

    return (
        <EditorSidebarSendWhen
            canEdit={canEdit}
            timezone={timezone}
            scheduledAt={editor.scheduledAt}
            isSuperUser={isSuperUser}
            onSendNowChange={onSendNowChange}
            onScheduledAtChange={onScheduledAtChange}
            isInCompanyTimezone={isInCompanyTimezone}
            scheduledAtFormatted={scheduledAtFormatted}
        />
    );
};

export default EditorSidebarSendWhenWithState;
