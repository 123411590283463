import './SmartTagSuggestions.css';

import React from 'react';
import {normalizeSelectedIndex} from '../../../../components/RogerEditor/Utils/editor';

export const filterSmartTags = (smartTags, query) =>
    smartTags.filter(smartTag =>
        smartTag.slug.toLowerCase().startsWith(query.toLowerCase()),
    );

const SmartTagSuggestions = ({
    left,
    top,
    selectedIndex,
    text,
    smartTags,
    onClick,
    onHover,
}) => {
    const filteredSmartTags = filterSmartTags(
        smartTags,
        text.replace(/^@/, ''),
    );
    const normalizedIndex = normalizeSelectedIndex(
        selectedIndex,
        filteredSmartTags.length,
    );

    return (
        <ul
            className="smart-tag-suggestions"
            style={{position: 'absolute', left, top}}>
            {filteredSmartTags.map((smartTag, index) => (
                <li
                    key={index}
                    className={`smart-tag-suggestions__suggestion ${index === normalizedIndex ? '--selected' : ''}`}
                    onMouseEnter={() => {
                        if (typeof onHover === 'function') {
                            onHover(index);
                        }
                    }}
                    onMouseDown={e => {
                        e.preventDefault();
                        onClick(index);
                    }}>
                    {smartTag.slug}
                </li>
            ))}
        </ul>
    );
};

export default SmartTagSuggestions;
