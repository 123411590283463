import React, {Fragment} from 'react';
import ConfirmModal from 'modals/ConfirmModal';
import MeModalWithState from '../../modals/me/MeModalWithState';

const Modals = () => (
    <Fragment>
        <ConfirmModal />
        <MeModalWithState />
    </Fragment>
);

export default Modals;
