import {useSelector} from 'react-redux';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';

const useTranslate = () => {
    const company = useSelector(({company: {data}}) => data);
    const localize = useSelector(
        ({localize: localizeFromState}) => localizeFromState,
    );
    return {
        translate: getTranslate(localize),
        companyLanguages: company.languages,
        defaultLanguage: company.default_language,
        activeLanguage: getActiveLanguage(localize).code,
    };
};

export default useTranslate;
