/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import {Translate} from 'react-localize-redux';
import Mood from 'lib/components/Mood';
import * as theme from 'lib/styles/theme';
import CustomBlocks from 'lib/constants/customBlocks';
import PropTypes from 'prop-types';
import CustomBlock from '../CustomBlock/CustomBlock';

const HappinessPollWrap = ({
    survey,
    onEdit,
    onDelete,
    onChange,
    interactive,
    canEdit,
}) => {
    const response = survey.response && survey.response.option;

    return (
        <CustomBlock
            type={CustomBlocks.SURVEY_BLOCK}
            label={<Translate id="editor.survey.happiness_poll" />}
            icon="smiley-o"
            title={survey.content.question}
            description={survey.content.description}
            onEdit={() => {
                onEdit(survey);
            }}
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            <div className={classNames('happiness-poll-block', {interactive})}>
                {survey.options &&
                    survey.options.map((smiley, smileyKey) => (
                        <div
                            key={smiley.id}
                            className={classNames('smiley', {
                                isActive:
                                    response && smiley.value === response.value,
                            })}
                            onClick={() => interactive && onChange(smiley)}
                            role="button"
                            tabIndex={0}>
                            <Mood mood={smileyKey} />
                        </div>
                    ))}
            </div>

            <style jsx>
                {`
                    .happiness-poll-block {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-bottom: -2rem;

                        &.interactive {
                            .smiley {
                                opacity: 0.5;
                                transition: ${theme.defaultTransition} opacity;
                                cursor: pointer;

                                &:hover,
                                &.isActive {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    @media (min-width: 350px) {
                        .happiness-poll-block {
                            justify-content: flex-start;
                        }

                        .smiley {
                            margin: 0;
                            margin-right: ${theme.spacing}rem;
                            max-width: 7rem;
                            flex: 1;
                        }
                    }

                    .smiley {
                        margin: 0 ${theme.spacingSmall / 2}rem;
                        margin-bottom: ${theme.spacing}rem;
                    }
                `}
            </style>
        </CustomBlock>
    );
};

HappinessPollWrap.propTypes = {
    survey: PropTypes.shape({
        response: PropTypes.shape({option: PropTypes.string}),
        content: PropTypes.shape({
            question: PropTypes.string,
            description: PropTypes.string,
        }),
        options: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    interactive: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool.isRequired,
};

export default HappinessPollWrap;
