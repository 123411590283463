import React from 'react';

const FilePowerpoint = () => (
    <path
        fill="currentColor"
        transform="translate(4.000000, 0.000000)"
        d="M16.0064054,4.56307145 L12.3758636,0.656523545 C11.9864133,0.237465963 11.4584919,0 10.9089343,0 L2.07706801,0 C0.930353382,0.00465619535 0,1.0057382 0,2.23962997 L0,21.6094026 C0,22.8432944 0.930353382,23.8443764 2.07706801,23.8443764 L14.5394761,23.8443764 C15.6861907,23.8443764 16.6165441,22.8432944 16.6165441,21.6094026 L16.6165441,6.14617787 C16.6165441,5.55484106 16.3958556,4.98212903 16.0064054,4.56307145 Z M15.0284525,5.62002779 C15.1193243,5.7178079 15.1799054,5.83421278 15.210196,5.96458625 L11.0776961,5.96458625 L11.0776961,1.51791969 C11.1988584,1.55051305 11.307039,1.61569979 11.3979107,1.71347989 L15.0284525,5.62002779 Z M14.5394761,22.3543939 L2.07706801,22.3543939 C1.69627221,22.3543939 1.38471201,22.0191478 1.38471201,21.6094026 L1.38471201,2.23962997 C1.38471201,1.82988477 1.69627221,1.49463871 2.07706801,1.49463871 L9.69298407,1.49463871 L9.69298407,6.33708188 C9.69298407,6.95635586 10.1559971,7.45456876 10.7315181,7.45456876 L15.2318321,7.45456876 L15.2318321,21.6094026 C15.2318321,22.0191478 14.9202719,22.3543939 14.5394761,22.3543939 Z M8.84052074,9.68954253 L6.05811504,9.68954253 C5.77251819,9.68954253 5.53884804,9.94097708 5.53884804,10.248286 L5.53884804,18.8156854 C5.53884804,19.1229943 5.77251819,19.3744289 6.05811504,19.3744289 L6.40429305,19.3744289 C6.6898899,19.3744289 6.92356005,19.1229943 6.92356005,18.8156854 L6.92356005,16.0033434 L8.84052074,16.0033434 C10.5151568,16.0033434 11.7700521,14.7322021 11.7700521,12.7905686 C11.7700521,10.9932772 10.7401725,9.68954253 8.84052074,9.68954253 Z M9.96559925,14.2386454 C9.64105737,14.5925162 9.19535319,14.7694517 8.62415949,14.7694517 L6.92356005,14.7694517 L6.92356005,10.8535914 L8.64146839,10.8535914 C9.19968042,10.8535914 9.64105737,11.0258706 9.96559925,11.3704291 C10.6276647,12.0735146 10.614683,13.5355599 9.96559925,14.2386454 Z"
    />
);

export default FilePowerpoint;
