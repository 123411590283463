import React, {Fragment} from 'react';
import {Tooltip} from 'react-tippy';

const InfoTooltip = ({children, info}) => (
    <Fragment>
        {children}
        <Tooltip
            title={info}
            position="bottom"
            trigger="mouseenter"
            theme="dark">
            <span className="info-tooltip">?</span>
        </Tooltip>
        <style jsx>
            {`
                @import '../sass/kit';

                .info-tooltip {
                    border-radius: 50%;
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    text-align: center;
                    line-height: 2rem;
                    font-weight: bold;
                    margin-left: 1rem;

                    background-color: $brand;
                    color: $text-color-light;
                }
            `}
        </style>
    </Fragment>
);

export default InfoTooltip;
