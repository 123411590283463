import * as routes from 'routes/index';
import {
    CONTENT_TYPE_MESSAGES,
    CONTENT_TYPE_AUTOMATED_MESSAGES,
} from '../store/actions/editor';
import {STATUS_PUBLISHED} from '../store/actions/messages/index';

export default (contentType, status, isSuperUser) => {
    if (contentType === CONTENT_TYPE_MESSAGES) {
        if (status === STATUS_PUBLISHED) {
            return routes.sentMessages();
        }
        return routes.drafts();
    }

    if (contentType === CONTENT_TYPE_AUTOMATED_MESSAGES) {
        return routes.automatedMessages();
    }

    if (isSuperUser) {
        return routes.drafts();
    }

    return routes.home();
};
