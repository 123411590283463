import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle(
    () => `
    .fullWidth {
        width: 100%;
    }

    .noWrap {
        white-space: nowrap;
    }
`,
);
