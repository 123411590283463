import {useEffect} from 'react';

const useMountEffect = callback => {
    useEffect(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMountEffect;
