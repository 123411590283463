const flatList = ({listActions, listType = null}) => {
    const INITIAL_STATE = {
        isFetching: false,
        isFailed: false,
        ids: [],
    };

    return (state = INITIAL_STATE, action) => {
        const [listRequestType, listSuccessType, listFailureType] = listActions;
        if (action.listType === listType) {
            switch (action.type) {
                case listRequestType:
                    return {
                        ...state,
                        isFetching: true,
                        isFailed: false,
                    };
                case listSuccessType:
                    return {
                        ...state,
                        ids: action.payload.ids,
                        isFetching: false,
                    };
                case listFailureType:
                    return {
                        ...state,
                        isFetching: false,
                        isFailed: true,
                    };
                default:
                    return state;
            }
        }
        return state;
    };
};

export default flatList;
