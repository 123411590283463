import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {format} from 'date-fns';
import Icon from 'lib/components/Icon';
import actions from 'store/actions';
import {
    STATUS_DRAFT,
    STATUS_PROPOSAL,
    DRAFT_SUB_TYPE_OWN,
    DRAFT_SUB_TYPE_TEAM,
    DRAFT_SUB_TYPE_SUPER_USERS,
} from 'lib/store/actions/messages';
import {getById} from 'lib/store/reducers/helpers/byId';
import SearchInput from 'lib/components/SearchInput';
import Table from 'components/Table/Table';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import getUserRoleHelper, {SUPER_USER} from 'util/getUserRoleHelper';
import AudienceLink from 'components/AudienceLink';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';
import {DESC} from 'lib/store/services/index';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import ContentView from 'components/util/ContentView';
import AudienceModal from 'modals/AudienceModal/AudienceModalWithData';
import styled from 'styled-components';
import BadgeCount from 'components/util/BadgeCount';
import PropTypes from 'prop-types';
import {useHistory} from '../../../../node_modules/react-router-dom/cjs/react-router-dom.min';

function getSubTypeOptions(translate, userRole) {
    const subMenu = [
        {
            subType: DRAFT_SUB_TYPE_OWN,
            label: translate('global.own'),
        },
        {
            subType: DRAFT_SUB_TYPE_TEAM,
            label: translate('global.team'),
        },
        {
            subType: DRAFT_SUB_TYPE_SUPER_USERS,
            label: translate('global.ambassadors'),
        },
    ];

    if (userRole === SUPER_USER) {
        subMenu.splice(1, 1);
    }

    return subMenu;
}

const defaultStatus = `${STATUS_DRAFT},${STATUS_PROPOSAL}`;

const Drafts = ({isAmbassador}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userRole = useSelector(state =>
        getUserRoleHelper(getById(state.users.byId, state.users.me)),
    );
    const translate = useSelector(state => getTranslate(state.localize));
    const messages = useSelector(state => state.messages.byId);
    const isFetching = useSelector(
        state => state.messages.paginatedDrafts.isFetching,
    );
    const isFailed = useSelector(
        state => state.messages.paginatedDrafts.isFailed,
    );
    const pages = useSelector(state => state.messages.paginatedDrafts.pages);
    const total = useSelector(state => state.messages.paginatedDrafts.total);
    const pageCount = useSelector(
        state => state.messages.paginatedDrafts.pageCount,
    );
    const totalSuperUserDrafts = useSelector(
        state => state.messages.paginatedSuperUserDrafts.total,
    );
    const company = useSelector(state => state.company.data);
    //
    const editMessage = message => {
        history.push(`/editor/messages/${message.id}`);
    };
    const fetchMessages = params =>
        dispatch(actions.messages.fetchMessages(params, 'view-drafts'));
    const fetchInboxCounts = () => {
        dispatch(
            actions.messages.fetchMessages(
                {
                    filters: {
                        status: defaultStatus,
                        subType: DRAFT_SUB_TYPE_SUPER_USERS,
                    },
                },
                'view-super-user-drafts',
            ),
        );
    };
    const deleteMessage = messageId =>
        dispatch(actions.messages.deleteMessage(messageId));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));

    const [query, setQuery] = useState('');
    const [sort, setSort] = useState({updated_at: DESC});
    const [subType, setSubType] = useState(DRAFT_SUB_TYPE_OWN);

    const fetch = page =>
        fetchMessages({
            filters: {
                status: defaultStatus,
                query,
                sort,
                subType,
            },
            page,
        });

    useEffect(() => {
        fetch(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, sort, subType, isAmbassador]);

    // Only once.
    useEffect(() => {
        fetchInboxCounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            if (pages.length < pageCount) {
                fetch(pages.length + 1);
            }
        }
    };

    const updateSubType = newSubType => {
        setSubType(newSubType);
    };

    const onRowClick = messageId => {
        const message = getById(messages, messageId);

        editMessage(message);
    };

    const onDelete = (e, messageId) => {
        e.stopPropagation();

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('drafts.confirm_delete'),
            buttonActions: [
                {
                    label: translate('global.archive'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteMessage(messageId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    const columns = [
        {
            key: 'icon',
            className: 'table__icon',
            renderer: (content, data) => <Icon name={data} />,
        },
        {
            key: 'title',
            label: translate('global.title'),
        },
        {
            key: 'audience',
            label: translate('global.audience'),
            renderer: (content, data) => (
                <AudienceLink users={content} audienceFilters={data} />
            ),
            nowrap: true,
        },
        {
            key: 'updated_at',
            label: translate('drafts.last_edited'),
            sortable: query === '',
            nowrap: true,
        },
        {
            key: 'delete',
            label: translate('global.actions'),
            renderer: (content, data) => (
                <ButtonWithoutStyle
                    onClick={e => onDelete(e, data)}
                    className="icon danger"
                    title={translate('editor.delete_message')}>
                    <Icon size={20} name="trash" />
                </ButtonWithoutStyle>
            ),
        },
    ];

    if (!isAmbassador) {
        columns.splice(3, 0, {
            key: 'created_by',
            label: translate('drafts.created_by'),
            nowrap: true,
        });
    }

    const rows = [];
    if (pages.length) {
        pages.forEach(page => {
            page.forEach(messageId => {
                const {
                    app_domain: appDomain,
                    title,
                    id,
                    updated_at: updatedAt,
                    created_by: createdBy,
                    audience_count: audienceCount,
                    audience_filters: audienceFilters,
                } = getById(messages, messageId);

                const data = [
                    {
                        className: 'table__icon',
                        data: appDomain ? appDomain.icon : null,
                    },
                    {
                        className: 'font-weight-bold',
                        content: title[company.default_language],
                    },
                    {
                        content: audienceCount || 0,
                        data: audienceFilters,
                    },
                    {
                        content: format(updatedAt, DATE_TIME_FORMAT),
                    },
                    {
                        data: messageId,
                    },
                ];

                if (!isAmbassador) {
                    data.splice(3, 0, {
                        content: createdBy
                            ? `${createdBy.first_name} ${createdBy.last_name}`
                            : '',
                    });
                }

                rows.push({
                    key: id,
                    data,
                });
            });
        });
    }

    const headerComponents = (
        <SearchInput
            placeholder={translate('drafts.search')}
            value={query}
            onChange={newQuery => setQuery(newQuery)}
        />
    );

    return (
        <ContentView
            title={translate('global.drafts')}
            headerComponents={headerComponents}
            total={total}>
            <TabContainer>
                {getSubTypeOptions(translate, userRole).map(subTypeOption => {
                    const {label} = subTypeOption;

                    return (
                        <TabButton
                            key={label}
                            onClick={() => updateSubType(subTypeOption.subType)}
                            isActive={subTypeOption.subType === subType}>
                            {label}
                            {subTypeOption.subType ===
                                DRAFT_SUB_TYPE_SUPER_USERS && (
                                <BadgeCount>{totalSuperUserDrafts}</BadgeCount>
                            )}
                        </TabButton>
                    );
                })}
            </TabContainer>
            <Table
                rows={rows}
                columns={columns}
                onTableEnd={() => {
                    onTableEnd();
                }}
                onRowClick={messageId => {
                    onRowClick(messageId);
                }}
                loading={isFetching}
                emptyTitle={translate('drafts.no_drafts')}
                sort={sort}
                onSortChange={newSort => {
                    setSort(newSort);
                }}
            />
            <AudienceModal />
        </ContentView>
    );
};

const TabContainer = styled.div(
    ({theme}) => `
    display: flex;
    margin-bottom: ${theme.spacingLarge}rem;
`,
);

const TabButton = styled(ButtonWithoutStyle)(
    ({theme, isActive}) => `
    display: flex;
    cursor: pointer;
    margin-right: ${theme.spacingLargest}rem;
    color: ${isActive ? theme.colorText : theme.colorTextLight};
    white-space: nowrap;
    font-weight: 800;
    font-size: ${theme.fontSizeLarge}rem;
`,
);

Drafts.propTypes = {
    isAmbassador: PropTypes.bool.isRequired,
};
export default Drafts;
