import React from 'react';
import classNames from 'classnames';
import {ASC, DESC} from 'lib/store/services/index';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';
import Icon, {arrowUp} from 'lib/components/Icon';

const TableHeader = ({
    columns,
    sort,
    multiSort,
    cellWidths,
    onSortChange,
    widthToPercent,
    useFixed,
}) => {
    const onClick = key => {
        let sortOrder;
        let newSort = {};

        if (sort) {
            if (!sort[key]) {
                sortOrder = DESC;
            }

            if (sort[key] === DESC) {
                sortOrder = ASC;
            }

            if (multiSort) {
                newSort = Object.assign({}, sort, {
                    [key]: sortOrder,
                });
            } else {
                newSort[key] = sortOrder;
            }

            if (sort[key] === ASC) {
                delete newSort[key];
            }
        }

        onSortChange(newSort);
    };

    return (
        <Branding.Consumer>
            {colors => (
                <table className="table">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: useFixed
                                        ? '0.25rem'
                                        : widthToPercent(cellWidths[0]),
                                }}
                            />
                            {columns.map(
                                (
                                    {key, className = '', label, sortable},
                                    columnIndex,
                                ) => {
                                    const isSortable = sortable && sort;

                                    return (
                                        <th
                                            key={key}
                                            className={classNames(className, {
                                                isSortable,
                                            })}
                                            onClick={() =>
                                                isSortable && onClick(key)
                                            }
                                            style={
                                                useFixed
                                                    ? {
                                                          width: cellWidths[
                                                              columnIndex
                                                          ],
                                                          minWidth:
                                                              cellWidths[
                                                                  columnIndex
                                                              ] === '100%'
                                                                  ? 'initial'
                                                                  : cellWidths[
                                                                        columnIndex
                                                                    ],
                                                      }
                                                    : {
                                                          width: widthToPercent(
                                                              cellWidths[
                                                                  columnIndex +
                                                                      1
                                                              ],
                                                          ),
                                                      }
                                            }>
                                            <div className="content">
                                                {isSortable && (
                                                    <div className="sort">
                                                        <Icon
                                                            name={arrowUp}
                                                            className={classNames(
                                                                'asc',
                                                                {
                                                                    isActive:
                                                                        sort[
                                                                            key
                                                                        ] ===
                                                                        ASC,
                                                                },
                                                            )}
                                                            size={14}
                                                        />
                                                        <Icon
                                                            name={arrowUp}
                                                            className={classNames(
                                                                'desc',
                                                                {
                                                                    isActive:
                                                                        sort[
                                                                            key
                                                                        ] ===
                                                                        DESC,
                                                                },
                                                            )}
                                                            size={14}
                                                        />
                                                    </div>
                                                )}
                                                {label}
                                            </div>
                                        </th>
                                    );
                                },
                            )}
                            <th
                                style={{
                                    width: widthToPercent(
                                        cellWidths[cellWidths.length - 1],
                                    ),
                                }}
                            />
                        </tr>
                    </thead>

                    <style jsx>
                        {`
                            @import '../../lib/sass/kit';

                            .table {
                                margin-bottom: 0;
                            }

                            thead {
                                color: ${theme.colorTextGrey};
                                text-transform: uppercase;
                                font-size: ${theme.fontSizeSmaller}rem;
                                font-weight: 600;

                                th {
                                    background-color: ${theme.colorGrey50};
                                    border-bottom: solid 0.1rem
                                        ${theme.colorBorder};
                                    padding: 1.6rem 1rem;

                                    &:first-child {
                                        border-top-left-radius: ${theme.borderRadiusLarge}rem;
                                    }
                                    &:last-child {
                                        border-top-right-radius: ${theme.borderRadiusLarge}rem;
                                    }
                                }
                            }

                            .content {
                                display: flex;
                                align-items: center;
                            }

                            .isSortable {
                                cursor: pointer;
                            }

                            .sort {
                                display: flex;
                                position: relative;
                                padding-right: 0.8rem;
                                margin-right: 0.5rem;
                                cursor: pointer;

                                :global(.asc),
                                :global(.desc) {
                                    opacity: 0.5;
                                }

                                :global(.asc.isActive),
                                :global(.desc.isActive) {
                                    opacity: 1;
                                }

                                :global(.desc) {
                                    transform: scaleY(-1);
                                    position: absolute;
                                    display: inline-block;
                                    left: 0.8rem;
                                    top: 0.2rem;
                                }
                            }
                        `}
                    </style>
                </table>
            )}
        </Branding.Consumer>
    );
};

export default TableHeader;
