import React from 'react';
import Icon from 'lib/components/Icon';
import Button from './ButtonHOC';

const PollsButton = props => (
    <Button {...props} type="survey">
        <Icon name="privacy" size="26" />
    </Button>
);

export default PollsButton;
