import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Select from 'components/util/Select';

const RepeatMonthlyOn = ({mode, on, hasMoreModes, handleChange, translate}) => {
    const isActive = mode === 'on';

    return (
        <div
            className={`form-group react-rule__flex ${!isActive ? 'opacity-50' : ''}`}>
            <div className="react-rule__col">
                {hasMoreModes && (
                    <input
                        type="radio"
                        name="repeat.monthly.mode"
                        aria-label="Repeat monthly on"
                        value="on"
                        checked={isActive}
                        onChange={handleChange}
                    />
                )}
            </div>
            <div className="react-rule__col noWrap">
                {translate('editor.recurrence.on_day')}
            </div>

            <div className="react-rule__col fullWidth">
                <Select
                    name="repeat.monthly.on.day"
                    aria-label="Repeat monthly on a day"
                    value={on.day}
                    isDisabled={!isActive}
                    onChange={handleChange}
                    options={[...new Array(31)].map((day, i) => ({
                        value: i + 1,
                        label: i + 1,
                    }))}
                />
            </div>
        </div>
    );
};
RepeatMonthlyOn.propTypes = {
    mode: PropTypes.oneOf(['on', 'on the']).isRequired,
    on: PropTypes.shape({
        day: PropTypes.number.isRequired,
    }).isRequired,
    hasMoreModes: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatMonthlyOn);
