import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M3 7H2"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 4H2"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.438 16H6.85003C6.47583 16.0001 6.1068 15.9126 5.77242 15.7446C5.43803 15.5767 5.14756 15.3328 4.92419 15.0326C4.70082 14.7324 4.55075 14.3841 4.48596 14.0155C4.42116 13.647 4.44345 13.2684 4.55103 12.91L6.71103 5.71C6.85942 5.21566 7.16322 4.78232 7.57736 4.47429C7.99149 4.16625 8.4939 3.99993 9.01003 4H19.599C19.9732 3.99995 20.3423 4.0874 20.6767 4.25537C21.011 4.42334 21.3015 4.66717 21.5249 4.96739C21.7482 5.26761 21.8983 5.6159 21.9631 5.98445C22.0279 6.353 22.0056 6.7316 21.898 7.09L19.738 14.29C19.5893 14.7843 19.2853 15.2176 18.871 15.5256C18.4567 15.8336 17.9543 16 17.438 16V16Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.74304 14.721L11.697 10.085"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.21 10.053L19.282 15.125"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 20H3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.198 4.836L12.001 10.44C12.1576 10.6228 12.3749 10.7429 12.613 10.7783C12.851 10.8138 13.0939 10.7622 13.297 10.633L21.709 5.285"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
