import {combineReducers} from 'redux';
import userActivity from './userActivity';
import appDomains from './appDomains';
import messages from './messages';
import mood from './mood';

export default combineReducers({
    mood: mood(),
    messages: messages(),
    appDomains: appDomains(),
    userActivity: userActivity(),
});
