import React from 'react';
import addHours from 'date-fns/add_hours';
import parse from 'date-fns/parse';
import classNames from 'classnames';
import qs from 'qs';
import {withRouter} from 'react-router-dom';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'lib/styles/theme';
import Button from 'lib/components/Button';
import CustomBlocks from 'lib/constants/customBlocks';
import CustomBlock from '../CustomBlock/CustomBlock';
import Event from '../../Event';
import IcsButton from '../../IcsButton';

const RsvpBlock = ({
    translate,
    survey,
    onEdit,
    onDelete,
    onChange,
    interactive,
    location: routerLocation,
    canEdit,
}) => {
    const {embed, appVersion} = qs.parse(routerLocation.search, {
        ignoreQueryPrefix: true,
    });
    let isAppV3OrHigher = false;
    if (appVersion) {
        const parsedVersion = appVersion.split('.');
        isAppV3OrHigher = parseInt(parsedVersion[0], 10) >= 3;
    }
    // Only show button when not embedding or when embedding and the app version is above 3
    const showIcsButton = embed !== 'true' || isAppV3OrHigher;

    const {title, dateTime, location, description} = survey.content;

    const response = survey.response && survey.response.option.value;

    let event;
    if (survey.content) {
        event = {
            title: title || '',

            description: description || '',
            location: location || '',
            startTime: dateTime,
            endTime: addHours(dateTime, 1),
        };
    }

    return (
        <Branding.Consumer>
            {colors => (
                <CustomBlock
                    type={CustomBlocks.SURVEY_BLOCK}
                    label={translate('editor.survey.rsvp_event')}
                    icon="event"
                    onEdit={() => {
                        onEdit(survey);
                    }}
                    onDelete={() => {
                        onDelete(survey);
                    }}
                    disableActions={interactive || !canEdit}
                    headerCTA={
                        interactive &&
                        event &&
                        showIcsButton && (
                            <div className="ics">
                                <IcsButton
                                    native={isAppV3OrHigher}
                                    title={event.title}
                                    description={event.description}
                                    location={event.location}
                                    start={event.startTime}
                                    end={event.endTime}
                                />
                            </div>
                        )
                    }>
                    <div className={classNames('rsvp-block', {interactive})}>
                        <Event
                            title={title}
                            dateTime={dateTime && parse(dateTime)}
                            location={location}
                        />
                        {description && (
                            <p className="description">{description}</p>
                        )}
                        <div className="buttons">
                            <Button
                                className={classNames('button small', {
                                    isActive: response === 1,
                                })}
                                disabled={!interactive}
                                onClick={() =>
                                    onChange({label: 'Attending', value: 1})
                                }
                                type="button">
                                {translate('rsvp.present')}
                            </Button>
                            <Button
                                className={classNames('button small', {
                                    isActive: response === 0,
                                })}
                                disabled={!interactive}
                                onClick={() =>
                                    onChange({label: 'Not attending', value: 0})
                                }
                                type="button">
                                {translate('rsvp.not_present')}
                            </Button>
                        </div>
                    </div>

                    <style jsx>
                        {`
                            .rsvp-block {
                                :global(.button) {
                                    margin-right: 1rem;
                                    background-color: ${colors.grey[400]};
                                }
                            }

                            .interactive {
                                :global(.button) {
                                    transition: ${theme.defaultTransition}
                                        background;
                                }

                                :global(.button.isActive),
                                :global(.button:hover) {
                                    background-color: ${colors.brand.base};
                                }
                            }

                            .description {
                                margin-bottom: ${theme.spacing}rem;
                            }

                            .ics {
                                margin-left: auto;
                            }
                        `}
                    </style>
                </CustomBlock>
            )}
        </Branding.Consumer>
    );
};

export default withRouter(RsvpBlock);
