import {CALL_API} from '../../constants/api';
import {GET} from '../services';

export const APP_DOMAINS_LIST_REQUEST = 'APPDOMAINS_LIST_REQUEST';
export const APP_DOMAINS_LIST_SUCCESS = 'APPDOMAINS_LIST_SUCCESS';
export const APP_DOMAINS_LIST_FAILURE = 'APPDOMAINS_LIST_FAILURE';

export const fetchAppDomains = () => ({
    [CALL_API]: {
        endpoint: '/appdomains',
        method: GET,
        query: {},
        types: [
            APP_DOMAINS_LIST_REQUEST,
            APP_DOMAINS_LIST_SUCCESS,
            APP_DOMAINS_LIST_FAILURE,
        ],
    },
});

export default {
    fetchAppDomains,
};
