import React from 'react';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';
import {connect} from 'react-redux';
import format from 'date-fns/format';
import actions from 'store/actions';
import colors from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import downloadHelper from 'lib/helpers/downloadHelper';
import {getMonthData, LAST_30} from 'util/analytics';
import Count from '../Count';
import BarChartWrapper from './BarChartWrapper';
import Widget from './Widget';

const AdminActivity = ({
    translate,
    messages,
    isLoading,
    langCode,
    fetchAdminActivityExport,
    fetchingAdminActivityExport,
    showNotification,
    ...props
}) => {
    const color = colors.chart.blue;
    const data = messages.map(month => month.published_manual).reverse();
    const monthActivity = getMonthData(messages, LAST_30);

    return (
        <Widget
            {...props}
            title={translate('dashboard.admin_activity')}
            onArrowClick={() =>
                downloadHelper(fetchAdminActivityExport, showNotification)
            }
            fetchingExport={fetchingAdminActivityExport}>
            <LoadingWrapper loading={isLoading}>
                {messages && messages.length ? (
                    <Count
                        color={color}
                        number={
                            monthActivity ? monthActivity.published_manual : ''
                        }
                        text={translate(
                            'dashboard.sent_messages_last_30_days',
                            {
                                month: format(
                                    new Date(),
                                    'MMMM',
                                    getDateLocale(langCode),
                                ),
                            },
                        )}
                    />
                ) : (
                    <p>{translate('global.no_data_available')}</p>
                )}
                <BarChartWrapper data={data} color={color} />
            </LoadingWrapper>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    messages: state.analytics.messages.messages,
    isLoading: state.analytics.messages.isLoading,
    langCode: getActiveLanguage(state.localize).code,
    fetchingAdminActivityExport: state.analytics.messages.fetchingAdminActivityExport,
});

const mapDispatchToProps = dispatch => ({
    fetchAdminActivityExport: () =>
        dispatch(actions.analytics.messages.fetchAdminActivityExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminActivity);
