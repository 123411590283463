import {CALL_API} from '../../constants/api';
import {GET, PUT, POST, DELETE} from '../services';

export const COMPANY_VIEW_REQUEST = 'COMPANY_VIEW_REQUEST';
export const COMPANY_VIEW_SUCCESS = 'COMPANY_VIEW_SUCCESS';
export const COMPANY_VIEW_FAILURE = 'COMPANY_VIEW_FAILURE';

export const fetchCompany = (isAuthenticated = true) => ({
    [CALL_API]: {
        endpoint: `${isAuthenticated ? '' : '/public'}/company`,
        method: GET,
        types: [
            COMPANY_VIEW_REQUEST,
            COMPANY_VIEW_SUCCESS,
            COMPANY_VIEW_FAILURE,
        ],
    },
});

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAILURE = 'COMPANY_UPDATE_FAILURE';

export const updateCompany = body => ({
    [CALL_API]: {
        endpoint: '/company',
        method: PUT,
        body,
        types: [
            COMPANY_UPDATE_REQUEST,
            COMPANY_UPDATE_SUCCESS,
            COMPANY_UPDATE_FAILURE,
        ],
    },
});

export const COMPANY_LOGO_UPDATE_REQUEST = 'COMPANY_LOGO_UPDATE_REQUEST';
export const COMPANY_LOGO_UPDATE_SUCCESS = 'COMPANY_LOGO_UPDATE_SUCCESS';
export const COMPANY_LOGO_UPDATE_FAILURE = 'COMPANY_LOGO_UPDATE_FAILURE';

export const updateCompanyLogo = body => ({
    [CALL_API]: {
        endpoint: '/company/logo',
        method: POST,
        body,
        types: [
            COMPANY_LOGO_UPDATE_REQUEST,
            COMPANY_LOGO_UPDATE_SUCCESS,
            COMPANY_LOGO_UPDATE_FAILURE,
        ],
    },
});

export const COMPANY_BANNER_UPDATE_REQUEST = 'COMPANY_BANNER_UPDATE_REQUEST';
export const COMPANY_BANNER_UPDATE_SUCCESS = 'COMPANY_BANNER_UPDATE_SUCCESS';
export const COMPANY_BANNER_UPDATE_FAILURE = 'COMPANY_BANNER_UPDATE_FAILURE';

export const updateCompanyBanner = body => ({
    [CALL_API]: {
        endpoint: '/company/banner',
        method: POST,
        body,
        types: [
            COMPANY_BANNER_UPDATE_REQUEST,
            COMPANY_BANNER_UPDATE_SUCCESS,
            COMPANY_BANNER_UPDATE_FAILURE,
        ],
    },
});

export const COMPANY_BANNER_DELETE_REQUEST = 'COMPANY_BANNER_DELETE_REQUEST';
export const COMPANY_BANNER_DELETE_SUCCESS = 'COMPANY_BANNER_DELETE_SUCCESS';
export const COMPANY_BANNER_DELETE_FAILURE = 'COMPANY_BANNER_DELETE_FAILURE';

export const deleteCompanyBanner = body => ({
    [CALL_API]: {
        endpoint: '/company/banner',
        method: DELETE,
        body,
        types: [
            COMPANY_BANNER_DELETE_REQUEST,
            COMPANY_BANNER_DELETE_SUCCESS,
            COMPANY_BANNER_DELETE_FAILURE,
        ],
    },
});

export default {
    fetchCompany,
    updateCompany,
    updateCompanyLogo,
    updateCompanyBanner,
    deleteCompanyBanner,
};
