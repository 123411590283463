import React from 'react';
import styled from 'styled-components';
import {Translate} from 'react-localize-redux';
import SearchInput from 'lib/components/SearchInput';
import ButtonGroup from 'lib/components/ButtonGroup';
import {saveFile} from 'lib/helpers/downloadHelper';
import Table from 'components/Table/Table';
import Button from 'components/util/Button';
import {ALL, UNREAD, READ, CHEERED} from 'util/constants';

const EditorFollowUp = ({
    translate,
    isFetching,
    filters,
    stats,
    fetchAudienceExport,
    isFetchingExport,
    showFailure,
    selectedFilter,
    onSortChange,
    rows,
    onQueryChange,
    onTableEnd,
    onFilterChange,
    canOpenMessageReadsByHour,
    openMessageReadsByHourModal,
}) => {
    const {all, unread, read, cheered} = stats;

    const columns = [
        {
            className: 'table__icon',
            key: 'icon',
            renderer: data => (
                <div
                    className={`avatar ${data.isOnline ? '--online' : '--offline'}`}
                    style={{backgroundImage: `url(${data.avatar})`}}
                    alt="profile"
                />
            ),
        },
        {
            key: 'first_name',
            label: translate('users.firstname'),
            sortable: true,
        },
        {key: 'last_name', label: translate('users.lastname'), sortable: true},
        {key: 'channel', label: translate('editor.follow_up.channel')},
        // For the userMessage this is the 'received_at', but we show it here as sent_at for the admin.
        {key: 'received_date', label: translate('global.date_sent')},
        {
            key: 'reminder_date',
            label: translate('editor.follow_up.reminder_date'),
        },
        {key: 'read_date', label: translate('editor.follow_up.read_date')},
    ];

    return (
        <FollowUpContainer>
            <TopContainer>
                <FollowUpMenu>
                    <ButtonGroup>
                        <Button
                            isInActive={selectedFilter !== ALL}
                            onClick={() => onFilterChange(ALL)}>
                            <Translate id="editor.follow_up.all_users" />
                            {all >= 0 && ` (${all})`}
                        </Button>
                        <Button
                            isInActive={selectedFilter !== UNREAD}
                            onClick={() => onFilterChange(UNREAD)}>
                            <Translate id="editor.follow_up.users_not_read" />
                            {unread >= 0 && ` (${unread})`}
                        </Button>
                        <Button
                            isInActive={selectedFilter !== READ}
                            onClick={() => onFilterChange(READ)}>
                            <Translate id="editor.follow_up.users_read" />
                            {read >= 0 && ` (${read})`}
                        </Button>
                        <Button
                            isInActive={selectedFilter !== CHEERED}
                            onClick={() => onFilterChange(CHEERED)}>
                            <Translate id="editor.follow_up.users_cheered" />
                            {cheered >= 0 && ` (${cheered})`}
                        </Button>
                    </ButtonGroup>
                </FollowUpMenu>

                <TopRight>
                    <SearchColumn>
                        <SearchInput
                            key={selectedFilter}
                            value={filters.query || ''}
                            onChange={onQueryChange}
                        />
                    </SearchColumn>
                    <FollowUpExtraMenu>
                        <MarginContainer>
                            <Button
                                isLoading={isFetchingExport}
                                className="button"
                                onClick={async () => {
                                    try {
                                        const audienceExport =
                                            await fetchAudienceExport();
                                        saveFile(audienceExport);
                                    } catch (error) {
                                        if (error.statusCode === 404) {
                                            showFailure(
                                                'notifications.nothing_to_export',
                                            );
                                        } else {
                                            showFailure(
                                                'notifications.general_error',
                                                {error},
                                            );
                                        }
                                    }
                                }}>
                                <Translate id="global.export" />
                            </Button>
                        </MarginContainer>
                        <Button
                            disabled={!canOpenMessageReadsByHour}
                            isInActive={!canOpenMessageReadsByHour}
                            onClick={() => openMessageReadsByHourModal()}>
                            <Translate id="editor.follow_up.read_by_hour" />
                        </Button>
                    </FollowUpExtraMenu>
                </TopRight>
            </TopContainer>

            <Content>
                <Table
                    rows={rows}
                    columns={columns}
                    onTableEnd={onTableEnd}
                    loading={isFetching}
                    sort={filters.sort || {}}
                    onSortChange={onSortChange}
                />
            </Content>
        </FollowUpContainer>
    );
};

const FollowUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
`;

const FollowUpMenu = styled.div`
    margin: 0 1rem;
    @media (max-width: 1800px) {
        order: 2;
        margin-right: auto;
    }
    button {
        white-space: nowrap;
    }
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -1rem 2rem;
    @media (max-width: 1800px) {
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
`;

const FollowUpExtraMenu = styled.div`
    margin: 0 1rem 0 auto;
`;

const Column = styled.div`
    margin: 0 1rem;
`;

const TopRight = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1800px) {
        margin-left: auto;
    }
`;

const SearchColumn = styled(Column)`
    width: auto;
    min-width: 16rem;
    flex: 1;
    margin: 0;
    padding: 0 1rem;
`;

const Content = styled.div`
    flex-grow: 1;
    flex: auto;
    overflow: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const MarginContainer = styled.div`
    margin-right: 16px;
    margin-bottom: 16px;
    display: inline-block;
`;

export default EditorFollowUp;
