import auth from 'lib/store/actions/auth';
import company from 'lib/store/actions/company';
import editor from 'lib/store/actions/editor';
import surveyEditor from 'lib/store/actions/surveyEditor';
import tabMenu from 'lib/store/actions/tabMenu';
import modals from 'lib/store/actions/modals';
import notifications from 'lib/store/actions/notifications';
import media from 'lib/store/actions/media';
import surveys from 'lib/store/actions/surveys';
import users from 'lib/store/actions/users';
import messages from 'lib/store/actions/messages';
import automatedMessages from 'lib/store/actions/messages/automated';
import messagesAudience from 'lib/store/actions/users/audience';
import templates from 'lib/store/actions/templates';
import appDomains from 'lib/store/actions/appDomains';
import analytics from 'lib/store/actions/analytics';
import smartTags from 'lib/store/actions/smartTags';
import embedModal from 'lib/store/actions/embedModal';
import embeds from 'lib/store/actions/embeds';
import channels from 'lib/store/actions/channels';
import audience from 'lib/store/actions/audience';
import segmentation from 'lib/store/actions/segmentation';
import directories from 'lib/store/actions/directories';
import history from 'lib/store/actions/history';
import messageComments from 'lib/store/actions/messages/comments';
import * as appHealth from 'lib/store/actions/appHealth';
import * as userManagement from 'lib/store/actions/userManagement';
import * as timezones from 'lib/store/actions/timezones';

export default {
    auth,
    editor,
    surveyEditor,
    tabMenu,
    modals,
    notifications,
    company,
    media,
    surveys,
    users,
    messages,
    automatedMessages,
    messagesAudience,
    templates,
    appDomains,
    analytics,
    smartTags,
    embedModal,
    embeds,
    channels,
    audience,
    segmentation,
    directories,
    history,
    appHealth,
    userManagement,
    messageComments,
    timezones,
};
