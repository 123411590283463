import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import UserList from 'components/util/UserList';
import {MODAL_USER_LIST} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import AudienceResult from 'components/util/AudienceResult/AudienceResult';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';

import PropTypes from 'prop-types';

const UsersModal = ({
    fetchAudienceForMessage,
    filters,
    isFetching,
    isFailed,
    pages,
    pageCount,
    audience,
    messageId,
    isVisible,
    sentToCalculation,
}) => {
    const DEFAULT_FILTER = {
        query: filters.query || '',
        sort: filters.sort || [],
    };

    const onQueryChange = (newFilters, page = 1) => {
        if (messageId) {
            return fetchAudienceForMessage(messageId, {
                filters: {...DEFAULT_FILTER, ...filters, ...newFilters},
                page,
            });
        }
        return undefined;
    };

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                onQueryChange(DEFAULT_FILTER, page);
            }
        }
    };

    const [isFetchingInitialData, setFetchingInitialData] = useState(true);

    const fetchInitialData = async () => {
        setFetchingInitialData(true);
        await onQueryChange();
        setFetchingInitialData(false);
    };

    useEffect(() => {
        if (isVisible && messageId) {
            fetchInitialData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, messageId]);

    return (
        <Modal name={MODAL_USER_LIST} noPadding big>
            <div className="userModal">
                <div className="userList">
                    <UserList
                        audience={audience}
                        isFetchingInitialData={isFetchingInitialData}
                        isFetching={isFetching}
                        pages={pages}
                        filters={filters}
                        onTableEnd={onTableEnd}
                        onQueryChange={onQueryChange}
                    />
                </div>
                {sentToCalculation && (
                    <div className="audience">
                        <AudienceResult audience={sentToCalculation} />
                    </div>
                )}
            </div>
            <style jsx>
                {`
                    .userModal {
                        display: flex;
                    }

                    .userList {
                        padding-top: ${theme.spacing}rem;
                        flex: 1;
                    }

                    .audience {
                        padding: ${theme.spacing}rem;
                        padding-top: ${theme.spacingLargest}rem;
                        min-height: 100%;
                        width: 30rem;
                        background-color: ${colors.grey[100]};
                        display: flex;
                        justify-content: center;
                        align-items: start;
                    }
                `}
            </style>
        </Modal>
    );
};

const mapStateToProps = ({modals, messagesAudience: {byId, paginated}}) => {
    const modalData = modals.data[MODAL_USER_LIST];
    const isVisible = modals.visibility[MODAL_USER_LIST];

    const {isFetching, isFailed, pages, filters, pageCount} = paginated;

    return {
        messageId: modalData && modalData.messageId,
        sentToCalculation: modalData && modalData.sentToCalculation,
        audience: byId,
        isFetching,
        isFailed,
        pages,
        filters,
        pageCount,
        isVisible,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchAudienceForMessage: (messageId, params) =>
        dispatch(
            actions.messagesAudience.fetchAudienceForMessage({
                ...params,
                messageId,
            }),
        ),
});
UsersModal.propTypes = {
    fetchAudienceForMessage: PropTypes.func.isRequired,
    filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFailed: PropTypes.bool.isRequired,
    pages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    pageCount: PropTypes.number.isRequired,
    audience: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    messageId: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    sentToCalculation: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersModal);
