import React from 'react';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import {useSelector} from 'react-redux';

const ConfirmModal = () => {
    const data = useSelector(state => state.modals.data[MODAL_CONFIRM]);
    return (
        <Modal
            name={MODAL_CONFIRM}
            cancelable
            buttonActions={data.buttonActions}>
            <h2>{data.title}</h2>
            {data.body && <p className="body">{data.body}</p>}
            {data.bodyBold && <p className="bodyBold">{data.bodyBold}</p>}

            <style jsx>
                {`
                    .body {
                        margin-top: 1.6rem;
                    }
                    .bodyBold {
                        font-weight: bold;
                    }
                `}
            </style>
        </Modal>
    );
};

export default ConfirmModal;
