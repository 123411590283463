import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M6.0769 9.46153V5.87179C6.0769 5.39576 6.2471 4.93923 6.55004 4.60263C6.85298 4.26602 7.26386 4.07692 7.69229 4.07692C8.12071 4.07692 8.53159 4.26602 8.83454 4.60263C9.13748 4.93923 9.30767 5.39576 9.30767 5.87179"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.30768 9.46154V4.61538C9.30768 4.18696 9.47787 3.77608 9.78081 3.47313C10.0838 3.17019 10.4946 3 10.9231 3C11.3515 3 11.7624 3.17019 12.0653 3.47313C12.3683 3.77608 12.5384 4.18696 12.5384 4.61538"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7692 6.76923C15.7692 6.3408 15.9185 5.92992 16.1843 5.62698C16.45 5.32404 17.0088 5.15384 17.3846 5.15384C17.7604 5.15384 18.3192 5.32404 18.5849 5.62698C18.8507 5.92992 19 6.3408 19 6.76923V13.7692C19 15.4829 18.4028 17.1264 17.3397 18.3382C16.2767 19.55 14.8349 20.2308 13.3315 20.2308H10.6551C9.29735 20.2309 7.98469 19.6754 6.95586 18.6655C5.92703 17.6556 5.25038 16.2583 5.04898 14.7277C5.01385 13.2308 4.5415 9.46153 7.45052 9.46153H13.0441C13.0441 9.46153 15.7693 9.38498 15.7692 10.7043C15.7692 12.0235 13.2726 13.2695 12 13.2695C10.7274 13.2695 9.73749 13.2695 9.73749 13.2695C9.73749 13.2695 12.5719 12.6923 13.0442 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 9.46153C19 9.88996 18.8298 10.3008 18.5269 10.6038C18.2239 10.9067 17.813 11.0769 17.3846 11.0769C16.9562 11.0769 16.5453 10.9067 16.2424 10.6038C15.9394 10.3008 15.7692 9.88996 15.7692 9.46153"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5385 9.1854V4.54635C12.5385 3.69276 13.2621 3 14.1538 3C15.0455 3 15.7692 3.69276 15.7692 4.54635V9.96162"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
