import {rendererFn} from 'medium-draft';
import {ATOMIC} from 'lib/constants/customBlocks';
import AtomicBlock from '../AtomicBlock/AtomicBlock';

export default ({setEditorState, getEditorState, contentBlock, canEdit}) => {
    const type = contentBlock.getType();

    switch (type) {
        case ATOMIC:
            return {
                component: AtomicBlock,
                editable: false,
                props: {
                    canEdit,
                },
            };
        default:
            return rendererFn(setEditorState, getEditorState)(contentBlock);
    }
};
