import React from 'react';
import UserDetailBlock from 'components/util/UserDetail/UserDetailBlock';
import parse from 'date-fns/parse';
import {Translate} from 'react-localize-redux';

export const ON_CREATION = '@on-creation';
export const ON_FIRST_DAY_OF_WORK = '@on-first-day-of-work';
export const ON_DATE = '@on-date';

const ONBOARDING_TYPES = [
    {
        label: <Translate id="users.onboarding.on_first_day_of_work" />,
        value: ON_FIRST_DAY_OF_WORK,
    },
    {
        label: <Translate id="users.onboarding.on_creation" />,
        value: ON_CREATION,
    },
    {
        label: <Translate id="users.onboarding.on_date" />,
        value: ON_DATE,
    },
];

const Onboarding = ({user, onChange}) => (
    <div className="row">
        <div className="col-md-6">
            <UserDetailBlock
                label="onboarding_type"
                value={user.onboarding_type || ON_FIRST_DAY_OF_WORK}
                type="select"
                options={ONBOARDING_TYPES}
                onChange={option => {
                    if (option.value !== ON_DATE) {
                        // Reset onboarding date
                        onChange({
                            onboarding_type: option.value,
                            onboarding_at: null,
                        });
                    } else {
                        // Set onboarding type
                        onChange({onboarding_type: option.value});
                    }
                }}
                editable
            />
        </div>
        {user.onboarding_type === ON_DATE && (
            <div className="col-md-6">
                <UserDetailBlock
                    id="onboarding_at"
                    label="onboarding"
                    type="date"
                    value={user.onboarding_at && parse(user.onboarding_at)}
                    onChange={value => onChange({onboarding_at: value})}
                    editable
                />
            </div>
        )}
    </div>
);

export default Onboarding;
