/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';
import {MODAL_CHANNEL} from 'lib/store/actions/modals';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import Icon from 'lib/components/Icon';
import Button from 'lib/components/Button';
import Mount from 'lib/components/Mount';
import * as theme from 'lib/styles/theme';
import * as media from 'lib/styles/media';
import useEditorState from 'components/hooks/useEditorState';

const Icons = new Map([
    ['sms', <Icon name="messageCircle" size="18" />],
    ['nexmo', <Icon name="messageCircle" size="18" />],
    ['app', <Icon name="mobilePhone" size="18" />],
    ['pusher', <Icon name="mobilePhone" size="18" />],
    ['mail', <Icon name="mail" size="18" />],
    ['whats_app', <Icon name="whatsApp" size="18" />],
]);

const ChooseChannelsBlock = ({interactive, cancelable}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(
        state => state.auth.accessToken !== null,
    );
    const channels = useSelector(state => state.channels.channels);
    const selectedChannels = useSelector(
        state => state.channels.selectedChannels,
    );
    const meId = useSelector(state => state.users.me);
    const users = useSelector(state => state.users.byId);
    const updatingUserChannels = useSelector(
        state => state.channels.updateUserChannels,
    );
    const updateUserChannels = channel =>
        dispatch(actions.channels.updateUserChannels(channel));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setSelectedChannels = newChannels =>
        dispatch(actions.channels.setSelectedChannels(newChannels));
    const fetchChannels = () =>
        Promise.all([
            dispatch(actions.channels.fetchChannels()),
            dispatch(actions.channels.fetchUserChannels()),
        ]);

    const {
        editor: {currentLanguage},
    } = useEditorState();
    const getActiveClass = channel => {
        if (selectedChannels.length === 0) return '';
        const selectedChannel = selectedChannels.find(item => item === channel);
        if (typeof selectedChannel !== 'undefined') {
            return 'isActive';
        }
        return '';
    };

    const removeChannel = channel => {
        const newChannels = selectedChannels.filter(
            selectedChannel => selectedChannel !== channel,
        );
        setSelectedChannels(newChannels);
    };

    const toggleChannel = channel => {
        const selectedChannel = selectedChannels.find(item => item === channel);
        if (selectedChannel) {
            removeChannel(channel);
        } else {
            const newChannels = [...selectedChannels, channel];
            setSelectedChannels(newChannels);
        }
    };

    const onCancel = () => {
        setModalVisibility(MODAL_CHANNEL, false);
    };

    const onSave = async () => {
        await updateUserChannels(selectedChannels);
        setModalVisibility(MODAL_CHANNEL, false);
    };

    const me = getById(users, meId);

    const translateOptions = {
        language: currentLanguage,
    };

    return (
        <Branding.Consumer>
            {colors => (
                <Mount
                    didMount={() => {
                        if (isAuthenticated) {
                            fetchChannels();
                        }
                    }}>
                    <div
                        className={`choose-channels ${interactive ? '--interactive' : ''}`}>
                        <h2 className="choose-channels__title">
                            <Translate
                                id="channel.title"
                                options={translateOptions}
                            />
                        </h2>

                        {isAuthenticated ? (
                            <Fragment>
                                <div className="choose-channels__options">
                                    {typeof me !== 'undefined' &&
                                        channels.length > 0 &&
                                        channels.map(channel => {
                                            const userDoesNotHaveAnEmail =
                                                me.email === null &&
                                                me.work_email === null;
                                            const userDoesNotHaveMobile =
                                                me.mobile === null &&
                                                me.work_mobile === null;
                                            const channelIsEmail =
                                                channel === 'mail';
                                            const channelIsSms =
                                                channel === 'nexmo' ||
                                                channel === 'sms';
                                            const channelIsWhatsApp =
                                                channel === 'whats_app';

                                            if (
                                                (channelIsEmail &&
                                                    userDoesNotHaveAnEmail) ||
                                                (channelIsSms &&
                                                    userDoesNotHaveMobile) ||
                                                (channelIsWhatsApp &&
                                                    userDoesNotHaveMobile)
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <div
                                                    key={channel}
                                                    role="button"
                                                    tabIndex={-1}
                                                    className={`choose-channels__option ${getActiveClass(channel)}`}
                                                    onClick={() =>
                                                        interactive &&
                                                        toggleChannel(channel)
                                                    }>
                                                    {Icons.get(channel)}
                                                    <Translate
                                                        id={`channel.${channel}`}
                                                        options={
                                                            translateOptions
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>

                                {(interactive || cancelable) && (
                                    <div className="choose-channels__footer">
                                        {cancelable && (
                                            <Button
                                                transparent
                                                onClick={onCancel}>
                                                <Translate
                                                    id="global.cancel"
                                                    options={translateOptions}
                                                />
                                            </Button>
                                        )}
                                        <Button
                                            className="button success"
                                            onClick={onSave}
                                            isLoading={updatingUserChannels}>
                                            <Translate
                                                id="global.save"
                                                options={translateOptions}
                                            />
                                        </Button>
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p>
                                    <Translate
                                        id="channel.unauthorized"
                                        options={translateOptions}
                                    />
                                </p>
                                <div className="choose-channels__footer">
                                    <Button
                                        onClick={() => {
                                            history.push(
                                                `/login?redirect=${location.pathname}`,
                                            );
                                        }}>
                                        <Translate
                                            id="auth.login"
                                            options={translateOptions}
                                        />
                                    </Button>
                                </div>
                            </Fragment>
                        )}
                        <style jsx>
                            {`
                                .choose-channels {
                                    $c: &;

                                    &__title {
                                        font-weight: bold;
                                        font-size: 1.8rem;
                                        margin-bottom: 1rem;
                                    }

                                    &__intro {
                                        margin-bottom: 1.5rem;
                                    }

                                    &__options,
                                    &__option,
                                    &__footer {
                                        display: flex;
                                        align-items: center;
                                    }

                                    &__options {
                                        flex-wrap: wrap;
                                        margin-top: 4rem;
                                    }

                                    &__option {
                                        width: 100%;
                                        margin-bottom: 2rem;
                                        transition: ${theme.defaultTransition}
                                            color;

                                        &.isActive {
                                            color: ${colors.brand.darkContrast};
                                        }

                                        :global(svg) {
                                            margin-right: 1rem;
                                        }
                                    }

                                    &__footer {
                                        flex-wrap: wrap;
                                        border-top: solid 0.1rem
                                            ${colors.grey[350]};
                                        width: 100%;
                                        padding-top: 3rem;
                                        margin-top: 3rem;

                                        :global(.button) {
                                            margin-right: 1rem;
                                        }
                                    }

                                    @media (${media.forMediumAndUp}) {
                                        &__footer {
                                            justify-content: flex-end;

                                            :global(.button) {
                                                margin-left: 1rem;
                                                margin-right: 0;
                                            }
                                        }

                                        &__option {
                                            width: 50%;
                                        }
                                    }

                                    &.--interactive {
                                        #{$c}__title {
                                            padding-right: 2rem;
                                        }

                                        #{$c}__option {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            `}
                        </style>
                    </div>
                </Mount>
            )}
        </Branding.Consumer>
    );
};

ChooseChannelsBlock.propTypes = {
    interactive: PropTypes.bool.isRequired,
    cancelable: PropTypes.bool.isRequired,
};
export default ChooseChannelsBlock;
