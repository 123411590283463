import FileSaver from 'file-saver';
import {FAILURE} from '../store/actions/notifications';

const extractFileNameFromHeaders = headers => {
    const contentDisposition = headers.get('content-disposition').split(';');
    if (contentDisposition.length > 1) {
        const attachmentDefinition = contentDisposition[1].split('=');
        if (attachmentDefinition.length > 1) {
            return attachmentDefinition[1].replace(/"/g, '');
        }
    }
    return 'download.xlsx';
};

export const saveFile = async response => {
    const blob = await response.payload.blob();
    FileSaver.saveAs(
        blob,
        extractFileNameFromHeaders(response.payload.headers),
    );
};

export const showNothingToExportNotification = (error, showNotification) => {
    if (error.statusCode === 404) {
        showNotification({
            type: FAILURE,
            content: {
                key: 'notifications.nothing_to_export',
            },
        });
    }
};

export default async (exportFunction, showNotification, ...params) => {
    const response = await exportFunction(...params);
    try {
        await saveFile(response);
    } catch (error) {
        showNothingToExportNotification(error, showNotification);
    }
};
