import React from 'react';
import classNames from 'classnames';
import Icon from 'lib/components/Icon';

const EditButton = ({onClick, size = 12, className}) => (
    <span className={classNames('edit-button', className)} onClick={onClick}>
        <Icon name="edit" size={size} />
        <style jsx>
            {`
                .edit-button {
                    background: none;
                    border: 0;
                    cursor: pointer;
                    color: inherit;
                    display: flex;
                    align-items: center;

                    &:focus {
                        outline: none;
                    }
                }
            `}
        </style>
    </span>
);

export default EditButton;
