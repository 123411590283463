import React from 'react';
import Loading from 'lib/components/Loading';
import styled from 'styled-components';

export default ({children, isLoading, disabled, onClick, ...props}) => (
    <StyledButton
        type="button"
        isLoading={isLoading}
        onClick={() => {
            if (!disabled && typeof onClick === 'function') onClick();
        }}
        disabled={disabled}
        {...props}>
        {isLoading && (
            <LoadingWrap>
                <Loading />
            </LoadingWrap>
        )}

        {children}
    </StyledButton>
);

const StyledButton = styled.button(
    ({
        theme,
        isInActive,
        isLoading,
        small,
        transparent,
        noBorder,
        danger,
        error,
        success,
        disabled,
        ghost,
        fullWidth,
        unstyled,
        className = [],
    }) => {
        let style = `
        display: inline-block;
        justify-content: center;
        // align-items: center;
        position: relative;
        border: 0;
        cursor: pointer;
        text-align: center;
        color: ${theme.colors.text};
        font-weight: ${theme.fwBold};
        white-space: pre-wrap;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.7rem;
        min-height: 4.2rem;
        background: none;
    `;

        if (!unstyled) {
            style += `
            border-radius: ${theme.borderRadius}rem;
            padding: ${theme.spacingSmall}rem ${theme.spacingLarger}rem;
            background-color: ${theme.brand.base};
            transition-property: background, opacity, border, padding;
            transition-duration: .4s;
            color: ${theme.text.light};
            @media (${theme.forMediumAndUp}) {
                &:hover,
                &:focus {
                    background-color: ${theme.brand[600]};
                    text-decoration: none;
                    color: ${theme.text.light};
                    outline: none;
                }
            }
        `;
        }

        if (isInActive) {
            style += 'opacity: 0.5;';
        }

        if (isLoading) {
            style += 'padding-right: 3.5rem;';
        }

        if (small) {
            style += `
            font-size: 1.4rem;
            padding: .5rem .7rem;
        `;
        }

        if (noBorder) {
            return 'border: none;';
        }

        if (danger || className.includes('danger')) {
            style += `
            background-color: ${theme.warning.base};

            @media (${theme.forMediumAndUp}) {
                &:hover,
                &:focus {
                    background-color: ${theme.warning.dark};
                }
            }
        `;
        }

        if (error || className.includes('error')) {
            style += `
            background-color: ${theme.danger.base};

            @media (${theme.forMediumAndUp}) {
                &:hover,
                &:focus {
                    background-color: ${theme.danger.dark};
                }
            }
        `;
        }

        if (success) {
            style += `
            background-color: ${theme.success.base};

            @media (${theme.forMediumAndUp}) {
                &:hover,
                &:focus {
                    background-color: ${theme.success.dark};
                }
            }
        `;
        }

        if (disabled) {
            style += `
            &,
            &:hover,
            &:focus {
                color: ${theme.text.light};
                background-color: ${theme.grey[400]};
                cursor: default;
            }
        `;
        }

        if (ghost) {
            style += `
            background-color: transparent;
            border: solid .1rem ${theme.brand.base};
            color: ${theme.brand.base};
            font-weight: ${theme.fwMedium};

            &:hover,
            &:focus {
                background-color: ${theme.brand.base};
                color: ${theme.text.light};
            }
        `;

            if (disabled) {
                style += `
                &,
                &:hover,
                &:focus {
                    background-color: transparent;
                    border-color: ${theme.grey[400]};
                    color: ${theme.grey[400]};
                }
            `;
            }
        }

        if (transparent) {
            style += `
            &,
            &:hover,
            &:focus {
                background-color: transparent;
                color: ${theme.brand.base};
            }
        `;

            if (disabled) {
                style += `
                &,
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: ${theme.grey[400]};
                }
            `;
            }
        }

        if (fullWidth) {
            style += `
            width: 100%;
        `;
        }

        return style;
    },
);

const LoadingWrap = styled.div`
    height: 3rem;
    width: 3rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    .loading {
        height: 100%;
    }

    .loading path {
        stroke: ${({theme}) => theme.text.light};
    }
`;
