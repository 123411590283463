import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Input from 'components/util/Input';
import RepeatMonthlyOn from './RepeatMonthlyOn';
import RepeatMonthlyOnThe from './RepeatMonthlyOnThe';

const RepeatMonthly = ({
    monthly: {mode, interval, on, onThe, options},
    handleChange,
    translate,
}) => {
    const isTheOnlyOneMode = option => options.modes === option;
    const isOptionAvailable = option =>
        !options.modes || isTheOnlyOneMode(option);

    return (
        <div>
            <div className="form-group react-rule__flex">
                <div className="react-rule__col">
                    {translate('editor.recurrence.every')}
                </div>
                <div className="react-rule__col">
                    <Input
                        name="repeat.monthly.interval"
                        aria-label="Repeat monthly interval"
                        className="form-control"
                        value={interval}
                        type="number"
                        min="1"
                        onNativeChange={handleChange}
                    />
                </div>
                <div className="react-rule__col">
                    {translate('editor.recurrence.months')}
                </div>
            </div>

            {isOptionAvailable('on') && (
                <RepeatMonthlyOn
                    mode={mode}
                    on={on}
                    hasMoreModes={!isTheOnlyOneMode('on')}
                    handleChange={handleChange}
                />
            )}
            {isOptionAvailable('on the') && (
                <RepeatMonthlyOnThe
                    mode={mode}
                    onThe={onThe}
                    hasMoreModes={!isTheOnlyOneMode('on the')}
                    handleChange={handleChange}
                />
            )}
        </div>
    );
};

RepeatMonthly.propTypes = {
    monthly: PropTypes.shape({
        mode: PropTypes.oneOf(['on', 'on the']).isRequired,
        interval: PropTypes.number.isRequired,
        on: PropTypes.object.isRequired,
        onThe: PropTypes.object.isRequired,
        options: PropTypes.shape({
            modes: PropTypes.oneOf(['on', 'on the']),
        }).isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatMonthly);
