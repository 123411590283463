import React from 'react';

export default function () {
    return (
        <path
            d="M4.66988 0.950475C-4.65531 5.70528 1.30101 20.2958 11.0106 16.4815L13.2776 15.5912L16.5151 19.4355C20.1975 23.8076 21.4733 24.6536 22.7676 23.5794C24.3783 22.2425 23.6793 20.977 19.3198 17.339L14.9943 13.7293L15.8622 12.05C17.8555 8.19591 15.9821 3.10227 11.8323 1.09339C8.90777 -0.322504 7.2314 -0.355928 4.66988 0.950475ZM5.67201 8.44083C5.52852 10.1766 5.23808 10.6381 4.19676 10.7862C3.29029 10.9148 2.90016 10.6768 2.90016 9.99503C2.90016 9.19344 3.05402 9.14791 3.76456 9.73744C4.50564 10.3529 4.62897 10.1575 4.62897 8.36707C4.62897 7.21914 4.90385 6.27982 5.23981 6.27982C5.59364 6.27982 5.77574 7.18975 5.67201 8.44083ZM9.52726 8.5849C9.52726 10.2376 9.29329 10.6353 8.23065 10.7862C7.06543 10.9516 6.93404 10.7286 6.93404 8.5849C6.93404 6.44117 7.06543 6.21816 8.23065 6.38355C9.29329 6.53453 9.52726 6.93215 9.52726 8.5849ZM13.5611 8.5849C13.5611 10.2376 13.3272 10.6353 12.2645 10.7862C11.0993 10.9516 10.9679 10.7286 10.9679 8.5849C10.9679 6.44117 11.0993 6.21816 12.2645 6.38355C13.3272 6.53453 13.5611 6.93215 13.5611 8.5849ZM7.51031 8.5509C7.51031 9.16635 7.76964 9.82964 8.08658 10.0256C8.41333 10.2278 8.66285 9.7432 8.66285 8.90703C8.66285 8.09622 8.40353 7.43236 8.08658 7.43236C7.76964 7.43236 7.51031 7.93544 7.51031 8.5509ZM11.7361 7.81673C11.9476 8.02764 12.2934 8.02764 12.5048 7.81673C12.7158 7.60524 12.5429 7.43236 12.1205 7.43236C11.6981 7.43236 11.5252 7.60524 11.7361 7.81673ZM11.8323 9.73744C12.0283 10.0544 12.3026 10.3137 12.4426 10.3137C12.5826 10.3137 12.6967 10.0544 12.6967 9.73744C12.6967 9.42049 12.4219 9.16117 12.0865 9.16117C11.7511 9.16117 11.6364 9.42049 11.8323 9.73744Z"
            fill="currentColor"
        />
    );
}
