import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import ImageWrapper from 'lib/components/Images/ImageWrapper';
import RogerLogo from 'lib/images/logo-white2x.png';
import getUrlBySize, {REGULAR} from 'lib/helpers/sizeHelper';

const Header = ({branding = {}}) => {
    const logo = getUrlBySize(branding.logo, REGULAR);

    return (
        <div className={classNames('l-login__header', {isBranded: logo})}>
            <ImageWrapper
                src={logo || RogerLogo}
                className="sidebar__logo"
                alt="logo"
                disableProgressiveLoading
            />
        </div>
    );
};

const mapStateToProps = state => ({
    branding: state.company.data.branding,
});

export default connect(mapStateToProps)(Header);
