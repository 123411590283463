import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M18.934 11.467C18.9019 13.4262 18.1011 15.2943 16.7043 16.6685C15.3074 18.0427 13.4265 18.8128 11.467 18.8128C9.50753 18.8128 7.62656 18.0427 6.22971 16.6685C4.83287 15.2943 4.03207 13.4262 4 11.467C4.03207 9.50779 4.83287 7.63967 6.22971 6.2655C7.62656 4.89133 9.50753 4.12121 11.467 4.12121C13.4265 4.12121 15.3074 4.89133 16.7043 6.2655C18.1011 7.63967 18.9019 9.50779 18.934 11.467"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 20L16.747 16.747"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 11.5H14"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5 14V9"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
