import React from 'react';

export default function () {
    return (
        <g className="nc-icon-wrapper" fill="#111111">
            <g fill="none">
                <path d="M0 0h24v24H0z" />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M21 7H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
                />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 11h6"
                />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M20.667 7v12a2 2 0 0 1-2 2H5.333a2 2 0 0 1-2-2V7"
                />
            </g>
        </g>
    );
}
