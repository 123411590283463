import React from 'react';

export default function () {
    return (
        <path
            d="M9.28549 2.96751C3.05448 6.4263 1.80591 7.89978 0.857745 12.9108C0.568658 14.4398 0.20676 16.2973 0.0530476 17.0389C-0.156756 18.0556 0.232648 18.8851 1.63655 20.4098L3.49836 22.4323H12.1337H20.7697L22.4848 20.4788C24.0823 18.6591 24.1762 18.3344 23.8461 15.7596C23.1703 10.4838 22.4735 8.75572 20.102 6.47484C17.6232 4.0915 13.6218 1.89746 11.9466 2.00371C11.3727 2.03984 10.1754 2.47347 9.28549 2.96751ZM13.7561 8.00495C13.9519 13.9361 14.9486 14.2581 14.9486 8.39058C14.9486 4.22417 14.9901 4.07909 16.0273 4.63407C16.7926 5.04343 17.1059 5.76345 17.1059 7.11181C17.1059 8.24173 17.3249 8.87707 17.6453 8.67913C17.9419 8.49575 18.1846 7.86634 18.1846 7.28008C18.1846 6.38316 18.3545 6.30495 19.2579 6.7882C20.7433 7.58373 21.96 10.1386 22.3268 13.2333L22.6358 15.8416L19.8442 16.4402C16.271 17.2067 7.30665 17.1387 4.16175 16.3216C1.75467 15.6954 1.73633 15.6733 1.91917 13.5731C2.15756 10.8306 2.87919 8.97307 4.29173 7.46561C5.76305 5.89505 6.31911 5.93173 6.31911 7.60045C6.31911 9.73246 7.31474 9.16831 7.50027 6.93059C7.61731 5.52021 7.9517 4.85898 8.61131 4.73385C9.44297 4.57636 9.55516 5.04074 9.55516 8.63976C9.55516 11.0032 9.78222 12.7242 10.0945 12.7242C10.4143 12.7242 10.6338 10.7346 10.6338 7.83452V2.94432L12.117 3.11529C13.5954 3.28518 13.6008 3.30136 13.7561 8.00495ZM12.2519 17.8479C15.6632 17.8479 19.4855 17.6446 20.7465 17.396C23.4971 16.8539 23.776 17.6036 21.6688 19.8758L20.2983 21.3536H12.0442C3.15426 21.3536 3.11435 21.3418 1.49471 18.2136C0.846418 16.9618 0.848576 16.9613 3.44766 17.4046C4.87853 17.6484 8.84053 17.8479 12.2519 17.8479Z"
            fill="currentColor"
        />
    );
}
