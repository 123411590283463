import React from 'react';
import color from 'tinycolor2';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import {isAfter, isToday} from 'date-fns';
import {Translate} from 'react-localize-redux';
import {brandingDefaults} from 'lib/styles/colors';
import Switch from 'components/util/Switch';
const isValidDate = current => isToday(current) || isAfter(current, new Date());

/**
 *  conditional rendering of send button
 */
const EditorSidebarSendWhen = ({
    canEdit,
    timezone,
    scheduledAt,
    isSuperUser,
    onSendNowChange,
    onScheduledAtChange,
    isInCompanyTimezone,
    scheduledAtFormatted,
}) => (
    <div className="form__group">
        <label className="label" htmlFor="default_when">
            <Translate id="editor.form.when.label" />
        </label>
        {!isSuperUser && (
            <div className="row">
                <p className="col-sm-8">
                    <Translate id="editor.form.send_now.label" />
                </p>
                <SwitchCol>
                    <Switch
                        disabled={!canEdit}
                        value={scheduledAt === null}
                        offColor={brandingDefaults.danger.base}
                        onChange={onSendNowChange}
                    />
                </SwitchCol>
            </div>
        )}
        {(!!scheduledAt || isSuperUser) && (
            <>
                <Translate>
                    {({translate}) => (
                        <Datetime
                            id="default_when"
                            isValidDate={isValidDate}
                            dateFormat="DD/MM/YYYY"
                            timeFormat="HH:mm"
                            initialValue={scheduledAt || new Date()}
                            inputProps={{
                                placeholder: translate(
                                    'editor.form.when.placeholder',
                                ),
                                disabled: !canEdit,
                            }}
                            onChange={onScheduledAtChange}
                        />
                    )}
                </Translate>
                {!isInCompanyTimezone && (
                    <TimezoneInfo>
                        <p>
                            <Translate id="We use the company timezone as a reference." />
                        </p>
                        <p>
                            <Translate id="The message will be sent at:" />
                        </p>
                        <p>
                            {scheduledAtFormatted} {timezone}
                        </p>
                    </TimezoneInfo>
                )}
            </>
        )}
    </div>
);

const SwitchCol = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    justify-content: flex-end;
`;

const TimezoneInfo = styled.div(
    ({theme}) => `
    margin-top: ${theme.spacing}rem;
    padding: ${theme.spacingSmallest}rem;
    font-size: ${theme.fontSizeSmaller}rem;
    background-color: ${color(theme.warning.base).setAlpha(0.3).toRgbString()};
    border: solid .1rem ${theme.warning.base};
    border-radius: ${theme.borderRadiusSmall}rem;

    p {
        margin-bottom: ${theme.spacingSmallest}rem;
    }
`,
);

export default EditorSidebarSendWhen;
