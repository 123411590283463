import {CALL_API} from '../../../constants/api';

export const FETCH_APP_DOMAINS_ANALYTICS_REQUEST =
    'FETCH_APP_DOMAINS_ANALYTICS_REQUEST';
export const FETCH_APP_DOMAINS_ANALYTICS_SUCCESS =
    'FETCH_APP_DOMAINS_ANALYTICS_SUCCESS';
export const FETCH_APP_DOMAINS_ANALYTICS_FAILURE =
    'FETCH_APP_DOMAINS_ANALYTICS_FAILURE';

export const fetchAdminUsage = () => ({
    [CALL_API]: {
        endpoint: '/analytics/admin-usage',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_APP_DOMAINS_ANALYTICS_REQUEST,
            FETCH_APP_DOMAINS_ANALYTICS_SUCCESS,
            FETCH_APP_DOMAINS_ANALYTICS_FAILURE,
        ],
    },
});

export const FETCH_APP_DOMAINS_ANALYTICS_EXPORT_REQUEST =
    'FETCH_APP_DOMAINS_ANALYTICS_EXPORT_REQUEST';
export const FETCH_APP_DOMAINS_ANALYTICS_EXPORT_SUCCESS =
    'FETCH_APP_DOMAINS_ANALYTICS_EXPORT_SUCCESS';
export const FETCH_APP_DOMAINS_ANALYTICS_EXPORT_FAILURE =
    'FETCH_APP_DOMAINS_ANALYTICS_EXPORT_FAILURE';

export const fetchAdminUsageExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/admin-usage/export',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_APP_DOMAINS_ANALYTICS_EXPORT_REQUEST,
            FETCH_APP_DOMAINS_ANALYTICS_EXPORT_SUCCESS,
            FETCH_APP_DOMAINS_ANALYTICS_EXPORT_FAILURE,
        ],
    },
});

export default {
    fetchAdminUsage,
    fetchAdminUsageExport,
};
