import React, {Fragment} from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {PieChart, Pie, Cell} from 'recharts';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Empty from 'components/util/Empty';
import actions from 'store/actions';
import downloadHelper from 'lib/helpers/downloadHelper';
import {LAST_30, getMonthUsage} from 'util/analytics';
import Icon from 'lib/components/Icon';
import Widget from './Widget';

const AdminUsage = ({
    translate,
    appDomains,
    isLoadingAppDomains,
    adminUsage,
    isLoadingAdminUsage,
    fetchAdminUsageExport,
    isFetchingAdminUsageExport,
    showNotification,
    ...otherProps
}) => {
    const data = getMonthUsage(appDomains, adminUsage, LAST_30);

    return (
        <Widget
            {...otherProps}
            title={translate('dashboard.admin_usage')}
            className="admin-usage"
            onArrowClick={() =>
                downloadHelper(fetchAdminUsageExport, showNotification)
            }
            fetchingExport={isFetchingAdminUsageExport}>
            <LoadingWrapper
                loading={isLoadingAppDomains || isLoadingAdminUsage}>
                {data.length > 0 ? (
                    <Fragment>
                        <div className="chart">
                            <PieChart width={174} height={174}>
                                <Pie
                                    data={data}
                                    dataKey="value"
                                    innerRadius={40}
                                    outerRadius={80}
                                    animationDuration={600}
                                    strokeWidth={0}>
                                    {data.map((entry, index) => (
                                        <Cell fill={entry.color} key={index} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </div>
                        <div className="labels">
                            {data.map((domain, index) => (
                                <div
                                    className="chart__label"
                                    style={{color: domain.color}}
                                    key={index}>
                                    <Icon name={domain.icon} size={14} />
                                    <span className="chart__label__text">
                                        {domain.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </Fragment>
                ) : (
                    <Empty title={translate('dashboard.month_no_data')}>
                        {translate('dashboard.click_for_more')}
                    </Empty>
                )}
            </LoadingWrapper>

            <style jsx>
                {`
                    @import '../../lib/sass/kit';

                    :global(.admin-usage) {
                        :global(div.empty) {
                            color: $text-color;
                        }

                        :global(.content) {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            justify-content: space-between;
                        }
                    }

                    .chart {
                        margin: 0 auto 1.5rem;

                        &__label {
                            flex: 0 50%;
                            position: relative;
                            margin-bottom: 1rem;
                            align-items: center;
                            display: flex;
                        }

                        &__label__text {
                            color: $brand-900;
                            font-size: 1.2rem;
                            margin-right: 0.5rem;
                            margin-left: 0.5rem;
                        }
                    }

                    .labels {
                        display: flex;
                        flex-wrap: wrap;

                        :global(& svg) {
                            flex-shrink: 0;
                        }
                    }
                `}
            </style>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    appDomains: state.appDomains.data,
    isLoadingAppDomains: state.appDomains.isLoading,
    adminUsage: state.analytics.appDomains.domains,
    isLoadingAdminUsage: state.analytics.appDomains.isLoading,
    isFetchingAdminUsageExport:
        state.analytics.appDomains.isFetchingAdminUsageExport,
});

const mapDispatchToProps = dispatch => ({
    fetchAdminUsageExport: () =>
        dispatch(actions.analytics.appDomains.fetchAdminUsageExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsage);
