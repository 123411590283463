import React, {Fragment} from 'react';
import {Translate} from 'react-localize-redux';
import ContentView from 'components/util/ContentView';
import List from 'components/List/List';
import AudienceLink from 'components/AudienceLink';
import * as theme from 'styles/theme';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import Icon, {pen} from 'lib/components/Icon';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Button from 'components/util/Button';
import LocaleModal from 'modals/LocaleModal';
import AudienceModal from 'modals/AudienceModal/AudienceModalWithData';
import Empty from 'components/util/Empty';

const Segmentation = ({
    segmentGroups,
    segments,
    selectedSegmentGroupKey,
    changeSegmentGroup,
    editSegment,
    isLoading,
    onSave,
    onDelete,
    onCreate,
}) => {
    const columns = [
        {key: 1, grow: true},
        {
            key: 'audience',
            renderer: (content, data) => (
                <AudienceLink users={content} audienceFilters={data} />
            ),
            nowrap: true,
        },
    ];

    const selectedSegmentGroup = segmentGroups[selectedSegmentGroupKey];

    return (
        <Branding.Consumer>
            {brandColors => (
                <ContentView
                    title={
                        <>
                            <Translate id="global.segmentation" />
                            {selectedSegmentGroup && (
                                <Fragment>
                                    : {selectedSegmentGroup.name}
                                    <ButtonWithoutStyle
                                        onClick={() =>
                                            editSegment(
                                                selectedSegmentGroup,
                                                selectedSegmentGroup.id,
                                            )
                                        }>
                                        <Icon name={pen} size="15" />
                                    </ButtonWithoutStyle>
                                </Fragment>
                            )}
                        </>
                    }
                    total={segments?.length}
                    noPadding
                    headerComponents={
                        <Button onClick={() => onCreate(null)}>
                            <Translate id="segmentation.addGroup" />
                        </Button>
                    }
                    wrapHasOverflow>
                    <LoadingWrapper loading={isLoading}>
                        <div className="segmentation">
                            <ul className="menu">
                                {segmentGroups &&
                                    Object.values(segmentGroups)
                                        .filter(
                                            group =>
                                                group.segment_group_type ===
                                                'editable',
                                        )
                                        .map((group, index) => {
                                            const key =
                                                Object.keys(segmentGroups)[
                                                    index
                                                ];
                                            const isActive =
                                                selectedSegmentGroupKey === key;

                                            return (
                                                <li
                                                    key={key}
                                                    className={`segment ${isActive ? 'is-active' : null}`}
                                                    onClick={() =>
                                                        changeSegmentGroup(key)
                                                    }>
                                                    {group.name}

                                                    <ButtonWithoutStyle
                                                        className="action"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            editSegment(
                                                                segmentGroups[
                                                                    key
                                                                ],
                                                                segmentGroups[
                                                                    key
                                                                ].id,
                                                            );
                                                        }}>
                                                        <Icon
                                                            name={pen}
                                                            size="15"
                                                        />
                                                    </ButtonWithoutStyle>
                                                </li>
                                            );
                                        })}
                            </ul>

                            {selectedSegmentGroup && (
                                <div className="add">
                                    <Button
                                        onClick={() =>
                                            onCreate(
                                                segmentGroups[
                                                    selectedSegmentGroupKey
                                                ].id,
                                            )
                                        }>
                                        <Translate
                                            id="segmentation.addSegmentation"
                                            data={{
                                                segmentation:
                                                    selectedSegmentGroup.name,
                                            }}
                                        />
                                    </Button>
                                </div>
                            )}

                            <div className="segments">
                                {segments && segments.length ? (
                                    <List
                                        columns={columns}
                                        widths={['100%', '20rem']}
                                        rows={segments.map(
                                            (
                                                {
                                                    name,
                                                    employee_count:
                                                        employeeCount = 0,
                                                    audience_filter:
                                                        audienceFilter,
                                                },
                                                key,
                                            ) => ({
                                                key,
                                                data: [
                                                    {content: name},
                                                    {
                                                        content:
                                                            employeeCount || 0,
                                                        data: [audienceFilter],
                                                    },
                                                ],
                                            }),
                                        )}
                                        onRowClick={rowData =>
                                            editSegment(
                                                segments[rowData],
                                                segmentGroups[
                                                    selectedSegmentGroupKey
                                                ].id,
                                                segments[rowData].id,
                                            )
                                        }
                                    />
                                ) : (
                                    <Translate>
                                        {({translate}) => (
                                            <Empty
                                                title={translate(
                                                    'segmentation.noSegments',
                                                )}
                                            />
                                        )}
                                    </Translate>
                                )}
                            </div>
                        </div>
                    </LoadingWrapper>

                    <AudienceModal />
                    <LocaleModal onSave={onSave} onDelete={onDelete} />

                    <style jsx>
                        {`
                            .segmentation {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                            }

                            .header {
                                display: flex;
                            }

                            .header :global(svg) {
                                margin-left: ${theme.spacing}rem;
                            }

                            .menu {
                                overflow: auto;
                                display: flex;
                                min-height: 6rem;
                                padding-bottom: ${theme.spacingLargest}rem;
                            }

                            .segment {
                                position: relative;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                margin-right: ${theme.spacingLargest}rem;
                                color: ${theme.colorTextLight};
                                white-space: nowrap;
                                font-weight: 800;
                                font-size: ${theme.fontSizeLarge}rem;
                            }

                            .segment.is-active {
                                color: ${theme.colorText};
                            }

                            .segment :global(.action) {
                                position: absolute;
                                opacity: 0;
                                right: -2rem;
                            }

                            .segment:hover :global(.action) {
                                opacity: 1;
                            }

                            .segments {
                                flex: 1;
                                display: flex;
                                height: 100%;
                                overflow: hidden;
                            }

                            .add {
                                display: flex;
                                margin-bottom: ${theme.spacingLarger}rem;
                            }

                            .segments :global(.list .content) {
                                flex: auto;
                            }

                            .segments
                                :global(.table-wrap.isList td:first-child) {
                                padding-left: 3rem;
                            }

                            .segments
                                :global(.table-wrap.isList td:last-child) {
                                padding-right: 3rem;
                            }
                        `}
                    </style>
                </ContentView>
            )}
        </Branding.Consumer>
    );
};

export default Segmentation;
