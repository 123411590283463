import React, {useEffect, useState} from 'react';
import Switch from 'components/util/Switch';
import styled from 'styled-components';
import actions from 'store/actions';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'components/util/Select';
import useMountEffect from 'components/hooks/useMountEffect';
import AdminInboxIcon from 'lib/icons/AdminInboxIcon';
import {
    GroupTop,
    Label,
    GroupTitle,
    GroupDescription,
    Row,
    SettingsInputGroup,
    SettingsGroup,
} from './settings.styled';

const AdminSelect = styled(Select)`
    width: 100%;
    ${({isDisabled}) =>
        isDisabled ? 'cursor: not-allowed!important; opacity: 0.5;' : ''};
`;
const AdminInboxConfigSettings = ({onChange}) => {
    const dispatch = useDispatch();
    const [whistleBlower, setWhistleBlower] = useState({enabled: false});
    const [ideaBox, setIdeaBox] = useState({enabled: false});
    const [systemBox, setSystemBox] = useState({});
    const [isEnabled, setIsEnabled] = useState(false);
    const adminInbox = useSelector(
        state => state.company.data?.settings?.admin_inbox,
    );
    const adminOptions = useSelector(({users: {admins}}) =>
        admins?.entries
            ? Object.values(admins.entries).map(user => ({
                  label: `${user.first_name} ${user.last_name}`,
                  value: user.id,
              }))
            : [],
    );
    useEffect(() => {
        if (adminInbox?.enabled) {
            setIsEnabled(adminInbox.enabled);
        }
        if (adminInbox?.forms?.whistle_blower) {
            setWhistleBlower(adminInbox.forms.whistle_blower);
        }
        if (adminInbox?.forms?.idea_box) {
            setIdeaBox(adminInbox.forms.idea_box);
        }
        if (adminInbox?.forms?.system) {
            setSystemBox(adminInbox.forms.system);
        }
    }, [adminInbox]);

    useEffect(() => {
        if (onChange) {
            onChange({
                enabled: isEnabled,
                forms: {
                    whistle_blower: whistleBlower,
                    idea_box: ideaBox,
                    system: systemBox,
                },
            });
        }
    }, [whistleBlower, ideaBox, onChange, isEnabled, systemBox]);

    const fetchManagers = () => dispatch(actions.users.fetchManagers());
    useMountEffect(() => {
        fetchManagers();
    });

    return (
        <SettingsGroup>
            <GroupTop>
                <GroupTitle>
                    <AdminInboxIcon />
                    {/* <Translate id="settings.title.adminInboxConfig" /> */}
                    Admin inbox configuration
                </GroupTitle>
                <GroupDescription>
                    The config for the admin inbox
                    {/* <Translate id="adminInboxConfigDescription" /> */}
                </GroupDescription>
            </GroupTop>
            {isEnabled && (
                <>
                    <SettingsInputGroup>
                        <Row>
                            <Label>
                                Whistle Blower
                                {/* <Translate id="global.whistleBlower"></Translate> */}
                            </Label>
                            <Switch
                                disabled={false}
                                onChange={() => {
                                    setWhistleBlower(wb => ({
                                        ...wb,
                                        enabled: !wb.enabled,
                                    }));
                                }}
                                value={whistleBlower.enabled}
                            />
                        </Row>
                        <Label>Who can see this inbox</Label>
                        <AdminSelect
                            inputId="whistleBlowerManagers"
                            value={whistleBlower?.accessible_for}
                            options={adminOptions}
                            onChange={value =>
                                setWhistleBlower(wb => ({
                                    ...wb,
                                    accessible_for: value
                                        ? value.map(e => e.value)
                                        : null,
                                }))
                            }
                            isMulti
                            isClearable={false}
                            isDisabled={!whistleBlower.enabled}
                        />
                    </SettingsInputGroup>
                    <SettingsInputGroup>
                        <Row>
                            <Label>Idea inbox</Label>
                            <Switch
                                onChange={() => {
                                    setIdeaBox(({enabled, ...r}) => ({
                                        ...r,
                                        enabled: !enabled,
                                    }));
                                }}
                                value={ideaBox.enabled}
                            />
                        </Row>
                        <Label disabled={!ideaBox.enabled}>
                            {/* <Translate id="editor.form.accessibleFor.label" /> */}
                            Who can see this inbox
                        </Label>
                        <AdminSelect
                            inputId="ideaBoxManagers"
                            value={ideaBox?.accessible_for}
                            options={adminOptions}
                            onChange={value =>
                                setIdeaBox(ib => ({
                                    ...ib,
                                    accessible_for: value
                                        ? value.map(e => e.value)
                                        : null,
                                }))
                            }
                            isMulti
                            isClearable={false}
                        />
                    </SettingsInputGroup>
                    <SettingsInputGroup>
                        <Row>
                            <Label htmlFor="systemBoxEnabled">
                                System Messages box
                            </Label>
                            <Switch
                                id="systemBoxEnabled"
                                name="systemBoxEnabled"
                                onChange={() => {
                                    setSystemBox(sb => ({
                                        ...sb,
                                        enabled: !sb.enabled,
                                    }));
                                }}
                                value={systemBox.enabled}
                            />
                        </Row>
                        <Label htmlFor="systemBoxManagers">
                            {/* <Translate id="editor.form.accessibleFor.label" /> */}
                            Who can see this box
                        </Label>
                        <AdminSelect
                            inputId="systemBoxManagers"
                            value={systemBox?.accessible_for}
                            options={adminOptions}
                            onChange={value =>
                                setSystemBox(ib => ({
                                    ...ib,
                                    accessible_for: value
                                        ? value.map(e => e.value)
                                        : null,
                                }))
                            }
                            isMulti
                            isClearable={false}
                        />
                    </SettingsInputGroup>
                </>
            )}
        </SettingsGroup>
    );
};

export default AdminInboxConfigSettings;
