import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {Translate} from 'react-localize-redux';
import * as routes from 'routes/index';
import SurveyWidget from 'components/Widgets/SurveyWidget';
import InfiniteScroll from 'lib/components/InfiniteScroll';
import * as theme from 'styles/theme';

const SurveyList = ({
    surveys,
    onAcceptAnswersChange,
    history,
    onScrollEnd,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetching}
        onFetchMore={onScrollEnd}
        hasMore>
        {Object.keys(surveys).map(date => (
            <Fragment key={date}>
                <h2 className="date">
                    <Translate id="global.date_sent" />: {date}
                </h2>
                {surveys[date].map(message => {
                    const {
                        id,
                        title,
                        survey_stats: surveyStats,
                        accept_answers: acceptAnswers,
                    } = message;

                    return (
                        <div className="row" key={id}>
                            <div className="col">
                                <SurveyWidget
                                    key={id}
                                    title={title}
                                    surveyStats={surveyStats}
                                    active={acceptAnswers}
                                    onChange={checked =>
                                        onAcceptAnswersChange(id, checked)
                                    }
                                    onClick={() =>
                                        history.push(
                                            routes.editorForMessageId(
                                                'messages',
                                                id,
                                            ),
                                        )
                                    }
                                />
                            </div>
                        </div>
                    );
                })}

                <style jsx>
                    {`
                        .date {
                            font-size: ${theme.fontSizeSmall}rem;
                            text-transform: uppercase;
                            font-weight: ${theme.fwBold};
                            margin-bottom: ${theme.spacingSmallest}rem;
                            margin-left: ${theme.spacingSmall}rem;
                        }
                    `}
                </style>
            </Fragment>
        ))}
    </InfiniteScroll>
);

export default withRouter(SurveyList);
