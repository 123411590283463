import {addTranslationForLanguage, initialize} from 'react-localize-redux';
import {
    DE,
    EN,
    ES,
    FR,
    IT,
    NL,
    PL,
    PT,
    TR,
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGES,
} from 'lib/helpers/locale';

// Import languages
import localeDE from 'lib/locale/de/messages';
import localeEN from 'lib/locale/en/messages';
import localeES from 'lib/locale/es/messages';
import localeFR from 'lib/locale/fr/messages';
import localeIT from 'lib/locale/it/messages';
import localeNL from 'lib/locale/nl/messages';
import localePL from 'lib/locale/pl/messages';
import localePT from 'lib/locale/pt/messages';
import localeTR from 'lib/locale/tr/messages';

export default store => {
    // Initialize languages
    store.dispatch(
        initialize({
            languages: SUPPORTED_LANGUAGES,
            options: {
                renderToStaticMarkup: false,
                defaultLanguage: DEFAULT_LANGUAGE,
                renderInnerHtml: false,
                onMissingTranslation: ({defaultTranslation}) =>
                    defaultTranslation,
            },
        }),
    );

    // Add languages to store
    store.dispatch(addTranslationForLanguage(localeDE, DE));
    store.dispatch(addTranslationForLanguage(localeEN, EN));
    store.dispatch(addTranslationForLanguage(localeES, ES));
    store.dispatch(addTranslationForLanguage(localeFR, FR));
    store.dispatch(addTranslationForLanguage(localeIT, IT));
    store.dispatch(addTranslationForLanguage(localeNL, NL));
    store.dispatch(addTranslationForLanguage(localePL, PL));
    store.dispatch(addTranslationForLanguage(localePT, PT));
    store.dispatch(addTranslationForLanguage(localeTR, TR));
};
