import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {MODAL_INPUT} from 'lib/store/actions/modals';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Icon, {play} from 'lib/components/Icon';
import * as colors from 'lib/styles/colors';
import * as theme from 'lib/styles/theme';
import * as media from 'lib/styles/media';
import CustomBlock from '../CustomBlock/CustomBlock';
import {actions} from '../../../../store';

const EmbedBlock = ({embedUrl, interactive, canEdit, removeBlock, block}) => {
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const embeds = useSelector(state => state.embeds.embeds || {});

    useEffect(() => {
        const fetchData = url => {
            setIsFetching(true);
            dispatch(actions.embeds.getEmbed(url)).then(() => {
                setIsFetching(false);
            });
        };
        fetchData(embedUrl);
    }, [embedUrl, dispatch]);

    const onEdit = () => {
        dispatch(actions.modals.setModalVisibility(MODAL_INPUT, true));
        dispatch(
            actions.embedModal.setData({
                content: embedUrl,
                editing: true,
                context: block,
            }),
        );
    };

    const onDelete = () => {
        removeBlock();
    };

    const embed = embeds[embedUrl] || {};
    let embedMapped = {};
    let video = false;

    if (embed.og) {
        embedMapped = {
            url: embedUrl,
            thumbnail: embed.og['og:image'] || '',
            title: embed.og['og:title'] || '',
            description: embed.og['og:description'] || '',
            siteName: embed.og['og:site_name'] || '',
        };

        if (embed.og['og:type']) {
            video = embed.og['og:type'].includes('video');
        }
    }

    return (
        <CustomBlock
            onEdit={onEdit}
            onDelete={onDelete}
            noPadding={!!embedMapped.url}
            disableActions={!interactive || !canEdit}>
            <div className="embed-block">
                <LoadingWrapper loading={isFetching}>
                    {embedMapped.url ? (
                        <a
                            className="embed-block__link"
                            href={embedMapped.url || '#'}
                            target="_blank"
                            rel="noopener noreferrer">
                            {embedMapped.thumbnail && (
                                <div
                                    className="embed-block__thumb"
                                    style={{
                                        backgroundImage: `url(${embedMapped.thumbnail})`,
                                    }}>
                                    {video && (
                                        <div className="embed-block__thumb__play">
                                            <Icon name={play} />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="embed-block__content">
                                {embedMapped.title && (
                                    <h2 className="embed-block__title">
                                        {embedMapped.title}
                                    </h2>
                                )}
                                {embedMapped.description && (
                                    <p className="embed-block__description">
                                        {embedMapped.description}
                                    </p>
                                )}
                                {embedMapped.siteName && (
                                    <span className="embed-block__site-name">
                                        {embedMapped.siteName}
                                    </span>
                                )}
                            </div>
                        </a>
                    ) : (
                        <a
                            className="embed-block__link --no-data"
                            href={embedUrl}
                            target="_blank"
                            rel="noopener noreferrer">
                            {embedUrl}
                        </a>
                    )}
                </LoadingWrapper>
            </div>

            <style jsx>
                {`
                    .embed-block {
                        min-width: 25rem;

                        &__link {
                            display: flex;
                            flex-direction: column;
                            min-height: 12rem;
                            color: ${colors.text.secondary};
                            &:hover,
                            &:focus {
                                color: inherit;
                                text-decoration: none;
                            }

                            &.--no-data {
                                min-height: 1rem;
                            }
                        }

                        &__thumb {
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            width: 100%;
                            height: 20rem;

                            &,
                            &__play {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            &__play {
                                color: ${colors.pureWhite};
                                width: 4rem;
                                height: 4rem;
                                border-radius: 50%;
                                background-color: ${colors.grey[400]};
                            }
                        }

                        &__content {
                            flex: 1;
                            padding: 1.5rem 1.5rem 1rem;
                            margin-right: 4rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }

                        &__title {
                            font-weight: ${theme.fwBold};
                            font-size: 1.6rem;
                            margin-bottom: 0.4rem;
                        }

                        &__description {
                            font-size: 1.4rem;
                            margin-bottom: 2.1rem;
                        }

                        &__site-name {
                            text-transform: uppercase;
                            font-size: 1.2rem;
                            line-height: 1;
                            color: ${colors.grey[400]};
                        }

                        @media (${media.forSmallAndUp}) {
                            min-width: 50rem;

                            &__link {
                                flex-direction: row;
                            }

                            &__thumb {
                                width: 20rem;
                                height: auto;
                            }
                        }
                    }
                `}
            </style>
        </CustomBlock>
    );
};

EmbedBlock.propTypes = {
    embedUrl: PropTypes.string.isRequired,
    interactive: PropTypes.bool,
    canEdit: PropTypes.bool,
    removeBlock: PropTypes.func.isRequired,
    block: PropTypes.shape({}).isRequired,
};

EmbedBlock.defaultProps = {
    interactive: false,
    canEdit: false,
};

export default EmbedBlock;
