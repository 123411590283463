/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// TODO: THIS IS A HUGE IMPORT!! REFACTOR TO IMPORT ONLY WHAT'S ABSOlUTELY NECESSARY!
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'isomorphic-fetch';
import 'dom4'; // Polyfill for new DOM Level 4 entries
import React from 'react';
import ReactDOM from 'react-dom';
import initializeSentry from 'lib/helpers/initSentry';
import App from 'components/app/App';
import {store} from 'store';
import capitalize from 'lodash/capitalize';
import './styles/main.css'; // TODO: get rid of all rendered css
import ReduxProviders from 'components/app/ReduxProviders';

initializeSentry();

const environment = process.env.REACT_APP_ENV;

if (environment !== 'production') {
    document.title = `${capitalize(environment)} - ${document.title}`;

    document.querySelectorAll("link[rel*='icon']").forEach(e => e.remove());
    const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `/favicon-${environment}.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);
}

const rootEl = document.getElementById('root');

function renderRogerApp() {
    ReactDOM.render(
        React.createElement(
            () => (
                <ReduxProviders store={store}>
                    <App />
                </ReduxProviders>
            ),
            null,
            null,
        ),
        rootEl,
    );
}

function clearConsole() {
    if (typeof console._commandLineAPI !== 'undefined') {
        console.API = console._commandLineAPI; // chrome
    } else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
        console.API = console._inspectorCommandLineAPI; // Safari
    } else if (typeof console.clear !== 'undefined') {
        console.API = console;
    }
    console.API.clear();
}

// Render map initially
renderRogerApp();

// Hot Module Reloading with store
if (module.hot) {
    module.hot.accept('components/app/App', () => {
        // Clear console and reload app
        clearConsole();
        renderRogerApp();
    });
}

if (process.env.REACT_APP_ENV === 'local') {
    setInterval(() => {
        const bodyChildren = document.body.children;
        const lastChild = bodyChildren[bodyChildren.length - 1];
        if (lastChild && lastChild.tagName === 'IFRAME') {
            lastChild.remove();
        }
    }, 2000);
}
