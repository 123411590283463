import styled from 'styled-components';

export const AdminInboxTextCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    > :last-child {
        font-weight: lighter;
    }
`;
export const TextCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    small {
        font-weight: lighter;
    }
`;
export const AnonymousSpan = styled('span')`
    color: ${({theme}) => theme.colorTextGrey};
    opacity: 0.5;
`;
export const ModalTitle = styled.span`
    font-weight: bold;
    font-size: 2rem;
`;

export const ModalBody = styled.p`
    font-size: 1.75rem;
    white-space: pre-wrap;
`;

export const ModalContentWrapper = styled.div`
    width: 65.5rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    min-height: 40rem;
`;

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    transform: scale(1.02);
    overflow: hidden;
    > div {
        transform: scale(0.98);
    }
`;

export const UserDetailRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailSmall = styled.small`
    font-weight: lighter;
`;
