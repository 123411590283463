import React from 'react';

export default function () {
    return (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.076 4.048c-.172.035-.44.228-.595.43-.256.332-.283.575-.283 2.575 0 1.955.026 2.198.222 2.121.121-.047.5-.086.84-.086h.618V5.662H22.39v10.705h-9.316l-.73.754c-.401.415-.73.8-.73.856 0 .057 2.61.103 5.8.103h5.8l.392-.345.393-.344V4.671l-.319-.325-.318-.325-8.988-.018c-4.943-.01-9.128.01-9.3.045Zm-.738 6.334c-1.685.723-1.901 3.034-.39 4.176.586.443 1.723.474 2.42.066.63-.369 1.19-1.331 1.186-2.034-.005-.651-.467-1.562-.983-1.936-.517-.377-1.664-.516-2.233-.272Zm6.228 3.611-2.24 2.344-.605-.27c-.796-.353-2.356-.535-3.375-.393-1.61.225-3.472 1.186-3.766 1.945-.172.444-.58 2.23-.58 2.54 0 .173.62.205 4.038.205h4.038l1.554-1.82c.854-1.001 2.249-2.567 3.099-3.48 1.805-1.937 1.96-2.293 1.292-2.973-.791-.807-.953-.718-3.455 1.902Z"
            fill="#000"
        />
    );
}
