import React from 'react';
import classNames from 'classnames';
import * as colors from 'lib/styles/colors';
import * as theme from 'styles/theme';

const Landing = ({children}) => {
    const hasTheme = false; // TODO: get theme

    return (
        <div className={classNames('landing', {hasTheme})}>
            <div className="content">
                <div className="left">{children}</div>
                {hasTheme && <div className="right" />}
            </div>

            <style jsx>
                {`
                    .landing {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        background-color: ${colors.background};
                        padding: ${theme.spacing}rem;
                    }

                    .content {
                        max-width: ${hasTheme ? '102.4' : '60'}rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: ${`${theme.spacingLargest}rem ${theme.spacingLarger}rem`};
                        background-color: ${colors.pureWhite};
                        box-shadow:
                            0px 0px 100px rgba(0, 0, 0, 0.1),
                            0px 45px 50px rgba(0, 0, 0, 0.25);
                    }

                    .hasTheme .left,
                    .hasTheme .right {
                        width: 50%;
                    }
                `}
            </style>
        </div>
    );
};

export default Landing;
