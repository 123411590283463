import * as React from 'react';

const SupportMailIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Laag_1"
        data-name="Laag 1"
        viewBox="0 0 370.09 332"
        {...props}>
        <defs>
            <clipPath id="clippath">
                <path
                    d="M62.82 58.17h171.44v171.44H62.82z"
                    style={{
                        fill: 'none',
                        strokeWidth: 0,
                    }}
                />
            </clipPath>
            <style>{'.cls-4{stroke-width:0;fill:#fff}'}</style>
        </defs>
        <ellipse
            cx={149.05}
            cy={150.28}
            rx={149.05}
            ry={150.28}
            style={{
                fill: '#2f68b1',
                strokeWidth: 0,
            }}
        />
        <path
            d="M110.58 130.82c-1.74 3.35-.49 7.39 2.85 9.12 3.35 1.74 7.38.5 9.12-2.85 1.74-3.35.49-7.38-2.85-9.12-3.37-1.62-7.41-.38-9.12 2.85Zm35.66-22.49c-1.74 3.35-.5 7.38 2.85 9.12 3.35 1.74 7.38.49 9.12-2.85 1.74-3.35.49-7.39-2.85-9.12-3.35-1.74-7.41-.38-9.12 2.85Zm35.55 55.98c8.93-15.11 6.43-33.59-4.42-45.77-2.68-3.04-7.65-2.49-9.58 1.17-1.1 2.16-.86 4.76.78 6.59 7.76 8.81 9.4 22.13 2.7 32.8-7.28 11.63-22.43 15.99-34.74 10.12-6.43-3.06-11.06-8.12-13.54-14.13-.93-2.27-3.15-3.63-5.58-3.59-4.14.13-6.81 4.36-5.14 8.13 3.58 8.57 10.34 15.94 19.51 20.23 17.96 8.62 39.86 1.7 50.01-15.55Zm-68.96-56.32c2.02-1.95 2.48-4.99.98-7.39-1.94-3.11-6.41-3.66-9.05-1.12-12.47 12.12-19.93 29.27-19.08 48.09 1.61 32.3 27.99 58.35 60.39 59.42 3.83.16 7.51-.08 11.14-.57 3.39-.55 5.61-3.9 4.81-7.23-.77-2.96-3.6-4.82-6.48-4.4-1.77.32-3.62.5-5.56.53-28.04 1.03-51.61-20.99-52.51-49.02-.64-15.02 5.29-28.65 15.37-38.3Zm35.88-26.06c-7.83-.11-15.29 1.31-22.16 3.83-3.43 1.26-4.88 5.41-2.96 8.63 1.5 2.4 4.43 3.32 7.07 2.36 5.56-2.1 11.72-3.1 18-3.1 25.92.3 47.75 20.46 50.01 46.26 1.05 12.22-2.23 23.8-8.63 33.2-1.64 2.4-1.33 5.74 1.02 7.61l.32.2c2.53 2.16 6.52 1.62 8.35-1.1 7.8-11.26 11.89-25.32 10.82-40.08-2.46-32.26-29.52-57.5-61.85-57.82Zm29.6 117.38c3.77-.1 6.69-3.3 6.45-6.98-.1-3.77-3.29-6.69-6.98-6.45-3.77.1-6.68 3.29-6.44 6.98.12 3.66 3.32 6.57 6.97 6.45Zm30.23 6.25c34.81-34.54 33.68-90.92-1.83-124.04-8.17-7.57-17.36-13.32-27.23-17.17-3.34-1.25-7.04.67-7.84 4.12-.66 2.87.85 5.76 3.64 6.77 8.43 3.28 16.47 8.28 23.53 14.87 30.63 28.6 31.59 77.33 1.61 107.17-27.6 27.38-72.15 28.7-101.3 3.1-18.29-16.08-26.74-39.19-24.91-61.75.18-2.86-1.61-5.57-4.48-6.24-3.56-.82-6.91 1.67-7.16 5.36-2.02 26.02 7.69 52.58 28.77 71.23 33.48 29.87 85.21 28.38 117.21-3.42Zm-59.11-145.6c-2.55 2.68-2.33 6.96.34 9.51 2.68 2.55 6.96 2.33 9.51-.35s2.33-6.96-.34-9.51-6.96-2.33-9.51.34Zm-71.45 50.27c2.68 2.55 2.89 6.84.34 9.51-2.55 2.68-6.83 2.9-9.51.34-2.67-2.55-2.89-6.84-.34-9.51 2.55-2.68 6.81-2.78 9.51-.35Z"
            className="cls-4"
            style={{
                clipPath: 'url(#clippath)',
            }}
        />
        <circle
            cx={293.72}
            cy={255.63}
            r={76.37}
            style={{
                strokeWidth: 0,
                fill: '#414142',
            }}
        />
        <g id="LckT8c">
            <path
                d="M257.73 239.05c7.07 2.91 27.7 15.78 32.53 18.27 3.41 1.76 6.61 1.22 9.75-.71 8.68-5.32 28.63-18.09 30.21-17.06 1.29.84.66 25.49.81 36.3.06 4-1.47 5.45-5.47 5.43-20.77-.12-41.54-.09-62.31-.01-3.51.01-5.57-.97-5.55-4.89.07-12.18.03-24.36.03-37.32Z"
                className="cls-4"
            />
            <path
                d="M294.64 227.07h30.84c2.22 0 4.72-.29 5.32 2.71.51 2.53-1.38 3.62-3.22 4.67-9.8 5.63-19.63 11.2-29.35 16.96-2.7 1.6-4.84 1.56-7.55-.05-9.71-5.78-19.58-11.28-29.34-16.99-1.79-1.05-4.37-1.86-3.55-4.83.8-2.88 3.31-2.47 5.48-2.48 10.45-.02 20.91-.01 31.36-.01Z"
                className="cls-4"
            />
        </g>
    </svg>
);
export default SupportMailIcon;
