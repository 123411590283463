import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';

import {
    LIST_INTERNAL_MEDIA_REQUEST,
    LIST_INTERNAL_MEDIA_SUCCESS,
    LIST_INTERNAL_MEDIA_FAILURE,
    VIEW_INTERNAL_MEDIA_REQUEST,
    VIEW_INTERNAL_MEDIA_SUCCESS,
    VIEW_INTERNAL_MEDIA_FAILURE,
    DELETE_INTERNAL_MEDIA_REQUEST,
    DELETE_INTERNAL_MEDIA_SUCCESS,
    DELETE_INTERNAL_MEDIA_FAILURE,
} from '../../actions/media/internal';

const internal = combineReducers({
    byId: createById({
        listActions: [
            LIST_INTERNAL_MEDIA_REQUEST,
            LIST_INTERNAL_MEDIA_SUCCESS,
            LIST_INTERNAL_MEDIA_FAILURE,
        ],
        viewActions: [
            VIEW_INTERNAL_MEDIA_REQUEST,
            VIEW_INTERNAL_MEDIA_SUCCESS,
            VIEW_INTERNAL_MEDIA_FAILURE,
        ],
        deleteActions: [
            DELETE_INTERNAL_MEDIA_REQUEST,
            DELETE_INTERNAL_MEDIA_SUCCESS,
            DELETE_INTERNAL_MEDIA_FAILURE,
        ],
    }),
    paginated: paginate({
        listActions: [
            LIST_INTERNAL_MEDIA_REQUEST,
            LIST_INTERNAL_MEDIA_SUCCESS,
            LIST_INTERNAL_MEDIA_FAILURE,
        ],
        deleteActions: [
            DELETE_INTERNAL_MEDIA_REQUEST,
            DELETE_INTERNAL_MEDIA_SUCCESS,
            DELETE_INTERNAL_MEDIA_FAILURE,
        ],
        requestFilterKey: 'filters',
    }),
});

export default internal;
