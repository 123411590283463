import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M11.4206 0.0506612C8.22738 0.540292 6.01339 3.82933 6.80106 6.91614C7.461 9.50267 9.65369 11.227 12.2828 11.227C14.9119 11.227 17.1046 9.50267 17.7645 6.91614C18.318 4.74473 17.3601 2.28593 15.4441 0.966058C14.3691 0.220968 12.7405 -0.140934 11.4206 0.0506612Z" />
            <path d="M10.5265 12.7811C8.42962 13.1004 6.46045 13.6965 4.77867 14.5161C2.62856 15.5698 1.56414 16.4214 1.21288 17.3793C1.02129 17.8903 1 18.2096 1 20.9664V24H23.5656V20.9664C23.5656 18.1883 23.5443 17.8903 23.3527 17.3687C22.7354 15.7295 19.6805 14.0158 15.6889 13.0685C14.7735 12.8556 14.2413 12.8024 12.7086 12.7704C11.6867 12.7491 10.7075 12.7598 10.5265 12.7811Z" />
        </g>
    );
}
