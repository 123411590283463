import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import Icon from 'lib/components/Icon';
import {getFileType} from 'lib/helpers/fileTypeHelper';
import getUrlBySize, {REGULAR, FULL} from 'lib/helpers/sizeHelper';
import * as colors from 'lib/styles/colors';
import * as theme from 'lib/styles/theme';
import capitalize from 'lodash/capitalize';

const FileBlock = ({file, interactive, translate}) => {
    const type = getFileType(file.mime_type);
    const icon = !type ? 'file' : `file${capitalize(type)}`;

    const block = (
        <Fragment>
            <div
                className="image"
                style={{backgroundImage: `url(${getUrlBySize(file, REGULAR)})`}}
            />
            <div className="content">
                <div className="icon-content">
                    <Icon className="icon" name={icon} />
                </div>
                <div className="text-content">
                    <span className="text">
                        {file.name ?? translate('editor.click_download')}
                    </span>
                    <span className="text-type">{capitalize(type)}</span>
                </div>
            </div>
            <style jsx>
                {`
                    .image {
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        position: absolute;
                        opacity: 0.6;
                        filter: blur(0.3rem);

                        width: 100%;
                        width: calc(100% + #{0.6rem});
                        height: 100%;
                        height: calc(100% + #{0.6rem});
                        top: 0;
                        top: calc(-#{0.3rem});
                        left: 0;
                        left: calc(-#{0.3rem});
                    }

                    .content {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        position: relative;
                        z-index: 1;
                        padding: 0 2rem;
                        color: ${colors.text.secondary};
                        user-select: none;
                    }

                    .text-content {
                        width: 100%;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                    }

                    .icon-content {
                        width: 15%;
                        display: block;
                    }

                    .text {
                        color: ${colors.text.secondary};
                        font-size: 2.4rem;
                        line-height: 1.2;
                        margin-top: 1.5rem;
                    }
                `}
            </style>
        </Fragment>
    );

    return (
        <div>
            {interactive ? (
                <a
                    href={getUrlBySize(file, FULL)}
                    target="_blank"
                    className="file-block"
                    rel="noopener noreferrer">
                    {block}
                </a>
            ) : (
                <div className="file-block">{block}</div>
            )}

            <style jsx>
                {`
                    .file-block {
                        background-color: ${colors.pureWhite};
                        box-shadow: ${theme.defaultShadow};
                        width: 100%;
                        min-height: 25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 0;
                        overflow: hidden;

                        &,
                        &:hover {
                            text-decoration: none;
                            color: ${colors.text.light};
                        }
                    }
                `}
            </style>
        </div>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(FileBlock);
