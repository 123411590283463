import React, {useRef} from 'react';
import classNames from 'classnames';
import * as theme from 'styles/theme';
import hexToRgb from 'util/color';
import CloseButton from 'lib/components/CloseButton';
import SimpleTabMenu from 'lib/components/SimpleTabMenuDeprecated';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import PropTypes from 'prop-types';
import ModalHeader from './ModalHeader';
import ModalActions from './ModalActions';
import useOnClickOutside from '../../components/hooks/useOnClickOutside';

const Modal = ({
    children,
    visible = false,
    loading = false,
    big,
    medium,
    title,
    icon,
    cancelable,
    buttonActions,
    noPadding,
    onClose,
    menu,
    activeTab,
    onTabSwitch,
    flex,
}) => {
    const clickOutsideRef = useRef();
    useOnClickOutside(clickOutsideRef, onClose);

    if (!visible) {
        return null;
    }

    const activeItem = menu && menu.find(item => item.key === activeTab);
    const tab = activeItem ? activeItem.key : activeTab;

    return (
        <Branding.Consumer>
            {colors => (
                <div
                    className={classNames(
                        'modal',
                        {hidden: !visible},
                        {big},
                        {noPadding},
                        {medium},
                        {hasMenu: menu},
                    )}>
                    <div className="wrapper">
                        <div ref={clickOutsideRef} className="contentWrapper">
                            <CloseButton className="close" onClick={onClose} />
                            {menu && (
                                <div className="menu">
                                    <SimpleTabMenu
                                        activeTab={tab}
                                        onChange={newActiveTab =>
                                            onTabSwitch(newActiveTab)
                                        }
                                        menu={menu}
                                    />
                                </div>
                            )}
                            {!menu && title && (
                                <ModalHeader title={title} icon={icon} />
                            )}
                            <div className="content">
                                {children}
                                <ModalActions
                                    cancelable={cancelable}
                                    loading={loading}
                                    onClose={onClose}
                                    buttonActions={buttonActions}
                                />
                            </div>
                        </div>
                    </div>

                    <style jsx>
                        {`
                            @import '../../lib/sass/kit';

                            .modal {
                                position: fixed;
                                display: block;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: ${theme.zModal};
                                overflow: auto;
                                overscroll-behavior: contain;
                                color: ${colors.text.secondary};
                                background-color: ${`rgba(${hexToRgb(colors.grey[900])}, 0.76)`};

                                :global(.close) {
                                    top: ${menu ? '1.8rem' : '1.2rem'};
                                    right: 2rem;
                                    position: absolute;
                                    z-index: ${theme.zClose};

                                    &:focus {
                                        outline: none;
                                    }
                                }

                                :global(.table-wrap) {
                                    border-radius: 0;
                                }
                            }

                            .wrapper {
                                ${(flex &&
                                    `max-height: 100vh;
                                height: 100%;
                                overflow: hidden;`) ||
                                ''}
                                padding: $modal-vertical-padding
                                    $modal-horizontal-padding;
                                width: 100%;
                                pointer-events: none;
                            }

                            .contentWrapper {
                                ${(flex &&
                                    `height: fit-content;
                                max-height: 100%;
                                overflow: hidden;`) ||
                                ''}
                                margin: 0 auto;
                                min-width: 60rem;
                                width: fit-content;
                                max-width: 90vw;
                                border-radius: 0.4rem;
                                position: relative;
                                pointer-events: all;
                                display: flex;
                                flex-direction: column;
                            }

                            .content {
                                padding: 2.5rem 2rem 2rem;
                                flex: 1;
                                flex-basis: auto;
                                background-color: $pure-white;
                                border-radius: ${title || menu
                                    ? '0 0 1rem 1rem'
                                    : '1rem'};
                                ${(flex &&
                                    `height: 100%;
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                                & > *:first-child {
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                }
                                
                                `) ||
                                ''}
                            }

                            .scrollBody {
                                overflow: auto;
                            }

                            .footer {
                                height: fit-content;
                            }

                            .modal :global(.header),
                            .menu {
                                border-radius: 1rem 1rem 0 0;
                                height: 6rem;
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;

                                :global(.tab:after) {
                                    background-color: ${colors.grey[100]};
                                    height: 0.4rem;
                                }
                            }

                            .menu {
                                background-color: ${colors.brand[900]};
                                padding-left: ${theme.spacing}rem;
                            }

                            .big {
                                color: ${colors.text.base};

                                .contentWrapper {
                                    max-width: 117rem;
                                }

                                .content {
                                    padding: 1.5rem;
                                }

                                @include media-breakpoint-up(md) {
                                    .content {
                                        padding: 3rem;
                                    }
                                }
                            }

                            .noPadding {
                                .content {
                                    padding: 0;
                                }
                            }

                            .medium {
                                .contentWrapper {
                                    max-width: 88.3rem;
                                }

                                .content {
                                    background-color: ${colors.grey[100]};
                                }
                            }

                            .hasMenu :global(.close) {
                                color: ${colors.text.light};
                            }

                            @include media-breakpoint-up(md) {
                                .wrapper {
                                    padding: $modal-vertical-padding-desktop
                                        $modal-horizontal-padding-desktop;
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};
Modal.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    big: PropTypes.bool,
    medium: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    cancelable: PropTypes.bool,
    buttonActions: PropTypes.arrayOf(PropTypes.any),
    noPadding: PropTypes.bool,
    onClose: PropTypes.func,
    menu: PropTypes.arrayOf(PropTypes.any),
    activeTab: PropTypes.number,
    onTabSwitch: PropTypes.func,
    flex: PropTypes.bool,
};

Modal.defaultProps = {
    children: null,
    visible: false,
    loading: false,
    big: false,
    medium: false,
    title: '',
    icon: '',
    cancelable: true,
    buttonActions: [],
    noPadding: false,
    onClose: () => {},
    menu: undefined,
    activeTab: 0,
    onTabSwitch: () => {},
    flex: false,
};

export default Modal;
