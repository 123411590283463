import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import actions from 'store/actions';
import Modal from './Modal';

const ModalWithState = ({children, onClose, name, menu, ...props}) => {
    const dispatch = useDispatch();
    const visible = useSelector(state => state.modals.visibility[name]);
    const loading = useSelector(state => state.modals.loading[name]);
    const translate = useSelector(state => getTranslate(state.localize));

    const setModalVisibility = (modal, visibility) => {
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    };
    const setModalLoading = (modal, newLoading) => {
        dispatch(actions.modals.setModalLoading(modal, newLoading));
    };
    const setActiveSidebar = sidebar => {
        dispatch(actions.editor.setActiveSidebar(sidebar));
    };

    const [activeTab, setActiveTab] = useState((menu && menu[0].key) || 0);
    const closeModal = () => {
        setModalVisibility(name, false);
        if (onClose) {
            onClose();
        }
    };

    if (!visible) {
        return null;
    }

    return (
        <Modal
            visible={visible}
            loading={loading}
            onClose={closeModal}
            activeTab={activeTab}
            onTabSwitch={setActiveTab}
            menu={menu}
            setModalLoading={setModalLoading}
            setActiveSidebar={setActiveSidebar}
            translate={translate}
            {...props}>
            {typeof children === 'function' ? children({activeTab}) : children}
        </Modal>
    );
};

ModalWithState.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    onClose: PropTypes.func,
    name: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.any),
};
ModalWithState.defaultProps = {
    onClose: null,
    name: '',
    menu: undefined,
};
export default ModalWithState;
