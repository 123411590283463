import React from 'react';
import * as theme from '../styles/theme';
import ScrollSentinel from './ScrollSentinel';
import LoadingWrapper from './LoadingWrapper';

const InfiniteScroll = ({
    isFetchingMore,
    hasMore,
    onFetchMore,
    children,
    hasLoader = true,
    className,
}) => (
    <div className={`scrollWrapper ${className}` || ''}>
        {children}
        {hasLoader && isFetchingMore && (
            <div className="loading">
                <LoadingWrapper loading size={8} />
            </div>
        )}
        {!isFetchingMore && hasMore && (
            <ScrollSentinel onIntersect={onFetchMore} />
        )}
        <style jsx>
            {`
                .scrollWrapper {
                    position: relative;
                }

                .loading {
                    margin: ${theme.spacing}rem auto;
                }
            `}
        </style>
    </div>
);

export default InfiniteScroll;
