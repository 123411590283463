import {
    APP_DOMAINS_LIST_REQUEST,
    APP_DOMAINS_LIST_SUCCESS,
    APP_DOMAINS_LIST_FAILURE,
} from '../../actions/appDomains';

const INITIAL_STATE = {
    data: [],
    isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case APP_DOMAINS_LIST_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case APP_DOMAINS_LIST_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data,
                isLoading: false,
            });
        case APP_DOMAINS_LIST_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
            });
        default:
            return state;
    }
};
