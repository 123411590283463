import moment from 'moment';
import {DATE_TIME_FORMAT} from 'util/constants';
import computeRRuleToString from './computeRRule/toString/computeRRule';

const configureState = (config = {}, calendarComponent) => {
    const configureFrequency = () =>
        config.repeat ? config.repeat[0] : 'Yearly';
    const configureYearly = () => config.yearly || 'on';
    const configureMonthly = () => config.monthly || 'on';
    const configureEnd = () => (config.end ? config.end[0] : 'Never');

    const data = {
        repeat: {
            frequency: configureFrequency(),
            yearly: {
                mode: configureYearly(),
                on: {
                    month: 'jan',
                    day: 1,
                },
                onThe: {
                    month: 'jan',
                    day: 'monday',
                    which: 'first',
                },
                options: {
                    modes: config.yearly,
                },
            },
            monthly: {
                mode: configureMonthly(),
                interval: 1,
                on: {
                    day: 1,
                },
                onThe: {
                    day: 'monday',
                    which: 'first',
                },
                options: {
                    modes: config.monthly,
                },
            },
            weekly: {
                interval: 1,
                days: {
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: false,
                },
                options: {
                    weekStartsOnSunday: config.weekStartsOnSunday,
                },
            },
            daily: {
                interval: 1,
            },
            hourly: {
                interval: 1,
            },
            options: {
                frequency: config.repeat,
            },
        },
        end: {
            mode: configureEnd(),
            after: 1,
            onDate: {
                date: moment().format(DATE_TIME_FORMAT),
                options: {
                    weekStartsOnSunday: config.weekStartsOnSunday,
                    calendarComponent,
                },
            },
            options: {
                modes: config.end,
            },
        },
        options: {
            hideEnd: config.hideEnd,
            weekStartsOnSunday: config.weekStartsOnSunday,
        },
        error: null,
    };

    return {
        data,
        rrule: computeRRuleToString(data),
    };
};

export default configureState;
