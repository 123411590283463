import React from 'react';
import {connect} from 'react-redux';
import UsersLeaderboard from './UsersLeaderboard';

const UsersLeaderboardWithRedux = ({userActivityDetail}) => (
    <UsersLeaderboard userActivityDetail={userActivityDetail} />
);

const mapStateToProps = state => ({
    userActivityDetail: state.analytics.userActivity.activityDetail,
});

export default connect(mapStateToProps)(UsersLeaderboardWithRedux);
