/* eslint-disable camelcase */
import {getById} from 'lib/store/reducers/helpers/byId';
import {CONTENT_TYPE_TEMPLATES} from 'lib/store/actions/editor';
import {store} from 'store';
import actions from 'store/actions';
import {VISIBILITY_PRIVATE} from 'lib/store/actions/messages/index';
import {isValidMessage} from './messages';
import {editorBodyToRaw} from './editorBody';
import {mapAudienceFilters} from '../lib/store/reducers/editor';

export const getNewMessageFromTemplate = (
    {
        accept_answers,
        app_domain,
        audience_filters,
        body,
        enable_comments,
        title,
        available_languages,
    },
    scheduled_at,
) => {
    const newMessage = {
        accept_answers,
        app_domain: app_domain.slug,
        audience_filters: mapAudienceFilters(audience_filters),
        body,
        enable_comments,
        send_reminders: false,
        title,
        visibility: VISIBILITY_PRIVATE,
        available_languages,
    };
    if (scheduled_at !== null) {
        newMessage.scheduled_at = scheduled_at;
    }
    return newMessage;
};

export const buildTemplateFromCurrentEditorState = editor => {
    const {
        contentId,
        contentType,
        title,
        body,
        appDomain,
        audienceFilters,
        enableComments,
        acceptAnswers,
        availableLanguages,
    } = editor;

    const template = {
        id: contentType === CONTENT_TYPE_TEMPLATES ? contentId : null,
        title,
        app_domain: appDomain.slug,
        audience_filters: mapAudienceFilters(audienceFilters),
        body: editorBodyToRaw(body),
        enable_comments: enableComments,
        accept_answers: acceptAnswers,
        available_languages: availableLanguages,
    };

    return template;
};

export const saveTemplate = async (editor, dispatch) => {
    const template = buildTemplateFromCurrentEditorState(editor);
    const isValidMessageToSave = isValidMessage(template);
    if (isValidMessageToSave) {
        let payload = null;
        if (!template.id) {
            ({payload} = await dispatch(
                actions.templates.createTemplate(template),
            ));
        } else {
            ({payload} = await dispatch(
                actions.templates.updateTemplate(template),
            ));
        }

        if (payload.ids && payload.ids.length) {
            const templateId = payload.ids;
            template.id = templateId;
            dispatch(
                actions.editor.loadTemplateIntoEditor(payload.data[templateId]),
            );
        }
    }

    return template;
};

const getTemplateFromStore = templateId => {
    const {byId} = store.getState().templates;
    return getById(byId, templateId);
};

export const prepareTemplateForEditor = async (templateId, dispatch) => {
    let template = getTemplateFromStore(templateId);
    if (!template) {
        const {payload} = await dispatch(
            actions.templates.fetchTemplate(templateId),
        );
        template = payload.data[templateId];
    }
    return getTemplateFromStore(templateId);
};
