import React, {Fragment} from 'react';
import rogerEditor from 'lib/styles/editor/rogerEditor';
import mediumDraft from 'lib/styles/editor/mediumDraft';
import actions from './global/actions';
import reset from './global/reset';
import select from './global/select';
import Forms from './global/forms';
import Global from './global/index';
import Helpers from './global/helpers';

const GlobalStyles = () => (
    <Fragment>
        <style jsx global>
            {rogerEditor}
        </style>
        <style jsx global>
            {mediumDraft}
        </style>
        <style jsx global>
            {actions}
        </style>
        <style jsx global>
            {reset}
        </style>
        <style jsx global>
            {select}
        </style>
        <Global />
        <Forms />
        <Helpers />
    </Fragment>
);

export default GlobalStyles;
