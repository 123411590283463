import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import actions from 'store/actions';
import AudienceResult from './AudienceResult';

const debouncedFetch = debounce(fetchData => fetchData(), 300);

const AudienceResultWithData = ({
    audienceFilters = [],
    calculateAudience,
    showFailure,
    parentId,
}) => {
    const [audience, setAudience] = useState(audienceFilters);
    const [isCalculatingAudience, setIsCalculatingAudience] = useState(true);

    const fetchData = async () => {
        setIsCalculatingAudience(true);

        try {
            const mappedFilters = audienceFilters.map(filter => {
                if (filter.filter === 'users') {
                    return {
                        ...filter,
                        value: filter.value.map(value => value.id || value),
                    };
                }
                return filter;
            });

            const {
                payload: {data},
            } = await calculateAudience(mappedFilters);
            setAudience(data);
        } catch ({message}) {
            if (message) showFailure(message);
        }
        setIsCalculatingAudience(false);
    };

    useEffect(() => {
        debouncedFetch(fetchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(audienceFilters), parentId]);

    return (
        <AudienceResult
            audience={audience}
            isCalculatingAudience={isCalculatingAudience}
        />
    );
};

const mapDispatchToProps = (dispatch, {parentId}) => ({
    calculateAudience: filters =>
        dispatch(actions.audience.calculateAudience(filters, parentId)),
    showFailure: text => dispatch(actions.notifications.showFailure(text)),
});

export default connect(null, mapDispatchToProps)(AudienceResultWithData);
