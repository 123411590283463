import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import actions from 'store/actions';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import Icon from 'lib/components/Icon';
import EditorSidebarAppDomainSelect from './EditorSidebarAppDomainSelect';

const getAppDomainOptions = appDomains =>
    appDomains.map(appDomain => ({
        label: appDomain.name,
        value: appDomain.slug,
        icon: <Icon name={appDomain.icon} />,
    }));

const EditorSidebarAppDomainSelectWithState = ({canEdit}) => {
    const location = useLocation();
    const {translate} = useTranslate();
    const {saveContent} = useContentSaver();
    const {editor} = useEditorState();
    const appDomains = useSelector(({appDomains: {data}}) => data);

    const dispatch = useDispatch();
    const updateAppDomain = appDomain =>
        dispatch(actions.editor.setAppDomain(appDomain));

    const onAppDomainChange = async option => {
        let newAppDomain = {};
        if (option) {
            newAppDomain = {
                slug: option,
            };
        }

        updateAppDomain(newAppDomain);
        await saveContent({...editor, appDomain: newAppDomain});
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search.slice(1));
        if (query.has('app_domain')) {
            editor.appDomain.slug = query.get('app_domain');
            (async () => onAppDomainChange(query.get('app_domain')))();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const selectedAppDomain = appDomains.find(
            ({slug}) => slug === editor.appDomain.slug,
        );
        // If the app domain is not found, just select the first one.
        if (!selectedAppDomain && appDomains.length > 0) {
            updateAppDomain({
                slug: appDomains[0].slug,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor.appDomain.slug]);

    const appDomainOptions = getAppDomainOptions(appDomains);

    const value = editor.appDomain.slug;
    const selectedOption = appDomainOptions.find(
        ({value: optionValue}) => optionValue === value,
    );

    return (
        <EditorSidebarAppDomainSelect
            value={value}
            canEdit={canEdit}
            translate={translate}
            options={appDomainOptions}
            selectedOption={selectedOption}
            onChange={option =>
                option && option.value ? onAppDomainChange(option.value) : ''
            }
        />
    );
};

export default EditorSidebarAppDomainSelectWithState;
