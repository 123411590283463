import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M21.3199 1.84666L21.3145 1.84667H2.65456C2.17424 1.85036 1.71477 2.04358 1.37616 2.38436C1.03698 2.7257 0.846611 3.18737 0.846621 3.66858V16.0834C0.84662 16.3225 0.893735 16.5591 0.985269 16.7799C1.0768 17.0007 1.21096 17.2013 1.38007 17.3702C1.54919 17.5391 1.74994 17.673 1.97084 17.7642C2.19174 17.8555 2.42846 17.9023 2.66747 17.902L2.66855 17.902L4.12812 17.902C4.5957 17.902 4.97474 18.281 4.97474 18.7486V22.5565L9.38131 18.15C9.54009 17.9912 9.75543 17.902 9.97996 17.902H21.3325C21.5715 17.9023 21.8083 17.8555 22.0292 17.7642C22.2501 17.673 22.4508 17.5391 22.6199 17.3702C22.789 17.2013 22.9232 17.0007 23.0147 16.7799C23.1063 16.5591 23.1534 16.3225 23.1534 16.0834V3.66858C23.1534 3.42835 23.1059 3.19048 23.0136 2.96868C22.9213 2.74688 22.7861 2.54552 22.6157 2.37618C22.4453 2.20685 22.2431 2.07289 22.0207 1.98201C21.7983 1.89113 21.5601 1.84513 21.3199 1.84666ZM2.65162 1.00005H21.3145C21.6664 0.997821 22.0152 1.0652 22.3409 1.1983C22.6667 1.33141 22.9628 1.52762 23.2124 1.77564C23.462 2.02366 23.6601 2.31859 23.7953 2.64347C23.9304 2.96834 24 3.31673 24 3.6686V16.0834C24 16.4337 23.931 16.7806 23.7968 17.1041C23.6627 17.4277 23.466 17.7217 23.2182 17.9692C22.9704 18.2167 22.6762 18.413 22.3524 18.5467C22.0287 18.6804 21.6817 18.7491 21.3315 18.7486H9.97996L5.26598 23.4626C5.17099 23.5509 5.05264 23.61 4.92502 23.633C4.7974 23.656 4.66586 23.6419 4.54603 23.5923C4.4262 23.5427 4.32312 23.4598 4.24904 23.3534C4.17496 23.2469 4.13299 23.1215 4.12812 22.9919V18.7486H2.66855C2.31827 18.7491 1.97134 18.6804 1.6476 18.5467C1.32385 18.413 1.02965 18.2167 0.781804 17.9692C0.533962 17.7217 0.337345 17.4277 0.203196 17.1041C0.0690476 16.7806 5.41448e-10 16.4337 5.41448e-10 16.0834V3.6686C-1.41875e-05 2.96378 0.278809 2.28758 0.775603 1.78761C1.2724 1.28765 1.94681 1.00453 2.65162 1.00005Z" />
            <path d="M4.83582 7.49887C4.83582 7.26508 5.02534 7.07556 5.25913 7.07556H18.7102C18.944 7.07556 19.1335 7.26508 19.1335 7.49887C19.1335 7.73266 18.944 7.92218 18.7102 7.92218H5.25913C5.02534 7.92218 4.83582 7.73266 4.83582 7.49887Z" />
            <path d="M4.99475 12.1754C4.99475 11.9417 5.18427 11.7521 5.41806 11.7521H12.1436C12.3774 11.7521 12.5669 11.9417 12.5669 12.1754C12.5669 12.4092 12.3774 12.5988 12.1436 12.5988H5.41806C5.18427 12.5988 4.99475 12.4092 4.99475 12.1754Z" />
        </g>
    );
}
