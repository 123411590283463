import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';
import Documents from './Documents';

const isNotEmptyObj = obj => Object.keys(obj).length !== 0;

const DocumentsWithState = ({
    isFetching,
    directories,
    fetchDirectory,
    match,
}) => {
    const [activeDirectoryId, setActiveDirectoryId] = useState(null);
    const [directory, setDirectory] = useState({});

    const _getDirectoryFromState = () => {
        if (activeDirectoryId === null) {
            // find directory with parent === null (root)
            const directoriesArray = Object.values(directories);
            const directoriesArrayFiltered = directoriesArray.filter(
                directoryFromArray => directoryFromArray.parent === null,
            );
            return directoriesArrayFiltered[0] || null;
        }
        return getById(directories, activeDirectoryId) || null;
    };

    useEffect(() => {
        if (isNotEmptyObj(match.params)) {
            const {subDirectoryId} = match.params;
            setActiveDirectoryId(subDirectoryId);
        } else {
            setActiveDirectoryId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params]);

    useEffect(() => {
        fetchDirectory(activeDirectoryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDirectoryId]);

    useEffect(() => {
        const directoryFromState = _getDirectoryFromState();
        if (directoryFromState !== null) {
            // if directory not found in state yet, don't trigger rerender
            setDirectory(directoryFromState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directories, setDirectory]);

    let subDirectories = [];
    let files = [];
    let parentId = null;
    let activeDirectoryName = null;

    if (isNotEmptyObj(directory)) {
        subDirectories = directory.children;
        files = directory.files;
        parentId = (directory.parent && directory.parent.id) || null;
        activeDirectoryName = (parentId !== null && directory.name) || null;
    }

    return (
        <Documents
            activeDirectoryId={activeDirectoryId}
            activeDirectoryName={activeDirectoryName}
            loading={isFetching}
            directories={subDirectories}
            files={files}
            parentId={parentId}
        />
    );
};

const mapStateToProps = state => ({
    isFetching: state.directories.isFetching,
    directories: state.directories.byId,
});

const mapDispatchToProps = dispatch => ({
    fetchDirectory: directoryId =>
        dispatch(actions.directories.fetchDirectory(directoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsWithState);
