import React from 'react';
import * as theme from 'styles/theme';

const AmountList = ({list}) => (
    <ul className="amount-list">
        {list.map(({key, color, label, amount, info}) => (
            <li className="item" key={key}>
                <span className="text">
                    {color && (
                        <span
                            className="color"
                            style={{backgroundColor: color}}
                        />
                    )}
                    <span className="content">
                        <span className="label">{label}</span>
                        {info && <h3 className="info">{info}</h3>}
                    </span>
                </span>
                {amount && <span className="amount">{amount}</span>}
            </li>
        ))}

        <style jsx>
            {`
                .amount-list {
                    list-style: none;
                }

                .item,
                .text {
                    display: flex;
                    align-items: center;
                }

                .item {
                    padding: 1.4rem 0;
                    color: #fff;
                    border-top: solid 0.1rem rgba(#fff, 0.25);
                    justify-content: space-between;
                    font-size: ${theme.fontSize}rem;
                    font-weight: ${theme.fwBold};

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                .info {
                    margin-top: ${theme.spacingSmallest}rem;
                    font-size: ${theme.fontSizeSmaller}rem;
                }

                .color {
                    border-radius: 50%;
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: ${theme.spacingSmaller}rem;
                    flex-shrink: 0;

                    &,
                    & + .content {
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            `}
        </style>
    </ul>
);

export default AmountList;
