import React, {useState, useRef} from 'react';
import debounce from 'lodash.debounce';
import {Translate} from 'react-localize-redux';
import CloseButton from 'lib/components/CloseButton';
import * as theme from 'styles/theme';
import Icon from './Icon';

const SearchInput = ({value: valueFromProps, placeholder, onChange}) => {
    const [value, setValue] = useState(valueFromProps || '');
    const debouncedOnChange = useRef(debounce(onChange, 300));

    const handleChange = newValue => {
        setValue(newValue);
        debouncedOnChange.current(newValue);
    };

    return (
        <div className="search-input">
            <div className="icon">
                <Icon name="search" size={18} />
            </div>
            <Translate>
                {({translate}) => (
                    <input
                        className="input"
                        value={value}
                        placeholder={placeholder || translate('global.search')}
                        onChange={e => handleChange(e.target.value)}
                    />
                )}
            </Translate>
            {value.length > 0 && (
                <CloseButton onClick={() => handleChange('')} />
            )}

            <style jsx>
                {`
                    @import '../sass/kit';

                    .search-input {
                        width: 100%;
                        position: relative;

                        .icon {
                            width: 4.3rem;
                            text-align: center;
                            color: $grey-350;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        :global(.close-button) {
                            @include align-center;
                            justify-content: center;

                            position: absolute;
                            height: 2rem;
                            width: 2rem;
                            right: 0.5rem;
                            top: 50%;
                            transform: translateY(-50%);

                            :global(svg) {
                                position: static;
                            }
                        }
                    }

                    .input {
                        padding-left: 4.3rem;
                        padding-right: 2.5rem;
                        border: 0;
                        background: ${theme.colorGrey200};
                        font-weight: 500;
                        color: ${theme.colorText};
                        border: 1px solid ${theme.colorGrey400};
                    }
                `}
            </style>
        </div>
    );
};

export default SearchInput;
