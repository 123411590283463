const computeYearlyOnTheWhich = (data, rruleObj) => {
    if (rruleObj.freq !== 0 || !rruleObj.byweekday) {
        return data.repeat.yearly.onThe.which;
    }

    switch (rruleObj.bysetpos[0]) {
        case 1: {
            return 'first';
        }
        case 2: {
            return 'second';
        }
        case 3: {
            return 'third';
        }
        case 4: {
            return 'fourth';
        }
        case -1: {
            return 'last';
        }
        default: {
            return data.repeat.yearly.onThe.which;
        }
    }
};

export default computeYearlyOnTheWhich;
