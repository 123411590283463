import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    FETCH_MESSAGES_ANALYTICS_REQUEST,
    FETCH_MESSAGES_ANALYTICS_SUCCESS,
    FETCH_MESSAGES_ANALYTICS_FAILURE,
    FETCH_MESSAGES_WEEKLY_ANALYTICS_REQUEST,
    FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS,
    FETCH_MESSAGES_WEEKLY_ANALYTICS_FAILURE,
    FETCH_MOST_READ_REQUEST,
    FETCH_MOST_READ_SUCCESS,
    FETCH_MOST_READ_FAILURE,
    FETCH_MOST_CHEERED_REQUEST,
    FETCH_MOST_CHEERED_SUCCESS,
    FETCH_MOST_CHEERED_FAILURE,
    FETCH_CONTENT_POPULARITY_EXPORT_REQUEST,
    FETCH_CONTENT_POPULARITY_EXPORT_SUCCESS,
    FETCH_CONTENT_POPULARITY_EXPORT_FAILURE,
    FETCH_ADMIN_ACTIVITY_EXPORT_REQUEST,
    FETCH_ADMIN_ACTIVITY_EXPORT_SUCCESS,
    FETCH_ADMIN_ACTIVITY_EXPORT_FAILURE,
    FETCH_POPULARITY_ANALYTICS_REQUEST,
    FETCH_POPULARITY_ANALYTICS_SUCCESS,
    FETCH_POPULARITY_ANALYTICS_FAILURE,
    FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_REQUEST,
    FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS,
    FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_FAILURE,
} from '../../actions/analytics/messages';

// @TODO: Split this into separate files
export default () => {
    const messages = (state = [], action) => {
        switch (action.type) {
            case FETCH_MESSAGES_ANALYTICS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const isLoading = (state = false, action) => {
        switch (action.type) {
            case FETCH_MESSAGES_ANALYTICS_REQUEST:
                return true;
            case FETCH_MESSAGES_ANALYTICS_SUCCESS:
            case FETCH_MESSAGES_ANALYTICS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const hasFetchedMessages = (state = false, action) => {
        switch (action.type) {
            case FETCH_MESSAGES_ANALYTICS_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    const messagesWeekly = (state = [], action) => {
        switch (action.type) {
            case FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS: {
                let previousData;
                return action.payload.data.filter(data => {
                    if (
                        previousData &&
                        previousData.year === data.year &&
                        previousData.week === data.week
                    ) {
                        return null;
                    }

                    previousData = data;
                    return data;
                });
            }
            default:
                return state;
        }
    };

    const isLoadingWeekly = (state = false, action) => {
        switch (action.type) {
            case FETCH_MESSAGES_WEEKLY_ANALYTICS_REQUEST:
                return true;
            case FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS:
            case FETCH_MESSAGES_WEEKLY_ANALYTICS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const mostRead = combineReducers({
        byId: createById({
            listActions: [
                FETCH_MOST_READ_REQUEST,
                FETCH_MOST_READ_SUCCESS,
                FETCH_MOST_READ_FAILURE,
            ],
        }),
        paginated: paginate({
            listActions: [
                FETCH_MOST_READ_REQUEST,
                FETCH_MOST_READ_SUCCESS,
                FETCH_MOST_READ_FAILURE,
            ],
        }),
    });

    const mostCheered = combineReducers({
        byId: createById({
            listActions: [
                FETCH_MOST_CHEERED_REQUEST,
                FETCH_MOST_CHEERED_SUCCESS,
                FETCH_MOST_CHEERED_FAILURE,
            ],
        }),
        paginated: paginate({
            listActions: [
                FETCH_MOST_CHEERED_REQUEST,
                FETCH_MOST_CHEERED_SUCCESS,
                FETCH_MOST_CHEERED_FAILURE,
            ],
        }),
    });

    const popularity = (state = [], action) => {
        switch (action.type) {
            case FETCH_POPULARITY_ANALYTICS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const isFetchingPopularity = (state = false, action) => {
        switch (action.type) {
            case FETCH_POPULARITY_ANALYTICS_REQUEST:
                return true;
            case FETCH_POPULARITY_ANALYTICS_SUCCESS:
            case FETCH_POPULARITY_ANALYTICS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const hasFetchedPopularity = (state = false, action) => {
        switch (action.type) {
            case FETCH_POPULARITY_ANALYTICS_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    const fetchingContentPopularityExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_CONTENT_POPULARITY_EXPORT_REQUEST:
                return true;
            case FETCH_CONTENT_POPULARITY_EXPORT_SUCCESS:
            case FETCH_CONTENT_POPULARITY_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const fetchingAdminActivityExport = (state = false, action) => {
        switch (action.type) {
            case FETCH_ADMIN_ACTIVITY_EXPORT_REQUEST:
                return true;
            case FETCH_ADMIN_ACTIVITY_EXPORT_SUCCESS:
            case FETCH_ADMIN_ACTIVITY_EXPORT_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const messageReadsByWeekdayAndHour = (state = [], action) => {
        switch (action.type) {
            case FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const isFetchingMessageReadsByWeekdayAndHour = (state = false, action) => {
        switch (action.type) {
            case FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_REQUEST:
                return true;
            case FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS:
            case FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_FAILURE:
                return false;
            default:
                return state;
        }
    };

    const hasFetchedMessageReadsByWeekdayAndHour = (state = false, action) => {
        switch (action.type) {
            case FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS:
                return true;
            default:
                return state;
        }
    };

    return combineReducers({
        messages,
        hasFetchedMessages,
        isLoading,
        messagesWeekly,
        isLoadingWeekly,
        mostRead,
        mostCheered,
        fetchingContentPopularityExport,
        fetchingAdminActivityExport,
        popularity,
        isFetchingPopularity,
        hasFetchedPopularity,
        messageReadsByWeekdayAndHour,
        isFetchingMessageReadsByWeekdayAndHour,
        hasFetchedMessageReadsByWeekdayAndHour,
    });
};
