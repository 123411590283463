/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import {MODAL_NEW_MESSAGE} from 'lib/store/actions/modals';
import {getTemplatesForAppDomain} from 'lib/store/reducers/templates';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import actions from 'store/actions';
import useRoles from 'components/hooks/useRoles';
import MessageThumb from 'components/MessageThumb';
import useTranslate from 'components/hooks/useTranslate';
import {getNewMessageFromTemplate} from 'util/templates';
import Icon from 'lib/components/Icon';
import {rawBodyToEditorBody} from '../util/editorBody';

const NewMessage = () => {
    const history = useHistory();
    const isRogerStaff = useRoles();
    const [selectedAppDomain, setselectedAppDomain] = useState(null);
    const {translate, defaultLanguage} = useTranslate();
    const appDomains = useSelector(({appDomains: {data}}) => data);
    const templates = useSelector(({templates: {byId}}) => byId);
    const modalState = useSelector(
        ({modals: {data}}) => data[MODAL_NEW_MESSAGE],
    );
    const selectedDay = modalState && modalState.day ? modalState.day : null;

    const dispatch = useDispatch();

    const startMessage = day => {
        dispatch(actions.modals.setModalVisibility(MODAL_NEW_MESSAGE, false));

        dispatch(actions.editor.setAvailableLanguages([defaultLanguage]));
        dispatch(actions.editor.setCurrentLanguage(defaultLanguage));
        dispatch(
            actions.editor.setEditorState(
                rawBodyToEditorBody({[defaultLanguage]: {}}),
            ),
        );

        if (day !== null) {
            dispatch(actions.editor.setScheduledAt(day));
        }
    };
    const fetchTemplatesForAppDomain = appDomain => {
        dispatch(
            actions.templates.fetchTemplates({
                limit: 100, // TODO: find a beter system to load all templates for a given app domain.
                filters: {
                    appDomain,
                },
            }),
        );
    };

    const startNewMessage = (appDomain, day) => {
        let url;

        if (appDomain) {
            url = `/editor/messages/?app_domain=${appDomain}`;
        } else {
            url = '/editor/messages';
        }

        history.push(url);

        dispatch(actions.editor.clearEditor());
        startMessage(day);
    };

    const startMessageFromTemplate = async (template, day) => {
        const messageToCreate = getNewMessageFromTemplate(template, day);
        const {payload} = await dispatch(
            actions.messages.createMessage(messageToCreate),
        );
        history.push(`/editor/messages/${payload.ids}`);
        startMessage(day);
    };

    const editTemplate = template => {
        history.push(`/editor/templates/${template.id}`);
        dispatch(actions.modals.setModalVisibility(MODAL_NEW_MESSAGE, false));
    };

    const deleteTemplate = template =>
        dispatch(actions.templates.deleteTemplate(template));

    const selectAppDomain = appDomain => {
        setselectedAppDomain(appDomain);
        if (appDomain) {
            fetchTemplatesForAppDomain(appDomain.slug);
        }
    };

    const selectTemplate = template => {
        startMessageFromTemplate(template, selectedDay);
    };

    const handleStartNewMessage = () => {
        const appDomain = (selectedAppDomain && selectedAppDomain.slug) || null;
        startNewMessage(appDomain, selectedDay);
    };

    const handleEditTemplate = template => {
        editTemplate(template);
    };

    const handleDeleteTemplate = async template => {
        await deleteTemplate(template);
        fetchTemplatesForAppDomain(selectedAppDomain.slug);
    };

    let templatesForAppDomain = [];
    if (selectedAppDomain) {
        templatesForAppDomain = getTemplatesForAppDomain(
            templates,
            selectedAppDomain.slug,
        );
    }

    const templateLists = [
        {
            listName: translate('global.roger_templates'),
            list: templatesForAppDomain.filter(template => template.is_roger),
        },
        {
            listName: translate('global.own_templates'),
            list: templatesForAppDomain.filter(template => !template.is_roger),
        },
    ];

    return (
        <Branding.Consumer>
            {colors => (
                <div className="new-message">
                    {selectedAppDomain ? (
                        <span
                            className="new-message__back"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                selectAppDomain(null);
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    selectAppDomain(null);
                                }
                            }}>
                            <span className="icon">
                                <Icon name="chevronLeft" />
                            </span>
                            {selectedAppDomain.name}
                        </span>
                    ) : (
                        <h2 className="new-message__title">
                            {translate('global.new_message.choose_scope')}
                        </h2>
                    )}
                    {!selectedAppDomain ? (
                        <div className="new-message__list">
                            {appDomains.map(appDomain => (
                                <div
                                    className="new-message__list__col"
                                    key={appDomain.slug}>
                                    <MessageThumb
                                        icon={appDomain.icon}
                                        title={appDomain.name}
                                        onClick={() => {
                                            selectAppDomain(appDomain);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        templateLists.map((lists, listIndex) => (
                            <div
                                className="new-message__list"
                                key={`msglist${listIndex}`}>
                                <span className="new-message__list-title">
                                    {`${lists.listName} (${lists.list.length})`}
                                </span>
                                {lists.list.map(template => (
                                    <div
                                        className="new-message__list__col"
                                        key={template.id}>
                                        <MessageThumb
                                            title={
                                                template.title[defaultLanguage]
                                            }
                                            image={getUrlBySize(
                                                template.featured_image,
                                                THUMB,
                                            )}
                                            onClick={() =>
                                                selectTemplate(template)
                                            }
                                            onEdit={
                                                !template.is_roger ||
                                                isRogerStaff
                                                    ? () => {
                                                          handleEditTemplate(
                                                              template,
                                                          );
                                                      }
                                                    : null
                                            }
                                            onDelete={
                                                !template.is_roger ||
                                                isRogerStaff
                                                    ? () => {
                                                          handleDeleteTemplate(
                                                              template,
                                                          );
                                                      }
                                                    : null
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        ))
                    )}

                    <button
                        className="new-message__button"
                        onClick={() => handleStartNewMessage()}
                        type="button">
                        <div className="icon">
                            <Icon name="add" />
                        </div>
                        {translate('global.new_message.add')}
                    </button>

                    <style jsx>
                        {`
                            @import '../lib/sass/kit';

                            .new-message {
                                height: 100%;
                                overflow: hidden !important;
                                display: flex;
                                flex-direction: column;
                                &__title {
                                    text-transform: uppercase;
                                    font-weight: $fw-bold;
                                    font-size: 2rem;
                                    margin-bottom: 2.4rem;
                                    padding-bottom: 2.6rem;
                                    position: relative;

                                    &:after {
                                        content: '';
                                        background-color: ${colors.brand.base};
                                        width: 10.3rem;
                                        height: 0.3rem;
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                    }
                                }

                                &__intro {
                                    font-size: 1.2rem;
                                    margin-bottom: 3.7rem;
                                    max-width: 65.5rem;
                                }

                                &__list-title {
                                    color: ${colors.brand[900]};
                                    font-weight: $fw-bold;
                                    font-size: 1.6rem;
                                    width: 100%;
                                    padding-bottom: 1.6rem;
                                    border-bottom: solid 0.1rem
                                        ${colors.grey[450]};
                                    margin-bottom: 2.2rem;
                                }

                                &__list {
                                    height: 100%;
                                    overflow-y: auto;
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin: 0 -1.5rem 4rem;

                                    &-title {
                                        margin-left: 1.5rem;
                                        text-transform: uppercase;
                                    }

                                    &__col {
                                        padding: 0 1.5rem;
                                        width: 20%;
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }

                                & &__button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-transform: uppercase;
                                    font-weight: $fw-bold;
                                    font-size: 2.4rem;
                                    color: ${colors.grey[400]};
                                    width: 100%;
                                    height: 10.7rem;
                                    border: dashed 0.2rem ${colors.grey[400]};
                                    background-color: transparent;

                                    &:active,
                                    &:hover {
                                        text-decoration: none;
                                    }

                                    .icon {
                                        font-size: 2.4rem;
                                        margin-right: 1rem;
                                    }
                                }

                                &__back {
                                    text-transform: uppercase;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 5rem;
                                    font-size: 2rem;
                                    font-weight: $fw-bold;
                                    cursor: pointer;

                                    .icon {
                                        color: ${colors.brand.base};
                                        margin-right: 0.7rem;
                                    }
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

export default NewMessage;
