import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as Msal from 'msal';
import {useQueryParam} from 'use-query-params';
import actions from 'store/actions';
import {FAILURE} from 'lib/store/actions/notifications';
import {
    getErrorNotification,
    throwIfInvalidApiResponse,
} from 'util/errorHelper';
import Login from './Login';

const LoginWithState = ({
    remember,
    isLoading,
    login,
    setRemember,
    azureADAuth,
    loginAzureAD,
    showError,
}) => {
    const [email = '', setEmail] = useQueryParam('email');
    const [password, setPassword] = useState('');
    const [azureADLoading, setAzureADLoading] = useState(false);

    const getADToken = async (myMSALObj, loginRequest) =>
        new Promise((resolve, reject) =>
            myMSALObj
                .acquireTokenSilent(loginRequest)
                .then(response => {
                    resolve(response);
                })
                .catch(error =>
                    // fallback to interaction when silent call fails
                    myMSALObj
                        .acquireTokenPopup(loginRequest)
                        .then(tokenResponse => {
                            resolve(tokenResponse);
                        })
                        .catch(error => {
                            reject(error);
                        }),
                ),
        );

    const loginAzureADAuth = async () => {
        setAzureADLoading(true);
        const msalConfig = {
            auth: {
                clientId: azureADAuth.client_id,
                redirectUri: azureADAuth.callback_url_adminapp,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
        };

        const myMSALObj = new Msal.UserAgentApplication(msalConfig);
        const loginRequest = {
            scopes: ['profile', 'User.Read'],
        };

        myMSALObj
            .loginPopup(loginRequest)
            .then(() => {
                getADToken(myMSALObj, loginRequest)
                    .then(tokenResponse => {
                        loginAzureAD(tokenResponse.accessToken);
                        setAzureADLoading(false);
                    })
                    .catch(error => {
                        setAzureADLoading(false);
                        showError(error);
                    });
            })
            .catch(error => {
                setAzureADLoading(false);
                showError(error);
            });

        setAzureADLoading(false);
    };

    return (
        <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            remember={remember}
            isLoading={isLoading || azureADLoading}
            login={login}
            setRemember={setRemember}
            canUseAzureADAuth={!!azureADAuth}
            azureADAuth={azureADAuth}
            loginAzureAD={loginAzureADAuth}
        />
    );
};

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading,
    isAuthenticated: typeof state.auth.accessToken === 'string',
    remember: state.auth.remember,
    azureADAuth:
        state.company?.data?.settings?.can_use_azure_ad_auth &&
        state.company?.data.settings?.azure_ad_auth?.config
            ? state.company.data.settings.azure_ad_auth.config
            : null,
});

const mapDispatchToProps = dispatch => ({
    login: async (email, password) => {
        try {
            const response = await dispatch(
                actions.auth.login(email, password),
            );
            throwIfInvalidApiResponse(response);
            dispatch(actions.auth.setAccessToken(response.payload));
        } catch (error) {
            dispatch(
                actions.notifications.addNotification({
                    type: FAILURE,
                    content: {
                        ...getErrorNotification(error),
                    },
                }),
            );
        }
    },
    loginAzureAD: async accessToken => {
        try {
            const response = await dispatch(
                actions.auth.loginAzureAD({access_token: accessToken}),
            );
            dispatch(actions.auth.setAccessToken(response.payload));
        } catch (error) {
            dispatch(
                actions.notifications.addNotification({
                    type: FAILURE,
                    content: {
                        ...getErrorNotification(error),
                    },
                }),
            );
        }
    },
    setRemember: value => dispatch(actions.auth.setRemember(value)),
    showError: e => {
        dispatch(
            actions.notifications.addNotification({
                type: FAILURE,
                content: {
                    ...getErrorNotification(e),
                },
            }),
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithState);
