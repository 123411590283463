import React from 'react';
import Button from 'components/util/Button';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';

const DownloadOption = ({
    isDownloading,
    onStartDownload,
    buttonText,
    title,
    info,
}) => (
    <div
        className="option"
        onClick={onStartDownload}
        role="button"
        tabIndex={0}>
        <div className="marginBottom">
            <h2 className="title">{title}</h2>

            <div className="info">{info}</div>
        </div>

        <div className="marginBottom">
            <Button isLoading={isDownloading}>{buttonText}</Button>
        </div>

        <style jsx>
            {`
                .title {
                    font-size: ${theme.fontSizeLarge}rem;
                    margin-bottom: ${theme.spacingLarge}rem;
                }

                .marginBottom {
                    margin-bottom: ${theme.spacing}rem;
                }

                .option {
                    padding: ${theme.spacing}rem;
                    position: relative;
                    top: 0;
                    border-radius: ${theme.borderRadius}rem;
                    background-color: ${colors.background};
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    cursor: pointer;
                    transition-duration: ${theme.defaultTransition};
                    transition-property: top, box-shadow;
                }
            `}
        </style>
    </div>
);

export default DownloadOption;
