const computeMonthlyOnTheDay = (data, rruleObj) => {
    if (rruleObj.freq !== 1 || !rruleObj.byweekday) {
        return data.repeat.monthly.onThe.day;
    }

    const weekdays = rruleObj.byweekday
        .map(weekday => weekday.weekday)
        .join(',');

    switch (weekdays) {
        case '0': {
            return 'monday';
        }
        case '1': {
            return 'tuesday';
        }
        case '2': {
            return 'wednesday';
        }
        case '3': {
            return 'thursday';
        }
        case '4': {
            return 'friday';
        }
        case '5': {
            return 'saturday';
        }
        case '6': {
            return 'sunday';
        }
        case '0,1,2,3,4,5,6': {
            return 'day';
        }
        case '0,1,2,3,4': {
            return 'weekday';
        }
        case '5,6': {
            return 'weekend_day';
        }
        default: {
            return data.repeat.monthly.onThe.day;
        }
    }
};

export default computeMonthlyOnTheDay;
