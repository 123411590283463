import {getActiveLanguage} from 'react-localize-redux';
import {
    setAccessToken,
    refreshAccessToken,
    logoutWithoutAPICall,
    TOKEN_REFRESH_FAILURE,
    TOKEN_REFRESH_SUCCESS,
} from '../actions/auth';
import service from '../services';
import attemptAccessTokenRefresh from './attemptRefreshAccessToken';
import {
    responseToActionTransformerForSuccessActionTypeAndFailureActionType,
    actionPayloadNormalizerForSchema,
} from './transformers';
import {DEFAULT_LANGUAGE} from '../../helpers/locale';
import {addNotification, FAILURE, SUCCESS} from '../actions/notifications';
import {setAppAvailability} from '../actions/appHealth';
import {CALL_API} from '../../constants/api';

const mapSort = sort => {
    if (typeof sort !== 'object') {
        return sort;
    }

    return Object.keys(sort || {}).reduce(
        (accumulator, sortItem) =>
            `${accumulator}${sortItem && `${sortItem}.${sort[sortItem]}`},`,
        '',
    );
};

const reduxAPIMiddleware = store => next => async action => {
    const callAPIProperties = action[CALL_API];
    // If the action doesn't contain a key that indicates it wants to do an API call, skip this middleware.
    if (typeof callAPIProperties === 'undefined') {
        return next(action);
    }

    const state = store.getState();
    const {accessToken, refreshToken} = state.auth;

    const activeLang = getActiveLanguage(state.localize);

    // Set locale if activeLang is set
    let locale = DEFAULT_LANGUAGE;
    if (activeLang) {
        locale = activeLang.code;
    }

    const {
        body,
        endpoint,
        method,
        query,
        headers = {},
        types,
        notifications = {},
        schema,
        listType,
        version = 'v1',
    } = callAPIProperties;
    const [
        requestType = 'API_MIDDLEWARE_REQUEST',
        successType = 'API_MIDDLEWARE_SUCCESS',
        failureType = 'API_MIDDLEWARE_FAILURE',
    ] = types || [];

    // Map sort
    if (query) {
        query.sort = mapSort(query.sort);
    }

    const actionWithoutCallAPIWith = data => {
        const finalAction = {...action, ...data};
        delete finalAction[CALL_API];
        return finalAction;
    };

    const handleUnavailable = statusCode => {
        if (statusCode === 503) {
            store.dispatch(setAppAvailability(false));
        }
    };

    const handleValidationErrors = (statusCode, payload) => {
        if (statusCode === 422) {
            const errors = payload.errors || {};
            const errorMessage = Object.keys(errors)
                .map(errorKey => `${errors[errorKey]}`)
                .join('\n');

            if (errorMessage !== '' && endpoint !== '/user-management/import') {
                store.dispatch(
                    addNotification({
                        type: FAILURE,
                        content: {
                            message: errorMessage,
                        },
                    }),
                );
            }
        }
    };

    const handleNotifications = (
        {success, failure},
        statusCode,
        type,
        payload,
    ) => {
        switch (type) {
            case successType:
                if (typeof success !== 'undefined') {
                    store.dispatch(
                        addNotification({
                            type: SUCCESS,
                            content: success,
                        }),
                    );
                }
                break;
            case failureType:
                if (typeof failure !== 'undefined') {
                    store.dispatch(
                        addNotification({
                            type: FAILURE,
                            content: failure,
                        }),
                    );
                } else if (
                    // Show general error notification when there's no configured notification
                    Math.floor(statusCode / 100) !== 2 &&
                    statusCode !== 422 &&
                    statusCode !== 404 &&
                    failure !== false // If set to false skip showing general error
                ) {
                    const message = payload ? payload.message || '' : '';
                    let error = '';
                    if (statusCode || message) {
                        error = `- ${statusCode} ${message}`;
                    }

                    store.dispatch(
                        addNotification({
                            type: FAILURE,
                            content: {
                                key: 'notifications.general_error',
                                params: {error},
                            },
                        }),
                    );
                }
                break;
            default:
                break;
        }
    };

    // Dispatch the ..._REQUEST action for reducers to respond to, with any additional info attached to the original action.
    next(
        actionWithoutCallAPIWith({
            type: requestType,
            payload: {
                ...callAPIProperties,
                query,
            },
            listType,
        }),
    );

    const response = await service(
        accessToken,
        endpoint,
        method,
        body,
        query,
        headers,
        locale,
        version,
    )
        .then(
            attemptAccessTokenRefresh({
                currentAction: action,
                accessToken,
                refreshToken,
                refreshAccessTokenCreator: refreshAccessToken,
                successActionCreator: setAccessToken,
                failureActionCreator: logoutWithoutAPICall,
                refreshAccessTokenSuccessType: TOKEN_REFRESH_SUCCESS,
                refreshAccessTokenFailureType: TOKEN_REFRESH_FAILURE,
                dispatch: store.dispatch,
            })(store)(next),
        )
        .then(
            responseToActionTransformerForSuccessActionTypeAndFailureActionType(
                successType,
                failureType,
                listType,
            ),
        )
        // After this the response is a Flux Standard Action with `type` and `payload`
        .then(actionPayloadNormalizerForSchema(schema)(successType))
        // Make sure Redux processes this action before doing anything else.
        .then(async responseAction => {
            const {type, statusCode, payload} = responseAction;

            await next(responseAction);
            handleUnavailable(statusCode);
            handleValidationErrors(statusCode, payload);
            handleNotifications(notifications, statusCode, type, payload);

            return responseAction;
        })
        .catch(error => {
            if (error.response) {
                const {status, data} = response;
                handleUnavailable(status);
                handleValidationErrors(status, data);
                handleNotifications(notifications, status, failureType, data);
            }
            // TODO: handle error here for reporting
        });
    return response;
};

export default reduxAPIMiddleware;
