import React from 'react';
import styled from 'styled-components';

const SidebarForm = ({children}) => <Container>{children}</Container>;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Content = styled.div`
    .DayPickerInput {
        display: block;
    }
`;

const Actions = styled.div`
    margin-top: auto;

    button {
        width: 100%;
        margin-bottom: 1.2rem;
    }

    .row {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

SidebarForm.Content = Content;

SidebarForm.Actions = Actions;

export default SidebarForm;
