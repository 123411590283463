import {CALL_API} from '../../../constants/api';

export const FETCH_USER_ONBOARDING_REQUEST = 'FETCH_USER_ONBOARDING_REQUEST';
export const FETCH_USER_ONBOARDING_SUCCESS = 'FETCH_USER_ONBOARDING_SUCCESS';
export const FETCH_USER_ONBOARDING_FAILURE = 'FETCH_USER_ONBOARDING_FAILURE';

export const fetchOnBoarding = () => ({
    [CALL_API]: {
        endpoint: '/analytics/onboarding',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_USER_ONBOARDING_REQUEST,
            FETCH_USER_ONBOARDING_SUCCESS,
            FETCH_USER_ONBOARDING_FAILURE,
        ],
    },
});

export const FETCH_ONBOARDING_EXPORT_REQUEST =
    'FETCH_ONBOARDING_EXPORT_REQUEST';
export const FETCH_ONBOARDING_EXPORT_SUCCESS =
    'FETCH_ONBOARDING_EXPORT_SUCCESS';
export const FETCH_ONBOARDING_EXPORT_FAILURE =
    'FETCH_ONBOARDING_EXPORT_FAILURE';

export const fetchOnBoardingExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/onboarding/export',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_ONBOARDING_EXPORT_REQUEST,
            FETCH_ONBOARDING_EXPORT_SUCCESS,
            FETCH_ONBOARDING_EXPORT_FAILURE,
        ],
    },
});

export const FETCH_USER_ACTIVITY_REQUEST = 'FETCH_USER_ACTIVITY_REQUEST';
export const FETCH_USER_ACTIVITY_SUCCESS = 'FETCH_USER_ACTIVITY_SUCCESS';
export const FETCH_USER_ACTIVITY_FAILURE = 'FETCH_USER_ACTIVITY_FAILURE';

export const fetchUserActivity = () => ({
    [CALL_API]: {
        endpoint: '/analytics/user-activity',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_USER_ACTIVITY_REQUEST,
            FETCH_USER_ACTIVITY_SUCCESS,
            FETCH_USER_ACTIVITY_FAILURE,
        ],
    },
});

export const FETCH_USER_ACTIVITY_DETAIL_REQUEST =
    'FETCH_USER_ACTIVITY_DETAIL_REQUEST';
export const FETCH_USER_ACTIVITY_DETAIL_SUCCESS =
    'FETCH_USER_ACTIVITY_DETAIL_SUCCESS';
export const FETCH_USER_ACTIVITY_DETAIL_FAILURE =
    'FETCH_USER_ACTIVITY_DETAIL_FAILURE';

export const fetchUserActivityDetail = activityType => ({
    [CALL_API]: {
        endpoint: `/analytics/user-activity/${activityType}`,
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_USER_ACTIVITY_DETAIL_REQUEST,
            FETCH_USER_ACTIVITY_DETAIL_SUCCESS,
            FETCH_USER_ACTIVITY_DETAIL_FAILURE,
        ],
    },
});

export const FETCH_USER_ACTIVITY_EXPORT_REQUEST =
    'FETCH_USER_ACTIVITY_EXPORT_REQUEST';
export const FETCH_USER_ACTIVITY_EXPORT_SUCCESS =
    'FETCH_USER_ACTIVITY_EXPORT_SUCCESS';
export const FETCH_USER_ACTIVITY_EXPORT_FAILURE =
    'FETCH_USER_ACTIVITY_EXPORT_FAILURE';

export const fetchUserActivityExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/user-activity/export',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_USER_ACTIVITY_EXPORT_REQUEST,
            FETCH_USER_ACTIVITY_EXPORT_SUCCESS,
            FETCH_USER_ACTIVITY_EXPORT_FAILURE,
        ],
    },
});

export default {
    fetchOnBoarding,
    fetchOnBoardingExport,
    fetchUserActivity,
    fetchUserActivityDetail,
    fetchUserActivityExport,
};
