import 'react-input-range/lib/css/index.css';
import React from 'react';
import AvatarEditorPlugin from 'react-avatar-editor';
import InputRange from 'react-input-range';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Button from './Button';
import * as colors from '../styles/colors';
import * as theme from '../styles/theme';
import ImageWrapper from './Images/ImageWrapper';
import Icon, {pen} from './Icon';

const dataURItoBlob = dataURI => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: mimeString});
};

class AvatarEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: this.props.editing,
            file: null,
            zoom: 1,
        };
    }

    _togglePictureEdit() {
        this.inputRef.click();
        this._toggleEdit();
    }

    _toggleEdit() {
        const {editing} = this.state;
        const {onEditChange} = this.props;

        this.setState({editing: !editing});
        if (onEditChange) onEditChange(!editing);
    }

    _onFileChange(files) {
        const file = files && files.length ? files[0] : null;

        this.setState({
            file,
        });
    }

    _onCancel() {
        this._toggleEdit();
    }

    _onSave() {
        this._toggleEdit();

        if (this.editor) {
            const image = this.editor.getImage();
            const blob = dataURItoBlob(image.toDataURL('image/png'));
            this.props.onEditComplete(blob);
        }
    }

    render() {
        const {editable, translate, image, saving} = this.props;

        const {editing, file, zoom} = this.state;

        return (
            <div className="avatar-editor">
                <input
                    type="file"
                    ref={input => {
                        this.inputRef = input;
                    }}
                    style={{display: 'none'}}
                    onChange={e => {
                        this._onFileChange(e.target.files);
                    }}
                    accept="image/*"
                />

                {file && editing ? (
                    <>
                        <AvatarEditorPlugin
                            ref={editor => {
                                this.editor = editor;
                            }}
                            image={file}
                            width={200}
                            height={200}
                            border={50}
                            borderRadius={200}
                            color={[255, 255, 255, 0.6]} // RGBA
                            rotate={0}
                            scale={zoom}
                        />

                        <div className="avatar-editor__slider">
                            <InputRange
                                minValue={1}
                                maxValue={3}
                                step={0.01}
                                value={zoom}
                                onChange={value => this.setState({zoom: value})}
                                formatLabel={() => {}}
                            />
                        </div>
                    </>
                ) : (
                    <div
                        className={classNames('avatar', {
                            editable: editable && !editing,
                        })}
                        onClick={() => editable && this._togglePictureEdit()}>
                        {!editing && (
                            <div className="editIcon">
                                <Icon name={pen} size="70" />
                            </div>
                        )}
                        {image && image.regular && (
                            <ImageWrapper
                                src={image.regular}
                                alt="avatar"
                                disableProgressiveLoading
                                retryIfNotFound
                            />
                        )}
                    </div>
                )}

                {(editing || saving) && (
                    <div className="avatar-editor__actions">
                        {file && (
                            <>
                                <Button
                                    className="button danger"
                                    onClick={() => this._onCancel()}>
                                    {translate('global.cancel')}
                                </Button>
                                <Button
                                    isLoading={saving}
                                    className="button success"
                                    onClick={() => this._onSave()}>
                                    {translate('global.save')}
                                </Button>
                            </>
                        )}
                    </div>
                )}

                <style jsx>
                    {`
                        .avatar-editor {
                            font-size: 0; // get rid of unnecessary whitespace
                            width: 100%;

                            .avatar {
                                width: 30rem;
                                height: 30rem;
                                position: relative;
                                margin: 0 auto 3rem;
                                display: flex;

                                .editIcon {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: ${colors.pureWhite};
                                    background: rgba(0, 0, 0, 0.3);
                                    opacity: 0;
                                    transition: opacity
                                        ${theme.defaultTransition};
                                }

                                &:hover .editIcon {
                                    opacity: 1;
                                }
                            }

                            :global(canvas) {
                                display: block;
                                margin: 0 auto;
                                width: 100% !important; // Override javascript plugin styling
                                height: auto !important; // Override javascript plugin styling
                            }

                            .input-range {
                                &,
                                &__track {
                                    height: 2.5rem;
                                }

                                &__track {
                                    background: none;

                                    &:after {
                                        content: '';
                                        width: 100%;
                                        height: 0.3rem;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        left: 0;
                                        background-color: ${colors.grey[100]};
                                        z-index: -1;
                                    }

                                    &--active:after {
                                        background-color: ${colors.brand.base};
                                    }
                                }

                                &__slider {
                                    background: ${colors.brand.base};
                                    border-color: ${colors.brand.base};
                                }

                                &__track,
                                &__slider,
                                &__slider-container {
                                    margin-top: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }

                            &__slider {
                                margin: 2rem 0;
                            }

                            &__actions {
                                display: flex;
                                justify-content: space-between;
                            }

                            &__edit {
                                margin-bottom: 2rem;
                            }
                        }
                    `}
                </style>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(AvatarEditor);
