import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, withLocalize} from 'react-localize-redux';
import {format} from 'date-fns';
import {
    MODAL_DIRECTORY_NAME,
    MODAL_CONFIRM,
    MODAL_FILE,
    MODAL_DIRECTORY_AUDIENCE,
    MODAL_FILE_AUDIENCE,
} from 'lib/store/actions/modals';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';

import Icon from 'lib/components/Icon';
import Button from 'components/util/Button';
import * as routes from 'routes/index';
import actions from 'store/actions';
import DirectoryNameModal from 'modals/DirectoryModal/DirectoryNameModalWithData';
import DirectoryAudienceModal from 'modals/DirectoryModal/DirectoryAudienceModalWithData';
import FileAudienceModal from 'modals/FileModal/FileAudienceModalWithData';
import FileModal from 'modals/FileModal/FileModalWithData';
import ContentView from 'components/util/ContentView';
import Table from 'components/Table/Table';
import AudienceLink from 'components/AudienceLink';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import {mapAudienceFilters} from 'lib/store/reducers/editor';

const Documents = ({
    activeDirectoryId,
    activeDirectoryName,
    loading,
    directories,
    files,
    parentId,
    history,
    translate,
    setModalVisibility,
    setModalData,
    deleteDirectory,
    deleteFile,
    maxFileUpload,
    showFailureByKey,
}) => {
    const hiddenFileInput = React.createRef();

    const _onNewDirectoryButtonClick = () => {
        setModalData(MODAL_DIRECTORY_NAME, {action: 'create'});
        setModalVisibility(MODAL_DIRECTORY_NAME, true);
    };
    const _onUploadFileButtonClick = () => {
        hiddenFileInput.current.click();
    };
    const _onNavigateToSubDirectory = id => {
        history.push(routes.documentsSubdirectory(id));
    };
    const _onOpenFile = id => {
        const fileToOpen = files.find(file => file.id === id);
        const url = fileToOpen.sizes.full;
        // open in new tab
        const win = window.open(url, '_blank');
        win.focus();
    };
    const _onUpdateDirectoryButtonClick = directory => {
        setModalData(MODAL_DIRECTORY_NAME, {
            action: 'update',
            directory,
        });
        setModalVisibility(MODAL_DIRECTORY_NAME, true);
    };
    const _onDeleteDirectoryButtonClick = directoryId => {
        setModalVisibility(MODAL_CONFIRM, true);
        setModalData(MODAL_CONFIRM, {
            title: translate('documents.confirmDeleteDirectory'),
            buttonActions: [
                {
                    label: translate('global.delete'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteDirectory(directoryId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };
    const _onUpdateFileButtonClick = file => {
        setModalData(MODAL_FILE, {
            action: 'update',
            file,
            activeDirectoryId,
        });
        setModalVisibility(MODAL_FILE, true);
    };
    const _onDeleteFileButtonClick = fileId => {
        setModalVisibility(MODAL_CONFIRM, true);
        setModalData(MODAL_CONFIRM, {
            title: translate('documents.confirmDeleteFile'),
            buttonActions: [
                {
                    label: translate('global.delete'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteFile(activeDirectoryId, fileId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };
    const _onFileSelected = filesFromInput => {
        const file = filesFromInput[0];

        if (file) {
            if (file.size >= maxFileUpload) {
                // Block when the file size is too big
                showFailureByKey('notifications.file_too_big');
            } else {
                setModalData(MODAL_FILE, {
                    action: 'create',
                    file,
                    activeDirectoryId,
                });
                setModalVisibility(MODAL_FILE, true);
            }
        }
    };

    const title =
        parentId === null ? (
            <Translate id="global.documents" />
        ) : (
            `${translate('global.documents')}: ${activeDirectoryName}`
        );

    const headerComponents = [
        <Button
            key={1}
            className="button"
            onClick={() => _onUploadFileButtonClick()}>
            <Translate id="documents.uploadFile" />
        </Button>,
        <Button
            key={2}
            className="button"
            onClick={() => _onNewDirectoryButtonClick()}>
            <Translate id="documents.newDirectory" />
        </Button>,
    ];

    const columns = [
        {
            className: 'table__icon',
            key: 'icon',
            renderer: (content, data) => {
                const {isDirectory, url, isFile} = data;
                if (isDirectory) {
                    return <Icon name="folder" size={30} />;
                }
                if (url) {
                    return (
                        <img
                            src={url}
                            alt="file"
                            style={{maxWidth: 30, maxHeight: 30}}
                        />
                    );
                }
                if (isFile) {
                    return <Icon name="file" size={30} />;
                }
            },
        },
        {
            key: 'name',
            label: translate('documents.name'),
            sortable: false,
        },
        {
            key: 'audience',
            label: translate('global.audience'),
            renderer: (content, data) => {
                if (data) {
                    const {
                        isDirectory,
                        audienceFilters,
                        directory,
                        hasDefaultAudience,
                        file,
                    } = data;

                    let visibleModal = MODAL_FILE_AUDIENCE;
                    const defaultModalData = {
                        audienceFilters,
                        hasDefaultAudience,
                        action: 'update',
                    };

                    let modalData = {
                        file,
                    };
                    if (isDirectory) {
                        visibleModal = MODAL_DIRECTORY_AUDIENCE;
                        modalData = {
                            directory,
                        };
                    }

                    return (
                        <AudienceLink
                            users={content}
                            onClick={() => {
                                setModalVisibility(visibleModal, true);
                                setModalData(visibleModal, {
                                    ...defaultModalData,
                                    ...modalData,
                                });
                            }}
                        />
                    );
                }

                return null;
            },
            nowrap: true,
        },
        {
            key: 'updated_at',
            label: translate('drafts.last_edited'),
            nowrap: true,
        },
        {
            key: 'actions',
            label: translate('global.actions'),
            renderer: (content, data) => {
                if (data) {
                    const {isDirectory, file, directory} = data;
                    const item = isDirectory ? directory : file;
                    return (
                        <div>
                            <ButtonWithoutStyle
                                onClick={e => {
                                    e.stopPropagation();
                                    if (isDirectory) {
                                        _onUpdateDirectoryButtonClick(item);
                                    } else {
                                        _onUpdateFileButtonClick(item);
                                    }
                                }}>
                                <Icon name="pen" size={20} />
                            </ButtonWithoutStyle>
                            <ButtonWithoutStyle
                                onClick={e => {
                                    e.stopPropagation();
                                    if (isDirectory) {
                                        _onDeleteDirectoryButtonClick(item.id);
                                    } else {
                                        _onDeleteFileButtonClick(item.id);
                                    }
                                }}>
                                <Icon
                                    className="table__remove"
                                    name="bin"
                                    size={20}
                                />
                            </ButtonWithoutStyle>
                            <style jsx>
                                {`
                                    div {
                                        width: 45px;
                                        display: flex;
                                        justify-content: space-between;
                                    }
                                `}
                            </style>
                        </div>
                    );
                }
            },
        },
    ];

    const rows = [];

    // add link to parent as first row
    if (parentId !== null) {
        rows.push({
            key: parentId,
            data: [
                {
                    className: 'table__icon',
                    data: {
                        isDirectory: false,
                        isFile: false,
                    },
                },
                {
                    className: 'font-weight-bold',
                    content: '...',
                },
                {}, // empty object because no need to display audience data
                {}, // empty object because no need to display actions
            ],
        });
    }

    if (directories && directories.length) {
        directories.map(directory => {
            const {
                id,
                name,
                updated_at: updatedAt,
                audience_filters: audienceFilters,
                audience_count: audienceCount,
                default_audience_filters: hasDefaultAudience,
            } = directory;

            // TODO: make this abstract and have a row generator for files and documents
            return rows.push({
                key: id,
                data: [
                    {
                        className: 'table__icon',
                        data: {
                            isDirectory: true,
                            isFile: false,
                        },
                    },
                    {
                        className: 'font-weight-bold',
                        content: name,
                    },
                    {
                        content: audienceCount || 0,
                        data: {
                            isDirectory: true,
                            isFile: false,
                            directory,
                            audienceFilters:
                                mapAudienceFilters(audienceFilters),
                            hasDefaultAudience,
                        },
                    },
                    {content: format(updatedAt, DATE_TIME_FORMAT)},
                    {
                        data: {
                            isDirectory: true,
                            isFile: false,
                            directory,
                        },
                    },
                ],
            });
        });
    }

    if (files && files.length) {
        const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

        files.map(file => {
            const {
                id,
                name,
                created_at: createdAt,
                mime_type: mimeType,
                audience_count: audienceCount,
                audience_filters: audienceFilters,
                default_audience_filters: hasDefaultAudience,
            } = file;
            const url = file.sizes.full;

            // TODO: make this abstract and have a row generator for files and documents
            return rows.push({
                key: `file-${id}`, // we prepend 'file-' to differentiate a file from a directory
                data: [
                    {
                        className: 'table__icon',
                        data: {
                            isDirectory: false,
                            isFile: true,
                            url: imageMimeTypes.includes(mimeType) ? url : null,
                        },
                    },
                    {
                        className: 'font-weight-bold',
                        content: `${name}`,
                    },
                    {
                        content: audienceCount || 0,
                        data: {
                            isDirectory: false,
                            isFile: true,
                            file,
                            audienceFilters,
                            hasDefaultAudience,
                        },
                    },
                    {content: format(createdAt, DATE_TIME_FORMAT)},
                    {
                        data: {
                            isDirectory: false,
                            isFile: true,
                            file,
                        },
                    },
                ],
            });
        });
    }

    return (
        <ContentView title={title} headerComponents={headerComponents}>
            <Table
                rows={rows}
                columns={columns}
                onRowClick={documentId => {
                    // check if row contains file or directory
                    if (documentId.toString().startsWith('file-')) {
                        // we need the fileId to get url, so we need to strip 'file-'
                        const fileId = parseInt(
                            documentId.replace('file-', ''),
                            10,
                        );
                        _onOpenFile(fileId);
                    } else {
                        _onNavigateToSubDirectory(documentId);
                    }
                }}
                loading={loading}
                emptyTitle={translate('documents.noDocumentsAvailable')}
            />
            <DirectoryNameModal />
            <DirectoryAudienceModal
                parentId={parentId}
                activeDirectoryId={activeDirectoryId}
            />
            <FileModal />
            <FileAudienceModal
                parentId={parentId}
                activeDirectoryId={activeDirectoryId}
            />
            <input
                type="file"
                ref={hiddenFileInput}
                style={{display: 'none'}}
                onChange={e => _onFileSelected(e.target.files)}
            />
        </ContentView>
    );
};

const mapStateToProps = ({company}) => ({
    maxFileUpload: company.data.max_file_upload,
});

const mapDispatchToProps = dispatch => ({
    setModalData: (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data)),
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    deleteDirectory: directoryId =>
        dispatch(actions.directories.deleteDirectory(directoryId)),
    deleteFile: (activeDirectoryId, fileId) =>
        dispatch(actions.directories.deleteFile(activeDirectoryId, fileId)),
    showFailureByKey: key =>
        dispatch(actions.notifications.showFailureByKey(key)),
});

export default withRouter(
    withLocalize(connect(mapStateToProps, mapDispatchToProps)(Documents)),
);
