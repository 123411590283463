import React from 'react';
import {Translate} from 'react-localize-redux';
import color from 'tinycolor2';
import styled from 'styled-components';
import useTimezone from 'components/hooks/useTimezone';
import CloseButton from 'lib/components/CloseButton';

const TimezoneWarning = () => {
    const {isInCompanyTimezone, warningClosed, closeWarning} = useTimezone();

    if (isInCompanyTimezone || warningClosed) {
        return null;
    }

    return (
        <Container>
            <Translate id="You are in a different timezone than the company timezone. All dates and times are shown in the timezone of the company." />
            <CloseButton onClick={closeWarning} />
        </Container>
    );
};

const Container = styled.div(
    ({theme}) => `
    position: fixed;
    z-index: ${theme.zNotice};
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    padding: ${theme.spacingSmaller}rem;
    background-color: ${color(theme.warning.base).toRgbString()};
    box-shadow: ${theme.defaultShadow};

    .close-button {
        margin-left: ${theme.spacing}rem;
    }
`,
);

export default TimezoneWarning;
