import React from 'react';

const CheckCircle = ({...props}) => (
    <path
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        {...props}
    />
);

const ExclamationCircle = ({...props}) => (
    <path
        d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zM8 3a1 1 0 00-1 1v4a1 1 0 002 0V4a1 1 0 00-1-1z"
        {...props}
    />
);

const FlatIcon = ({
    className,
    size = 16,
    name,
    color = 'currentColor',
    fill = 'primary',
}) => (
    <svg
        className={className}
        width={size}
        height={size}
        strokeWidth="2"
        viewBox="0 0 16 16"
        fill="none"
        style={{color}}>
        {(() => {
            switch (name) {
                case 'checkCircle':
                    return <CheckCircle fill={fill} />;
                case 'exclamationCircle':
                    return <ExclamationCircle fill={fill} />;
                default:
                    return null;
            }
        })()}
    </svg>
);

export default FlatIcon;
