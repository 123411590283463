import merge from 'deepmerge';

const createListReducerPart =
    ([ENTITY_LIST_REQUEST, ENTITY_LIST_SUCCESS, ENTITY_LIST_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_LIST_SUCCESS: {
                const nextState = {...state, ...action.payload.data};
                return nextState;
            }
            case ENTITY_LIST_REQUEST:
            case ENTITY_LIST_FAILURE:
            default:
                return state;
        }
    };

const createViewReducerPart =
    ([ENTITY_VIEW_REQUEST, ENTITY_VIEW_SUCCESS, ENTITY_VIEW_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_VIEW_SUCCESS: {
                const nextState = {...state, ...action.payload.data};
                return nextState;
            }
            case ENTITY_VIEW_REQUEST:
            case ENTITY_VIEW_FAILURE:
            default:
                return state;
        }
    };

const createCreateReducerPart =
    ([ENTITY_CREATE_REQUEST, ENTITY_CREATE_SUCCESS, ENTITY_CREATE_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_CREATE_SUCCESS: {
                const nextState = {...state, ...action.payload.data};
                return nextState;
            }
            case ENTITY_CREATE_REQUEST:
            case ENTITY_CREATE_FAILURE:
            default:
                return state;
        }
    };

const createUpdateReducerPart =
    ([ENTITY_UPDATE_REQUEST, ENTITY_UPDATE_SUCCESS, ENTITY_UPDATE_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_UPDATE_SUCCESS: {
                const nextState = {...state, ...action.payload.data};
                return nextState;
            }
            case ENTITY_UPDATE_REQUEST:
            case ENTITY_UPDATE_FAILURE:
            default:
                return state;
        }
    };

const createMergeReducerPart =
    ([ENTITY_UPDATE_REQUEST, ENTITY_UPDATE_SUCCESS, ENTITY_UPDATE_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_UPDATE_SUCCESS: {
                const nextState = merge(state, action.payload.data);
                return nextState;
            }
            case ENTITY_UPDATE_REQUEST:
            case ENTITY_UPDATE_FAILURE:
            default:
                return state;
        }
    };

const createDeleteReducerPart =
    ([ENTITY_DELETE_REQUEST, ENTITY_DELETE_SUCCESS, ENTITY_DELETE_FAILURE]) =>
    (state, action) => {
        switch (action.type) {
            case ENTITY_DELETE_SUCCESS: {
                const nextState = {...state};
                Object.keys(action.payload.data).forEach(key => {
                    delete nextState[key];
                });
                return nextState;
            }
            case ENTITY_DELETE_REQUEST:
            case ENTITY_DELETE_FAILURE:
            default:
                return state;
        }
    };

const createById = ({
    listActions,
    viewActions,
    mergeActions = [],
    createActions,
    updateActions,
    deleteActions,
}) => {
    const reducerParts = [];

    const createReducerPart = (actions, creator) => {
        if (actions && actions.length === 3) {
            reducerParts.push(creator(actions));
        }
    };

    const createReducerPartsForActionArray = (actionArray, creator) => {
        if (actionArray && actionArray.length) {
            if (Array.isArray(actionArray[0])) {
                actionArray.forEach(actions => {
                    createReducerPart(actions, creator);
                });
            } else {
                createReducerPart(actionArray, creator);
            }
        }
    };

    createReducerPartsForActionArray(listActions, createListReducerPart);
    createReducerPartsForActionArray(viewActions, createViewReducerPart);
    createReducerPartsForActionArray(createActions, createCreateReducerPart);
    createReducerPartsForActionArray(updateActions, createUpdateReducerPart);
    createReducerPartsForActionArray(deleteActions, createDeleteReducerPart);
    createReducerPartsForActionArray(mergeActions, createMergeReducerPart);

    return (state = {}, action) => {
        let newState = {...state};

        reducerParts.forEach(reducerPart => {
            newState = reducerPart(newState, action);
        });

        return newState;
    };
};

export default createById;

export const getById = (state, id) => state[id];
