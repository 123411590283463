import React from 'react';
import {Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import * as theme from 'styles/theme';
import Switch from 'components/util/Switch';
import Select from 'components/util/Select';
import * as colors from 'lib/styles/colors';
import Button from 'components/util/Button';
import Segmentation from './Segmentation';
import InfoTooltip from '../../../lib/components/InfoTooltip';
import useTranslate from '../../hooks/useTranslate';
import Icon from 'lib/components/Icon';

const Action = ({id}) => (
    <strong>
        <Translate id={id} />
    </strong>
);
Action.propTypes = {
    id: PropTypes.string.isRequired,
};

const AudienceSelect = ({
    onSendToEveryoneChange,
    onSendToActiveChange,
    segments,
    onUpdateClick,
    sendToEveryone,
    sendToActive,
    selectedSegments,
    parentId,
    hasDefaultAudienceFilters,
    onDefaultAudienceFilterChange,
    userOptions,
    usersAdded,
    usersRemoved,
    onUsersAddedChange,
    onUsersRemovedChange,
    onAddSubFilter,
    onChangeSegment,
    onDeleteSegment,
    onAddFilter,
}) => {
    const {translate} = useTranslate();

    return (
        <div className="audienceSelect">
            <header className="audienceSwitchContainer">
                {parentId && (
                    <div className="switch">
                        <Translate id="segmentation.inheritFromParent" />
                        <div className="audience-select-toggle">
                            <Switch
                                value={hasDefaultAudienceFilters}
                                onChange={onDefaultAudienceFilterChange}
                            />
                        </div>
                    </div>
                )}
                <div className="switch">
                    <Translate id="segmentation.sendToEveryone" />
                    <div className="audience-select-toggle">
                        <Switch
                            value={sendToEveryone}
                            onChange={onSendToEveryoneChange}
                        />
                    </div>
                </div>
                <Button
                    onClick={() => onSendToActiveChange(!sendToActive)}
                    disabled={sendToActive}
                    style={{
                        display: 'flex',
                        gap: '0.25rem',
                        alignItems: 'center',
                    }}
                    transparent>
                    <Icon name="add" size="1rem" />
                    <Translate id="segmentation.sendToActive" />
                    <InfoTooltip
                        info={translate('segmentation.sendToActiveInfo')}
                    />
                </Button>
            </header>

            <div className="content">
                {sendToEveryone && <div className="disableOverlay" />}
                <div className="segments">
                    <h2 className="title">
                        <Translate id="segmentation.audience" />
                    </h2>
                    <div className="intro">
                        <p>
                            <Translate id="segmentation.intro" />
                        </p>
                    </div>
                    <Segmentation
                        segments={segments}
                        selectedSegments={selectedSegments}
                        onAddSubFilter={onAddSubFilter}
                        onChangeSegment={onChangeSegment}
                        onDeleteSegment={onDeleteSegment}
                        onAddFilter={onAddFilter}
                    />
                </div>

                <hr className="divider" />

                <div className="users">
                    <h2 className="title">
                        <Translate id="segmentation.users" />
                    </h2>

                    <div className="selectUsers">
                        <div className="intro">
                            <p>
                                <Translate
                                    id="segmentation.actionUsers"
                                    data={{
                                        action: (
                                            <Action id="segmentation.added" />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                        <Select
                            value={usersAdded}
                            options={userOptions}
                            hideIndicator
                            isMulti
                            onChange={onUsersAddedChange}
                            tagColor={colors.success.dark}
                        />
                    </div>

                    <div className="selectUsers">
                        <div className="intro">
                            <p>
                                <Translate
                                    id="segmentation.actionUsers"
                                    data={{
                                        action: (
                                            <Action id="segmentation.removed" />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                        <Select
                            value={usersRemoved}
                            options={userOptions}
                            hideIndicator
                            isMulti
                            onChange={onUsersRemovedChange}
                            tagColor={colors.danger.base}
                        />
                    </div>
                </div>
            </div>

            <footer>
                <Button onClick={onUpdateClick} success>
                    <Translate id="global.confirm" />
                </Button>
            </footer>

            <style jsx>
                {`
                    .audienceSelect {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        padding: ${`${theme.spacing}rem 0`};
                    }

                    header {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-direction: column;
                    }

                    .switch {
                        display: flex;
                        align-items: center;
                        margin-bottom: ${theme.spacing}rem;
                    }

                    .audience-select-toggle {
                        margin-left: ${theme.spacing}rem;
                    }

                    footer {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: auto;
                    }

                    .audienceSelect .intro {
                        margin-bottom: ${theme.spacing}rem;
                    }

                    .audienceSelect .title {
                        font-size: ${theme.fontSizeLarge}rem;
                        font-weight: ${theme.fwBold};
                        margin-bottom: ${theme.spacing}rem;
                    }

                    .selectUsers {
                        margin-bottom: ${theme.spacingLarge}rem;
                    }

                    .switch,
                    .users,
                    .segments,
                    footer {
                        padding: 0 ${theme.spacing}rem;
                    }

                    .divider {
                        margin: ${`${theme.spacingLarge}rem 0`};
                    }

                    .content {
                        position: relative;
                    }

                    .disableOverlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: ${colors.pureWhite};
                        z-index: 2;
                        opacity: 0.8;
                    }
                `}
            </style>
        </div>
    );
};

AudienceSelect.propTypes = {
    onSendToEveryoneChange: PropTypes.func.isRequired,
    onSendToActiveChange: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    onUpdateClick: PropTypes.func.isRequired,
    sendToEveryone: PropTypes.bool.isRequired,
    sendToActive: PropTypes.bool.isRequired,
    selectedSegments: PropTypes.arrayOf(PropTypes.any).isRequired,
    parentId: PropTypes.string,
    hasDefaultAudienceFilters: PropTypes.bool.isRequired,
    onDefaultAudienceFilterChange: PropTypes.func.isRequired,
    userOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    usersAdded: PropTypes.arrayOf(PropTypes.any).isRequired,
    usersRemoved: PropTypes.arrayOf(PropTypes.any).isRequired,
    onUsersAddedChange: PropTypes.func.isRequired,
    onUsersRemovedChange: PropTypes.func.isRequired,
    onAddSubFilter: PropTypes.func.isRequired,
    onChangeSegment: PropTypes.func.isRequired,
    onDeleteSegment: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func.isRequired,
};

AudienceSelect.defaultProps = {
    parentId: null,
};
export default AudienceSelect;
