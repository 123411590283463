import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import {getTranslate} from 'react-localize-redux';
import styled from 'styled-components';

import actions from '../../store/actions';

const ToastContainerStyled = styled(ToastContainer)`
    & > * {
        border-radius: ${({theme}) => theme.borderRadius}rem;
    }
`;

const Notifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(
        state => state.notifications.notifications,
    );
    const translate = useSelector(state => getTranslate(state.localize));

    useEffect(() => {
        notifications.forEach(notification => {
            dispatch(actions.notifications.clearNotification(notification.id));

            let content = '';
            if (notification.content.key) {
                content = translate(
                    notification.content.key,
                    notification.content.params,
                );
            } else if (notification.content.message) {
                content = notification.content.message;
            }

            if (content === '') {
                return null;
            }

            const options = {style: {whiteSpace: 'pre-line'}};

            switch (notification.type) {
                case actions.notifications.FAILURE:
                    toast.error(content, options);
                    break;
                case actions.notifications.WARNING: {
                    toast.warn(content, options);
                    break;
                }
                case actions.notifications.SUCCESS:
                    toast.success(content, options);
                    break;
                default:
                    toast(content, options);
                    break;
            }
        });
    }, [dispatch, notifications, translate]);

    return <ToastContainerStyled hideProgressBar />;
};

export default Notifications;
