import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import {MODAL_AUDIENCE} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import AudienceResultWithData from 'components/util/AudienceResult/AudienceResultWithData';
import AudienceSelectWithData from 'components/util/AudienceSelect/AudienceSelectWithData';
import UserListWithData from './UserListWithData';

const AudienceModal = ({
    name = MODAL_AUDIENCE,
    segments,
    onSegmentsChange,
    onUpdateClick,
    onCancel,
    usersOnly,
    children,
    disableMenu,
    isBig = true,
    parentId,
    hasDefaultAudienceFilters,
    onDefaultAudienceFilterChange,
}) => {
    const audienceMenu = {
        key: 'audience',
        label: <Translate id="segmentation.audience" />,
    };
    const usersMenu = {
        key: 'users',
        label: <Translate id="segmentation.users" />,
    };
    let menu;

    if (!usersOnly) {
        menu = [audienceMenu, usersMenu];
    }

    return (
        <Modal
            name={name}
            noPadding
            big={isBig}
            menu={!disableMenu && menu}
            onClose={onCancel}>
            {({activeTab}) =>
                children || (
                    <div className="content">
                        <div className="main">
                            {(() => {
                                switch (activeTab) {
                                    case 'audience':
                                        return (
                                            <div className="audience">
                                                <AudienceSelectWithData
                                                    segments={segments}
                                                    onSegmentsChange={
                                                        onSegmentsChange
                                                    }
                                                    onUpdateClick={
                                                        onUpdateClick
                                                    }
                                                    parentId={parentId}
                                                    hasDefaultAudienceFilters={
                                                        hasDefaultAudienceFilters
                                                    }
                                                    onDefaultAudienceFilterChange={
                                                        onDefaultAudienceFilterChange
                                                    }
                                                />
                                            </div>
                                        );
                                    case 'users':
                                    default:
                                        return (
                                            <div className="userList">
                                                <UserListWithData
                                                    audienceFilters={segments}
                                                    parentId={
                                                        hasDefaultAudienceFilters &&
                                                        parentId
                                                    }
                                                />
                                            </div>
                                        );
                                }
                            })()}
                        </div>
                        <div className="sidebar">
                            <AudienceResultWithData
                                audienceFilters={segments}
                                parentId={hasDefaultAudienceFilters && parentId}
                            />
                        </div>

                        <style jsx>
                            {`
                                .content {
                                    display: flex;
                                    align-items: stretch;
                                }

                                .content,
                                .main,
                                .sidebar {
                                    min-height: 60rem;
                                }

                                .audience,
                                .sidebar {
                                    min-height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                }

                                .sidebar {
                                    padding: ${theme.spacing}rem;
                                }

                                .userList {
                                    padding-top: ${theme.spacing}rem;
                                }

                                .main {
                                    flex: 1;
                                }

                                .sidebar {
                                    width: 30rem;
                                    background-color: ${colors.grey[100]};
                                    display: flex;
                                    align-items: center;
                                    padding-top: ${theme.spacingLargest}rem;
                                }
                            `}
                        </style>
                    </div>
                )
            }
        </Modal>
    );
};
AudienceModal.propTypes = {
    name: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.any),
    onSegmentsChange: PropTypes.func.isRequired,
    onUpdateClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    usersOnly: PropTypes.bool,
    children: PropTypes.node,
    disableMenu: PropTypes.bool,
    isBig: PropTypes.bool,
    parentId: PropTypes.number,
    hasDefaultAudienceFilters: PropTypes.bool,
    onDefaultAudienceFilterChange: PropTypes.func.isRequired,
};

AudienceModal.defaultProps = {
    name: MODAL_AUDIENCE,
    segments: [],
    usersOnly: false,
    children: null,
    disableMenu: false,
    isBig: false,
    parentId: null,
    hasDefaultAudienceFilters: false,
};

export default AudienceModal;
