import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M14.063 10.5c0-.563-.094-.984-.282-1.313-.187-.328-.515-.468-.984-.468-.422 0-.844.094-1.266.234-.375.188-.75.469-1.078.797-.75.844-1.172 1.828-1.172 3 0 .75.235 1.36.61 1.734.421.422.937.61 1.546.61.61 0 1.126-.14 1.5-.469.422-.375.797-.844 1.126-1.547zm2.484 3.375c0 .89.281 1.313.89 1.313.282 0 .61-.094.985-.282.328-.187.656-.469.984-.89.75-.938 1.125-2.25 1.125-3.844 0-2.016-.656-3.656-2.015-4.875-1.453-1.313-3.328-1.969-5.72-1.969-2.858 0-5.202.89-6.983 2.625-1.829 1.734-2.72 3.938-2.72 6.563 0 2.53.845 4.546 2.579 6.046 1.781 1.547 4.266 2.344 7.36 2.344v2.532c-1.97 0-3.75-.282-5.345-.891-1.593-.563-3-1.36-4.125-2.344a10.185 10.185 0 0 1-2.624-3.515c-.61-1.36-.938-2.86-.938-4.5C0 10.5.328 8.953.984 7.5c.614-1.406 1.5-2.672 2.676-3.703 1.125-1.031 2.485-1.828 4.031-2.39a14.754 14.754 0 0 1 4.97-.845c1.687 0 3.234.235 4.546.75 1.36.516 2.484 1.172 3.422 2.016a9.583 9.583 0 0 1 2.203 3.094c.516 1.172.797 2.344.797 3.61 0 1.265-.188 2.39-.563 3.327-.375.891-.843 1.688-1.406 2.297a5.97 5.97 0 0 1-2.015 1.453 5.87 5.87 0 0 1-2.344.516c-.797 0-1.453-.188-1.969-.469-.516-.281-.844-.75-1.031-1.265l-.14-.422c-.329.656-.75 1.172-1.266 1.547-.47.422-1.079.609-1.829.609-.707 0-1.363-.14-1.875-.375a5.347 5.347 0 0 1-1.363-.984c-.844-.938-1.312-2.11-1.312-3.47 0-1.78.613-3.327 1.875-4.593 1.218-1.219 2.675-1.875 4.406-1.875 1.125 0 2.02.375 2.719 1.031.707.704 1.03 1.735 1.03 3.094zm0 0"
        />
    );
}
