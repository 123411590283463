import React from 'react';
import {Translate} from 'react-localize-redux';
import Count from 'components/Count';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import PeriodChart from 'components/PeriodChart';
import colors from 'lib/styles/colors';
import Dropdown from 'components/Dropdown';
import AmountList from 'components/AmountList';
import {WEEKLY, MONTHLY} from 'util/analytics';
import CustomBarChart from '../CustomBarChart/CustomBarChart';

const AdminActivity = ({
    activePeriod,
    onPeriodChange,
    onRangeChange,
    isLoadingMonthly,
    isLoadingWeekly,
    selectedActivity,
    options,
    activePeriodText,
    chartData,
    translate,
}) => {
    const list = [
        {
            key: 1,
            label: <Translate id="dashboard.automated_messages" />,
            amount: selectedActivity ? selectedActivity.published_auto || 0 : 0,
        },
    ];

    return (
        <PeriodChart
            dateOptions={options}
            activePeriod={activePeriod}
            onPeriodChange={onPeriodChange}
            sidebar={
                <LoadingWrapper loading={isLoadingMonthly || isLoadingWeekly}>
                    <Count
                        number={
                            selectedActivity
                                ? selectedActivity.published_manual || 0
                                : 0
                        }
                        text={activePeriodText}
                    />
                    <AmountList list={list} />
                </LoadingWrapper>
            }>
            <div className="dateRangePicker">
                <Dropdown onChange={onRangeChange}>
                    <option value={MONTHLY}>
                        {translate('dashboard.per_month')}
                    </option>
                    <option value={WEEKLY}>
                        {translate('dashboard.per_week')}
                    </option>
                </Dropdown>
            </div>
            <LoadingWrapper loading={isLoadingMonthly || isLoadingWeekly}>
                <CustomBarChart
                    color={colors.chart.blue}
                    data={chartData}
                    activePeriod={activePeriod}
                    onPeriodChange={onPeriodChange}
                />
            </LoadingWrapper>

            <style jsx>
                {`
                    .dateRangePicker {
                        margin: 0.5rem 0 0 1rem;
                    }
                `}
            </style>
        </PeriodChart>
    );
};

export default AdminActivity;
