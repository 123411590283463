import React from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import LastHappinessPoll from './LastHappinessPoll';

const LastHappinessPollWithRedux = ({
    mood,
    average,
    isFetching,
    isFetchingExport,
    fetchMoodExport,
    showNotification,
    companySettings,
}) => (
    <LastHappinessPoll
        mood={mood}
        average={average}
        isFetching={isFetching}
        isFetchingExport={isFetchingExport}
        fetchMoodExport={fetchMoodExport}
        showNotification={showNotification}
    />
);

const mapStateToProps = state => ({
    mood: state.analytics.mood.mood,
    average: state.analytics.mood.average,
    isFetching: state.analytics.mood.isFetching,
    isFetchingExport: state.analytics.mood.isFetchingExport,
    companySettings: state.company.data.settings,
});

const mapDispatchToProps = dispatch => ({
    fetchMoodExport: () =>
        dispatch(actions.analytics.mood.fetchMoodExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LastHappinessPollWithRedux);
