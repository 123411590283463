import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M3.11798 12.467C3.0407 12.3233 3.00024 12.1627 3.00024 11.9995C3.00024 11.8363 3.0407 11.6757 3.11798 11.532C5.00998 8.033 8.50498 5 12 5C15.495 5 18.99 8.033 20.882 11.533C20.9593 11.6767 20.9997 11.8373 20.9997 12.0005C20.9997 12.1637 20.9593 12.3243 20.882 12.468C18.99 15.967 15.495 19 12 19C8.50498 19 5.00998 15.967 3.11798 12.467V12.467Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.121 9.879C14.4123 10.1545 14.6454 10.4857 14.8066 10.8529C14.9677 11.2201 15.0536 11.6158 15.0592 12.0168C15.0648 12.4177 14.9899 12.8157 14.839 13.1872C14.6882 13.5588 14.4643 13.8963 14.1808 14.1798C13.8973 14.4634 13.5598 14.6872 13.1882 14.838C12.8167 14.9889 12.4187 15.0638 12.0178 15.0582C11.6168 15.0526 11.2211 14.9667 10.8539 14.8056C10.4867 14.6444 10.1555 14.4113 9.88 14.12C9.35008 13.5513 9.06158 12.7991 9.0753 12.0219C9.08901 11.2447 9.40386 10.5032 9.95351 9.95351C10.5032 9.40386 11.2447 9.08901 12.0219 9.0753C12.7991 9.06159 13.5513 9.35008 14.12 9.88"
                stroke="currentColor"
                strokeWidth="1.429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
