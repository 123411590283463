import {
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    SET_ACCESS_TOKEN,
    REMEMBER,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
} from '../actions/auth';

const initialState = {
    isLoading: false,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    remember: true,
};

export default function accessTokenReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case LOGOUT_REQUEST:
        case LOGOUT_SUCCESS:
            return initialState;
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };
        case REMEMBER:
            return {
                ...state,
                remember: action.payload,
            };
        default:
            return state;
    }
}
