import React, {Component} from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import TabMenu from './TabMenu';

// @TODO: this component will be obsolute and should be removed when the last remnant of this component is removed.
class TabMenuWithRedux extends Component {
    componentDidMount() {
        const {getActiveMenuItem, menuKey} = this.props;
        getActiveMenuItem(menuKey);
    }

    render() {
        const {setActiveMenuItem, menu, menuKey} = this.props;
        return (
            <TabMenu
                menuKey={menuKey}
                menu={menu}
                onClick={setActiveMenuItem}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    menu: state.tabMenu[ownProps.menuKey],
});

const mapDispatchToProps = dispatch => ({
    setActiveMenuItem: (menu, menuItem) => {
        dispatch(actions.tabMenu.setActiveMenuItem(menu, menuItem));
    },
    getActiveMenuItem: menu => {
        dispatch(actions.tabMenu.getActiveMenuItem(menu));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabMenuWithRedux);
