import React from 'react';
import {Provider} from 'react-redux';
import {LocalizeProvider} from 'react-localize-redux';

const ReduxProviders = ({store, children}) => (
    <Provider store={store}>
        <LocalizeProvider store={store}>{children}</LocalizeProvider>
    </Provider>
);

export default ReduxProviders;
