import React from 'react';

const Edit = () => (
    <path
        fill="currentColor"
        d="M14.9237928,4.00529079 L19.8059357,8.9112975 L7.44788384,21.3298707 L2.56850895,16.4238639 L14.9237928,4.00529079 Z M23.5105378,2.82207447 L21.33329,0.634163919 C20.4918588,-0.211387973 19.1255486,-0.211387973 18.2812587,0.634163919 L16.1956729,2.72996343 L21.0778158,7.63601574 L23.5105378,5.19137991 C24.1631541,4.53552201 24.1631541,3.47788675 23.5105378,2.82207447 Z M0.0135858518,23.3196513 C-0.0752628872,23.7214743 0.285758853,24.0815281 0.685668933,23.9838083 L6.12599793,22.6582759 L1.24662302,17.7522692 L0.0135858518,23.3196513 Z"
    />
);

export default Edit;
