import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M19 9H12.529C12.3659 8.99998 12.2052 8.96005 12.0611 8.88371C11.917 8.80737 11.7937 8.69692 11.702 8.562L10.297 6.497C10.2053 6.36241 10.0821 6.25224 9.93818 6.17608C9.79423 6.09992 9.63386 6.06007 9.471 6.06H5C4.46957 6.06 3.96086 6.27071 3.58579 6.64579C3.21071 7.02086 3 7.52957 3 8.06V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V11C21 10.4696 20.7893 9.96086 20.4142 9.58579C20.0391 9.21071 19.5304 9 19 9Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 19V11C21 10.4696 20.7893 9.96086 20.4142 9.58579C20.0391 9.21071 19.5304 9 19 9H12.529C12.3659 8.99998 12.2052 8.96005 12.0611 8.88371C11.917 8.80737 11.7937 8.69692 11.702 8.562L10.297 6.497C10.2053 6.36241 10.0821 6.25224 9.93818 6.17608C9.79423 6.09992 9.63386 6.06007 9.471 6.06H5C4.46957 6.06 3.96086 6.27071 3.58579 6.64579C3.21071 7.02086 3 7.52957 3 8.06V19"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 5.94H14.529C14.3659 5.93998 14.2052 5.90005 14.0611 5.82371C13.917 5.74737 13.7937 5.63692 13.702 5.502L12.297 3.437C12.2053 3.30241 12.0821 3.19224 11.9382 3.11608C11.7942 3.03992 11.6339 3.00007 11.471 3H5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
