import {CALL_API} from '../../constants/api';

export const EMBED_CODE_REQUEST = 'EMBED_CODE_REQUEST';
export const EMBED_CODE_SUCCESS = 'EMBED_CODE_SUCCESS';
export const EMBED_CODE_FAILURE = 'EMBED_CODE_FAILURE';

export const getEmbed = url => ({
    [CALL_API]: {
        endpoint: '/helpers/open-graph',
        query: {
            url,
        },
        method: 'GET',
        types: [EMBED_CODE_REQUEST, EMBED_CODE_SUCCESS, EMBED_CODE_FAILURE],
    },
});

export default {
    getEmbed,
};
