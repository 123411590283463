export const fetchAll = (dispatch, action, params, listType = '', page = 1) => {
    const fetch = newPage => {
        // Fetch 1 page of 100 items
        dispatch(
            action(
                Object.assign({}, params, {limit: 100, page: newPage}),
                listType,
            ),
        ).then(response => {
            // Get next page if there are still pages left to fetch
            const {meta} = response.payload;

            if (meta.current_page < meta.last_page) {
                fetch(newPage + 1);
            }
        });
    };

    return fetch(page);
};

export default fetchAll;
