import React from 'react';
import {useDispatch} from 'react-redux';
import {useQueryParam} from 'use-query-params';
import {useHistory} from 'react-router-dom';
import actions from 'store/actions';
import * as routes from 'routes/index';
import ForgotPassword from './ForgotPassword';

const ForgotPasswordWithState = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email = '', setEmail] = useQueryParam('email');

    const onSubmit = async e => {
        e.preventDefault();
        await dispatch(actions.auth.sendPasswordResetLink(email));
        history.push(`${routes.login()}?email=${email}`);
    };

    return (
        <ForgotPassword email={email} setEmail={setEmail} onSubmit={onSubmit} />
    );
};

export default ForgotPasswordWithState;
