import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {connect, useDispatch, useSelector} from 'react-redux';
import format from 'date-fns/format';
import {getById} from 'lib/store/reducers/helpers/byId';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';
import {setModalVisibility} from 'lib/store/actions/modals';
import {FAILURE} from 'lib/store/actions/notifications';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import actions from 'store/actions';
import * as routes from 'routes/index';
import useTranslate from 'components/hooks/useTranslate';
import AdminMessageReadsModal from 'modals/Analytics/AdminMessageReadsModal/AdminMessageReadsModal';
import PropTypes from 'prop-types';
import EditorFollowUp from './EditorFollowUp';

const EditorFollowUpWithState = ({messageId, status}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {translate} = useTranslate();
    const messagesAudience = useSelector(
        ({messagesAudience: messagesAudienceFromState}) =>
            messagesAudienceFromState,
    );
    const hasfetchedFollowUpMessageReadsByHour = useSelector(
        ({messagesAudience: newMessagesAudience}) =>
            newMessagesAudience.readsByHour.hasFetched,
    );
    const users = messagesAudience.byId;
    const {isFetchingAudienceExport} = messagesAudience;
    const {pages, filters, isFetching, pageCount} = messagesAudience.paginated;

    const [selectedFilter, setSelectedFilter] = useState(status);
    const [stats, setStats] = useState({});
    const [, setReadsByHour] = useState();
    const [initialLoading, setInitialLoading] = useState(true);

    const fetch = (newFilters = {}, page = 1) => {
        const params = {
            filters: {
                ...filters,
                ...newFilters,
                status: selectedFilter,
            },
            page,
        };

        return dispatch(
            actions.messagesAudience.fetchAudienceForMessage({
                ...params,
                followUp: true,
                messageId,
            }),
        );
    };

    const onQueryChange = query => fetch({query});

    const onSortChange = sort => fetch({sort});

    const onTableEnd = () => {
        if (!isFetching) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetch(filters, page);
            }
        }
    };

    // On filter change
    const onFilterChange = filter => {
        history.push(routes.messageFollowUp(messageId, filter));
        setSelectedFilter(filter);
    };

    useEffect(() => {
        if (!initialLoading) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter]);

    // Update selected filter if query parameter updates
    useEffect(() => {
        setSelectedFilter(status);
    }, [status]);

    const initialFetch = async () => {
        setInitialLoading(true);
        await fetch();
        const result = await dispatch(
            actions.messages.fetchFollowUpStats(messageId),
        );
        const {fetchMessageReadsByHour} = actions.analytics.messages;
        const followUpReadsByHour = await dispatch(
            fetchMessageReadsByHour(messageId),
        );
        if (
            followUpReadsByHour?.payload?.data &&
            followUpReadsByHour?.payload?.data.length > 0
        )
            setReadsByHour(followUpReadsByHour.payload.data);
        setStats(result.payload.data);
        setInitialLoading(false);
    };

    useEffect(() => {
        initialFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapPageData = (accumulator, page) => [
        ...accumulator,
        ...page.map(userId => {
            const user = getById(users, userId);
            return {
                key: user.id,
                data: [
                    {
                        className: 'table__icon',
                        content: {
                            isOnline: user.is_online,
                            avatar: getUrlBySize(user.avatar, THUMB),
                        },
                    },
                    {className: 'font-weight-bold', content: user.first_name},
                    {className: 'font-weight-bold', content: user.last_name},
                    {
                        content:
                            user.channel &&
                            translate(`channel.${user.channel}`),
                    },
                    {
                        content:
                            user.received_at &&
                            format(user.received_at, DATE_TIME_FORMAT),
                    },
                    {
                        content:
                            user.reminder_at &&
                            format(user.reminder_at, DATE_TIME_FORMAT),
                    },
                    {
                        content:
                            user.read_at &&
                            format(user.read_at, DATE_TIME_FORMAT),
                    },
                ],
            };
        }),
    ];

    const getRows = () => {
        const hasSelectedPage = pages.length > 0;
        if (hasSelectedPage) {
            return pages.reduce(mapPageData, []);
        }
        return [];
    };

    const openModal = modal => {
        dispatch(setModalVisibility(modal, true));
    };

    return (
        <>
            <EditorFollowUp
                translate={translate}
                isFetching={isFetching}
                filters={filters}
                stats={stats}
                fetchAudienceExport={() =>
                    dispatch(
                        actions.messagesAudience.fetchAudienceExport(messageId),
                    )
                }
                isFetchingExport={isFetchingAudienceExport}
                showFailure={(key, params) =>
                    dispatch(
                        actions.notifications.addNotification({
                            type: FAILURE,
                            content: {
                                key,
                                params,
                            },
                        }),
                    )
                }
                selectedFilter={selectedFilter}
                onSortChange={onSortChange}
                rows={getRows()}
                onQueryChange={onQueryChange}
                onTableEnd={onTableEnd}
                onFilterChange={onFilterChange}
                canOpenMessageReadsByHour={hasfetchedFollowUpMessageReadsByHour}
                openMessageReadsByHourModal={() =>
                    openModal('adminMessageReadsModal')
                }
            />
            <AdminMessageReadsModal />
        </>
    );
};
EditorFollowUpWithState.propTypes = {
    companySettings: PropTypes.shape({has_new_analytics: PropTypes.bool})
        .isRequired,
    messageId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    companySettings: state.company.data.settings,
});

export default connect(mapStateToProps)(EditorFollowUpWithState);
