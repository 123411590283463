import React from 'react';
import Icon from 'lib/components/Icon';
import Button from './ButtonHOC';

const FileButton = props => (
    <Button {...props} type="media" subType="file">
        <Icon name="document" size="26" />
    </Button>
);

export default FileButton;
