// Unauthenticated
export const auth = () => '/auth'; // Route is not used in app but only to build other routes

export const login = () => `${auth()}/login`;

export const forgotPassword = () => `${auth()}/forgot-password`;

// @TODO: Refactor so that reset-password is also a sub route of auth (this is set to /reset-password in the mail template)
export const resetPassword = (token = ':token') => `/reset-password/${token}`;

// Authenticated
export const home = () => '/';

export const planning = () => '/planning';

export const drafts = () => '/drafts';

export const automatedMessages = () => '/automated-messages';

export const sentMessages = () => '/sent-messages';

export const archivedMessages = () => '/archived-messages';

export const users = () => '/users';

export const bulkEditUsers = () => '/users/bulk-edit';

export const settings = () => '/settings';

export const segmentation = () => '/segmentation';

export const segmentationGroup = (groupId = ':groupId') =>
    `/segmentation/${groupId}`;

export const documents = () => '/documents';

export const documentsSubdirectory = (subDirectoryId = ':subDirectoryId') =>
    `/documents/${subDirectoryId}`;

export const docs = () => '/docs';

export const editor = () => '/editor';

export const editorForMessageType = (type = ':messageType') =>
    `${editor()}/${type}`;

export const editorForMessageId = (type, id = ':messageId') =>
    `${editorForMessageType(type)}/${id}`;

export const messageAnswers = (id = ':messageId') => `/answers/${id}`;

export const messageFollowUp = (id = ':messageId', status = ':status') =>
    `/follow-up/${id}/${status}`;

export const messageComments = (id = ':messageId') => `/comments/${id}`;

export const adminInbox = () => '/admin-inbox';
