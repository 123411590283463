import React, {Fragment, useRef} from 'react';

// TODO: allow multiple files to be uploaded
const FileUpload = ({onFilesChange, acceptTypes = '*', children}) => {
    const fileInput = useRef();
    const form = useRef();
    const onUploadClick = () => {
        if (fileInput.current) {
            fileInput.current.value = '';
            fileInput.current.click();
        }
    };
    const onFileInputChange = e => {
        onFilesChange(e.target.files);
    };

    return (
        <Fragment>
            {typeof children === 'function' ? (
                children({onUploadClick})
            ) : (
                <div onClick={onUploadClick} role="button" tabIndex={0}>
                    {children}
                </div>
            )}

            <form ref={form}>
                <input
                    type="file"
                    name="media_file"
                    style={{display: 'none'}}
                    ref={fileInput}
                    onChange={onFileInputChange}
                    accept={acceptTypes}
                />
            </form>
        </Fragment>
    );
};

export default FileUpload;
