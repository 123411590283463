import React from 'react';
import PropTypes from 'prop-types';
import CustomBlocks from 'lib/constants/customBlocks';

import Scale from '../../Scale';
import CustomBlock from '../CustomBlock/CustomBlock';

const OpinionScaleBlock = ({
    translate,
    survey,
    onEdit,
    onDelete,
    onChange,
    interactive,
    canEdit,
}) => {
    const response = survey.response && survey.response.option.value;

    return (
        <CustomBlock
            type={CustomBlocks.SURVEY_BLOCK}
            label={translate('editor.survey.opinion_scale')}
            icon="scale"
            title={survey.content.question}
            description={survey.content.description}
            onEdit={() => {
                onEdit(survey);
            }}
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            <Scale
                type="opinion"
                value={interactive ? response : survey.options.length}
                maxValue={interactive ? survey.options.length : 10}
                interactive={interactive}
                onChange={value => interactive && onChange({value})}
            />
        </CustomBlock>
    );
};
OpinionScaleBlock.propTypes = {
    translate: PropTypes.func.isRequired,
    survey: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        response: PropTypes.shape({option: PropTypes.object}.isRequired),
        content: PropTypes.shape({
            question: PropTypes.string.isRequired,
            description: PropTypes.shape({
                question: PropTypes.string.isRequired,
            }),
        }),
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    interactive: PropTypes.bool,
    canEdit: PropTypes.bool,
};

OpinionScaleBlock.defaultProps = {
    interactive: false,
    canEdit: true,
};
export default OpinionScaleBlock;
