import React from 'react';

import UserDetailBlock from './UserDetailBlock';

const PhoneMail = ({editable, onChange, user}) => (
    <div className="row">
        <div className="col-md-6">
            <UserDetailBlock
                label="first_name"
                value={user.first_name || ''}
                editable={editable}
                onChange={value => onChange({first_name: value})}
                required
            />
        </div>
        <div className="col-md-6">
            <UserDetailBlock
                label="last_name"
                value={user.last_name || ''}
                editable={editable}
                onChange={value => onChange({last_name: value})}
                required
            />
        </div>
    </div>
);

export default PhoneMail;
