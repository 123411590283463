import React from 'react';
import {connect, useSelector} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import AdminMessageReadsByWeekdayAndHour from './AdminMessageReadsByWeekdayAndHour';

const AdminMessageReadsWithState = props => {
    const data = useSelector(
        state => state.analytics.messages.messageReadsByWeekdayAndHour,
    );

    return (
        <AdminMessageReadsByWeekdayAndHour
            data={data}
            translate={props.translate}
        />
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMessageReadsWithState);
