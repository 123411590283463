import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M20.926 15.999V8.001C20.926 7.286 20.545 6.626 19.926 6.269L13 2.27C12.6959 2.09456 12.3511 2.0022 12 2.0022C11.649 2.0022 11.3041 2.09456 11 2.27L4.07401 6.269C3.76997 6.44453 3.5175 6.697 3.34197 7.00103C3.16643 7.30506 3.07401 7.64994 3.07401 8.001V15.998C3.07401 16.713 3.45501 17.373 4.07401 17.73L11 21.73C11.3041 21.9054 11.649 21.9978 12 21.9978C12.3511 21.9978 12.6959 21.9054 13 21.73L19.926 17.731C20.23 17.5555 20.4825 17.303 20.658 16.999C20.8336 16.6949 20.926 16.3501 20.926 15.999V15.999Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.17001 12.63L9.17001 14.37"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.34201 7.001L12 12L20.658 7.001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 22V12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.67001 9.5L16.6 4.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
