import React from 'react';
import {Translate} from 'react-localize-redux';
import {brandingDefaults} from 'lib/styles/colors';
import Switch from 'components/util/Switch';

const AcceptAnswers = ({acceptAnswers, setAcceptAnswers}) => (
    <div className="l-editor__switch">
        <Switch
            value={acceptAnswers}
            offColor={brandingDefaults.danger.base}
            onChange={checked => setAcceptAnswers(checked)}
        />
        <span className="l-editor__switch-label font-weight-bold">
            <Translate id="editor.accept_answers" />
        </span>
        <style jsx="true">
            {`
                .l-editor__switch-label {
                    margin-left: 1rem;
                    text-transform: uppercase;
                }
            `}
        </style>
    </div>
);

export default AcceptAnswers;
