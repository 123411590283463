import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M14.0661 1.10219C13.6154 1.26686 13.1214 1.79553 13.0087 2.23753C12.9654 2.41953 12.8874 2.9482 12.8354 3.4162C12.5927 5.59155 11.1367 7.64555 8.69271 9.24889C8.12937 9.62156 7.61803 9.98557 7.55737 10.0549C7.4707 10.1589 7.45337 11.1902 7.45337 14.8736L7.46203 19.5623L7.9647 19.6663C8.46737 19.7703 8.69271 19.9003 10.1487 20.9316C11.5354 21.911 12.1421 22.015 16.3454 22.0236H19.5521L19.9941 21.807C20.2714 21.6683 20.5228 21.4516 20.6788 21.2263C20.9041 20.9056 20.9301 20.7929 20.9301 20.1949C20.9301 19.6316 20.8954 19.4843 20.7308 19.2589L20.5314 18.9903H20.8174C21.4761 18.9816 22.2561 18.3749 22.4381 17.7163C22.5854 17.2049 22.5508 16.8929 22.3168 16.4249C22.0654 15.9396 22.0568 15.8703 22.2128 15.8703C22.4728 15.8703 23.0708 15.3329 23.2441 14.9429C23.4868 14.3883 23.4348 13.6602 23.1141 13.2182L22.8888 12.8889L23.1228 12.7416C24.1368 12.1176 24.3014 10.7742 23.4608 9.90757C22.9754 9.4049 22.6894 9.37023 19.2748 9.37023H16.2241L15.9641 9.15356C15.5741 8.82423 15.5567 8.59023 15.8514 7.68889C16.1981 6.64022 16.3627 5.68688 16.4667 4.18754C16.5621 2.7662 16.4927 2.21153 16.1634 1.74353C15.7301 1.11086 14.8027 0.833523 14.0661 1.10219Z" />
            <path d="M0.0173334 14.8043L0.0433335 21.1136H2.42668C5.13936 21.1136 5.21736 21.0963 5.55536 20.4116C5.72003 20.0649 5.72869 19.9089 5.70269 14.6829C5.67669 8.72023 5.71136 9.04956 5.08736 8.67689C4.82736 8.51223 4.63669 8.50356 2.40068 8.50356H0L0.0173334 14.8043Z" />
        </g>
    );
}
