import React from 'react';
import CustomBlock from '../CustomBlock/CustomBlock';
import ChooseChannelsBlock from './ChooseChannelsBlock';

const ChooseChannelsWrapper = ({removeBlock, interactive, canEdit}) => (
    <CustomBlock
        onDelete={value => removeBlock(value)}
        disableActions={interactive || !canEdit}
        autoWidth
        className="choose-channels-wrapper">
        <ChooseChannelsBlock interactive={interactive} />
    </CustomBlock>
);

export default ChooseChannelsWrapper;
