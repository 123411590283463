import React from 'react';

const Video = () => (
    <path
        d="M4.82758621,24 C4.69517241,24 4.56193103,23.9714999 4.44110345,23.9129997 C4.16965517,23.7832493 4,23.5282485 4,23.2499976 L4,0.749925919 C4,0.471675032 4.16965517,0.21667422 4.44110345,0.0869238062 C4.71255172,-0.0435766096 5.04027586,-0.0255765523 5.29434483,0.130423945 L23.5012414,11.3804598 C23.7263448,11.5199602 23.862069,11.751711 23.862069,11.9999618 C23.862069,12.2482126 23.7263448,12.4799633 23.5004138,12.6194637 L5.29351724,23.8694996 C5.15365517,23.9557499 4.99062069,24 4.82758621,24 Z"
        fill="currentColor"
        fillRule="nonzero"
    />
);

export default Video;
