import css from 'styled-jsx/css';
import * as theme from 'lib/styles/theme';
import * as colors from 'lib/styles/colors';

const root = '.md-RichEditor-root';
const block = '.md-block';

export default css`
    ${root} {
        background: none;
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSize}rem;
        line-height: 2.2rem;
        padding: 0 0 5rem 0;
    }

    ${root} .md-block-paragraph${block},
    ${root} h1${block},
    ${root} h2${block},
    ${root} h3${block},
    ${root} blockquote${block},
    ${root} ul${block},
    ${root} ol${block} {
        padding: 0;
        margin: 0;
    }

    ${root} h1${block},
    ${root} h2${block},
    ${root} h3${block} {
        font-weight: ${theme.fwBold};
    }

    ${root} h1${block} {
        margin-bottom: 5.5rem;
        font-size: 3.2rem;
        line-height: 3.7rem;
    }

    ${root} h2${block} {
        font-size: 2rem;
        line-height: 2.3rem;
        margin-bottom: 4.5rem;
    }

    ${root} blockquote${block} {
        background-color: transparent;
        border: 0;
    }

    ${root} blockquote${block}  > div {
        margin: 3.8rem 4rem;
        padding-left: 2.5rem;

        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: ${theme.fwBold};
        font-style: italic;
        border-left: solid 0.2rem ${colors.brand.base};

        background-color: transparent;
    }

    ${root} ul${block},
    ${root} ol${block} {
        margin-left: 3rem;
    }

    ${root} a {
        color: ${colors.brand.base};
        text-decoration: none;
    }
`;
