import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M22.3021 6.34656C22.4484 6.5087 22.5294 6.71935 22.5294 6.93776L22.5294 20.6378C22.5294 20.6382 22.5294 20.6385 22.5294 20.6389C22.5294 20.6389 22.5294 20.639 22.5294 20.639C22.5299 21.08 22.4434 21.5167 22.275 21.9242C22.1065 22.3321 21.8592 22.7028 21.5472 23.0151C21.2353 23.3273 20.8649 23.575 20.4571 23.744C20.0493 23.913 19.6123 24 19.1709 24H4.36198C3.47045 24 2.61535 23.646 1.98483 23.0157C1.3543 22.3854 1 21.5305 1 20.639V3.36098C1 2.46947 1.3543 1.61456 1.98483 0.98428C2.61535 0.354015 3.47045 0 4.36198 0H16.1815C16.4312 0 16.6692 0.105796 16.8366 0.291156L22.3021 6.34656ZM21.6471 20.639V6.93776L16.1815 0.882353H4.36198C3.70434 0.882353 3.07364 1.14349 2.60862 1.60832C2.1436 2.07316 1.88235 2.7036 1.88235 3.36098V20.639C1.88235 21.2964 2.1436 21.9268 2.60862 22.3917C3.07364 22.8565 3.70434 23.1176 4.36198 23.1176H19.1709C19.4963 23.1176 19.8186 23.0535 20.1193 22.9289C20.4199 22.8043 20.693 22.6217 20.923 22.3915C21.153 22.1613 21.3353 21.888 21.4596 21.5873C21.5838 21.2866 21.6475 20.9644 21.6471 20.639Z" />
            <path d="M16.1765 0.455292C16.4201 0.455292 16.6176 0.652813 16.6176 0.896468V4.34073C16.6368 4.87499 16.866 5.38024 17.2556 5.74659C17.6468 6.11448 18.168 6.31228 18.7048 6.29666C18.7091 6.29653 18.7134 6.29647 18.7176 6.29647H21.7847C22.0284 6.29647 22.2259 6.49399 22.2259 6.73765C22.2259 6.9813 22.0284 7.17882 21.7847 7.17882H18.7238C17.9559 7.19942 17.2109 6.91573 16.6511 6.3894C16.0898 5.86155 15.7605 5.1327 15.7355 4.36254C15.7354 4.35778 15.7353 4.35301 15.7353 4.34824V0.896468C15.7353 0.652813 15.9328 0.455292 16.1765 0.455292Z" />
            <path d="M5.57413 13.733C5.57413 13.4893 5.77165 13.2918 6.0153 13.2918H17.2918C17.5354 13.2918 17.7329 13.4893 17.7329 13.733C17.7329 13.9766 17.5354 14.1741 17.2918 14.1741H6.0153C5.77165 14.1741 5.57413 13.9766 5.57413 13.733Z" />
            <path d="M5.57413 18.5012C5.57413 18.2575 5.77165 18.06 6.0153 18.06H12.6541C12.8978 18.06 13.0953 18.2575 13.0953 18.5012C13.0953 18.7448 12.8978 18.9424 12.6541 18.9424H6.0153C5.77165 18.9424 5.57413 18.7448 5.57413 18.5012Z" />
        </g>
    );
}
