import {combineReducers} from 'redux';

import {
    TIMEZONES_REQUEST,
    TIMEZONES_SUCCESS,
    TIMEZONES_FAILURE,
} from '../actions/timezones';

const isFetching = (state = true, {type}) => {
    switch (type) {
        case TIMEZONES_REQUEST:
            return true;
        case TIMEZONES_SUCCESS:
        case TIMEZONES_FAILURE:
            return false;
        default:
            return state;
    }
};

const data = (state = [], {type, payload}) => {
    switch (type) {
        case TIMEZONES_SUCCESS:
            return payload.data;
        default:
            return state;
    }
};

export default combineReducers({
    isFetching,
    data,
});
