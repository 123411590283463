/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import RRuleGenerator from 'components/RRuleGenerator';
import {Translate} from 'react-localize-redux';

const RecurrentSelect = ({trigger, onChange}) => {
    const onRRuleChange = rrule => {
        const parameters = (trigger && trigger.parameters) || {};
        const newTrigger = {...trigger, parameters: {...parameters, rrule}};
        onChange(newTrigger);
    };

    return (
        <div className="form__group">
            <label className="label" htmlFor="default_audience">
                <Translate id="editor.form.recurrence.label" />
            </label>
            <RRuleGenerator
                defaultValue={
                    (trigger.parameters && trigger.parameters.rrule) ||
                    'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1'
                }
                onChange={onRRuleChange}
            />
        </div>
    );
};
RecurrentSelect.propTypes = {
    trigger: PropTypes.shape({
        parameters: PropTypes.shape({rrule: PropTypes.string}),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default RecurrentSelect;
