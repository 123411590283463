import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M3.6225 5.15034C3.1275 5.45034 3.15 5.15784 3.15 11.3753V17.0003H20.85V11.3528C20.85 5.89284 20.8425 5.70534 20.7 5.47284C20.4 4.97034 20.955 5.00034 11.985 5.00034C4.065 5.00034 3.855 5.00784 3.6225 5.15034ZM19.275 15.4253H4.725V6.58284L4.8975 6.56034C4.9875 6.54534 8.2575 6.53784 12.1725 6.53784H19.275V15.4253Z" />
            <path d="M0 18.3578C0 18.8303 0.18 19.2278 0.48 19.4078C0.705 19.5428 1.0875 19.5503 12 19.5503C23.19 19.5503 23.2875 19.5503 23.535 19.4003C23.8425 19.2128 24 18.8528 24 18.3578V17.9828L18.7875 17.9753H13.575V18.2003C13.575 18.3353 13.5225 18.4478 13.4325 18.4928C13.35 18.5378 12.7425 18.5603 11.9325 18.5528C10.47 18.5303 10.425 18.5153 10.425 18.1403V17.9753H5.2125L0 17.9828V18.3578Z" />
        </g>
    );
}
