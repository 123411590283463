import React from 'react';

const ArrowLEft = () => (
    <g stroke="currentColor" fill="none">
        <line x1="19" y1="12" x2="5" y2="12" />
        <polyline points="12 19 5 12 12 5" />
    </g>
);

export default ArrowLEft;
