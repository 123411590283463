import React from 'react';
import {Translate} from 'react-localize-redux';
import tinyColor from 'tinycolor2';
import * as theme from 'styles/theme';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import {pluralize} from 'lib/helpers/locale';

const WeekInfo = ({data}) => {
    const {planned_concept: concept, planned_messages: messages} = data;

    return (
        <Branding.Consumer>
            {colors => (
                <div className="week-info">
                    <div className="content">
                        <div className="planned">
                            <span className="count">{messages}</span>
                            <span className="weeklabel">
                                <Translate
                                    id={pluralize(
                                        'planning.planned_message',
                                        messages,
                                    )}
                                />
                            </span>
                        </div>
                        <div className="planned">
                            <span className="count">{concept}</span>
                            <span className="weeklabel">
                                <Translate
                                    id={pluralize(
                                        'planning.planned_concept',
                                        messages,
                                    )}
                                />
                            </span>
                        </div>
                    </div>

                    <style jsx>
                        {`
                            .week-info {
                                display: flex;
                                flex-direction: column;
                                flex: 1;
                                width: 12.5rem;
                                justify-content: space-between;
                                border-top: solid 0.1rem ${theme.colorBorder};
                                padding: 0.8rem 1rem;
                            }

                            .content {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                            }

                            .week {
                                font-size: ${theme.fontSizeSmall}rem;
                            }

                            .planned {
                                display: flex;
                                align-items: center;
                            }

                            .count {
                                font-weight: ${theme.fwBold};
                                font-size: ${theme.fontSize}rem;
                                color: ${theme.colorText};
                                text-align: center;
                                margin-right: 1rem;
                                line-height: 1;
                            }

                            .weeklabel {
                                font-size: ${theme.fontSizeSmaller}rem;
                                line-height: 1.2rem;
                                max-width: 6rem;
                                color: ${colors.colorGrey400};
                            }

                            .messages {
                                display: flex;
                                justify-content: space-between;
                                font-size: 1.2rem;
                                color: ${tinyColor(colors.text.light)
                                    .setAlpha(0.5)
                                    .toRgbString()};
                            }

                            @media (min-height: 680px) {
                                .planned {
                                    margin: 0.2rem 0;
                                }

                                .label {
                                    font-size: ${theme.fontSizeSmaller}rem;
                                    line-height: 1.4rem;
                                }
                            }

                            @media (min-height: 880px) {
                                .planned {
                                    margin: 0.5rem 0;
                                }
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

export default WeekInfo;
