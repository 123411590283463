import React from 'react';

export default function () {
    return (
        <path
            d="M9 17L14 12L9 7"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
