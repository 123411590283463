import React from 'react';
import classNames from 'classnames';
import {Translate} from 'react-localize-redux';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import Icon, {bin, addFilter} from 'lib/components/Icon';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import Select from 'components/util/Select';
import Tooltip from 'components/util/Tooltip';

const SegmentationSelect = ({
    className,
    selectedType,
    typeOptions,
    onDelete,
    onAddSubFilter,
    onTypeChange,
    onPrimaryChange,
    onSecondaryChange,
    primaryValue,
    primaryOptions,
    secondaryValue,
    secondaryOptions,
}) => {
    const filterHasBeenDeleted =
        typeof primaryValue === 'undefined' ||
        typeof secondaryValue === 'undefined';
    const groupHasNoSegments =
        typeof secondaryOptions === 'undefined' ||
        secondaryOptions.length === 0;
    const isInvalid = filterHasBeenDeleted || groupHasNoSegments;

    return (
        <div className={classNames('segmentationSelect', className)}>
            {isInvalid && (
                <div className="invalidSelection">
                    {!groupHasNoSegments && filterHasBeenDeleted && (
                        <p>
                            <Translate id="segmentation.filterHasBeenDeleted" />
                        </p>
                    )}
                    {groupHasNoSegments && (
                        <p>
                            <Translate id="segmentation.groupHasNoSegments" />
                        </p>
                    )}
                    <p>
                        <Translate id="segmentation.changeOrDeleteFilter" />
                    </p>
                </div>
            )}

            <div className={classNames('content', {isInvalid})}>
                <div className="selectWrap typeSelect">
                    <Select
                        clearable={false}
                        value={selectedType.value}
                        options={typeOptions}
                        onChange={({value}) => onTypeChange(value)}
                        hideIndicator
                    />
                </div>

                <div className="selectWrap">
                    <Select
                        clearable={false}
                        value={primaryValue ? primaryValue.value : ''}
                        options={primaryOptions}
                        onChange={({value}) => onPrimaryChange(value)}
                    />
                </div>

                <div className="selectWrap">
                    <Select
                        clearable={false}
                        value={secondaryValue ? secondaryValue.value : ''}
                        options={secondaryOptions}
                        onChange={({value}) => onSecondaryChange(value)}
                    />
                </div>

                <Translate>
                    {({translate}) => (
                        <div className="actions">
                            {onAddSubFilter && (
                                <div className="action">
                                    <Tooltip
                                        title={translate(
                                            'segmentation.addSubFilter',
                                        )}>
                                        <ButtonWithoutStyle
                                            onClick={onAddSubFilter}>
                                            <Icon name={addFilter} />
                                        </ButtonWithoutStyle>
                                    </Tooltip>
                                </div>
                            )}

                            {onDelete && (
                                <div className="action">
                                    <Tooltip title={translate('global.delete')}>
                                        <ButtonWithoutStyle onClick={onDelete}>
                                            <Icon name={bin} />
                                        </ButtonWithoutStyle>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </Translate>
            </div>

            <style jsx>
                {`
                    .segmentationSelect {
                        border: solid 0.1rem ${colors.border};
                        border-radius: ${theme.borderRadius}rem;
                        background: ${colors.pureWhite};
                    }

                    .content {
                        display: flex;
                    }

                    .content.isInvalid {
                        border-top: solid 0.1rem ${colors.border};
                    }

                    .selectWrap {
                        min-width: 18rem;
                        margin-right: ${theme.spacing}rem;
                    }

                    .typeSelect {
                        min-width: 7.5rem;
                        padding-left: ${theme.spacing}rem;
                        padding-right: ${theme.spacing}rem;
                        background: ${colors.grey[100]};
                        border-top-left-radius: ${theme.borderRadius}rem;
                        border-bottom-left-radius: ${theme.borderRadius}rem;
                    }

                    .actions {
                        margin-left: auto;
                        padding-right: ${theme.spacing}rem;
                        display: flex;
                        align-items: center;
                    }

                    .action {
                        margin-left: ${theme.spacingSmall}rem;
                    }

                    .selectWrap,
                    .invalidSelection {
                        padding-top: ${theme.spacing}rem;
                        padding-bottom: ${theme.spacing}rem;
                    }

                    .invalidSelection {
                        padding-left: ${theme.spacing}rem;
                        color: ${colors.danger.base};
                    }
                `}
            </style>
        </div>
    );
};

export default SegmentationSelect;
