import React from 'react';
import {Translate} from 'react-localize-redux';
import RogerLogo from 'assets/logo.svg';
import Landing from 'components/layout/Landing';
import LandingContent from 'components/layout/LandingContent';

const AppNeedsUpdate = () => (
    <Landing>
        <LandingContent
            image={<img src={RogerLogo} alt="logo" className="logo" />}
            title={<Translate id="global.not-up-to-date" />}
            description={<Translate id="global.please-refresh" />}
        />
    </Landing>
);

export default AppNeedsUpdate;
