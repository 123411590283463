import React from 'react';
import Lottie from 'react-lottie';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../animations/loading.json'),
};

const Loading = ({color, size}) => (
    <div className="loading">
        <Lottie
            options={defaultOptions}
            height={size}
            width={size}
            isClickToPauseDisabled
        />

        <style jsx>
            {`
                .loading :global(path) {
                    stroke: ${color};
                }
            `}
        </style>
    </div>
);

export default Loading;
