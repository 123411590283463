import React from 'react';

const SmileySolid = () => (
    <path
        fill="currentColor"
        fillRule="nonzero"
        d="M12,24 C5.37258301,24 0,18.627417 0,12 C0,5.37258301 5.37258301,0 12,0 C18.627417,0 24,5.37258301 24,12 C24,18.627417 18.627417,24 12,24 Z M8.27764179,9.38074526 C9.29205867,9.38074526 10.114406,8.56015672 10.114406,7.54790949 C10.114406,6.53566221 9.29205867,5.71507368 8.27764179,5.71507368 C7.26322491,5.71507368 6.44087756,6.53566221 6.44087756,7.54790949 C6.44087756,8.56015672 7.26322491,9.38074526 8.27764179,9.38074526 Z M15.2232026,9.38074526 C16.2376195,9.38074526 17.0599669,8.56015672 17.0599669,7.54790949 C17.0599669,6.53566221 16.2376195,5.71507368 15.2232026,5.71507368 C14.2087858,5.71507368 13.3864384,6.53566221 13.3864384,7.54790949 C13.3864384,8.56015672 14.2087858,9.38074526 15.2232026,9.38074526 Z M6.54378099,15.0827826 C6.1292061,14.6550585 5.4769416,16.0693102 5.8909736,16.4960985 C9.13694697,19.8435256 14.4610758,19.8617476 17.7312821,16.5403237 C18.1487688,16.1162933 17.4961589,14.7026326 17.0784253,15.1270078 C14.1526616,18.0981264 9.4451637,18.0747333 6.54378099,15.0827826 Z"
    />
);

export default SmileySolid;
