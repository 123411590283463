import {
    COMPANY_VIEW_REQUEST,
    COMPANY_VIEW_SUCCESS,
    COMPANY_VIEW_FAILURE,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_LOGO_UPDATE_SUCCESS,
    COMPANY_BANNER_UPDATE_SUCCESS,
    COMPANY_BANNER_DELETE_SUCCESS,
} from '../actions/company';

const INITIAL_STATE = {
    data: {
        languages: [],
        max_file_upload: 104857600,
        branding: {},
        name: '',
        slug: '',
    },
    isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPANY_VIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case COMPANY_UPDATE_SUCCESS:
        case COMPANY_LOGO_UPDATE_SUCCESS:
        case COMPANY_VIEW_SUCCESS:
        case COMPANY_BANNER_UPDATE_SUCCESS:
        case COMPANY_BANNER_DELETE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            };
        case COMPANY_VIEW_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};
