export default {
    MEDIA_BLOCK: 'MediaBlock',
    SURVEY_BLOCK: 'SurveyBlock',
    EMBED_BLOCK: 'EmbedBlock',
    CHOOSE_CHANNELS_BLOCK: 'ChooseChannels',
};

export const ATOMIC = 'atomic';
export const SURVEY_TYPE_FREE_TEXT = 'free-text';
export const SURVEY_TYPE_OPTION = 'option';
