import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import SearchInput from 'lib/components/SearchInput';
import {getById} from 'lib/store/reducers/helpers/byId';
import CustomBlocks, {ATOMIC} from 'lib/constants/customBlocks';
import actions from 'store/actions';
import MediaGallery from 'components/MediaGallery';
import useTranslate from 'components/hooks/useTranslate';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';

const UnsplashSidebar = ({onImportComplete}) => {
    const [isImporting, setIsImporting] = useState(false);
    const {addBlock, updateBlockWithKey} = useManageEditorBlocks();
    const {translate} = useTranslate();
    const sidebarData = useSelector(
        ({editor: {sidebarData: sidebarDataFromState}}) => sidebarDataFromState,
    );
    const unsplashItems = useSelector(
        ({
            media: {
                external: {unsplash},
            },
        }) => unsplash,
    );
    const {
        byId: mediaItems,
        paginated: {pages, pageCount, filters, isFetching},
    } = unsplashItems;

    const dispatch = useDispatch();
    const fetchUnsplashItems = fetchFilters =>
        dispatch(actions.media.external.fetchUnsplashItems(fetchFilters));
    const importUnsplashItem = item =>
        dispatch(actions.media.external.importUnsplashItem(item));

    useEffect(() => {
        fetchUnsplashItems({
            filters: {
                query: filters.query || '',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onQueryChange = newFilters => {
        fetchUnsplashItems({
            filters: Object.assign({}, filters, newFilters),
        });
    };
    const onSearchQueryChange = query => {
        onQueryChange({query});
    };
    const fetchNextUnsplashItemsPage = () => {
        if (!isFetching) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetchUnsplashItems({
                    filters: {
                        query: filters.query || '',
                    },
                    page,
                });
            }
        }
    };
    const onMediaItemClick = async mediaItem => {
        setIsImporting(true);
        const {payload} = await importUnsplashItem(mediaItem);
        setIsImporting(false);
        onImportComplete();

        const {blockKey} = sidebarData;
        const importedMediaItem = payload.data;
        const blockData = {
            type: CustomBlocks.MEDIA_BLOCK,
            mediaItemId: importedMediaItem.id,
        };
        if (!blockKey) {
            await addBlock(blockData, ATOMIC);
        } else {
            await updateBlockWithKey(blockData, blockKey);
        }
        dispatch(actions.editor.setActiveSidebar(''));
    };

    const items = [];
    if (pages.length) {
        pages.forEach(page => {
            page.forEach(mediaItemId => {
                const mediaItem = getById(mediaItems, mediaItemId);
                items.push(mediaItem);
            });
        });
    }

    return [
        <div key={1}>
            <Title>
                {translate('editor.sidebar.source', {source: 'unsplash'})}
            </Title>
            <SearchInput
                placeholder={translate('editor.sidebar.search_images')}
                value={filters.query}
                onChange={onSearchQueryChange}
            />
        </div>,
        <MediaGallery
            key={2}
            isFetching={isFetching}
            isImporting={isImporting}
            media={items}
            onListEnd={fetchNextUnsplashItemsPage}
            onMediaClick={onMediaItemClick}
            unsplash
        />,
    ];
};

const Title = styled.h2(
    ({theme}) => `
    margin-bottom: ${theme.spacing}rem;
`,
);

export default UnsplashSidebar;
