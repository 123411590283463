import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    MESSAGES_COMMENTS_REQUEST,
    MESSAGES_COMMENTS_SUCCESS,
    MESSAGES_COMMENTS_FAILURE,
    MESSAGES_COMMENT_DELETE_REQUEST,
    MESSAGES_COMMENT_DELETE_SUCCESS,
    MESSAGES_COMMENT_DELETE_FAILURE,
} from '../../actions/messages/comments';

const listActions = [
    MESSAGES_COMMENTS_REQUEST,
    MESSAGES_COMMENTS_SUCCESS,
    MESSAGES_COMMENTS_FAILURE,
];

const updateActions = [
    MESSAGES_COMMENT_DELETE_REQUEST,
    MESSAGES_COMMENT_DELETE_SUCCESS,
    MESSAGES_COMMENT_DELETE_FAILURE,
];

const messageComments = combineReducers({
    byId: createById({
        listActions,
        updateActions,
    }),
    paginated: paginate({
        listActions,
        requestFilterKey: 'filters',
    }),
});

export default messageComments;
