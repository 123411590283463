import React, {useEffect} from 'react';
import {format, subWeeks, addDays} from 'date-fns';
import {useSelector, useDispatch} from 'react-redux';
import {getById} from 'lib/store/reducers/helpers/byId';
import {fetchMessagesSurveys} from 'lib/store/actions/surveys/index';
import {STATUS_PUBLISHED} from 'lib/store/actions/messages/index';
import actions from 'store/actions';

import SurveyList from './SurveyList';

const TODAY = new Date();

const SurveyListWithData = () => {
    const messagesById = useSelector(({surveys}) => surveys.byId);
    const pages = useSelector(({surveys}) => surveys.paginated.pages);
    const isFetching = useSelector(({surveys}) => surveys.paginated.isFetching);
    const isFailed = useSelector(({surveys}) => surveys.paginated.isFailed);
    const pageCount = useSelector(({surveys}) => surveys.paginated.pageCount);

    const dispatch = useDispatch();
    const fetchSurveys = (page = 1) => {
        dispatch(
            fetchMessagesSurveys({
                filters: {
                    status: STATUS_PUBLISHED,
                    from: format(subWeeks(TODAY, 8), 'YYYY-MM-DD'),
                    to: format(addDays(TODAY, 1), 'YYYY-MM-DD'),
                    sort: {
                        published_at: 'desc',
                    },
                },
                page,
            }),
        );
    };
    const acceptAnswers = id => dispatch(actions.surveys.acceptAnswers(id));
    const refuseAnswers = id => dispatch(actions.surveys.refuseAnswers(id));
    const setAcceptAnswers = (id, value) => {
        if (value) {
            acceptAnswers(id);
        }
        refuseAnswers(id);
        fetchSurveys();
    };

    let surveys = {};
    if (pages && pages.length) {
        surveys = pages.reduce((accumulator, page) => {
            const newValues = accumulator;
            page.forEach(messageId => {
                const survey = getById(messagesById, messageId);
                const date = format(survey.published_at, 'DD-MM-YYYY');

                // Create new array if empty
                if (!accumulator[date]) {
                    newValues[date] = [];
                }

                // Insert new survey
                newValues[date] = [...accumulator[date], survey];
            });
            return newValues;
        }, {});
    }

    const onScrollEnd = () => {
        if (!isFetching && !isFailed) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetchSurveys(page);
            }
        }
    };

    useEffect(() => {
        fetchSurveys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SurveyList
            surveys={surveys}
            onAcceptAnswersChange={setAcceptAnswers}
            isFetching={isFetching}
            onScrollEnd={onScrollEnd}
        />
    );
};

export default SurveyListWithData;
