import React from 'react';

const Scale = () => (
    <g fill="currentColor" fillRule="nonzero">
        <g transform="translate(0.000000, 7.000000)">
            <path
                d="M15.6,-7 L8.4,-7 C8.002335,-7 7.68,-6.66423437 7.68,-6.25 L7.68,16.25 C7.68,16.6642343 8.002335,17 8.4,17 L15.6,17 C15.997665,17 16.32,16.6642343 16.32,16.25 L16.32,-6.25 C16.32,-6.66423437 15.99762,-7 15.6,-7 Z M14.4,16 L8.64,16 L8.64,13.75 L12.24,13.75 L12.24,12.25 L8.64,12.25 L8.64,10 L10.8,10 L10.8,8.5 L8.64,8.5 L8.64,6.25 L12.24,6.25 L12.24,4.75 L8.64,4.75 L8.64,2.5 L10.8,2.5 L10.8,1 L8.64,1 L8.64,-1.25 L12.24,-1.25 L12.24,-2.75 L8.64,-2.75 L8.64,-5 L14.4,-5 L14.4,16 Z"
                transform="translate(12.000000, 5.000000) rotate(90.000000) translate(-12.000000, -5.000000) "
            />
        </g>
    </g>
);

export default Scale;
