import React, {createContext, useContext, useState} from 'react';
import {GET} from 'lib/store/services/index';
import {useDispatch} from 'react-redux';
import {CALL_API} from 'lib/constants/api';
import {uniqBy} from 'lodash';

// Provider component
import PropTypes from 'prop-types';

// Create context
const AdminInboxContext = createContext();

// Custom hook that uses the context
function useAdminInbox() {
    const context = useContext(AdminInboxContext);
    if (context === undefined) {
        throw new Error(
            'useAdminInbox must be used within a AdminInboxProvider',
        );
    }
    return context;
}

function AdminInboxProvider({children}) {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(true);

    const value = {
        data,
        loading,
        getMessageDetails: async uuid => {
            const result = await dispatch({
                [CALL_API]: {
                    endpoint: `/admin-inbox/messages/${uuid}`,
                    method: GET,
                },
            });
            return result;
        },
        getData: async (
            page = pagination ? pagination.current_page + 1 : 0,
            {query = '', limit = 20} = {},
        ) => {
            if (page === pagination?.last_page + 1) {
                return undefined;
            }
            const result = await dispatch({
                [CALL_API]: {
                    endpoint: '/admin-inbox/messages',
                    method: GET,
                    query: {
                        query,
                        page,
                        limit,
                        sort: 'created_at.desc',
                    },
                },
            });
            setPagination(result.payload.meta);
            setData(oldData =>
                uniqBy([...oldData, ...result.payload.data], 'uuid'),
            );
            setLoading(false);
            return result;
        },
        updateAdminInboxConfig: async newData => {
            const result = await dispatch({
                [CALL_API]: {
                    endpoint: '/company',
                    method: 'PUT',
                    data: {admin_inbox: newData},
                },
            });
            return result;
        },
    };
    return (
        <AdminInboxContext.Provider value={value}>
            {children}
        </AdminInboxContext.Provider>
    );
}

AdminInboxProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
export {AdminInboxProvider, useAdminInbox};
