import React, {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {useHistory, useLocation} from 'react-router-dom';
import getUserRoleHelper from 'util/getUserRoleHelper';
import Icon from 'lib/components/Icon';
import {getById} from 'lib/store/reducers/helpers/byId';
import {MODAL_CONFIRM, MODAL_USER_DETAIL_ADMIN} from 'lib/store/actions/modals';
import actions from 'store/actions';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import {
    getSegmentsFromGroupBySlug,
    DEPARTMENTS,
    FUNCTIONS,
} from 'lib/helpers/segmentation';
import {bulkEditUsers} from 'routes/index';
import Users from './Users';

const UsersWithState = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const history = useHistory();

    // Redux state
    const usersById = useSelector(({users}) => users.byId);
    const pages = useSelector(({users}) => users.paginatedViewUsers.pages);
    const filters = useSelector(({users}) => users.paginatedViewUsers.filters);
    const isFetching = useSelector(
        ({users}) => users.paginatedViewUsers.isFetching,
    );
    const isFailed = useSelector(
        ({users}) => users.paginatedViewUsers.isFailed,
    );
    const pageCount = useSelector(
        ({users}) => users.paginatedViewUsers.pageCount,
    );
    const total = useSelector(({users}) => users.paginatedViewUsers.total);
    const translate = useSelector(state => getTranslate(state.localize));
    // Actions
    const dispatch = useDispatch();
    const {search} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const onboarded = searchParams.get('onboarded');
    const notifications = searchParams.get('notifications');

    const fetchUsers = params =>
        dispatch(
            actions.users.fetchUsers(
                params,
                'view-users',
                onboarded,
                notifications,
            ),
        );
    const deleteUser = userId => dispatch(actions.users.deleteUser(userId));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));

    const onQueryChange = newFilters => {
        fetchUsers({
            filters: Object.assign({}, filters, newFilters),
        });
    };

    const onSortChange = sort => {
        onQueryChange({sort});
    };

    const onSearchQueryChange = query => {
        onQueryChange({query});
    };

    const initialFetchUsers = () => {
        onQueryChange({
            query: filters.query || '',
            sort: filters.sort || {},
        });
    };

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetchUsers({
                    filters: {
                        query: filters.query || '',
                        sort: filters.sort || {},
                    },
                    page,
                });
            }
        }
    };

    const onRowClick = userId => {
        const user = getById(usersById, userId);
        setSelectedUser(user);
        setModalVisibility(MODAL_USER_DETAIL_ADMIN, true);
    };

    const onModalSuccess = () => {
        initialFetchUsers();
    };

    const onDelete = (e, messageId) => {
        e.stopPropagation();

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('users.confirm_delete'),
            buttonActions: [
                {
                    label: translate('global.delete'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteUser(messageId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    const onNewUserButtonClick = () => {
        setSelectedUser({});
        setModalVisibility(MODAL_USER_DETAIL_ADMIN, true);
    };

    const onBulkEditClick = () => {
        history.push(bulkEditUsers());
    };

    const onUserEdit = updatedUser => {
        setSelectedUser(updatedUser);
        if (updatedUser === null) {
            setModalVisibility(MODAL_USER_DETAIL_ADMIN, false);
        }
        onModalSuccess();
    };

    const onModalClose = () => {
        setSelectedUser(null);
    };

    const columns = [
        {
            className: 'table__icon',
            key: 'icon',
            renderer: (content, data) => (
                <div
                    className={`avatar ${data.isOnline ? '--online' : '--offline'}`}
                    style={{backgroundImage: `url(${data.avatar})`}}
                    title={
                        data.isOnline ? null : translate('global.userNotActive')
                    }
                    alt="profile"
                />
            ),
        },
        {
            key: 'first_name',
            label: translate('users.firstname'),
            sortable: !filters.query,
        },
        {
            key: 'last_name',
            label: translate('users.lastname'),
            sortable: !filters.query,
        },
        {
            key: 'function',
            label: translate('users.function'),
        },
        {
            key: 'department',
            label: translate('users.department'),
        },
        {
            key: 'role',
            label: translate('users.role'),
        },
        {
            key: 'delete',
            renderer: (content, data) => (
                <ButtonWithoutStyle onClick={e => onDelete(e, data)}>
                    <Icon className="table__remove" name="bin" />
                </ButtonWithoutStyle>
            ),
        },
    ];

    let rows = [];
    if (pages.length) {
        rows = [].concat(
            ...pages.map(page =>
                page.map(userId => {
                    const user = getById(usersById, userId);
                    const functions =
                        getSegmentsFromGroupBySlug(
                            user.segment_groups,
                            FUNCTIONS,
                        ).join(', ') || '';
                    const departments =
                        getSegmentsFromGroupBySlug(
                            user.segment_groups,
                            DEPARTMENTS,
                        ).join(', ') || '';

                    return {
                        key: user.id,
                        data: [
                            {
                                className: 'table__icon',
                                data: {
                                    isOnline: user.is_online,
                                    avatar: getUrlBySize(user.avatar, THUMB),
                                },
                            },
                            {
                                className: 'font-weight-bold',
                                content: user.first_name,
                            },
                            {
                                className: 'font-weight-bold',
                                content: user.last_name,
                            },
                            {content: functions},
                            {content: departments},
                            {
                                className: 'text-uppercase',
                                content:
                                    (user.roles &&
                                        user.roles[0] &&
                                        user.roles[0].label) ||
                                    '',
                            },
                            {
                                data: userId,
                            },
                        ],
                    };
                }),
            ),
        );
    }

    const selectedUserRole =
        (selectedUser && selectedUser.role) || getUserRoleHelper(selectedUser);

    useEffect(() => {
        initialFetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Users
            filters={filters}
            isFetching={isFetching}
            onBulkEditClick={onBulkEditClick}
            onNewUserButtonClick={onNewUserButtonClick}
            onSearchQueryChange={onSearchQueryChange}
            rows={rows}
            columns={columns}
            selectedUserRole={selectedUserRole}
            selectedUser={selectedUser}
            onSortChange={onSortChange}
            onTableEnd={onTableEnd}
            onRowClick={onRowClick}
            onUserEdit={onUserEdit}
            onModalClose={onModalClose}
            total={total}
        />
    );
};

export default UsersWithState;
