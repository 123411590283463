import './AdminMessageReads.css';
import React, {useEffect, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {Pie, PieChart, Tooltip} from 'recharts';
import Select from 'components/util/Select';

const AdminMessageReads = ({data}) => {
    const [mappedPieData, setMappedPieData] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [activePeriod, setActivePeriod] = useState();

    useEffect(() => {
        const periodOptionsLocal = [];

        Object.entries(data).forEach(([key]) => {
            const dateTimeSplit = key.split(' ');
            const date = dateTimeSplit[0];

            if (!periodOptionsLocal.find(option => option.value === date))
                periodOptionsLocal.push({value: date, label: date});

            setPeriodOptions(periodOptionsLocal);
            setActivePeriod(
                periodOptionsLocal.length > 0
                    ? periodOptionsLocal[0].value
                    : null,
            );
        });
    }, [data]);

    useEffect(() => {
        const mappedData = [];

        Object.entries(data).forEach(([key, value]) => {
            const dateTimeSplit = key.split(' ');
            const date = dateTimeSplit[0];
            const hour = dateTimeSplit[1];
            const hourGranular = `${hour.split(':')[0]}:00`;

            if (date === activePeriod) {
                if (!mappedData[hourGranular])
                    mappedData[hourGranular] = {name: hourGranular, value: 0};
                mappedData[hourGranular].value += value.count;
            }
        });

        setMappedPieData(Object.values(mappedData || {}));
    }, [data, activePeriod]);

    const onPeriodChange = day => {
        setActivePeriod(day.value);
    };

    const renderLegendDay = hour => (
        <div className="admin-message-reads-day">
            <p className="admin-message-reads-day-title">
                <span>{`${hour.name}:`}</span> {`${hour.value} reads`}
            </p>
        </div>
    );

    return (
        <div className="admin-message-reads-modal">
            <div className="row admin-message-reads-modal-container">
                <div className="col-md-5 admin-message-read-info">
                    <h2>
                        <Translate id="dashboard.per_hour" />
                    </h2>

                    <div className="admin-message-read">
                        {mappedPieData.map(item => renderLegendDay(item))}
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="admin-message-read-chart">
                        <div className="admin-message-read-chart-select">
                            <Select
                                name="period-selector"
                                aria-label="Days"
                                value={activePeriod}
                                onChange={onPeriodChange}
                                options={periodOptions}
                            />
                        </div>

                        <div className="admin-message-read-chart-chart-wrapper">
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={mappedPieData}
                                    nameKey="name"
                                    dataKey="value"
                                    innerRadius={90}
                                    outerRadius={150}
                                    animationDuration={600}
                                    fill="#017CFF"
                                    cx="50%"
                                    cy="50%"
                                />
                                <Tooltip />
                            </PieChart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminMessageReads;
