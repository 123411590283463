import React from 'react';

export default function () {
    return (
        <>
            <path
                d="M3.14386 12.2149C2.01491 12.6693 0.942982 14.1846 0.392544 16.1039L0 17.4737H2.54737C4.96886 17.4737 5.12281 17.4079 5.64386 16.1491C5.94561 15.4206 6.79386 14.3184 7.52851 13.7004C8.60175 12.7974 8.74123 12.4987 8.23903 12.1811C7.45219 11.6829 4.41403 11.7031 3.14386 12.2149ZM15.761 12.1811C15.2588 12.4987 15.3982 12.7974 16.4715 13.7004C17.2061 14.3184 18.0544 15.4206 18.3561 16.1491C18.8772 17.4079 19.0311 17.4737 21.4526 17.4737H24L23.6075 16.1039C23.0338 14.1039 21.9741 12.6675 20.7206 12.1908C19.336 11.6645 16.5855 11.6592 15.761 12.1811ZM8.35526 13.5377C6.96623 14.3842 6.04474 15.9285 5.40307 18.4846L5.10658 19.6667H18.8934L18.5969 18.4846C17.9553 15.9285 17.0338 14.3842 15.6447 13.5377C13.6991 12.3513 10.3009 12.3513 8.35526 13.5377Z"
                fill="currentColor"
            />
            <path
                d="M16 10.4466C16.7013 9.71732 17.1324 8.72638 17.1324 7.63475C17.1324 6.55232 16.7085 5.5689 16.0177 4.84144C16.6502 4.31597 17.463 4.00001 18.3495 4.00001C20.3662 4.00001 22.0009 5.63479 22.0009 7.65141C22.0009 9.66802 20.3662 11.3028 18.3495 11.3028C17.4547 11.3028 16.635 10.9809 16 10.4466Z"
                fill="currentColor"
            />
            <path
                d="M8.00092 4.85623C7.29963 5.58548 6.86849 6.57642 6.86849 7.66806C6.86849 8.75048 7.29238 9.7339 7.98322 10.4614C7.35071 10.9868 6.53794 11.3028 5.65137 11.3028C3.63476 11.3028 1.99997 9.66801 1.99997 7.6514C1.99998 5.63479 3.63476 4 5.65138 4C6.54623 4 7.36591 4.3219 8.00092 4.85623Z"
                fill="currentColor"
            />
            <path
                d="M16 8.00001C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8.00001C8 5.79087 9.79086 4.00001 12 4.00001C14.2091 4.00001 16 5.79087 16 8.00001Z"
                fill="currentColor"
            />
        </>
    );
}
