import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M18.204 5.762C17.3961 4.94935 16.4351 4.305 15.3765 3.86627C14.318 3.42753 13.1829 3.20311 12.037 3.206C7.23002 3.206 3.31902 7.115 3.31802 11.92C3.316 13.4493 3.71715 14.9521 4.48102 16.277L3.24402 20.793L7.86602 19.581C9.14463 20.2771 10.5772 20.6418 12.033 20.642H12.037C16.842 20.642 20.753 16.732 20.755 11.927C20.7585 10.7818 20.5348 9.64724 20.0969 8.58903C19.659 7.53082 19.0157 6.56995 18.204 5.762Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.095 13.56L13.501 13.157C13.874 12.787 14.464 12.74 14.894 13.042C15.31 13.334 15.686 13.596 16.036 13.84C16.592 14.226 16.659 15.018 16.18 15.496L15.821 15.855"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.14502 8.179L8.50402 7.82C8.61582 7.7076 8.75094 7.62111 8.89983 7.56665C9.04872 7.51219 9.20775 7.49108 9.36569 7.50481C9.52363 7.51854 9.67663 7.56679 9.81388 7.64613C9.95114 7.72548 10.0693 7.83399 10.16 7.964C10.403 8.314 10.665 8.69 10.958 9.106C11.1051 9.31486 11.1742 9.56876 11.1532 9.82336C11.1322 10.078 11.0224 10.3171 10.843 10.499L10.44 10.905"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.821 15.855C14.34 17.329 11.851 16.077 9.88599 14.111"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.888 14.114C7.923 12.148 6.67101 9.66 8.14501 8.179"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.44 10.905C10.7824 11.4344 11.1814 11.925 11.63 12.368L11.632 12.37C12.094 12.832 12.592 13.241 13.095 13.56"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
