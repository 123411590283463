import React from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect, useDispatch} from 'react-redux';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import actions from 'store/actions';
import Widget from '../Widget';
import OnBoarding from './OnBoarding';
import {
    showFailureByKey,
    showSuccessByKey,
} from '../../../lib/store/actions/notifications';

const OnBoardingWithState = ({
    companySettings,
    translate,
    isLoading,
    onBoarding,
    isFetchingOnBoardingExport,
    fetchOnBoardingExport,
    showNotification,
    ...otherProps
}) => {
    const dispatch = useDispatch();

    return (
        <Widget
            {...otherProps}
            title={translate('dashboard.users_active')}
            tooltip={translate('segmentation.sendToActiveInfo')}
            className="on-boarding"
            onArrowClick={async () => {
                try {
                    await fetchOnBoardingExport();
                    dispatch(showSuccessByKey('global.userExportIsStarted'));
                } catch (e) {
                    dispatch(showFailureByKey('global.general_error'));
                }
            }}
            fetchingExport={isFetchingOnBoardingExport}>
            <LoadingWrapper loading={isLoading}>
                {onBoarding !== null ? (
                    <OnBoarding onBoarding={onBoarding} />
                ) : (
                    <p>{translate('global.no_data_available')}</p>
                )}
            </LoadingWrapper>
            <style jsx>
                {`
                    :global(.on-boarding .content) {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                `}
            </style>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    isLoading: state.analytics.userActivity.isLoading,
    onBoarding: state.analytics.userActivity.onBoarding,
    isFetchingOnBoardingExport:
        state.analytics.userActivity.isFetchingOnBoardingExport,
    companySettings: state.company.data.settings,
});

const mapDispatchToProps = dispatch => ({
    fetchOnBoardingExport: () =>
        dispatch(actions.analytics.userActivity.fetchOnBoardingExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OnBoardingWithState);
