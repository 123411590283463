/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import Choice from 'lib/components/Choice/Choice';
import {MODAL_MULTIPLE_CHOICE} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import useSurveyEditor from 'components/hooks/useSurveyEditor';
import {SURVEY_TYPE_FREE_TEXT} from '../lib/constants/customBlocks';

const MultipleChoiceModal = () => {
    const {
        baseForm,
        survey,
        onSave,
        isSaving,
        translate,
        language,
        headerTitleKey,
        buttonTitleKey,
    } = useSurveyEditor();
    const inputRefs = [];

    // Disable save when labels are invalid
    const disabled = survey.options.reduce((acc, {label = {}, type}) => {
        let newValue = acc;

        if (type === SURVEY_TYPE_FREE_TEXT) {
            return acc;
        }

        const translatedLabel = label[language] || null;
        if (
            translatedLabel === null ||
            translatedLabel.length === 0 ||
            translatedLabel.trim().length === 0
        ) {
            newValue = true;
        }

        const translatedQuestion =
            (survey.content.question && survey.content.question[language]) ||
            null;
        if (
            translatedQuestion === null ||
            translatedQuestion.length === 0 ||
            translatedQuestion.trim().length === 0
        ) {
            newValue = true;
        }
        return newValue;
    }, false);

    return (
        <Modal
            name={MODAL_MULTIPLE_CHOICE}
            icon="multipleChoice"
            title={`${translate(headerTitleKey, {name: translate('editor.survey.multiple_choice')})} (${language})`}
            cancelable
            buttonActions={[
                {
                    label: translate(buttonTitleKey),
                    loading: isSaving,
                    callBack: onSave,
                    classes: 'success',
                    disabled,
                },
            ]}>
            <div className="form">
                {baseForm}
                <div className="form__group">
                    <label className="label">
                        {translate('editor.form.choices.label')}
                    </label>
                    {survey.options.map((option, key) => (
                        <Choice
                            survey={survey}
                            key={option.value}
                            index={key}
                            option={option}
                            editable
                            addRef={ref => inputRefs.push(ref)}
                            inputRefs={inputRefs}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default MultipleChoiceModal;
