import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M19.922 8.719c-.14-.469-.375-1.172-.375-2.203C19.547 2.906 16.172 0 12 0S4.453 2.906 4.453 6.516c0 1.03-.234 1.734-.375 2.203-.047.094 0 .187.047.234.047.094.094.14.188.14h15.375c.093 0 .14-.046.187-.14.047-.047.094-.14.047-.234zm-.281 4.172c-.141 0-.282-.047-.422-.047l-.422-.047.14-2.11c0-.093 0-.187-.046-.234a.358.358 0 0 0-.235-.094H5.344a.358.358 0 0 0-.235.094c-.046.047-.046.14-.046.235l.14 2.109-.422.047c-.14 0-.281.047-.422.047-.144.046-.238.187-.187.328 0 .047 0 .14.047.187l.75 2.578.14.141c.235.094.375.328.47.656.28 1.032 1.171 3.657 2.062 4.828.89 1.176 3.75 2.344 4.359 2.395.61-.05 3.469-1.219 4.36-2.395.89-1.172 1.78-3.797 2.062-4.828.094-.328.234-.562.469-.656l.14-.14.75-2.579c.047-.047.047-.14.047-.187.047-.14-.047-.281-.187-.328zm0 0"
        />
    );
}
