import React, {Component} from 'react';

export default class VideoBlock extends Component {
    componentDidMount() {
        if (this.videoElement) {
            this.videoElement.setAttribute('playsinline', true);
        }
    }

    render() {
        const {src} = this.props;

        return (
            <div className="video-block">
                <video
                    controls
                    src={src}
                    ref={ref => {
                        this.videoElement = ref;
                    }}
                />
                <style jsx>
                    {`
                        .video-block {
                            &,
                            video {
                                width: 100%;
                                white-space: normal; // Fix for controls disappearing in firefox
                            }
                        }
                    `}
                </style>
            </div>
        );
    }
}
