import React from 'react';
import styled from 'styled-components';
import {Translate} from 'react-localize-redux';
import {format} from 'date-fns';
import Table from 'components/Table/Table';
import ButtonWithoutStyle from 'lib/components/ButtonWithoutStyle';
import Icon, {bin} from 'lib/components/Icon';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';
import Tooltip from 'components/util/Tooltip';

const Comments = ({
    isFetchingComments,
    onTableEnd,
    sort,
    onSortChange,
    messageComments,
    onDelete,
}) => {
    const columns = [
        {
            className: 'table__icon',
            key: 'icon',
            renderer: (content, data) => (
                <div
                    className={`avatar ${data.isOnline ? '--online' : '--offline'}`}
                    style={{backgroundImage: `url(${data.avatar})`}}
                    alt="profile"
                />
            ),
        },
        {
            key: 'first_name',
            label: <Translate id="users.firstname" />,
            sortable: true,
        },
        {
            key: 'last_name',
            label: <Translate id="users.lastname" />,
            sortable: true,
        },
        {
            key: 'comment',
            label: <Translate id="comment" />,
            renderer: (comment, {deletedAt}) => {
                if (deletedAt === null) {
                    return <Comment deletedAt={deletedAt}>{comment}</Comment>;
                }
                return (
                    <Translate>
                        {({translate}) => (
                            <Tooltip
                                title={`${translate('deleted at')}: ${format(deletedAt, DATE_TIME_FORMAT)}`}>
                                <Comment deletedAt={deletedAt}>
                                    {comment}
                                </Comment>
                            </Tooltip>
                        )}
                    </Translate>
                );
            },
        },
        {
            key: 'created_at',
            label: <Translate id="sent_messages.sent_at" />,
            sortable: true,
        },
        {
            key: 'delete',
            renderer: (content, {deletedAt, id}) => (
                <Actions>
                    {deletedAt === null && (
                        <ButtonWithoutStyle onClick={() => onDelete(id)}>
                            <Icon className="table__remove" name={bin} />
                        </ButtonWithoutStyle>
                    )}
                </Actions>
            ),
        },
    ];

    const rows = messageComments.map(
        ({
            id,
            text,
            deleted_at: deletedAt,
            created_at: createdAt,
            created_by: user,
        }) => ({
            key: id,
            data: [
                {
                    className: 'table__icon',
                    data: {
                        isOnline: user.is_online,
                        avatar: getUrlBySize(user.avatar, THUMB),
                    },
                },
                {
                    className: 'font-weight-bold',
                    content: user.first_name,
                    noWrap: true,
                },
                {
                    className: 'font-weight-bold',
                    content: user.last_name,
                    noWrap: true,
                },
                {
                    style: {
                        maxWidth: '40rem',
                    },
                    content: text,
                    data: {
                        deletedAt,
                    },
                },
                {
                    content: format(createdAt, DATE_TIME_FORMAT),
                    noWrap: true,
                },
                {
                    data: {
                        id,
                        deletedAt,
                    },
                },
            ],
        }),
    );

    return (
        <Container>
            <Table
                rows={rows}
                columns={columns}
                onTableEnd={onTableEnd}
                loading={isFetchingComments}
                emptyTitle={<Translate id="No comments yet" />}
                sort={sort}
                onSortChange={onSortChange}
                height="100%"
            />
        </Container>
    );
};

const Container = styled.div`
    max-height: 100%;
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Comment = styled.div(
    ({theme, deletedAt}) => `
    word-wrap: break-word;
    display: inline-block;
    max-width: 100%;

    ${
        deletedAt
            ? `
        text-decoration: line-through;
        color: ${theme.danger.base};
    `
            : ''
    }
`,
);

export default Comments;
