import React from 'react';
import styled from 'styled-components';

const Input = ({onChange, onNativeChange, ...otherProps}) => (
    <StyledInput
        onChange={e => {
            if (typeof onChange === 'function') {
                onChange(e.target.value);
            }
            if (typeof onNativeChange === 'function') {
                onNativeChange(e);
            }
        }}
        {...otherProps}
    />
);

const StyledInput = styled.input(
    ({theme}) => `
    width: 100%;
    height: 4.2rem;
    border: solid ${theme.colorGrey300} .1rem;
    border-radius: ${theme.borderRadius}rem;
    font-size: ${theme.fontSizeSmall}rem;
    color: ${theme.colorText};
    padding: ${theme.spacingSmall}rem ${theme.spacingLarge}rem;
    line-height: 1.6;

    &:focus {
        outline: none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${theme.colorGrey300};
        font-style: italic;
    }
    :-ms-input-placeholder {
        color: ${theme.colorGrey300};
        font-style: italic;
    }
`,
);

export default Input;
