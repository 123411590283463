import './AdminUsageModal.css';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';
import {PieChart, Pie, Cell} from 'recharts';
import Select from 'components/util/Select';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import Modal from 'modals/Modal/ModalWithState';
import {LAST_30, getMonthUsage, parseOptions} from 'util/analytics';
import Icon from 'lib/components/Icon';

class AdminUsageModal extends Component {
    constructor() {
        super();

        this.state = {
            selectedDate: LAST_30,
        };
    }

    render() {
        const {
            translate,
            appDomains,
            isLoadingAppDomains,
            adminUsage,
            isLoadingAdminUsage,
            langCode,
        } = this.props;
        const {selectedDate} = this.state;

        const dateOptions = parseOptions(
            adminUsage,
            getDateLocale(langCode),
            () => translate('dashboard.last_30_days'),
        );

        let data;
        let total = 0;
        if (selectedDate) {
            data = getMonthUsage(appDomains, adminUsage, selectedDate);

            data.forEach(item => {
                total += item.value || 0;
            });
        }

        return (
            <Modal
                name="adminUsage"
                medium
                menu={[{label: translate('dashboard.admin_usage')}]}>
                <LoadingWrapper
                    loading={isLoadingAppDomains && isLoadingAdminUsage}>
                    <div className="l-admin-usage-modal">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="l-admin-usage-modal__labels">
                                    {data &&
                                        data.map(domain => (
                                            <div
                                                className="l-admin-usage-modal__label"
                                                style={{color: domain.color}}
                                                key={domain.slug}>
                                                <Icon
                                                    name={domain.icon}
                                                    size={12}
                                                />
                                                <span className="l-admin-usage-modal__label__text">
                                                    {domain.name}
                                                </span>
                                                <span className="l-admin-usage-modal__label__count">
                                                    {`${domain.value ? Math.round(100 / (total / domain.value)) : 0}% (${domain.value || 0})`}
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="l-admin-usage-modal__chart">
                                    <Select
                                        placeholder={translate(
                                            'global.filter_date',
                                        )}
                                        options={dateOptions}
                                        value={selectedDate}
                                        onChange={selectedOption => {
                                            this.setState({
                                                selectedDate:
                                                    selectedOption.value,
                                            });
                                        }}
                                        clearable={false}
                                    />
                                    {data && (
                                        <PieChart width={300} height={300}>
                                            <Pie
                                                data={data}
                                                dataKey="value"
                                                innerRadius={90}
                                                outerRadius={150}
                                                animationDuration={600}
                                                strokeWidth={0}>
                                                {data.map((entry, index) => (
                                                    <Cell
                                                        fill={entry.color}
                                                        key={index}
                                                    />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    appDomains: state.appDomains.data,
    isLoadingAppDomains: state.appDomains.isLoading,
    adminUsage: state.analytics.appDomains.domains,
    isLoadingAdminUsage: state.analytics.appDomains.isLoading,
    langCode: getActiveLanguage(state.localize).code,
});

export default connect(mapStateToProps)(AdminUsageModal);
