import {
    USERS_ME_REQUEST,
    USERS_ME_SUCCESS,
    USERS_ME_FAILURE,
    USERS_ME_UPDATE_REQUEST,
    USERS_ME_UPDATE_SUCCESS,
    USERS_ME_UPDATE_FAILURE,
} from '../../../actions/users/me';
import {
    USERS_ME_AVATAR_REQUEST,
    USERS_ME_AVATAR_SUCCESS,
    USERS_ME_AVATAR_FAILURE,
} from '../../../actions/users/me/avatar';

export default (state = null, action) => {
    switch (action.type) {
        case USERS_ME_SUCCESS:
        case USERS_ME_AVATAR_SUCCESS:
        case USERS_ME_UPDATE_SUCCESS:
            return action.payload.ids || null;
        case USERS_ME_REQUEST:
        case USERS_ME_FAILURE:
        case USERS_ME_AVATAR_REQUEST:
        case USERS_ME_AVATAR_FAILURE:
        case USERS_ME_UPDATE_REQUEST:
        case USERS_ME_UPDATE_FAILURE:
        default:
            return state;
    }
};
