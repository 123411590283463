import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {GET} from '../../services';

export const AUDIENCE_SCHEMA = new schema.Entity('audience');

export const LIST_AUDIENCE_FOR_MESSAGE_REQUEST =
    'LIST_AUDIENCE_FOR_MESSAGE_REQUEST';
export const LIST_AUDIENCE_FOR_MESSAGE_SUCCESS =
    'LIST_AUDIENCE_FOR_MESSAGE_SUCCESS';
export const LIST_AUDIENCE_FOR_MESSAGE_FAILURE =
    'LIST_AUDIENCE_FOR_MESSAGE_FAILURE';

export const fetchAudienceForMessage = ({
    messageId,
    page,
    limit,
    followUp,
    filters = {},
    filters: {query, sort, status} = {},
}) => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/audience`,
        method: GET,
        query: {
            query,
            page,
            limit,
            sort,
            status,
            follow_up: followUp,
        },
        filters,
        types: [
            LIST_AUDIENCE_FOR_MESSAGE_REQUEST,
            LIST_AUDIENCE_FOR_MESSAGE_SUCCESS,
            LIST_AUDIENCE_FOR_MESSAGE_FAILURE,
        ],
        listType: status && `audience_${status}`,
        schema: [AUDIENCE_SCHEMA],
    },
});

export const FETCH_AUDIENCE_EXPORT_REQUEST = 'FETCH_AUDIENCE_EXPORT_REQUEST';
export const FETCH_AUDIENCE_EXPORT_SUCCESS = 'FETCH_AUDIENCE_EXPORT_SUCCESS';
export const FETCH_AUDIENCE_EXPORT_FAILURE = 'FETCH_AUDIENCE_EXPORT_FAILURE';

export const fetchAudienceExport = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/audience/export`,
        method: 'GET',
        types: [
            FETCH_AUDIENCE_EXPORT_REQUEST,
            FETCH_AUDIENCE_EXPORT_SUCCESS,
            FETCH_AUDIENCE_EXPORT_FAILURE,
        ],
    },
});

export default {
    fetchAudienceForMessage,
    fetchAudienceExport,
};
