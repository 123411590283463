/* eslint-disable no-underscore-dangle */
import {normalize} from 'normalizr';

export const responseToActionTransformerForSuccessActionTypeAndFailureActionType =
    (successActionType, failureActiontype, listType) => response => {
        let actionToDispatch = response;
        if (response && response.headers && response.json) {
            const contentType = response.headers.get('Content-Type');
            const emptyCodes = [204, 205];

            const createResultAction = payload => {
                if (response.ok) {
                    return {
                        type: successActionType,
                        statusCode: response.status,
                        payload,
                        listType,
                    };
                }
                return {
                    type: failureActiontype,
                    statusCode: response.status,
                    payload,
                    listType,
                };
            };

            if (
                emptyCodes.indexOf(response.status) === -1 &&
                contentType &&
                contentType.indexOf('json') !== -1
            ) {
                return response.json().then(createResultAction);
            }

            actionToDispatch = createResultAction(response);
        }

        return actionToDispatch;
    };

export const actionPayloadNormalizerForSchema =
    schema => successActionType => action => {
        let schemaKey = null;
        let defaultData = null;

        if (
            action.type !== successActionType ||
            typeof schema === 'undefined'
        ) {
            return action;
        }

        if (schema && schema.constructor === Array) {
            schemaKey = schema[0]._key;
            defaultData = [];
        } else {
            schemaKey = schema._key;
        }

        const normalizedData = normalize(action.payload.data, schema);
        const normalizedAction = action;

        normalizedAction.payload.data =
            normalizedData.entities[schemaKey] || defaultData;
        normalizedAction.payload.ids = normalizedData.result;

        return normalizedAction;
    };
