import React from 'react';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';
import {brandingDefaults} from 'lib/styles/colors';

const Count = ({number, text, color}) => (
    <Branding.Consumer>
        {colors => (
            <div className="count">
                <div className="number" style={color && {color}}>
                    {number}
                </div>
                <div className="countText">{text}</div>

                <style jsx>
                    {`
                        .count {
                            margin: 3rem 0;
                        }

                        .number,
                        .countText {
                            font-weight: ${theme.fwBold};
                        }

                        .number {
                            font-size: 7.2rem;
                            color: ${colors.brand.darkContrast};
                        }

                        .countText {
                            color: ${brandingDefaults.brand[900]};
                            text-transform: uppercase;
                            font-size: 1.6rem;
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);

export default Count;
