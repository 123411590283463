import React from 'react';
import format from 'date-fns/format';

const EditorSidebarInformation = ({
    locale,
    translate,
    publishedAt,
    contentType,
    isAlreadyPublished,
}) => (
    <>
        <div className="row form__group">
            <label
                htmlFor="staticContentType"
                className="col-sm-5 label col-form-label">
                {translate('editor.content_type')}:
            </label>
            <div className="col-sm-7">
                <input
                    type="text"
                    readOnly
                    className="form-control-plaintext text-right"
                    id="staticContentType"
                    value={translate(`editor.content_types.${contentType}`)}
                />
            </div>
        </div>
        {isAlreadyPublished && (
            <>
                <div className="row form__group">
                    <label
                        htmlFor="staticContentType"
                        className="col-sm-5 label col-form-label">
                        {translate('global.date_sent')}:
                    </label>
                    <div className="col-sm-7">
                        <input
                            type="text"
                            readOnly
                            className="form-control-plaintext text-right"
                            id="staticContentDate"
                            value={format(
                                publishedAt,
                                'dddd DD MMMM HHumm',
                                locale,
                            )}
                        />
                    </div>
                </div>
            </>
        )}
        <hr />
        <br />
    </>
);

export default EditorSidebarInformation;
