import React from 'react';
import {format} from 'date-fns';
import Datetime from 'react-datetime';
import {Translate} from 'react-localize-redux';
import TriggerWithState from 'components/EditorSidebar/TriggerWithState';
import EditorSidebarSwitch from './EditorSidebarSwitch';

const EditorSidebarTrigger = ({
    delay,
    canEdit,
    trigger,
    translate,
    onTriggerChange,
    onDaysDelayChange,
    onTimeOfDayChange,
    onSendImmediatelyChange,
}) => (
    <>
        <TriggerWithState
            canEdit={canEdit}
            trigger={trigger}
            onChange={onTriggerChange}
        />
        {trigger && trigger.name && (
            <div className="form__group">
                <label className="label" htmlFor="default_delay">
                    <Translate id="editor.form.when.label" />
                </label>
                <EditorSidebarSwitch
                    canEdit={canEdit}
                    value={!delay}
                    onChange={onSendImmediatelyChange}
                    label={translate('editor.form.send_after_trigger.label')}
                />
                {delay && (
                    <>
                        <div className="form-group">
                            <label className="label" htmlFor="default_delay">
                                {translate('editor.form.delay.label')}
                            </label>
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <label className="sublabel" htmlFor="days">
                                        {translate('editor.form.delay.days')}
                                    </label>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <input
                                            disabled={!canEdit}
                                            type="number"
                                            id="days"
                                            className="form-control"
                                            placeholder={translate(
                                                'editor.form.delay.days',
                                            )}
                                            value={delay.days}
                                            onChange={e =>
                                                onDaysDelayChange(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-sm-6">
                                <label className="sublabel" htmlFor="days">
                                    {translate('editor.form.delay.time_of_day')}
                                </label>
                            </div>
                            <div className="col-sm-6">
                                <Datetime
                                    className="full-width"
                                    dateFormat={false}
                                    timeFormat="HH:mm"
                                    initialValue={delay.time_of_day}
                                    inputProps={{
                                        placeholder: format(
                                            new Date(),
                                            'HH:mm',
                                        ),
                                        disabled: !canEdit,
                                    }}
                                    onChange={onTimeOfDayChange}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        )}
    </>
);

export default EditorSidebarTrigger;
