import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'lib/components/Icon';
import styled from 'styled-components';

const ModalHeader = ({title, icon}) => (
    <Header>
        {typeof icon !== 'undefined' && <StyledIcon name={icon} size={32} />}
        <Title>{title}</Title>
    </Header>
);

const Header = styled.div(
    ({theme}) => `
    background-color: ${theme.grey[100]};
    padding: 1rem 2rem 1rem 2rem;
    position: relative;
    justify-content: center;
    height: 6rem;
    flex-shrink:0;
    align-content:end;
    border-radius: ${theme.borderRadius}rem ${theme.borderRadius}rem 0 0;
`,
);

const StyledIcon = styled(Icon)(
    ({theme}) => `
    display: inline-block;
    vertical-align: middle;
    font-size: ${theme.fontSizeLargest}rem;
    margin-right: ${theme.spacingSmall}rem;
`,
);

const Title = styled.span(
    ({theme}) => `
    display: inline-block;
    vertical-align: middle;
    font-size: ${theme.fontSizeLargest}rem;
    font-weight: ${theme.fwBold};
`,
);

ModalHeader.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
};
ModalHeader.defaultProps = {
    title: '',
    icon: undefined,
};
export default ModalHeader;
