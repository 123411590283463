import React from 'react';
import {Translate} from 'react-localize-redux';
import classNames from 'classnames';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import Button from 'components/util/Button';
import SegmentationSelectWithState from './SegmentationSelectWithState';

const Segmentation = ({
    segments,
    selectedSegments,
    onAddSubFilter,
    onChangeSegment,
    onDeleteSegment,
    onAddFilter,
}) => {
    const hasSegments = selectedSegments && selectedSegments.length > 0;

    return (
        <div className="segmentation">
            {hasSegments && (
                <ul className="selectedSegments">
                    {selectedSegments.map(
                        ({grouped_values: group, ...segment}, index) => (
                            <li
                                key={index}
                                className={classNames('segmentGroup', {
                                    hasChildren: group && group.length,
                                })}>
                                <SegmentationSelectWithState
                                    segment={segment}
                                    segments={segments}
                                    onAddSubFilter={() => onAddSubFilter(index)}
                                    onChange={newSegment => {
                                        onChangeSegment(newSegment, index);
                                    }}
                                    onDelete={() => {
                                        onDeleteSegment(index);
                                    }}
                                />
                                <ul className="segmentSubGroup">
                                    {group &&
                                        group.map((subSegment, subIndex) => (
                                            <li key={subIndex}>
                                                <SegmentationSelectWithState
                                                    segment={subSegment}
                                                    segments={segments}
                                                    onChange={newSegment => {
                                                        onChangeSegment(
                                                            newSegment,
                                                            index,
                                                            subIndex,
                                                        );
                                                    }}
                                                    onDelete={() => {
                                                        onDeleteSegment(
                                                            index,
                                                            subIndex,
                                                        );
                                                    }}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        ),
                    )}
                </ul>
            )}

            <div className="addFilter">
                <Button onClick={onAddFilter}>
                    <Translate id="segmentation.addFilter" />
                </Button>
            </div>

            <style jsx>
                {`
                    .intro {
                        margin-bottom: ${theme.spacingLarge}rem;
                    }

                    .segmentGroup {
                        margin-bottom: ${theme.spacing}rem;
                        position: relative; // This is important for the :before to get the correct height.
                    }

                    .addFilter,
                    .addSubFilter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .segmentSubGroup {
                        margin-left: 10rem;
                    }

                    .selectedSegments {
                        position: relative;
                        z-index: ${theme.zAbove};
                    }

                    .segmentGroup:not(:last-child):before {
                        content: '';
                        position: absolute;
                        left: 5rem;
                        top: 0;
                        bottom: -${theme.spacing}rem;
                        width: 0.4rem;
                        background-color: ${colors.border};
                        z-index: ${theme.zBelow};
                    }

                    .hasChildren > :global(.segmentationSelect) {
                        border-bottom-right-radius: 0;
                    }

                    .segmentSubGroup :global(.segmentationSelect) {
                        border-top: 0;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }

                    .segmentSubGroup :global(.typeSelect) {
                        border-top-left-radius: 0 !important;
                    }

                    .segmentSubGroup
                        li:not(:last-child)
                        :global(.segmentationSelect) {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;

                        :global(.typeSelect) {
                            border-radius: 0 !important;
                        }
                    }

                    .segmentationIntro {
                        font-size: ${theme.fontSize}rem;
                        font-weight: ${theme.fwBold};
                        margin-bottom: ${theme.spacing}rem;
                    }
                `}
            </style>
        </div>
    );
};

export default Segmentation;
