import React, {useState} from 'react';
import Icon from 'lib/components/Icon';
import styled from 'styled-components';
import {Translate} from 'react-localize-redux';

const AttachmentRow = styled.a`
    background: #f9f9f9;
    margin-top: 0.25rem;
    transform: scale(0.99);
    display: flex;
    transition: 0.1s ease all;
    gap: 1rem;
    min-height: 2rem;
    align-items: center;
    justify-content: space-between;
    border-radius: ${({theme}) => theme.borderRadius}rem;
    color: ${({theme}) => theme.colorText};
    &:hover {
        transform: scale(1);
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
        img,
        svg {
            max-height: 10rem;
        }
    }
    img,
    svg {
        max-height: 10rem;
        margin: 0.5rem;
        border: 1px solid ${({theme}) => theme.colorBorder};
    }
    span {
        margin-right: 1rem;
    }
`;
const OpenArrowStyled = styled.div`
    margin-left: 1rem;
    display: inline-block;
    transform: rotate(${({isOpen}) => (isOpen ? '90deg' : '0deg')});
`;

const OpenArrow = ({isOpen}) => (
    <OpenArrowStyled isOpen={isOpen}>&#11166;</OpenArrowStyled>
);
const AttachmentLink = styled.a``;
const AttachmentGroup = styled.div`
    max-height: 25rem;
    overflow: auto;
`;
const Attachments = ({files}) => {
    const [isOpen, setOpen] = useState();
    const renderFile = ({mime_type: mimeType, sizes, id, file_name: name}) => {
        return (
            <AttachmentRow
                href={sizes.full}
                target="_blank"
                rel="noopener noreferrer"
                key={id}>
                {mimeType.startsWith('image') ||
                mimeType.startsWith('video') ? (
                    <img src={sizes.thumb} alt={name} />
                ) : (
                    <Icon name="file" />
                )}
                <span>{name}</span>
            </AttachmentRow>
        );
    };
    if (!files?.length) return null;
    if (files?.length === 1) {
        return renderFile(files[0]);
    }
    if (files.length)
        return (
            <>
                <AttachmentLink onClick={() => setOpen(v => !v)}>
                    <OpenArrow isOpen={isOpen} />
                    <Translate id="global.attachments">Attachments</Translate> (
                    {files.length})
                </AttachmentLink>
                <AttachmentGroup>
                    {isOpen && files?.map(renderFile)}
                </AttachmentGroup>
            </>
        );
};
export default Attachments;
