import React from 'react';

export default ({children}) => (
    <div className="button-group">
        {children}

        <style jsx>
            {`
                .button-group {
                    display: flex;

                    :global(button) {
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;

                        &:first-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:last-child {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }

                        &:not(:first-child):not(:last-child) {
                            border-radius: 0;
                        }
                    }
                }
            `}
        </style>
    </div>
);
