import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M21.14 16.64c3.797-3.796 3.797-9.984 0-13.78-3.796-3.797-9.984-3.797-13.78 0-3.188 3.187-3.704 8.015-1.454 11.718 0 0 .188.281-.047.516C4.594 16.313.891 20.063.891 20.063c-.985.984-1.22 2.343-.329 3.234l.141.14c.89.891 2.25.657 3.235-.328l4.968-4.968c.235-.235.516-.047.516-.047 3.703 2.25 8.531 1.734 11.719-1.453zM9.188 14.813a7.186 7.186 0 0 1 0-10.171 7.186 7.186 0 0 1 10.172 0 7.186 7.186 0 0 1 0 10.171 7.186 7.186 0 0 1-10.172 0zm5.063-8.484c-2.906 0-5.297 2.953-5.297 3.422 0 .469 2.39 3.422 5.297 3.422 2.906 0 5.25-2.953 5.25-3.422 0-.469-2.344-3.422-5.25-3.422zm0 5.86c-1.36 0-2.39-1.079-2.39-2.438 0-1.36 1.03-2.438 2.39-2.438 1.313 0 2.39 1.079 2.39 2.438 0 1.36-1.078 2.438-2.39 2.438zm1.453-2.579a1.58 1.58 0 0 1-1.594 1.594 1.58 1.58 0 0 1-1.593-1.594c0-.843.703-1.593 1.593-1.593.891 0 1.594.75 1.594 1.593zm0 0"
        />
    );
}
