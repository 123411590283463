import * as Sentry from '@sentry/react';

export default function initializeSentry() {
    const {REACT_APP_ENV, REACT_APP_SENTRY_DSN} = process.env;

    if (REACT_APP_ENV !== 'local' && REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            environment: REACT_APP_ENV,
            beforeSend(event) {
                const {values} = event.exception;
                const errorValue = values.length && values[0].value;
                // We can safely ignore ResizeObserver error. For more info: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
                if (errorValue && errorValue.includes('ResizeObserver loop')) {
                    return null;
                }

                // Try to parse statuscode and check if error should be logged
                try {
                    const {statusCode} = JSON.parse(errorValue);

                    if (
                        statusCode === 401 || // Unauthorized
                        statusCode === 403 || // Forbidden
                        statusCode === 404 || // Not found
                        statusCode === 422 // Validation
                    ) {
                        return null;
                    }
                } catch (error) {
                    if (event.exception) {
                        Sentry.showReportDialog();
                    }
                    return event;
                }
                return null;
            },
        });
    }
}
