import React from 'react';
import Choice from 'lib/components/Choice/Choice';
import CustomBlocks from 'lib/constants/customBlocks';
import downloadHelper from 'lib/helpers/downloadHelper';
import {CONTENT_TYPE_MESSAGES} from 'lib/store/actions/editor';
import CustomBlock from 'lib/components/Editor/CustomBlock/CustomBlock';
import {getContentSurveysByContentTypeAndId} from 'lib/store/reducers/contentSurveys';
import {TYPE_RSVP} from 'lib/store/actions/surveys/index';
import Button from 'components/util/Button';
import AcceptAnswers from 'components/util/AcceptAnswers';
import {
    iconForSurveyType,
    titleTranslationKeyForSurveyType,
} from 'util/surveys';
import useEditorState from 'components/hooks/useEditorState';
import styled from 'styled-components';

const EditorAnswers = ({
    messageId,
    translate,
    surveys,
    contentSurveys,
    hasSurveys,
    acceptAnswers,
    setAcceptAnswers,
    fetchSurveyExport,
    isFetchingExport,
    showNotification,
}) => {
    const {
        editor: {currentLanguage},
    } = useEditorState();
    const fullSurveys = getContentSurveysByContentTypeAndId(
        contentSurveys,
        surveys,
        CONTENT_TYPE_MESSAGES,
        messageId,
    );

    const surveyBlocks = fullSurveys.map(survey => {
        let max = 0;
        if (survey.answer_stats) {
            survey.answer_stats.forEach(answer => {
                const answerHasExceededMax = max < answer.value;
                max = answerHasExceededMax ? answer.value : max;
            });
        }

        const answerStats = survey.answer_stats ?? [];

        const title =
            survey.type === TYPE_RSVP
                ? survey.content.title[currentLanguage]
                : survey.content.question[currentLanguage];

        return (
            <CustomBlock
                key={survey.id}
                type={CustomBlocks.SURVEY_BLOCK}
                icon={iconForSurveyType(survey.type)}
                label={translate(titleTranslationKeyForSurveyType(survey.type))}
                title={title}
                description={survey.content.description[currentLanguage]}>
                {survey.options.map((choice, key) => (
                    <Choice
                        key={key}
                        survey={survey}
                        option={choice}
                        answer={{
                            count: answerStats[key]?.value,
                            max,
                        }}
                    />
                ))}
            </CustomBlock>
        );
    });

    const splitSurveysInTwo = Math.ceil(surveyBlocks.length / 2);
    const surveyBlocksPartOne = surveyBlocks.splice(0, splitSurveysInTwo);

    return (
        <Container>
            <Header>
                {hasSurveys && (
                    <AcceptAnswers
                        acceptAnswers={acceptAnswers}
                        setAcceptAnswers={setAcceptAnswers}
                    />
                )}
                <Button
                    isLoading={isFetchingExport}
                    className="button"
                    onClick={() =>
                        downloadHelper(
                            fetchSurveyExport,
                            showNotification,
                            messageId,
                        )
                    }>
                    {translate('editor.export-survey')}
                </Button>
            </Header>

            <div className="row">
                <div className="col-md-6">{surveyBlocksPartOne}</div>
                <div className="col-md-6">{surveyBlocks}</div>
            </div>
        </Container>
    );
};

const Container = styled.div(
    ({theme}) => `
    .l-editor__switch {
        position: static;
    }

    .customBlock {
        margin-bottom: 3rem;
        margin-bottom: ${theme.spacingLargest}rem;
    }
`,
);

const Header = styled.header(
    ({theme}) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacingLarger}rem;
`,
);

export default EditorAnswers;
