import {CALL_API} from '../../../constants/api';
import {GET, POST} from '../../services';

export const FETCH_CHANNELS_REQUEST = 'FETCH_CHANNELS_REQUEST';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';

export const fetchChannels = () => ({
    [CALL_API]: {
        endpoint: '/notifications/configured-channels',
        method: GET,
        types: [
            FETCH_CHANNELS_REQUEST,
            FETCH_CHANNELS_SUCCESS,
            FETCH_CHANNELS_FAILURE,
        ],
    },
});

export const FETCH_USER_CHANNELS_REQUEST = 'FETCH_USER_CHANNELS_REQUEST';
export const FETCH_USER_CHANNELS_SUCCESS = 'FETCH_USER_CHANNELS_SUCCESS';
export const FETCH_USER_CHANNELS_FAILURE = 'FETCH_USER_CHANNELS_FAILURE';

export const fetchUserChannels = () => ({
    [CALL_API]: {
        endpoint: '/user-notifications/configured-channels',
        method: GET,
        types: [
            FETCH_USER_CHANNELS_REQUEST,
            FETCH_USER_CHANNELS_SUCCESS,
            FETCH_USER_CHANNELS_FAILURE,
        ],
    },
});

export const UPDATE_USER_CHANNELS_REQUEST = 'UPDATE_USER_CHANNELS_REQUEST';
export const UPDATE_USER_CHANNELS_SUCCESS = 'UPDATE_USER_CHANNELS_SUCCESS';
export const UPDATE_USER_CHANNELS_FAILURE = 'UPDATE_USER_CHANNELS_FAILURE';

export const updateUserChannels = channels => ({
    [CALL_API]: {
        endpoint: '/user-notifications/configured-channels',
        method: POST,
        body: {
            channels,
        },
        types: [
            UPDATE_USER_CHANNELS_REQUEST,
            UPDATE_USER_CHANNELS_SUCCESS,
            UPDATE_USER_CHANNELS_FAILURE,
        ],
    },
});

export const SET_SELECTED_CHANNELS = 'SET_SELECTED_CHANNELS';
export const setSelectedChannels = payload => ({
    payload,
    type: SET_SELECTED_CHANNELS,
});

export default {
    fetchChannels,
    fetchUserChannels,
    updateUserChannels,
    setSelectedChannels,
};
