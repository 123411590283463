import React from 'react';
import {Translate} from 'react-localize-redux';
import ReactSelect from 'react-select';
import color from 'tinycolor2';
import styled from 'styled-components';
import * as theme from 'styles/theme';
import Icon from 'lib/components/Icon';

const StyledSelect = styled(ReactSelect)`
    min-width: 20rem;
`;

const Select = ({
    value,
    options,
    components,
    isMulti,
    hideIndicator,
    tagColor = theme.colors.primary,
    menuPlacement = 'auto',
    isSearch,
    ...props
}) => {
    if (options === null || typeof options === 'undefined') {
        return null;
    }
    // Get selected option as value (needed for upgrade to react-select v3)
    let selectedValue;
    if (value === null || typeof value === 'undefined') {
        selectedValue = null;
    } else if (isMulti) {
        selectedValue = options.filter(
            option => value.indexOf(option.value) >= 0,
        );
    } else {
        selectedValue = options.reduce((acc, option) => {
            if (typeof option.options === 'undefined') {
                return option.value === value ? option : acc;
            }
            // If the options are grouped get the subvalue
            const subValue = option.options.find(
                subOption => subOption.value === value,
            );
            if (typeof subValue !== 'undefined') {
                return subValue;
            }
            return acc;
        }, {});
    }

    if (typeof selectedValue === 'undefined') {
        selectedValue = value;
    }

    const backgroundColor = color(tagColor).setAlpha(0.1).toRgbString();
    const borderColor = color(tagColor).setAlpha(0.5).toRgbString();

    const styles = {
        option: provided => ({
            ...provided,
            cursor: 'pointer',
        }),
        control: provided => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '1rem',
            background: theme.colors.grey[0],
            border: `1px solid ${theme.colors.border}`,
            minHeight: '4.2rem',
        }),
        multiValue: provided => ({
            ...provided,
            backgroundColor,
            border: `solid 1px ${borderColor}`,
        }),
        multiValueLabel: provided => ({
            ...provided,
            color: tagColor,
        }),
        multiValueRemove: provided => ({
            ...provided,
            color: tagColor,
            ':hover': {
                backgroundColor: tagColor,
                color: theme.colors.textLight,
            },
        }),
        valueContainer: provided => ({
            ...provided,
            padding:
                isMulti && selectedValue !== null && selectedValue.length
                    ? `${theme.spacingSmallest}rem`
                    : `${theme.spacingSmall}rem ${theme.spacingLarge}rem`,
        }),
        singleValue: provided => ({
            ...provided,
            margin: 0,
        }),
    };

    return (
        <Translate>
            {({translate}) => (
                <StyledSelect
                    placeholder={translate('global.select')}
                    styles={styles}
                    components={{
                        DropdownIndicator: () =>
                            !hideIndicator && (
                                <IconWrap>
                                    {isSearch ? (
                                        <Icon size={20} name="search" />
                                    ) : (
                                        <Icon size={20} name="chevronDown" />
                                    )}
                                </IconWrap>
                            ),
                        IndicatorSeparator: () => null,
                        ...components,
                    }}
                    value={selectedValue}
                    options={options}
                    isMulti={isMulti}
                    closeMenuOnSelect={!isMulti}
                    menuPlacement={menuPlacement}
                    noOptionsMessage={() => translate('No results found')}
                    {...props}
                />
            )}
        </Translate>
    );
};

const IconWrap = styled.div`
    margin-right: ${theme.spacingSmallest}rem;
    color: ${theme.colorGrey700};
`;

export default Select;
