import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16.641 16L11.719 13.064V6.736"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.364 5.636C19.1997 6.47173 19.8627 7.46389 20.315 8.55583C20.7673 9.64777 21 10.8181 21 12C21 13.1819 20.7673 14.3522 20.315 15.4442C19.8627 16.5361 19.1997 17.5283 18.364 18.364C17.5283 19.1997 16.5361 19.8627 15.4442 20.315C14.3522 20.7673 13.1819 21.0001 12 21.0001C10.8181 21.0001 9.64776 20.7673 8.55582 20.315C7.46389 19.8627 6.47173 19.1997 5.63599 18.364C3.94816 16.6762 2.99994 14.387 2.99994 12C2.99994 9.61304 3.94816 7.32384 5.63599 5.636C7.32383 3.94816 9.61303 2.99994 12 2.99994C14.387 2.99994 16.6762 3.94816 18.364 5.636"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
