// If you are using date-fns < 2.0 you should use this file
import dateTranslationsDE from 'date-fns/locale/de';
import dateTranslationsEN from 'date-fns/locale/en';
import dateTranslationsES from 'date-fns/locale/es';
import dateTranslationsFR from 'date-fns/locale/fr';
import dateTranslationsIT from 'date-fns/locale/it';
import dateTranslationsNL from 'date-fns/locale/nl';
import dateTranslationsPL from 'date-fns/locale/pl';
import dateTranslationsPT from 'date-fns/locale/pt';
import dateTranslationsTR from 'date-fns/locale/tr';

import {DE, EN, ES, FR, IT, NL, PL, PT, TR} from './locale';

const TRANSLATIONS = {
    [DE]: dateTranslationsDE,
    [EN]: dateTranslationsEN,
    [ES]: dateTranslationsES,
    [FR]: dateTranslationsFR,
    [IT]: dateTranslationsIT,
    [NL]: dateTranslationsNL,
    [PL]: dateTranslationsPL,
    [PT]: dateTranslationsPT,
    [TR]: dateTranslationsTR,
};

export const getDateLocale = langCode => ({
    locale: TRANSLATIONS[langCode],
});

export default getDateLocale;
