import React from 'react';
import {useDispatch} from 'react-redux';
import {
    VISIBILITY_PRIVATE,
    VISIBILITY_PUBLIC,
} from 'lib/store/actions/messages/index';
import useTranslate from 'components/hooks/useTranslate';
import actions from 'store/actions';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import EditorSidebarVisibilitySelect from './EditorSidebarVisibilitySelect';

const getVisibilityOptions = translate => [
    {
        label: translate('editor.visibilities.private'),
        value: VISIBILITY_PRIVATE,
    },
    {
        label: translate('editor.visibilities.public'),
        value: VISIBILITY_PUBLIC,
    },
];

const EditorSidebarVisibilitySelectWithState = ({canEdit}) => {
    const {translate} = useTranslate();
    const {editor} = useEditorState();
    const {saveContent} = useContentSaver();

    const dispatch = useDispatch();
    const updateVisibility = newVisibility =>
        dispatch(actions.editor.setVisibility(newVisibility));

    const onVisibilityChange = async option => {
        if (option) {
            await updateVisibility(option.value);
            saveContent({...editor, visibility: option.value});
        }
    };

    const visibilityOptions = getVisibilityOptions(translate);
    const selectedOption = visibilityOptions.find(
        ({value}) => value === editor.visibility,
    );

    return (
        <EditorSidebarVisibilitySelect
            value={editor.visibility}
            options={visibilityOptions}
            canEdit={canEdit}
            onChange={onVisibilityChange}
            translate={translate}
            selectedOption={selectedOption}
        />
    );
};

export default EditorSidebarVisibilitySelectWithState;
