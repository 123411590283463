import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M12.188.281c-.422-.375-1.079-.375-1.454 0L4.547 6.47a.878.878 0 0 0 0 1.218c.14.141.375.235.61.235.187 0 .421-.094.609-.234l4.828-4.829v18.657a.84.84 0 0 0 .844.843.84.84 0 0 0 .843-.843V2.859l4.828 4.829a.878.878 0 0 0 1.22 0 .878.878 0 0 0 0-1.22zm0 0"
        />
    );
}
