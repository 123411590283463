import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {callApi as callApiAction} from 'lib/store/actions/index';

const useApi = (endpoint, options) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const callApi = async () => {
        setLoading(true);

        try {
            const {
                payload: {data: response},
            } = await dispatch(callApiAction(endpoint, options));
            setData(response);
            return response;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return [
        callApi,
        {
            data,
            loading,
            error,
        },
    ];
};

export default useApi;
