import React from 'react';

export default function () {
    return (
        <g stroke="currentColor" fill="none">
            <path d="M5 12L19 12" />
            <path d="M12 5L19 12L12 19" />
        </g>
    );
}
