import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {POST, GET, DELETE} from '../../services';

export const INTERNAL_MEDIA_ITEM_SCHEMA = new schema.Entity(
    'internalMediaItem',
);

export const TYPE_VIDEO = 'video';
export const TYPE_IMAGE = 'image';
export const TYPE_FILE = 'file';

export const LIST_INTERNAL_MEDIA_REQUEST = 'LIST_INTERNAL_MEDIA_ITEMS_REQUEST';
export const LIST_INTERNAL_MEDIA_SUCCESS = 'LIST_INTERNAL_MEDIA_SUCCESS';
export const LIST_INTERNAL_MEDIA_FAILURE = 'LIST_INTERNAL_MEDIA_FAILURE';

export const fetchInternalMediaItems = (
    {page, limit, filters = {}, filters: {query, recentlyUsed, type} = {}} = {},
    listType = null,
) => ({
    [CALL_API]: {
        endpoint: '/media/items',
        method: GET,
        query: {
            query,
            page,
            limit,
            recently_used: recentlyUsed,
            type,
        },
        filters,
        types: [
            LIST_INTERNAL_MEDIA_REQUEST,
            LIST_INTERNAL_MEDIA_SUCCESS,
            LIST_INTERNAL_MEDIA_FAILURE,
        ],
        listType,
        schema: [INTERNAL_MEDIA_ITEM_SCHEMA],
    },
});

export const fetchMediaItemsForPublicUserMessageId = (
    {page, limit, filters = {}} = {},
    userMessageId,
) => ({
    [CALL_API]: {
        endpoint: `/public/user-messages/${userMessageId}/media`,
        method: GET,
        query: {
            page,
            limit,
        },
        filters,
        types: [
            LIST_INTERNAL_MEDIA_REQUEST,
            LIST_INTERNAL_MEDIA_SUCCESS,
            LIST_INTERNAL_MEDIA_FAILURE,
        ],
        schema: [INTERNAL_MEDIA_ITEM_SCHEMA],
    },
});

export const VIEW_INTERNAL_MEDIA_REQUEST = 'VIEW_INTERNAL_MEDIA_ITEMS_REQUEST';
export const VIEW_INTERNAL_MEDIA_SUCCESS = 'VIEW_INTERNAL_MEDIA_SUCCESS';
export const VIEW_INTERNAL_MEDIA_FAILURE = 'VIEW_INTERNAL_MEDIA_FAILURE';

export const fetchMediaItemById = (id, listType = null) => ({
    [CALL_API]: {
        endpoint: `/media/items/${id}`,
        method: GET,
        types: [
            VIEW_INTERNAL_MEDIA_REQUEST,
            VIEW_INTERNAL_MEDIA_SUCCESS,
            VIEW_INTERNAL_MEDIA_FAILURE,
        ],
        listType,
        schema: INTERNAL_MEDIA_ITEM_SCHEMA,
    },
});

export const POST_INTERNAL_MEDIA_REQUEST = 'POST_INTERNAL_MEDIA_REQUEST';
export const POST_INTERNAL_MEDIA_SUCCESS = 'POST_INTERNAL_MEDIA_SUCCESS';
export const POST_INTERNAL_MEDIA_FAILURE = 'POST_INTERNAL_MEDIA_FAILURE';

export const uploadNewMediaItem = body => ({
    [CALL_API]: {
        endpoint: '/media/items',
        method: POST,
        types: [
            POST_INTERNAL_MEDIA_REQUEST,
            POST_INTERNAL_MEDIA_SUCCESS,
            POST_INTERNAL_MEDIA_FAILURE,
        ],
        body,
        schema: INTERNAL_MEDIA_ITEM_SCHEMA,
    },
});

export const DELETE_INTERNAL_MEDIA_REQUEST = 'DELETE_INTERNAL_MEDIA_REQUEST';
export const DELETE_INTERNAL_MEDIA_SUCCESS = 'DELETE_INTERNAL_MEDIA_SUCCESS';
export const DELETE_INTERNAL_MEDIA_FAILURE = 'DELETE_INTERNAL_MEDIA_FAILURE';

export const deleteMedia = id => ({
    [CALL_API]: {
        endpoint: `/media/items/${id}`,
        method: DELETE,
        types: [
            DELETE_INTERNAL_MEDIA_REQUEST,
            DELETE_INTERNAL_MEDIA_SUCCESS,
            DELETE_INTERNAL_MEDIA_FAILURE,
        ],
        schema: INTERNAL_MEDIA_ITEM_SCHEMA,
    },
});

export default {
    fetchInternalMediaItems,
    fetchMediaItemsForPublicUserMessageId,
    uploadNewMediaItem,
    fetchMediaItemById,
    deleteMedia,
};
