import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getEntityRange} from 'draftjs-utils';
import {createEditor, HANDLED} from 'medium-draft';
import {EditorState, Modifier, SelectionState} from 'draft-js';
import {SMART_TAG} from 'lib/components/Editor/SmartTags/SmartTagEntity';
import SmartTagSuggestions, {
    filterSmartTags,
} from 'lib/components/Editor/SmartTags/SmartTagSuggestions';
import {normalizeSelectedIndex, getTypeaheadRange} from './Utils/editor';
import originalHandlers from './Handlers';

const getElementBoundingRectInDocument = el => {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        width: rect.width,
        height: rect.height,
    };
};

class SmartTagEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            smartTagTypeaheadState: null,
        };

        this.editorClass = createEditor({
            ...originalHandlers,
            handleReturn: (...args) => this._handleReturn(...args),
            onUpArrow: (...args) => this._onUpArrow(...args),
            onDownArrow: (...args) => this._onDownArrow(...args),
            onEscape: (...args) => this._onEscape(...args),
        });
    }

    /*
     * PRIVATE
     */
    _onEditorChange(state) {
        this.setState({
            editorState: state,
        });
        if (this.props.onChange) {
            this.props.onChange(state);
        }

        window.requestAnimationFrame(() => {
            this._calculateAndSetSmartTagTypeaheadState();
        });
    }

    _calculateAndSetSmartTagTypeaheadState() {
        const {editorState} = this.state;
        let smartTagTypeaheadState = null;

        let typeaheadRange = null;
        try {
            typeaheadRange = getTypeaheadRange(editorState);
        } catch (error) {
            //
        }

        if (typeaheadRange === null) {
            smartTagTypeaheadState = null;
        } else {
            const tempRange = window.getSelection().getRangeAt(0).cloneRange();
            tempRange.setStart(tempRange.startContainer, typeaheadRange.start);

            const {left, top, height} =
                getElementBoundingRectInDocument(tempRange);

            smartTagTypeaheadState = {
                left,
                top: top + height,
                text: typeaheadRange.text,
                selectedIndex: 0,
            };

            // Only set state when editing smarttags
            this.setState({
                smartTagTypeaheadState,
            });
        }
    }

    _handleSmartTagTypeaheadReturn(
        editorState,
        text,
        selectedIndex,
        selection,
    ) {
        const {smartTags} = this.props;

        const filteredSmartTags = filterSmartTags(
            smartTags,
            text.replace(/^@/, ''),
        );
        const index = normalizeSelectedIndex(
            selectedIndex,
            filteredSmartTags.length,
        );

        if (index < filteredSmartTags.length) {
            const contentState = editorState.getCurrentContent();

            const contentStateWithEntity = contentState.createEntity(
                SMART_TAG,
                'IMMUTABLE',
                {smartTagSlug: filteredSmartTags[index].slug},
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

            const contentStateWithSmartTag = Modifier.replaceText(
                contentState,
                selection,
                `@${filteredSmartTags[index].slug}`,
                null,
                entityKey,
            );

            let nextEditorState = EditorState.push(
                editorState,
                contentStateWithSmartTag,
                'apply-entity',
            );

            const entityRange = getEntityRange(nextEditorState, entityKey);

            const newSelection = new SelectionState({
                anchorKey: selection.getAnchorKey(),
                anchorOffset: entityRange.end + 1,
                focusKey: selection.getAnchorKey(),
                focusOffset: entityRange.end + 1,
                isBackward: false,
            });

            const finalContentState = Modifier.insertText(
                contentStateWithSmartTag,
                newSelection,
                ' ',
            );

            nextEditorState = EditorState.push(
                editorState,
                finalContentState,
                'apply-entity',
            );

            const finalEditorState = EditorState.forceSelection(
                nextEditorState,
                newSelection,
            );

            window.requestAnimationFrame(() => {
                this._onEditorChange(finalEditorState);
            });
        }
    }

    _onUpArrow(e, state, options) {
        if (!this.state.smartTagTypeaheadState) {
            originalHandlers.onUpArrow(e, state, options);
            return;
        }

        this._onArrow(e, -1);
    }

    _onDownArrow(e) {
        if (!this.state.smartTagTypeaheadState) {
            return;
        }

        this._onArrow(e, 1);
    }

    _onSmartTagHover(smartTagIndex) {
        const smartTagTypeaheadState = {...this.state.smartTagTypeaheadState};
        smartTagTypeaheadState.selectedIndex = smartTagIndex;

        this.setState({
            smartTagTypeaheadState,
        });
    }

    _onSmartTagClick() {
        const {editorState, smartTagTypeaheadState} = this.state;
        const contentState = editorState.getCurrentContent();

        const selection = contentState.getSelectionAfter();
        const entitySelection = selection.set(
            'anchorOffset',
            selection.getFocusOffset() - smartTagTypeaheadState.text.length,
        );

        this._handleSmartTagTypeaheadReturn(
            editorState,
            smartTagTypeaheadState.text,
            smartTagTypeaheadState.selectedIndex,
            entitySelection,
        );

        this.setState({
            smartTagTypeaheadState: null,
        });
    }

    _onArrow(e, nudgeAmount) {
        e.preventDefault();
        const smartTagTypeaheadState = {...this.state.smartTagTypeaheadState};
        smartTagTypeaheadState.selectedIndex += nudgeAmount;

        this.setState({
            smartTagTypeaheadState,
        });
    }

    _onEscape(e) {
        if (!this.state.smartTagTypeaheadState) {
            return;
        }
        e.preventDefault();
        this.setState({
            smartTagTypeaheadState: null,
        });
    }

    _handleReturn(e, editorState, options, handlers) {
        const {smartTagTypeaheadState} = this.state;
        if (!smartTagTypeaheadState) {
            return originalHandlers.handleReturn(
                e,
                editorState,
                options,
                handlers,
            );
        }

        const contentState = editorState.getCurrentContent();

        const selection = contentState.getSelectionAfter();
        const entitySelection = selection.set(
            'anchorOffset',
            selection.getFocusOffset() - smartTagTypeaheadState.text.length,
        );

        this._handleSmartTagTypeaheadReturn(
            editorState,
            smartTagTypeaheadState.text,
            smartTagTypeaheadState.selectedIndex,
            entitySelection,
        );

        this.setState({
            smartTagTypeaheadState: null,
        });
        return HANDLED;
    }

    renderSmartTagTypeahead() {
        const {smartTags} = this.props;
        const {smartTagTypeaheadState} = this.state;
        if (smartTagTypeaheadState === null) {
            return null;
        }
        return (
            <SmartTagSuggestions
                {...smartTagTypeaheadState}
                smartTags={smartTags}
                onClick={() => this._onSmartTagClick()}
                onHover={smartTagIndex => this._onSmartTagHover(smartTagIndex)}
            />
        );
    }

    render() {
        const Editor = this.editorClass;

        return (
            <div>
                {this.renderSmartTagTypeahead()}
                <Editor
                    {...this.props}
                    ref={ref => {
                        this.editor = ref;
                        if (this.props.editorRef) {
                            this.props.editorRef(ref);
                        }
                    }}
                    onChange={state => this._onEditorChange(state)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    smartTags: state.smartTags.data,
});

export default connect(mapStateToProps)(SmartTagEditor);
