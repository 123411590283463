import React from 'react';
import {Translate} from 'react-localize-redux';
import RogerLogo from 'assets/logo.svg';
import Landing from 'components/layout/Landing';
import LandingContent from '../layout/LandingContent';

const AppFailure = ({error = ''}) => (
    <Landing>
        <LandingContent
            image={<img src={RogerLogo} alt="logo" className="logo" />}
            title={
                <Translate
                    id="notifications.general_error"
                    data={{error: ''}}
                />
            }
            description={error && error.message ? error.message : String(error)}
        />
    </Landing>
);

export default AppFailure;
