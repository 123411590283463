import React from 'react';
import {useDispatch} from 'react-redux';
import actions from 'store/actions';
import {MODAL_INPUT} from 'lib/store/actions/modals';
import Icon from 'lib/components/Icon';
import Button from './ButtonHOC';

const EmbedButton = ({
    close,
    setModalVisibility,
    setModalData,
    ...otherProps
}) => {
    const dispatch = useDispatch();

    return (
        <Button
            {...otherProps}
            type="embed"
            onClick={() => {
                close();
                dispatch(actions.modals.setModalVisibility(MODAL_INPUT, true));
                dispatch(actions.embedModal.setData({content: ''}));
            }}>
            <Icon name="code" />
        </Button>
    );
};

export default EmbedButton;
