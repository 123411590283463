import React from 'react';
import {connect, useSelector} from 'react-redux';
import {actions} from 'store/index';
import AdminMessageReads from './AdminMessageReads';

const AdminMessageReadsWithState = () => {
    const messageReadsByHourData = useSelector(
        ({messagesAudience}) => messagesAudience.readsByHour.entries,
    );

    return <AdminMessageReads data={messageReadsByHourData} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    fetchMessagesPerWeek: () => {
        dispatch(actions.analytics.messages.fetchMessagesPerWeek());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMessageReadsWithState);
