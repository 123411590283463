import {useState, useEffect} from 'react';
import localforage from 'localforage';
import {formatMinutesToHoursAndMinutes} from 'util/time';
import useCompany from './useCompany';

const browserOffset = formatMinutesToHoursAndMinutes(
    new Date().getTimezoneOffset() * -1,
);

const useTimezone = () => {
    const company = useCompany();
    const {timezone, timezone_offset: companyOffset} = company.settings;
    const isInCompanyTimezone = companyOffset === browserOffset;
    const [warningClosed, setWarningClosed] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const closeWarning = () => {
        setWarningClosed(true);
        localforage.setItem('savedBrowserOffset', browserOffset);
        localforage.setItem('savedCompanyOffset', companyOffset);
    };

    // Check if warning is closed for current timezone
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const savedBrowserOffset =
                await localforage.getItem('savedBrowserOffset');
            const savedCompanyOffset =
                await localforage.getItem('savedCompanyOffset');
            // Warning is closed if the saved browser and company offset are the same
            setWarningClosed(
                browserOffset === savedBrowserOffset &&
                    companyOffset === savedCompanyOffset,
            );
            setIsLoading(false);
        })();
    }, [companyOffset]);

    return {
        isLoading: company.isLoadingCompany || isLoading,
        timezone,
        timezoneOffset: companyOffset,
        isInCompanyTimezone,
        closeWarning,
        warningClosed,
    };
};

export default useTimezone;
