/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as theme from 'lib/styles/theme';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import useEditorState from 'components/hooks/useEditorState';
import useTranslate from 'components/hooks/useTranslate';
import {SURVEY_TYPE_OPTION} from '../../constants/customBlocks';
import Answer from './Answer';
import ChoiceEdit from './ChoiceEdit';

const DEFAULT_LABELS = 'abcdefghijklmnopqrstuvwxyz';

const Choice = ({
    index,
    labels,
    interactive,
    survey,
    onClick,
    editable,
    option,
    addRef,
    inputRefs,
    answer,
}) => {
    const {
        editor: {currentLanguage},
    } = useEditorState();
    const {translate} = useTranslate();

    return (
        <Branding.Consumer>
            {colors => {
                const checkIfActive = choiceToCheck => {
                    if (survey.response) {
                        const response = survey.response.option;

                        if (!Array.isArray(response) || !response.length) {
                            return false;
                        }

                        return survey.response.option.find(responseOption => {
                            if (typeof responseOption === 'object') {
                                return (
                                    responseOption.label ===
                                        choiceToCheck.label &&
                                    responseOption.value === choiceToCheck.value
                                );
                            }

                            return responseOption === choiceToCheck;
                        });
                    }

                    return false;
                };

                const isNotTouchDevice = !(
                    'ontouchstart' in document.documentElement
                );
                const isOptionType =
                    (option.type || SURVEY_TYPE_OPTION) === SURVEY_TYPE_OPTION;

                return (
                    <div
                        className={classNames('choice', {
                            interactive,
                            isActive: checkIfActive(option),
                            editable,
                            hasAnswers: answer,
                            isNotTouchDevice,
                        })}
                        onClick={() => interactive && onClick(option)}>
                        <div className="content">
                            {!answer && (
                                <div className="label">
                                    {labels
                                        ? labels[index]
                                        : DEFAULT_LABELS[index]}
                                </div>
                            )}
                            {editable ? (
                                <ChoiceEdit
                                    survey={survey}
                                    labels={DEFAULT_LABELS}
                                    index={index}
                                    option={option}
                                    addRef={ref => addRef(ref)}
                                    inputRefs={inputRefs}
                                    currentLanguage={currentLanguage}
                                />
                            ) : (
                                <div className="text">
                                    {isOptionType &&
                                        (typeof option.label === 'object'
                                            ? option.label[currentLanguage]
                                            : option.label)}
                                    {!isOptionType && (
                                        <input
                                            className="free-text"
                                            disabled
                                            placeholder={
                                                typeof option.label === 'object'
                                                    ? option.label[
                                                          currentLanguage
                                                      ]
                                                    : option.label
                                            }
                                        />
                                    )}

                                    <span className="option-type">
                                        (
                                        {isOptionType
                                            ? translate(
                                                  'editor.form.choices.option',
                                              )
                                            : translate(
                                                  'editor.form.choices.free_text',
                                              )}
                                        )
                                    </span>
                                </div>
                            )}
                            {answer && <Answer answer={answer} />}
                        </div>

                        <style jsx>
                            {`
                                .choice {
                                    margin-bottom: 1rem;
                                }

                                .content {
                                    display: flex;
                                    align-items: center;
                                }

                                .text {
                                    font-size: 1.4rem;
                                }

                                .text,
                                .label {
                                    transition: ${theme.defaultTransition} color;
                                }

                                .label {
                                    text-transform: uppercase;
                                    color: ${colors.grey[400]};
                                    font-weight: ${colors.fwBold};

                                    border-radius: 50%;
                                    font-size: 1.4rem;
                                    border: solid 0.1rem ${colors.grey[400]};
                                    width: 2.5rem;
                                    height: 2.5rem;
                                    line-height: 2.5rem;
                                    margin-right: 1rem;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .option-type {
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                    font-weight: ${colors.fwBold};
                                }

                                .free-text {
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                }

                                .isNotTouchDevice.interactive:hover,
                                .interactive.isActive {
                                    cursor: pointer;

                                    .text {
                                        color: ${colors.brand.base};
                                        color: red;
                                    }

                                    .label {
                                        color: ${colors.pureWhite};
                                    }

                                    .label {
                                        border-color: ${colors.brand.base};
                                        background-color: ${colors.brand.base};
                                    }
                                }

                                .editable {
                                    .label {
                                        border: 0;
                                        font-size: 2.4rem;
                                        width: auto;
                                        height: auto;
                                        line-height: 1.2;
                                        margin: 0;
                                        width: 3rem;
                                        display: block;
                                        text-align: left;
                                    }
                                }

                                .hasAnswers {
                                    .content {
                                        display: block;
                                    }
                                }
                            `}
                        </style>
                    </div>
                );
            }}
        </Branding.Consumer>
    );
};

Choice.propTypes = {
    index: PropTypes.number.isRequired,
    labels: PropTypes.arrayOf(PropTypes.string),
    interactive: PropTypes.bool,
    survey: PropTypes.shape({
        response: PropTypes.shape({
            option: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
                PropTypes.object,
            ]),
        }),
    }).isRequired,
    onClick: PropTypes.func,
    editable: PropTypes.bool,
    option: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
    }).isRequired,
    addRef: PropTypes.func,
    inputRefs: PropTypes.arrayOf(PropTypes.object),
    answer: PropTypes.shape({}),
};
Choice.defaultProps = {
    labels: [],
    interactive: false,
    onClick: () => {},
    editable: false,
    addRef: () => {},
    inputRefs: [],
    answer: null,
};
export default Choice;
