import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M11.39 19.406c4.97 0 7.641-2.953 7.641-6.187V1.922h2.719V0h-7.828v1.922h2.953v9.656c0 2.156-.188 3.422-1.172 4.406-.844.844-1.875 1.36-3.422 1.36-3.515 0-5.344-1.407-5.344-5.063V1.921H9.75V0H1.219v1.922h2.765v11.297c0 3.61 2.579 6.187 7.407 6.187zM1.22 22.781h20.39V24H1.22zm0 0"
        />
    );
}
