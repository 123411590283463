import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Input from 'components/util/Input';

const EndAfter = ({after, handleChange, translate}) => (
    <div className="form-group react-rule__flex">
        <div className="react-rule__col">
            <Input
                name="end.after"
                aria-label="End after"
                className="form-control"
                value={after}
                type="number"
                min="1"
                onNativeChange={handleChange}
            />
        </div>
        <div className="react-rule__col">
            {translate('editor.recurrence.executions')}
        </div>
    </div>
);

EndAfter.propTypes = {
    after: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(EndAfter);
