import React from 'react';

const ButtonWithoutStyle = ({className, onClick, children, title = ''}) => (
    <div
        className={className}
        role="button"
        tabIndex="-1"
        title={title}
        style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            outline: 'none',
        }}
        onClick={onClick}>
        {children}
    </div>
);

export default ButtonWithoutStyle;
