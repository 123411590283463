import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {mapLanguageOptions} from 'lib/helpers/locale';
import actions from 'store/actions';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import {
    addLanguageToMessageSurveys,
    removeLanguageFromMessageSurveys,
} from 'util/surveys';
import {Translate} from 'react-localize-redux';
import EditorSidebarLanguageSelect from './EditorSidebarLanguageSelect';
import {MODAL_CONFIRM} from '../../../lib/store/actions/modals';
import {SUCCESS} from '../../../lib/store/actions/notifications';

const EditorSidebarLanguageSelectWithState = ({
    companySettings,
    showNotification,
}) => {
    const {editor, canEdit} = useEditorState();
    const dispatch = useDispatch();
    const {saveContent} = useContentSaver();
    const {defaultLanguage, companyLanguages} = useTranslate();
    const surveys = useSelector(({surveys: {byId}}) => byId);

    const canTranslate = companySettings.automatic_translations && canEdit;

    const languageOptions = mapLanguageOptions(companyLanguages)
        .sort(({value}) => editor.availableLanguages.includes(value))
        .sort(({value}) => value !== defaultLanguage);

    const handleChangeSelectedLanguage = language => {
        dispatch(actions.editor.setCurrentLanguage(language));
    };

    const handleChangeAvailableLanguages = async (isTranslated, value) => {
        let updatedAvailableLanguages = [];
        const updatedTitle = editor.title;
        const updatedEditorState = editor.body;
        if (isTranslated) {
            // Remove translation
            updatedAvailableLanguages = editor.availableLanguages.filter(
                language => language !== value,
            );
            delete updatedTitle[value];
            delete updatedEditorState[value];
            removeLanguageFromMessageSurveys(
                editor.body,
                defaultLanguage,
                surveys,
                value,
            );
        } else {
            // Add translation
            updatedAvailableLanguages = [...editor.availableLanguages, value];
            updatedTitle[value] = editor.title[defaultLanguage];
            updatedEditorState[value] = editor.body[defaultLanguage];
            addLanguageToMessageSurveys(
                editor.body,
                defaultLanguage,
                surveys,
                value,
            );
        }

        await saveContent({
            ...editor,
            body: updatedEditorState,
            title: updatedTitle,
            availableLanguages: updatedAvailableLanguages,
        });
    };

    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));
    const setModalLoading = (modal, loading) =>
        dispatch(actions.modals.setModalLoading(modal, loading));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));

    const generateTranslations = async sourceLanguageKey => {
        await saveContent(editor);
        const message = await dispatch(
            actions.editor.generateTranslations(editor, sourceLanguageKey),
        );
        if (message !== null) {
            dispatch(actions.editor.setAvailableLanguages(companyLanguages));

            dispatch(
                actions.surveys.fetchSurveysForContentWithType(
                    {
                        id: editor.contentId,
                    },
                    editor.contentType,
                    {limit: 100, withAnswerStats: 0},
                ),
            );

            if (message.payload.data.type === 'automated') {
                dispatch(
                    actions.editor.loadAutomatedMessageIntoEditor(
                        message.payload.data,
                    ),
                );
            } else {
                dispatch(
                    actions.editor.loadMessageIntoEditor(message.payload.data),
                );
            }
            showNotification({
                type: SUCCESS,
                content: {
                    key: 'notifications.translations_generated',
                },
            });
        }
    };

    const onGenerateTranslations = (sourceLanguageKey, languages) => {
        const sourceLanguageIndex = languages.findIndex(
            item => item.value === sourceLanguageKey,
        );
        const sourceLanguage = languages.splice(sourceLanguageIndex, 1)[0];

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: <Translate id="automatic_translations.title" />,
            buttonActions: [
                {
                    label: <Translate id="automatic_translations.modalCta" />,
                    callBack: async () => {
                        setModalLoading(MODAL_CONFIRM, true);
                        await generateTranslations(sourceLanguageKey);
                        setModalVisibility(MODAL_CONFIRM, false);
                        setModalLoading(MODAL_CONFIRM, false);
                    },
                    classes: 'danger',
                },
            ],
            body: (
                <Translate
                    id="automatic_translations.body"
                    data={{
                        languages: languages.map(language => language.label),
                    }}
                />
            ),
            bodyBold: (
                <Translate
                    id="automatic_translations.bodyBold"
                    data={{source: sourceLanguage.label}}
                />
            ),
        });
    };

    return (
        <EditorSidebarLanguageSelect
            availableLanguages={editor.availableLanguages}
            languageOptions={languageOptions}
            hasAllTranslations={
                editor.availableLanguages.length === languageOptions.length
            }
            selectedLanguage={editor.currentLanguage}
            setSelectedLanguage={handleChangeSelectedLanguage}
            defaultLanguage={defaultLanguage}
            onChangeAvailableLanguages={handleChangeAvailableLanguages}
            onGenerateTranslations={onGenerateTranslations}
            canTranslate={canTranslate}
        />
    );
};

const mapStateToProps = state => ({
    companySettings: state.company.data.settings,
});

const mapDispatchToProps = dispatch => ({
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditorSidebarLanguageSelectWithState);
