import React from 'react';
import PropTypes from 'prop-types';
import DirectoryNameForm from 'components/DirectoryForm/DirectoryNameForm';
import Modal from 'modals/Modal/ModalWithState';
import {MODAL_DIRECTORY_NAME} from 'lib/store/actions/modals';

const DirectoryNameModal = ({name, onSetName, title, buttonActions}) => (
    <Modal
        name={MODAL_DIRECTORY_NAME}
        title={title}
        cancelable
        buttonActions={buttonActions}>
        <DirectoryNameForm name={name} onSetName={onSetName} />
    </Modal>
);
DirectoryNameModal.propTypes = {
    name: PropTypes.string.isRequired,
    onSetName: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    buttonActions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DirectoryNameModal;
