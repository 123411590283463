import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M21 5.25v-3H0v16.5s0 3 3 3h18.75s2.25-.047 2.25-3V5.25zm-18 15c-1.5 0-1.5-1.5-1.5-1.5v-15h18v15c0 .656.234 1.172.516 1.5zM2.906 6.797h15.235v1.64H2.906zm8.438 9.094h5.203v1.64h-5.203zm0-2.907h6.797v1.594h-6.797zm0-3.234h6.797v1.594h-6.797zm-8.438 0H9.75v7.453H2.906zm0 0"
        />
    );
}
