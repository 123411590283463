import React from 'react';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import actions from 'store/actions';
import {INTERNAL} from 'lib/store/actions/tabMenu';
import Tooltip from 'components/util/Tooltip';

const Button = ({
    type,
    subType,
    onClick,
    children,
    translate,
    close,
    setActiveSidebar,
    sidebarData,
}) => (
    <Tooltip
        title={
            type === 'media'
                ? translate(`editor.add-${subType}`)
                : translate(`editor.add-${type}`)
        }
        position="bottom"
        trigger="mouseenter"
        theme="dark">
        <button
            className="md-sb-button"
            type="button"
            onClick={() => {
                if (onClick) {
                    onClick();
                } else {
                    close();
                    let newData = {};
                    if (subType) {
                        newData = Object.assign({}, sidebarData, {subType});
                    }
                    setActiveSidebar(type, newData);
                }
            }}>
            {children}
        </button>
    </Tooltip>
);

const mapStateToProps = state => ({
    sidebarData: state.editor.sidebarData,
    translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
    setActiveSidebar: (sidebarType, sidebarData) => {
        dispatch(actions.editor.setActiveSidebar(sidebarType, sidebarData));
        dispatch(actions.tabMenu.setActiveMenuItem('media', INTERNAL));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
