import React from 'react';
import {Translate} from 'react-localize-redux';
import Unavailable from 'lib/images/Unavailable';
import Landing from 'components/layout/Landing';
import LandingContent from 'components/layout/LandingContent';

const AppUnavailable = () => (
    <Landing>
        <LandingContent
            image={<Unavailable />}
            title={<Translate id="global.not-available" />}
            description={<Translate id="global.try-again" />}
        />
    </Landing>
);

export default AppUnavailable;
