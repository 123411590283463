import React from 'react';

import MervieldeBusiness from 'lib/icons/MervieldeBusiness';
import MervieldePeople from 'lib/icons/MervieldePeople';
import MervieldeSpotted from 'lib/icons/MervieldeSpotted';
import HiperTalkFacil202x from 'lib/icons/HiperTalkFacil202x';
import Actions from '../icons/Actions';
import Add from '../icons/Add';
import AddFilter from '../icons/AddFilter';
import ArrowBackUp from '../icons/ArrowBackUp';
import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';
import ArrowUp from '../icons/ArrowUp';
import Bin from '../icons/Bin';
import Bold from '../icons/Bold';
import Bookmark from '../icons/Bookmark';
import Box from '../icons/Box';
import BsMagic from '../icons/BsMagic';
import Calendar from '../icons/Calendar';
import CalendarAdd from '../icons/CalendarAdd';
import CaretRight from '../icons/CaretRight';
import Chat from '../icons/Chat';
import Check from '../icons/Check';
import CheckRoundChecked from '../icons/CheckRoundChecked';
import CheckRoundUnchecked from '../icons/CheckRoundUnchecked';
import Cheer from '../icons/Cheer';
import ChevronDown from '../icons/ChevronDown';
import ChevronLargeLeft from '../icons/ChevronLargeLeft';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';
import Clock from '../icons/Clock';
import ClockBack from '../icons/ClockBack';
import Code from '../icons/Code';
import Comment from '../icons/Comment';
import Comments from '../icons/Comments';
import Company from '../icons/Company';
import Copy from '../icons/Copy';
import Cross from '../icons/Cross';
import Dashboard from '../icons/Dashboard';
import Document from '../icons/Document';
import Documents from '../icons/Documents';
import Download from '../icons/Download';
import Draft from '../icons/Draft';
import Dropdown from '../icons/Dropdown';
import Edit from '../icons/Edit';
import EditUsers from '../icons/EditUsers';
import Email from '../icons/Email';
import Error from '../icons/Error';
import Event from '../icons/Event';
import EventAdd from '../icons/EventAdd';
import EventStar from '../icons/EventStar';
import Eye from '../icons/Eye';
import FacebookMessenger from '../icons/FacebookMessenger';
import File from '../icons/File';
import FileAdd from '../icons/FileAdd';
import FilePdf from '../icons/FilePdf';
import FilePowerpoint from '../icons/FilePowerpoint';
import FileSpreadsheet from '../icons/FileSpreadsheet';
import FileVideo from '../icons/FileVideo';
import FileWord from '../icons/FileWord';
import Folder from '../icons/Folder';
import Folders from '../icons/Folders';
import Fontsize from '../icons/Fontsize';
import Home from '../icons/Home';
import HoverBreak from '../icons/HoverBreak';
import Image from '../icons/Image';
import Images from '../icons/Images';
import Inbox from '../icons/Inbox';
import Info from '../icons/Info';
import Italic from '../icons/Italic';
import Layers from '../icons/Layers';
import Link from '../icons/Link';
import List from '../icons/List';
import Location from '../icons/Location';
import Magnifier from '../icons/Magnifier';
import Mail from '../icons/Mail';
import Marker from '../icons/Marker';
import Menu from '../icons/Menu';
import MenuDots from '../icons/MenuDots';
import MessageCircle from '../icons/MessageCircle';
import MobilePhone from '../icons/MobilePhone';
import MultipleChoice from '../icons/MultipleChoice';
import Newspaper from '../icons/Newspaper';
import NewUser from '../icons/NewUser';
import NoSearch from '../icons/NoSearch';
import Pen from '../icons/Pen';
import Phone from '../icons/Phone';
import Play from '../icons/Play';
import Plus from '../icons/Plus';
import PlusCircle from '../icons/PlusCircle';
import Polls from '../icons/Polls';
import Privacy from '../icons/Privacy';
import Quality from '../icons/Quality';
import Quote from '../icons/Quote';
import Rename from '../icons/Rename';
import Roger from '../icons/Roger';
import Safety from '../icons/Safety';
import Scale from '../icons/Scale';
import Search from '../icons/Search';
import SearchChart from '../icons/SearchChart';
import SearchEye from '../icons/SearchEye';
import Segmentation from '../icons/Segmentation';
import Send from '../icons/Send';
import SendMail from '../icons/SendMail';
import Settings from '../icons/Settings';
import SizeExpand from '../icons/SizeExpand';
import SizeFullWidth from '../icons/SizeFullWidth';
import SizeNormal from '../icons/SizeNormal';
import SizeSmall from '../icons/SizeSmall';
import SmartTag from '../icons/SmartTag';
import Smiley from '../icons/Smiley';
import SmileySolid from '../icons/SmileySolid';
import SpinnerThird from '../icons/SpinnerThird';
import Star from '../icons/Star';
import Survey from '../icons/Survey';
import Switch from '../icons/Switch';
import Templates from '../icons/Templates';
import Timeline from '../icons/Timeline';
import Trash from '../icons/Trash';
import Trumpet from '../icons/Trumpet';
import Underline from '../icons/Underline';
import Unlock from '../icons/Unlock';
import Upload from '../icons/Upload';
import User from '../icons/User';
import Users from '../icons/Users';
import Video from '../icons/Video';
import WhatsApp from '../icons/WhatsApp';
import YesNo from '../icons/YesNo';
import Zoom from '../icons/Zoom';
import BaltaCommunication from '../icons/BaltaCommunication';
import BaltaEvents from '../icons/BaltaEvents';
import BaltaMission from '../icons/BaltaMission';
import BaltaNews from '../icons/BaltaNews';
import BaltaOnboarding from '../icons/BaltaOnboarding';
import BaltaPolls from '../icons/BaltaPolls';
import BaltaQuality from '../icons/BaltaQuality';
import BaltaSafety from '../icons/BaltaSafety';
import BaltaSuccess from '../icons/BaltaSuccess';
import BaltaVacancies from '../icons/BaltaVacancies';
import MervieldePolls from '../icons/MervieldePolls';
import MervieldeEducation from '../icons/MervieldeEducation';
import MervieldeEvents from '../icons/MervieldeEvents';
import MervieldeFun from '../icons/MervieldeFun';
import MervieldeJobs from '../icons/MervieldeJobs';
import MervieldeNews from '../icons/MervieldeNews';
import MervieldeOnboarding from '../icons/MervieldeOnboarding';
import MervieldeSafetyQuality from '../icons/MervieldeSafetyQuality';
import Microsoft from '../icons/Microsoft';
import HellocoeckAandacht from '../icons/HellocoeckAandacht';
import HellocoeckHrUpdate from '../icons/HellocoeckHrUpdate';
import HellocoeckOverCoeck from '../icons/HellocoeckOverCoeck';
import HellocoeckWistjedat from '../icons/HellocoeckWistjedat';
import HellocoeckBevraging from '../icons/HellocoeckBevraging';
import HellocoeckItUpdate from '../icons/HellocoeckItUpdate';
import HellocoeckVacature from '../icons/HellocoeckVacature';
import HellocoeckWoordjeVanHetBestuur from '../icons/HellocoeckWoordjeVanHetBestuur';
import HellocoeckEvent from '../icons/HellocoeckEvent';
import HellocoeckOpleiding from '../icons/HellocoeckOpleiding';
import HellocoeckProficiat from '../icons/HellocoeckProficiat';
import HellocoeckVeiligheid from '../icons/HellocoeckVeiligheid';
import AspiraviProjectNewHorizon from '../icons/AspiraviProjectNewHorizon';
import HiperTalkHr from '../icons/HiperTalkHr';
import HiperTalkBirthday from '../icons/HiperTalkBirthday';
import HiperTalkIct from '../icons/HiperTalkIct';
import HiperTalkDigital from '../icons/HiperTalkDigital';
import HiperTalkThumb from '../icons/HiperTalkThumb';
import HiperTalkTraining from '../icons/HiperTalkTraining';
import Refresh from '../icons/Refresh';
import Archive from '../icons/Archive';
import TiaSafety from '../icons/TiaSafety';
import TiaQuality from '../icons/TiaQuality';
import TiaNews from '../icons/TiaNews';
import TiaEvents from '../icons/TiaEvents';
import TiaAfterSales from '../icons/TiaAfterSales';
import TiaMarketing from '../icons/TiaMarketing';
import TiaConversation from '../icons/TiaConversation';
import TiaAutomotive from '../icons/TiaAutomotive';
import TiaHR from '../icons/TiaHR';
import TiaSuccessStories from '../icons/TiaSuccessStories';
import AdminInboxIcon from '../icons/AdminInboxIcon';
import RogerIcon from '../icons/RogerIcon';
import AnonymousIcon from '../icons/AnonymousIcon';
import IdeaIcon from '../icons/IdeaIcon';

export const actions = 'actions';
export const add = 'add';
export const addFilter = 'addFilter';
export const arrowBackUp = 'arrowBackUp';
export const arrowLeft = 'arrowLeft';
export const arrowUp = 'arrowUp';
export const bin = 'bin';
export const bold = 'bold';
export const bookmark = 'bookmark';
export const box = 'box';
export const bsMagic = 'bsMagic';
export const calendar = 'calendar';
export const calendarAdd = 'calendarAdd';
export const caretRight = 'caretRight';
export const chat = 'chat';
export const check = 'check';
export const checkRoundChecked = 'checkRoundChecked';
export const checkRoundUnchecked = 'checkRoundUnchecked';
export const cheer = 'cheer';
export const chevronDown = 'chevronDown';
export const chevronLargeLeft = 'chevronLargeLeft';
export const chevronLeft = 'chevronLeft';
export const chevronRight = 'chevronRight';
export const clock = 'clock';
export const clockBack = 'clockBack';
export const code = 'code';
export const comment = 'comment';
export const comments = 'comments';
export const company = 'company';
export const copy = 'copy';
export const cross = 'cross';
export const dashboard = 'dashboard';
export const document = 'document';
export const documents = 'documents';
export const download = 'download';
export const draft = 'draft';
export const dropdown = 'dropdown';
export const edit = 'edit';
export const editUsers = 'editUsers';
export const email = 'email';
export const error = 'error';
export const event = 'event';
export const eventAdd = 'eventAdd';
export const eventStar = 'eventStar';
export const eye = 'eye';
export const facebookMessenger = 'facebookMessenger';
export const file = 'file';
export const fileAdd = 'fileAdd';
export const filePdf = 'filePdf';
export const filePowerpoint = 'filePowerpoint';
export const fileSpreadsheet = 'fileSpreadsheet';
export const fileExcel = 'fileExcel';
export const fileVideo = 'fileVideo';
export const fileWord = 'fileWord';
export const fileDocument = 'fileDocument';
export const folder = 'folder';
export const folders = 'folders';
export const fontsize = 'fontsize';
export const home = 'home';
export const image = 'image';
export const images = 'images';
export const inbox = 'inbox';
export const info = 'info';
export const italic = 'italic';
export const layers = 'layers';
export const link = 'link';
export const list = 'list';
export const location = 'location';
export const magnifier = 'magnifier';
export const mail = 'mail';
export const marker = 'marker';
export const menu = 'menu';
export const menuDots = 'menuDots';
export const messageCircle = 'messageCircle';
export const mobilePhone = 'mobilePhone';
export const multipleChoice = 'multipleChoice';
export const microsoft = 'microsoft';
export const newspaper = 'newspaper';
export const newUser = 'newUser';
export const noSearch = 'noSearch';
export const pen = 'pen';
export const phone = 'phone';
export const play = 'play';
export const plus = 'plus';
export const plusCircle = 'plusCircle';
export const polls = 'polls';
export const privacy = 'privacy';
export const quality = 'quality';
export const quote = 'quote';
export const rename = 'rename';
export const roger = 'roger';
export const safety = 'safety';
export const scale = 'scale';
export const search = 'search';
export const searchChart = 'searchChart';
export const searchEye = 'searchEye';
export const segmentation = 'segmentation';
export const send = 'send';
export const sendMail = 'sendMail';
export const settings = 'settings';
export const sizeExpand = 'sizeExpand';
export const sizeFullWidth = 'sizeFullWidth';
export const sizeNormal = 'sizeNormal';
export const sizeSmall = 'sizeSmall';
export const smartTag = 'smartTag';
export const smiley = 'smiley';
export const smileySolid = 'smileySolid';
export const spinnerThird = 'spinnerThird';
export const star = 'star';
export const survey = 'survey';
export const switchIcon = 'switch';
export const templates = 'templates';
export const timeline = 'timeline';
export const trash = 'trash';
export const trumpet = 'trumpet';
export const underline = 'underline';
export const unlock = 'unlock';
export const upload = 'upload';
export const user = 'user';
export const users = 'users';
export const video = 'video';
export const whatsApp = 'whatsApp';
export const yesNo = 'yesNo';
export const zoom = 'zoom';
export const refresh = 'refresh';
export const archive = 'archive';
export const adminInbox = 'adminInbox';
export const rogerIcon = 'rogerIcon';

export const Icons = new Map([
    ['arrow-right', <ArrowRight />],
    ['arrow-up', <ArrowUp />],
    ['caret-right', <CaretRight />],
    ['chevron-left', <ChevronLeft />],
    ['chevron-right', <ChevronRight />],
    ['event-add', <EventAdd />],
    ['event-star', <EventStar />],
    ['hover-break', <HoverBreak />],
    ['multiple-choice', <MultipleChoice />],
    ['new-user', <NewUser />],
    ['search-eye', <SearchEye />],
    ['size-expand', <SizeExpand />],
    ['size-full-width', <SizeFullWidth />],
    ['size-normal', <SizeNormal />],
    ['size-small', <SizeSmall />],
    ['smart-tag', <SmartTag />],
    ['smiley-o', <Smiley />],
    ['surveys', <Survey />],
    ['template', <Templates />],
    ['time', <Clock />],
    ['yes-no', <YesNo />],
    [actions, <Actions />],
    [add, <Add />],
    [addFilter, <AddFilter />],
    [arrowBackUp, <ArrowBackUp />],
    [arrowLeft, <ArrowLeft />],
    [arrowUp, <ArrowUp />],
    [bin, <Bin />],
    [bold, <Bold />],
    [bookmark, <Bookmark />],
    [box, <Box />],
    [bsMagic, <BsMagic />],
    [calendar, <Calendar />],
    [calendarAdd, <CalendarAdd />],
    [caretRight, <CaretRight />],
    [chat, <Chat />],
    [check, <Check />],
    [checkRoundChecked, <CheckRoundChecked />],
    [checkRoundUnchecked, <CheckRoundUnchecked />],
    [cheer, <Cheer />],
    [chevronDown, <ChevronDown />],
    [chevronLargeLeft, <ChevronLargeLeft />],
    [chevronLeft, <ChevronLeft />],
    [chevronRight, <ChevronRight />],
    [clock, <Clock />],
    [clockBack, <ClockBack />],
    [code, <Code />],
    [comment, <Comment />],
    [comments, <Comments />],
    [company, <Company />],
    [copy, <Copy />],
    [cross, <Cross />],
    [dashboard, <Dashboard />],
    [document, <Document />],
    [documents, <Documents />],
    [download, <Download />],
    [draft, <Draft />],
    [dropdown, <Dropdown />],
    [edit, <Edit />],
    [editUsers, <EditUsers />],
    [email, <Email />],
    [error, <Error />],
    [event, <Event />],
    [eventAdd, <EventAdd />],
    [eventStar, <EventStar />],
    [eye, <Eye />],
    [facebookMessenger, <FacebookMessenger />],
    [file, <File />],
    [fileAdd, <FileAdd />],
    [filePdf, <FilePdf />],
    [filePowerpoint, <FilePowerpoint />],
    [fileSpreadsheet, <FileSpreadsheet />],
    [fileExcel, <FileSpreadsheet />],
    [fileVideo, <FileVideo />],
    [fileWord, <FileWord />],
    [fileDocument, <File />],
    [folder, <Folder />],
    [folders, <Folders />],
    [fontsize, <Fontsize />],
    [home, <Home />],
    [image, <Image />],
    [images, <Images />],
    [inbox, <Inbox />],
    [info, <Info />],
    [italic, <Italic />],
    [layers, <Layers />],
    [link, <Link />],
    [list, <List />],
    [location, <Location />],
    [magnifier, <Magnifier />],
    [mail, <Mail />],
    [marker, <Marker />],
    [menu, <Menu />],
    [menuDots, <MenuDots />],
    [messageCircle, <MessageCircle />],
    [mobilePhone, <MobilePhone />],
    [multipleChoice, <MultipleChoice />],
    [microsoft, <Microsoft />],
    [newspaper, <Newspaper />],
    [newUser, <NewUser />],
    [noSearch, <NoSearch />],
    [pen, <Pen />],
    [phone, <Phone />],
    [play, <Play />],
    [plus, <Plus />],
    [plusCircle, <PlusCircle />],
    [polls, <Polls />],
    [privacy, <Privacy />],
    [quality, <Quality />],
    [quote, <Quote />],
    [rename, <Rename />],
    [roger, <Roger />],
    [safety, <Safety />],
    [scale, <Scale />],
    [search, <Search />],
    [searchChart, <SearchChart />],
    [searchEye, <SearchEye />],
    [segmentation, <Segmentation />],
    [send, <Send />],
    [sendMail, <SendMail />],
    [settings, <Settings />],
    [sizeExpand, <SizeExpand />],
    [sizeFullWidth, <SizeFullWidth />],
    [sizeNormal, <SizeNormal />],
    [sizeSmall, <SizeSmall />],
    [smartTag, <SmartTag />],
    [smiley, <SmileySolid />],
    [smileySolid, <SmileySolid />],
    [spinnerThird, <SpinnerThird />],
    [star, <Star />],
    [survey, <Survey />],
    [switchIcon, <Switch />],
    [templates, <Templates />],
    [timeline, <Timeline />],
    [trash, <Trash />],
    [trumpet, <Trumpet />],
    [underline, <Underline />],
    [unlock, <Unlock />],
    [upload, <Upload />],
    [user, <User />],
    [users, <Users />],
    [video, <Video />],
    [whatsApp, <WhatsApp />],
    [yesNo, <YesNo />],
    [zoom, <Zoom />],
    [refresh, <Refresh />],
    [archive, <Archive />],
    ['balta-icon-communication', <BaltaCommunication />],
    ['balta-icon-events', <BaltaEvents />],
    ['balta-icon-mission', <BaltaMission />],
    ['balta-icon-news', <BaltaNews />],
    ['balta-icon-onboarding', <BaltaOnboarding />],
    ['balta-icon-polls', <BaltaPolls />],
    ['balta-icon-quality', <BaltaQuality />],
    ['balta-icon-safety', <BaltaSafety />],
    ['balta-icon-success', <BaltaSuccess />],
    ['balta-icon-vacancies', <BaltaVacancies />],
    ['mervielde-icon-business', <MervieldeBusiness />],
    ['mervielde-icon-polls', <MervieldePolls />],
    ['mervielde-icon-education', <MervieldeEducation />],
    ['mervielde-icon-events', <MervieldeEvents />],
    ['mervielde-icon-fun', <MervieldeFun />],
    ['mervielde-icon-jobs', <MervieldeJobs />],
    ['mervielde-icon-news', <MervieldeNews />],
    ['mervielde-icon-onboarding', <MervieldeOnboarding />],
    ['mervielde-icon-people', <MervieldePeople />],
    ['mervielde-icon-spotted', <MervieldeSpotted />],
    ['mervielde-icon-safety-quality', <MervieldeSafetyQuality />],
    ['hellocoeck-icon-aandacht', <HellocoeckAandacht />],
    ['hellocoeck-icon-hr-update', <HellocoeckHrUpdate />],
    ['hellocoeck-icon-over-coeck', <HellocoeckOverCoeck />],
    ['hellocoeck-icon-wistjedat', <HellocoeckWistjedat />],
    ['hellocoeck-icon-bevraging', <HellocoeckBevraging />],
    ['hellocoeck-icon-it-update', <HellocoeckItUpdate />],
    ['hellocoeck-icon-vacature', <HellocoeckVacature />],
    [
        'hellocoeck-icon-woordje-van-het-bestuur',
        <HellocoeckWoordjeVanHetBestuur />,
    ],
    ['hellocoeck-icon-event', <HellocoeckEvent />],
    ['hellocoeck-icon-opleiding', <HellocoeckOpleiding />],
    ['hellocoeck-icon-veiligheid', <HellocoeckVeiligheid />],
    ['hellocoeck-icon-congrats', <HellocoeckProficiat />],
    ['aspiravi-icon-project-new-horizon', <AspiraviProjectNewHorizon />],
    ['hipertalk-icon-hr', <HiperTalkHr />],
    ['hipertalk-icon-birthday', <HiperTalkBirthday />],
    ['hipertalk-icon-ict', <HiperTalkIct />],
    ['hipertalk-icon-digital', <HiperTalkDigital />],
    ['hipertalk-icon-thumb', <HiperTalkThumb />],
    ['hipertalk-icon-facil-202x', <HiperTalkFacil202x />],
    ['hipertalk-icon-training', <HiperTalkTraining />],
    ['tia-safety', <TiaSafety />],
    ['tia-quality', <TiaQuality />],
    ['tia-news', <TiaNews />],
    ['tia-events', <TiaEvents />],
    ['tia-aftersales', <TiaAfterSales />],
    ['tia-conversation', <TiaConversation />],
    ['tia-automotive', <TiaAutomotive />],
    ['tia-marketing', <TiaMarketing />],
    ['tia-hr', <TiaHR />],
    ['tia-successstories', <TiaSuccessStories />],
    ['adminInbox', <AdminInboxIcon />],
    ['rogerIcon', <RogerIcon />],
    ['idea_box', <IdeaIcon />],
    ['whistle_blower', <AnonymousIcon />],
]);

const Icon = ({
    className,
    size = 24,
    name,
    color = 'currentColor',
    strokeWidth = 2,
}) => (
    <svg
        className={className}
        width={size}
        height={size}
        strokeWidth={strokeWidth}
        viewBox="0 0 24 24"
        fill="none"
        style={{color}}>
        {Icons.get(name)}
    </svg>
);

export default Icon;
