import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {format} from 'date-fns';
import {
    STATUS_PUBLISHED,
    MESSAGES_SUB_TYPE_OWN,
    MESSAGES_SUB_TYPE_ASSIGNED,
} from 'lib/store/actions/messages';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';
import SearchInput from 'lib/components/SearchInput';
import Table from 'components/Table/Table';
import AudienceLink from 'components/AudienceLink';
import {DATE_TIME_FORMAT} from 'lib/constants/datetime';
import {AUTOMATED, MANUAL} from 'lib/constants/messages';
import ContentView from 'components/util/ContentView';
import UsersModal from 'modals/UsersModal';
import Icon from 'lib/components/Icon';
import PropTypes from 'prop-types';
import ButtonGroup from '../../../lib/components/ButtonGroup';
import Button from '../../util/Button';
import useRoles from '../../hooks/useRoles';
import ButtonWithoutStyle from '../../../lib/components/ButtonWithoutStyle';
import {MODAL_CONFIRM} from '../../../lib/store/actions/modals';

const SentMessages = ({
    fetchMessages,
    editMessage,
    translate,
    messages,
    pages,
    isFetching,
    isFailed,
    pageCount,
    company,
    total,
}) => {
    const {isAdmin, isPublisher} = useRoles();
    const status = STATUS_PUBLISHED;
    const [type, setType] = useState(MANUAL);
    const [query, setQuery] = useState('');
    const [sort, setSort] = useState({published_at: 'desc'});
    const dispatch = useDispatch();
    const [appDomain] = useState('');

    const archiveMessage = messageId =>
        dispatch(actions.messages.deleteMessage(messageId));
    const copyMessage = messageId =>
        dispatch(actions.messages.copyMessage(messageId));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));

    const onCopy = (clickEvent, messageId) => {
        clickEvent.stopPropagation();

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('editor.confirm_copy_message'),
            buttonActions: [
                {
                    label: translate('global.copy'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        copyMessage(messageId);
                    },
                },
            ],
        });
    };

    const onArchive = (clickEvent, messageId) => {
        clickEvent.stopPropagation();

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('editor.confirm_archive_message'),
            buttonActions: [
                {
                    label: translate('global.archive'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        archiveMessage(messageId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    let subType = '';
    if (!isAdmin) {
        if (type === MANUAL) {
            subType = MESSAGES_SUB_TYPE_OWN;
        } else {
            subType = MESSAGES_SUB_TYPE_ASSIGNED;
        }
    }

    const fetch = page =>
        fetchMessages({
            filters: {
                status,
                subType,
                type,
                query,
                sort,
                appDomain,
            },
            page,
        });

    useEffect(() => {
        fetch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, sort, type, appDomain]);

    const onTableEnd = () => {
        if (!isFetching && !isFailed) {
            if (pages.length < pageCount) {
                fetch(pages.length + 1);
            }
        }
    };

    const onRowClick = messageId => {
        const message = getById(messages, messageId);

        editMessage(message);
    };

    const columns = [
        {
            key: 'app_domain',
            className: 'table__icon',
            renderer: (content, data) => <Icon name={data} />,
            sortable: query === '',
        },
        {
            key: 'title',
            label: translate('global.title'),
        },
        {
            key: 'audience',
            label: translate('global.audience'),
            renderer: (content, {messageId, sentToCalculation}) => (
                <AudienceLink
                    users={content}
                    sentToCalculation={sentToCalculation}
                    messageId={messageId}
                />
            ),
            nowrap: true,
        },
        {
            key: 'updated_at',
            label: translate('drafts.last_edited'),
            sortable: query === '',
            nowrap: true,
        },
        {
            key: 'published_at',
            label: translate('sent_messages.sent_at'),
            sortable: query === '',
            nowrap: true,
        },
        {
            key: 'actions',
            label: translate('global.actions'),
            renderer: (content, {messageId}) => {
                if (type === MANUAL) {
                    return (
                        <div className="action-group-btns">
                            <ButtonWithoutStyle
                                onClick={clickEvent =>
                                    onCopy(clickEvent, messageId)
                                }
                                className="icon"
                                title={translate('editor.copy_message')}>
                                <Icon size={20} name="copy" />
                            </ButtonWithoutStyle>

                            <ButtonWithoutStyle
                                onClick={clickEvent =>
                                    onArchive(clickEvent, messageId)
                                }
                                className="icon danger"
                                title={translate('editor.archive_message')}>
                                <Icon size={20} name="trash" />
                            </ButtonWithoutStyle>
                            <style jsx>
                                {`
                                    .action-group-btns {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-around;
                                    }
                                `}
                            </style>
                        </div>
                    );
                }
                return (
                    <div className="action-group-btns">
                        <ButtonWithoutStyle
                            onClick={clickEvent =>
                                onArchive(clickEvent, messageId)
                            }
                            className="icon danger"
                            title={translate('editor.archive_message')}>
                            <Icon size={20} name="trash" />
                        </ButtonWithoutStyle>
                        <style jsx>
                            {`
                                .action-group-btns {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                }
                            `}
                        </style>
                    </div>
                );
            },
        },
    ];

    if (type === MANUAL && isAdmin) {
        columns.splice(3, 0, {
            key: 'created_by',
            label: translate('drafts.created_by'),
            nowrap: true,
        });
    }

    let rows = [];
    if (pages.length) {
        rows = pages.reduce(
            (accumulator, page) => [
                ...accumulator,
                ...page.map(messageId => {
                    const {
                        app_domain: appDomainFromMessage,
                        title,
                        id,
                        published_at: publishedAt,
                        updated_at: updatedAt,
                        created_by: createdBy,
                        sent_to_count: audienceCount,
                        sent_to_calculation: sentToCalculation,
                    } = getById(messages, messageId);

                    const data = [
                        {
                            className: 'table__icon',
                            data: appDomainFromMessage
                                ? appDomainFromMessage.icon
                                : null,
                        },
                        {
                            className: 'font-weight-bold',
                            content: title[company.default_language],
                        },
                        {
                            content: audienceCount || 0,
                            data: {
                                sentToCalculation,
                                messageId,
                            },
                        },
                        {content: format(updatedAt, DATE_TIME_FORMAT)},
                        {content: format(publishedAt, DATE_TIME_FORMAT)},
                        {
                            content: '',
                            data: {
                                messageId,
                            },
                        },
                    ];

                    if (type === MANUAL && isAdmin) {
                        data.splice(3, 0, {
                            content: createdBy
                                ? `${createdBy.first_name} ${createdBy.last_name}`
                                : '',
                        });
                    }

                    return {
                        key: id,
                        data,
                    };
                }),
            ],
            [],
        );
    }
    const headerComponents = (
        <>
            {!isPublisher && (
                <ButtonGroup>
                    <Button
                        onClick={() => setType(MANUAL)}
                        isInActive={type !== MANUAL}>
                        {translate('sent_messages.manual')}
                    </Button>
                    <Button
                        onClick={() => setType(AUTOMATED)}
                        isInActive={type !== AUTOMATED}>
                        {translate('sent_messages.automatic')}
                    </Button>
                </ButtonGroup>
            )}
            <SearchInput
                placeholder={translate('sent_messages.search')}
                value={query || ''}
                onChange={setQuery}
            />
        </>
    );

    return (
        <ContentView
            title={translate('sent_messages.title')}
            total={total}
            headerComponents={headerComponents}>
            <Table
                rows={rows}
                columns={columns}
                onTableEnd={() => {
                    onTableEnd();
                }}
                onRowClick={messageId => {
                    onRowClick(messageId);
                }}
                loading={isFetching}
                emptyTitle={translate('sent_messages.no_messages')}
                sort={sort || {}}
                onSortChange={setSort}
            />
            <UsersModal />
        </ContentView>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    messages: state.messages.byId,
    isFetching: state.messages.paginatedSentMessages.isFetching,
    isFailed: state.messages.paginatedSentMessages.isFailed,
    pages: state.messages.paginatedSentMessages.pages,
    pageCount: state.messages.paginatedSentMessages.pageCount,
    company: state.company.data,
    total: state.messages.paginatedSentMessages.total,
});

const mapDispatchToProps = (dispatch, props) => ({
    editMessage: message => {
        const {history} = props;
        history.push(`/editor/messages/${message.id}`);
    },
    fetchMessages: params =>
        dispatch(actions.messages.fetchMessages(params, 'view-sent-messages')),
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
});
SentMessages.propTypes = {
    fetchMessages: PropTypes.func.isRequired,
    editMessage: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(PropTypes.any).isRequired,
    pages: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFailed: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    company: PropTypes.shape({
        default_language: PropTypes.string.isRequired,
    }).isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(SentMessages);
