import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M5.94995 13H12.85"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 16H9.89"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.893 21H4.97C3.882 21 3 20.105 3 19V5C3 3.895 3.882 3 4.97 3H15.03C16.118 3 17 3.895 17 5V11"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.293 12.707L13 19V21H15L21.293 14.707C21.3859 14.6142 21.4597 14.504 21.51 14.3827C21.5603 14.2614 21.5862 14.1313 21.5862 14C21.5862 13.8687 21.5603 13.7386 21.51 13.6173C21.4597 13.496 21.3859 13.3858 21.293 13.293L20.707 12.707C20.5195 12.5195 20.2652 12.4142 20 12.4142C19.7348 12.4142 19.4805 12.5195 19.293 12.707Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 9H6V6H7.5C7.89782 6 8.27936 6.15804 8.56066 6.43934C8.84196 6.72064 9 7.10218 9 7.5C9 7.89782 8.84196 8.27936 8.56066 8.56066C8.27936 8.84196 7.89782 9 7.5 9V9Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
