import React from 'react';

export default ({src, type}) => (
    <div className="audio-block">
        <audio controls>
            <source src={src} type={type} />
        </audio>

        <style jsx>
            {`
                .audio-block {
                    white-space: normal;

                    audio {
                        width: 100%;
                    }
                }
            `}
        </style>
    </div>
);
