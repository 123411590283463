import React, {useEffect, useMemo, useRef} from 'react';
import copy from 'copy-to-clipboard';
import debounce from 'lodash.debounce';
import {useDispatch} from 'react-redux';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import RogerEditor from 'components/RogerEditor/RogerEditor';
import useContentSaver from 'components/hooks/useContentSaver';
import RogerEditorWithState from 'components/RogerEditor/RogerEditorWithState';
import {actions} from 'store/index';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import useFetchSurveys from 'components/hooks/useFetchSurveys';
import EditorHeader from './EditorHeader';

const EditorWithState = () => {
    const editorRef = useRef(null);
    const {editor, canEdit, hasSurveys, editorState} = useEditorState();
    const {translate} = useTranslate();
    const dispatch = useDispatch();
    const showSuccess = text =>
        dispatch(actions.notifications.showSuccess(text));
    const {saveContent} = useContentSaver(true);
    const setEditorTitle = newTitle =>
        dispatch(actions.editor.setTitle(newTitle));
    const useDebounce = (callback, debounceTime) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                if (ref.current) {
                    ref.current();
                }
            };

            return debounce(func, 10000);
        }, []);

        return debouncedCallback;
    };
    const debouncedSaveContent = useDebounce(() => saveContent(editor));

    const onTitleChange = newTitle => {
        const editorTitle = editor.title;
        setEditorTitle({...editorTitle, [editor.currentLanguage]: newTitle});
        debouncedSaveContent();
    };

    const onShareMessage = async () => {
        copy(editor.shareUrl);
        showSuccess(translate('global.copy_to_clipboard'));
    };

    // Update state when language changes
    const translatedTitle = editor.title[editor.currentLanguage] || '';

    const {isLoading} = useFetchSurveys({editor, hasSurveys});

    if (isLoading) {
        return <LoadingWrapper loading size={8} />;
    }

    return (
        <>
            <EditorHeader
                title={translatedTitle}
                canEdit={canEdit}
                editorRef={editorRef}
                hasSurveys={hasSurveys}
                onTitleChange={onTitleChange}
                onShareMessage={onShareMessage}
                shareUrl={editor.shareUrl}
                translate={translate}
                currentLanguage={editor.currentLanguage}
            />
            {canEdit ? (
                <RogerEditorWithState
                    ref={editorRef}
                    editor={editor}
                    editorState={editorState}
                    canEdit={canEdit}
                    saveContent={saveContent}
                />
            ) : (
                <RogerEditor canEdit={canEdit} editorState={editorState} />
            )}
        </>
    );
};

export default EditorWithState;
