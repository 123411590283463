import React, {useRef} from 'react';
import {Translate} from 'react-localize-redux';
import styled from 'styled-components';
import ImageWrapper from 'lib/components/Images/ImageWrapper';
import Button from 'components/util/Button';

const Image = ({
    label,
    image,
    defaultImage,
    onChange,
    noDefaultText,
    className,
}) => {
    const inputRef = useRef(null);

    const editImage = () => {
        inputRef.current.click();
    };

    const hasImage = image || defaultImage;

    return (
        <>
            {!hasImage && <NoDefaultText>{noDefaultText}</NoDefaultText>}

            <Container role="img" onClick={editImage}>
                {hasImage && (
                    <ImageWrapper
                        src={image}
                        alt={label}
                        retryIfNotFound
                        disableProgressiveLoading
                        id={label}
                    />
                )}
            </Container>

            <Actions>
                {!hasImage && (
                    <Button onClick={editImage}>
                        <Translate id="global.add" />
                    </Button>
                )}
            </Actions>

            <form className="hidden">
                <input
                    type="file"
                    name="company_image"
                    style={{display: 'none'}}
                    ref={inputRef}
                    onChange={e => {
                        const {files = []} = e.target;
                        const [file] = files;
                        if (typeof file !== 'undefined') {
                            onChange(file);
                        }
                    }}
                    accept="image/*"
                />
            </form>
        </>
    );
};

const Container = styled.div(
    ({theme}) => `
    color: ${theme.brand.base};

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`,
);

const NoDefaultText = styled.span(
    ({theme}) => `
    font-size: ${theme.fontSizeSmallest}rem;
    margin-bottom: ${`${theme.spacing}rem 0`};
    display: block;
`,
);

const Actions = styled.div(
    ({theme}) => `
    margin-top: ${theme.spacing}rem;

    .button {
        margin-right: ${theme.spacing}rem;
    }
`,
);

export default Image;
