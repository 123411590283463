import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {MODAL_FILE_AUDIENCE} from 'lib/store/actions/modals';
import actions from 'store/actions';
import AudienceModal from 'modals/AudienceModal/AudienceModal';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import * as theme from 'styles/theme';
import {isEqual} from 'lodash';

const FileAudienceModalWithData = ({
    parentId,
    activeDirectoryId,
    segmentsFromProps = [],
}) => {
    const dispatch = useDispatch();
    const [segments, setSegments] = useState(segmentsFromProps);
    const [isSaving, setIsSaving] = useState(false);
    const modalData = useSelector(
        ({modals}) => modals.data[MODAL_FILE_AUDIENCE],
    );
    const isVisible = useSelector(
        ({modals}) => modals.visibility[MODAL_FILE_AUDIENCE],
    );
    const hasDefaultAudience =
        (modalData && modalData.hasDefaultAudience) || false;
    const name = (modalData && modalData.name) || '';
    const file = (modalData && modalData.file) || null;
    const action = (modalData && modalData.action) || null;

    const [defaultAudience, setDefaultAudience] = useState(hasDefaultAudience);
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data));
    const createFile = (newActiveDirectoryId, values) =>
        dispatch(actions.directories.createFile(newActiveDirectoryId, values));
    const updateFile = (newActiveDirectoryId, fileId, values) =>
        dispatch(
            actions.directories.updateFile(
                newActiveDirectoryId,
                fileId,
                values,
            ),
        );

    useEffect(() => {
        if (
            segmentsFromProps.length > 0 &&
            !isEqual(segments, segmentsFromProps)
        ) {
            setSegments(segmentsFromProps);
        }
    }, [segmentsFromProps, segments]);

    if (!isVisible) {
        return null;
    }

    return (
        <AudienceModal
            name={MODAL_FILE_AUDIENCE}
            segments={segments}
            onSegmentsChange={setSegments}
            onCancel={() => {
                setModalData(MODAL_FILE_AUDIENCE, {});
                setModalVisibility(MODAL_FILE_AUDIENCE, false);
            }}
            disableMenu={isSaving}
            isBig={!isSaving}
            onUpdateClick={async () => {
                // create file
                if (action === 'create') {
                    const formData = new FormData();
                    formData.append('name', name);
                    formData.append('media_file', file);
                    formData.append(
                        'audience_filters',
                        JSON.stringify(segments),
                    );
                    formData.append(
                        'default_audience_filters',
                        defaultAudience,
                    );

                    setIsSaving(true);
                    try {
                        await createFile(activeDirectoryId, formData);
                        setModalVisibility(MODAL_FILE_AUDIENCE, false);
                    } catch (error) {
                        //
                    }
                    setIsSaving(false);
                } else {
                    // = update
                    updateFile(activeDirectoryId, file.id, {
                        name: file.name,
                        audience_filters: segments,
                        default_audience_filters: defaultAudience,
                    });
                    setModalVisibility(MODAL_FILE_AUDIENCE, false);
                }
            }}
            parentId={parentId && parseInt(activeDirectoryId, 10)}
            hasDefaultAudienceFilters={defaultAudience}
            onDefaultAudienceFilterChange={setDefaultAudience}>
            {isSaving && (
                <div className="loader">
                    <LoadingWrapper loading />
                    <h2>
                        <Translate id="documents.uploading" />
                    </h2>

                    <style jsx>
                        {`
                            .loader {
                                margin: ${theme.spacingLargest}rem;
                                text-align: center;
                            }
                        `}
                    </style>
                </div>
            )}
        </AudienceModal>
    );
};

FileAudienceModalWithData.propTypes = {
    parentId: PropTypes.number,
    activeDirectoryId: PropTypes.number.isRequired,
    segmentsFromProps: PropTypes.arrayOf(PropTypes.object),
};
FileAudienceModalWithData.defaultProps = {
    parentId: null,
    segmentsFromProps: [],
};
export default FileAudienceModalWithData;
