import React from 'react';
import {brandingDefaults} from 'lib/styles/colors';
import Switch from 'components/util/Switch';

const EditorSidebarSwitch = ({value, label, canEdit, onChange}) => (
    <div key={2} className="form__group row">
        <label className="col-sm-8 label col-form-label">{label}</label>
        <div className="col-sm-4 editor-sidebar-switch">
            <Switch
                disabled={!canEdit}
                value={value}
                offColor={brandingDefaults.danger.base}
                onChange={onChange}
            />
        </div>
        <style jsx>
            {`
                .editor-sidebar-switch {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                }
            `}
        </style>
    </div>
);

export default EditorSidebarSwitch;
