import {CALL_API} from '../../constants/api';
import {GET} from '../services';

export const TIMEZONES_REQUEST = 'TIMEZONES_REQUEST';
export const TIMEZONES_SUCCESS = 'TIMEZONES_SUCCESS';
export const TIMEZONES_FAILURE = 'TIMEZONES_FAILURE';

export const fetchTimezones = () => ({
    [CALL_API]: {
        endpoint: '/timezones/',
        method: GET,
        types: [TIMEZONES_REQUEST, TIMEZONES_SUCCESS, TIMEZONES_FAILURE],
    },
});
