// eslint-disable-next-line import/prefer-default-export
const tryParse = json => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return json;
    }
};
export const getErrorNotification = obj => {
    // Return empty object when 422. This gets handled by api middleware
    const {message, payload, statusCode} = tryParse(obj);
    if (statusCode === 422) {
        return {};
    }
    if (typeof message !== 'undefined') {
        return {message};
    }
    if (typeof payload.message !== 'undefined' && payload.message !== '') {
        return {message: payload.message};
    }
    return {
        key: 'notifications.general_error_no_data',
    };
};

export const throwIfInvalidApiResponse = response => {
    if (!response || response.statusCode > 299) {
        throw new Error(JSON.stringify(response));
    }
};
