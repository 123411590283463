import React from 'react';
import {Translate} from 'react-localize-redux';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Select from 'components/util/Select';

const SuperUserSelect = ({
    error,
    options,
    isFetching,
    selectedSuperUsers,
    onChange,
}) => (
    <div className="form__group">
        <label className="label" htmlFor="superusers">
            <Translate id="editor.form.accessibleFor.label" />
        </label>

        {(() => {
            if (error) {
                return error;
            }

            return (
                <LoadingWrapper loading={isFetching}>
                    <Select
                        inputId="superusers"
                        value={selectedSuperUsers}
                        options={options}
                        onChange={onChange}
                        isMulti
                        isClearable={false}
                    />
                </LoadingWrapper>
            );
        })()}
    </div>
);

export default SuperUserSelect;
