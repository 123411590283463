import React, {Fragment} from 'react';
import {Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import ContentView from 'components/util/ContentView';
import * as theme from 'styles/theme';
import * as media from 'lib/styles/media';
import * as colors from 'lib/styles/colors';
import FileDropZone from 'components/util/FileDropZone';
import Button from 'components/util/Button';
import DownloadOption from './DownloadOption';
import {FETCHING, SUCCESS, FAILED} from './constants';
import Icon, {info} from '../../../lib/components/Icon';
import styled from 'styled-components';

const acceptTypes = ['.xlsx', '.csv'];

const OptionContainer = styled.div`
    width: 100%;
`;
const UsersBulkEdit = ({
    breadcrumbs,
    fetchUsersExport,
    isFetchingUsersExport,
    onValidationFilesChange,
    onFilesChange,
    ignoredColumns,
    userValidateState,
    userImportState,
    onShowImportErrors,
    onOpenGuidelines,
}) => {
    const isValidationSuccessful = userValidateState === SUCCESS;
    const hasValidationFailed = userValidateState === FAILED;
    const isSuccessful = userImportState === SUCCESS;
    const hasFailed = userImportState === FAILED;
    const hasIgnoredColumns = ignoredColumns.length > 0;

    return (
        <ContentView breadcrumbs={breadcrumbs} hasOverflow>
            <div className="usersBulkEdit">
                <div className="step">
                    <h2 className="title">
                        <Translate id="global.step" data={{step: 1}} />
                    </h2>
                    <div className="options">
                        <OptionContainer>
                            <DownloadOption
                                isDownloading={isFetchingUsersExport}
                                onStartDownload={fetchUsersExport}
                                buttonText={
                                    <Translate id="users.downloadUsers" />
                                }
                                title={<Translate id="users.updateUsers" />}
                                info={
                                    <Fragment>
                                        <p>
                                            <Translate id="users.startUsersFileDownload" />
                                        </p>
                                        <p>
                                            <Translate id="users.adjustUsersData" />
                                        </p>
                                    </Fragment>
                                }
                            />
                        </OptionContainer>
                    </div>
                </div>

                <div className="step">
                    <h2 className="title">
                        <Translate id="global.step" data={{step: 2}} />
                    </h2>
                    <p>
                        <Translate id="Add and edit users in the file." />
                    </p>
                    <Button onClick={onOpenGuidelines}>
                        <Translate id="Show guidelines" />
                    </Button>
                </div>

                <div className="step">
                    <h2 className="title">
                        <Translate id="global.step" data={{step: 3}} />
                    </h2>
                    <div>
                        <Translate id="users.validate" />

                        {(() => {
                            if (isValidationSuccessful) {
                                return (
                                    <p className="success">
                                        <br />
                                        <Translate id="global.uploadSuccessful" />{' '}
                                        <Translate id="users.validateSuccessful" />
                                    </p>
                                );
                            }
                        })()}
                    </div>

                    <div className="dropZone">
                        <FileDropZone
                            acceptTypes={acceptTypes}
                            onFilesChange={onValidationFilesChange}
                            isLoading={userValidateState === FETCHING}
                            isSuccessful={isValidationSuccessful}
                            hasFailed={hasValidationFailed}
                            actionText="Validate"
                        />
                    </div>
                </div>

                <div className="step">
                    <h2 className="title">
                        <Translate id="global.step" data={{step: 4}} />
                    </h2>
                    <p>
                        <Translate id="users.uploadInfo" />
                    </p>

                    <div className="uploadWarning">
                        <Icon name={info} />
                        <p>
                            <Translate id="users.uploadWarning" />
                        </p>
                    </div>

                    {(() => {
                        if (isSuccessful) {
                            return (
                                <p className="success">
                                    <Translate id="global.uploadSuccessful" />{' '}
                                    <Translate id="users.uploadDone" />
                                    {hasIgnoredColumns && (
                                        <span className="ignoredColumns">
                                            <Translate id="users.ignoredColumns" />
                                            <br />
                                            {ignoredColumns.join(', ')}
                                        </span>
                                    )}
                                </p>
                            );
                        }
                        if (hasFailed) {
                            return (
                                <div className="errorMessage">
                                    <p>
                                        <Translate id="users.uploadFailed" />{' '}
                                        <Translate id="users.tryAgain" />
                                    </p>
                                    <Button onClick={onShowImportErrors} error>
                                        <Translate id="users.showErrors" />
                                    </Button>
                                </div>
                            );
                        }
                    })()}

                    <div className="dropZone">
                        <FileDropZone
                            acceptTypes={acceptTypes}
                            onFilesChange={onFilesChange}
                            isLoading={userImportState === FETCHING}
                            isSuccessful={isSuccessful}
                            hasFailed={hasFailed}
                            actionText={<Translate id="users.uploadUsers" />}
                        />
                    </div>
                </div>

                <style jsx>
                    {`
                        .usersBulkEdit {
                            width: 100%;
                            max-width: 80rem;
                        }

                        .step {
                            margin-bottom: ${theme.spacingLargest}rem;
                        }

                        .title {
                            margin-bottom: ${theme.spacingLarge}rem;
                            font-weight: ${theme.fwBold};
                            font-size: ${theme.fontSizeLarge}rem;
                        }

                        .options {
                            display: flex;
                            flex-direction: column;

                            @media (${media.forMediumAndUp}) {
                                flex-direction: row;
                            }
                        }

                        .option {
                            flex: 1;
                        }

                        .or {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: ${theme.spacingLarge}rem;
                            font-size: ${theme.fontSizeLarger}rem;
                            font-weight: ${theme.fwBold};
                        }

                        .dropZone {
                            margin: ${`${theme.spacingLarger}rem 0`};
                        }

                        .errorMessage {
                            color: ${colors.danger.base};
                        }

                        .errorMessage p {
                            margin-bottom: ${theme.spacing}rem;
                        }

                        .errorMessage a:hover {
                            text-decoration: underline;
                        }

                        .ignoredColumns {
                            color: ${colors.warning.dark};
                            margin-top: ${theme.spacing}rem;
                            display: block;
                        }

                        .success {
                            color: ${colors.success.dark};
                        }

                        .uploadWarning {
                            font-style: italic;
                            font-weight: bold;
                            display: inline-flex;
                        }
                        .uploadWarning p {
                            margin-left: 0.5rem;
                        }
                    `}
                </style>
            </div>
        </ContentView>
    );
};

UsersBulkEdit.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.any).isRequired,
    fetchUsersExport: PropTypes.func.isRequired,
    isFetchingUsersExport: PropTypes.bool.isRequired,
    onValidationFilesChange: PropTypes.func.isRequired,
    onFilesChange: PropTypes.func.isRequired,
    ignoredColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
    userValidateState: PropTypes.string.isRequired,
    userImportState: PropTypes.string.isRequired,
    onShowImportErrors: PropTypes.func.isRequired,
    onOpenGuidelines: PropTypes.func.isRequired,
};
export default UsersBulkEdit;
