import React from 'react';
import styled from 'styled-components';

const ErrorMessage = ({error}) => {
    if (typeof error === 'undefined' || error === null) {
        return null;
    }

    return <Message>{error}</Message>;
};

const Message = styled.span(
    ({theme}) => `
    display: block;
    color: ${theme.danger.base};
    margin-top: ${theme.spacing}rem;
`,
);

export default ErrorMessage;
