import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0391 6 19.5304 6 19V5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21V21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.997 17.25C11.8488 17.2506 11.7041 17.2951 11.5811 17.3779C11.4582 17.4606 11.3625 17.578 11.3062 17.7151C11.2499 17.8522 11.2355 18.0029 11.2648 18.1482C11.2941 18.2934 11.3657 18.4268 11.4707 18.5314C11.5757 18.636 11.7094 18.7071 11.8548 18.7358C12.0002 18.7645 12.1508 18.7495 12.2877 18.6926C12.4246 18.6358 12.5415 18.5396 12.6238 18.4164C12.7061 18.2931 12.75 18.1482 12.75 18C12.75 17.9013 12.7305 17.8035 12.6926 17.7123C12.6547 17.6211 12.5992 17.5383 12.5293 17.4686C12.4593 17.3989 12.3763 17.3437 12.2849 17.3062C12.1936 17.2687 12.0957 17.2496 11.997 17.25Z"
                fill="currentColor"
            />
            <path
                d="M10.75 6H13.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
