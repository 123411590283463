import React from 'react';

const Mood = ({mood}) => (
    <svg viewBox="0 0 44 44">
        {(() => {
            switch (mood) {
                case 0:
                    return (
                        <g>
                            <path
                                d="M22 44C17.6488 44 13.3953 42.7097 9.77746 40.2923C6.15957 37.8749 3.33978 34.439 1.67465 30.419C0.0095228 26.3991 -0.426151 21.9756 0.422724 17.708C1.2716 13.4404 3.3669 9.5204 6.44365 6.44365C9.52041 3.36689 13.4404 1.27159 17.708 0.422718C21.9756 -0.426156 26.3991 0.00951707 30.419 1.67464C34.439 3.33977 37.8749 6.15957 40.2923 9.77745C42.7097 13.3953 44 17.6488 44 22C43.9934 27.8327 41.6734 33.4247 37.549 37.549C33.4247 41.6734 27.8327 43.9934 22 44Z"
                                fill="#FFD764"
                            />
                            <path
                                d="M12 23C11.4067 23 10.8266 22.8241 10.3333 22.4944C9.83994 22.1648 9.45542 21.6962 9.22836 21.1481C9.0013 20.5999 8.94189 19.9967 9.05764 19.4147C9.1734 18.8328 9.45912 18.2982 9.87868 17.8787C10.2982 17.4591 10.8328 17.1734 11.4147 17.0576C11.9967 16.9419 12.5999 17.0013 13.148 17.2284C13.6962 17.4554 14.1648 17.8399 14.4944 18.3333C14.8241 18.8266 15 19.4067 15 20C15 20.7957 14.6839 21.5587 14.1213 22.1213C13.5587 22.6839 12.7956 23 12 23Z"
                                fill="#444444"
                            />
                            <path
                                d="M32 23C31.4067 23 30.8266 22.8241 30.3333 22.4944C29.8399 22.1648 29.4554 21.6962 29.2284 21.1481C29.0013 20.5999 28.9419 19.9967 29.0576 19.4147C29.1734 18.8328 29.4591 18.2982 29.8787 17.8787C30.2982 17.4591 30.8328 17.1734 31.4147 17.0576C31.9967 16.9419 32.5999 17.0013 33.148 17.2284C33.6962 17.4554 34.1648 17.8399 34.4944 18.3333C34.8241 18.8266 35 19.4067 35 20C35 20.7957 34.6839 21.5587 34.1213 22.1213C33.5587 22.6839 32.7956 23 32 23Z"
                                fill="#444444"
                            />
                            <path
                                d="M28.942 37C28.7661 37.0003 28.5933 36.9543 28.4409 36.8665C28.2885 36.7787 28.1619 36.6523 28.074 36.5C27.4633 35.4277 26.5797 34.5362 25.5128 33.9161C24.446 33.2959 23.234 32.9693 22 32.9693C20.766 32.9693 19.554 33.2959 18.4872 33.9161C17.4203 34.5362 16.5367 35.4277 15.926 36.5C15.7941 36.7301 15.5761 36.8983 15.3201 36.9677C15.0641 37.0371 14.7911 37.0019 14.561 36.87C14.3309 36.738 14.1627 36.5201 14.0933 36.2641C14.0239 36.0081 14.0591 35.7351 14.191 35.505C14.9768 34.1273 16.1131 32.982 17.4846 32.1854C18.8561 31.3888 20.4139 30.9692 22 30.9692C23.5861 30.9692 25.1439 31.3888 26.5154 32.1854C27.8869 32.982 29.0232 34.1273 29.809 35.505C29.8743 35.619 29.9165 35.7447 29.9332 35.875C29.9499 36.0053 29.9407 36.1376 29.9063 36.2644C29.8718 36.3911 29.8127 36.5098 29.7324 36.6138C29.652 36.7177 29.552 36.8047 29.438 36.87C29.287 36.9561 29.1159 37.001 28.942 37Z"
                                fill="#444444"
                            />
                        </g>
                    );
                case 1:
                    return (
                        <g>
                            <path
                                d="M22 44C17.6488 44 13.3953 42.7097 9.77745 40.2923C6.15957 37.8749 3.33978 34.439 1.67465 30.419C0.00952089 26.3991 -0.426153 21.9756 0.422722 17.708C1.2716 13.4404 3.36689 9.5204 6.44365 6.44365C9.5204 3.36689 13.4404 1.27159 17.708 0.422718C21.9756 -0.426156 26.3991 0.00951707 30.419 1.67464C34.439 3.33977 37.8749 6.15957 40.2923 9.77745C42.7097 13.3953 44 17.6488 44 22C43.9934 27.8327 41.6734 33.4247 37.549 37.549C33.4247 41.6734 27.8327 43.9934 22 44Z"
                                fill="#FFD764"
                            />
                            <path
                                d="M29 35H15C14.7348 35 14.4804 34.8946 14.2929 34.7071C14.1054 34.5196 14 34.2652 14 34C14 33.7348 14.1054 33.4804 14.2929 33.2929C14.4804 33.1054 14.7348 33 15 33H29C29.2652 33 29.5196 33.1054 29.7071 33.2929C29.8946 33.4804 30 33.7348 30 34C30 34.2652 29.8946 34.5196 29.7071 34.7071C29.5196 34.8946 29.2652 35 29 35Z"
                                fill="#444444"
                            />
                            <path
                                d="M12 23C11.4067 23 10.8266 22.8241 10.3333 22.4944C9.83994 22.1648 9.45542 21.6962 9.22836 21.1481C9.0013 20.5999 8.94189 19.9967 9.05764 19.4147C9.1734 18.8328 9.45912 18.2982 9.87868 17.8787C10.2982 17.4591 10.8328 17.1734 11.4147 17.0576C11.9967 16.9419 12.5999 17.0013 13.148 17.2284C13.6962 17.4554 14.1648 17.8399 14.4944 18.3333C14.8241 18.8266 15 19.4067 15 20C15 20.7957 14.6839 21.5587 14.1213 22.1213C13.5587 22.6839 12.7956 23 12 23Z"
                                fill="#444444"
                            />
                            <path
                                d="M32 23C31.4067 23 30.8266 22.8241 30.3333 22.4944C29.8399 22.1648 29.4554 21.6962 29.2284 21.1481C29.0013 20.5999 28.9419 19.9967 29.0576 19.4147C29.1734 18.8328 29.4591 18.2982 29.8787 17.8787C30.2982 17.4591 30.8328 17.1734 31.4147 17.0576C31.9967 16.9419 32.5999 17.0013 33.148 17.2284C33.6962 17.4554 34.1648 17.8399 34.4944 18.3333C34.8241 18.8266 35 19.4067 35 20C35 20.7957 34.6839 21.5587 34.1213 22.1213C33.5587 22.6839 32.7957 23 32 23Z"
                                fill="#444444"
                            />
                        </g>
                    );
                case 2:
                    return (
                        <g>
                            <path
                                d="M22 44C17.6488 44 13.3953 42.7097 9.77746 40.2923C6.15958 37.8749 3.33978 34.439 1.67465 30.419C0.0095247 26.3991 -0.426149 21.9756 0.422726 17.708C1.2716 13.4404 3.3669 9.5204 6.44365 6.44365C9.52041 3.36689 13.4404 1.27159 17.708 0.422718C21.9756 -0.426156 26.3991 0.00951707 30.419 1.67464C34.439 3.33977 37.8749 6.15957 40.2923 9.77745C42.7097 13.3953 44 17.6488 44 22C43.9934 27.8327 41.6734 33.4247 37.549 37.549C33.4247 41.6734 27.8327 43.9934 22 44Z"
                                fill="#FFD764"
                            />
                            <path
                                d="M12 23C11.4067 23 10.8266 22.8241 10.3333 22.4944C9.83994 22.1648 9.45543 21.6962 9.22836 21.1481C9.0013 20.5999 8.94189 19.9967 9.05765 19.4147C9.1734 18.8328 9.45912 18.2982 9.87868 17.8787C10.2982 17.4591 10.8328 17.1734 11.4147 17.0576C11.9967 16.9419 12.5999 17.0013 13.1481 17.2284C13.6962 17.4554 14.1648 17.8399 14.4944 18.3333C14.8241 18.8266 15 19.4067 15 20C15 20.7957 14.6839 21.5587 14.1213 22.1213C13.5587 22.6839 12.7957 23 12 23Z"
                                fill="#444444"
                            />
                            <path
                                d="M32 23C31.4067 23 30.8266 22.8241 30.3333 22.4944C29.8399 22.1648 29.4554 21.6962 29.2284 21.1481C29.0013 20.5999 28.9419 19.9967 29.0576 19.4147C29.1734 18.8328 29.4591 18.2982 29.8787 17.8787C30.2982 17.4591 30.8328 17.1734 31.4147 17.0576C31.9967 16.9419 32.5999 17.0013 33.148 17.2284C33.6962 17.4554 34.1648 17.8399 34.4944 18.3333C34.8241 18.8266 35 19.4067 35 20C35 20.7957 34.6839 21.5587 34.1213 22.1213C33.5587 22.6839 32.7957 23 32 23Z"
                                fill="#444444"
                            />
                            <path
                                d="M22 37C20.4145 36.9965 18.8579 36.5753 17.487 35.7788C16.1161 34.9823 14.9793 33.8387 14.191 32.463C14.0657 32.2333 14.0354 31.9637 14.1066 31.712C14.1778 31.4603 14.3448 31.2464 14.5718 31.1164C14.7987 30.9864 15.0677 30.9504 15.3208 31.0164C15.574 31.0823 15.7912 31.2448 15.926 31.469C16.5367 32.5413 17.4203 33.4328 18.4872 34.0529C19.554 34.6731 20.766 34.9997 22 34.9997C23.234 34.9997 24.446 34.6731 25.5128 34.0529C26.5797 33.4328 27.4633 32.5413 28.074 31.469C28.2088 31.2448 28.426 31.0823 28.6792 31.0164C28.9323 30.9504 29.2013 30.9864 29.4282 31.1164C29.6552 31.2464 29.8222 31.4603 29.8934 31.712C29.9646 31.9637 29.9343 32.2333 29.809 32.463C29.0207 33.8387 27.8839 34.9823 26.513 35.7788C25.1421 36.5753 23.5855 36.9965 22 37Z"
                                fill="#444444"
                            />
                        </g>
                    );
                case 3:
                    return (
                        <g>
                            <path
                                d="M22 44C17.6488 44 13.3953 42.7097 9.77745 40.2923C6.15957 37.8749 3.33977 34.439 1.67464 30.419C0.00951707 26.3991 -0.426156 21.9756 0.422718 17.708C1.27159 13.4404 3.36689 9.5204 6.44365 6.44365C9.5204 3.36689 13.4404 1.27159 17.708 0.422718C21.9756 -0.426156 26.3991 0.00951707 30.419 1.67464C34.439 3.33977 37.8749 6.15957 40.2923 9.77745C42.7097 13.3953 44 17.6488 44 22C43.9934 27.8327 41.6734 33.4247 37.549 37.549C33.4247 41.6734 27.8327 43.9934 22 44Z"
                                fill="#FFD764"
                            />
                            <path
                                d="M28 21C28.2652 21 28.5196 20.8946 28.7071 20.7071C28.8946 20.5196 29 20.2652 29 20C29 19.4696 29.2107 18.9609 29.5858 18.5858C29.9609 18.2107 30.4696 18 31 18C31.5304 18 32.0391 18.2107 32.4142 18.5858C32.7893 18.9609 33 19.4696 33 20C33 20.2652 33.1054 20.5196 33.2929 20.7071C33.4804 20.8946 33.7348 21 34 21C34.2652 21 34.5196 20.8946 34.7071 20.7071C34.8946 20.5196 35 20.2652 35 20C35 18.9391 34.5786 17.9217 33.8284 17.1716C33.0783 16.4214 32.0609 16 31 16C29.9391 16 28.9217 16.4214 28.1716 17.1716C27.4214 17.9217 27 18.9391 27 20C27 20.2652 27.1054 20.5196 27.2929 20.7071C27.4804 20.8946 27.7348 21 28 21Z"
                                fill="#444444"
                            />
                            <path
                                d="M16 21C15.7348 21 15.4804 20.8946 15.2929 20.7071C15.1054 20.5196 15 20.2652 15 20C15 19.4696 14.7893 18.9609 14.4142 18.5858C14.0391 18.2107 13.5304 18 13 18C12.4696 18 11.9609 18.2107 11.5858 18.5858C11.2107 18.9609 11 19.4696 11 20C11 20.2652 10.8946 20.5196 10.7071 20.7071C10.5196 20.8946 10.2652 21 10 21C9.73478 21 9.48043 20.8946 9.29289 20.7071C9.10536 20.5196 9 20.2652 9 20C9 18.9391 9.42143 17.9217 10.1716 17.1716C10.9217 16.4214 11.9391 16 13 16C14.0609 16 15.0783 16.4214 15.8284 17.1716C16.5786 17.9217 17 18.9391 17 20C17 20.2652 16.8946 20.5196 16.7071 20.7071C16.5196 20.8946 16.2652 21 16 21Z"
                                fill="#444444"
                            />
                            <path
                                d="M33.6 30C33.8601 29.021 33.9945 28.0129 34 27C34 26.7348 33.8946 26.4804 33.7071 26.2929C33.5196 26.1054 33.2652 26 33 26H11C10.7348 26 10.4804 26.1054 10.2929 26.2929C10.1054 26.4804 10 26.7348 10 27C10.0038 28.0127 10.1366 29.0208 10.395 30H33.6Z"
                                fill="white"
                            />
                            <path
                                d="M10.4 30C11.0651 32.5707 12.5653 34.8476 14.665 36.4731C16.7646 38.0986 19.3447 38.9806 22 38.9806C24.6553 38.9806 27.2354 38.0986 29.335 36.4731C31.4347 34.8476 32.9349 32.5707 33.6 30H10.4Z"
                                fill="#AE453E"
                            />
                            <path
                                d="M16.014 37.385C17.8316 38.4429 19.897 39.0002 22 39.0002C24.103 39.0002 26.1684 38.4429 27.986 37.385C27.365 36.3523 26.4874 35.4979 25.4385 34.9047C24.3896 34.3115 23.205 33.9997 22 33.9997C20.795 33.9997 19.6104 34.3115 18.5615 34.9047C17.5126 35.4979 16.635 36.3523 16.014 37.385Z"
                                fill="#FA645A"
                            />
                        </g>
                    );
                default:
                    return null;
            }
        })()}
    </svg>
);

export default Mood;
