import React from 'react';
import {connect} from 'react-redux';
import {getActiveLanguage, getTranslate} from 'react-localize-redux';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/start_of_iso_week';
import addWeeks from 'date-fns/add_weeks';
import getWeek from 'date-fns/get_iso_week';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {
    parseOptions,
    getMonthData,
    getWeekData,
    MONTHLY,
    WEEKLY,
    DATE_FORMAT,
    LAST_30,
    parseChartData,
    parseDate,
} from 'util/analytics';
import {actions} from 'store/index';
import State from 'lib/components/State';
import AdminActivity from './AdminActivity';

const AdminActivityWithState = ({
    isLoadingMonthly,
    isLoadingWeekly,
    messagesWeekly,
    langCode,
    messages,
    translate,
    fetchMessagesPerWeek,
}) => (
    <State
        initialState={{
            activePeriod: LAST_30,
            selectedPeriod: MONTHLY,
        }}>
        {({state, setState}) => {
            const {selectedPeriod, activePeriod} = state;
            let options = [];
            let selectedActivity = {};
            let activePeriodText = '';
            let chartData = [];

            if (selectedPeriod === WEEKLY) {
                options = messagesWeekly.map(({year, week}) => {
                    const parsedDate = parseDate({year, month: 1});
                    const dateAtStartOfWeek = startOfWeek(
                        addWeeks(parsedDate, week),
                    );

                    return {
                        label: translate('dashboard.week_readable', {
                            year,
                            week,
                        }),
                        value: format(dateAtStartOfWeek, DATE_FORMAT),
                    };
                });

                selectedActivity = getWeekData(messagesWeekly, activePeriod);
                activePeriodText = translate('dashboard.sent_messages_week', {
                    week: getWeek(activePeriod),
                });

                chartData = messagesWeekly.map(messagePeriod => {
                    const parsedDate = parseDate({
                        year: messagePeriod.year,
                        month: 1,
                    });
                    const dateAtStartOfWeek = startOfWeek(
                        addWeeks(parsedDate, messagePeriod.week),
                    );

                    return {
                        ...messagePeriod,
                        id: format(dateAtStartOfWeek, DATE_FORMAT),
                        name: format(
                            dateAtStartOfWeek,
                            'W',
                            getDateLocale(langCode),
                        ),
                        value: messagePeriod.published_manual || 0,
                    };
                });
            } else {
                options = parseOptions(messages, getDateLocale(langCode), () =>
                    translate('dashboard.last_30_days'),
                );

                selectedActivity = getMonthData(messages, activePeriod);
                if (activePeriod === LAST_30) {
                    activePeriodText = translate(
                        'dashboard.sent_messages_last_30_days',
                    );
                } else {
                    activePeriodText = translate('dashboard.sent_messages', {
                        month: format(
                            activePeriod,
                            'MMMM',
                            getDateLocale(langCode),
                        ),
                    });
                }

                chartData = parseChartData(
                    messages,
                    'published_manual',
                    getDateLocale(langCode),
                    () => translate('dashboard.last_30_days_short'),
                );
            }

            return (
                <AdminActivity
                    onPeriodChange={period => {
                        setState({activePeriod: period});
                    }}
                    onRangeChange={selectedValue => {
                        let newPeriod;

                        switch (selectedValue) {
                            case WEEKLY:
                                if (!messagesWeekly.length) {
                                  fetchMessagesPerWeek();
                                }
                                newPeriod = format(
                                    startOfWeek(new Date()),
                                    DATE_FORMAT,
                                );
                                break;
                            case MONTHLY:
                            default:
                                newPeriod = LAST_30;
                                break;
                        }

                        setState({
                            selectedPeriod: selectedValue,
                            activePeriod: newPeriod,
                        });
                    }}
                    activePeriod={activePeriod}
                    isLoadingMonthly={isLoadingMonthly}
                    isLoadingWeekly={isLoadingWeekly}
                    selectedActivity={selectedActivity}
                    options={options}
                    activePeriodText={activePeriodText}
                    chartData={chartData}
                    translate={translate}
                />
            );
        }}
    </State>
);

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    messages: state.analytics.messages.messages,
    isLoadingMonthly: state.analytics.messages.isLoading,
    messagesWeekly: state.analytics.messages.messagesWeekly,
    isLoadingWeekly: state.analytics.messages.isLoadingWeekly,
    langCode: getActiveLanguage(state.localize).code,
});

const mapDispatchToProps = dispatch => ({
    fetchMessagesPerWeek: () => {
        dispatch(actions.analytics.messages.fetchMessagesPerWeek());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminActivityWithState);
