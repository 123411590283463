import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import classNames from 'classnames';
import CloseButton from 'lib/components/CloseButton';
import EditButton from 'lib/components/EditButton';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import * as theme from 'lib/styles/theme';
import * as colors from 'lib/styles/colors';
import {actions} from 'store';
import Icon from 'lib/components/Icon';
import customBlocks from 'lib/constants/customBlocks';

const CustomBlock = ({
    children,
    type,
    label,
    icon,
    title,
    description,
    onEdit,
    onDelete,
    autoWidth,
    noPadding,
    disableActions,
    headerCTA,
    className,
}) => {
    const dispatch = useDispatch();
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));
    return (
        <div
            className={classNames(
                'customBlock',
                {autoWidth},
                {noPadding},
                {interactive: !disableActions},
                {className},
            )}>
            {(icon || label) && (
                <div className="header">
                    {icon && <Icon className="icon" name={icon} size="14" />}
                    {label && <span className="label">{label}</span>}
                    {headerCTA}
                </div>
            )}
            {!disableActions && (
                <div className="actions">
                    <div className="actions__content">
                        {onEdit && (
                            <EditButton className="action" onClick={onEdit} />
                        )}
                        {onDelete && (
                            <CloseButton
                                className="action"
                                onClick={() => {
                                    setModalVisibility(MODAL_CONFIRM, true);
                                    setModalData({
                                        title: (
                                            <Translate id="global.confirm_delete" />
                                        ),
                                        body: type ===
                                            customBlocks.SURVEY_BLOCK && (
                                            <Translate id="This will remove this survey in all language variants of the message." />
                                        ),
                                        buttonActions: [
                                            {
                                                label: (
                                                    <Translate id="global.delete" />
                                                ),
                                                callBack: () => {
                                                    setModalVisibility(
                                                        MODAL_CONFIRM,
                                                        false,
                                                    );
                                                    onDelete(false);
                                                },
                                                classes: 'danger',
                                            },
                                        ],
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {title && <h2 className="question">{title}</h2>}
            {description && <p className="description">{description}</p>}
            {children}

            <style jsx>
                {`
                    .customBlock {
                        $c: &;

                        display: inline-block;
                        width: 100%;
                        box-shadow: ${theme.defaultShadow};
                        border-radius: 0.4rem;
                        background: ${colors.pureWhite};
                        padding: 2rem 1.3rem;
                        color: ${colors.text.secondary};
                        position: relative;
                        cursor: pointer;

                        &.autoWidth {
                            width: auto;
                        }

                        &.noPadding {
                            padding: 0;

                            #{$c}__actions {
                                top: 0.7rem;
                                right: 0.7rem;
                            }
                        }

                        &.interactive {
                            cursor: default;
                            user-select: none;
                        }
                    }

                    .header {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-bottom: 1.6rem;
                        margin-top: -1rem;
                    }

                    .header :global(.icon) {
                        margin-right: 1rem;
                    }

                    .label {
                        font-size: 1.2rem;
                        text-transform: uppercase;
                    }

                    .question {
                        font-size: 1.6rem;
                        font-weight: ${theme.fwBold};
                        margin-bottom: 1.1rem;
                    }

                    .description {
                        font-size: 1.4rem;
                        margin-bottom: 2rem;
                    }

                    :global(.--selected) .customBlock {
                        border-width: 0.2rem;
                        border-color: ${colors.brand.base};
                    }
                `}
            </style>
        </div>
    );
};

CustomBlock.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    autoWidth: PropTypes.bool,
    noPadding: PropTypes.bool,
    disableActions: PropTypes.bool,
    headerCTA: PropTypes.node,
    className: PropTypes.string,
};
CustomBlock.defaultProps = {
    children: null,
    type: '',
    label: '',
    icon: '',
    title: '',
    description: '',
    onEdit: null,
    onDelete: null,
    autoWidth: false,
    noPadding: false,
    disableActions: false,
    headerCTA: null,
    className: '',
};
export default CustomBlock;
