import React, {useEffect} from 'react';
import {Translate} from 'react-localize-redux';
import Input from 'components/util/Input';
import Form from 'components/util/Form';

const {LabelGroup} = Form;

const WorkAnniversary = ({trigger, onChange}) => {
    const handleChange = years => {
        const parameters = (trigger && trigger.parameters) || {};
        const newTrigger = {
            ...trigger,
            parameters: {
                ...parameters,
                years,
            },
        };
        onChange(newTrigger);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!trigger.parameters || !trigger.parameters.years) {
            handleChange(1);
        }
    }, []);

    return (
        <LabelGroup
            label={<Translate id="How many years is the anniversary?" />}>
            <Translate>
                {({translate}) => (
                    <Input
                        value={trigger.parameters && trigger.parameters.years}
                        type="number"
                        placeholder={translate(
                            'How many years is the anniversary?',
                        )}
                        min={1}
                        onChange={handleChange}
                    />
                )}
            </Translate>
        </LabelGroup>
    );
};

export default WorkAnniversary;
