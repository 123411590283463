import React from 'react';
import {PieChart, Pie, Cell} from 'recharts';
import {chart} from 'lib/styles/colors';
import OnBoardingLabel from './OnBoardingLabel';

const OnBoarding = ({onBoarding}) => {
    const {
        active_mobile: activeMobile,
        active_web: activeWeb,
        active_both: activeBoth,
        not_active: notActive,
    } = onBoarding;

    const total = activeMobile + activeWeb + activeBoth + notActive;
    const data = [
        {name: 'active_mobile', value: activeMobile, color: chart.blue},
        {name: 'active_web', value: activeWeb, color: chart.cyan},
        {name: 'active_both', value: activeBoth, color: chart.green.base},
        {name: 'not_active', value: notActive, color: chart.orange.base},
    ];
    return (
        <div className="content">
            <div className="chart">
                <PieChart width={174} height={174}>
                    <Pie
                        data={data}
                        dataKey="value"
                        innerRadius={40}
                        outerRadius={80}
                        strokeWidth={0}
                        isAnimationActive={false}>
                        {data.map(({color}) => (
                            <Cell key={color} fill={color} />
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className="labels">
                {data.map(label => (
                    <OnBoardingLabel
                        key={label.name}
                        label={label.name}
                        color={label.color}
                        value={label.value}
                        total={total}
                    />
                ))}
            </div>

            <style jsx>
                {`
                    .content {
                        padding: 2rem 0;
                    }

                    .chart {
                        margin: 0 auto 1.5rem;
                    }

                    .labels {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                `}
            </style>
        </div>
    );
};

export default OnBoarding;
