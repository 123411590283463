import qs from 'qs';
import {getStore} from '../helpers/storeBridge';
import {DEFAULT_LANGUAGE} from '../../helpers/locale';

export const REACT_NATIVE_IDENTIFIER = 'ReactNative';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const DESC = 'desc';
export const ASC = 'asc';

const SUPPORTED_METHODS = [GET, POST, PUT, DELETE];

let APIRoot = '';

if (navigator.product !== REACT_NATIVE_IDENTIFIER) {
    APIRoot = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_COMPANY}.${process.env.REACT_APP_API}`;
    const fqdn = (window && window.location && window.location.host) || '';
    const parts = fqdn.split('.');
    const subdomain = parts[0];

    if (typeof window !== 'undefined' && typeof jasmine === 'undefined') {
        APIRoot = `${process.env.REACT_APP_PROTOCOL}://${subdomain}.${process.env.REACT_APP_API}`;
    }
}

export default (
    accessToken,
    endpoint,
    method,
    body,
    query,
    headers = {},
    locale = DEFAULT_LANGUAGE,
    version = 'v1',
) => {
    if (navigator.product === REACT_NATIVE_IDENTIFIER) {
        const store = getStore();
        let prefix = '';

        if (store && store.getState() && store.getState().company) {
            const companySlug = store.getState().company.data.slug;
            prefix = companySlug ? `${companySlug}.` : '';
        }

        const {API_ROOT} = process.env;

        if (typeof API_ROOT === 'undefined') {
            throw new Error(
                "API_ROOT is not set in 'process.env'. Check your .env file.",
            );
        }

        APIRoot = API_ROOT.replace('https://', `https://${prefix}`);
    }

    if (
        typeof method !== 'string' &&
        SUPPORTED_METHODS.indexOf(method.toUpperCase() === -1)
    ) {
        throw new Error(
            'Method should be a string and be GET, POST, PUT or DELETE',
        );
    }

    const newHeaders = headers;
    newHeaders.Accept = 'application/json';
    newHeaders['Accept-Language'] = locale;

    if (typeof accessToken === 'string') {
        newHeaders.Authorization = `Bearer ${accessToken}`;
    }

    let newBody = null;
    if (body instanceof FormData) {
        newBody = body;
    } else {
        newHeaders['Content-Type'] =
            headers['Content-Type'] || 'application/json';
        newBody = JSON.stringify(body) || JSON.stringify({});
    }

    let querystring = '';

    if (query) {
        const clonedQuery = {...query};

        // Clear undefined and empty values
        Object.keys(clonedQuery).forEach(key => {
            if (clonedQuery[key] === undefined || clonedQuery[key] === '') {
                delete clonedQuery[key];
            }
        });

        querystring += `?${qs.stringify(clonedQuery)}`;
    }

    const fetchParams = {
        method,
        body: newBody,
        headers: newHeaders,
    };

    if (method === GET) {
        delete fetchParams.body;
    }

    // TODO: check if company needs the new stats
    // if (endpoint.includes("analytics/")) {
    //     APIRoot = APIRoot.replace('/v1', '/v2')
    // }

    return fetch(`${APIRoot}/${version}${endpoint}${querystring}`, fetchParams);
};
