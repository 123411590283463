import React from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, Cell} from 'recharts';
import ReferenceLine from 'recharts/lib/cartesian/ReferenceLine';
import {LabelList} from 'recharts/lib';
import RoundedBar from 'components/RoundedBar';
import CustomizedLabel from './CustomizedLabel';
import CustomizedAxis from './CustomizedAxis';

const CustomBarChart = ({
    min,
    max,
    color,
    data,
    activePeriod,
    stack,
    height = 450,
    onPeriodChange,
}) => {
    const renderBar = (key, barColor, stacked, lastItem) => {
        const additionalProps = {};
        if (!stacked || (stacked && lastItem)) {
            additionalProps.shape = <RoundedBar />;
        }

        return (
            <Bar
                key={key}
                dataKey={key}
                fill={barColor}
                maxBarSizeNumber={60}
                fill-opacity={0.48}
                stackId={stacked ? '1' : null}
                onClick={(month, index) => {
                    onPeriodChange(data[index].id);
                }}
                {...additionalProps}>
                <LabelList
                    dataKey="name"
                    content={<CustomizedLabel data={data} labelKey={key} />}
                />
                {data &&
                    data.map(entry => (
                        <Cell
                            cursor="pointer"
                            fillOpacity={
                                entry.id === activePeriod ? '1' : '0.48'
                            }
                            key={entry.id}
                        />
                    ))}
            </Bar>
        );
    };

    return (
        <div className="custom-bar-chart">
            <ResponsiveContainer width="100%" height={height}>
                <BarChart
                    data={data}
                    margin={{
                        top: 50,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }}>
                    {min ? (
                        <ReferenceLine
                            y={min}
                            stroke="#004463"
                            strokeDasharray="5 5"
                        />
                    ) : (
                        ''
                    )}
                    {max ? (
                        <ReferenceLine
                            y={max}
                            stroke="#004463"
                            strokeDasharray="5 5"
                        />
                    ) : (
                        ''
                    )}
                    {stack
                        ? stack.map((stackItem, index) =>
                              renderBar(
                                  stackItem.key,
                                  stackItem.color,
                                  true,
                                  index === stack.length - 1,
                              ),
                          )
                        : renderBar('value', color)}
                    <XAxis
                        dataKey="name"
                        padding={{left: 40, right: 40}}
                        minTickGap={30}
                        tickLine={false}
                        interval={0}
                        tick={
                            <CustomizedAxis
                                color={color}
                                data={data}
                                activePeriod={activePeriod}
                                min={min}
                                max={max}
                                angle={-45}
                            />
                        }
                    />
                </BarChart>
            </ResponsiveContainer>

            <style jsx>
                {`
                    @import '../../../lib/sass/kit';

                    .custom-bar-chart {
                        margin-top: auto;

                        &__label {
                            opacity: 0.48;

                            :global(text) {
                                text-transform: uppercase;
                                font-size: 1.6rem;
                                font-weight: $fw-bold;
                            }

                            :global(line) {
                                display: none;
                            }

                            :global(&--active) {
                                opacity: 1;
                            }

                            :global(&--out-of-range) {
                                :global(line) {
                                    display: block;
                                    stroke: $danger-color;
                                    stroke-width: 0.5rem;
                                }
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default CustomBarChart;
