import React, {useEffect, useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    getTranslate,
    getLanguages,
    setActiveLanguage,
} from 'react-localize-redux';

import actions from 'store/actions';
import {MODAL_USER_DETAIL} from 'lib/store/actions/modals';
import {getById} from 'lib/store/reducers/helpers/byId';
import {checkLangHelper, mapLanguageOptions} from 'lib/helpers/locale';
import {removeScopedSentryUser} from 'lib/helpers/scopeUser';
import Modal from 'modals/Modal/ModalWithState';
import {transformMobile} from 'lib/components/PhoneInput';
import MeModal from './MeModal';
import {throwIfInvalidApiResponse} from '../../util/errorHelper';

const MeModalWithState = () => {
    const [editingPicture, setEditingPicture] = useState(false);
    const [saving, setSaving] = useState(false);
    const [savingAvatar, setSavingAvatar] = useState(false);
    const [user, setUser] = useState({});

    const translate = useSelector(state => getTranslate(state.localize));
    const data = useSelector(state => state.modals.data[MODAL_USER_DETAIL]);

    const meId = useSelector(state => state.users.me);
    const users = useSelector(state => state.users.byId);
    const company = useSelector(state => state.company.data);
    const languages = useSelector(state => getLanguages(state.localize));

    const dispatch = useDispatch();

    const setUserById = useCallback(
        id => {
            setUser(users[id]);
        },
        [users],
    );

    useEffect(() => {
        setUserById(data.userId);
    }, [data.userId, setUserById]);

    const setNewUserData = newData => {
        setUser(prevUser => ({
            ...prevUser,
            ...newData,
        }));
    };

    const onSaveUserDetail = async () => {
        setSaving(true);

        if (checkLangHelper(user.language, languages)) {
            dispatch(setActiveLanguage(user.language));
        }

        const userToSave = {
            ...user,
            work_mobile: transformMobile(user.work_mobile),
            mobile: transformMobile(user.mobile),
        };

        try {
            const res = await dispatch(actions.users.me.updateMe(userToSave));
            throwIfInvalidApiResponse(res);
            await dispatch(
                actions.modals.setModalVisibility(MODAL_USER_DETAIL, false),
            );
        } catch (error) {
            // Error already being shown through toast notification
        }
        setSaving(false);
    };

    const onSetAvatar = avatar => {
        const formData = new FormData();
        formData.append('avatar_file', avatar, 'avatar');

        setSavingAvatar(true);
        dispatch(actions.users.me.avatar.setAvatar(formData)).then(() => {
            setSavingAvatar(false);
        });
    };

    const userFromState = user;
    const userFromProps = getById(users, data.userId);

    const languageOptions = mapLanguageOptions(company && company.languages);

    return (
        <Modal
            flex
            name={MODAL_USER_DETAIL}
            big
            title={
                userFromState && userFromState.first_name
                    ? `${userFromState.first_name} ${userFromState.last_name}`
                    : ''
            }
            cancelable={data.editable}
            buttonActions={
                data.editable && [
                    {
                        label: translate('global.logout'),
                        classes: 'danger',
                        callBack: () => {
                            dispatch(
                                actions.modals.setModalVisibility(
                                    MODAL_USER_DETAIL,
                                    false,
                                ),
                            );
                            dispatch(actions.auth.logout());
                            removeScopedSentryUser();
                        },
                        left: true,
                    },
                    {
                        loading: saving,
                        label: translate('global.save'),
                        callBack: () => !editingPicture && onSaveUserDetail(),
                        classes: `button success ${editingPicture ? 'disabled' : ''}`,
                    },
                ]
            }>
            <MeModal
                me={getById(users, meId)}
                userFromState={userFromState}
                userFromProps={userFromProps}
                editable={data.editable}
                savingAvatar={savingAvatar}
                languageOptions={languageOptions}
                company={company}
                onEditAvatar={edit => setEditingPicture(edit)}
                onSetAvatar={avatar => onSetAvatar(avatar)}
                setNewUserData={newData => setNewUserData(newData)}
            />
        </Modal>
    );
};

export default MeModalWithState;
