import React from 'react';
import {Translate} from 'react-localize-redux';
import State from 'lib/components/State';
import Switch from 'components/util/Switch';
import useTranslate from 'components/hooks/useTranslate';
import Widget from './Widget';

const SurveyWidget = ({title, surveyStats, active, onChange, onClick}) => {
    const {defaultLanguage} = useTranslate();

    return (
        <State
            initialState={{
                answered: surveyStats ? surveyStats.answered : 0,
                unAnswered: surveyStats
                    ? surveyStats.recipients - surveyStats.answered
                    : 0,
            }}>
            {({state}) => (
                <Widget
                    header={
                        <span className="survey-header">
                            <span className="survey-title">
                                {title[defaultLanguage]}
                            </span>
                            <div>
                                <span className="survey-header-switch-label font-weight-bold">
                                    {active ? (
                                        <Translate id="global.active" />
                                    ) : (
                                        <Translate id="global.not_active" />
                                    )}
                                </span>
                                <Switch value={active} onChange={onChange} />
                            </div>
                        </span>
                    }
                    type="dark-header"
                    onHeaderClick={
                        onClick
                            ? () => {
                                  if (onClick) {
                                      onClick();
                                  }
                              }
                            : null
                    }>
                    <div className="number-label-list">
                        <div className="number-label">
                            <div className="number-label__number">
                                {state.answered}
                            </div>
                            <div className="number-label__label">
                                <Translate id="global.answered" />
                            </div>
                        </div>
                        <div className="number-label">
                            <div className="number-label__number">
                                {state.unAnswered}
                            </div>
                            <div className="number-label__label">
                                <Translate id="global.not_answered" />
                            </div>
                        </div>
                    </div>

                    <style jsx>
                        {`
                            .survey-header {
                                display: flex;
                                justify-content: space-between;
                            }

                            .survey-title {
                                flex: 1;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;
                                white-space: nowrap;
                                margin-right: 2rem;
                            }

                            .survey-header-switch-label {
                                color: white;
                                text-transform: uppercase;
                                margin-right: 1rem;
                            }
                        `}
                    </style>
                </Widget>
            )}
        </State>
    );
};

export default SurveyWidget;
