import React from 'react';

export default function () {
    return (
        <path
            d="M4.78125 0H19.2188C21.8438 0 24 2.15625 24 4.78125V19.2188C24 21.8438 21.8438 24 19.2188 24H4.78125C2.15625 24 0 21.8438 0 19.2188V4.78125C0 2.15625 2.15625 0 4.78125 0ZM16.7812 10.7812C18.7972 10.7812 20.391 9.1875 20.391 7.21875C20.391 5.20275 18.7972 3.609 16.7812 3.609C14.8125 3.609 13.2188 5.20275 13.2188 7.21875C13.2188 9.1875 14.8125 10.7812 16.7812 10.7812ZM5.625 5.15625C5.4375 4.9215 5.109 4.78125 4.78125 4.78125C4.125 4.78125 3.609 5.34375 3.609 6V18.6562C3.609 19.3125 4.125 19.875 4.78125 19.875H17.4847C17.8132 19.875 18.141 19.734 18.3285 19.5C18.7972 19.0312 18.7972 18.2812 18.3285 17.8125L5.625 5.15625Z"
            fill="currentColor"
        />
    );
}
