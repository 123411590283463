export const DEPARTMENTS = 'departments';
export const FUNCTIONS = 'functions';
export const LOCATIONS = 'locations';

export const getSegmentGroupBySlug = (segmentGroups, slug) =>
    segmentGroups && segmentGroups.find(group => group.slug === slug);

export const getSegmentsFromGroupBySlug = (segmentGroups, slug) => {
    const segmentGroup = getSegmentGroupBySlug(segmentGroups, slug);

    if (!segmentGroup) {
        return [];
    }

    return segmentGroup.segments.map(segment => segment.name);
};
