import React from 'react';
import {Translate} from 'react-localize-redux';
import styled, {useTheme} from 'styled-components';
import Icon from 'lib/components/Icon';
import Select from 'components/util/Select';
import Button from 'components/util/Button';
import FlatIcon from 'components/util/FlatIcon';

const EditorSidebarLanguageSelect = ({
    availableLanguages,
    languageOptions,
    hasAllTranslations,
    selectedLanguage,
    setSelectedLanguage,
    defaultLanguage,
    onChangeAvailableLanguages,
    onGenerateTranslations,
    canTranslate,
}) => {
    const theme = useTheme();

    const translateButton = canTranslate ? (
        <Button
            onClick={() =>
                onGenerateTranslations(selectedLanguage, languageOptions)
            }
            style={{marginTop: '1rem'}}
            warning
            fullWidth
            ghost>
            <Translate id="automatic_translations.buttonCta" />
            &nbsp; <Icon name="bsMagic" style={{marginLeft: '.5rem'}} />
        </Button>
    ) : (
        ''
    );

    return (
        <div className="form__group">
            <SelectLabelContainer className="label">
                <Translate id="Languages" />
                <SelectLabelRightContainer>
                    <SelectLabelAmount>
                        {availableLanguages.length} <Translate id="of" />{' '}
                        {languageOptions.length}
                    </SelectLabelAmount>
                    <FlatIcon
                        name={
                            hasAllTranslations
                                ? 'checkCircle'
                                : 'exclamationCircle'
                        }
                        fill={
                            hasAllTranslations
                                ? theme.colors.whatsapp
                                : theme.colors.primary
                        }
                    />
                </SelectLabelRightContainer>
            </SelectLabelContainer>
            <Select
                inputId="language"
                clearable={false}
                value={selectedLanguage}
                options={languageOptions}
                components={{
                    Option: props => {
                        const {
                            data: {value, label},
                            isSelected,
                            setValue,
                        } = props;
                        const isTranslated = availableLanguages.includes(value);
                        const isDefault = value === defaultLanguage;
                        const onChangeValue = (inputValue = null) => {
                            const newValue =
                                inputValue === null ? value : inputValue;
                            setValue(newValue);
                            setSelectedLanguage(newValue);
                        };
                        return (
                            <OptionContainer
                                isTranslated={isTranslated}
                                onClick={({target}) => {
                                    if (
                                        !isSelected &&
                                        target.id !== 'optionAddRemoveButton' &&
                                        isTranslated
                                    ) {
                                        onChangeValue();
                                    }
                                }}>
                                <OptionLeftContainer>
                                    <Icon
                                        name={
                                            isTranslated
                                                ? 'checkRoundChecked'
                                                : 'checkRoundUnchecked'
                                        }
                                    />
                                    <OptionLabel isTranslated={isTranslated}>
                                        {label}
                                    </OptionLabel>
                                </OptionLeftContainer>
                                {isDefault ? (
                                    <DefaultText>
                                        <Translate id="Default" />
                                    </DefaultText>
                                ) : (
                                    <OptionButton
                                        unstyled
                                        id="optionAddRemoveButton"
                                        isTranslated={isTranslated}
                                        onClick={() => {
                                            onChangeAvailableLanguages(
                                                isTranslated,
                                                value,
                                            );
                                            if (
                                                isTranslated &&
                                                selectedLanguage === value
                                            ) {
                                                onChangeValue(defaultLanguage);
                                            }
                                            if (!isTranslated) {
                                                onChangeValue();
                                            }
                                        }}>
                                        {isTranslated ? (
                                            <Translate id="Remove" />
                                        ) : (
                                            <Translate id="Add" />
                                        )}
                                    </OptionButton>
                                )}
                            </OptionContainer>
                        );
                    },
                }}
            />
            {translateButton}
        </div>
    );
};

const SelectLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SelectLabelRightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SelectLabelAmount = styled.div(
    ({theme}) => `
    color: ${theme.colors.textLight};
    margin-right: ${theme.spacingSmaller}rem;
`,
);

const OptionContainer = styled.div(
    ({isTranslated, theme}) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: ${isTranslated ? 'pointer' : ''};
    padding: ${theme.spacing}rem;
    &:hover {
        p {
            font-weight: ${theme.fwSemiBold};
        }
        background-color: ${isTranslated ? theme.colors.grey[1] : theme.colors.white};
    }
`,
);

const OptionLeftContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OptionLabel = styled.div(
    ({isTranslated, theme}) => `
    margin-left: ${theme.spacing}rem;
    align-vertical: center;
    font-size: ${theme.fontSizeSmall}rem;
    color: ${isTranslated ? theme.colors.text : theme.colors.textLight};
`,
);

const DefaultText = styled.div(
    ({theme}) => `
    color: ${theme.colors.textLight};
`,
);

const OptionButton = styled(Button)(
    ({isTranslated, theme}) => `
    color: ${isTranslated ? theme.colors.red : theme.colors.primary};
    font-size: ${theme.fontSizeSmaller}rem;
`,
);

export default EditorSidebarLanguageSelect;
