import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M10.923 1.50812C10.5855 1.68048 10.3988 1.88875 10.2695 2.22628C10.0612 2.77208 10.2839 3.4687 10.7507 3.7416L10.9589 3.86369V7.11695L11.3037 7.16722C11.4904 7.18876 11.7274 7.23185 11.8351 7.2534L12.0218 7.29649L12.0362 3.88523L12.2947 3.66978C13.214 2.90853 12.6825 1.39322 11.4904 1.39322C11.2965 1.39322 11.0451 1.44349 10.923 1.50812Z" />
            <path d="M19.0382 3.57642C18.9233 3.63387 18.7366 3.76314 18.6289 3.87805C18.0974 4.44539 18.1764 5.29282 18.8012 5.79553C19.067 6.01098 19.0885 6.04689 19.0526 6.31261C18.9808 6.80096 18.8587 6.85123 17.6809 6.85123C16.7258 6.85123 16.6396 6.86559 16.2877 7.03795C15.7634 7.29648 15.4762 7.72738 15.4331 8.31627L15.3972 8.76871L15.6342 8.86207C15.7706 8.91952 15.9932 9.03443 16.1297 9.12779L16.381 9.29297L16.4241 8.75435C16.4816 7.92128 16.5103 7.89974 17.9107 7.85665C19.1244 7.82074 19.2896 7.77765 19.663 7.37548C19.9216 7.10258 20.0796 6.70759 20.0796 6.32697C20.0796 6.04689 20.1083 5.98225 20.3381 5.8099C21.0994 5.22819 20.9988 4.03604 20.1442 3.60515C19.8569 3.45433 19.3399 3.43997 19.0382 3.57642Z" />
            <path d="M2.96583 4.58903C2.41285 4.78293 2.10404 5.22819 2.10404 5.82426C2.10404 6.42033 2.44876 6.86559 3.03765 7.03795C3.38955 7.13849 3.45418 7.13849 3.77735 7.03077C4.02871 6.94459 4.20825 6.8225 4.36624 6.6286L4.58887 6.34852H5.25676C6.30527 6.34852 6.24782 6.24079 6.27655 8.40963L6.29091 10.1907L6.51354 10.1763C7.22452 10.1404 7.29633 9.94649 7.29633 7.97156C7.29633 6.38442 7.24606 6.16898 6.82235 5.75244C6.46327 5.39336 6.24064 5.32155 5.40039 5.28564L4.6176 5.24973L4.32315 4.94811C4.14362 4.7542 3.92099 4.61057 3.74145 4.5603C3.38237 4.45976 3.30337 4.46694 2.96583 4.58903Z" />
            <path d="M9.85298 8.0721C8.7901 8.40963 7.94986 9.42224 7.82059 10.5138L7.78468 10.8586L7.25324 10.8873C6.27655 10.9519 5.49375 11.4905 5.0844 12.3739C4.81868 12.9484 4.81868 13.7527 5.0844 14.3273C5.33576 14.8731 5.69484 15.2537 6.23346 15.5481L6.6859 15.7923L12.029 15.8139C18.0113 15.8354 17.7743 15.8498 18.3775 15.3255C19.2465 14.5571 19.2321 13.1064 18.3416 12.3523C18.0113 12.0722 17.5229 11.8783 17.1351 11.8783C16.8694 11.8783 16.8694 11.8783 16.8263 11.5193C16.7329 10.7365 16.1512 9.96086 15.3828 9.60896C14.9088 9.39351 14.2625 9.32169 13.8316 9.4366C13.53 9.5156 13.5084 9.5156 13.4007 9.35042C13.1924 9.02725 12.661 8.50299 12.3665 8.33063C11.6627 7.92128 10.6501 7.81356 9.85298 8.0721Z" />
            <path d="M0.883172 10.3343C-0.0719797 10.6718 -0.301791 11.7922 0.437913 12.496L0.746722 12.7976L0.775448 13.6809C0.804174 14.6361 0.904717 14.9592 1.27816 15.304C1.66597 15.663 1.91732 15.7133 3.54754 15.742C4.49551 15.7564 5.07004 15.742 5.07004 15.6989C5.07004 15.663 5.01977 15.584 4.94795 15.5338C4.88332 15.4763 4.72532 15.2609 4.58887 15.0526L4.3447 14.6792H3.28182C2.31231 14.6792 2.1974 14.6648 2.02504 14.5283C1.84551 14.3847 1.83832 14.3632 1.83832 13.5876V12.7904L2.12559 12.5462C2.91556 11.864 2.63548 10.5785 1.63724 10.2984C1.2997 10.205 1.22071 10.2122 0.883172 10.3343Z" />
            <path d="M22.191 11.153C22.0258 11.2248 21.8031 11.4044 21.6954 11.548L21.5015 11.8065H18.9018L19.1244 12.0363C19.2465 12.1656 19.4117 12.4098 19.4979 12.575L19.6487 12.8838H20.6038L21.5518 12.8909L21.6954 13.1136C21.9252 13.4655 22.2628 13.6163 22.7798 13.5876C23.1246 13.566 23.2754 13.5157 23.4836 13.3649C24.2808 12.7545 24.13 11.5695 23.2036 11.153C22.8373 10.9806 22.5644 10.9878 22.191 11.153Z" />
            <path d="M8.37357 16.9342C8.37357 17.4082 8.25148 17.6739 8.00013 17.7601C7.87804 17.8032 7.35379 17.8391 6.83671 17.8391C6.07546 17.8391 5.83847 17.8678 5.60866 17.9755C5.0485 18.2341 4.71096 18.7727 4.71096 19.3975C4.71096 19.7135 4.68942 19.7565 4.45242 19.9074C4.01435 20.1874 3.80608 20.8912 3.99998 21.4083C4.09334 21.6525 4.39497 21.9828 4.65351 22.1193C4.76123 22.1767 5.01259 22.2198 5.21367 22.2198C5.62302 22.2198 5.88156 22.1121 6.16164 21.8105C6.69308 21.2503 6.58536 20.288 5.95338 19.8858C5.70202 19.7278 5.65175 19.498 5.79538 19.182C5.92465 18.9019 6.15446 18.8445 7.21015 18.8445C8.33048 18.8445 8.62493 18.7583 8.97682 18.3346C9.28563 17.9755 9.37899 17.6882 9.37899 17.1209V16.6182H8.37357V16.9342Z" />
            <path d="M12.2516 20.5322L12.0362 20.6758C11.9141 20.7548 11.7346 20.9918 11.634 21.1929C11.476 21.5017 11.4545 21.6309 11.4904 21.8895C11.5909 22.5358 12.029 22.9452 12.6682 22.9954C13.3863 23.0457 13.889 22.6795 14.0398 21.99C14.1476 21.5232 14.0255 21.1713 13.6449 20.8051L13.3289 20.4963V16.6182H12.2516V20.5322Z" />
            <path d="M16.4313 17.6739C16.4529 18.5213 16.4888 18.787 16.5893 18.9881C16.9412 19.6632 17.3362 19.8643 18.3991 19.9074C19.1819 19.9361 19.1962 19.9433 19.3183 20.1372C19.6199 20.6471 20.4171 20.8266 20.9773 20.5106C21.5231 20.2018 21.7816 19.5483 21.5949 18.9666C21.4441 18.5141 20.7905 18.0545 20.2878 18.0545C20.0652 18.0545 19.4332 18.428 19.3111 18.6362C19.189 18.8445 19.1819 18.8445 18.4996 18.8445C17.8676 18.8445 17.7958 18.8301 17.6522 18.6793C17.5086 18.5285 17.4942 18.4351 17.4942 17.5662V16.6182H16.4026L16.4313 17.6739Z" />
        </g>
    );
}
