import React from 'react';
import {Translate} from 'react-localize-redux';
import classNames from 'classnames';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';

const FileForm = ({name, onSetName}) => (
    <Branding.Consumer>
        {colors => (
            <div className="form">
                <div className="form-group">
                    <label className={classNames('label', {isRequired: true})}>
                        <Translate id="documents.name" />
                        <span className="required">*</span>
                    </label>
                    <input
                        className="field input"
                        type="text"
                        value={name}
                        onChange={e => onSetName(e.target.value)}
                    />
                </div>

                <style jsx>
                    {`
                        label {
                            position: relative;
                            padding-right: ${theme.spacingSmall}rem;
                        }

                        .required {
                            color: ${colors.danger.base};
                            font-size: ${theme.fontSizeLarge}rem;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);

export default FileForm;
