import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M22.7322 0.833968C22.732 0.834066 22.7317 0.834164 22.7315 0.834263L1.02579 9.36397L0.875238 8.98658L1.02515 9.36422C0.965423 9.38758 0.91394 9.42786 0.877112 9.48004C0.840283 9.53223 0.819736 9.59402 0.818031 9.65771C0.816326 9.7214 0.833538 9.78419 0.867524 9.83825C0.90151 9.8923 0.950765 9.93524 1.00916 9.96171L1.00935 9.9618L9.79855 13.9518C10.0629 14.0692 10.2715 14.2833 10.3809 14.5494L13.9786 22.9895L13.9788 22.9898C14.0041 23.0493 14.0468 23.1 14.1013 23.1354C14.1558 23.1707 14.2197 23.1891 14.2849 23.1882C14.35 23.1872 14.4134 23.167 14.4669 23.1301C14.5203 23.0933 14.5615 23.0414 14.5851 22.9811L14.5855 22.9801L23.1599 1.25801C23.1599 1.25783 23.16 1.25764 23.1601 1.25746C23.183 1.19826 23.1882 1.13373 23.1749 1.07168C23.1616 1.00944 23.1303 0.952385 23.0849 0.907467C23.0395 0.86255 22.9819 0.831715 22.9191 0.818718C22.8567 0.805775 22.7917 0.811076 22.7322 0.833968ZM0.72488 8.6091C0.725029 8.60905 0.725177 8.60899 0.725326 8.60893L22.4322 0.0787647L22.434 0.0780598C22.6415 -0.00236639 22.8681 -0.0211353 23.0862 0.0240338C23.3043 0.0692041 23.5045 0.176366 23.6623 0.332456C23.8201 0.488544 23.9287 0.686822 23.975 0.903133C24.0212 1.11944 24.0031 1.34443 23.9228 1.55068L23.9221 1.55241L15.3475 23.2752C15.3474 23.2754 15.3473 23.2755 15.3473 23.2757C15.2647 23.4864 15.1206 23.6678 14.9336 23.7968C14.7464 23.9259 14.5246 23.9966 14.2966 23.9999C14.0687 24.0031 13.845 23.9388 13.6541 23.8151C13.4633 23.6914 13.314 23.5141 13.2254 23.3058M13.2252 23.3055L9.62654 14.8631L9.62403 14.857C9.59416 14.7837 9.53676 14.7247 9.46395 14.6926L9.4601 14.6909L9.4601 14.6909L0.669344 10.7001C0.669278 10.7001 0.669212 10.7001 0.669146 10.7C0.464856 10.6074 0.292534 10.4571 0.173626 10.268C0.0546803 10.0788 -0.00556371 9.85908 0.000404109 9.63615C0.00637185 9.41323 0.0782837 9.19697 0.207186 9.01431C0.335997 8.83179 0.516027 8.69088 0.72488 8.6091" />
            <path d="M14.2193 10.0515C14.3821 10.2069 14.387 10.4639 14.2303 10.6254L10.2618 14.7169C10.1052 14.8784 9.84627 14.8833 9.68356 14.7278C9.52084 14.5723 9.51592 14.3154 9.67257 14.1539L13.6411 10.0624C13.7977 9.90088 14.0566 9.896 14.2193 10.0515Z" />
        </g>
    );
}
