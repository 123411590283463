import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M7 6H17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 19V6"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.999 19H18.278L21.706 15.572C21.799 15.4792 21.8727 15.369 21.923 15.2477C21.9733 15.1264 21.9992 14.9963 21.9992 14.865C21.9992 14.7337 21.9733 14.6036 21.923 14.4823C21.8727 14.361 21.799 14.2508 21.706 14.158L20.842 13.294C20.7492 13.2011 20.639 13.1273 20.5177 13.077C20.3964 13.0267 20.2664 13.0008 20.135 13.0008C20.0037 13.0008 19.8736 13.0267 19.7523 13.077C19.631 13.1273 19.5208 13.2011 19.428 13.294L16 16.721L15.999 19Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
