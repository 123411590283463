import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import actions from 'store/actions';
import Mount from 'lib/components/Mount';
import {getById} from 'lib/store/reducers/helpers/byId';
import * as routes from 'routes/index';
import MostRead from './MostRead';

const MostReadWithRedux = ({
    messages,
    pages,
    fetchMostRead,
    isFetching,
    isFailed,
    pageCount,
    editMessage,
}) => {
    let rows = [];
    if (pages.length) {
        let count = 0;
        rows = pages.reduce(
            (accumulator, page) => [
                ...accumulator,
                ...page.map(messageId => {
                    const {
                        app_domain: appDomain,
                        title,
                        read_count: readCount = 0,
                        id,
                    } = getById(messages, messageId);
                    count += 1;

                    return {
                        key: id,
                        data: [
                            {content: appDomain.icon},
                            {content: `${count}.`},
                            {content: title},
                            {content: readCount},
                        ],
                    };
                }),
            ],
            [],
        );
    }

    const onListEnd = () => {
        if (!isFetching && !isFailed) {
            const page = pages.length + 1 || 1;

            if (page < pageCount + 1) {
                fetchMostRead(page);
            }
        }
    };

    return (
        <Mount didMount={fetchMostRead}>
            <MostRead
                messages={rows}
                onListEnd={onListEnd}
                isFetching={isFetching}
                onRowClick={editMessage}
            />
        </Mount>
    );
};

const mapStateToProps = state => ({
    messages: state.analytics.messages.mostRead.byId,
    pages: state.analytics.messages.mostRead.paginated.pages,
    isFetching: state.analytics.messages.mostRead.paginated.isFetching,
    isFailed: state.analytics.messages.mostRead.paginated.isFailed,
    pageCount: state.analytics.messages.mostRead.paginated.pageCount,
});

const mapDispatchToProps = (dispatch, props) => ({
    editMessage: id => {
        const {history} = props;
        history.push(routes.editorForMessageId('messages', id));
    },
    fetchMostRead: (page = 1) =>
        dispatch(
            actions.analytics.messages.fetchMostRead({
                page,
            }),
        ),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MostReadWithRedux),
);
