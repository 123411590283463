import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M10.5 0h3v24h-3zM15 6c0 1.64-1.36 3-3 3S9 7.64 9 6s1.36-3 3-3 3 1.36 3 3zm0 12c0 1.64-1.36 3-3 3s-3-1.36-3-3 1.36-3 3-3 3 1.36 3 3zm1.5-1.5H24v3h-7.5zM0 4.5h7.5v3H0zm0 0"
        />
    );
}
