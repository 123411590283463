import {CALL_API} from '../../constants/api';
import {POST} from '../services';

export const SET_TITLE = 'SET_TITLE';
export const SET_AUDIENCE_FILTERS = 'SET_AUDIENCE_FILTERS';
export const SET_APP_DOMAIN = 'SET_APP_DOMAIN';
export const SET_VISIBILITY = 'SET_VISIBILITY';
export const SET_ACCEPT_ANSWERS = 'SET_ACCEPT_ANSWERS';
export const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const SET_ENABLE_COMMENTS = 'SET_ENABLE_COMMENTS';
export const SET_SCHEDULED_AT = 'SET_SCHEDULED_AT';
export const SET_TRIGGER = 'SET_TRIGGER';
export const SET_DELAY = 'SET_DELAY';
export const SET_SEND_REMINDERS = 'SET_SEND_REMINDERS';
export const SET_ACTIVE_SIDEBAR = 'SET_ACTIVE_SIDEBAR';
export const SET_EDITOR_STATE = 'SET_EDITOR_STATE';
export const SET_ACCESSIBLE_FOR = 'SET_ACCESSIBLE_FOR';
export const EDITOR_LOAD_MESSAGE = 'EDITOR_LOAD_MESSAGE';
export const EDITOR_LOAD_TEMPLATE = 'EDITOR_LOAD_TEMPLATE';
export const EDITOR_LOAD_AUTOMATED_MESSAGE = 'EDITOR_LOAD_AUTOMATED_MESSAGE';
export const CLEAR_EDITOR = 'CLEAR_EDITOR';
export const START_NEW_AUTOMATED_MESSAGE = 'START_NEW_AUTOMATED_MESSAGE';
export const SET_STATUS = 'SET_STATUS';
export const SET_EDITOR = 'SET_EDITOR';

export const CONTENT_TYPE_MESSAGES = 'messages';
export const CONTENT_TYPE_AUTOMATED_MESSAGES = 'automated-messages';
export const CONTENT_TYPE_TEMPLATES = 'templates';

export const setTitle = title => ({
    type: SET_TITLE,
    payload: {title},
});

export const setAudienceFilters = audienceFilters => ({
    type: SET_AUDIENCE_FILTERS,
    payload: {audienceFilters},
});

export const setAppDomain = appDomain => ({
    type: SET_APP_DOMAIN,
    payload: {appDomain},
});

export const setVisibility = visibility => ({
    type: SET_VISIBILITY,
    payload: {visibility},
});

export const setAcceptAnswers = acceptAnswers => ({
    type: SET_ACCEPT_ANSWERS,
    payload: {acceptAnswers},
});

export const setAvailableLanguages = availableLanguages => ({
    type: SET_AVAILABLE_LANGUAGES,
    payload: {availableLanguages},
});

export const setCurrentLanguage = currentLanguage => ({
    type: SET_CURRENT_LANGUAGE,
    payload: {currentLanguage},
});

export const setEnableComments = enableComments => ({
    type: SET_ENABLE_COMMENTS,
    payload: {enableComments},
});

export const setEditorState = editorState => ({
    type: SET_EDITOR_STATE,
    payload: {editorState},
});

export const setScheduledAt = scheduledAt => ({
    type: SET_SCHEDULED_AT,
    payload: {scheduledAt},
});

export const setTrigger = trigger => ({
    type: SET_TRIGGER,
    payload: {trigger},
});

export const setStatus = status => ({
    type: SET_STATUS,
    payload: {status},
});

export const setDelay = delay => ({
    type: SET_DELAY,
    payload: {delay},
});

export const setSendReminders = sendReminders => ({
    type: SET_SEND_REMINDERS,
    payload: {sendReminders},
});

export const setAccessibleFor = accessibleFor => ({
    type: SET_ACCESSIBLE_FOR,
    payload: {accessibleFor},
});

export const loadMessageIntoEditor = message => ({
    type: EDITOR_LOAD_MESSAGE,
    payload: {message},
});

export const loadTemplateIntoEditor = template => ({
    type: EDITOR_LOAD_TEMPLATE,
    payload: {template},
});

export const loadAutomatedMessageIntoEditor = message => ({
    type: EDITOR_LOAD_AUTOMATED_MESSAGE,
    payload: {message},
});

export const startNewAutomatedMessage = body => ({
    type: START_NEW_AUTOMATED_MESSAGE,
    payload: {body},
});

export const clearEditor = () => ({
    type: CLEAR_EDITOR,
    payload: {},
});

export const setActiveSidebar = (sidebarType, sidebarData = null) => ({
    type: SET_ACTIVE_SIDEBAR,
    payload: {
        sidebarType,
        sidebarData,
    },
});

export const setEditor = editor => ({
    type: SET_EDITOR,
    payload: {editor},
});

export const TRANSLATE_UPDATE_REQUEST = 'TRANSLATE_UPDATE_REQUEST';
export const TRANSLATE_UPDATE_SUCCESS = 'TRANSLATE_UPDATE_SUCCESS';
export const TRANSLATE_UPDATE_FAILURE = 'TRANSLATE_UPDATE_FAILURE';

export const generateTranslations = (editor, sourceLanguageKey) => {
    const endpointPrefix =
        editor.contentType === 'automated-messages'
            ? 'auto-messages'
            : 'messages';
    return {
        [CALL_API]: {
            endpoint: `/${endpointPrefix}/${editor.contentId}/translate`,
            method: POST,
            body: {
                language_source: sourceLanguageKey,
            },
            types: [
                TRANSLATE_UPDATE_REQUEST,
                TRANSLATE_UPDATE_SUCCESS,
                TRANSLATE_UPDATE_FAILURE,
            ],
        },
    };
};

export default {
    setTitle,
    setAppDomain,
    setVisibility,
    setAcceptAnswers,
    setAvailableLanguages,
    setCurrentLanguage,
    setEnableComments,
    setAudienceFilters,
    setScheduledAt,
    setTrigger,
    setDelay,
    setActiveSidebar,
    setEditorState,
    loadMessageIntoEditor,
    loadTemplateIntoEditor,
    loadAutomatedMessageIntoEditor,
    clearEditor,
    startNewAutomatedMessage,
    setSendReminders,
    setAccessibleFor,
    setStatus,
    setEditor,
    generateTranslations,
};
