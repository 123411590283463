import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {POST, GET, DELETE, PUT} from '../../services';

export const DIRECTORY_SCHEMA = new schema.Entity('directory');

export const DIRECTORIES_VIEW_REQUEST = 'DIRECTORIES_VIEW_REQUEST';
export const DIRECTORIES_VIEW_SUCCESS = 'DIRECTORIES_VIEW_SUCCESS';
export const DIRECTORIES_VIEW_FAILURE = 'DIRECTORIES_VIEW_FAILURE';

export const fetchDirectory = (directoryId, user) => {
    const userPart = user ? '/user' : '';
    const subDirectory = directoryId ? `/${directoryId}` : '';
    const endpoint = `${userPart}/directories${subDirectory}`;

    return {
        [CALL_API]: {
            endpoint,
            method: GET,
            types: [
                DIRECTORIES_VIEW_REQUEST,
                DIRECTORIES_VIEW_SUCCESS,
                DIRECTORIES_VIEW_FAILURE,
            ],
            schema: DIRECTORY_SCHEMA,
        },
    };
};

export const DIRECTORY_CREATE_REQUEST = 'DIRECTORY_CREATE_REQUEST';
export const DIRECTORY_CREATE_SUCCESS = 'DIRECTORY_CREATE_SUCCESS';
export const DIRECTORY_CREATE_FAILURE = 'DIRECTORY_CREATE_FAILURE';

export const createDirectory = (directoryId, values) => {
    const endpoint =
        directoryId === null
            ? '/directories'
            : `/directories/${directoryId}/subdirectories`;

    return {
        [CALL_API]: {
            endpoint,
            method: POST,
            body: values,
            types: [
                DIRECTORY_CREATE_REQUEST,
                DIRECTORY_CREATE_SUCCESS,
                DIRECTORY_CREATE_FAILURE,
            ],
            notifications: {
                success: {
                    key: 'notifications.directory_created',
                },
            },
            schema: DIRECTORY_SCHEMA,
        },
    };
};

export const DIRECTORY_UPDATE_REQUEST = 'DIRECTORY_UPDATE_REQUEST';
export const DIRECTORY_UPDATE_SUCCESS = 'DIRECTORY_UPDATE_SUCCESS';
export const DIRECTORY_UPDATE_FAILURE = 'DIRECTORY_UPDATE_FAILURE';

export const updateDirectory = (directoryId, values) => ({
    [CALL_API]: {
        endpoint: `/directories/${directoryId}`,
        method: PUT,
        body: values,
        types: [
            DIRECTORY_UPDATE_REQUEST,
            DIRECTORY_UPDATE_SUCCESS,
            DIRECTORY_UPDATE_FAILURE,
        ],
        notifications: {
            success: {
                key: 'notifications.directory_updated',
            },
        },
        schema: DIRECTORY_SCHEMA,
    },
});

export const DIRECTORY_DELETE_REQUEST = 'DIRECTORY_DELETE_REQUEST';
export const DIRECTORY_DELETE_SUCCESS = 'DIRECTORY_DELETE_SUCCESS';
export const DIRECTORY_DELETE_FAILURE = 'DIRECTORY_DELETE_FAILURE';

export const deleteDirectory = directoryId => ({
    [CALL_API]: {
        endpoint: `/directories/${directoryId}`,
        method: DELETE,
        types: [
            DIRECTORY_DELETE_REQUEST,
            DIRECTORY_DELETE_SUCCESS,
            DIRECTORY_DELETE_FAILURE,
        ],
        notifications: {
            success: {
                key: 'notifications.directory_deleted',
            },
        },
        schema: DIRECTORY_SCHEMA,
    },
});

export const FILE_CREATE_REQUEST = 'FILE_CREATE_REQUEST';
export const FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS';
export const FILE_CREATE_FAILURE = 'FILE_CREATE_FAILURE';

export const createFile = (activeDirectoryId, values) => {
    const endpoint =
        activeDirectoryId === null
            ? '/directories/media-items'
            : `/directories/${activeDirectoryId}/media-items`;

    return {
        [CALL_API]: {
            endpoint,
            method: POST,
            body: values,
            types: [
                FILE_CREATE_REQUEST,
                FILE_CREATE_SUCCESS,
                FILE_CREATE_FAILURE,
            ],
            notifications: {
                success: {
                    key: 'notifications.file_created',
                },
            },
            schema: DIRECTORY_SCHEMA,
        },
    };
};

export const FILE_UPDATE_REQUEST = 'FILE_UPDATE_REQUEST';
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS';
export const FILE_UPDATE_FAILURE = 'FILE_UPDATE_FAILURE';

export const updateFile = (activeDirectoryId, fileId, values) => {
    const endpoint =
        activeDirectoryId === null
            ? `/directories/media-items/${fileId}`
            : `/directories/${activeDirectoryId}/media-items/${fileId}`;
    return {
        [CALL_API]: {
            endpoint,
            method: PUT,
            body: values,
            types: [
                FILE_UPDATE_REQUEST,
                FILE_UPDATE_SUCCESS,
                FILE_UPDATE_FAILURE,
            ],
            notifications: {
                success: {
                    key: 'notifications.file_updated',
                },
            },
            schema: DIRECTORY_SCHEMA,
        },
    };
};

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

export const deleteFile = (activeDirectoryId, fileId) => {
    const endpoint =
        activeDirectoryId === null
            ? `/directories/media-items/${fileId}`
            : `/directories/${activeDirectoryId}/media-items/${fileId}`;
    return {
        [CALL_API]: {
            endpoint,
            method: DELETE,
            types: [
                FILE_DELETE_REQUEST,
                FILE_DELETE_SUCCESS,
                FILE_DELETE_FAILURE,
            ],
            notifications: {
                success: {
                    key: 'notifications.file_deleted',
                },
            },
            schema: DIRECTORY_SCHEMA,
        },
    };
};

export default {
    fetchDirectory,
    createDirectory,
    updateDirectory,
    deleteDirectory,
    createFile,
    deleteFile,
    updateFile,
};
