import React from 'react';
import {useDispatch} from 'react-redux';
import InfoTooltip from 'lib/components/InfoTooltip';
import actions from 'store/actions';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import EditorSidebarSwitch from './EditorSidebarSwitch';

const EditorSidebarSendRemindersWithState = ({canEdit}) => {
    const {editor} = useEditorState();
    const {translate} = useTranslate();
    const {saveContent} = useContentSaver();

    const dispatch = useDispatch();
    const setSendReminders = sendReminders =>
        dispatch(actions.editor.setSendReminders(sendReminders));

    const onChangeSendReminders = async sendReminders => {
        await setSendReminders(sendReminders);
        saveContent({...editor, sendReminders});
    };

    return (
        <EditorSidebarSwitch
            value={editor.sendReminders}
            onChange={onChangeSendReminders}
            canEdit={canEdit}
            label={
                <InfoTooltip info={translate('editor.form.reminder.info')}>
                    {translate('editor.form.reminder.placeholder')}
                </InfoTooltip>
            }
        />
    );
};

export default EditorSidebarSendRemindersWithState;
