import './MessageInfo.css';

import React from 'react';
import format from 'date-fns/format';
import Icon from 'lib/components/Icon';
import {getDateLocale} from 'lib/helpers/getDateLocale';

const MessageInfo = ({
    icon,
    name,
    title,
    type,
    audience,
    time,
    langCode,
    onNameClick,
    color,
    defaultLanguage,
    isAdmin,
}) => {
    return (
        <div className="message-info">
            <div
                className="message-info__header"
                style={{backgroundColor: color}}>
                <div className="message-info__header__top">
                    <span className="icon">
                        <Icon name={icon} size={12} />
                    </span>
                    <span className="className message-info__header__name">
                        {name}
                    </span>
                </div>
                <div onClick={isAdmin ? onNameClick : () => {}}>
                    <h2 id="nameClickButton" className="message-info__title">
                        <span>{title[defaultLanguage]}</span>
                        {isAdmin && <Icon name="pen" size={16} />}
                    </h2>
                </div>
            </div>
            <div className="message-info__content">
                {type && (
                    <div className="message-info__content__row">
                        <span className="icon" style={{color}}>
                            <Icon name="send" size={12} />
                        </span>{' '}
                        {type}
                    </div>
                )}
                {audience && (
                    <div className="message-info__content__row">
                        <span className="icon" style={{color}}>
                            <Icon name="users" size={12} />
                        </span>{' '}
                        {audience}
                    </div>
                )}
                {time && (
                    <div className="message-info__content__row">
                        <span className="icon" style={{color}}>
                            <Icon name="event" size={12} />
                        </span>{' '}
                        {format(
                            time,
                            'ddd DD/MM - HHumm',
                            getDateLocale(langCode),
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessageInfo;
