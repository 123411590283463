import React from 'react';
import Modal from 'modals/Modal/ModalWithState';
import YesNoButton from 'lib/components/YesNoButton';
import useSurveyEditor from 'components/hooks/useSurveyEditor';
import {MODAL_YES_NO_QUESTION} from 'lib/store/actions/modals';

const YesNoQuestionModal = () => {
    const {
        baseForm,
        isSaving,
        onSave,
        translate,
        language,
        headerTitleKey,
        buttonTitleKey,
    } = useSurveyEditor();

    return (
        <Modal
            name={MODAL_YES_NO_QUESTION}
            icon="yesNo"
            title={`${translate(headerTitleKey, {name: translate('editor.survey.yes_no_question')})} (${language})`}
            cancelable
            buttonActions={[
                {
                    label: translate(buttonTitleKey),
                    loading: isSaving,
                    callBack: onSave,
                    classes: 'success',
                },
            ]}>
            <div className="form">{baseForm}</div>
            <div className="text-center">
                <YesNoButton>{translate('global.yes')}</YesNoButton>
                <YesNoButton>{translate('global.no')}</YesNoButton>
            </div>
        </Modal>
    );
};

export default YesNoQuestionModal;
