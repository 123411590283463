import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import actions from 'store/actions';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import CloseButton from 'lib/components/CloseButton';
import EditButton from 'lib/components/EditButton';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';
import {getById} from 'lib/store/reducers/helpers/byId';
import getUserRoleHelper, {
    isSuperUser as isSuperUserHelper,
} from 'util/getUserRoleHelper';
import Icon from 'lib/components/Icon';

const MessageThumb = ({
    icon,
    image,
    title,
    onEdit,
    onDelete,
    onClick,
    setModalVisibility,
    setModalData,
    isSuperUser,
}) => (
    <Branding.Consumer>
        {colors => (
            <div
                className="message-thumb actions"
                role="button"
                tabIndex={-1}
                onClick={isSuperUser ? onClick : () => {}}>
                {!isSuperUser && (
                    <div className="actions__content" onClick={onClick}>
                        {onEdit && (
                            <EditButton
                                className="message-thumb__action"
                                onClick={e => {
                                    e.stopPropagation();
                                    onEdit();
                                }}
                            />
                        )}
                        {onDelete && (
                            <CloseButton
                                className="action"
                                onClick={e => {
                                    e.stopPropagation();

                                    setModalVisibility(MODAL_CONFIRM, true);
                                    setModalData({
                                        title: (
                                            <Translate id="templates.confirm_delete" />
                                        ),
                                        buttonActions: [
                                            {
                                                label: (
                                                    <Translate id="global.delete" />
                                                ),
                                                callBack: () => {
                                                    setModalVisibility(
                                                        MODAL_CONFIRM,
                                                        false,
                                                    );
                                                    onDelete(false);
                                                },
                                                classes: 'danger',
                                            },
                                        ],
                                    });
                                }}
                            />
                        )}
                    </div>
                )}
                {icon && (
                    <div className="icon">
                        <Icon name={icon} size={65} />
                    </div>
                )}
                {image && (
                    <div
                        className="image"
                        style={{backgroundImage: `url(${image})`}}
                    />
                )}
                {!icon && !image && <div className="empty" />}

                <div className="message-thumb__title">{title}</div>

                <style jsx>
                    {`
                        .message-thumb {
                            position: relative;
                            margin-bottom: 4.4rem;
                            cursor: pointer;
                        }

                        .empty,
                        .image,
                        .icon {
                            background-color: ${colors.grey[100]};
                            width: 100%;
                            height: 13rem;
                            border-radius: 0.4rem;
                            margin-bottom: 1rem;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .image {
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                        }

                        .title {
                            display: block;
                            text-align: center;
                            font-weight: ${theme.fwBold};
                            font-size: 1.6rem;
                            color: ${colors.brand[900]};
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);

const mapStateToProps = ({users}) => {
    const meId = users.me;
    const me = getById(users.byId, meId) || {};
    const userRole = getUserRoleHelper(me);
    return {
        isSuperUser: isSuperUserHelper(userRole),
    };
};

const mapDispatchToProps = dispatch => ({
    setModalVisibility: (modal, visibility) => {
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    },
    setModalData: data => {
        dispatch(actions.modals.setConfirmModalData(data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageThumb);
