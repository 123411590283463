import {combineReducers} from 'redux';

import {
    FETCH_CHANNELS_REQUEST,
    FETCH_CHANNELS_SUCCESS,
    FETCH_CHANNELS_FAILURE,
    FETCH_USER_CHANNELS_REQUEST,
    FETCH_USER_CHANNELS_SUCCESS,
    FETCH_USER_CHANNELS_FAILURE,
    UPDATE_USER_CHANNELS_REQUEST,
    UPDATE_USER_CHANNELS_SUCCESS,
    UPDATE_USER_CHANNELS_FAILURE,
    SET_SELECTED_CHANNELS,
} from '../../actions/channels';

export const fetchingChannels = (state = false, action) => {
    switch (action.type) {
        case FETCH_CHANNELS_REQUEST:
            return true;
        case FETCH_CHANNELS_SUCCESS:
        case FETCH_CHANNELS_FAILURE:
            return false;
        default:
            return state;
    }
};

const channels = (state = [], action) => {
    switch (action.type) {
        case FETCH_CHANNELS_SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

export const fetchingUserChannels = (state = false, action) => {
    switch (action.type) {
        case FETCH_USER_CHANNELS_REQUEST:
            return true;
        case FETCH_USER_CHANNELS_SUCCESS:
        case FETCH_USER_CHANNELS_FAILURE:
            return false;
        default:
            return state;
    }
};

export const userChannels = (state = [], action) => {
    switch (action.type) {
        case FETCH_USER_CHANNELS_SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

export const updatingUserChannels = (state = false, action) => {
    switch (action.type) {
        case UPDATE_USER_CHANNELS_REQUEST:
            return true;
        case UPDATE_USER_CHANNELS_SUCCESS:
        case UPDATE_USER_CHANNELS_FAILURE:
            return false;
        default:
            return state;
    }
};

export const selectedChannels = (state = [], action) => {
    switch (action.type) {
        case SET_SELECTED_CHANNELS:
            return action.payload;
        case FETCH_USER_CHANNELS_SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

export default combineReducers({
    fetchingChannels,
    channels,
    fetchingUserChannels,
    userChannels,
    updatingUserChannels,
    selectedChannels,
});
