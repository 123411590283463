import React from 'react';

const Search = () => (
    <g stroke="currentColor" fill="none">
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </g>
);

export default Search;
