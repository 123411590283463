import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M19.414 6.128L16.871 3.585C16.4957 3.21045 15.9872 3.00007 15.457 3H9C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5V15.714C7 16.2444 7.21071 16.7531 7.58579 17.1282C7.96086 17.5033 8.46957 17.714 9 17.714H18C18.5304 17.714 19.0391 17.5033 19.4142 17.1282C19.7893 16.7531 20 16.2444 20 15.714V7.543C20 7.012 19.789 6.504 19.414 6.128Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 8H16C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 17.714V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H7"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
