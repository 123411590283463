import React from 'react';
import PropTypes from 'prop-types';
import {brandingDefaults} from 'lib/styles/colors';

const CustomizedAxisTick = ({
    x,
    y,
    payload,
    index,
    data,
    activePeriod,
    min,
    max,
    color,
}) => {
    const inRange =
        !min || !max || (data[index].value >= min && data[index].value <= max);
    const isActive = activePeriod === data[index].id;
    const width = payload.offset - 8;

    let textColor = '#004463';
    if (isActive) {
        textColor = color;
    }
    if (!inRange) {
        textColor = brandingDefaults.danger.base;
    }

    return (
        <g
            transform={`translate(${x},${y})`}
            className={`
                chart-modal__label
                ${isActive ? 'chart-modal__label--active' : ''}
                ${!inRange ? 'chart-modal__label--out-of-range' : ''}
            `}>
            <text x={0} y={0} dy={12} textAnchor="middle" fill={textColor}>
                {payload.value}
            </text>
            <line x1={-width} y1={20} x2={width} y2={20} />
        </g>
    );
};

CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
    index: PropTypes.number,
    data: PropTypes.array,
    activePeriod: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    color: PropTypes.string,
};
CustomizedAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: {},
    index: 0,
    data: [],
    activePeriod: '',
    min: 0,
    max: 0,
    color: '',
};
export default CustomizedAxisTick;
