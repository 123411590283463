/* eslint-disable react/sort-comp */
import './RangeSelector.css';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getActiveLanguage, getTranslate} from 'react-localize-redux';
import differenceInWeeks from 'date-fns/difference_in_weeks';
import startOfMonth from 'date-fns/start_of_month';
import endOfMonth from 'date-fns/end_of_month';
import startOfWeek from 'date-fns/start_of_week';
import endOfWeek from 'date-fns/end_of_week';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import addMonths from 'date-fns/add_months';
import subMonths from 'date-fns/sub_months';
import isSameDay from 'date-fns/is_same_day';
import parse from 'date-fns/parse';
import {getById} from 'lib/store/reducers/helpers/byId';
import actions from 'store/actions';
import MonthCalendar from 'components/MonthCalendar/MonthCalendar';
import Dropdown from 'components/Dropdown';
import ContentView from 'components/util/ContentView';

import {STATUS_DRAFT, STATUS_PUBLISHED} from 'lib/store/actions/messages/index';
import {fetchAll} from 'util/fetchAll';
import {MANUAL} from 'lib/constants/messages';
import {MODAL_NEW_MESSAGE} from 'lib/store/actions/modals';
import getAppDomainNameForLanguage from 'util/appDomains';
import Icon from 'lib/components/Icon';

class Planning extends Component {
    constructor(props) {
        super(props);
        const today = new Date();

        this.state = {
            start: startOfWeek(startOfMonth(today), {weekStartsOn: 1}),
            end: endOfWeek(endOfMonth(today), {weekStartsOn: 1}),
            month: startOfMonth(today),
            weekView: 'week',
        };
    }

    componentDidMount() {
        const {start, end} = this.state;
        this.props.fetchMessages(start, end);
    }

    componentDidUpdate(prevProps, prevState) {
        const {start, end} = this.state;

        if (prevState.start !== this.state.start) {
            this.props.fetchMessages(start, end);
        }
    }

    render() {
        const {start, end, month, weekView} = this.state;

        const {pages, messages, translate, langCode, startNewMessage} =
            this.props;

        const weekLength = weekView === 'workweek' ? 5 : 7;

        const headerComponents = [
            <Dropdown
                key={2}
                value={weekView}
                onChange={value => {
                    this.setState({weekView: value});
                }}>
                <option value="week">{translate('planning.week_view')}</option>
                <option value="workweek">
                    {translate('planning.workweek_view')}
                </option>
            </Dropdown>,
            <div key="1" className="range-selector">
                <button
                    className="range-selector__prev"
                    onClick={() => {
                        this._previousMonth();
                    }}
                    type="button">
                    <Icon name="chevronLeft" />
                </button>
                <span className="range-selector__content">
                    {format(month, 'MMMM YYYY')}
                </span>
                <button
                    className="range-selector__next"
                    onClick={() => {
                        this._nextMonth();
                    }}
                    type="button">
                    <Icon name="chevronRight" />
                </button>
            </div>,
        ];

        const weekCount = differenceInWeeks(end, start) + 1;
        const calendarStart = startOfWeek(start);

        // Map the messages on the correct days
        const messagesMapped = [];
        if (pages.length) {
            pages.forEach(page => {
                page.forEach(messageId => {
                    const message = getById(messages, messageId);

                    if (
                        ((message.status === STATUS_PUBLISHED &&
                            message.published_at) ||
                            message.scheduled_at) &&
                        message.app_domain
                    ) {
                        messagesMapped.push(
                            Object.assign({}, message, {
                                name: getAppDomainNameForLanguage(
                                    message.app_domain,
                                    langCode,
                                ),
                                icon: message.app_domain.icon,
                                messageType: translate(
                                    `planning.${message.type ? message.type : MANUAL}`,
                                ),
                            }),
                        );
                    }
                });
            });
        }

        // Map days
        const weeks = [];
        let day = calendarStart;
        for (let i = 0; i < weekCount; i += 1) {
            const week = {
                days: [],
                info: {
                    planned_messages: 0,
                    planned_concept: 0,
                    automated_messages: 0,
                    manual_messages: 0,
                },
            };

            for (let j = 0; j < 7; j += 1) {
                day = addDays(day, 1); // Add a day

                week.days.push({
                    date: day,
                    messages: messagesMapped.filter(
                        // eslint-disable-next-line
                        message => {
                            let messageDay =
                                message.published_at || message.scheduled_at;
                            if (message.status === STATUS_PUBLISHED) {
                                messageDay = message.published_at;
                            } else {
                                messageDay = message.scheduled_at;
                            }
                            if (isSameDay(day, parse(messageDay))) {
                                if (message.status === STATUS_DRAFT) {
                                    week.info.planned_concept += 1;
                                } else {
                                    week.info.planned_messages += 1;
                                }
                                return true;
                            }
                        },
                    ),
                });
            }

            weeks.push(week);
        }

        return (
            <ContentView
                title={translate('global.planning')}
                headerComponents={headerComponents}>
                <MonthCalendar
                    weekLength={weekLength}
                    data={weeks}
                    month={month}
                    messages={messagesMapped}
                    langCode={langCode}
                    startNewMessage={startNewMessage}
                />
            </ContentView>
        );
    }

    _nextMonth() {
        const month = addMonths(this.state.month, 1);
        this._changeMonth(month);
    }

    _previousMonth() {
        const month = subMonths(this.state.month, 1);
        this._changeMonth(month);
    }

    _changeMonth(month) {
        this.setState({
            start: startOfWeek(startOfMonth(month), {weekStartsOn: 1}),
            end: endOfWeek(endOfMonth(month), {weekStartsOn: 1}),
            month,
        });
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    messages: state.messages.byId,
    pages: state.messages.paginatedPlanning.pages,
    filters: state.messages.paginatedPlanning.filters,
    isFetching: state.messages.paginatedPlanning.isFetching,
    isFailed: state.messages.paginatedPlanning.isFailed,
    langCode: getActiveLanguage(state.localize).code,
});

const mapDispatchToProps = dispatch => ({
    fetchMessages: (start, end) =>
        fetchAll(
            dispatch,
            actions.messages.fetchCalendarMessages,
            {
                filters: {
                    from: format(start, 'YYYY-MM-DD'),
                    to: format(end, 'YYYY-MM-DD'),
                },
            },
            'planning',
        ),
    startNewMessage: day => {
        dispatch(actions.modals.setModalVisibility(MODAL_NEW_MESSAGE, true));
        dispatch(actions.modals.setModalData(MODAL_NEW_MESSAGE, {day}));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
