import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import actions from 'store/actions';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {
    addSegment,
    updateSegment,
    deleteSegment,
    findAddedUsers,
    findRemovedUsers,
} from 'util/segmentationHelper';
import {AND, OR, NOT} from 'util/constants';
import AudienceSelect from './AudienceSelect';

export const COMPANY = 'company';
export const ACTIVE = 'active';
export const USERS = 'users';

const AudienceSelectWithData = ({
    segments,
    onSegmentsChange,
    onUpdateClick,
    parentId,
    hasDefaultAudienceFilters,
    onDefaultAudienceFilterChange,
}) => {
    const dispatch = useDispatch();
    const availableSegments = useSelector(
        state => state.segmentation.segments.data,
    );
    const isFetchingSegments = useSelector(
        state => state.segmentation.segments.isFetching,
    );

    const getSegmentGroups = () =>
        dispatch(actions.segmentation.getSegmentGroups());
    useEffect(() => {
        getSegmentGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isFetchingSegments || !availableSegments) {
        return <LoadingWrapper loading />;
    }

    // Filter out users and company and the rest in seperate arrays
    const [selectedUsers, companySegment, selectedSegments] = segments.reduce(
        (result, element) => {
            const newResult = [...result];

            switch (element && element.filter) {
                case USERS:
                    if (newResult[0] === null) {
                        newResult[0] = [element];
                    } else {
                        newResult[0] = [...newResult[0], element];
                    }
                    break;
                case COMPANY:
                    newResult[1] = element;
                    break;
                case ACTIVE:
                    newResult[2] = [...newResult[2], element];

                    break;
                default:
                    newResult[2] = [...newResult[2], element];
            }
            return newResult;
        },
        [null, null, []],
    );
    const activeSegment = segments.some(segment => segment.filter === ACTIVE);
    // Map segments
    const {users, company, ...otherFilters} = availableSegments;
    const mappedSegments = Object.values(otherFilters).reduce(
        (acc, segment) => {
            if (segment.segments && segment.segments.length > 0) {
                return [...acc, segment];
            }
            return acc;
        },
        [],
    );
    let firstSegmentSubSegments = mappedSegments[0].segments;
    if (
        typeof firstSegmentSubSegments === 'undefined' ||
        firstSegmentSubSegments.length === 0
    ) {
        firstSegmentSubSegments = [{}];
    }

    const toggleSendToEveryone = sendToEveryone => {
        let newSegments;
        if (sendToEveryone) {
            // Filter out active segment
            const filteredSegments = segments.filter(
                segment => segment.filter !== ACTIVE,
            );

            newSegments = [
                ...filteredSegments,
                {
                    filter: COMPANY,
                    type: AND,
                    value: 'all',
                },
            ];
        } else {
            // Remove company segment if it exists
            newSegments = segments.filter(
                segment => segment.filter !== COMPANY,
            );
        }
        onSegmentsChange(newSegments);
    };

    const toggleSendToActive = sendToActive => {
        let newSegments;
        if (sendToActive) {
            // Filter out company segment
            const filteredSegments = segments.filter(
                segment => segment.filter !== COMPANY,
            );

            newSegments = [
                ...filteredSegments,
                {
                    filter: ACTIVE,
                    type: AND,
                    value: 'active',
                },
            ];
        } else {
            // Remove active segment if it exists
            newSegments = segments.filter(segment => segment.filter !== ACTIVE);
        }
        onSegmentsChange(newSegments);
    };

    const addOrUpdateUserSegment = (selectedOptions, index, type) => {
        let list = [];
        if (selectedOptions !== null) {
            list = selectedOptions.map(({value}) => value);
        }

        let newSegments = [...segments];

        if (index >= 0) {
            // Update user segment if it exists
            newSegments[index].value = list;
        } else {
            // Add user segment if it doesn't exists
            newSegments.push({
                filter: USERS,
                type,
                value: list,
            });
        }

        newSegments = newSegments.filter(
            segment =>
                !(segment.filter === USERS && segment.value.length === 0),
        );

        return newSegments;
    };

    // Users added handlers and state
    const usersAddedSegment = segments.filter(findAddedUsers);
    const onUsersAddedChange = selectedOptions => {
        const index = segments.findIndex(findAddedUsers);
        const newSegments = addOrUpdateUserSegment(selectedOptions, index, OR);
        onSegmentsChange(newSegments);
    };
    let usersAdded = [];
    if (
        typeof usersAddedSegment !== 'undefined' &&
        typeof usersAddedSegment[0] !== 'undefined'
    ) {
        usersAdded = usersAddedSegment[0].value;
    }

    // Users removed handlers and state
    const onUsersRemovedChange = selectedOptions => {
        const index = segments.findIndex(findRemovedUsers);
        const newSegments = addOrUpdateUserSegment(selectedOptions, index, NOT);
        onSegmentsChange(newSegments);
    };
    const usersRemovedSegment = segments.filter(
        segment => segment.filter === USERS && segment.type === NOT,
    );
    let usersRemoved = [];
    if (
        typeof usersRemovedSegment !== 'undefined' &&
        typeof usersRemovedSegment[0] !== 'undefined'
    ) {
        usersRemoved = usersRemovedSegment[0].value;
    }

    const changeSegment = list => {
        const newSegments = list;

        // Add selected users if present
        if (selectedUsers) {
            newSegments.push(...selectedUsers);
        }

        // Add company is present
        if (companySegment) {
            newSegments.push(companySegment);
        }

        onSegmentsChange(newSegments);
    };

    const userOptions = availableSegments.users.segments.map(user => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
    }));

    const onAddSubFilter = index => {
        changeSegment(
            addSegment(
                selectedSegments,
                {
                    filter: mappedSegments[0].id,
                    type: AND,
                    value: firstSegmentSubSegments[0].id,
                },
                index,
            ),
        );
    };

    const onChangeSegment = (newSegment, index, subIndex) => {
        changeSegment(
            updateSegment(selectedSegments, newSegment, index, subIndex),
        );
    };

    const onDeleteSegment = (index, subIndex) => {
        changeSegment(deleteSegment(selectedSegments, index, subIndex));
    };

    const onAddFilter = () => {
        changeSegment(
            addSegment(selectedSegments, {
                filter: mappedSegments[0].id,
                type: AND,
                value: firstSegmentSubSegments[0].id,
            }),
        );
    };

    return (
        <AudienceSelect
            segments={mappedSegments}
            sendToEveryone={companySegment}
            sendToActive={activeSegment}
            selectedSegments={selectedSegments}
            onSendToEveryoneChange={toggleSendToEveryone}
            onSendToActiveChange={toggleSendToActive}
            onUpdateClick={onUpdateClick}
            parentId={parentId}
            hasDefaultAudienceFilters={hasDefaultAudienceFilters}
            onDefaultAudienceFilterChange={onDefaultAudienceFilterChange}
            userOptions={userOptions}
            usersAdded={usersAdded}
            usersRemoved={usersRemoved}
            onUsersAddedChange={onUsersAddedChange}
            onUsersRemovedChange={onUsersRemovedChange}
            onAddSubFilter={onAddSubFilter}
            onChangeSegment={onChangeSegment}
            onDeleteSegment={onDeleteSegment}
            onAddFilter={onAddFilter}
        />
    );
};

AudienceSelectWithData.propTypes = {
    segments: PropTypes.arrayOf(PropTypes.object),
    onSegmentsChange: PropTypes.func,
    onUpdateClick: PropTypes.func,
    parentId: PropTypes.number,
    hasDefaultAudienceFilters: PropTypes.bool,
    onDefaultAudienceFilterChange: PropTypes.func,
};
AudienceSelectWithData.defaultProps = {
    segments: [],
    onSegmentsChange: () => {},
    onUpdateClick: () => {},
    parentId: null,
    hasDefaultAudienceFilters: false,
    onDefaultAudienceFilterChange: () => {},
};
export default AudienceSelectWithData;
