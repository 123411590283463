import React from 'react';
import CustomBlocks from 'lib/constants/customBlocks';

import Scale from '../../Scale';
import CustomBlock from '../CustomBlock/CustomBlock';

const RatingScaleBlock = ({
    translate,
    survey,
    onEdit,
    onDelete,
    onChange,
    interactive,
    canEdit,
}) => {
    const response = survey.response && survey.response.option.value;

    return (
        <CustomBlock
            type={CustomBlocks.SURVEY_BLOCK}
            label={translate('editor.survey.rating')}
            icon="star"
            title={survey.content.question}
            description={survey.content.description}
            onEdit={() => {
                onEdit(survey);
            }}
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            <Scale
                type="rating"
                value={interactive ? response : survey.options.length}
                maxValue={interactive ? survey.options.length : 10}
                interactive={interactive}
                onChange={value => interactive && onChange({value})}
            />
        </CustomBlock>
    );
};

export default RatingScaleBlock;
