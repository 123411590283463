import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M5.12701 10.045V9.873C5.12701 8.97042 5.30479 8.07669 5.65019 7.24282C5.99559 6.40895 6.50185 5.65127 7.14007 5.01306C7.77829 4.37484 8.53596 3.86858 9.36983 3.52318C10.2037 3.17778 11.0974 3 12 3C12.9026 3 13.7963 3.17778 14.6302 3.52318C15.4641 3.86858 16.2217 4.37484 16.86 5.01306C17.4982 5.65127 18.0044 6.40895 18.3498 7.24282C18.6952 8.07669 18.873 8.97042 18.873 9.873V10.045C18.873 13.506 14.491 18.716 12.725 20.676C12.6338 20.778 12.522 20.8595 12.3971 20.9154C12.2722 20.9712 12.1369 21.0001 12 21.0001C11.8632 21.0001 11.7279 20.9712 11.6029 20.9154C11.478 20.8595 11.3663 20.778 11.275 20.676C9.50901 18.716 5.12701 13.506 5.12701 10.045V10.045Z"
                stroke="currentColor"
                strokeWidth="1.447"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 9.955C10 10.4854 10.2107 10.9941 10.5858 11.3692C10.9609 11.7443 11.4696 11.955 12 11.955C12.5304 11.955 13.0391 11.7443 13.4142 11.3692C13.7893 10.9941 14 10.4854 14 9.955V9.918C14 9.38757 13.7893 8.87886 13.4142 8.50379C13.0391 8.12871 12.5304 7.918 12 7.918C11.4696 7.918 10.9609 8.12871 10.5858 8.50379C10.2107 8.87886 10 9.38757 10 9.918"
                stroke="currentColor"
                strokeWidth="1.447"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
