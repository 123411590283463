import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import {SUCCESS, showError} from 'lib/store/actions/notifications';
import getLastRootLocation from 'lib/helpers/getLastRootLocation';
import actions from 'store/actions';
import {sentMessages, planning} from 'routes/index';
import useRoles from 'components/hooks/useRoles';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import {getErrorNotification} from 'util/errorHelper';
import {SUPER_USER} from 'util/getUserRoleHelper';
import {STATUS_SCHEDULED} from 'lib/store/actions/messages/index';
import MessageConfigurationSidebarButtons from './MessageConfigurationSidebarButtons';

const MessageConfigurationSidebarButtonsWithState = ({canEdit, messageId}) => {
    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isSendingToAdmin, setIsSendingToAdmin] = useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const {saveContent, createTemplate, publishContent} = useContentSaver();
    const {userRole} = useRoles();
    const isSuperUser = userRole === SUPER_USER;
    const {translate} = useTranslate();
    const {editor, editorTitle, isAlreadyPublished} = useEditorState();

    const {status, contentId, scheduledAt, contentType} = editor;
    const {lastRootLocation} = history;
    const saveDisabled = !editorTitle || !editorTitle.length;
    const publishDisabled = saveDisabled; // @TODO: check if valid audience || !isValidAudience(audience)
    const deleteDisabled = !messageId;
    const sendToAdminDisabled = saveDisabled || !status;

    const dispatch = useDispatch();
    const deleteMessage = () =>
        dispatch(actions.messages.deleteMessage(contentId));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));
    const sendMessageToAdmin = message =>
        dispatch(actions.messages.sendMessageToAdmin(message));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const showNotification = (notificationKey, type) => {
        dispatch(
            actions.notifications.addNotification({
                type,
                content: {
                    key: notificationKey,
                },
            }),
        );
    };

    const closeEditor = () => {
        if (lastRootLocation) {
            history.push(lastRootLocation);
        } else {
            history.push(getLastRootLocation(contentType, status, isSuperUser));
        }
    };
    const onSaveContent = async () => {
        setIsSaving(true);
        try {
            await saveContent(editor);
            showNotification('notifications.message_updated', SUCCESS);
        } catch (error) {
            dispatch(showError(getErrorNotification(error)));
        }
        setIsSaving(false);
    };
    const onDeleteMessage = () => {
        setIsDeleting(true);
        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('editor.confirm_archive_message'),
            buttonActions: [
                {
                    label: translate('global.archive'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteMessage().then(() => {
                            setIsDeleting(false);
                            closeEditor();
                        });
                    },
                    classes: 'danger',
                },
            ],
        });
    };
    const onSendToAdmin = async () => {
        setIsSendingToAdmin(true);
        try {
            await saveContent(editor);
            await sendMessageToAdmin({id: contentId});
            history.push(sentMessages());
        } catch (error) {
            dispatch(showError(getErrorNotification(error)));
        }
        setIsSendingToAdmin(false);
    };
    const onCreateTemplate = async () => {
        setIsSavingTemplate(true);
        try {
            await createTemplate(editor);
        } catch (error) {
            dispatch(showError(getErrorNotification(error)));
        }
        setIsSavingTemplate(false);
    };
    const onPublishMessage = async () => {
        setIsPublishing(true);
        try {
            await publishContent(editor);
            setTimeout(() => {
                switch (editor.status) {
                    case STATUS_SCHEDULED:
                        history.push(planning());
                        break;
                    default:
                        history.push(sentMessages());
                        break;
                }
            }, 300);
        } catch (error) {
            dispatch(showError(getErrorNotification(error)));
        }
        setIsPublishing(false);
    };

    if (!canEdit) {
        return null;
    }
    return (
        <MessageConfigurationSidebarButtons
            isSaving={isSaving}
            translate={translate}
            isDeleting={isDeleting}
            isSuperUser={isSuperUser}
            scheduledAt={scheduledAt}
            isPublishing={isPublishing}
            saveDisabled={saveDisabled}
            onSaveContent={onSaveContent}
            onSendToAdmin={onSendToAdmin}
            deleteDisabled={deleteDisabled}
            publishDisabled={publishDisabled}
            onDeleteMessage={onDeleteMessage}
            isSendingToAdmin={isSendingToAdmin}
            onPublishMessage={onPublishMessage}
            isSavingTemplate={isSavingTemplate}
            onCreateTemplate={onCreateTemplate}
            sendToAdminDisabled={sendToAdminDisabled}
            isAlreadyPublished={isAlreadyPublished}
        />
    );
};

export default MessageConfigurationSidebarButtonsWithState;
