import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {toPairs} from 'lodash';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Input from 'components/util/Input';

const RepeatWeekly = ({
    weekly: {interval, days, options},
    handleChange,
    translate,
}) => {
    let daysArray = toPairs(days);
    if (options.weekStartsOnSunday) {
        daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
    }

    return (
        <Fragment>
            <div className="form-group react-rule__flex">
                <div className="react-rule__col">
                    {translate('editor.recurrence.every')}
                </div>
                <div className="react-rule__col">
                    <Input
                        name="repeat.weekly.interval"
                        aria-label="Repeat weekly interval"
                        className="form-control"
                        value={interval}
                        type="number"
                        min="1"
                        onNativeChange={handleChange}
                    />
                </div>
                <div className="react-rule__col">
                    {translate('editor.recurrence.weeks')}
                </div>
            </div>

            <div className="form-group">
                <div className="btn-group" data-toggle="buttons">
                    {daysArray.map(([dayName, isDayActive]) => (
                        <label
                            htmlFor={`Repeat weekly on ${dayName}`}
                            key={dayName}
                            className={`btn btn-primary ${isDayActive && 'active'}`}>
                            <Input
                                type="checkbox"
                                id={`Repeat weekly on ${dayName}`}
                                name={`repeat.weekly.days[${dayName}]`}
                                className="form-control"
                                checked={isDayActive}
                                onNativeChange={event => {
                                    const editedEvent = {
                                        ...event,
                                        target: {
                                            ...event.target,
                                            value: !isDayActive,
                                            name: event.target.name,
                                        },
                                    };

                                    handleChange(editedEvent);
                                }}
                            />
                            {translate(`editor.recurrence.${dayName}`)}
                        </label>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

RepeatWeekly.propTypes = {
    weekly: PropTypes.shape({
        interval: PropTypes.number.isRequired,
        days: PropTypes.shape({
            mon: PropTypes.bool.isRequired,
            tue: PropTypes.bool.isRequired,
            wed: PropTypes.bool.isRequired,
            thu: PropTypes.bool.isRequired,
            fri: PropTypes.bool.isRequired,
            sat: PropTypes.bool.isRequired,
            sun: PropTypes.bool.isRequired,
        }).isRequired,
        options: PropTypes.shape({
            weekStartsOnSunday: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatWeekly);
