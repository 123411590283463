import React from 'react';
import Loading from 'lib/components/Loading';
import Triggerconfig from './TriggerConfig';
import TriggerOptions from './TriggerOptions';
import TriggerReceiver from './TriggerReceiver';

const Triggers = ({
    canEdit,
    isFetching,
    onChange,
    activeTrigger,
    triggerValue,
    options,
}) => {
    if (isFetching) {
        return <Loading size={30} />;
    }

    return (
        <>
            <TriggerOptions
                canEdit={canEdit}
                value={triggerValue.name}
                options={options}
                onChange={option =>
                    onChange({
                        name: option.value,
                    })
                }
            />
            <TriggerReceiver trigger={activeTrigger} />
            <Triggerconfig
                canEdit={canEdit}
                trigger={triggerValue}
                onChange={onChange}
            />
        </>
    );
};

export default Triggers;
