import {useSelector, useDispatch} from 'react-redux';
import {showFailureByKey} from 'lib/store/actions/notifications';

const useFileUpload = callback => {
    const dispatch = useDispatch();
    const maxFileUpload = useSelector(
        ({company}) => company.data.max_file_upload,
    );

    return files => {
        const file = files.length > 0 ? files[0] : null;
        if (file === null) {
            dispatch(
                showFailureByKey('notifications.general_error', {error: ''}),
            );
        } else if (file.size >= maxFileUpload) {
            // Block when the file size is too big
            dispatch(showFailureByKey('notifications.file_too_big'));
        } else {
            callback(files);
        }
    };
};

export default useFileUpload;
