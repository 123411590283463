import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import Table from 'components/Table/Table';
import SearchInput from 'lib/components/SearchInput';
import UserModalWithState from 'modals/user/UserModalWithState';
import ContentView from 'components/util/ContentView';
import Button from 'components/util/Button';

const Users = ({
    filters,
    isFetching,
    onBulkEditClick,
    onNewUserButtonClick,
    onSearchQueryChange,
    rows,
    columns,
    selectedUserRole,
    selectedUser,
    onSortChange,
    onTableEnd,
    onRowClick,
    onUserEdit,
    onModalClose,
    total,
}) => (
    <ContentView
        title={
            <>
                <Translate id="global.users" />
            </>
        }
        total={total}
        headerComponents={
            <>
                <Translate>
                    {({translate}) => (
                        <SearchInput
                            placeholder={translate('users.search')}
                            value={filters.query || ''}
                            onChange={onSearchQueryChange}
                        />
                    )}
                </Translate>
                <Button onClick={onBulkEditClick}>
                    <Translate id="users.bulkEdit" />
                </Button>
                <Button onClick={onNewUserButtonClick}>
                    <Translate id="global.add" />
                </Button>
            </>
        }>
        <Table
            rows={rows}
            columns={columns}
            sort={filters.sort || {}}
            onSortChange={onSortChange}
            onTableEnd={onTableEnd}
            onRowClick={onRowClick}
            loading={isFetching}
            emptyTitle={<Translate id="users.no_users" />}
        />
        <UserModalWithState
            key={selectedUserRole}
            selectedUser={selectedUser}
            onSuccess={onUserEdit}
            onClose={onModalClose}
        />
    </ContentView>
);
Users.propTypes = {
    filters: PropTypes.shape({
        query: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    onBulkEditClick: PropTypes.func,
    onNewUserButtonClick: PropTypes.func,
    onSearchQueryChange: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.any),
    columns: PropTypes.arrayOf(PropTypes.any),
    selectedUserRole: PropTypes.string,
    selectedUser: PropTypes.shape({}),
    onSortChange: PropTypes.func,
    onTableEnd: PropTypes.func,
    onRowClick: PropTypes.func,
    onUserEdit: PropTypes.func,
    onModalClose: PropTypes.func,
};

Users.defaultProps = {
    filters: {},
    isFetching: false,
    onBulkEditClick: () => {},
    onNewUserButtonClick: () => {},
    onSearchQueryChange: () => {},
    rows: [],
    columns: [],
    selectedUserRole: '',
    selectedUser: {},
    onSortChange: () => {},
    onTableEnd: () => {},
    onRowClick: () => {},
    onUserEdit: () => {},
    onModalClose: () => {},
};

export default Users;
