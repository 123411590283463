import {combineReducers} from 'redux';
import {
    GET_SEGMENT_GROUPS_REQUEST,
    GET_SEGMENT_GROUPS_SUCCESS,
    GET_SEGMENT_GROUPS_FAILURE,
} from '../actions/segmentation';

export default () => {
    const data = (state = null, action) => {
        switch (action.type) {
            case GET_SEGMENT_GROUPS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    };

    const isFetchingSegments = (state = false, action) => {
        switch (action.type) {
            case GET_SEGMENT_GROUPS_REQUEST:
                return true;
            case GET_SEGMENT_GROUPS_SUCCESS:
            case GET_SEGMENT_GROUPS_FAILURE:
                return false;
            default:
                return state;
        }
    };

    return combineReducers({
        segments: combineReducers({
            data,
            isFetching: isFetchingSegments,
        }),
    });
};
