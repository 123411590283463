import React from 'react';
import Select from 'components/util/Select';
import OptionWithIcon from 'components/util/OptionWithIcon';

const EditorSidebarAppDomainSelect = ({
    value,
    options,
    canEdit,
    onChange,
    translate,
    selectedOption,
}) => (
    <div className="form__group">
        <label className="label" htmlFor="appDomain">
            {translate('editor.form.scope.label')}
        </label>
        {canEdit ? (
            <Select
                disabled={!canEdit}
                inputId="appDomain"
                clearable={false}
                value={value}
                placeholder={translate('editor.form.scope.placeholder')}
                options={options}
                components={{
                    Option: OptionWithIcon,
                }}
                onChange={onChange}
            />
        ) : (
            <OptionWithIcon data={selectedOption} innerProps={{}}>
                {selectedOption && selectedOption.label}
            </OptionWithIcon>
        )}
    </div>
);

export default EditorSidebarAppDomainSelect;
