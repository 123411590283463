import React from 'react';

const FacebookMessenger = () => (
    <path
        fill="currentColor"
        transform="translate(2.000000, 0.000000)"
        d="M10.2909044,0 C0.633310406,0 -3.70123684,11.6347635 3.82156946,17.433227 L3.82156946,21.1885124 L7.33468752,19.2020894 C13.9339661,21.0844617 20.5796531,16.3690718 20.5796531,9.80441658 C20.5842939,4.38904901 15.975937,0 10.2909044,0 Z M11.3768617,13.1529583 L8.71301786,10.3341294 L3.58952732,13.223902 L9.20958804,7.14639337 L11.8734319,9.96522226 L16.9969224,7.07544969 L11.3768617,13.1529583 Z"
    />
);
export default FacebookMessenger;
