const LOCAL_STORAGE_KEY = 'state';

export const loadState = () => {
    try {
        const state = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (state === null) {
            return undefined;
        }
        return JSON.parse(state);
    } catch (e) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    } catch (e) {
        // Just ignore the fact we can't save the item.
    }
};

export const removeState = () => {
    try {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (e) {
        // Just ignore the fact we can't remove the item
    }
};
