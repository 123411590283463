import {DEFAULT_LANGUAGE} from '../lib/helpers/locale';

const getAppDomainNameForLanguage = (appDomain, langCode) => {
    if (appDomain.name === null) {
        return '';
    }

    if (typeof appDomain.name === 'string') {
        return appDomain.name;
    }

    // First try to get the name from the original app domain in the requested language.
    if (langCode in appDomain.name) {
        return appDomain.name[langCode];
    }

    if (DEFAULT_LANGUAGE in appDomain.name) {
        return appDomain.name[DEFAULT_LANGUAGE];
    }

    return '';
};

export default getAppDomainNameForLanguage;
