import {CALL_API} from '../../constants/api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const login = (username, password) => ({
    [CALL_API]: {
        endpoint: '/clients/admin/token',
        method: 'POST',
        body: {
            username,
            password,
            grant_type: 'password',
        },
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
        notifications: {
            failure: false,
        },
    },
});

export const OTP_TOKEN_REQUEST = 'OTP_TOKEN_REQUEST';
export const OTP_TOKEN_SUCCESS = 'OTP_TOKEN_SUCCESS';
export const OTP_TOKEN_FAILURE = 'OTP_TOKEN_FAILURE';

export const getOtpToken = (body, client = 'web') => ({
    [CALL_API]: {
        endpoint: `/clients/${client}/otp`,
        method: 'POST',
        body: {...body, grant_type: 'otp'},
        types: [OTP_TOKEN_REQUEST, OTP_TOKEN_SUCCESS, OTP_TOKEN_FAILURE],
    },
});

export const OTP_LOGIN_REQUEST = 'OTP_LOGIN_REQUEST';
export const OTP_LOGIN_SUCCESS = 'OTP_LOGIN_SUCCESS';
export const OTP_LOGIN_FAILURE = 'OTP_LOGIN_FAILURE';

export const otpLogin = (body, client = 'web') => ({
    [CALL_API]: {
        endpoint: `/clients/${client}/token`,
        method: 'POST',
        body: {...body, grant_type: 'otp'},
        types: [OTP_LOGIN_REQUEST, OTP_LOGIN_SUCCESS, OTP_LOGIN_FAILURE],
        notifications: {
            failure: false,
        },
    },
});

export const AZURE_AD_LOGIN_REQUEST = 'AZURE_AD_LOGIN_REQUEST';
export const AZURE_AD_LOGIN_SUCCESS = 'AZURE_AD_LOGIN_SUCCESS';
export const AZURE_AD_LOGIN_FAILURE = 'AZURE_AD_LOGIN_FAILURE';

export const loginAzureAD = (body, client = 'admin') => ({
    [CALL_API]: {
        endpoint: `/clients/${client}/azure-token`,
        method: 'POST',
        body: {...body},
        types: [
            AZURE_AD_LOGIN_REQUEST,
            AZURE_AD_LOGIN_SUCCESS,
            AZURE_AD_LOGIN_FAILURE,
        ],
        notifications: {
            failure: false,
        },
    },
});

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';

export const refreshAccessToken = refreshToken => ({
    [CALL_API]: {
        endpoint: '/clients/admin/token',
        method: 'POST',
        body: {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        },
        types: [
            TOKEN_REFRESH_REQUEST,
            TOKEN_REFRESH_SUCCESS,
            TOKEN_REFRESH_FAILURE,
        ],
    },
});

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const logout = () => ({
    [CALL_API]: {
        endpoint: '/clients/admin/logout',
        method: 'POST',
        types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    },
});

export const logoutWithoutAPICall = () => ({
    type: LOGOUT_REQUEST,
    payload: {},
});

export const REMEMBER = 'REMEMBER';

export const setRemember = value => ({
    type: REMEMBER,
    payload: value,
});

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const setAccessToken = token => ({
    type: SET_ACCESS_TOKEN,
    payload: {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        expiresIn: token.expires_in,
    },
});

export const SEND_PASSWORD_LINK_REQUEST = 'SEND_PASSWORD_LINK_REQUEST';
export const SEND_PASSWORD_LINK_SUCCESS = 'SEND_PASSWORD_LINK_SUCCESS';
export const SEND_PASSWORD_LINK_FAILURE = 'SEND_PASSWORD_LINK_FAILURE';

export const sendPasswordResetLink = (email, otherPerson) => ({
    [CALL_API]: {
        endpoint: '/password/email',
        method: 'POST',
        body: {
            email,
        },
        types: [
            SEND_PASSWORD_LINK_REQUEST,
            SEND_PASSWORD_LINK_SUCCESS,
            SEND_PASSWORD_LINK_FAILURE,
        ],
        notifications: {
            success: {
                key: otherPerson
                    ? 'notifications.mail_sent_to'
                    : 'notifications.mail_sent',
                params: {email},
            },
        },
    },
});

export const sendUserLink = email => ({
    [CALL_API]: {
        endpoint: '/re-invite-user',
        method: 'POST',
        body: {
            email,
        },
        types: [
            SEND_PASSWORD_LINK_REQUEST,
            SEND_PASSWORD_LINK_SUCCESS,
            SEND_PASSWORD_LINK_FAILURE,
        ],
        notifications: {
            success: {
                key: 'notifications.mail_link_sent_to',
                params: {email},
            },
        },
    },
});

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPassword = body => ({
    [CALL_API]: {
        endpoint: `/password/reset/${body.token}`,
        method: 'POST',
        body,
        types: [
            RESET_PASSWORD_REQUEST,
            RESET_PASSWORD_SUCCESS,
            RESET_PASSWORD_FAILURE,
        ],
        notifications: {
            success: {
                key: 'notifications.password_reset',
            },
        },
    },
});

export const IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST';
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS';
export const IMPERSONATE_FAILURE = 'IMPERSONATE_FAILURE';

export const impersonateUser = userId => ({
    [CALL_API]: {
        endpoint: `/impersonate/${userId}`,
        method: 'POST',
        types: [IMPERSONATE_REQUEST, IMPERSONATE_SUCCESS, IMPERSONATE_FAILURE],
        notifications: {
            success: {
                key: 'notifications.user_impersonated',
            },
        },
    },
});

export default {
    login,
    logout,
    setAccessToken,
    setRemember,
    sendPasswordResetLink,
    sendUserLink,
    resetPassword,
    getOtpToken,
    otpLogin,
    logoutWithoutAPICall,
    loginAzureAD,
    impersonateUser,
};
