import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/util/Button';

const MessageConfigurationSidebarButtons = ({
    isSaving,
    translate,
    isDeleting,
    scheduledAt,
    isSuperUser,
    isPublishing,
    saveDisabled,
    onSaveContent,
    onSendToAdmin,
    deleteDisabled,
    onDeleteMessage,
    publishDisabled,
    isSavingTemplate,
    isSendingToAdmin,
    onPublishMessage,
    onCreateTemplate,
    isAlreadyPublished,
    sendToAdminDisabled,
}) => (
    <div>
        {isAlreadyPublished ? (
            <>
                <Button
                    disabled={publishDisabled}
                    isLoading={isPublishing}
                    tabIndex={0}
                    onClick={onPublishMessage}>
                    {translate('editor.update_published')}
                </Button>
                {/* <Button
                    disabled={saveDisabled}
                    isLoading={isSaving}
                    tabIndex={0}
                    onClick={onSaveContent}
                    ghost
                >
                    {translate('editor.save')}
                </Button> */}
            </>
        ) : (
            <>
                {isSuperUser ? (
                    <Button
                        disabled={sendToAdminDisabled}
                        isLoading={isSendingToAdmin}
                        tabIndex={0}
                        onClick={onSendToAdmin}>
                        {translate('editor.send_to_admin')}
                    </Button>
                ) : (
                    <Button
                        disabled={publishDisabled}
                        isLoading={isPublishing}
                        tabIndex={0}
                        onClick={onPublishMessage}>
                        {scheduledAt
                            ? translate('editor.publish')
                            : translate('editor.send')}
                    </Button>
                )}
                <Button
                    disabled={saveDisabled}
                    isLoading={isSaving}
                    tabIndex={0}
                    onClick={onSaveContent}
                    ghost>
                    {translate('editor.save')}
                </Button>
            </>
        )}
        <Button
            disabled={deleteDisabled}
            isLoading={isDeleting}
            tabIndex={0}
            onClick={onDeleteMessage}
            transparent>
            {translate('global.archive')}
        </Button>
        {!isSuperUser && (
            <Button
                disabled={saveDisabled}
                isLoading={isSavingTemplate}
                tabIndex={0}
                onClick={onCreateTemplate}
                transparent>
                {translate('editor.create_template')}
            </Button>
        )}
    </div>
);

MessageConfigurationSidebarButtons.propTypes = {
    isSaving: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool,
    scheduledAt: PropTypes.string,
    isSuperUser: PropTypes.bool,
    isPublishing: PropTypes.bool,
    saveDisabled: PropTypes.bool,
    onSaveContent: PropTypes.func.isRequired,
    onSendToAdmin: PropTypes.func.isRequired,
    deleteDisabled: PropTypes.bool,
    onDeleteMessage: PropTypes.func.isRequired,
    publishDisabled: PropTypes.bool,
    isSavingTemplate: PropTypes.bool,
    isSendingToAdmin: PropTypes.bool,
    onPublishMessage: PropTypes.func.isRequired,
    onCreateTemplate: PropTypes.func.isRequired,
    isAlreadyPublished: PropTypes.bool,
    sendToAdminDisabled: PropTypes.bool,
};
MessageConfigurationSidebarButtons.defaultProps = {
    isSaving: false,
    isDeleting: false,
    scheduledAt: '',
    isSuperUser: false,
    isPublishing: false,
    saveDisabled: false,
    deleteDisabled: false,
    publishDisabled: false,
    isSavingTemplate: false,
    isSendingToAdmin: false,
    isAlreadyPublished: false,
    sendToAdminDisabled: false,
};

export default MessageConfigurationSidebarButtons;
