import React from 'react';
import PropTypes from 'prop-types';

import UserDetailBlock from 'components/util/UserDetail/UserDetailBlock';
import PhoneMail from 'components/util/UserDetail/PhoneMail';
import Name from 'components/util/UserDetail/Name';
import AvatarEditor from 'lib/components/AvatarEditor';
import {
    getSegmentsFromGroupBySlug,
    DEPARTMENTS,
    FUNCTIONS,
} from 'lib/helpers/segmentation';

const MeModal = ({
    userFromState,
    userFromProps,
    editable,
    savingAvatar,
    languageOptions,
    company,
    onEditAvatar,
    onSetAvatar,
    setNewUserData,
}) => (
    <div className="user-detail-modal">
        {userFromState && userFromState.id && (
            <div className="row justify-space-between">
                <div className="col-lg-4">
                    {userFromProps && (
                        <AvatarEditor
                            editable={editable}
                            onEditChange={onEditAvatar}
                            onEditComplete={onSetAvatar}
                            image={
                                userFromProps.avatar &&
                                userFromProps.avatar.sizes
                            }
                            saving={savingAvatar}
                        />
                    )}
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        {editable &&
                            company.languages &&
                            company.languages.length && (
                                <div className="col-md-4">
                                    <UserDetailBlock
                                        label="language"
                                        value={userFromState.language || 'nl'}
                                        type="select"
                                        options={languageOptions}
                                        editable
                                        onChange={option =>
                                            setNewUserData({
                                                language: option.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                    </div>

                    <Name
                        user={userFromState}
                        onChange={obj => setNewUserData(obj)}
                        editable={editable}
                    />

                    <div className="row">
                        <div className="col-md-12">
                            <UserDetailBlock
                                label="description"
                                value={userFromState.description || ''}
                                editable={editable}
                                type="textarea"
                                onChange={value =>
                                    setNewUserData({description: value})
                                }
                            />
                        </div>
                    </div>

                    <PhoneMail
                        user={userFromState}
                        onChange={obj => setNewUserData(obj)}
                        editable={editable}
                    />

                    <div className="row">
                        <div className="col-md-6">
                            <UserDetailBlock
                                label="functions"
                                value={
                                    getSegmentsFromGroupBySlug(
                                        userFromState.segment_groups,
                                        FUNCTIONS,
                                    ).join(', ') || ''
                                }
                            />
                        </div>
                        <div className="col-md-6">
                            <UserDetailBlock
                                label="departments"
                                value={
                                    getSegmentsFromGroupBySlug(
                                        userFromState.segment_groups,
                                        DEPARTMENTS,
                                    ).join(', ') || ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
);
MeModal.propTypes = {
    userFromState: PropTypes.shape({
        id: PropTypes.string,
        segment_groups: PropTypes.array,
        description: PropTypes.string,
        language: PropTypes.string,
    }),
    userFromProps: PropTypes.shape({
        avatar: PropTypes.shape({
            sizes: PropTypes.string,
        }),
    }),
    editable: PropTypes.bool,
    savingAvatar: PropTypes.bool.isRequired,
    languageOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    company: PropTypes.shape({languages: PropTypes.array}).isRequired,
    onEditAvatar: PropTypes.func.isRequired,
    onSetAvatar: PropTypes.func.isRequired,
    setNewUserData: PropTypes.func.isRequired,
};
MeModal.defaultProps = {
    userFromProps: {},
    userFromState: {},
    editable: false,
};
export default MeModal;
