import React, {useEffect, useState, useRef} from 'react';
import isPast from 'date-fns/is_past';
import isToday from 'date-fns/is_today';
import useTranslate from 'components/hooks/useTranslate';
import Day from './Day';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const DayWithState = ({
    day,
    month,
    dayOfTheWeek,
    langCode,
    startNewMessage,
}) => {
    const [collapsed, setCollapsed] = useState(false);
    const messageRef = useRef();
    const containerRef = useRef();
    const canCollapse = useRef(false);
    const {defaultLanguage} = useTranslate();
    const canAdd = !isPast(day.date) || isToday(day.date);
    const addLength = canAdd ? 1 : 0;
    const messageLength = day.messages.length + addLength;

    const calculateCollapse = () => {
        // Only calculate when there are messages present
        if (!collapsed && day.messages && day.messages.length > 1) {
            // Calculate height of 1 message
            let messageHeight = 0;
            if (messageRef.current) {
                const messageStyle = window.getComputedStyle(
                    messageRef.current,
                );
                messageHeight =
                    messageRef.current.clientHeight +
                    parseInt(messageStyle.getPropertyValue('margin-top'), 10) +
                    parseInt(
                        messageStyle.getPropertyValue('margin-bottom'),
                        10,
                    );
            }

            if (
                messageHeight * messageLength >
                containerRef.current.clientHeight
            ) {
                setCollapsed(true);
                canCollapse.current = true;
            } else {
                canCollapse.current = false;
            }
        }
    };

    useEffect(() => {
        calculateCollapse();
        window.addEventListener('resize', calculateCollapse);

        return () => {
            window.removeEventListener('resize', calculateCollapse);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day]);

    useOnClickOutside(containerRef, ({target, path}) => {
        const isNameClickButton =
            (typeof path !== 'undefined' &&
                path !== null &&
                typeof path.find(({id}) => id === 'nameClickButton') !==
                    'undefined') ||
            (typeof target.parentNode !== 'undefined' &&
                target.parentNode !== null &&
                target.parentNode.id === 'nameClickButton');
        if (!collapsed && canCollapse.current && !isNameClickButton) {
            setCollapsed(true);
        }
    });

    const onShowMoreClick = () => {
        setCollapsed(false);
        canCollapse.current = true;
    };

    if (typeof day === 'undefined') {
        return null;
    }

    return (
        <Day
            day={day}
            month={month}
            dayOfTheWeek={dayOfTheWeek}
            langCode={langCode}
            startNewMessage={startNewMessage}
            collapsed={collapsed}
            onShowMoreClick={onShowMoreClick}
            containerRef={containerRef}
            messageRef={messageRef}
            canAdd={canAdd}
            defaultLanguage={defaultLanguage}
        />
    );
};

export default DayWithState;
