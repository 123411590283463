import {CALL_API} from '../../../constants/api';

export const FETCH_MOOD_ANALYTICS_REQUEST = 'FETCH_MOOD_ANALYTICS_REQUEST';
export const FETCH_MOOD_ANALYTICS_SUCCESS = 'FETCH_MOOD_ANALYTICS_SUCCESS';
export const FETCH_MOOD_ANALYTICS_FAILURE = 'FETCH_MOOD_ANALYTICS_FAILURE';

export const fetchMood = () => ({
    [CALL_API]: {
        endpoint: '/analytics/mood?with_answer_stats=true',
        method: 'GET',
        version: 'v2',
        types: [
            FETCH_MOOD_ANALYTICS_REQUEST,
            FETCH_MOOD_ANALYTICS_SUCCESS,
            FETCH_MOOD_ANALYTICS_FAILURE,
        ],
    },
});

export const FETCH_MOOD_ANALYTICS_EXPORT_REQUEST =
    'FETCH_MOOD_ANALYTICS_EXPORT_REQUEST';
export const FETCH_MOOD_ANALYTICS_EXPORT_SUCCESS =
    'FETCH_MOOD_ANALYTICS_EXPORT_SUCCESS';
export const FETCH_MOOD_ANALYTICS_EXPORT_FAILURE =
    'FETCH_MOOD_ANALYTICS_EXPORT_FAILURE';

export const fetchMoodExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/mood/export',
        method: 'GET',
        version: 'v1',
        types: [
            FETCH_MOOD_ANALYTICS_EXPORT_REQUEST,
            FETCH_MOOD_ANALYTICS_EXPORT_SUCCESS,
            FETCH_MOOD_ANALYTICS_EXPORT_FAILURE,
        ],
    },
});

export default {
    fetchMood,
    fetchMoodExport,
};
