import css from 'styled-jsx/css';
import * as theme from 'styles/theme';
import selectSVG from 'lib/images/select.svg';

export default css`
    .react-rule select.form-control {
        appearance: none;
        padding: 0;
        background-image: url('${selectSVG}');
        background-size: 1.4rem;
        background-repeat: no-repeat;
        background-position: right 1rem top 50%;
    }

    .react-rule .form-group select.form-control {
        padding-right: 3rem;
    }

    .react-rule select.form-control,
    .Select:not(.Select--multi) .Select-control,
    .Select:not(.Select--multi) .Select-input {
        height: 3.6rem;
        cursor: pointer;
    }

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
        line-height: 3.6rem;
    }

    .Select:not(.Select--multi) .Select-input {
        width: 100%;
    }

    .react-rule select.form-control,
    .Select-placeholder,
    .Select--single > .Select-control .Select-value,
    .form-control {
        padding-left: ${theme.spacingSmall}rem;
    }

    .react-rule select.form-control,
    .form-control {
        padding-right: ${theme.spacingSmall}rem;
    }
`;
