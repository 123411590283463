import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {DESC} from 'lib/store/services/index';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import actions from 'store/actions';
import Comments from './Comments';
import useFetchMessageCommentsData from './useFetchMessageCommentsData';

const INITIAL_SORT = {created_at: DESC};

const CommentsWithState = ({messageId}) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState(INITIAL_SORT);
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setModalData = data =>
        dispatch(actions.modals.setConfirmModalData(data));

    const {isFetching, messageComments, handleFetchMore, handleFetch} =
        useFetchMessageCommentsData({
            messageId,
            filters: {
                sort,
            },
        });

    const deleteUserMessageComment = commentId =>
        dispatch(
            actions.messageComments.deleteMessageComment(messageId, commentId),
        );

    const handleSort = newSort => {
        if (Object.keys(newSort).length > 0) {
            setSort(newSort);
        } else {
            setSort(INITIAL_SORT);
        }
    };

    const onDelete = commentId => {
        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: (
                <Translate id="Are you sure you want to delete this comment?" />
            ),
            buttonActions: [
                {
                    label: <Translate id="global.delete" />,
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteUserMessageComment(commentId);
                    },
                    classes: 'danger',
                },
            ],
        });
    };

    useEffect(() => {
        handleFetch({page: 1, filters: {sort}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    return (
        <Comments
            isFetchingComments={isFetching}
            messageComments={messageComments}
            onTableEnd={handleFetchMore}
            sort={sort}
            onSortChange={handleSort}
            onDelete={onDelete}
        />
    );
};

export default CommentsWithState;
