import React from 'react';
import * as theme from 'styles/theme';
import styled from 'styled-components';

const LandingContent = ({image, title, description}) => (
    <Container>
        {image && <Image>{image}</Image>}
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
    </Container>
);

const Container = styled.div`
    text-align: center;
`;

const Image = styled.div`
    margin-bottom: ${theme.spacingLargest}rem;

    img {
        width: 100%;
        max-height: 10rem;
    }
`;

const Title = styled.h1`
    margin-bottom: ${theme.spacing}rem;
    font-weight: ${theme.fwBold};
`;

const Description = styled.p``;

export default LandingContent;
