import './MediaThumb.css';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {getFileType} from 'lib/helpers/fileTypeHelper';
import actions from 'store/actions';
import {MODAL_CONFIRM} from 'lib/store/actions/modals';
import Icon from 'lib/components/Icon';
import CloseButton from 'lib/components/CloseButton';

const MAX_ATTEMPTS = 5;

class MediaThumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attempts: 0,
            imageUrl: this.props.url,
        };
    }

    render() {
        const {type, title, callBack, removable, url} = this.props;
        const {imageUrl = url} = this.state;
        let footer;
        let icon = getFileType(type);
        icon = !icon ? 'file' : `file-${icon}`;

        if (title) {
            footer = (
                <div className="media-thumb__footer">
                    <span className="media-thumb__type">
                        <Icon name={icon} size={16} />
                    </span>
                    {title ? (
                        <span className="media-thumb__title">{title}</span>
                    ) : (
                        ''
                    )}
                </div>
            );
        }

        return (
            <div className="media-thumb actions">
                {removable && (
                    <div
                        className="actions__content  media-thumb__delete"
                        style={{cursor: 'pointer'}}
                        onClick={e => {
                            callBack();
                            e.stopPropagation();
                        }}>
                        <CloseButton onClick={() => this._deleteMedia()} />
                    </div>
                )}
                <div
                    className="media-thumb__image-wrap"
                    onClick={() => callBack()}>
                    <div
                        style={{backgroundImage: `url(${imageUrl})`}}
                        className="media-thumb__image-wrap__image"
                    />
                    <span className="media-thumb__image-wrap__type">
                        <Icon name={icon} />
                    </span>
                </div>
                {footer}
                <img
                    className="hidden"
                    src={imageUrl}
                    alt="loader"
                    onError={() => this._onError()}
                />
            </div>
        );
    }

    _deleteMedia() {
        const {id, deleteMedia, setModalVisibility, setModalData, translate} =
            this.props;

        setModalVisibility(MODAL_CONFIRM, true);
        setModalData({
            title: translate('editor.confirm_delete_media'),
            buttonActions: [
                {
                    label: translate('global.delete'),
                    callBack: () => {
                        setModalVisibility(MODAL_CONFIRM, false);
                        deleteMedia(id);
                    },
                    classes: 'danger',
                },
            ],
        });
    }

    _onError() {
        const {imageUrl, attempts} = this.state;

        if (attempts < MAX_ATTEMPTS) {
            setTimeout(
                () => {
                    this.setState({
                        attempts: attempts + 1,
                        imageUrl: `${imageUrl}#new_attempt`,
                    });
                },
                500 + attempts * 500,
            );
        } else {
            this.setState({fetchFailed: true});
        }
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
    deleteMedia: id => dispatch(actions.media.internal.deleteMedia(id)),
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setModalData: data => dispatch(actions.modals.setConfirmModalData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaThumb);
