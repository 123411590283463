import {getById} from '../helpers/byId';
import {
    MESSAGE_SURVEYS_LIST_SUCCESS,
    SURVEYS_CREATE_SUCCESS,
    SURVEYS_DELETE_SUCCESS,
} from '../../actions/surveys';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MESSAGE_SURVEYS_LIST_SUCCESS:
            return {...state, [action.listType]: action.payload.ids};
        case SURVEYS_CREATE_SUCCESS: {
            const originalIds = state[action.listType] || [];
            return {
                ...state.byId,
                [action.listType]: [...originalIds, action.payload.ids],
            };
        }
        case SURVEYS_DELETE_SUCCESS: {
            const deletedSurveyId = action.payload.ids;
            const newState = {...state.byId};
            Object.keys(newState).forEach(messageId => {
                newState[messageId] = newState[messageId].filter(
                    surveyId => surveyId !== deletedSurveyId,
                );
            });
            return newState;
        }
        default:
            return state;
    }
};

export const getContentSurveysByContentTypeAndId = (
    contentSurveys,
    surveys,
    contentType,
    contentId,
) => {
    const surveyIds = contentSurveys[`${contentType}_${contentId}`] || [];
    return surveyIds.map(surveyId => getById(surveys, surveyId));
};
