import React, {Fragment} from 'react';
import format from 'date-fns/format';
import {withRouter} from 'react-router-dom';
import {Translate} from 'react-localize-redux';
import Mood from 'lib/components/Mood';
import * as media from 'lib/styles/media';
import downloadHelper from 'lib/helpers/downloadHelper';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import * as theme from 'styles/theme';
import useTranslate from 'components/hooks/useTranslate';
import Widget from '../Widget';

const LastHappinessPoll = ({
    mood,
    average,
    isFetching,
    isFetchingExport,
    fetchMoodExport,
    showNotification,
    history,
    ...props
}) => {
    const moodIsSet = average !== null;
    const {defaultLanguage} = useTranslate();

    return (
        <Widget
            title={<Translate id="dashboard.last_happiness_poll" />}
            subTitle={
                moodIsSet &&
                mood.content &&
                mood.content.question[defaultLanguage]
            }
            type="dark"
            onArrowClick={
                moodIsSet
                    ? () => downloadHelper(fetchMoodExport, showNotification)
                    : null
            }
            fetchingExport={isFetchingExport}
            onClick={() =>
                mood.message_id &&
                history.push(`/editor/messages/${mood.message_id}`)
            }
            {...props}>
            <LoadingWrapper loading={isFetching}>
                <div className="company-mood">
                    {moodIsSet && (
                        <div className="icon">
                            <Mood mood={average} />
                        </div>
                    )}
                    <div className="content">
                        {moodIsSet ? (
                            <Fragment>
                                <span className="text">
                                    <Translate id={`global.mood-${average}`} />
                                </span>
                                {mood.created_at && (
                                    <span className="data">
                                        <Translate id="global.date_sent" />{' '}
                                        {format(mood.created_at, 'DD/MM/YYYY')}
                                    </span>
                                )}
                            </Fragment>
                        ) : (
                            <span className="data">
                                <Translate id="global.no_data_available" />
                            </span>
                        )}
                    </div>
                </div>
            </LoadingWrapper>

            <style jsx>
                {`
                    .company-mood {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2.8rem;
                    }

                    .icon {
                        min-width: 2rem;
                        max-width: 6rem;
                        width: 20%;
                        margin: 0.5rem 2rem 0.5rem 0;

                        :global(svg) {
                            width: 100%;
                        }
                    }

                    .text {
                        text-transform: uppercase;
                        font-weight: ${theme.fwBold};
                        font-size: 1.8rem;
                        margin-bottom: 0.2rem;
                        display: block;
                    }

                    .data {
                        font-size: 1.6rem;
                    }

                    .content {
                        flex: 1;
                    }

                    @media (${media.forLargeAndUp}) {
                        .text {
                            font-size: 2.2rem;
                        }
                    }

                    @media (${media.forExtraLargeAndUp}) {
                        .text {
                            font-size: 2.6rem;
                        }
                    }
                `}
            </style>
        </Widget>
    );
};

export default withRouter(LastHappinessPoll);
