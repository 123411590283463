import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';

import {
    GET_UNSPLASH_REQUEST,
    GET_UNSPLASH_SUCCESS,
    GET_UNSPLASH_FAILURE,
} from '../../actions/media/source';

const unsplash = combineReducers({
    byId: createById({
        listActions: [
            GET_UNSPLASH_REQUEST,
            GET_UNSPLASH_SUCCESS,
            GET_UNSPLASH_FAILURE,
        ],
    }),
    paginated: paginate({
        listActions: [
            GET_UNSPLASH_REQUEST,
            GET_UNSPLASH_SUCCESS,
            GET_UNSPLASH_FAILURE,
        ],
        requestFilterKey: 'filters',
        listType: 'unsplash',
    }),
});

export default combineReducers({
    unsplash,
});
