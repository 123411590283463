export const bpSmall = 576; // Mobile landscape for most smaller screens
export const bpMedium = 768; // Tablet landscape or big phone landscape
export const bpLarge = 992; // Most tablets and small desktop screens
export const bpExtraLarge = 1200; // Desktops

export const forSmallOnly = `max-width: ${bpSmall - 1}px`;
export const forSmallAndUp = `min-width: ${bpSmall}px`;

export const forMediumAndUp = `min-width: ${bpMedium}px`;
export const forBelowMedium = `max-width: ${bpMedium - 1}px`;

export const forLargeAndUp = `min-width: ${bpLarge}px`;
export const forBelowLarge = `max-width: ${bpLarge - 1}px`;

export const forExtraLargeAndUp = `min-width: ${bpExtraLarge}px`;
export const forBelowExtraLarge = `max-width: ${bpExtraLarge - 1}px`;
