/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import EditButton from 'lib/components/EditButton';
import CloseButton from 'lib/components/CloseButton';
import * as media from 'lib/styles/media';
import * as theme from 'lib/styles/theme';
import * as colors from 'lib/styles/colors';
import getUrlBySize, {REGULAR, SMALL, FULL} from 'lib/helpers/sizeHelper';
import useManageEditorBlocks from 'components/hooks/useManageEditorBlocks';
import Icon from 'lib/components/Icon';
import {actions} from '../../../../store';
import CustomBlocks from '../../../constants/customBlocks';
import {getFileType} from '../../../helpers/fileTypeHelper';
import {getById} from '../../../store/reducers/helpers/byId';
import MediaBlock from '../MediaBlocks/MediaBlock';
import LoaderBlock from '../LoaderBlock/LoaderBlock';

const MediaWrapper = ({
    canEdit = false,
    block,
    mediaItemId,
    size = REGULAR,
    removeBlock,
    interactive = false,
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [couldNotBeFetched, setCouldNotBeFetched] = useState(false);
    const {updateBlockWithKey} = useManageEditorBlocks();
    const isAuthenticated = useSelector(
        ({auth: {accessToken}}) => accessToken !== null,
    );
    const mediaItems = useSelector(
        ({
            media: {
                internal: {byId},
            },
        }) => byId,
    );

    const dispatch = useDispatch();
    const setActiveSidebar = (sidebarType, data) =>
        dispatch(actions.editor.setActiveSidebar(sidebarType, data));
    const fetchMediaItemById = id =>
        dispatch(actions.media.internal.fetchMediaItemById(id));

    useEffect(() => {
        const mediaItem = getById(mediaItems, mediaItemId);

        if (isAuthenticated && !isFetching && !mediaItem) {
            setIsFetching(true);
            fetchMediaItemById(mediaItemId).then(
                () => {
                    setIsFetching(false);
                },
                () => {
                    setCouldNotBeFetched(true);
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaItemId]);

    const onDelete = () => {
        removeBlock();
    };
    const onEdit = () => {
        const fileType = getFileType(
            getById(mediaItems, mediaItemId).mime_type,
        );

        // TODO: switch subType and fileType and use getSubFileTypeHelper from lib
        let subType = '';
        switch (fileType) {
            case 'image':
            case 'video':
                subType = fileType;
                break;
            default:
                subType = 'file';
                break;
        }
        setActiveSidebar('media', {
            blockKey: block.getKey(),
            subType,
        });
    };
    const changeSize = newSize => {
        const blockKey = block.getKey();
        const blockData = {
            type: CustomBlocks.MEDIA_BLOCK,
            mediaItemId,
            size: newSize,
        };

        updateBlockWithKey(blockData, blockKey);
    };

    const mediaItem = getById(mediaItems, mediaItemId);
    let type;
    if (mediaItem) {
        type = getFileType(mediaItem.mime_type);
    }

    return (
        <LoaderBlock loading={!couldNotBeFetched && isFetching}>
            <div
                className={`media-wrapper ${type} ${interactive ? 'interactive' : ''} ${size ? `size-${size}` : ''}`}
                onClick={event => {
                    if (event.ctrlKey || event.metaKey) {
                        const url = getUrlBySize(mediaItem, FULL);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = mediaItem.name;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }}
                contentEditable={false}>
                <div className="media-wrapper__content actions">
                    {!interactive && canEdit && (
                        <>
                            <div className="media-wrapper__actions actions__content">
                                {mediaItem && (
                                    <EditButton
                                        className="media-wrapper__action"
                                        onClick={onEdit}
                                    />
                                )}
                                <CloseButton onClick={onDelete} />
                            </div>
                            {mediaItem && (
                                <div className="media-wrapper__sizes">
                                    <span
                                        className={`icon ${size === SMALL ? 'isActive' : ''}`}
                                        onClick={() => {
                                            changeSize(SMALL);
                                        }}>
                                        <Icon name="sizeSmall" size={16} />
                                    </span>
                                    <span
                                        className={`icon ${size === REGULAR ? 'isActive' : ''}`}
                                        onClick={() => {
                                            changeSize(REGULAR);
                                        }}>
                                        <Icon name="sizeNormal" size={16} />
                                    </span>
                                    <span
                                        className={`icon ${size === FULL ? 'isActive' : ''}`}
                                        onClick={() => {
                                            changeSize(FULL);
                                        }}>
                                        <Icon name="sizeExpand" size={16} />
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    <MediaBlock
                        mediaItem={mediaItem}
                        type={type}
                        interactive={interactive}
                    />
                </div>
            </div>

            <style jsx>
                {`
                    .media-wrapper {
                        $c: &;
                        position: relative;
                        cursor: pointer;
                        text-align: center;
                        transition: ${theme.defaultTransition} margin;

                        &__content {
                            display: inline-block;
                            position: relative;
                            transition: ${theme.defaultTransition} width;
                            width: 100%;
                        }

                        &__sizes {
                            position: absolute;
                            z-index: 1;
                            transition: ${theme.defaultTransition} ease opacity;
                        }

                        &__sizes {
                            opacity: 0;
                            top: 50%;
                            left: 50%;
                            background-color: ${colors.grey[900]};
                            border-radius: 0.5rem;
                            display: flex;
                            align-items: center;
                            box-shadow: ${theme.defaultShadow};
                            padding: 0.5rem 1rem;
                            transform: translate(-50%, -50%);

                            .icon {
                                margin-right: 1rem;
                                color: ${colors.pureWhite};
                                transition: ${theme.defaultTransition} color;

                                &:last-child {
                                    margin-right: 0;
                                }

                                &:hover,
                                &.isActive {
                                    color: ${colors.brand.base};
                                }
                            }
                        }

                        &:hover {
                            #{$c}__sizes {
                                opacity: 1;
                            }
                        }

                        .edit-button {
                            width: auto;
                            height: auto;
                            margin-left: auto;
                        }

                        &.audio {
                            #{$c}__actions {
                                position: static;
                                padding: 0.5rem;
                                display: flex;
                                justify-content: flex-end;
                                color: ${colors.pureBlack};
                            }

                            .edit-button {
                                order: 1;
                                margin-right: 0.5rem;
                            }

                            .close-button {
                                order: 2;
                            }

                            .actions:hover .actions__content {
                                filter: none;
                            }
                        }

                        &.size {
                            &-small {
                                #{$c}__content {
                                    width: 62%;
                                }
                            }

                            &-full {
                                margin: 0 -2rem;

                                @media (${media.forMediumAndUp}) {
                                    margin: 0 -9rem;
                                }
                            }

                            &-full-width {
                                max-width: none;
                            }
                        }

                        &.interactive {
                            cursor: default;
                        }
                    }
                `}
            </style>
        </LoaderBlock>
    );
};

export default MediaWrapper;
