import React from 'react';

const Smiley = () => (
    <g>
        <path
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 22.909C18.0249 22.909 22.9091 18.0248 22.9091 11.9999C22.9091 5.97499 18.0249 1.09082 12 1.09082C5.97508 1.09082 1.09091 5.97499 1.09091 11.9999C1.09091 18.0248 5.97508 22.909 12 22.909Z"
            fill="currentColor"
        />
        <path
            d="M7.63636 10.909C8.84135 10.909 9.81818 9.93222 9.81818 8.72723C9.81818 7.52224 8.84135 6.54541 7.63636 6.54541C6.43138 6.54541 5.45454 7.52224 5.45454 8.72723C5.45454 9.93222 6.43138 10.909 7.63636 10.909Z"
            fill="currentColor"
        />
        <path
            d="M16.3636 10.909C17.5686 10.909 18.5454 9.93222 18.5454 8.72723C18.5454 7.52224 17.5686 6.54541 16.3636 6.54541C15.1586 6.54541 14.1818 7.52224 14.1818 8.72723C14.1818 9.93222 15.1586 10.909 16.3636 10.909Z"
            fill="currentColor"
        />
        <path
            d="M5.95235 17.1859C5.91148 17.1443 5.87353 17.1056 5.83834 17.0704L7.25258 15.6562C7.29456 15.6982 7.33653 15.7407 7.37885 15.7835C7.72854 16.1375 8.10281 16.5163 8.71389 16.8484C9.38685 17.2142 10.4011 17.5452 12 17.5452C13.5989 17.5452 14.6132 17.2142 15.2861 16.8484C15.8972 16.5163 16.2715 16.1374 16.6212 15.7835C16.6635 15.7407 16.7054 15.6982 16.7473 15.6563L18.1617 17.0704C18.1265 17.1056 18.0886 17.1442 18.0477 17.1858C17.6955 17.5447 17.1262 18.1246 16.2412 18.6056C15.2414 19.149 13.892 19.5452 12 19.5452C10.108 19.5452 8.75861 19.149 7.75884 18.6056C6.87384 18.1246 6.30461 17.5448 5.95235 17.1859Z"
            fill="currentColor"
        />
    </g>
);

export default Smiley;
