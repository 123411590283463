/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import getUrlBySize, {THUMB, REGULAR, FULL} from 'lib/helpers/sizeHelper';
import * as theme from 'lib/styles/theme';
import * as colors from 'lib/styles/colors';
import ImageBlock from './ImageBlock';
import AudioBlock from './AudioBlock';
import VideoBlock from './VideoBlock';
import FileBlock from './FileBlock';

const MediaBlock = ({mediaItem, type, interactive, translate}) => {
    let blockContent;
    if (mediaItem) {
        switch (type) {
            case 'image':
                blockContent = (
                    <ImageBlock
                        src={getUrlBySize(mediaItem, REGULAR)}
                        smallSrc={getUrlBySize(mediaItem, THUMB)}
                    />
                );
                break;
            case 'audio':
                blockContent = (
                    <AudioBlock
                        src={getUrlBySize(mediaItem, FULL)}
                        type={mediaItem.mime_type}
                    />
                );
                break;
            case 'video':
                blockContent = (
                    <VideoBlock src={getUrlBySize(mediaItem, FULL)} />
                );
                break;
            default:
                blockContent = (
                    <FileBlock file={mediaItem} interactive={interactive} />
                );
                break;
        }
    } else {
        blockContent = (
            <div className="not-found">
                {translate('editor.media_not_found')}

                <style jsx>
                    {`
                        .not-found {
                            background-color: ${colors.brand[200]};
                            color: ${colors.text.light};
                            height: 10rem;
                            font-size: 1.8rem;
                            font-weight: ${theme.fwBold};
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            line-height: 1.2;
                        }
                    `}
                </style>
            </div>
        );
    }

    if (interactive && !mediaItem) {
        blockContent = '';
    }

    return (
        <div
            className="media-block"
            onClick={event => {
                if (event.ctrlKey || event.metaKey) {
                    const url = getUrlBySize(mediaItem, FULL);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = mediaItem.name; // Assuming file has a name property
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }}>
            {blockContent}
        </div>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(MediaBlock);
