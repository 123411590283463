import {combineReducers} from 'redux';
import {localizeReducer as localize} from 'react-localize-redux';

import auth from 'lib/store/reducers/auth';
import editor from 'lib/store/reducers/editor';
import smartTags from 'lib/store/reducers/smartTags';
import surveyEditor from 'lib/store/reducers/surveyEditor';
import tabMenu from 'lib/store/reducers/tabMenu';
import modals from 'lib/store/reducers/modals';
import media from 'lib/store/reducers/media';
import surveys from 'lib/store/reducers/surveys';
import contentSurveys from 'lib/store/reducers/contentSurveys';
import templates from 'lib/store/reducers/templates';
import messages from 'lib/store/reducers/messages';
import automatedMessages from 'lib/store/reducers/messages/automated';
import messagesAudience from 'lib/store/reducers/users/audience';
import notifications from 'lib/store/reducers/notifications';
import company from 'lib/store/reducers/company';
import appDomains from 'lib/store/reducers/appDomains';
import analytics from 'lib/store/reducers/analytics';
import users from 'lib/store/reducers/users';
import embedModal from 'lib/store/reducers/embedModal';
import embeds from 'lib/store/reducers/embeds';
import channels from 'lib/store/reducers/channels';
import formErrors from 'lib/store/reducers/formErrors';
import segmentation from 'lib/store/reducers/segmentation';
import audience from 'lib/store/reducers/audience';
import directories from 'lib/store/reducers/directories';
import history from 'lib/store/reducers/history';
import appHealth from 'lib/store/reducers/appHealth';
import messageComments from 'lib/store/reducers/messages/comments';
import timezones from 'lib/store/reducers/timezones';

const reducers = combineReducers({
    // DATA
    users,
    templates,
    messages,
    automatedMessages,
    messagesAudience,
    smartTags,
    media,
    surveys,
    contentSurveys,
    company,
    appDomains,
    analytics,
    embedModal,
    embeds,
    channels,
    formErrors,
    segmentation: segmentation(),
    audience,
    directories,
    history,
    appHealth,
    messageComments,
    timezones,

    // UI
    localize,
    auth,
    editor,
    surveyEditor,
    tabMenu,
    modals,
    notifications,
});

export default reducers;
