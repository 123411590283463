/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useEffect, useReducer} from 'react';
import {useTheme} from 'styled-components';
import {Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import LoadingWrapper from '../LoadingWrapper';

const MAX_ATTEMPTS = 5;
const FETCHING = 'FETCHING';
const COMPLETE = 'COMPLETE';
const FAILED = 'FAILED';
const UPDATE_SOURCE = 'UPDATE_SOURCE';
const INCREMENT_ATTEMPT = 'INCREMENT_ATTEMPT';
const SET_STATUS = 'SET_STATUS';

const initialState = {
    status: FETCHING,
    src: null,
    attempts: 0,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case INCREMENT_ATTEMPT: {
            const attempts = state.attempts + 1;
            return {
                ...state,
                attempts,
                src: `${payload.src}#attempt${attempts}`,
            };
        }
        case SET_STATUS:
            return {
                ...state,
                status: payload,
            };
        case UPDATE_SOURCE:
            return {
                ...state,
                src: payload,
            };
        default:
            throw new Error(`Action not defined: ${type}`);
    }
};

const ImageLoader = ({
    src: srcFromProps,
    alt,
    disableLoader,
    className,
    hideOnFail,
    retryIfNotFound,
    onLoad = () => {},
    onClick = () => {},
}) => {
    const [{attempts, status, src}, dispatch] = useReducer(
        reducer,
        initialState,
    );
    const theme = useTheme();

    useEffect(() => {
        dispatch({type: UPDATE_SOURCE, payload: srcFromProps});
    }, [srcFromProps]);

    const handleImageLoaded = () => {
        onLoad();
        dispatch({type: SET_STATUS, payload: COMPLETE});
    };

    const handleError = () => {
        if (retryIfNotFound && attempts < MAX_ATTEMPTS) {
            setTimeout(
                () => {
                    dispatch({
                        type: INCREMENT_ATTEMPT,
                        payload: {src: srcFromProps},
                    });
                },
                250 + attempts * 250,
            );
        } else {
            dispatch({type: SET_STATUS, payload: FAILED});
        }
    };

    return (
        <>
            <img
                className={`${className || ''} ${status === FETCHING ? 'hidden' : ''}`}
                src={src}
                alt={alt}
                tabIndex={0}
                onClick={onClick}
                onLoad={handleImageLoaded}
                onError={handleError}
            />

            {status === FAILED ? (
                !hideOnFail && (
                    <div className="image-loader">
                        <div className="content">
                            <span role="img" aria-label="Warning">
                                ⚠️
                            </span>
                            <Translate id="editor.image-not-loaded" />
                        </div>
                    </div>
                )
            ) : (
                <LoadingWrapper
                    loading={status === FETCHING && !disableLoader}
                />
            )}

            <style jsx="true">
                {`
                    .image-loader {
                        background-color: ${theme.colors.grey[0]};
                        border: 1px solid ${theme.colors.grey[1]};
                        padding: 2rem 1.5rem;
                        color: ${theme.colors.grey[4]};
                        border-radius: ${theme.borderRadius}rem;
                        text-style: italic;
                    }

                    .text {
                        font-weight: ${theme.fwMedium};
                    }
                `}
            </style>
        </>
    );
};

ImageLoader.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    disableLoader: PropTypes.bool,
    className: PropTypes.string,
    hideOnFail: PropTypes.bool,
    retryIfNotFound: PropTypes.bool,
    onLoad: PropTypes.func,
    onClick: PropTypes.func,
};

ImageLoader.defaultProps = {
    alt: '',
    disableLoader: false,
    className: '',
    hideOnFail: false,
    retryIfNotFound: false,
    onLoad: () => {},
    onClick: () => {},
};
export default ImageLoader;
