import React, {useState, useRef} from 'react';
import {Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import FileUpload from 'components/util/FileUpload';
import * as theme from 'styles/theme';
import * as colors from 'lib/styles/colors';
import Icon, {upload, cross} from 'lib/components/Icon';
import Loading from 'lib/components/Loading';

const OUTSIDE = 'OUTSIDE';
const NOT_ALLOWED = 'NOT_ALLOWED';
const MULTI_NOT_ALLOWED = 'MULTI_NOT_ALLOWED';
const INSIDE = 'INSIDE';
const DROPPED = 'DROPPED';

const isFileAllowed = (file, types) => {
    if (types === '*') {
        return true;
    }
    const parts = file.name.split('.');
    if (parts.length < 2) {
        return false;
    }
    const extension = parts[parts.length - 1];
    return types.indexOf(`.${extension}`) > -1;
};

const getIconProps = (isSuccessful, hasFailed) => {
    if (isSuccessful) {
        return {
            name: upload,
            color: colors.success.dark,
        };
    }
    if (hasFailed) {
        return {
            name: cross,
            color: colors.danger.dark,
        };
    }
    return {
        name: upload,
    };
};

// TODO: allow multiple files to be uploaded

const FileDropZone = ({
    onFilesChange,
    acceptTypes = '*',
    isLoading,
    actionText,
    isSuccessful,
    hasFailed,
    errorMessage = null,
    successMessage = null,
}) => {
    const [dragState, setDragState] = useState(OUTSIDE);
    const enterCount = useRef(0);

    const onDragEnter = e => {
        e.preventDefault();
        enterCount.current += 1;
        const isMulti = e.dataTransfer.items.length > 1;

        if (isMulti) {
            setDragState(MULTI_NOT_ALLOWED);
        } else {
            setDragState(INSIDE);
        }
    };
    const onDragLeave = e => {
        e.preventDefault();
        enterCount.current -= 1;
        if (enterCount.current < 1) {
            setDragState(OUTSIDE);
        }
    };

    const onDrop = e => {
        e.preventDefault();
        const {files} = e.dataTransfer;

        if (!isFileAllowed(files[0], acceptTypes)) {
            setDragState(NOT_ALLOWED);
        } else if (dragState !== MULTI_NOT_ALLOWED) {
            onFilesChange(files);
            setDragState(DROPPED);
        }
    };

    return (
        <div
            className="fileDropZone"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={e => e.preventDefault()}
            onDrop={onDrop}>
            <FileUpload
                onFilesChange={files => {
                    setDragState(OUTSIDE);
                    onFilesChange(files);
                }}
                acceptTypes={acceptTypes}>
                <div className="content">
                    {errorMessage === null && (
                        <div className="icon">
                            {isLoading ? (
                                <Loading size={60} />
                            ) : (
                                <Icon
                                    size={60}
                                    {...getIconProps(isSuccessful, hasFailed)}
                                />
                            )}
                        </div>
                    )}

                    {isSuccessful && typeof successMessage === 'undefined' && (
                        <span className="successMessage">
                            <Translate id="global.uploadSuccessful" />
                        </span>
                    )}

                    {isSuccessful &&
                        typeof successMessage !== 'undefined' &&
                        successMessage !== null && (
                            <span className="successMessage">
                                {successMessage}
                            </span>
                        )}

                    {errorMessage !== null && (
                        <div className="error">{errorMessage}</div>
                    )}

                    <span className="action">{actionText}</span>
                    <span className="help">
                        <Translate id="global.dragAndDrop" />
                    </span>
                    <span className="acceptFiles">
                        <Translate id="global.acceptTypes" />{' '}
                        {acceptTypes.join(', ')}
                    </span>

                    {dragState === MULTI_NOT_ALLOWED && (
                        <span className="error">
                            <Translate id="global.maxUpload" />
                        </span>
                    )}

                    {dragState === NOT_ALLOWED && (
                        <span className="error">
                            <Translate id="global.fileTypeNotAllowed" />
                        </span>
                    )}
                </div>
            </FileUpload>

            <style jsx>
                {`
                    .fileDropZone {
                        background-color: ${colors.background};
                        border-radius: ${theme.borderRadius}rem;
                        padding: ${theme.spacingSmallest}rem;
                        box-shadow: ${theme.defaultShadow};
                        cursor: pointer;
                    }

                    .content {
                        padding: ${theme.spacing}rem;
                        border: dashed 0.2rem ${colors.border};
                        border-radius: ${theme.borderRadius}rem;
                        display: flex;
                        flex-direction: column;
                    }

                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        ${isSuccessful !== null && 'margin-bottom: 0;'}
                    }

                    .action,
                    .help,
                    .acceptFiles,
                    .error,
                    .successMessage {
                        text-align: center;
                    }

                    .successMessage {
                        color: ${colors.success.dark};
                        margin-bottom: ${theme.spacingLarge}rem;
                    }

                    .action {
                        font-size: ${theme.fontSizeLarge}rem;
                        font-weight: ${theme.fwSemiBold};
                        margin-bottom: ${theme.spacing}rem;
                    }

                    .help {
                        margin-bottom: ${theme.spacingSmallest}rem;
                    }

                    .acceptFiles {
                        font-size: ${theme.fontSizeSmaller}rem;
                        font-weight: ${theme.fwLight};
                    }

                    .error {
                        color: ${colors.danger.base};
                        margin-top: ${theme.spacing}rem;
                        margin-bottom: ${theme.spacing}rem;
                    }
                `}
            </style>
        </div>
    );
};

FileDropZone.propTypes = {
    onFilesChange: PropTypes.func.isRequired,
    acceptTypes: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    actionText: PropTypes.string.isRequired,
    isSuccessful: PropTypes.bool.isRequired,
    hasFailed: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
};

FileDropZone.defaultProps = {
    acceptTypes: '*',
    errorMessage: null,
    successMessage: null,
};
export default FileDropZone;
