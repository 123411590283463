import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M21.328 0H2.672C1.219 0 0 1.219 0 2.672v18.656C0 22.781 1.219 24 2.672 24h18.656C22.781 24 24 22.781 24 21.328V2.672C24 1.219 22.781 0 21.328 0zM8.578 17.156H5.156V8.578h3.422zm5.156 1.688h-3.468V5.156h3.468zm5.11 0h-3.422v-5.11h3.422zm0 0"
        />
    );
}
