import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M10.64 12.14c3.422 0 4.641-3.374 4.97-6.14.374-3.422-1.266-6-4.97-6-3.703 0-5.343 2.578-4.968 6C6 8.766 7.219 12.14 10.64 12.14zm7.407 1.688h.328a2.294 2.294 0 0 0-.563-.61c-.656-.468-1.5-.655-2.25-.937-.374-.14-.703-.281-.984-.422-1.078 1.125-2.39 1.688-3.984 1.688-1.594 0-2.906-.563-3.938-1.688-.328.141-.656.282-1.031.422-.75.281-1.594.469-2.25.938-1.125.844-1.406 2.719-1.64 4.031a25.324 25.324 0 0 0-.376 3.234c0 .844.375.938 1.125 1.172.844.328 1.782.563 2.672.75 1.782.375 3.61.657 5.438.703.89-.047 1.781-.093 2.625-.187-.61-.938-1.031-2.11-1.031-3.328 0-3.188 2.624-5.766 5.859-5.766zm.047 1.078c-2.532 0-4.594 2.016-4.594 4.547 0 2.485 2.063 4.547 4.594 4.547 2.484 0 4.547-2.063 4.547-4.547a4.551 4.551 0 0 0-4.547-4.547zm1.968 5.297h-1.218v1.219c0 .469-.328.797-.75.797-.469 0-.797-.328-.797-.797v-1.219h-1.219c-.422 0-.797-.328-.797-.75s.375-.797.797-.797h1.219v-1.218c0-.422.328-.75.797-.75a.74.74 0 0 1 .75.75v1.218h1.218c.422 0 .797.375.797.797 0 .422-.375.75-.797.75zm0 0"
        />
    );
}
