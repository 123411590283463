import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import actions from 'store/actions';
import {
    MODAL_DIRECTORY_AUDIENCE,
    MODAL_DIRECTORY_NAME,
} from 'lib/store/actions/modals';
import DirectoryNameModal from './DirectoryNameModal';

const DirectoryNameModalWithData = ({translate}) => {
    const dispatch = useDispatch();
    const modalData = useSelector(
        ({modals}) => modals.data[MODAL_DIRECTORY_NAME],
    );
    const isVisible = useSelector(
        ({modals}) => modals.visibility[MODAL_DIRECTORY_NAME],
    );

    const action = (modalData && modalData.action) || null;
    const directory = (modalData && modalData.directory) || null;

    const setModalData = (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const updateDirectory = (directoryId, {name}) =>
        dispatch(actions.directories.updateDirectory(directoryId, {name}));

    const [name, setName] = useState((directory && directory.name) || '');

    useEffect(() => {
        setName((directory && directory.name) || '');
    }, [directory]);

    if (!isVisible) {
        return null;
    }

    const directoryId = (directory && directory.id) || null;
    const buttonActions = [];
    let title = '';

    if (action === 'create') {
        title = translate('documents.createDirectoryTitle');
        buttonActions.push({
            classes: 'button success',
            disabled: name === '',
            label: translate('global.next'),
            callBack: () => {
                // we need this to assign name data to directoryFormValues
                setModalData(MODAL_DIRECTORY_AUDIENCE, {
                    name,
                    action: 'create',
                });
                setModalVisibility(MODAL_DIRECTORY_AUDIENCE, true);
                setModalVisibility(MODAL_DIRECTORY_NAME, false);
            },
        });
    } else {
        // = update
        title = translate('documents.renameDirectory');
        const label = translate('global.save');

        buttonActions.push({
            classes: 'button success',
            disabled: name === '',
            label,
            callBack: () => {
                updateDirectory(directoryId, {
                    name,
                    audience_filters: directory.audience_filters,
                    default_audience_filters:
                        directory.default_audience_filters,
                });
                setModalVisibility(MODAL_DIRECTORY_NAME, false);
            },
        });
    }

    return (
        <DirectoryNameModal
            name={name}
            onSetName={setName}
            title={title}
            buttonActions={buttonActions}
            directory={directory}
        />
    );
};

DirectoryNameModalWithData.propTypes = {
    translate: PropTypes.func.isRequired,
};
export default withLocalize(DirectoryNameModalWithData);
