import React from 'react';
import {
    CONTENT_TYPE_MESSAGES,
    CONTENT_TYPE_TEMPLATES,
    CONTENT_TYPE_AUTOMATED_MESSAGES,
} from 'lib/store/actions/editor';
import MessageConfigurationSidebar from './MessageConfigurationSidebar';
import TemplateConfigurationSidebar from './TemplateConfigurationSidebar';
import AutomatedMessageConfigurationSidebar from './AutomatedMessageConfigurationSidebar';

const EditorConfigurationSidebar = ({canEdit, messageId, contentType}) => {
    switch (contentType) {
        case CONTENT_TYPE_MESSAGES:
            return (
                <MessageConfigurationSidebar
                    canEdit={canEdit}
                    messageId={messageId}
                />
            );
        case CONTENT_TYPE_AUTOMATED_MESSAGES:
            return (
                <AutomatedMessageConfigurationSidebar
                    canEdit={canEdit}
                    messageId={messageId}
                />
            );
        case CONTENT_TYPE_TEMPLATES:
            return (
                <TemplateConfigurationSidebar
                    canEdit={canEdit}
                    messageId={messageId}
                />
            );
        default:
            return null;
    }
};

export default EditorConfigurationSidebar;
