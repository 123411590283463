import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {drafts, home} from 'routes/index';
import {isSuperUser} from 'util/getUserRoleHelper';

const UnauthorizedPage = ({isAuthenticated, userRole, children, location}) => {
    if (isAuthenticated && userRole) {
        if (isSuperUser(userRole)) {
            if (location.pathname !== drafts()) {
                return <Redirect to={drafts()} />;
            }
        } else if (location.pathname !== home()) {
            return <Redirect to={home()} />;
        }
    }

    return children;
};

export default withRouter(UnauthorizedPage);
