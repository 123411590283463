import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M.75 23.672v-3.094h3.234V3.141H.75V0h12.188c7.687 0 9.468 2.906 9.468 5.719 0 2.39-1.64 4.734-6.375 5.437v.235c4.969.562 7.219 2.625 7.219 5.765 0 3.563-2.484 6.516-10.125 6.516zM9.844 9.844h2.437c3.188 0 4.219-1.36 4.219-3.563 0-2.156-.89-3.14-4.172-3.14H9.844zm0 10.734h2.484c3.703 0 4.922-1.078 4.922-3.984 0-2.25-1.266-3.703-4.969-3.703H9.844zm0 0"
        />
    );
}
