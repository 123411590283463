import css from 'styled-jsx/css';
import * as colors from 'lib/styles/colors';
import * as theme from 'styles/theme';

export default css`
    .table {
        color: ${colors.text.base};
    }

    .tippy-tooltip,
    .tippy-tooltip--regular {
        font-size: ${theme.fontSize}rem;
    }

    .tippy-tooltip--small {
        font-size: ${theme.fontSizeSmaller}rem;
    }

    .tippy-tooltip--big {
        font-size: ${theme.fontSizeLarger}rem;
    }

    .form-control-plaintext {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        font-size: 1.4rem;
        font-weight: 300;
    }
`;
