import {CALL_API} from '../../constants/api';
import {GET} from '../services';

export const APP_HEALTH_REQUEST = 'APP_HEALTH_REQUEST';
export const APP_HEALTH_SUCCESS = 'APP_HEALTH_SUCCESS';
export const APP_HEALTH_FAILURE = 'APP_HEALTH_FAILURE';

export const fetchAppHealth = clientName => ({
    [CALL_API]: {
        endpoint: `/health-check/${clientName}`,
        method: GET,
        version: 'v1',
        types: [APP_HEALTH_REQUEST, APP_HEALTH_SUCCESS, APP_HEALTH_FAILURE],
    },
});

export const SET_APP_AVAILABILITY = 'SET_APP_AVAILABILITY';

export const setAppAvailability = availability => ({
    type: SET_APP_AVAILABILITY,
    payload: {availability},
});
