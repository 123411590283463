import React from 'react';
import SidebarForm from 'components/layout/SidebarForm';
import Audience from 'components/EditorSidebar/Audience';
import EditorSidebarTriggerWithState from './EditorSidebarTriggerWithState';
import EditorSidebarInformationWithState from './EditorSidebarInformationWithState';
import EditorSidebarCommentToggleWithState from './EditorSidebarCommentToggleWithState';
import EditorSidebarSendRemindersWithState from './EditorSidebarSendRemindersWithState';
import EditorSidebarLanguageSelectWithState from './EditorSidebarLanguageSelectWithState';
import EditorSidebarSuperUserSelectWithState from './EditorSidebarSuperUserSelectWithState';
import EditorSidebarAppDomainSelectWithState from './EditorSidebarAppDomainSelectWithState';
import EditorSidebarVisibilitySelectWithState from './EditorSidebarVisibilitySelectWithState';
import EditorSidebarAutomatedMessageActiveToggleWithState from './EditorSidebarAutomatedMessageActiveToggleWithState.js';
import AutomatedMessageConfigurationSidebarButtonsWithState from './AutomatedMessageConfigurationSidebarButtonsWithState';

const AutomatedMessageConfigurationSidebar = ({canEdit, messageId}) => (
    <SidebarForm>
        <SidebarForm.Content>
            <EditorSidebarLanguageSelectWithState />
            <EditorSidebarInformationWithState />
            <EditorSidebarAutomatedMessageActiveToggleWithState
                canEdit={canEdit}
            />
            <EditorSidebarCommentToggleWithState canEdit={canEdit} />
            <Audience canEdit={canEdit} messageId={messageId} />
            <EditorSidebarAppDomainSelectWithState canEdit={canEdit} />
            <EditorSidebarTriggerWithState canEdit={canEdit} />
            <EditorSidebarVisibilitySelectWithState canEdit={canEdit} />
            <EditorSidebarSendRemindersWithState canEdit={canEdit} />
            <EditorSidebarSuperUserSelectWithState canEdit={canEdit} />
        </SidebarForm.Content>
        <SidebarForm.Actions>
            <AutomatedMessageConfigurationSidebarButtonsWithState
                canEdit={canEdit}
            />
        </SidebarForm.Actions>
    </SidebarForm>
);

export default AutomatedMessageConfigurationSidebar;
