import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="m15.281 19.453-1.265-3.703H7.219l-1.266 3.703h2.484v2.672H.798v-2.672h2.156L9.375 2.016h4.969l6.375 17.437h2.343v2.672H12.61v-2.672zM10.594 5.906 8.016 13.36h5.25l-2.532-7.453zm0 0"
        />
    );
}
