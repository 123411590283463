import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M12.004 6.25a5.75 5.75 0 100 11.5 5.752 5.752 0 005.75-5.75 5.76 5.76 0 00-5.75-5.75zm-.82 8.09L8.68 11.836l.851-.852 1.653 1.653 3.39-3.387.848.852zm0 0" />
            <path d="M20.531 12.316a.495.495 0 010-.632l.617-.754a.5.5 0 00-.07-.703l-.047-.036-.82-.527a.5.5 0 01-.195-.601l.351-.907a.5.5 0 00-.285-.648c-.016-.008-.035-.012-.05-.016l-.946-.25a.505.505 0 01-.371-.512l.055-.972a.496.496 0 00-.47-.528h-.058l-.972.055a.494.494 0 01-.508-.37l-.25-.942a.496.496 0 00-.614-.356c-.015.004-.035.008-.05.016l-.91.351a.502.502 0 01-.602-.195l-.527-.82a.502.502 0 00-.692-.149.415.415 0 00-.043.035l-.758.614a.495.495 0 01-.632 0l-.754-.614a.498.498 0 00-.703.07l-.036.044-.527.82a.495.495 0 01-.598.195l-.91-.351a.5.5 0 00-.648.285c-.004.016-.012.035-.016.055l-.25.941a.498.498 0 01-.512.371l-.972-.055a.502.502 0 00-.528.473v.055l.055.972a.497.497 0 01-.37.512l-.942.25a.5.5 0 00-.356.61c.004.02.012.035.016.054l.355.906a.497.497 0 01-.199.602l-.816.527a.5.5 0 00-.153.692l.035.047.618.754a.505.505 0 010 .632l-.618.754a.497.497 0 00.118.739l.816.527a.497.497 0 01.2.601l-.356.907a.504.504 0 00.34.664l.941.25c.23.062.387.273.371.512l-.055.972a.5.5 0 00.473.528h.055l.972-.055a.498.498 0 01.512.37l.25.942c.07.27.344.43.61.356a.224.224 0 00.054-.016l.91-.351a.495.495 0 01.598.195l.527.82c.153.23.461.297.696.149a.415.415 0 01.043-.035l.754-.614a.495.495 0 01.632 0l.758.614c.211.175.528.144.7-.07.015-.016.027-.028.035-.044l.527-.82a.502.502 0 01.601-.195l.91.351a.497.497 0 00.645-.285c.008-.016.012-.035.02-.055l.25-.941a.494.494 0 01.508-.371l.972.055a.5.5 0 00.528-.528l-.055-.972a.505.505 0 01.37-.512l.946-.25a.5.5 0 00.356-.61c-.004-.02-.012-.035-.02-.054l-.351-.906a.5.5 0 01.195-.602l.82-.527a.503.503 0 00.149-.692c-.008-.015-.02-.031-.032-.047zM12.004 18.5a6.5 6.5 0 110-13c3.586 0 6.5 2.91 6.5 6.5a6.51 6.51 0 01-6.5 6.5zm0 0" />
        </g>
    );
}
