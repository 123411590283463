import React from 'react';
import {useSelector} from 'react-redux';
import versionCompare from 'lib/helpers/versionCompare';
import AppNeedsUpdate from './AppNeedsUpdate';
import AppUnavailable from './AppUnavailable';
import {version} from '../../../package.json';

const HealthWrapper = ({children}) => {
    const appHealth = useSelector(state => state.appHealth);
    const {available, minClientVersion} = appHealth;

    if (!available) {
        return <AppUnavailable />;
    }

    if (versionCompare(version, minClientVersion) === -1) {
        return <AppNeedsUpdate />;
    }

    return children;
};

export default HealthWrapper;
