import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M0 1.875h24v16.453H0zm2.531 17.719H21.47v2.531H2.53zM.094.656h.703V.75H.094zm0 0"
        />
    );
}
