import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M3 23.672h11.719l.515-2.344h-4.03l4.733-18.937h3.938L20.438 0H8.765l-.563 2.39h4.031L7.5 21.329H3.562zm0 0"
        />
    );
}
