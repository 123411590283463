import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import actions from 'store/actions';
import {MODAL_NEW_MESSAGE} from 'lib/store/actions/modals';
import {fetchAll} from 'util/fetchAll';
import AutomatedMessages from './AutomatedMessages';

const AutomatedMessagesWithState = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const fetchMessages = useCallback(
        params =>
            fetchAll(
                dispatch,
                actions.automatedMessages.fetchAutomatedMessages,
                params,
            ),
        [dispatch],
    );

    const {
        byId: automatedMessages,
        paginated: {filters, isFetching, pages, total},
    } = useSelector(state => state.automatedMessages);
    useEffect(() => {
        fetchMessages({
            filters: {
                query: filters.query || '',
            },
        });
    }, [fetchMessages, filters.query]);

    const startNewAutomatedMessage = () => {
        history.push('/editor/automated-messages');
        dispatch(actions.modals.setModalVisibility(MODAL_NEW_MESSAGE, false));
        dispatch(actions.editor.startNewAutomatedMessage());
    };
    const loadAutomatedMessageIntoEditor = message => {
        history.push(`/editor/automated-messages/${message.id}`);
    };

    const onQueryChange = newFilters => {
        fetchMessages({
            filters: Object.assign({}, filters, newFilters),
        });
    };

    const handleSearchQueryChange = query => {
        onQueryChange({query});
    };

    const handleNewMessageClick = () => {
        dispatch(actions.editor.clearEditor());
        startNewAutomatedMessage();
    };

    const handleMessageClick = message => {
        loadAutomatedMessageIntoEditor(message);
    };

    return (
        <AutomatedMessages
            pages={pages}
            automatedMessages={automatedMessages}
            filters={filters}
            isFetching={isFetching}
            total={total}
            onSearchQueryChange={handleSearchQueryChange}
            onMessageClick={handleMessageClick}
            onNewMessageClick={handleNewMessageClick}
        />
    );
};

export default AutomatedMessagesWithState;
