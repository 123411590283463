import React from 'react';

const Location = () => (
    <path
        d="M18.3409 2.46193C16.7543 0.874409 14.6446 0 12.4008 0C10.1567 0 8.04742 0.874409 6.46077 2.46193C3.52446 5.39942 3.15956 10.9264 5.67051 14.275L12.4008 24L19.1211 14.2886C21.6421 10.9264 21.2772 5.39942 18.3409 2.46193ZM12.4784 11.3958C10.7879 11.3958 9.41197 10.0191 9.41197 8.3277C9.41197 6.6363 10.7879 5.2596 12.4784 5.2596C14.1689 5.2596 15.5448 6.6363 15.5448 8.3277C15.5448 10.0191 14.1689 11.3958 12.4784 11.3958Z"
        fill="currentColor"
    />
);

export default Location;
