import React from 'react';

export default function () {
    return (
        <path
            d="M10.856 13.144C9.74937 12.0478 8.855 10.7564 8.21799 9.335C8.1594 9.19643 8.1439 9.04342 8.17351 8.89591C8.20312 8.7484 8.27647 8.61323 8.38399 8.508L9.20299 7.69C9.87399 7.019 9.87399 6.07 9.28799 5.484L8.11399 4.31C7.73894 3.93506 7.23032 3.72443 6.69999 3.72443C6.16966 3.72443 5.66105 3.93506 5.28599 4.31L4.63399 4.962C3.89299 5.703 3.58399 6.772 3.78399 7.832C4.27799 10.445 5.79599 13.306 8.24499 15.755C10.694 18.204 13.555 19.722 16.168 20.216C17.228 20.416 18.297 20.107 19.038 19.366L19.689 18.715C20.0639 18.3399 20.2746 17.8313 20.2746 17.301C20.2746 16.7707 20.0639 16.2621 19.689 15.887L18.516 14.714C18.2347 14.4328 17.8532 14.2748 17.4555 14.2748C17.0577 14.2748 16.6763 14.4328 16.395 14.714L15.492 15.618C15.3868 15.7255 15.2516 15.7989 15.1041 15.8285C14.9566 15.8581 14.8036 15.8426 14.665 15.784C13.2437 15.1459 11.9524 14.2509 10.856 13.144V13.144Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
