import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M.75 4.5h22.5v15H.75zm0-4.5h22.5v3H.75zM.047.047h.656v.047H.047zM.75 21h22.5v3H.75zM.047.703h.656V.75H.047zm0 0"
        />
    );
}
