import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {DELETE, PUT, GET, POST} from '../../services';

export const TYPE_HAPPINESS_POLL = 'happiness_poll';
export const TYPE_MULTIPLE_CHOICE = 'multiple_choice';
export const TYPE_YES_NO_QUESTION = 'yes_no_question';
export const TYPE_OPINION_SCALE = 'opinion_scale';
export const TYPE_RATING = 'rating';
export const TYPE_RSVP = 'rsvp';

export const SURVEY_SCHEMA = new schema.Entity('survey');

export const SURVEYS_LIST_REQUEST = 'SURVEYS_LIST_REQUEST';
export const SURVEYS_LIST_SUCCESS = 'SURVEYS_LIST_SUCCESS';
export const SURVEYS_LIST_FAILURE = 'SURVEYS_LIST_FAILURE';

export const fetchSurveys = ({query, page, limit}, listType = null) => ({
    [CALL_API]: {
        endpoint: '/surveys',
        method: GET,
        query: {
            query,
            page,
            limit,
        },
        types: [
            SURVEYS_LIST_REQUEST,
            SURVEYS_LIST_SUCCESS,
            SURVEYS_LIST_FAILURE,
        ],
        listType,
        schema: [SURVEY_SCHEMA],
    },
});

export const fetchMessagesSurveys = ({
    page,
    filters: {sort, from, to, status} = {},
}) => ({
    [CALL_API]: {
        endpoint: '/messages-surveys',
        method: GET,
        query: {
            page,
            sort,
            from,
            to,
            status,
        },
        types: [
            SURVEYS_LIST_REQUEST,
            SURVEYS_LIST_SUCCESS,
            SURVEYS_LIST_FAILURE,
        ],
        listType: 'messages',
        schema: [SURVEY_SCHEMA],
    },
});

export const MESSAGE_SURVEYS_LIST_REQUEST = 'MESSAGE_SURVEYS_LIST_REQUEST';
export const MESSAGE_SURVEYS_LIST_SUCCESS = 'MESSAGE_SURVEYS_LIST_SUCCESS';
export const MESSAGE_SURVEYS_LIST_FAILURE = 'MESSAGE_SURVEYS_LIST_FAILURE';

export const fetchSurveysForContentWithType = (
    content,
    type,
    {page, limit, query, withAnswerStats},
) => ({
    [CALL_API]: {
        endpoint: `/${type}/${content.id}/surveys`,
        method: GET,
        query: {
            query,
            page,
            limit,
            with_answer_stats: withAnswerStats,
        },
        types: [
            MESSAGE_SURVEYS_LIST_REQUEST,
            MESSAGE_SURVEYS_LIST_SUCCESS,
            MESSAGE_SURVEYS_LIST_FAILURE,
        ],
        listType: `${type}_${content.id}`,
        schema: [SURVEY_SCHEMA],
    },
});

export const SURVEYS_CREATE_REQUEST = 'SURVEYS_CREATE_REQUEST';
export const SURVEYS_CREATE_SUCCESS = 'SURVEYS_CREATE_SUCCESS';
export const SURVEYS_CREATE_FAILURE = 'SURVEYS_CREATE_FAILURE';

export const createSurvey = survey => ({
    [CALL_API]: {
        endpoint: '/surveys',
        method: POST,
        body: survey,
        types: [
            SURVEYS_CREATE_REQUEST,
            SURVEYS_CREATE_SUCCESS,
            SURVEYS_CREATE_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export const SURVEYS_VIEW_REQUEST = 'SURVEYS_VIEW_REQUEST';
export const SURVEYS_VIEW_SUCCESS = 'SURVEYS_VIEW_SUCCESS';
export const SURVEYS_VIEW_FAILURE = 'SURVEYS_VIEW_FAILURE';

export const fetchSurvey = surveyId => ({
    [CALL_API]: {
        endpoint: `/surveys/${surveyId}`,
        method: GET,
        types: [
            SURVEYS_VIEW_REQUEST,
            SURVEYS_VIEW_SUCCESS,
            SURVEYS_VIEW_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export const SURVEYS_UPDATE_REQUEST = 'SURVEYS_UPDATE_REQUEST';
export const SURVEYS_UPDATE_SUCCESS = 'SURVEYS_UPDATE_SUCCESS';
export const SURVEYS_UPDATE_FAILURE = 'SURVEYS_UPDATE_FAILURE';

export const updateSurvey = (survey, resetAnswers = false) => ({
    [CALL_API]: {
        endpoint: `/surveys/${survey.id}?reset_answers=${resetAnswers}`,
        method: PUT,
        body: survey,
        types: [
            SURVEYS_UPDATE_REQUEST,
            SURVEYS_UPDATE_SUCCESS,
            SURVEYS_UPDATE_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export const SURVEYS_DELETE_REQUEST = 'SURVEYS_DELETE_REQUEST';
export const SURVEYS_DELETE_SUCCESS = 'SURVEYS_DELETE_SUCCESS';
export const SURVEYS_DELETE_FAILURE = 'SURVEYS_DELETE_FAILURE';

export const deleteSurvey = survey => ({
    [CALL_API]: {
        endpoint: `/surveys/${survey.id}`,
        method: DELETE,
        body: survey,
        types: [
            SURVEYS_DELETE_REQUEST,
            SURVEYS_DELETE_SUCCESS,
            SURVEYS_DELETE_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export const ACCEPT_ANSWERS_REQUEST = 'ACCEPT_ANSWERS_REQUEST';
export const ACCEPT_ANSWERS_SUCCESS = 'ACCEPT_ANSWERS_SUCCESS';
export const ACCEPT_ANSWERS_FAILURE = 'ACCEPT_ANSWERS_FAILURE';

export const acceptAnswers = id => ({
    [CALL_API]: {
        endpoint: `/messages/${id}/accept-answers`,
        method: POST,
        types: [
            ACCEPT_ANSWERS_REQUEST,
            ACCEPT_ANSWERS_SUCCESS,
            ACCEPT_ANSWERS_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export const REFUSE_ANSWERS_REQUEST = 'REFUSE_ANSWERS_REQUEST';
export const REFUSE_ANSWERS_SUCCESS = 'REFUSE_ANSWERS_SUCCESS';
export const REFUSE_ANSWERS_FAILURE = 'REFUSE_ANSWERS_FAILURE';

export const refuseAnswers = id => ({
    [CALL_API]: {
        endpoint: `/messages/${id}/refuse-answers`,
        method: POST,
        types: [
            REFUSE_ANSWERS_REQUEST,
            REFUSE_ANSWERS_SUCCESS,
            REFUSE_ANSWERS_FAILURE,
        ],
        schema: SURVEY_SCHEMA,
    },
});

export default {
    fetchSurveys,
    fetchSurveysForContentWithType,
    fetchSurvey,
    createSurvey,
    updateSurvey,
    deleteSurvey,
    acceptAnswers,
    refuseAnswers,
};
