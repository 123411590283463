import React from 'react';
import Table from 'components/Table/Table';
import ContentView from 'components/util/ContentView';
import {Translate, withLocalize} from 'react-localize-redux';

const ArchivedMessages = ({
    rows,
    columns,
    onTableEnd,
    isFetching,
    onSortChange,
    filters,
    total,
}) => (
    <ContentView
        title={<Translate id="archived_messages.title" />}
        total={total}>
        <Table
            rows={rows}
            columns={columns}
            onTableEnd={onTableEnd}
            loading={isFetching}
            emptyTitle={<Translate id="archived_messages.no_messages" />}
            sort={filters.sort || {}}
            onSortChange={onSortChange}
        />
    </ContentView>
);

export default withLocalize(ArchivedMessages);
