import React from 'react';

const Code = () => (
    <g stroke="currentColor" fill="none">
        <polyline points="16 18 22 12 16 6" />
        <polyline points="8 6 2 12 8 18" />
    </g>
);

export default Code;
