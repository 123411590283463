import React, {useEffect, useState, useRef, useCallback} from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Empty from 'components/util/Empty';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import InfiniteScroll from 'lib/components/InfiniteScroll';
import {useTheme} from 'styled-components';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const Table = ({
    rows,
    columns,
    onTableEnd = () => {},
    onRowClick,
    loading,
    emptyTitle,
    sort,
    multiSort,
    height,
    onSortChange,
    list: isList,
    active,
    useFixed,
}) => {
    const rowRef = useRef(null);
    const theme = useTheme();
    const [cellWidths, setCellWidths] = useState(
        useFixed ? columns.map(({width}) => width || 'auto') : [],
    );
    let tbody;
    const calculateWidth = useCallback(() => {
        if (rowRef.current && !useFixed) {
            const widths = Array.from(rowRef?.current?.children).map(
                cell => cell.clientWidth,
            );
            setCellWidths(widths);
        }
    }, [useFixed, rowRef]);

    const resize = debounce(() => {
        calculateWidth();
    }, 300);
    useEffect(() => {
        calculateWidth();
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculateWidth();
    }, [calculateWidth, rows]);

    const widthToPercent = elWidth =>
        `${(elWidth / rowRef?.current?.clientWidth) * 100}%`;

    return (
        <Branding.Consumer>
            {colors => (
                <div className={classNames('table-wrap', {isList})}>
                    {!isList && (
                        <TableHeader
                            widthToPercent={widthToPercent}
                            columns={columns}
                            sort={sort}
                            multiSort={multiSort}
                            cellWidths={cellWidths}
                            onSortChange={onSortChange}
                            useFixed={useFixed}
                        />
                    )}
                    <div className="content">
                        {rows.length || loading ? (
                            <InfiniteScroll
                                isFetchingMore={loading}
                                onFetchMore={onTableEnd}
                                hasMore>
                                <table
                                    className={classNames('table', {
                                        'table-hover': onRowClick || [
                                            rows[0]?.onClick,
                                        ],
                                        'table-fixed': useFixed,
                                    })}>
                                    <tbody
                                        ref={ref => {
                                            tbody = ref;
                                        }}>
                                        {rows.map((row, index) => {
                                            if (!row) {
                                                return null;
                                            }
                                            return (
                                                <TableRow
                                                    widthToPercent={
                                                        widthToPercent
                                                    }
                                                    cellWidths={cellWidths}
                                                    rowRef={rowRef}
                                                    row={row}
                                                    key={row.key || index}
                                                    onClick={
                                                        row.onClick ||
                                                        onRowClick
                                                    }
                                                    active={index === active}
                                                    isList={isList}
                                                    columns={columns}
                                                    useFixed={useFixed}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        ) : (
                            <Empty title={emptyTitle} />
                        )}
                    </div>

                    <style jsx>
                        {`
                            @import '../../lib/sass/kit';
                            $table-padding: 3rem;

                            .table-wrap {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                height: ${height || '100%'};
                                box-shadow: ${theme.defaultShadow};
                                border-radius: ${theme.borderRadiusLarge}rem;
                                overflow: hidden;

                                :global(td),
                                :global(th) {
                                    padding: 1rem;
                                    vertical-align: middle;
                                    border: 0;
                                    width: auto;
                                }

                                :global(.empty) {
                                    min-height: 15rem;
                                }
                            }
                            .content {
                                flex: 1;
                                background: ${theme.colorWhite};
                                overflow: auto;
                            }
                            .table-fixed {
                                table-layout: fixed;
                            }
                            .table {
                                width: 100%;
                                border-collapse: collapse;
                                margin: 0;
                                padding: 0;
                                overflow: hidden;

                                :global(td) {
                                    border-bottom: solid 0.1rem
                                        ${theme.colorBorder};
                                }

                                :global(td).table__no-items {
                                    text-align: center;
                                    padding: 5rem 0;
                                    font-size: 1.8rem;
                                }

                                & &__icon {
                                    padding: 1rem 2rem 1rem 0;
                                    border: 0;
                                    width: 6rem;

                                    & + :global(td),
                                    & + :global(th) {
                                        padding-left: 0;
                                    }
                                }

                                a {
                                    color: $text-color;
                                }

                                :global(.icon) {
                                    color: ${colors.brand[900]};
                                    font-size: 3rem;
                                    min-height: 4.1rem;
                                }
                                :global(.icon:hover) {
                                    color: $brand;
                                }
                                :global(.icon.danger) {
                                    transition: $default-transition color;
                                }
                                :global(.icon.danger:hover) {
                                    color: $danger-color;
                                }
                                &__remove {
                                    color: $danger-color;
                                    font-size: 1.6rem;
                                    transition: $default-transition color;

                                    :global(&:hover) {
                                        color: $danger-color-dark;
                                    }
                                }
                            }

                            :global(.table-hover tbody tr:hover) {
                                background: ${theme.colors.grey[1]};
                                transition: 0.2s ease-out;
                                scale: 1.01;
                            }
                            :global(.table-hover tr:hover td) {
                                background: ${theme.colors.grey[1]};
                                transition: 0.2s ease-out;
                            }
                        `}
                    </style>
                </div>
            )}
        </Branding.Consumer>
    );
};

Table.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.any).isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    onTableEnd: PropTypes.func,
    onRowClick: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    emptyTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    sort: PropTypes.string,
    multiSort: PropTypes.bool,
    height: PropTypes.number,
    onSortChange: PropTypes.func,
    list: PropTypes.bool.isRequired,
    active: PropTypes.number.isRequired,
    useFixed: PropTypes.bool,
};

Table.defaultProps = {
    onTableEnd: undefined,
    onRowClick: undefined,
    sort: undefined,
    multiSort: false,
    height: undefined,
    onSortChange: undefined,
    useFixed: false,
};

export default Table;
