import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import PeriodChart from 'components/PeriodChart';
import * as colors from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import AmountList from 'components/AmountList';
import User from 'components/User';
import CustomBarChart from '../CustomBarChart/CustomBarChart';

const UsersActivity = ({
    dateOptions,
    activePeriod,
    isLoading,
    mostActiveUser,
    selectedDate,
    isLoadingDetail,
    chartData,
    onPeriodChange,
}) => {
    let list = [
        {
            key: 'not_active',
            label: <Translate id="dashboard.not_active" />,
            color: '#773AD5',
            info: <Translate id="dashboard.not_active_info" />,
        },
        {
            key: 'active',
            label: <Translate id="dashboard.active" />,
            color: '#1FE192',
            info: <Translate id="dashboard.active_info" />,
        },
        {
            key: 'weekly_active',
            label: <Translate id="dashboard.weekly_active" />,
            color: '#007CFF',
            info: <Translate id="dashboard.weekly_active_info" />,
        },
        {
            key: 'daily_active',
            label: <Translate id="dashboard.daily_active" />,
            color: '#0EC5D2',
            info: <Translate id="dashboard.daily_active_info" />,
        },
    ];

    list = list.map(listItem => ({
        ...listItem,
        amount: selectedDate && Math.round(selectedDate[listItem.key]),
    }));

    return (
        <PeriodChart
            dateOptions={dateOptions}
            activePeriod={activePeriod}
            onPeriodChange={onPeriodChange}
            sidebar={
                <LoadingWrapper loading={isLoading}>
                    {mostActiveUser && (
                        <div className="mostActiveUser">
                            <User
                                name={`${mostActiveUser && mostActiveUser.first_name && mostActiveUser.first_name[0]}. ${mostActiveUser.last_name}`}
                                title={<Translate id="dashboard.most_active" />}
                                big
                                border
                                image={getUrlBySize(
                                    mostActiveUser.avatar,
                                    THUMB,
                                )}
                            />
                        </div>
                    )}

                    <AmountList list={list} />
                </LoadingWrapper>
            }>
            <LoadingWrapper loading={isLoadingDetail}>
                <CustomBarChart
                    color={colors.chart.blue}
                    data={chartData}
                    activePeriod={activePeriod}
                    onPeriodChange={onPeriodChange}
                    stack={list}
                    height={500}
                />
            </LoadingWrapper>

            <style jsx>
                {`
                    .mostActiveUser {
                        padding: 2rem 0;

                        :global(.user__name),
                        :global(.user__title) {
                            color: ${colors.brandingDefaults.pureWhite};
                        }
                    }
                `}
            </style>
        </PeriodChart>
    );
};
UsersActivity.propTypes = {
    dateOptions: PropTypes.arrayOf(PropTypes.shape({})),
    activePeriod: PropTypes.string,
    isLoading: PropTypes.bool,
    mostActiveUser: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        avatar: PropTypes.string,
    }),
    selectedDate: PropTypes.shape({}),
    isLoadingDetail: PropTypes.bool,
    chartData: PropTypes.arrayOf(PropTypes.any),
    onPeriodChange: PropTypes.func,
};
UsersActivity.defaultProps = {
    dateOptions: [],
    activePeriod: '',
    isLoading: false,
    mostActiveUser: {},
    selectedDate: {},
    isLoadingDetail: false,
    chartData: [],
    onPeriodChange: () => {},
};

export default UsersActivity;
