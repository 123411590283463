import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M12 19V12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.83301 14L12 11.833L14.167 14"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 19H18.56C20.488 19 22.06 17.428 22.06 15.5C22.06 13.572 20.488 12 18.56 12H18.125V11C18.125 7.69 15.435 5 12.125 5C9.148 5 6.68 7.178 6.212 10.023C3.835 10.144 1.94 12.093 1.94 14.5C1.94 15.0909 2.0564 15.6761 2.28254 16.2221C2.50869 16.768 2.84016 17.2641 3.25802 17.682C4.10194 18.5259 5.24653 19 6.44 19H8"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
