import React from 'react';
import {Translate} from 'react-localize-redux';
import Modal from 'modals/Modal/ModalWithState';
import AdminMessageReadsWithState from './AdminMessageReadsWithState';

const AdminMessageReadsModal = () => (
    <Modal
        name="adminMessageReadsModal"
        noPadding
        menu={[{label: <Translate id="dashboard.admin_message_reads" />}]}>
        <AdminMessageReadsWithState />
    </Modal>
);

export default AdminMessageReadsModal;
