import React from 'react';

const AddFilter = () => (
    <g stroke="currentColor" fill="none">
        <path d="M23 3H3L11 12.46V19L15 21V12.46L23 3Z" />
        <path d="M4 13V19" />
        <path d="M1 16H7" />
    </g>
);
export default AddFilter;
