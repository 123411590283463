import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import Select from 'components/util/Select';
import EndAfter from './EndAfter';
import EndOnDate from './EndOnDate';

const End = ({end: {mode, after, onDate}, handleChange, translate}) => {
    const isOptionSelected = option => mode === option;

    return (
        <div className="react-rule__block">
            <div className="form-group">
                <label htmlFor="End" className="label">
                    {translate('editor.recurrence.end')}
                </label>
                <Select
                    name="end.mode"
                    id="End"
                    value={mode}
                    onChange={handleChange}
                    options={[
                        {
                            value: 'Never',
                            label: translate('editor.recurrence.never'),
                        },
                        {
                            value: 'After',
                            label: translate('editor.recurrence.after'),
                        },
                        {
                            value: 'On date',
                            label: translate('editor.recurrence.on_date'),
                        },
                    ]}
                />
            </div>

            <div className="form-group">
                {isOptionSelected('After') && (
                    <EndAfter after={after} handleChange={handleChange} />
                )}
                {isOptionSelected('On date') && (
                    <EndOnDate onDate={onDate} handleChange={handleChange} />
                )}
            </div>
        </div>
    );
};

End.propTypes = {
    end: PropTypes.shape({
        mode: PropTypes.string.isRequired,
        after: PropTypes.number.isRequired,
        onDate: PropTypes.object.isRequired,
        options: PropTypes.shape({
            modes: PropTypes.arrayOf(
                PropTypes.oneOf(['Never', 'After', 'On date']),
            ),
            weekStartsOnSunday: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(End);
