import React from 'react';
import Observer from '@researchgate/react-intersection-observer';

const ScrollSentinel = ({onIntersect}) => (
    <Observer
        onChange={e => {
            if (e.isIntersecting) {
                onIntersect();
            }
        }}>
        <div className="sentinel">
            <style jsx>
                {`
                    .sentinel {
                        position: absolute;
                        z-index: -1;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100px;
                    }
                `}
            </style>
        </div>
    </Observer>
);
export default ScrollSentinel;
