import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M12 12V7.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.625 15.877C11.6254 15.9511 11.6477 16.0234 11.6892 16.0848C11.7306 16.1462 11.7893 16.1939 11.8579 16.222C11.9264 16.2501 12.0018 16.2572 12.0744 16.2425C12.147 16.2279 12.2136 16.192 12.2659 16.1395C12.3181 16.0869 12.3536 16.0201 12.3679 15.9474C12.3822 15.8747 12.3747 15.7994 12.3463 15.731C12.3178 15.6626 12.2698 15.6042 12.2081 15.5631C12.1465 15.5219 12.0741 15.5 12 15.5C11.9004 15.5005 11.805 15.5405 11.7347 15.6111C11.6644 15.6818 11.625 15.7774 11.625 15.877"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 14.381V9.619C3 8.83183 3.20649 8.05843 3.59885 7.37601C3.9912 6.69359 4.55571 6.12604 5.236 5.73L9.736 3.111C10.4233 2.71111 11.2043 2.50045 11.9995 2.50045C12.7947 2.50045 13.5757 2.71111 14.263 3.111L18.763 5.73C19.4435 6.12591 20.0082 6.69341 20.4007 7.37584C20.7933 8.05826 20.9999 8.83173 21 9.619V14.381C21 15.1682 20.7935 15.9416 20.4012 16.624C20.0088 17.3064 19.4443 17.874 18.764 18.27L14.264 20.889C13.5767 21.2889 12.7957 21.4996 12.0005 21.4996C11.2053 21.4996 10.4243 21.2889 9.737 20.889L5.237 18.27C4.55652 17.8741 3.99182 17.3066 3.59928 16.6242C3.20674 15.9417 3.0001 15.1683 3 14.381V14.381Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
