import React from 'react';
import classNames from 'classnames';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'lib/styles/theme';

export default ({interactive, isActive, children, onClick}) => (
    <Branding.Consumer>
        {colors => (
            <button
                className={classNames('yes-no-button', {interactive, isActive})}
                onClick={onClick}
                type="button">
                {children}
                <style jsx>
                    {`
                        .yes-no-button {
                            border-radius: 50%;
                            width: 6rem;
                            height: 6rem;
                            font-weight: ${theme.fwBold};
                            text-transform: uppercase;
                            text-align: center;
                            margin-right: 2rem;
                            border: 0.2rem solid ${colors.grey[400]};
                            color: ${colors.grey[400]};
                            transition:
                                ${theme.defaultTransition} border,
                                ${theme.defaultTransition} color;

                            &:focus {
                                outline: none;
                            }
                        }

                        .interactive:hover,
                        .interactive.isActive {
                            border: 0.2rem solid ${colors.brand.base};
                            color: ${colors.brand.base};
                        }
                    `}
                </style>
            </button>
        )}
    </Branding.Consumer>
);
