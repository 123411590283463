import React from 'react';

export default function () {
    return (
        <>
            <path
                d="M8.05734 1.48861C7.57774 1.72751 7.19405 2.39642 7.19405 2.87421C7.19405 3.87758 6.043 4.16425 5.51544 3.35201C5.03584 2.58754 3.16538 2.77865 2.3021 3.68646C1.87045 4.16425 1.15105 4.83317 0.767366 5.16762C0.143881 5.69319 0 7.03101 0 12.9557C0 17.0169 0.239802 20.5526 0.479603 21.0781C0.959207 21.9382 1.58269 21.9859 11.7503 21.9859C25.0353 21.9859 23.9802 22.7504 23.9802 13.0034C23.9802 6.31432 23.9322 6.12321 22.7812 4.9765C21.774 3.97314 21.1985 3.78202 19.328 3.87758C17.3616 4.02092 17.0739 3.87758 16.6902 2.87421C16.4504 2.25308 16.0188 1.53639 15.683 1.34527C14.8197 0.819701 9.01655 0.91526 8.05734 1.48861ZM4.79603 3.8298C4.79603 4.0687 4.46031 4.30759 4.07663 4.30759C3.69295 4.30759 3.35722 4.0687 3.35722 3.8298C3.35722 3.54312 3.69295 3.35201 4.07663 3.35201C4.46031 3.35201 4.79603 3.54312 4.79603 3.8298ZM14.8677 7.93882C17.5055 9.51554 18.5607 13.4812 17.0739 16.1569C15.6351 18.8325 11.9421 19.8359 9.11247 18.4025C6.37873 16.9691 5.22768 12.4779 7.00221 9.80221C8.63286 7.26991 12.2778 6.45766 14.8677 7.93882ZM20.1433 7.89104C20.1433 8.60773 18.0811 8.46439 17.8413 7.7477C17.6974 7.26991 18.0331 7.12657 18.8964 7.26991C19.6158 7.36547 20.1433 7.60437 20.1433 7.89104Z"
                fill="currentColor"
            />
            <path
                d="M8.53694 9.51554C6.23485 11.5701 6.23485 14.7235 8.53694 16.778C11.3186 19.2625 15.1555 18.4025 16.6422 14.9624C18.7045 9.99333 12.5656 5.93209 8.53694 9.51554ZM15.2514 10.3278C16.6902 11.9523 16.6422 14.389 15.1555 16.1091C12.6615 19.0236 7.67366 17.1125 7.67366 13.2423C7.67366 9.08553 12.5177 7.22213 15.2514 10.3278Z"
                fill="currentColor"
            />
            <path
                d="M14.0044 10.7578C14.4361 11.2834 14.8677 12.4779 15.0116 13.3857L15.2034 15.0579L15.2514 13.3379C15.3473 12.0956 15.0596 11.3789 14.2922 10.71C13.1891 9.80221 13.1891 9.80221 14.0044 10.7578Z"
                fill="currentColor"
            />
        </>
    );
}
