import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M19.5001 19.2857L14.7421 14.5277"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M10.5001 16.2857C13.8138 16.2857 16.5001 13.5994 16.5001 10.2857C16.5001 6.97201 13.8138 4.28572 10.5001 4.28572C7.18641 4.28572 4.50012 6.97201 4.50012 10.2857C4.50012 13.5994 7.18641 16.2857 10.5001 16.2857Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </g>
    );
}
