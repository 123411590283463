import React from 'react';
import {connect} from 'react-redux';
import actions from 'store/actions';
import MessageSelectWithData from './MessageSelectWithData';

const MessageSelectWithRedux = ({...props}) => (
    <MessageSelectWithData {...props} />
);

const mapStateToProps = state => ({
    messages: state.messages.byId,
    messagePages: state.messages.paginatedSearchMessages.pages,
    trigger: state.editor.trigger,
});

const mapDispatchToProps = dispatch => ({
    updateTrigger: trigger => dispatch(actions.editor.setTrigger(trigger)),
    fetchMessage: id => dispatch(actions.messages.fetchMessage(id)),
    fetchMessages: params =>
        dispatch(actions.messages.fetchMessages(params, 'search')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessageSelectWithRedux);
