import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M8.251 21.502C4.804 21.502 2 18.698 2 15.251C2 11.804 4.804 9 8.251 9C11.698 9 14.502 11.804 14.502 15.251C14.502 18.698 11.698 21.502 8.251 21.502V21.502Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.749 21.502C12.302 21.502 9.49805 18.698 9.49805 15.251C9.49805 11.804 12.302 9 15.749 9C19.196 9 22 11.804 22 15.251C22 18.698 19.196 21.502 15.749 21.502V21.502Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.01 15C8.56303 15 5.75903 12.196 5.75903 8.749C5.75903 5.302 8.56303 2.498 12.01 2.498C15.457 2.498 18.261 5.302 18.261 8.749C18.261 12.196 15.457 15 12.01 15Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
