import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, {useTheme} from 'styled-components';
import Button from 'lib/components/Button';
import {getLangByCode} from 'lib/helpers/locale';
import {Translate} from 'react-localize-redux';

const EditorHeader = ({
    title,
    canEdit,
    editorRef,
    onTitleChange,
    onShareMessage,
    shareUrl,
    translate,
    currentLanguage,
}) => {
    const theme = useTheme();
    const showShareButton =
        typeof shareUrl !== 'undefined' && shareUrl !== null;

    return (
        <div className="l-editor__content__header">
            <Language>
                <Translate id="global.language" />:{' '}
                {getLangByCode(currentLanguage)}
            </Language>
            <Container>
                {canEdit ? (
                    <TextareaAutosize
                        className="l-editor title"
                        type="text"
                        placeholder={translate('global.title')}
                        value={title}
                        onChange={e => {
                            onTitleChange(e.target.value);
                        }}
                        spellCheck={false}
                        onKeyPress={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                editorRef.current.editor.focus();
                            }
                        }}
                    />
                ) : (
                    <h1 className="l-editor title">{title}</h1>
                )}
                {showShareButton && (
                    <Button
                        onClick={onShareMessage}
                        small
                        ghost
                        style={{
                            border: 'none',
                            color: theme.colors.primary,
                        }}>
                        <Translate id="global.share" />
                    </Button>
                )}
            </Container>
        </div>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Language = styled.span(
    ({theme}) => `
    display: block;
    margin-bottom: ${theme.spacing}rem;
    color: ${theme.colors.grey[4]};
`,
);

export default EditorHeader;
