import {OR, NOT} from 'util/constants';
import {USERS} from 'components/util/AudienceSelect/AudienceSelectWithData';

export const addSegment = (segments, segment, segmentGroupId = null) => {
    const newSegments = [...segments];
    if (segmentGroupId !== null) {
        const grouped = newSegments[segmentGroupId].grouped_values || [];
        newSegments[segmentGroupId].grouped_values = [...grouped, segment];
    } else {
        newSegments.push(segment);
    }

    return newSegments;
};

export const updateSegment = (
    segments,
    segment,
    segmentGroupId,
    segmentId = null,
) => {
    const newSegments = [...segments];
    if (segmentId !== null) {
        const prevValue = newSegments[segmentGroupId].grouped_values[segmentId];
        newSegments[segmentGroupId].grouped_values[segmentId] = {
            ...prevValue,
            ...segment,
        };
    } else {
        newSegments[segmentGroupId] = {
            ...newSegments[segmentGroupId],
            ...segment,
        };
    }

    return newSegments;
};

export const deleteSegment = (segments, segmentGroupId, segmentId = null) => {
    const newSegments = [...segments];
    if (segmentId !== null) {
        newSegments[segmentGroupId].grouped_values.splice(segmentId, 1);
    } else {
        newSegments.splice(segmentGroupId, 1);
    }

    return newSegments;
};

export const findAddedUsers = segment =>
    segment.filter === USERS && segment.type === OR;

export const findRemovedUsers = segment =>
    segment.filter === USERS && segment.type === NOT;
