import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'lib/components/InfiniteScroll';
import Empty from 'components/util/Empty';

const List = ({
    rows,
    columns,
    isFetching,
    onRowClick,
    onTableEnd = () => {},
    loading,
    widths = [],
    emptyTitle = '',
}) => (
    <RoundedBox>
        <ListContainer
            isFetchingMore={loading}
            onFetchMore={onTableEnd}
            hasMore>
            {rows.length || isFetching ? (
                <StyledList e>
                    {rows.map(row => (
                        <Row key={row.key} onClick={() => onRowClick(row.key)}>
                            {(row.data || row).map((rowData, index) => {
                                const {
                                    key: key2,
                                    data,
                                    content,
                                } = typeof rowData === 'object'
                                    ? rowData
                                    : {
                                          content: rowData,
                                          data: rowData,
                                          key: index,
                                      };
                                const width = widths[index] || '100%';
                                const renderer =
                                    columns[index]?.renderer ||
                                    (v => <span>{v}</span>);
                                return (
                                    <RowItem key={key2 || index} width={width}>
                                        {renderer(content, data)}
                                    </RowItem>
                                );
                            })}
                        </Row>
                    ))}
                </StyledList>
            ) : (
                <Empty title={emptyTitle} />
            )}
        </ListContainer>
    </RoundedBox>
);
export const Row = styled.div`
    padding-left: 3rem;
    gap: 1rem;
    height: 5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    flex-direction: row;
    ${props =>
        props.onClick &&
        `cursor: pointer;&:hover{background-color: ${props.theme.colors.grey[2]};transform: scale(1.01);}`}
    border-bottom: 0.1rem solid ${props => props.theme.colors.grey[1]};
    justify-content: space-between;
    transition: 0.25s ease all;
`;
export const RowItem = styled.div`
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    ${({width}) => width && `width: ${width};`}
`;

export const StyledList = styled.div`
    ${({theme}) =>
        `
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: fit-content;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius}rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.1rem 0.3rem;
    .icon {
        color: ${theme.brand[900]};
        font-size: 2.6rem;
        height: 2.6rem;
        line-height: 1;
        min-height: auto;
        width: 2.6rem;
    }
`}
`;
export const ListContainer = styled(InfiniteScroll)`
    width: 100%;
    height: 100%;
    display: flex;
    min-height: inherit;
    overflow: auto;
    border-radius: ${({theme}) => theme.borderRadius}rem;
`;
// This container is used to round the corners of the list, can not use container due to overflow that happens there
const RoundedBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    min-height: inherit;
    overflow: hidden;
    border-radius: ${({theme}) => theme.borderRadius}rem;
    transform: scale(1.02);
    > * {
        transform: scale(0.98);
    }
`;
List.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.number,
                    content: PropTypes.string,
                }),
            ),
        }),
    ),
    columns: PropTypes.arrayOf(PropTypes.shape({renderer: PropTypes.func})),
    emptyTitle: PropTypes.string,
    isFetching: PropTypes.bool,
    onRowClick: PropTypes.func,
    onTableEnd: PropTypes.func,
    loading: PropTypes.bool,
    widths: PropTypes.arrayOf(PropTypes.string),
};
List.defaultProps = {
    rows: [],
    columns: [],
    isFetching: false,
    onRowClick: () => {},
    onTableEnd: () => {},
    loading: false,
    widths: [],
    emptyTitle: '',
};
export default List;
