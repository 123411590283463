import React, {useState, useEffect} from 'react';
import useApi from 'components/hooks/useApi';
import Trigger from './Trigger';

const TriggerWithState = ({canEdit, onChange, trigger}) => {
    const [options, setOptions] = useState();

    // Fetch triggers on mount
    const [fetchTriggers, {loading, data: triggers}] = useApi(
        '/auto-messages/triggers',
    );
    useEffect(() => {
        fetchTriggers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Create options when triggers are updated
    useEffect(() => {
        if (triggers !== null) {
            setOptions(
                Object.values(
                    Object.values(triggers).reduce(
                        (
                            acc,
                            {
                                type,
                                translated_type: typeLabel,
                                name: value,
                                translated_name: label,
                            },
                        ) => ({
                            ...acc,
                            [type]: {
                                ...acc[type],
                                label: typeLabel,
                                options: [
                                    ...(acc[type] && acc[type].options
                                        ? acc[type].options
                                        : []),
                                    {value, label},
                                ],
                            },
                        }),
                        {},
                    ),
                ),
            );
        }
    }, [triggers]);

    return (
        <Trigger
            canEdit={canEdit}
            isFetching={loading}
            onChange={onChange}
            triggerValue={trigger}
            options={options}
            activeTrigger={triggers !== null ? triggers[trigger.name] : null}
        />
    );
};

export default TriggerWithState;
