import React from 'react';
import styled from 'styled-components';
import ImageWrapper from 'lib/components/Images/ImageWrapper';

const User = ({name, title, image, onClick = () => {}}) => (
    <Container onClick={onClick}>
        <Picture className="avatar">
            <ImageWrapper
                src={image}
                disableProgressiveLoading
                retryIfNotFound
            />
        </Picture>

        <Info className="user__info">
            <Name className="user__name">{name}</Name>
            <Title className="user__title">{title}</Title>
        </Info>
    </Container>
);

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Info = styled.div(
    ({theme}) => `
    flex: 1;
    font-size: ${theme.fontSizeSmall}rem;
`,
);

const Picture = styled.div(
    ({theme}) => `
    margin-right: ${theme.spacingSmaller}rem;
`,
);

const Name = styled.span(
    ({theme}) => `
    display: block;
    font-weight: 500;
    line-height: 1.7rem;
    margin-bottom: ${theme.spacingSmallest}rem;
`,
);

const Title = styled.span(
    ({theme}) => `
    font-size: ${theme.fontSizeSmall}rem;
    display: block;
    color: ${theme.colorTextGrey};
`,
);

export default User;
