import React from 'react';
import SidebarForm from 'components/layout/SidebarForm';
import Audience from 'components/EditorSidebar/Audience';
import EditorSidebarSendWhenWithState from './EditorSidebarSendWhenWithState';
import EditorSidebarInformationWithState from './EditorSidebarInformationWithState';
import EditorSidebarCommentToggleWithState from './EditorSidebarCommentToggleWithState';
import EditorSidebarSendRemindersWithState from './EditorSidebarSendRemindersWithState';
import EditorSidebarLanguageSelectWithState from './EditorSidebarLanguageSelectWithState';
import EditorSidebarAppDomainSelectWithState from './EditorSidebarAppDomainSelectWithState';
import EditorSidebarVisibilitySelectWithState from './EditorSidebarVisibilitySelectWithState';
import EditorSidebarAcceptAnswersToggleWithState from './EditorSidebarAcceptAnswersToggleWithState';
import MessageConfigurationSidebarButtonsWithState from './MessageConfigurationSidebarButtonsWithState';

const MessageConfigurationSidebar = ({canEdit, messageId}) => (
    <SidebarForm>
        <SidebarForm.Content>
            <EditorSidebarLanguageSelectWithState />
            <EditorSidebarInformationWithState />
            <EditorSidebarCommentToggleWithState canEdit={canEdit} />
            <EditorSidebarAcceptAnswersToggleWithState canEdit={canEdit} />
            <Audience canEdit={canEdit} messageId={messageId} />
            <EditorSidebarAppDomainSelectWithState canEdit={canEdit} />
            <EditorSidebarVisibilitySelectWithState canEdit={canEdit} />
            <EditorSidebarSendWhenWithState canEdit={canEdit} />
            <EditorSidebarSendRemindersWithState canEdit={canEdit} />
        </SidebarForm.Content>
        <SidebarForm.Actions>
            <MessageConfigurationSidebarButtonsWithState
                canEdit={canEdit}
                messageId={messageId}
            />
        </SidebarForm.Actions>
    </SidebarForm>
);

export default MessageConfigurationSidebar;
