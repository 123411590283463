import React from 'react';
import {Translate} from 'react-localize-redux';
import {Link} from 'react-router-dom';
import * as routes from 'routes/index';
import Button from 'components/util/Button';
import Header from './Header';

const ResetPassword = ({
    onSubmit,
    email,
    setEmail,
    values,
    setValues,
    errors = {},
}) => (
    <div className="l-login">
        <div className="l-login__container">
            <Header />
            <form onSubmit={onSubmit} className="form l-login__content">
                <div className="form__group">
                    <label className="label" htmlFor="email">
                        <Translate id="auth.email" />
                    </label>
                    <input
                        id="email"
                        type="email"
                        className="input"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="form__group">
                    <label className="label" htmlFor="password">
                        <Translate id="auth.password" />
                    </label>
                    <input
                        id="password"
                        type="password"
                        className="input"
                        name="password"
                        autoComplete="new-password"
                        value={values.password}
                        onChange={e => setValues({password: e.target.value})}
                    />
                </div>
                <div className="form__group">
                    <label className="label" htmlFor="passwordConfirm">
                        <Translate id="auth.password_confirm" />
                    </label>
                    <input
                        id="passwordConfirm"
                        type="password"
                        className="input"
                        name="passwordConfirm"
                        autoComplete="new-password"
                        value={values.passwordConfirm}
                        onChange={e =>
                            setValues({passwordConfirm: e.target.value})
                        }
                    />
                </div>
                <div className="form__group error-box">
                    {errors.password && (
                        <ul className="error-list">
                            {errors.password.map((error, index) => (
                                <li
                                    key={`error-item-${index}`}
                                    className="error-list-item">
                                    {error}
                                </li>
                            ))}
                        </ul>
                    )}
                    <style jsx>
                        {`
                            .error-box {
                                padding-left: 1rem;
                                padding-right: 1rem;
                            }
                            .error-list {
                                list-style-type: disc;
                                padding-left: 1.25rem;
                                color: #cd1010;
                            }
                            .error-list-item {
                                margin-top: 0.75rem;
                            }
                        `}
                    </style>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Link
                            to={`${routes.login()}?email=${email}`}
                            tabIndex={-1}
                            className="button transparent no-padding">
                            <Translate id="global.cancel" />
                        </Link>
                    </div>
                    <div className="col-6">
                        <Button
                            type="submit"
                            className="button success full-width">
                            <Translate id="global.send" />
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
);

export default ResetPassword;
