import React from 'react';
import subMonths from 'date-fns/sub_months';
import {getActiveLanguage, getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {
    getMonthData,
    parseOptions,
    LAST_30,
    parseChartData,
} from 'util/analytics';
import State from 'lib/components/State';
import ContentPopularity from './ContentPopularity';

const ContentPopularityWithRedux = ({
    popularity,
    isLoading,
    langCode,
    translate,
}) => (
    <State initialState={{activePeriod: LAST_30}}>
        {({state, setState}) => {
            let dateOptions;
            let selectedActivity;
            let lastMonthActivity;
            let articlesRead;
            let percentageRead = 0;
            let isMoreThanBefore = true;

            if (popularity) {
                dateOptions = parseOptions(
                    popularity,
                    getDateLocale(langCode),
                    () => translate('dashboard.last_30_days'),
                );

                selectedActivity = getMonthData(popularity, state.activePeriod);
                if (state.activePeriod !== LAST_30) {
                    lastMonthActivity = getMonthData(
                        popularity,
                        subMonths(state.activePeriod, 1),
                    );
                    if (selectedActivity && lastMonthActivity) {
                        const lastRead = parseInt(
                            lastMonthActivity.read || 0,
                            10,
                        );
                        const currentRead = parseInt(
                            selectedActivity.read || 0,
                            10,
                        );

                        if (currentRead && lastRead) {
                            percentageRead =
                                Math.round(
                                    Math.abs(
                                        ((currentRead - lastRead) / lastRead) *
                                            10000,
                                    ),
                                ) / 100;
                            isMoreThanBefore = currentRead > lastRead;
                        }
                    }
                }

                articlesRead = parseChartData(
                    popularity,
                    'read',
                    getDateLocale(langCode),
                    () => translate('dashboard.last_30_days_short'),
                );
            }

            return (
                <ContentPopularity
                    isLoadingSidebar={isLoading}
                    isLoadingContent={isLoading}
                    activePeriod={state.activePeriod}
                    dateOptions={dateOptions}
                    percentageRead={percentageRead}
                    isMoreThanBefore={isMoreThanBefore}
                    articlesRead={articlesRead}
                    onPeriodChange={newPeriod =>
                        setState({activePeriod: newPeriod})
                    }
                    selectedActivity={selectedActivity}
                    lastMonthActivity={lastMonthActivity}
                    langCode={langCode}
                />
            );
        }}
    </State>
);

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    isLoading: state.analytics.messages.isLoading,
    popularity: state.analytics.messages.popularity,
    langCode: getActiveLanguage(state.localize).code,
});

export default connect(mapStateToProps)(ContentPopularityWithRedux);
