import React from 'react';
import Icon from 'lib/components/Icon';
import Button from './ButtonHOC';

const VideoButton = props => (
    <Button {...props} type="media" subType="video">
        <Icon name="play" size="40" />
    </Button>
);

export default VideoButton;
