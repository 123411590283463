import {CALL_API} from '../../constants/api';
import {GET} from '../services';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const callApi = (endpoint, options) => ({
    [CALL_API]: {
        endpoint,
        method: GET,
        query: {},
        types: [REQUEST, SUCCESS, FAILURE],
        ...options,
    },
});

export default {
    callApi,
};
