import React from 'react';

import UserDetailBlock from './UserDetailBlock';

const PhoneMail = ({editable, onChange, user}) => (
    <div className="row">
        {/* Phone */}
        {editable && (
            <div className="col-md-6">
                <UserDetailBlock
                    label="phone"
                    value={user.phone || ''}
                    type="text"
                    isPrivate
                    editable
                    onChange={value => onChange({phone: value})}
                />
            </div>
        )}
        <div className="col-md-6">
            <UserDetailBlock
                label="phone"
                id="work_phone"
                value={user.work_phone || ''}
                type="text"
                isWork={editable}
                editable={editable}
                onChange={value => onChange({work_phone: value})}
            />
        </div>

        {/* Cellphone */}
        {editable && (
            <div className="col-md-6">
                <UserDetailBlock
                    label="mobile"
                    value={user.mobile || ''}
                    type="tel"
                    isPrivate
                    editable
                    onChange={value => onChange({mobile: value})}
                    dependency={[
                        'mobile',
                        'work_mobile',
                        'email',
                        'work_email',
                    ]}
                />
            </div>
        )}
        <div className="col-md-6">
            <UserDetailBlock
                label="mobile"
                id="work_mobile"
                value={user.work_mobile || ''}
                type="tel"
                isWork={editable}
                editable={editable}
                onChange={value => onChange({work_mobile: value})}
                dependency={['mobile', 'work_mobile', 'email', 'work_email']}
            />
        </div>

        {/* Mail */}
        {editable && (
            <div className="col-md-6">
                <UserDetailBlock
                    label="email"
                    value={user.email || ''}
                    type="email"
                    isPrivate
                    editable
                    onChange={value => onChange({email: value})}
                    dependency={[
                        'mobile',
                        'work_mobile',
                        'email',
                        'work_email',
                    ]}
                />
            </div>
        )}
        <div className={`col-md-${editable ? '6' : '12'}`}>
            <UserDetailBlock
                label="email"
                id="work_email"
                value={user.work_email || ''}
                type="email"
                isWork={editable}
                editable={editable}
                onChange={value => onChange({work_email: value})}
                dependency={['mobile', 'work_mobile', 'email', 'work_email']}
            />
        </div>
    </div>
);

export default PhoneMail;
