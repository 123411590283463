import React from 'react';
import {Translate} from 'react-localize-redux';

const TabMenu = ({menuKey, menu, onClick}) => (
    <ul className="tab-menu">
        {menu.map(item => (
            <li key={item.key} className="item">
                <span
                    className={`link ${item.active ? 'active' : ''}`}
                    onClick={() => onClick(menuKey, item.key)}>
                    <Translate id={`global.tabmenu.${item.label}`} />
                </span>
            </li>
        ))}

        <style jsx>
            {`
                @import '../../lib/sass/kit';

                .tab-menu {
                    border-bottom: solid $pure-white 0.2rem;
                    display: flex;
                    margin-bottom: 2.8rem;
                }

                .link {
                    display: block;
                    font-size: 1.6rem;
                    text-transform: uppercase;
                    margin: 0 2rem;
                    padding-top: 3rem;
                    padding-bottom: 2.3rem;
                    cursor: pointer;
                    color: $grey-400;

                    &.active {
                        font-weight: $fw-bold;
                        position: relative;
                        color: $brand;

                        &:after {
                            content: '';
                            position: absolute;
                            height: 0.4rem;
                            width: 100%;
                            left: 0;
                            bottom: -0.2rem;
                            background-color: $brand;
                        }
                    }
                }

                .item:first-child .link {
                    padding-left: 0.2rem;
                    margin-left: 0;
                }
            `}
        </style>
    </ul>
);

export default TabMenu;
