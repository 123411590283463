import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Translate} from 'react-localize-redux';
import {MODAL_AUDIENCE} from 'lib/store/actions/modals';
import Button from 'components/util/Button';
import AudienceResultWithData from 'components/util/AudienceResult/AudienceResultWithData';
import actions from 'store/actions';

const Audience = ({
    setModalVisibility,
    setModalData,
    audienceFilters,
    canEdit,
}) => {
    // TODO: move this outside (make stateless)
    const onEdit = () => {
        if (canEdit) {
            setModalVisibility(MODAL_AUDIENCE, true);
            setModalData(MODAL_AUDIENCE, {audienceFilters});
        }
    };

    return (
        <div className="form__group">
            <label className="label" htmlFor="default_audience">
                <Translate id="editor.form.audience.label" />
            </label>

            <AudienceWrap onClick={onEdit}>
                <AudienceResultWithData audienceFilters={audienceFilters} />
            </AudienceWrap>

            {canEdit && (
                <Button onClick={onEdit} warning fullWidth ghost>
                    <Translate id="editor.edit" />
                </Button>
            )}
        </div>
    );
};

const AudienceWrap = styled.div`
    display: flex;
    justify-content: center;
`;

const mapStateToProps = ({editor}) => ({
    audienceFilters: editor.audienceFilters,
});

const mapDispatchToProps = dispatch => ({
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setModalData: (modal, data) =>
        dispatch(actions.modals.setModalData(modal, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audience);
