import {
    SET_ACTIVE_MENU_ITEM,
    GET_ACTIVE_MENU_ITEM,
    STOCK,
    INTERNAL,
    MESSAGES,
    STREAM,
    SURVEY,
} from '../actions/tabMenu';

const INITIAL_STATE = {
    activeMenuItem: '',
    media: [
        {key: INTERNAL, label: 'internal', active: true},
        {key: STOCK, label: 'stock'},
    ],
    newMessage: [
        {key: MESSAGES, label: 'messages', active: true},
        {key: STREAM, label: 'stream'},
    ],
    survey: [{key: SURVEY, label: 'survey', active: true}],
};

export default (state = INITIAL_STATE, action) => {
    const newState = {};
    switch (action.type) {
        case SET_ACTIVE_MENU_ITEM:
            newState[action.payload.menuKey] = state[
                action.payload.menuKey
            ].map(item => {
                const newItem = item;
                newItem.active = item.key === action.payload.activeMenuItem;
                return newItem;
            });
            newState.activeMenuItem = action.payload.activeMenuItem;
            return Object.assign({}, state, newState);

        case GET_ACTIVE_MENU_ITEM: {
            const activeMenuItem = state[action.payload.menuKey].find(item => {
                if (item.active) {
                    return item;
                }
                return false;
            }).key;

            return Object.assign({}, state, {
                activeMenuItem,
            });
        }

        default:
            return state;
    }
};
