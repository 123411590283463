import * as Sentry from '@sentry/react';

export const scopeSentryUser = user => {
    const {id, email, first_name: firstName, last_name: lastName} = user;
    const userName = `${firstName} ${lastName}`;

    Sentry.configureScope(scope => {
        scope.setUser({id, email, userName});
    });
};

export const removeScopedSentryUser = () => {
    Sentry.configureScope(scope => {
        scope.setUser({});
    });
};
