import React from 'react';
import {Route} from 'react-router-dom';
import * as routes from 'routes/index';
import DashboardWithState from 'components/pages/dashboard/DashboardWithState';
import Planning from 'components/pages/planning/Planning';
import Drafts from 'components/pages/drafts/Drafts';
import AutomatedMessagesWithState from 'components/pages/automatedMessages/AutomatedMessagesWithState';
import SentMessages from 'components/pages/sentMessages/SentMessages';
import ArchivedMessagesWithState from 'components/pages/archivedMessages/ArchivedMessagesWithState';
import UsersWithState from 'components/pages/users/UsersWithState';
import UsersBulkEditWithState from 'components/pages/users-bulkEdit/UsersBulkEditWithState';
import Settings from 'components/pages/settings/Settings';
import SegmentationWithState from 'components/pages/segmentation/SegmentationWithState';
import DocumentsWithState from 'components/pages/documents/DocumentsWithState';
import Sidebar from 'components/util/Sidebar';
import Icons from 'components/pages/icons/Icons';
import AdminInboxPage from 'modules/AdminInbox/AdminInboxPage';
import {useSelector} from 'react-redux';
import AuthorizedRoute from './AuthorizedRoute';
import useRoles from 'components/hooks/useRoles';

const AppRoutes = ({isAuthenticated, userRole}) => {
    const hasAdminInboxEnabled = useSelector(
        state => state.company.data?.settings?.admin_inbox?.enabled,
    );
    const {isAdmin, isSuperUser} = useRoles();
    return (
        <div className="main">
            <Sidebar className="sidebar" />

            <>
                <AuthorizedRoute
                    isAuthenticated={isAuthenticated}
                    userRole={userRole}
                    path={routes.drafts()}
                    component={Drafts}
                />

                {/* Routes for admin only */}
                {isAdmin && (
                    <>
                        <AuthorizedRoute
                            exact
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.home()}
                            component={DashboardWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.planning()}
                            component={Planning}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.automatedMessages()}
                            component={AutomatedMessagesWithState}
                        />

                        <AuthorizedRoute
                            exact
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.users()}
                            component={UsersWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.bulkEditUsers()}
                            component={UsersBulkEditWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.settings()}
                            component={Settings}
                        />

                        <AuthorizedRoute
                            exact
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.segmentation()}
                            component={SegmentationWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.segmentationGroup()}
                            component={SegmentationWithState}
                        />

                        <AuthorizedRoute
                            exact
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.documents()}
                            component={DocumentsWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.documentsSubdirectory()}
                            component={DocumentsWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.sentMessages()}
                            component={SentMessages}
                        />
                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.archivedMessages()}
                            component={ArchivedMessagesWithState}
                        />

                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path="/admin-inbox/:inboxMessageId"
                            component={AdminInboxPage}
                            exact
                        />
                        {hasAdminInboxEnabled && (
                            <AuthorizedRoute
                                isAuthenticated={isAuthenticated}
                                userRole={userRole}
                                path={routes.adminInbox()}
                                exact
                                component={AdminInboxPage}
                            />
                        )}
                    </>
                )}

                {isSuperUser && (
                    <>
                        <AuthorizedRoute
                            exact
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.home()}
                            component={Drafts}
                        />
                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.planning()}
                            component={Planning}
                        />
                        <AuthorizedRoute
                            isAuthenticated={isAuthenticated}
                            userRole={userRole}
                            path={routes.sentMessages()}
                            component={SentMessages}
                        />
                    </>
                )}

                <Route path="/icons">
                    <Icons />
                </Route>
            </>

            <style jsx>
                {`
                    .main {
                        height: 100%;
                        height: 100vh;
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                    }
                `}
            </style>
        </div>
    );
};

export default AppRoutes;
