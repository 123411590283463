import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {GET, POST, PUT, DELETE} from '../../services';

export const MESSAGE_SCHEMA = new schema.Entity('message');

export const AUDIENCE_VALUE_TRIGGER_USER = '@trigger.user';
export const AUDIENCE_TYPE_TRIGGER_USER = '@trigger.user';
export const AUDIENCE_VALUE_COMPANY = '@company';
export const AUDIENCE_TYPE_COMPANY = '@company';
export const AUDIENCE_TYPE_DEPARTMENTS = 'departments';
export const AUDIENCE_TYPE_FUNCTIONS = 'functions';
export const AUDIENCE_TYPE_USERS = 'users';
export const AUDIENCE_TYPE_LOCATIONS = 'locations';

export const STATUS_NEW = 'new';
export const STATUS_PROPOSAL = 'proposal';
export const STATUS_DRAFT = 'draft';
export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_PUBLISHING = 'publishing';
export const STATUS_PUBLISHED = 'published';

export const VISIBILITY_PRIVATE = 'private';
export const VISIBILITY_PUBLIC = 'public';

export const MESSAGES_LIST_REQUEST = 'MESSAGES_LIST_REQUEST';
export const MESSAGES_LIST_SUCCESS = 'MESSAGES_LIST_SUCCESS';
export const MESSAGES_LIST_FAILURE = 'MESSAGES_LIST_FAILURE';

export const DRAFT_SUB_TYPE_OWN = 'own';
export const DRAFT_SUB_TYPE_TEAM = 'team';
export const DRAFT_SUB_TYPE_SUPER_USERS = 'super-users';
export const DRAFT_SUB_TYPE_INTEGRATIONS = 'integrations';

export const MESSAGES_SUB_TYPE_OWN = 'own';
export const MESSAGES_SUB_TYPE_ASSIGNED = 'assigned';

export const fetchMessages = (
    {
        page,
        limit,
        filters = {},
        filters: {
            query,
            from,
            to,
            status,
            sort,
            acceptAnswers,
            hasSurveys,
            withSurveyStats,
            isArchived,
            type,
            subType,
            appDomain,
        } = {},
    },
    listType = null,
    endpoint = '/messages',
) => ({
    [CALL_API]: {
        endpoint,
        method: GET,
        query: {
            query,
            page,
            limit,
            from,
            to,
            status,
            type,
            has_surveys: hasSurveys,
            accept_answers: acceptAnswers,
            with_survey_stats: withSurveyStats,
            sort,
            sub_type: subType,
            is_archived: isArchived,
            app_domain: appDomain,
        },
        filters,
        types: [
            MESSAGES_LIST_REQUEST,
            MESSAGES_LIST_SUCCESS,
            MESSAGES_LIST_FAILURE,
        ],
        listType,
        schema: [MESSAGE_SCHEMA],
    },
});

export const fetchDrafts = (params, listType) =>
    fetchMessages(params, listType, '/messages/drafts');

export const fetchCalendarMessages = (params, listType) =>
    fetchMessages(params, listType, '/messages/calendar');

export const MESSAGES_VIEW_REQUEST = 'MESSAGES_VIEW_REQUEST';
export const MESSAGES_VIEW_SUCCESS = 'MESSAGES_VIEW_SUCCESS';
export const MESSAGES_VIEW_FAILURE = 'MESSAGE_VIEW_FAILURE';

export const fetchMessage = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}`,
        method: GET,
        types: [
            MESSAGES_VIEW_REQUEST,
            MESSAGES_VIEW_SUCCESS,
            MESSAGES_VIEW_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
    },
});

export const MESSAGES_CREATE_REQUEST = 'MESSAGES_CREATE_REQUEST';
export const MESSAGES_CREATE_SUCCESS = 'MESSAGES_CREATE_SUCCESS';
export const MESSAGES_CREATE_FAILURE = 'MESSAGES_CREATE_FAILURE';

export const createMessage = message => ({
    [CALL_API]: {
        endpoint: '/messages',
        method: POST,
        body: message,
        types: [
            MESSAGES_CREATE_REQUEST,
            MESSAGES_CREATE_SUCCESS,
            MESSAGES_CREATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.message_created',
            },
        },
    },
});

export const MESSAGES_UPDATE_REQUEST = 'MESSAGES_UPDATE_REQUEST';
export const MESSAGES_UPDATE_SUCCESS = 'MESSAGES_UPDATE_SUCCESS';
export const MESSAGES_UPDATE_FAILURE = 'MESSAGES_UPDATE_FAILURE';

export const updateMessage = message => ({
    [CALL_API]: {
        endpoint: `/messages/${message.id}`,
        method: PUT,
        body: message,
        types: [
            MESSAGES_UPDATE_REQUEST,
            MESSAGES_UPDATE_SUCCESS,
            MESSAGES_UPDATE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
    },
});

export const MESSAGES_DELETE_REQUEST = 'MESSAGES_DELETE_REQUEST';
export const MESSAGES_DELETE_SUCCESS = 'MESSAGES_DELETE_SUCCESS';
export const MESSAGES_DELETE_FAILURE = 'MESSAGES_DELETE_FAILURE';

export const deleteMessage = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}`,
        method: DELETE,
        types: [
            MESSAGES_DELETE_REQUEST,
            MESSAGES_DELETE_SUCCESS,
            MESSAGES_DELETE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.message_archived',
            },
        },
    },
});

export const MESSAGES_RESTORE_REQUEST = 'MESSAGES_RESTORE_REQUEST';
export const MESSAGES_RESTORE_SUCCESS = 'MESSAGES_RESTORE_SUCCESS';
export const MESSAGES_RESTORE_FAILURE = 'MESSAGES_RESTORE_FAILURE';

export const restoreMessage = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/restore`,
        method: POST,
        types: [
            MESSAGES_RESTORE_REQUEST,
            MESSAGES_RESTORE_SUCCESS,
            MESSAGES_RESTORE_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.message_restored',
            },
        },
    },
});

export const MESSAGES_PUBLISH_REQUEST = 'MESSAGES_PUBLISH_REQUEST';
export const MESSAGES_PUBLISH_SUCCESS = 'MESSAGES_PUBLISH_SUCCESS';
export const MESSAGES_PUBLISH_FAILURE = 'MESSAGES_PUBLISH_FAILURE';

export const publishMessage = message => ({
    [CALL_API]: {
        endpoint: `/messages/${message.id}/publish`,
        method: POST,
        body: message,
        types: [
            MESSAGES_PUBLISH_REQUEST,
            MESSAGES_PUBLISH_SUCCESS,
            MESSAGES_PUBLISH_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: message.scheduled_at
                    ? 'notifications.message_scheduled'
                    : 'notifications.message_sent',
            },
        },
    },
});

export const MESSAGES_COPY_REQUEST = 'MESSAGES_COPY_REQUEST';
export const MESSAGES_COPY_SUCCESS = 'MESSAGES_COPY_SUCCESS';
export const MESSAGES_COPY_FAILURE = 'MESSAGES_COPY_FAILURE';

export const copyMessage = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/copy`,
        method: POST,
        types: [
            MESSAGES_COPY_REQUEST,
            MESSAGES_COPY_SUCCESS,
            MESSAGES_COPY_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.message_copied',
            },
        },
    },
});

export const MESSAGES_SUBMIT_TO_ADMIN_REQUEST =
    'MESSAGES_SUBMIT_TO_ADMIN_REQUEST';
export const MESSAGES_SUBMIT_TO_ADMIN_SUCCESS =
    'MESSAGES_SUBMIT_TO_ADMIN_SUCCESS';
export const MESSAGES_SUBMIT_TO_ADMIN_FAILURE =
    'MESSAGES_SUBMIT_TO_ADMIN_FAILURE';

export const sendMessageToAdmin = message => ({
    [CALL_API]: {
        endpoint: `/messages/${message.id}/send-to-admin`,
        method: POST,
        body: message,
        types: [
            MESSAGES_SUBMIT_TO_ADMIN_REQUEST,
            MESSAGES_SUBMIT_TO_ADMIN_SUCCESS,
            MESSAGES_SUBMIT_TO_ADMIN_FAILURE,
        ],
        schema: MESSAGE_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.message_submitted_to_admin',
            },
        },
    },
});

export const FETCH_SURVEY_EXPORT_REQUEST = 'FETCH_SURVEY_EXPORT_REQUEST';
export const FETCH_SURVEY_EXPORT_SUCCESS = 'FETCH_SURVEY_EXPORT_SUCCESS';
export const FETCH_SURVEY_EXPORT_FAILURE = 'FETCH_SURVEY_EXPORT_FAILURE';

export const fetchSurveyExport = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/surveys/export`,
        method: GET,
        types: [
            FETCH_SURVEY_EXPORT_REQUEST,
            FETCH_SURVEY_EXPORT_SUCCESS,
            FETCH_SURVEY_EXPORT_FAILURE,
        ],
    },
});

export const FETCH_FOLLOW_UP_STATS_REQUEST = 'FETCH_FOLLOW_UP_STATS_REQUEST';
export const FETCH_FOLLOW_UP_STATS_SUCCESS = 'FETCH_FOLLOW_UP_STATS_SUCCESS';
export const FETCH_FOLLOW_UP_STATS_FAILURE = 'FETCH_FOLLOW_UP_STATS_FAILURE';

export const fetchFollowUpStats = messageId => ({
    [CALL_API]: {
        endpoint: `/messages/${messageId}/follow-up-stats`,
        method: GET,
        types: [
            FETCH_FOLLOW_UP_STATS_REQUEST,
            FETCH_FOLLOW_UP_STATS_SUCCESS,
            FETCH_FOLLOW_UP_STATS_FAILURE,
        ],
    },
});

export default {
    fetchMessages,
    fetchDrafts,
    fetchCalendarMessages,
    fetchMessage,
    createMessage,
    updateMessage,
    deleteMessage,
    restoreMessage,
    publishMessage,
    copyMessage,
    sendMessageToAdmin,
    fetchSurveyExport,
    fetchFollowUpStats,
};
