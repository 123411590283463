import React from 'react';

const File = () => (
    <path
        fill="currentColor"
        transform="translate(4.000000, 0.000000)"
        d="M16.0064054,4.56307145 L12.3758636,0.656523545 C11.9864133,0.237465963 11.4584919,0 10.9089343,0 L2.07706801,0 C0.930353382,0.00465619535 0,1.0057382 0,2.23962997 L0,21.6094026 C0,22.8432944 0.930353382,23.8443764 2.07706801,23.8443764 L14.5394761,23.8443764 C15.6861907,23.8443764 16.6165441,22.8432944 16.6165441,21.6094026 L16.6165441,6.14617787 C16.6165441,5.55484106 16.3958556,4.98212903 16.0064054,4.56307145 Z M15.0284525,5.62002779 C15.1193243,5.7178079 15.1799054,5.83421278 15.210196,5.96458625 L11.0776961,5.96458625 L11.0776961,1.51791969 C11.1988584,1.55051305 11.307039,1.61569979 11.3979107,1.71347989 L15.0284525,5.62002779 Z M14.5394761,22.3543939 L2.07706801,22.3543939 C1.69627221,22.3543939 1.38471201,22.0191478 1.38471201,21.6094026 L1.38471201,2.23962997 C1.38471201,1.82988477 1.69627221,1.49463871 2.07706801,1.49463871 L9.69298407,1.49463871 L9.69298407,6.33708188 C9.69298407,6.95635586 10.1559971,7.45456876 10.7315181,7.45456876 L15.2318321,7.45456876 L15.2318321,21.6094026 C15.2318321,22.0191478 14.9202719,22.3543939 14.5394761,22.3543939 Z"
    />
);

export default File;
