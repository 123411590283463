import React from 'react';
import {Translate} from 'react-localize-redux';
import * as theme from 'styles/theme';
import styled from 'styled-components';

const Form = () => null;
const Red = styled.span`
    color: red;
`;
Form.LabelGroup = ({children, label, isRequired}) => (
    <LabelGroup className="labelGroup">
        <Label>
            {label} {isRequired && <Red>*</Red>}
        </Label>

        {children}
    </LabelGroup>
);

const LabelGroup = styled.label`
    margin-bottom: ${theme.spacingLarger}rem;
    width: 100%;
`;

const Label = styled.span`
    font-size: ${theme.fontSizeSmall}rem;
    font-weight: 500;
    display: block;
    margin-bottom: ${theme.spacing}rem;
`;

Form.Input = ({
    placeholder,
    placeholderKey,
    onChange,
    type = 'text',
    value,
    required,
}) => (
    <Translate>
        {({translate}) => (
            <input
                className="input"
                type={type}
                value={value}
                onChange={({target}) => onChange(target.value)}
                placeholder={
                    placeholderKey ? translate(placeholder) : placeholder
                }
                required={required}
            />
        )}
    </Translate>
);

export default Form;
