import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M5 20C5 18.8206 5.46852 17.6895 6.3025 16.8555C7.13647 16.0215 8.26758 15.553 9.447 15.553H14.553C15.7324 15.553 16.8635 16.0215 17.6975 16.8555C18.5315 17.6895 19 18.8206 19 20"
                stroke="currentColor"
                strokeWidth="1.482"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.005 5.245C15.4072 5.63794 15.7274 6.10678 15.9471 6.62436C16.1668 7.14194 16.2817 7.69798 16.2849 8.26025C16.2882 8.82252 16.1799 9.37986 15.9662 9.89997C15.7525 10.4201 15.4378 10.8926 15.0402 11.2902C14.6426 11.6878 14.1701 12.0025 13.65 12.2162C13.1299 12.4299 12.5725 12.5382 12.0103 12.5349C11.448 12.5317 10.8919 12.4168 10.3744 12.1971C9.85678 11.9774 9.38794 11.6572 8.995 11.255C8.2133 10.4549 7.77856 9.37883 7.78508 8.26025C7.79159 7.14167 8.23883 6.07076 9.0298 5.2798C9.82077 4.48883 10.8917 4.04159 12.0103 4.03507C13.1288 4.02856 14.2049 4.4633 15.005 5.245"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
