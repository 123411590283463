import React from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';

const OnBoardingLabel = ({translate, label, color, total, value}) => (
    <span className="activity-label">
        <span className="activity-color" />
        <span>
            {translate(`dashboard.label.${label}`, {count: value})}
            &nbsp;
        </span>
        <span>
            ({Math.round((value / total) * 10000) / 100}
            %)
        </span>

        <style jsx>
            {`
                @import '../../../lib/sass/kit';

                .activity-label {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 1rem;
                }

                .activity-color {
                    display: inline-block;
                    border: 0.2rem solid $pure-white;
                    outline: 0.1rem solid $grey-300;
                    background-color: ${color};
                    width: 1.7rem;
                    height: 1.7rem;
                    margin-right: 0.5rem;
                }
            `}
        </style>
    </span>
);

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(OnBoardingLabel);
