import React from 'react';
import {Tooltip as ReactTippyTooltip} from 'react-tippy';

const Tooltip = ({children, ...otherProps}) => (
    <ReactTippyTooltip position="top" theme="dark" {...otherProps}>
        {children}
    </ReactTippyTooltip>
);

export default Tooltip;
