import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16.333 3H4.667C3.746 3 3 3.746 3 4.667V13"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 21L15.167 17.667H19.334C20.254 17.667 21 16.92 21 16V7.667C21 6.746 20.254 6 19.333 6H7.667C6.746 6 6 6.746 6 7.667V16C6 16.92 6.746 17.667 7.667 17.667H11"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 10H17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 14H14"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
