import React from 'react';
import {format} from 'date-fns';
import {useDispatch} from 'react-redux';
import actions from 'store/actions';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import useContentSaver from 'components/hooks/useContentSaver';
import EditorSidebarTrigger from './EditorSidebarTrigger';

const EditorSidebarTriggerWithState = ({canEdit}) => {
    const {editor} = useEditorState();
    const {translate} = useTranslate();
    const {saveContent} = useContentSaver();
    const {trigger, delay} = editor;

    const dispatch = useDispatch();
    const updateTrigger = async newTrigger => {
        await dispatch(actions.editor.setTrigger(newTrigger));
        saveContent({...editor, trigger: newTrigger});
    };
    const updateDelay = async newDelay => {
        await dispatch(actions.editor.setDelay(newDelay));
        saveContent({...editor, delay: newDelay});
    };

    const onTriggerChange = option => {
        if (!option) {
            return updateTrigger(null);
        }
        return updateTrigger(option);
    };
    const onSendImmediatelyChange = checked => {
        if (checked) {
            updateDelay(null);
        } else {
            updateDelay({days: 0, time_of_day: '12:00'});
        }
    };
    const onDaysDelayChange = days => {
        const newDelay = Object.assign({}, delay, {days});
        updateDelay(newDelay);
    };
    const onTimeOfDayChange = timeOfDay => {
        updateDelay(
            Object.assign({}, delay, {time_of_day: format(timeOfDay, 'HH:mm')}),
        );
    };

    return (
        <EditorSidebarTrigger
            delay={delay}
            canEdit={canEdit}
            trigger={trigger}
            translate={translate}
            onTriggerChange={onTriggerChange}
            onDaysDelayChange={onDaysDelayChange}
            onTimeOfDayChange={onTimeOfDayChange}
            onSendImmediatelyChange={onSendImmediatelyChange}
        />
    );
};

export default EditorSidebarTriggerWithState;
