import {schema} from 'normalizr';
import {CALL_API} from '../../../../constants/api';
import {GET, PUT} from '../../../services';
import avatar from './avatar';

export const USER_SCHEMA = new schema.Entity('user');

export const USERS_ME_REQUEST = 'USERS_ME_REQUEST';
export const USERS_ME_SUCCESS = 'USERS_ME_SUCCESS';
export const USERS_ME_FAILURE = 'USERS_ME_FAILURE';

export const fetchMe = () => ({
    [CALL_API]: {
        endpoint: '/users/me',
        method: GET,
        types: [USERS_ME_REQUEST, USERS_ME_SUCCESS, USERS_ME_FAILURE],
        schema: USER_SCHEMA,
    },
});

export const USERS_ME_UPDATE_REQUEST = 'USERS_ME_UPDATE_REQUEST';
export const USERS_ME_UPDATE_SUCCESS = 'USERS_ME_UPDATE_SUCCESS';
export const USERS_ME_UPDATE_FAILURE = 'USERS_ME_UPDATE_FAILURE';

export const updateMe = body => ({
    [CALL_API]: {
        endpoint: '/users/me',
        method: PUT,
        body,
        types: [
            USERS_ME_UPDATE_REQUEST,
            USERS_ME_UPDATE_SUCCESS,
            USERS_ME_UPDATE_FAILURE,
        ],
        schema: USER_SCHEMA,
    },
});

export const REGISTER_FOR_PUSH_NOTIFICATIONS_REQUEST =
    'REGISTER_FOR_PUSH_NOTIFICATIONS_REQUEST';
export const REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS =
    'REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS';
export const REGISTER_FOR_PUSH_NOTIFICATIONS_FAILURE =
    'REGISTER_FOR_PUSH_NOTIFICATIONS_FAILURE';

export const registerForPushNotifications = (token, info = {}) => ({
    [CALL_API]: {
        endpoint: '/users/me/devices',
        method: 'POST',
        body: {
            token,
            provider: 'pusher',
            info: JSON.stringify(info),
        },
        types: [
            REGISTER_FOR_PUSH_NOTIFICATIONS_REQUEST,
            REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS,
            REGISTER_FOR_PUSH_NOTIFICATIONS_FAILURE,
        ],
    },
});

export default {
    fetchMe,
    updateMe,
    avatar,
    registerForPushNotifications,
};
