import React from 'react';

export default function () {
    return (
        <path
            d="M14.391 10.4062H22.407C23.298 10.4062 24.0007 11.1097 24.0007 12C24.0007 12.8903 23.2972 13.5938 22.407 13.5938H14.391C13.5 13.5938 12.7972 12.8903 12.7972 12C12.7972 11.1097 13.5007 10.4062 14.391 10.4062V10.4062ZM1.59375 10.4062H9.60975C10.5007 10.4062 11.2035 11.1097 11.2035 12C11.2035 12.8903 10.5 13.5938 9.60975 13.5938H1.59375C0.70275 13.5938 0 12.8903 0 12C0 11.1097 0.7035 10.4062 1.59375 10.4062Z"
            fill="currentColor"
        />
    );
}
