import React from 'react';
import {Translate} from 'react-localize-redux';
import Button from 'components/util/Button';
import * as media from 'lib/styles/media';
import * as colors from 'lib/styles/colors';
import * as theme from 'styles/theme';

import PropTypes from 'prop-types';

const ModalActions = ({cancelable, loading, onClose, buttonActions}) => {
    const buttonRenderer = button => (
        <Button
            isLoading={button.loading || loading}
            className={`button ${button.classes} ${button.disabled ? 'disabled' : ''}`}
            onClick={() => {
                button.callBack();
            }}
            key={button.label}
            disabled={button.disabled}>
            {button.label}
        </Button>
    );

    let buttonsLeft;
    let buttonsRight;
    if (buttonActions) {
        buttonsLeft = buttonActions.filter(action => action.left);
        buttonsLeft = buttonsLeft.map(button => {
            if (button.left) {
                return buttonRenderer(button);
            }
            return false;
        });

        buttonsRight = buttonActions.filter(action => !action.left);
        buttonsRight = buttonsRight.map(button => {
            if (!button.left) {
                return buttonRenderer(button);
            }
            return false;
        });
    }

    return buttonActions || cancelable ? (
        <div className="footer">
            {buttonsLeft && <div className="left">{buttonsLeft}</div>}
            <div className="right">
                {cancelable && (
                    <Button className="button" transparent onClick={onClose}>
                        <Translate id="global.cancel" />
                    </Button>
                )}
                {buttonsRight}
            </div>

            <style jsx>
                {`
                    .footer {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        margin-top: 3rem;
                        padding-top: 3rem;
                        border-top: solid ${colors.brandingDefaults.grey[350]}
                            0.1rem;

                        :global(.button) {
                            width: 100%;
                            margin-bottom: 1rem;
                        }

                        @media (${media.forMediumAndUp}) {
                            align-items: center;
                            flex-direction: row;

                            :global(.button) {
                                width: auto;
                                margin: 0;
                            }

                            .left {
                                margin-right: auto;

                                :global(.button) {
                                    margin-right: ${theme.spacing}rem;
                                }
                            }

                            .right {
                                margin-left: auto;

                                :global(.button) {
                                    margin-left: ${theme.spacing}rem;
                                }
                            }
                        }
                    }
                `}
            </style>
        </div>
    ) : null;
};

ModalActions.propTypes = {
    cancelable: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    buttonActions: PropTypes.arrayOf(PropTypes.object),
};
ModalActions.defaultProps = {
    cancelable: false,
    loading: false,
    onClose: () => {},
    buttonActions: [],
};
export default ModalActions;
