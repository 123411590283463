import {Component} from 'react';
import PropTypes from 'prop-types';

class State extends Component {
    constructor(props) {
        super(props);
        const {initialState} = this.props;
        this.state = initialState || {};
        this.publicSetState = (...args) => this.setState(...args);
    }

    render() {
        const {children} = this.props;
        return typeof children === 'function'
            ? children({
                  state: this.state,
                  setState: this.publicSetState,
              })
            : children;
    }
}
State.propTypes = {
    initialState: PropTypes.shape({}),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

State.defaultProps = {
    initialState: {},
};
export default State;
