import React from 'react';

export default function () {
    return (
        <g className="nc-icon-wrapper" fill="#111111">
            <path
                d="M4 13a8 8 0 1 0 8-8H5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 2L5 5"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 8L5 5"
            />
            <path
                d="M14.233 11.851l-2.79 2.79-1.678-1.672"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path fill="none" d="M0 0h24v24H0z" />
        </g>
    );
}
