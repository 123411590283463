import React from 'react';
import PropTypes from 'prop-types';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'lib/styles/theme';

const Answer = ({answer}) => (
    <Branding.Consumer>
        {colors => (
            <div className="answered">
                <div
                    className="bar"
                    style={{width: `${(answer.count / answer.max) * 100}%`}}
                />
                <div className="value">{answer.count}</div>

                <style jsx>
                    {`
                        .answered {
                            display: flex;
                            align-items: center;
                            margin: 0.5rem 0 0.5rem -1.3rem;
                        }

                        .bar {
                            background-color: ${colors.brand.base};
                            height: 1.5rem;
                            border-radius: 0 0.4rem 0.4rem 0;
                        }

                        .value {
                            color: ${colors.brand.base};
                            margin: 0 0.6rem;
                            font-weight: ${theme.fwBold};
                            font-size: 1.6rem;
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);

Answer.propTypes = {
    answer: PropTypes.shape({
        count: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
    }).isRequired,
};
export default Answer;
