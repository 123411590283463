import React from 'react';
import Icon from 'lib/components/Icon';
import {caretRight} from '../lib/components/Icon';

const Dropdown = ({children, onChange = () => {}}) => (
    <div className="dropdown">
        <select
            className="dropdown__select"
            onChange={e => onChange(e.target.value)}>
            {children}
        </select>
        <Icon name={caretRight} size={10} />
    </div>
);

export default Dropdown;
