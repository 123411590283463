/* eslint-disable no-case-declarations */
import fastCompare from 'react-fast-compare';
import {ADD_NOTIFICATION, CLEAR_NOTIFICATION} from '../actions/notifications';

const INITIAL_STATE = {
    notifications: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            const newNotification = {
                id: action.payload.id,
                ...action.payload.notification,
            };

            // See if there is not an already existing notification with the same message
            const existing = state.notifications.find(notification =>
                fastCompare(notification.content, newNotification.content),
            );

            if (existing) {
                return state;
            }

            return Object.assign({}, state, {
                notifications: [...state.notifications, newNotification],
            });
        case CLEAR_NOTIFICATION:
            let notifications = [...state.notifications];

            notifications = notifications.filter(
                notification =>
                    notification.id !== action.payload.notificationId,
            );

            return Object.assign({}, state, {
                notifications,
            });
        default:
            return state;
    }
};
