import {
    TYPE_RSVP,
    TYPE_RATING,
    TYPE_OPINION_SCALE,
    TYPE_HAPPINESS_POLL,
    TYPE_MULTIPLE_CHOICE,
    TYPE_YES_NO_QUESTION,
} from 'lib/store/actions/surveys';
import {ATOMIC} from 'lib/constants/customBlocks';
import {store} from 'store';
import actions from 'store/actions';
import {getById} from 'lib/store/reducers/helpers/byId';
import {editorBodyToRaw, rawBodyToEditorBody} from './editorBody';

export const saveSurvey = (survey, resetAnswers) => {
    if (!survey.id) {
        return store.dispatch(actions.surveys.createSurvey(survey));
    }
    return store.dispatch(actions.surveys.updateSurvey(survey, resetAnswers));
};

export const iconForSurveyType = surveyType => {
    switch (surveyType) {
        case TYPE_HAPPINESS_POLL:
            return 'smiley';
        case TYPE_MULTIPLE_CHOICE:
            return 'multipleChoice';
        case TYPE_OPINION_SCALE:
            return 'scale';
        case TYPE_RATING:
            return 'star';
        case TYPE_RSVP:
            return 'event';
        case TYPE_YES_NO_QUESTION:
            return 'yesNo';
        default:
            return null;
    }
};

export const titleTranslationKeyForSurveyType = surveyType => {
    switch (surveyType) {
        case TYPE_HAPPINESS_POLL:
            return 'editor.survey.happiness_poll';
        case TYPE_MULTIPLE_CHOICE:
            return 'editor.survey.multiple_choice';
        case TYPE_OPINION_SCALE:
            return 'editor.survey.opinion_scale';
        case TYPE_RATING:
            return 'editor.survey.rating';
        case TYPE_RSVP:
            return 'editor.survey.rsvp_event';
        case TYPE_YES_NO_QUESTION:
            return 'editor.survey.yes_no_question';
        default:
            return null;
    }
};

export const getModalUiTranslationKeys = surveyId => ({
    headerTitleKey: surveyId ? 'global.edit_x' : 'global.add_x',
    buttonTitleKey: surveyId ? 'global.save' : 'global.add',
});

const getSurveyWithIndexFromBlocks = (blocks, id) =>
    blocks
        .map((block, index) => ({...block, index}))
        .find(({type, data}) => type === ATOMIC && data.surveyId === id);

const KEY_LENGTH = 5;
const KEY_POSSIBLE = 'abcdefghijklmnopqrstuvwxyz0123456789';

const makeSurveyKey = () => {
    let text = '';
    for (let i = 0; i < KEY_LENGTH; i += 1) {
        text += KEY_POSSIBLE.charAt(
            Math.floor(Math.random() * KEY_POSSIBLE.length),
        );
    }
    return text;
};

const getSurveyWithUniqueKey = (body, survey) => {
    const bodyKeys = body.blocks.map(({key}) => key);
    const getSurveyKey = key => {
        if (bodyKeys.includes(key)) {
            const newKey = makeSurveyKey();
            return getSurveyKey(newKey);
        }
        return key;
    };
    return {
        ...survey,
        key: getSurveyKey(survey.key),
    };
};

const addLanguageToSurvey = (survey, sourceLanguage, newLanguage) => {
    if (typeof survey === 'undefined') {
        return null;
    }
    const updatedSurvey = {...survey};

    if (
        updatedSurvey.content.question !== null &&
        typeof updatedSurvey.content.question !== 'undefined'
    ) {
        updatedSurvey.content.question[newLanguage] =
            survey.content.question[sourceLanguage];
    }

    if (
        updatedSurvey.content.description !== null &&
        typeof updatedSurvey.content.description !== 'undefined'
    ) {
        updatedSurvey.content.description[newLanguage] =
            survey.content.description[sourceLanguage];
    }
    if (
        typeof survey.content.location !== 'undefined' &&
        survey.content.location[sourceLanguage]
    ) {
        updatedSurvey.content.location[newLanguage] =
            survey.content.location[sourceLanguage];
    }
    updatedSurvey.options.map(option => {
        const updatedOption = {...option};
        if (typeof updatedOption.label === 'object') {
            updatedOption.label[newLanguage] =
                updatedOption.label[sourceLanguage];
        }
        return updatedOption;
    });
    return updatedSurvey;
};

export const syncNewSurveyWithLanguages = (
    survey,
    availableLanguages,
    currentLanguage,
) =>
    availableLanguages.reduce((acc, language) => {
        let updatedSurvey = acc;
        if (language !== currentLanguage) {
            updatedSurvey = addLanguageToSurvey(
                survey,
                currentLanguage,
                language,
            );
        }
        return {
            ...acc,
            ...updatedSurvey,
        };
    }, survey);

export const syncBodyWithNewSurvey = (body, currentLanguage, surveyId) => {
    // Convert editor body
    const convertedBody = editorBodyToRaw(body);
    // Find newly created survey with index
    const surveyToSync = getSurveyWithIndexFromBlocks(
        convertedBody[currentLanguage].blocks,
        surveyId,
    );
    // Insert survey in other languages
    const syncedBody = Object.keys(convertedBody).reduce((acc, language) => {
        if (language !== currentLanguage) {
            const bodyToSync = convertedBody[language];
            // Avoid merging survey with existing block
            const updatedSurvey = getSurveyWithUniqueKey(
                bodyToSync,
                surveyToSync,
            );
            bodyToSync.blocks.splice(surveyToSync.index, 0, updatedSurvey);
            acc[language] = bodyToSync;
        } else {
            acc[language] = convertedBody[language];
        }
        return acc;
    }, {});
    // Return editor compatible body
    return rawBodyToEditorBody(syncedBody);
};

export const syncBodyWithRemovedSurvey = (body, surveyId) => {
    // Convert editor body
    const convertedBody = editorBodyToRaw(body);
    // Remove survey from all languages
    const syncedBody = Object.keys(convertedBody).reduce((acc, language) => {
        const bodyToSync = convertedBody[language];
        const indexOfSurvey = getSurveyWithIndexFromBlocks(
            bodyToSync.blocks,
            surveyId,
        ).index;
        bodyToSync.blocks.splice(indexOfSurvey, 1);
        acc[language] = bodyToSync;
        return acc;
    }, {});
    // Return editor compatible body
    return rawBodyToEditorBody(syncedBody);
};

export const getSurveyInCurrentLanguage = (survey, currentLanguage) => {
    if (typeof survey === 'undefined') {
        return null;
    }
    const {content} = survey;
    return {
        ...survey,
        content: {
            ...content,
            title:
                typeof content.title !== 'undefined'
                    ? content.title[currentLanguage]
                    : undefined,
            question:
                typeof content.question !== 'undefined'
                    ? content.question[currentLanguage]
                    : undefined,
            description:
                typeof content.description !== 'undefined' &&
                content.description !== null
                    ? content.description[currentLanguage]
                    : '',
            location:
                typeof content.location !== 'undefined'
                    ? content.location[currentLanguage]
                    : undefined,
        },
    };
};

const getDefaultSurveysWithContent = (defaultBody, surveys) =>
    defaultBody?.blocks
        .filter(({type, data}) => type === ATOMIC && data.surveyId)
        .map(({data}) => getById(surveys, data.surveyId));

export const addLanguageToMessageSurveys = (
    body,
    defaultLanguage,
    surveys,
    newLanguage,
) => {
    const convertedBody = editorBodyToRaw(body);
    const defaultSurveys = getDefaultSurveysWithContent(
        convertedBody[defaultLanguage],
        surveys,
    );
    defaultSurveys.forEach(survey => {
        const updatedSurvey = addLanguageToSurvey(
            survey,
            defaultLanguage,
            newLanguage,
        );
        if (updatedSurvey !== null) {
            saveSurvey({
                ...updatedSurvey,
                available_languages: [
                    ...survey.available_languages,
                    newLanguage,
                ],
            });
        }
    });
};

export const removeLanguageFromMessageSurveys = (
    body,
    defaultLanguage,
    surveys,
    removedLanguage,
) => {
    const convertedBody = editorBodyToRaw(body);
    const defaultSurveys =
        getDefaultSurveysWithContent(convertedBody[defaultLanguage], surveys) ||
        [];
    defaultSurveys.forEach(survey => {
        const updatedSurvey = {...survey};
        if (
            updatedSurvey.content.question !== null &&
            typeof updatedSurvey.content.question !== 'undefined'
        ) {
            delete updatedSurvey.content.question[removedLanguage];
        }
        if (
            updatedSurvey.content.description !== null &&
            typeof updatedSurvey.content.description !== 'undefined'
        ) {
            delete updatedSurvey.content.description[removedLanguage];
        }
        updatedSurvey.available_languages = survey.available_languages.filter(
            language => language !== removedLanguage,
        );
        updatedSurvey.options.map(option => {
            const updatedOption = {...option};
            if (
                typeof updatedOption.label !== 'undefined' &&
                updatedOption.label !== null
            ) {
                delete updatedOption.label[removedLanguage];
            }
            return updatedOption;
        });
        saveSurvey(updatedSurvey);
    });
};
