import 'medium-draft/lib/index.css';
import React, {Component} from 'react';
import Icon from 'lib/components/Icon';

import ImageButton from './Buttons/ImageButton';
import VideoButton from './Buttons/VideoButton';
import FileButton from './Buttons/FileButton';
import PollsButton from './Buttons/PollsButton';
import EmbedButton from './Buttons/EmbedButton';
import ChooseChannelsButton from './Buttons/ChooseChannelsButton';
import customRenderer from './Utils/customRenderer';
import SmartTagEditor from './SmartTagEditor';

const TOOLBAR_INLINE_BUTTONS = [
    {label: <Icon name="bold" size={14} />, style: 'BOLD', type: 'inline'},
    {label: <Icon name="italic" size={14} />, style: 'ITALIC', type: 'inline'},
    {
        label: <Icon name="underline" size={14} />,
        style: 'UNDERLINE',
        type: 'inline',
    },
    {label: <Icon name="link" size="18" />, style: 'hyperlink', type: 'inline'},
];

const TOOLBAR_BLOCK_BUTTONS = [
    {
        label: <Icon name="fontsize" size={18} />,
        style: 'header-one',
        type: 'block',
    },
    {
        label: <Icon name="fontsize" size={12} />,
        style: 'header-two',
        type: 'block',
    },
    {
        label: <Icon name="quote" size={14} />,
        style: 'blockquote',
        type: 'block',
    },
    {label: 'UL', style: 'unordered-list-item', type: 'block'},
    {label: 'OL', style: 'ordered-list-item', type: 'block'},
];

const SIDE_BUTTONS = [
    {
        title: 'Image',
        component: ImageButton,
    },
    {
        title: 'Video',
        component: VideoButton,
    },
    {
        title: 'File',
        component: FileButton,
    },
    {
        title: 'Polls',
        component: PollsButton,
    },
    {
        title: 'Embed',
        component: EmbedButton,
    },
    {
        title: 'Choose Channels',
        component: ChooseChannelsButton,
    },
];

// This has to remain a component because its ref gets passed from the parent.
class RogerEditor extends Component {
    render() {
        const {onChange, canEdit, editorState} = this.props;

        return (
            <article
                className="roger-editor"
                onClick={() => {
                    this.editor.focus();
                }}>
                <SmartTagEditor
                    editorRef={ref => {
                        this.editor = ref;
                    }}
                    editorState={editorState}
                    onChange={state => canEdit && onChange(state)}
                    inlineButtons={TOOLBAR_INLINE_BUTTONS}
                    blockButtons={TOOLBAR_BLOCK_BUTTONS}
                    sideButtons={SIDE_BUTTONS}
                    rendererFn={(setEditorState, getEditorState) =>
                        contentBlock =>
                            customRenderer({
                                setEditorState,
                                getEditorState,
                                contentBlock,
                                canEdit,
                            })
                        }
                    editorEnabled={canEdit}
                />

                <style jsx global>
                    {`
                        @import '../../lib/sass/kit';

                        .md-sb-button {
                            background-color: $brand;
                            box-shadow: $default-shadow;
                            border: none;
                            color: $pure-white;
                            cursor: pointer;
                            height: 4rem;
                            width: 4rem;
                            border-radius: 2.5rem;
                            font-weight: $fw-bold;
                            font-size: 2rem;
                            margin-right: 1rem;
                            text-align: center;

                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            transition: $default-transition background;

                            &:hover {
                                background-color: $brand-550;
                                color: $text-color-light;
                            }

                            svg path {
                                stroke-width: 1.5;
                                fill: none;
                            }
                        }

                        .md-add-button.md-open-button {
                            transform: none;
                            transition: 0.4s ease all;
                            &:before {
                                transform: rotate(45deg);
                            }
                        }

                        .md-add-button {
                            display: flex;
                            width: 4rem;
                            height: 4rem;
                            align-items: center;
                            justify-content: center;

                            svg {
                                display: none;
                            }

                            &:before {
                                content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon fill="white" points="23.039062 10.078125 13.921875 10.078125 13.921875 0.960938 10.078125 0.960938 10.078125 10.078125 0.960938 10.078125 0.960938 13.921875 10.078125 13.921875 10.078125 23.039062 13.921875 23.039062 13.921875 13.921875 23.039062 13.921875" /></svg>');
                                width: 100%;
                                height: 100%;
                            }
                        }
                    `}
                </style>
            </article>
        );
    }
}

export default RogerEditor;
