import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {DELETE, PUT, POST, GET} from '../../services';

import me from './me';

export const USER_SCHEMA = new schema.Entity('user');

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_FAILURE = 'USERS_LIST_FAILURE';

export const fetchUsers = (
    {page, limit, filters = {}, filters: {query, sort, departments} = {}} = {},
    listType = null,
    onboarded = null,
    notifications = null,
) => ({
    [CALL_API]: {
        endpoint: '/users',
        method: GET,
        query: {
            query,
            page,
            limit,
            sort,
            'departments[]': departments,
            ...(onboarded !== null && {onboarded}),
            ...(notifications !== null && {notifications}),
        },
        filters,
        types: [USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_FAILURE],
        listType,
        schema: [USER_SCHEMA],
    },
});

export const fetchSuperUsers = () => ({
    [CALL_API]: {
        endpoint: '/company/super-users',
        method: GET,
        types: [USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_FAILURE],
        listType: 'super-user',
        schema: [USER_SCHEMA],
    },
});

export const fetchManagers = () => ({
    [CALL_API]: {
        endpoint: '/company/admins',
        method: GET,
        types: [USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_FAILURE].map(
            v => `ADMIN_${v}`,
        ),
        listType: 'admin',
        schema: [USER_SCHEMA],
    },
});

export const USERS_VIEW_REQUEST = 'USERS_VIEW_REQUEST';
export const USERS_VIEW_SUCCESS = 'USERS_VIEW_SUCCESS';
export const USERS_VIEW_FAILURE = 'USERS_VIEW_FAILURE';

export const fetchUser = userId => ({
    [CALL_API]: {
        endpoint: `/users/${userId}`,
        method: GET,
        types: [USERS_VIEW_REQUEST, USERS_VIEW_SUCCESS, USERS_VIEW_FAILURE],
        schema: USER_SCHEMA,
    },
});

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST';
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE';

export const createUser = (role = 'user', user) => ({
    [CALL_API]: {
        endpoint: `/roles/${role}/users`,
        method: POST,
        body: {...user, username: user.email}, // TODO: Fix this nasty workaround.
        types: [
            USERS_CREATE_REQUEST,
            USERS_CREATE_SUCCESS,
            USERS_CREATE_FAILURE,
        ],
        schema: USER_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.user_created',
            },
        },
    },
});

export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST';
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_FAILURE = 'USERS_UPDATE_FAILURE';

export const updateUser = user => ({
    [CALL_API]: {
        endpoint: `/users/${user.id}`,
        method: PUT,
        body: user,
        types: [
            USERS_UPDATE_REQUEST,
            USERS_UPDATE_SUCCESS,
            USERS_UPDATE_FAILURE,
        ],
        schema: USER_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.user_updated',
            },
        },
    },
});

export const USERS_UPDATE_AVATAR_REQUEST = 'USERS_UPDATE_AVATAR_REQUEST';
export const USERS_UPDATE_AVATAR_SUCCESS = 'USERS_UPDATE_AVATAR_SUCCESS';
export const USERS_UPDATE_AVATAR_FAILURE = 'USERS_UPDATE_AVATAR_FAILURE';

export const updateUserAvatar = (body, userId) => ({
    [CALL_API]: {
        endpoint: `/users/${userId}/avatar`,
        method: POST,
        body,
        types: [
            USERS_UPDATE_AVATAR_REQUEST,
            USERS_UPDATE_AVATAR_SUCCESS,
            USERS_UPDATE_AVATAR_FAILURE,
        ],
        schema: USER_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.user_updated',
            },
        },
    },
});

export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

export const deleteUser = userId => ({
    [CALL_API]: {
        endpoint: `/users/${userId}`,
        method: DELETE,
        types: [
            USERS_DELETE_REQUEST,
            USERS_DELETE_SUCCESS,
            USERS_DELETE_FAILURE,
        ],
        schema: USER_SCHEMA,
        notifications: {
            success: {
                key: 'notifications.user_deleted',
            },
        },
    },
});

export default {
    fetchUsers,
    fetchSuperUsers,
    fetchManagers,
    fetchUser,
    createUser,
    updateUser,
    updateUserAvatar,
    deleteUser,
    me,
};
