import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import actions from 'store/actions';
import {MODAL_LOCALE, MODAL_CONFIRM} from 'lib/store/actions/modals';

import {getLangByCode} from 'lib/helpers/locale';
import Modal from 'modals/Modal/ModalWithState';
import Form from 'components/util/Form';
import {isEqual} from 'lodash';
import useCompany from 'components/hooks/useCompany';

const LocaleModal = ({onSave, onDelete, defaultLanguage}) => {
    const dispatch = useDispatch();
    const translate = useSelector(state => getTranslate(state.localize));
    const company = useCompany();
    const translationsFromProps = useSelector(
        state => state.modals.data[MODAL_LOCALE]?.translations || {},
    );
    const isEditing = useSelector(
        state => state.modals.data[MODAL_LOCALE]?.isEditing || false,
    );
    const segmentGroupId = useSelector(
        state => state.modals.data[MODAL_LOCALE]?.segmentGroupId || 0,
    );
    const segmentId = useSelector(
        state => state.modals.data[MODAL_LOCALE]?.segmentId || 0,
    );
    const canDelete = useSelector(
        state => state.modals.data[MODAL_LOCALE]?.canDelete || 0,
    );
    const availableLanguages = useSelector(
        state => state.company.data.languages,
    );

    const isVisible = useSelector(
        ({modals}) => modals.visibility[MODAL_LOCALE],
    );
    const [translations, setTranslations] = useState({});
    const [isRemoving, setIsRemoving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        if (!isEqual(translations, translationsFromProps)) {
            setTranslations(translationsFromProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const buttonActions = [
        {
            loading: isSaving,
            label: isEditing
                ? translate('global.edit')
                : translate('global.add'),
            callBack: async () => {
                setIsSaving(true);
                await onSave(isEditing, segmentGroupId, segmentId, {
                    name: translations[defaultLanguage] || 'not_set',
                    translations,
                });
                setIsSaving(false);
            },
            classes: 'success',
            disabled: !translations[company.default_language],
        },
    ];

    if (isEditing && canDelete) {
        buttonActions.push({
            loading: isRemoving,
            label: 'Delete',
            callBack: () => {
                dispatch(
                    actions.modals.setModalVisibility(MODAL_CONFIRM, true),
                );
                dispatch(
                    actions.modals.setConfirmModalData({
                        title: 'Confirm Delete',
                        buttonActions: [
                            {
                                label: translate('global.delete'),
                                callBack: async () => {
                                    setIsRemoving(true);
                                    await dispatch(
                                        actions.modals.setModalVisibility(
                                            MODAL_CONFIRM,
                                            false,
                                        ),
                                    );
                                    await onDelete(segmentGroupId, segmentId);
                                    setIsRemoving(false);
                                },
                                classes: 'error',
                            },
                        ],
                    }),
                );
            },
            classes: 'error',
            left: true,
        });
    }
    return (
        <Modal
            name={MODAL_LOCALE}
            buttonActions={buttonActions}
            disabled={!translations[company.default_language]}>
            {availableLanguages.map(language => (
                <div key={language}>
                    <Form.LabelGroup
                        label={getLangByCode(language)}
                        isRequired={language === company.default_language}>
                        <Form.Input
                            value={
                                (translations && translations[language]) || ''
                            }
                            placeholder={getLangByCode(language)}
                            onChange={value =>
                                setTranslations({
                                    ...translations,
                                    [language]: value,
                                })
                            }
                            required={language === company.default_language}
                        />
                    </Form.LabelGroup>
                </div>
            ))}
        </Modal>
    );
};

LocaleModal.propTypes = {
    defaultLanguage: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

LocaleModal.defaultProps = {
    defaultLanguage: 'en',
};
export default LocaleModal;
