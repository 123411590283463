import React from 'react';
import {withLocalize} from 'react-localize-redux';
import {AND, OR, NOT} from 'util/constants';
import SegmentationSelect from './SegmentationSelect';

const findOption = (options, key) => options.find(({value}) => value === key);
const createOption = (label, value) => ({label, value});

const SegmentationSelectWithState = ({
    translate,
    segments,
    onAdd,
    onDelete,
    onChange,
    segment,
    className,
    onAddSubFilter,
}) => {
    const {filter, type: filterType, value: filterValue} = segment;

    // Type options
    const typeOptions = [
        {label: translate('segmentation.and'), value: AND},
        {label: translate('segmentation.or'), value: OR},
        {label: translate('segmentation.not'), value: NOT},
    ];
    const selectedType = findOption(typeOptions, filterType) || typeOptions[0];

    // Segment group options
    const segmentGroupOptions = segments.map(({name, id}) =>
        createOption(name, id),
    );
    const selectedSegmentGroup = findOption(segmentGroupOptions, filter);

    // Segment options
    const createSegmentOptions = selectedSegmentGroupValue => {
        const segmentGroupData = segments.find(
            ({id}) => id === selectedSegmentGroupValue,
        );
        return (
            segmentGroupData &&
            segmentGroupData.segments &&
            segmentGroupData.segments.map(({name, id}) =>
                createOption(name, id),
            )
        );
    };
    const segmentOptions = createSegmentOptions(
        selectedSegmentGroup && selectedSegmentGroup.value,
    );
    const selectedSegment =
        segmentOptions && findOption(segmentOptions, filterValue);

    const onSelectChange = value => {
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    const onTypeChange = type => onSelectChange({type});

    const onPrimaryChange = value => {
        const newOptions = segments.find(({id}) => id === value).segments;
        const id = newOptions && newOptions[0] && newOptions[0].id;
        onSelectChange({
            filter: value,
            value: id,
        });
    };

    const onSecondaryChange = value => onSelectChange({value});

    return (
        <SegmentationSelect
            className={className}
            selectedType={selectedType}
            typeOptions={typeOptions}
            onAdd={onAdd}
            onDelete={onDelete}
            onTypeChange={onTypeChange}
            onPrimaryChange={onPrimaryChange}
            onSecondaryChange={onSecondaryChange}
            primaryValue={selectedSegmentGroup}
            primaryOptions={segmentGroupOptions}
            secondaryValue={selectedSegment}
            secondaryOptions={segmentOptions}
            onAddSubFilter={onAddSubFilter}
        />
    );
};

export default withLocalize(SegmentationSelectWithState);
