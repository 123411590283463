import {combineReducers} from 'redux';

import {
    APP_HEALTH_REQUEST,
    APP_HEALTH_SUCCESS,
    APP_HEALTH_FAILURE,
    SET_APP_AVAILABILITY,
} from '../actions/appHealth';

const isFetching = (state = false, {type}) => {
    switch (type) {
        case APP_HEALTH_REQUEST:
            return true;
        case APP_HEALTH_SUCCESS:
        case APP_HEALTH_FAILURE:
            return false;
        default:
            return state;
    }
};

const minClientVersion = (state = '0.0.0', {type, payload}) => {
    switch (type) {
        case APP_HEALTH_SUCCESS:
            return payload.data.min_client_version;
        default:
            return state;
    }
};

const available = (state = true, {type, payload}) => {
    switch (type) {
        case APP_HEALTH_SUCCESS:
            return payload.data.available;
        case SET_APP_AVAILABILITY:
            return payload.availability;
        default:
            return state;
    }
};

export default combineReducers({
    isFetching,
    minClientVersion,
    available,
});
