import React from 'react';
import {BrandingContext as Branding} from 'lib/components/providers/BrandingProvider';
import * as theme from 'styles/theme';
import Icon from 'lib/components/Icon';

const PeriodChart = ({
    dateOptions,
    title,
    activePeriod,
    onPeriodChange,
    sidebar,
    children,
}) => (
    <Branding.Consumer>
        {colors => (
            <div className="period-modal">
                <div className="period-modal__sidebar">
                    <div className="period-modal__header">
                        <h3 className="period-modal__title">{title}</h3>
                    </div>

                    <div className="dropdown dropdown--full-width dropdown--date">
                        <select
                            name="period"
                            className="dropdown__select"
                            onChange={e => onPeriodChange(e.target.value)}
                            value={activePeriod}>
                            {dateOptions.map(({value, label}) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                        <Icon name="chevronRight" />
                    </div>
                    {sidebar}
                </div>

                <div className="period-modal__chart">{children}</div>

                <style jsx>
                    {`
                        @import '../lib/sass/kit';

                        .period-modal {
                            display: flex;
                            overflow: hidden;
                            background-color: ${colors.grey[100]};

                            &,
                            &__sidebar {
                                min-height: 50rem;
                            }

                            &__sidebar {
                                width: 35rem;
                                background-color: ${colors.brand[900]};
                                padding: 2rem;

                                .dropdown {
                                    color: ${colors.brand.darkContrast};

                                    &,
                                    &__select {
                                        font-size: 2.8rem;
                                    }

                                    &:after {
                                        font-size: 1.5rem;
                                    }
                                }

                                &__text {
                                    color: rgba(255, 255, 255, 0.75);
                                    margin-bottom: 8rem;
                                    font-weight: ${theme.fwNormal};
                                    font-size: 1.6rem;
                                }

                                hr {
                                    border-top: solid rgba(255, 255, 255, 0.25)
                                        0.1rem;
                                    margin-top: 3.8rem;
                                    margin-bottom: 2.9rem;
                                }

                                .user {
                                    margin-top: 5.2rem;
                                    margin-bottom: 8rem;

                                    &__name,
                                    &__title {
                                        color: ${colors.pureWhite};
                                    }
                                }
                            }

                            &__chart {
                                position: relative;

                                .dropdown {
                                    margin: 2rem 0 0 2rem;
                                    position: absolute;
                                }

                                .recharts-responsive-container {
                                    position: absolute;
                                    bottom: 0;
                                }

                                &,
                                & > div {
                                    display: flex;
                                    flex: 1;
                                    flex-direction: column;
                                    justify-content: space-between;
                                }
                            }

                            &__header {
                                margin-bottom: 2.3rem;
                            }

                            &__title {
                                text-transform: uppercase;
                                font-weight: ${theme.fwBold};
                                color: ${colors.text.light};
                                font-size: 1.2rem;
                            }

                            :global(.count) {
                                margin-bottom: 1.6rem;
                            }

                            :global(.countText) {
                                color: ${colors.text.light};
                                max-width: 20rem;
                            }

                            .recharts-bar-rectangle {
                                cursor: pointer;
                            }

                            .recharts-label-list {
                                pointer-events: none;
                            }
                        }
                    `}
                </style>
            </div>
        )}
    </Branding.Consumer>
);

export default PeriodChart;
