import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M11.467 18.934C9.48663 18.934 7.58737 18.1473 6.18703 16.747C4.7867 15.3466 4 13.4474 4 11.467C4 9.48663 4.7867 7.58737 6.18703 6.18703C7.58737 4.7867 9.48663 4 11.467 4C13.4474 4 15.3466 4.7867 16.747 6.18703C18.1473 7.58737 18.934 9.48663 18.934 11.467C18.934 13.4474 18.1473 15.3466 16.747 16.747C15.3466 18.1473 13.4474 18.934 11.467 18.934V18.934Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.467 12.504V12.297C11.467 11.619 11.886 11.252 12.306 10.97C12.716 10.694 13.127 10.334 13.127 9.67C13.1271 9.22987 12.9524 8.80772 12.6413 8.49641C12.3302 8.1851 11.9081 8.01013 11.468 8.01C11.0279 8.00987 10.6057 8.18458 10.2944 8.4957C9.98308 8.80683 9.80812 9.22887 9.80798 9.669"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.466 14.843C11.4463 14.8429 11.4268 14.8467 11.4086 14.8542C11.3904 14.8617 11.3739 14.8727 11.36 14.8867C11.3461 14.9007 11.3352 14.9173 11.3278 14.9355C11.3204 14.9538 11.3168 14.9733 11.317 14.993C11.317 15.0227 11.3259 15.0517 11.3424 15.0764C11.3589 15.1011 11.3824 15.1204 11.4099 15.1317C11.4373 15.143 11.4675 15.1459 11.4967 15.14C11.5258 15.1342 11.5525 15.1198 11.5735 15.0987C11.5944 15.0776 11.6086 15.0508 11.6143 15.0216C11.6199 14.9925 11.6168 14.9623 11.6053 14.9349C11.5938 14.9075 11.5744 14.8842 11.5496 14.8678C11.5248 14.8514 11.4957 14.8428 11.466 14.843"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.9999 20L16.7469 16.747"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
