import React from 'react';
import styled from 'styled-components';

const SimpleTabMenu = ({activeTab, onChange, menu}) => (
    <Nav>
        <Menu>
            {menu.map((menuItem, index) => {
                const key = menuItem.key || index;
                return (
                    <MenuItem key={key}>
                        <StyledButton
                            isActive={activeTab === key}
                            onClick={() => onChange(key)}>
                            {menuItem.label}
                        </StyledButton>
                    </MenuItem>
                );
            })}
        </Menu>
    </Nav>
);

const Nav = styled.nav`
    height: 100%;
`;

const Menu = styled.ul`
    display: flex;
    height: 100%;
`;

const MenuItem = styled.li`
    height: 100%;
    list-style-type: none;
    margin: 0;
`;

const StyledButton = styled.button(
    ({isActive, theme}) => `
    height: 100%;
    background-color: transparent;
    border: 0;
    color: ${theme.colors.black};
    margin-right: 3rem;
    font-weight: ${theme.fwBold};
    font-size: 1.6rem;
    opacity: ${isActive ? 1 : 0.5};
    position: relative;
    transition: opacity ${theme.defaultTransition};

    &:after {
        content: '';
        position: absolute;
        height: .2rem;
        width: ${isActive ? '100%' : 0};
        left: 0;
        bottom: 0;
        background-color: ${theme.colors.primary};
        transition: width ${theme.defaultTransition};
    }

    &:hover {
        opacity: 1;

        &:after {
            width: 100%;
        }
    }
`,
);

export default SimpleTabMenu;
