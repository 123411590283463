import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M11.997 7.25001C11.8488 7.2506 11.7041 7.29509 11.5811 7.37786C11.4582 7.46063 11.3625 7.57797 11.3062 7.71507C11.2499 7.85216 11.2355 8.00286 11.2648 8.14815C11.2941 8.29344 11.3657 8.4268 11.4707 8.53139C11.5757 8.63598 11.7094 8.70711 11.8548 8.73581C12.0002 8.7645 12.1508 8.74948 12.2877 8.69262C12.4246 8.63577 12.5415 8.53964 12.6238 8.41637C12.7061 8.2931 12.75 8.14821 12.75 8.00001C12.75 7.90126 12.7305 7.80349 12.6926 7.7123C12.6547 7.62111 12.5992 7.53829 12.5293 7.46861C12.4593 7.39893 12.3763 7.34375 12.2849 7.30623C12.1936 7.26872 12.0957 7.24961 11.997 7.25001Z"
                fill="currentColor"
            />
            <path
                d="M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21V21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 12V17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
