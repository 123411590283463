import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import format from 'date-fns/format';
import subMonths from 'date-fns/sub_months';
import Count from 'components/Count';
import PeriodChart from 'components/PeriodChart';
import {chart} from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {LAST_30} from 'util/analytics';
import CustomBarChart from '../CustomBarChart/CustomBarChart';

const ContentPopularity = ({
    isLoadingSidebar,
    isLoadingContent,
    activePeriod,
    dateOptions,
    percentageRead,
    isMoreThanBefore,
    articlesRead,
    onPeriodChange,
    selectedActivity,
    lastMonthActivity,
    langCode,
}) => {
    let activePeriodText;
    if (activePeriod === LAST_30) {
        activePeriodText = (
            <Translate id="dashboard.read_articles_last_30_days" />
        );
    } else {
        activePeriodText = (
            <Translate
                id="dashboard.read_articles"
                data={{
                    month: format(
                        activePeriod,
                        'MMMM',
                        getDateLocale(langCode),
                    ),
                }}
            />
        );
    }

    return (
        <PeriodChart
            dateOptions={dateOptions}
            activePeriod={activePeriod}
            onPeriodChange={month => {
                onPeriodChange(month);
            }}
            sidebar={
                <LoadingWrapper loading={isLoadingSidebar}>
                    <Count
                        number={
                            selectedActivity ? selectedActivity.read || 0 : 0
                        }
                        text={activePeriodText}
                    />
                    {lastMonthActivity && (
                        <Fragment>
                            <hr />
                            <Count
                                number={`${percentageRead}%`}
                                text={
                                    <Translate
                                        id={`dashboard.${isMoreThanBefore ? 'read_more' : 'read_less'}`}
                                        data={{
                                            month: format(
                                                subMonths(activePeriod, 1),
                                                'MMMM',
                                                getDateLocale(langCode),
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Fragment>
                    )}
                </LoadingWrapper>
            }>
            <LoadingWrapper loading={isLoadingContent}>
                <CustomBarChart
                    color={chart.blue}
                    data={articlesRead}
                    activePeriod={activePeriod}
                    onPeriodChange={onPeriodChange}
                />
            </LoadingWrapper>
        </PeriodChart>
    );
};

ContentPopularity.propTypes = {
    isLoadingSidebar: PropTypes.bool.isRequired,
    isLoadingContent: PropTypes.bool.isRequired,
    activePeriod: PropTypes.instanceOf(Date).isRequired,
    dateOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    percentageRead: PropTypes.number,
    isMoreThanBefore: PropTypes.bool,
    articlesRead: PropTypes.arrayOf(PropTypes.object).isRequired,
    onPeriodChange: PropTypes.func.isRequired,
    selectedActivity: PropTypes.shape({
        read: PropTypes.number,
    }),
    lastMonthActivity: PropTypes.shape({}),
    langCode: PropTypes.string.isRequired,
};

ContentPopularity.defaultProps = {
    percentageRead: 0,
    isMoreThanBefore: false,
    selectedActivity: null,
    lastMonthActivity: null,
};

export default ContentPopularity;
