import React from 'react';
import {Translate} from 'react-localize-redux';
import {getById} from 'lib/store/reducers/helpers/byId';
import Table from 'components/Table/Table';
import SearchInput from 'lib/components/SearchInput';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import getUrlBySize, {THUMB} from 'lib/helpers/sizeHelper';
import * as theme from 'styles/theme';
import {
    getSegmentsFromGroupBySlug,
    DEPARTMENTS,
    FUNCTIONS,
} from 'lib/helpers/segmentation';
import PropTypes from 'prop-types';

const UserList = ({
    audience,
    isFetchingInitialData,
    isFetching,
    pages,
    filters,
    onQueryChange,
    onTableEnd,
}) => {
    const columns = [
        {
            className: 'table__icon',
            key: 'icon',
            renderer: (content, data) => (
                <div
                    className={`avatar ${data.isOnline ? '--online' : '--offline'}`}
                    style={{backgroundImage: `url(${data.avatar})`}}
                    alt="profile"
                />
            ),
        },
        {
            key: 'first_name',
            label: <Translate id="users.firstname" />,
            sortable: true,
        },
        {
            key: 'last_name',
            label: <Translate id="users.lastname" />,
            sortable: true,
        },
        {
            key: 'function',
            label: <Translate id="users.function" />,
        },
        {
            key: 'department',
            label: <Translate id="users.department" />,
        },
    ];

    let rows = [];

    if (pages.length) {
        rows = [].concat(
            ...pages.map(page =>
                page.map(userId => {
                    const user = getById(audience, userId);
                    const functions =
                        getSegmentsFromGroupBySlug(
                            user.segment_groups,
                            FUNCTIONS,
                        ).join(', ') || '';
                    const departments =
                        getSegmentsFromGroupBySlug(
                            user.segment_groups,
                            DEPARTMENTS,
                        ).join(', ') || '';

                    return {
                        key: user.id,
                        data: [
                            {
                                className: 'table__icon',
                                data: {
                                    isOnline: user.is_online,
                                    avatar: getUrlBySize(user.avatar, THUMB),
                                },
                            },
                            {
                                className: 'font-weight-bold',
                                content: user.first_name,
                            },
                            {
                                className: 'font-weight-bold',
                                content: user.last_name,
                            },
                            {content: functions},
                            {content: departments},
                        ],
                    };
                }),
            ),
        );
    }

    return (
        <div className="user-list-modal">
            <LoadingWrapper loading={isFetchingInitialData}>
                <div className="top">
                    <SearchInput
                        value={filters.query || ''}
                        onChange={query => onQueryChange({query})}
                    />
                </div>
                <div className="content">
                    <Table
                        rows={rows}
                        columns={columns}
                        sort={filters.sort || {}}
                        onSortChange={sort => onQueryChange({sort})}
                        onTableEnd={() => onTableEnd()}
                        loading={isFetching}
                        emptyTitle={<Translate id="users.no_users" />}
                        height="60rem"
                    />
                </div>
            </LoadingWrapper>

            <style jsx>
                {`
                    .user-list-modal {
                        min-height: 30rem;
                        display: flex;
                        flex-direction: column;
                    }

                    .top {
                        margin-bottom: ${theme.spacing}rem;
                        margin-left: ${theme.spacing}rem;
                        max-width: 25rem;
                    }

                    .content {
                        flex-grow: 1;
                    }
                `}
            </style>
        </div>
    );
};

UserList.propTypes = {
    audience: PropTypes.arrayOf(PropTypes.any).isRequired,
    isFetchingInitialData: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    pages: PropTypes.arrayOf(PropTypes.any).isRequired,
    filters: PropTypes.shape({
        query: PropTypes.string,
        sort: PropTypes.string,
    }).isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onTableEnd: PropTypes.func.isRequired,
};
export default UserList;
