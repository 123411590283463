import React, {useState} from 'react';
import actions from 'store/actions';
import * as routes from 'routes/index';
import {Translate} from 'react-localize-redux';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {showError, showSuccessByKey} from 'lib/store/actions/notifications';
import * as userManagement from 'lib/store/actions/userManagement';
import {
    getErrorNotification,
    throwIfInvalidApiResponse,
} from 'util/errorHelper';
import useFileUpload from 'components/hooks/useFileUpload';
import {MODAL_IMPORT_ERRORS} from 'lib/store/actions/modals';
import ImportErrorsModal from 'modals/ImportErrorsModal';
import {IMPORT_HELP_URL} from 'util/constants';
import UsersBulkEdit from './UsersBulkEdit';
import {NOT_STARTED, FETCHING, SUCCESS, FAILED} from './constants';

const UsersBulkEditWithState = () => {
    const [userValidateState, setValidateImportState] = useState(NOT_STARTED);
    const [isFetchingUsersExport, setIsFetchingUsersExport] = useState(false);
    const [userImportState, setUserImportState] = useState(NOT_STARTED);
    const [importError, setImportError] = useState(null);
    const [ignoredColumns, setIgnoredColumns] = useState([]);

    const dispatch = useDispatch();
    const fetchExportAction = () =>
        dispatch(userManagement.fetchExport(userManagement.MODELS.USERS));
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const fetchUsersExport = async () => {
        if (!isFetchingUsersExport) {
            setIsFetchingUsersExport(true);
            try {
                await fetchExportAction();
                dispatch(showSuccessByKey('global.userExportIsStarted'));
                setIsFetchingUsersExport(false);
            } catch (error) {
                dispatch(showError(getErrorNotification(error)));
            }
            setIsFetchingUsersExport(false);
        }
    };

    const startValidationAction = body =>
        dispatch(userManagement.startValidation(body));
    const validateUsers = async files => {
        if (userValidateState !== FETCHING) {
            setValidateImportState(FETCHING);
            setImportError(null);
            setIgnoredColumns([]);
            try {
                const formData = new FormData();
                formData.append('file', files[0]);
                formData.append('model', userManagement.MODELS.USERS);
                const result = await importUsersAction(formData);
                throwIfInvalidApiResponse(result);
                const {payload} = result;
                if (
                    typeof payload.data !== 'undefined' &&
                    payload.data.ignored_columns !== 'undefined'
                ) {
                    setIgnoredColumns(
                        Object.values(payload.data.ignored_columns),
                    );
                }
                await startValidationAction({
                    custom_mapping: false,
                    model: userManagement.MODELS.USERS,
                });
                setValidateImportState(SUCCESS);
            } catch (error) {
                setImportError(error);
                setValidateImportState(FAILED);
            }
        }
    };
    const onValidationFilesChange = useFileUpload(validateUsers);

    const startImportAction = body =>
        dispatch(userManagement.startImport(body));

    const importUsersAction = body =>
        dispatch(userManagement.importUsers(body));
    const importUsers = async files => {
        if (userImportState !== FETCHING) {
            setUserImportState(FETCHING);
            setImportError(null);
            setIgnoredColumns([]);
            try {
                const formData = new FormData();
                formData.append('file', files[0]);
                formData.append('model', userManagement.MODELS.USERS);
                const {payload} = await importUsersAction(formData);
                if (
                    typeof payload.data !== 'undefined' &&
                    payload.data.ignored_columns !== 'undefined'
                ) {
                    setIgnoredColumns(
                        Object.values(payload.data.ignored_columns),
                    );
                }
                await startImportAction({
                    custom_mapping: false,
                    model: userManagement.MODELS.USERS,
                });
                setUserImportState(SUCCESS);
            } catch (error) {
                setImportError(error);
                setUserImportState(FAILED);
            }
        }
    };

    const onFilesChange = useFileUpload(importUsers);

    const history = useHistory();
    const breadcrumbs = [
        {
            title: <Translate id="global.users" />,
            onClick: () => history.push(routes.users()),
        },
        {
            title: <Translate id="users.bulkEdit" />,
        },
    ];

    return (
        <>
            <UsersBulkEdit
                breadcrumbs={breadcrumbs}
                fetchUsersExport={fetchUsersExport}
                isFetchingUsersExport={isFetchingUsersExport}
                userValidateState={userValidateState}
                userImportState={userImportState}
                onValidationFilesChange={onValidationFilesChange}
                onFilesChange={onFilesChange}
                importError={importError}
                ignoredColumns={ignoredColumns}
                onShowImportErrors={() =>
                    setModalVisibility(MODAL_IMPORT_ERRORS, true)
                }
                onOpenGuidelines={() => window.open(IMPORT_HELP_URL, 'blank')}
            />
            <ImportErrorsModal
                importError={importError}
                buttonActions={[
                    {
                        label: <Translate id="global.close" />,
                        callBack: () =>
                            setModalVisibility(MODAL_IMPORT_ERRORS, false),
                    },
                ]}
            />
        </>
    );
};

export default UsersBulkEditWithState;
