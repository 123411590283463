import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle(
    ({theme}) => `
    .input,
    .textarea,
    .form-control,
    .DayPickerInput input,
    .react-tel-input input[type=tel].form-control,
    .react-tel-input input[type=text].form-control {
        width: 100%;
        height: 4.2rem;
        border: solid ${theme.colorGrey300} .1rem;
        border-radius: ${theme.borderRadius}rem;
        font-size: ${theme.fontSizeSmall}rem;
        color: ${theme.colorText};
        padding: ${theme.spacingSmall}rem ${theme.spacingLarge}rem;
        line-height: 1.6;

        &:focus {
            outline: none;
        }

        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${theme.colorGrey300};
            font-style: italic;
        }
        :-ms-input-placeholder {
            color: ${theme.colorGrey300};
            font-style: italic;
        }
    }

    .react-tel-input input[type=tel].form-control,
    .react-tel-input input[type=text].form-control {
        padding-left: 6rem;
    }

    .DayPickerInput {
        display: block;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
        border: solid ${theme.colorGrey300} .1rem;
    }

    .textarea {
        resize: none;
        min-height: 7rem;
    }

    .checkbox {
        margin-bottom: 0;
    }

    .checkbox__input {
        display: none;
    }

    .checkbox__label {
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            border-radius: ${theme.borderRadiusSmall}rem;
            border: solid ${theme.colorGrey300} .1rem;
            margin-right: .7rem;
            width: 1.6rem;
            height: 1.6rem;
            vertical-align: bottom;
        }

        svg {
            position: absolute;
            left: .2rem;
            top: .1rem;
            opacity: 0;
            transform: scale(0);
            transition: opacity ${theme.defaultTransition}, transform ${theme.defaultTransition};
        }
    }

    .checkbox__input:checked + .checkbox__label svg {
        opacity: 1;
        transform: scale(1);
    }

    .button--microsoft, .button--microsoft:hover, .button--microsoft:focus {
        background-color: black;
    }

    .button--microsoft svg {
        margin-right: 1rem;
    }
`,
);
