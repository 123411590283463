import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useQueryParam} from 'use-query-params';
import actions from 'store/actions';
import * as routes from 'routes/index';
import ResetPassword from './ResetPassword';
import {throwIfInvalidApiResponse} from 'util/errorHelper';

const ResetPasswordWithState = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email = '', setEmail] = useQueryParam('email');
    // TODO: use useFormState
    const [values, setValues] = useState({
        password: '',
        passwordConfirm: '',
    });
    const [errors, setErrors] = useState({});
    const {token} = useParams('token');

    const onSubmit = async e => {
        e.preventDefault();
        try {
            const res = await dispatch(
                actions.auth.resetPassword({
                    email,
                    password: values.password,
                    password_confirmation: values.passwordConfirm,
                    token,
                }),
            );
            throwIfInvalidApiResponse(res);
            history.push(`${routes.login()}?email=${email}`);
        } catch (error) {
            if (error.statusCode === 422) {
                const {
                    payload: {newErrors},
                } = error;
                setErrors(newErrors);
            }
        }
    };

    return (
        <ResetPassword
            onSubmit={onSubmit}
            email={email}
            setEmail={setEmail}
            values={values}
            errors={errors}
            setValues={newValues => setValues({...values, ...newValues})}
        />
    );
};

export default ResetPasswordWithState;
