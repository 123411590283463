import React from 'react';
import {Route} from 'react-router-dom';
import AuthorizedPage from 'components/pages/AuthorizedPage';
import TimezoneWarning from 'components/util/TimezoneWarning';

import PropTypes from 'prop-types';
import {useHistory} from '../../../node_modules/react-router-dom/cjs/react-router-dom.min';

const AuthorizedRoute = ({
    component: Component,
    isAuthenticated,
    userRole,
    ...rest
}) => {
    const history = useHistory();
    const currentPath = history.location.pathname;
    if (
        !currentPath.includes('/editor') &&
        !currentPath.includes('/answers') &&
        !currentPath.includes('/follow-up') &&
        !currentPath.includes('/comments')
    ) {
        // TODO: https://linear.app/meet-roger/issue/SUP-593/check-errors-for-react-redux-history-in-the-admin-app
        // setLastRootLocation(currentPath)
    }

    return (
        <Route
            {...rest}
            render={props => (
                <AuthorizedPage
                    isAuthenticated={isAuthenticated}
                    userRole={userRole}>
                    <TimezoneWarning />
                    <Component {...props} />
                </AuthorizedPage>
            )}
        />
    );
};

AuthorizedRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,
};
export default AuthorizedRoute;
