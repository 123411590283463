import configureStoreDev from './store.dev';
import configureStoreProd from './store.prod';

const exports = {
    configureStore: configureStoreDev,
};

if (process.env.NODE_ENV === 'production') {
    exports.configureStore = configureStoreProd;
}

export default exports.configureStore;
