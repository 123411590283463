import onTab from 'medium-draft/lib/handlers/onTab';
import onUpArrow from 'medium-draft/lib/handlers/onUpArrow';
import handlePastedText from 'medium-draft/lib/handlers/handlePastedText';
import handleReturn from 'medium-draft/lib/handlers/handleReturn';
import handleKeyCommand from 'medium-draft/lib/handlers/handleKeyCommand';
import handleBeforeInput from 'medium-draft/lib/handlers/handleBeforeInput';
import blockRendererFn from 'medium-draft/lib/handlers/blockRendererFn';
import blockStyleFn from 'medium-draft/lib/handlers/blockStyleFn';
import keyBindingFn from 'medium-draft/lib/handlers/keyBindingFn';

export default {
    onTab,
    onUpArrow,
    handlePastedText,
    handleReturn,
    handleKeyCommand,
    handleBeforeInput,
    blockRendererFn,
    blockStyleFn,
    keyBindingFn,
};
