import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    SURVEYS_LIST_REQUEST,
    SURVEYS_LIST_SUCCESS,
    SURVEYS_LIST_FAILURE,
    MESSAGE_SURVEYS_LIST_REQUEST,
    MESSAGE_SURVEYS_LIST_SUCCESS,
    MESSAGE_SURVEYS_LIST_FAILURE,
    SURVEYS_VIEW_REQUEST,
    SURVEYS_VIEW_SUCCESS,
    SURVEYS_VIEW_FAILURE,
    SURVEYS_CREATE_REQUEST,
    SURVEYS_CREATE_SUCCESS,
    SURVEYS_CREATE_FAILURE,
    SURVEYS_UPDATE_REQUEST,
    SURVEYS_UPDATE_SUCCESS,
    SURVEYS_UPDATE_FAILURE,
    SURVEYS_DELETE_REQUEST,
    SURVEYS_DELETE_SUCCESS,
    SURVEYS_DELETE_FAILURE,
} from '../../actions/surveys';

const surveys = combineReducers({
    byId: createById({
        listActions: [
            [SURVEYS_LIST_REQUEST, SURVEYS_LIST_SUCCESS, SURVEYS_LIST_FAILURE],
            [
                MESSAGE_SURVEYS_LIST_REQUEST,
                MESSAGE_SURVEYS_LIST_SUCCESS,
                MESSAGE_SURVEYS_LIST_FAILURE,
            ],
        ],
        viewActions: [
            SURVEYS_VIEW_REQUEST,
            SURVEYS_VIEW_SUCCESS,
            SURVEYS_VIEW_FAILURE,
        ],
        createActions: [
            SURVEYS_CREATE_REQUEST,
            SURVEYS_CREATE_SUCCESS,
            SURVEYS_CREATE_FAILURE,
        ],
        updateActions: [
            SURVEYS_UPDATE_REQUEST,
            SURVEYS_UPDATE_SUCCESS,
            SURVEYS_UPDATE_FAILURE,
        ],
        deleteActions: [
            SURVEYS_DELETE_REQUEST,
            SURVEYS_DELETE_SUCCESS,
            SURVEYS_DELETE_FAILURE,
        ],
    }),
    paginated: paginate({
        listActions: [
            SURVEYS_LIST_REQUEST,
            SURVEYS_LIST_SUCCESS,
            SURVEYS_LIST_FAILURE,
        ],
        requestFilterKey: 'filters',
    }),
});

export default surveys;
