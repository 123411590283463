import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M10.922 13.313c.375-.985.75-1.735 1.031-2.25.188-.422.422-.75.61-1.032.187-.281.374-.562.656-.797.281-.28.562-.468.937-.609.328-.094.703-.188 1.125-.188h3.422v2.532c0 .094.047.187.094.281.094.094.187.14.328.14.094 0 .234-.046.281-.14l4.266-4.172c.094-.094.094-.187.094-.328 0-.094 0-.188-.094-.281l-4.219-4.172c-.14-.094-.234-.145-.328-.145-.14 0-.234.051-.328.094-.047.094-.094.188-.094.332v2.485h-3.422a6.38 6.38 0 0 0-1.687.234c-.516.14-.985.328-1.406.562a6.7 6.7 0 0 0-1.22.891c-.374.375-.702.75-.984 1.078-.234.375-.515.797-.843 1.313a19.59 19.59 0 0 0-.704 1.406c-.187.375-.421.844-.656 1.453-.422.938-.75 1.688-1.031 2.25-.234.375-.422.703-.61.984a5.396 5.396 0 0 1-.656.797 2.93 2.93 0 0 1-.937.61c-.328.14-.703.187-1.172.187H.422c-.14 0-.234.047-.281.14-.094.048-.141.188-.141.282v2.531c0 .094.047.235.14.282.047.093.141.14.282.14h3c.562 0 1.125-.094 1.687-.234.516-.14.985-.328 1.407-.563.421-.187.796-.515 1.171-.89.422-.375.75-.703.985-1.078.281-.329.562-.797.844-1.313.328-.516.562-.984.75-1.36.187-.421.375-.89.656-1.453zM.422 8.436h2.953c.422 0 .75.047 1.125.188.328.094.61.281.797.422.234.187.469.469.703.75.234.328.422.61.516.844.144.234.332.562.52.937.655-1.547 1.265-2.765 1.827-3.562C7.41 6.046 5.63 5.062 3.38 5.062H.422c-.14 0-.234.047-.281.094A.426.426 0 0 0 0 5.484v2.532c0 .093.047.187.14.28.051.095.145.142.282.142zm18.844 5.297a.506.506 0 0 0-.328-.14.426.426 0 0 0-.329.14c-.047.094-.093.188-.093.282v2.53h-3.422c-.375 0-.75-.046-1.078-.187-.329-.14-.61-.28-.797-.468a3.66 3.66 0 0 1-.703-.75c-.239-.282-.426-.563-.52-.797a8.914 8.914 0 0 1-.516-.985c-.656 1.594-1.265 2.766-1.78 3.61.234.328.468.656.702.89.282.282.52.516.75.703.239.235.52.375.801.516.281.188.516.281.75.375.235.14.516.188.844.281.328.047.61.094.844.094.234.047.562.094.937.094h3.188v2.484c0 .14.047.235.14.328.047.094.188.094.282.094.14 0 .234 0 .28-.094l4.266-4.218c.094-.047.141-.141.141-.282 0-.136-.047-.234-.14-.28zm0 0"
        />
    );
}
