import React from 'react';

const Actions = () => (
    <g fill="currentColor">
        <circle cx="12" cy="12" r="2" />
        <circle cx="19" cy="12" r="2" />
        <circle cx="5" cy="12" r="2" />
    </g>
);

export default Actions;
