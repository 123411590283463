import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M21 11.291C21 15.83 16.952 19.473 12 19.473C11.3727 19.4727 10.7469 19.4128 10.131 19.294"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.599 17.826C4.42 16.336 3 13.974 3 11.291"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.599 17.826C6.598 18.68 6.6 19.832 6.6 21.041"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 11.141C3 6.602 7.048 2.959 12 2.959C16.952 2.959 21 6.602 21 11.141"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.131 19.294L6.60001 21.041"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
