import React from 'react';
import List from 'components/List/List';
import Icon from 'lib/components/Icon';

const columns = [
    {
        key: 1,
        renderer: content => <Icon name={content} />,
    },
    {key: 2},
    {key: 3, grow: true},
    {key: 4},
];

const MostRead = ({messages, onListEnd, isFetching, onRowClick}) => (
    <List
        rows={messages}
        columns={columns}
        active={0}
        onListEnd={onListEnd}
        isFetching={isFetching}
        onRowClick={onRowClick}
        widths={['3rem', '2rem', '100%', '3rem']}
    />
);

export default MostRead;
