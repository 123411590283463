export const ROGER_STAFF = 'roger-staff';
export const SUPER_ADMIN = 'super-admin';
export const ADMIN = 'admin';
export const SUPER_USER = 'super-user';
export const SUPER_USER_ADVANCED = 'super-user-advanced';
export const USER = 'user';

// TODO: when roles are replaced with permissions isAdmin and isSuperUser helpers should be removed
export const isRogerStaff = role => role === ROGER_STAFF;
export const isAdmin = role =>
    role === ADMIN || role === ROGER_STAFF || role === SUPER_ADMIN;
// NOTE: not in any way a super user, this is a step below admin
export const isSuperUser = role =>
    role === SUPER_USER || role === SUPER_USER_ADVANCED;
export const isPublisher = role => role === SUPER_USER_ADVANCED;

// Get first role by default
export default user =>
    user &&
    user.roles &&
    user.roles.length &&
    user.roles[0] &&
    user.roles[0].name;
