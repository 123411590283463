import React from 'react';
import Event from './Event';

const EventAdd = () => (
    <g fill="currentColor">
        <Event />

        <rect x="8" y="14" width="9" height="2" />
        <polygon
            transform="translate(12.500000, 15.000000) rotate(-90.000000) translate(-12.500000, -15.000000) "
            points="8 14 17 14 17 16 8 16"
        />
    </g>
);

export default EventAdd;
