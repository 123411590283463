import React, {useEffect} from 'react';
import {useSelector, useDispatch, connect} from 'react-redux';
import {setModalVisibility} from 'lib/store/actions/modals';
import {showError} from 'lib/store/actions/notifications';
import {
    getErrorNotification,
    throwIfInvalidApiResponse,
} from 'util/errorHelper';
import {
    fetchOnBoarding,
    fetchUserActivity,
} from 'lib/store/actions/analytics/userActivity';
import {fetchMood} from 'lib/store/actions/analytics/mood';
import {fetchAdminUsage} from 'lib/store/actions/analytics/appDomains';
import {
    fetchMessages,
    fetchPopularity,
    fetchMessageReadsByWeekDayAndHour,
} from 'lib/store/actions/analytics/messages';
import Dashboard from './Dashboard';

const tryFunction = fn => {
    try {
        return fn();
    } catch (e) {
        return null;
    }
};
const DashboardWithState = companySettings => {
    const hasFetchedOnboarding = useSelector(
        ({analytics}) => analytics.userActivity.hasFetchedOnboarding,
    );
    const hasFetchedUserActivity = useSelector(
        ({analytics}) => analytics.userActivity.hasFetchedActivity,
    );
    const hasFetchedMood = useSelector(
        ({analytics}) => analytics.mood.hasFetched,
    );
    const hasFetchedMessages = useSelector(
        ({analytics}) => analytics.messages.hasFetchedMessages,
    );
    const hasFetchedPopularity = useSelector(
        ({analytics}) => analytics.messages.hasFetchedPopularity,
    );
    const hasFetchedAdminUsage = useSelector(
        ({analytics}) => analytics.appDomains.hasFetched,
    );
    const hasFetchedMessageReadsByWeekDayAndHour = useSelector(
        ({analytics}) =>
            analytics.messages.hasFetchedMessageReadsByWeekdayAndHour,
    );
    const dispatch = useDispatch();

    const openModal = modal => {
        dispatch(setModalVisibility(modal, true));
    };

    const fetchData = async (alreadyFetched, action) => {
        if (!alreadyFetched) {
            try {
                const res = await dispatch(action());
                throwIfInvalidApiResponse(res);
            } catch (error) {
                const {statusCode} =
                    tryFunction(() => JSON.parse(error.message)) || error;
                if (statusCode !== 404) {
                    dispatch(showError(getErrorNotification(error)));
                }
            }
        }
    };

    useEffect(() => {
        fetchData(hasFetchedOnboarding, fetchOnBoarding);
        fetchData(hasFetchedUserActivity, fetchUserActivity);
        fetchData(hasFetchedMood, fetchMood);
        fetchData(hasFetchedAdminUsage, fetchAdminUsage);
        fetchData(hasFetchedMessages, fetchMessages);
        fetchData(hasFetchedPopularity, fetchPopularity);
        fetchData(
            hasFetchedMessageReadsByWeekDayAndHour,
            fetchMessageReadsByWeekDayAndHour,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Dashboard openModal={openModal} />;
};

const mapStateToProps = state => ({
    companySettings: state.company.data.settings,
});

export default connect(mapStateToProps)(DashboardWithState);
