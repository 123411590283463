import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {
    TYPE_HAPPINESS_POLL,
    TYPE_MULTIPLE_CHOICE,
    TYPE_OPINION_SCALE,
    TYPE_RATING,
    TYPE_RSVP,
    TYPE_YES_NO_QUESTION,
} from 'lib/store/actions/surveys';
import IconButton from 'lib/components/IconButton';
import {modalForSurveyType} from 'lib/store/actions/modals';
import actions from 'store/actions';

const SurveySidebar = ({
    startNewSurvey,
    setActiveSidebar,
    setModalVisibility,
}) => {
    const startSurveyWithType = surveyType => {
        startNewSurvey(surveyType);
        setActiveSidebar('');
        setModalVisibility(modalForSurveyType(surveyType), true);
    };

    return (
        <div>
            <IconButton
                icon="smiley-o"
                onClick={() => {
                    startSurveyWithType(TYPE_HAPPINESS_POLL);
                }}>
                <Translate id="editor.survey.happiness_poll" />
            </IconButton>
            <IconButton
                icon="multipleChoice"
                onClick={() => {
                    startSurveyWithType(TYPE_MULTIPLE_CHOICE);
                }}>
                <Translate id="editor.survey.multiple_choice" />
            </IconButton>
            <IconButton
                icon="yesNo"
                onClick={() => {
                    startSurveyWithType(TYPE_YES_NO_QUESTION);
                }}>
                <Translate id="editor.survey.yes_no" />
            </IconButton>
            <IconButton
                icon="scale"
                onClick={() => {
                    startSurveyWithType(TYPE_OPINION_SCALE);
                }}>
                <Translate id="editor.survey.opinion_scale" />
            </IconButton>
            <IconButton
                icon="star"
                onClick={() => {
                    startSurveyWithType(TYPE_RATING);
                }}>
                <Translate id="editor.survey.rating" />
            </IconButton>
            <IconButton
                icon="event"
                onClick={() => {
                    startSurveyWithType(TYPE_RSVP);
                }}>
                <Translate id="editor.survey.rsvp" />
            </IconButton>
        </div>
    );
};

const mapStateToProps = state => ({
    messages: state.messages.byId,
    contentId: state.editor.contentId,
    contentType: state.editor.contentType,
});

const mapDispatchToProps = dispatch => ({
    startNewSurvey: type => dispatch(actions.surveyEditor.startNewSurvey(type)),
    setModalVisibility: (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility)),
    setActiveSidebar: sidebarType =>
        dispatch(actions.editor.setActiveSidebar(sidebarType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveySidebar);
