import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import useTranslate from 'components/hooks/useTranslate';
import useEditorState from 'components/hooks/useEditorState';
import actions from 'store/actions';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import EditorAnswers from './EditorAnswers';
import useFetchSurveys from '../../hooks/useFetchSurveys';

const EditorAnswersWithState = ({messageId}) => {
    const {translate} = useTranslate();
    const {editor, hasSurveys} = useEditorState();

    const dispatch = useDispatch();
    const setAcceptAnswers = acceptAnswers =>
        dispatch(actions.editor.setAcceptAnswers(acceptAnswers));
    const answersState = useSelector(({surveys, contentSurveys, messages}) => ({
        surveys,
        contentSurveys,
        messages,
    }));

    const {isLoading} = useFetchSurveys({
        editor,
        hasSurveys,
        withAnswerStats: 1,
    });

    if (isLoading) {
        return <LoadingWrapper loading size={8} />;
    }

    return (
        <EditorAnswers
            messageId={messageId}
            translate={translate}
            surveys={answersState.surveys.byId}
            contentSurveys={answersState.contentSurveys}
            hasSurveys={hasSurveys}
            acceptAnswers={editor.acceptAnswers}
            setAcceptAnswers={setAcceptAnswers}
            fetchSurveyExport={() =>
                dispatch(actions.messages.fetchSurveyExport(messageId))
            }
            isFetchingExport={answersState.messages.isFetchingExport}
            showNotification={notification =>
                dispatch(actions.notifications.addNotification(notification))
            }
        />
    );
};

export default EditorAnswersWithState;
