import React from 'react';
import CustomBlocks from 'lib/constants/customBlocks';

import YesNoButton from '../../YesNoButton';
import CustomBlock from '../CustomBlock/CustomBlock';

const YesNoQuestionBlock = ({
    translate,
    survey,
    onEdit,
    onDelete,
    onChange,
    interactive,
    canEdit,
}) => {
    const response = survey.response && survey.response.option.value;

    return (
        <CustomBlock
            type={CustomBlocks.SURVEY_BLOCK}
            label={translate('editor.survey.yes_no_question')}
            icon="yesNo"
            title={survey.content.question}
            description={survey.content.description}
            onEdit={() => {
                onEdit(survey);
            }}
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            <YesNoButton
                interactive={interactive}
                isActive={response === 1}
                onClick={() => onChange({label: 'Yes', value: 1})}>
                {translate('global.yes')}
            </YesNoButton>

            <YesNoButton
                interactive={interactive}
                isActive={response === 0}
                onClick={() => onChange({label: 'No', value: 0})}>
                {translate('global.no')}
            </YesNoButton>
        </CustomBlock>
    );
};

export default YesNoQuestionBlock;
