import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {showError} from 'lib/store/actions/notifications';
import {getErrorNotification} from 'util/errorHelper';
import actions from 'store/actions';
import {CONTENT_TYPE_AUTOMATED_MESSAGES} from 'lib/store/actions/editor';

const useFetchSurveys = ({editor, hasSurveys, withAnswerStats = 0}) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {contentId} = editor;
            if (contentId !== null && hasSurveys) {
                let {contentType} = editor;
                // TODO: atm the endpoint for automated messages is auto-messages. This should be changed.
                if (editor.contentType === CONTENT_TYPE_AUTOMATED_MESSAGES) {
                    contentType = 'auto-messages';
                }

                try {
                    dispatch(
                        actions.surveys.fetchSurveysForContentWithType(
                            {
                                id: contentId,
                            },
                            contentType,
                            {limit: 100, withAnswerStats},
                        ),
                    );
                } catch (error) {
                    dispatch(showError(getErrorNotification(error)));
                }
            }
            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
    };
};

export default useFetchSurveys;
