import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16.138 21H7.858C7.35301 20.9999 6.86675 20.8088 6.49674 20.4651C6.12674 20.1215 5.90035 19.6506 5.863 19.147L4.966 7H19L18.133 19.142C18.0971 19.6466 17.8713 20.1188 17.5011 20.4636C17.1309 20.8083 16.6439 21 16.138 21V21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 11V17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 7H20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 7L15.987 4.298C15.8441 3.91669 15.5881 3.5881 15.2534 3.35615C14.9187 3.1242 14.5212 2.99995 14.114 3H9.886C9.47878 2.99995 9.08126 3.1242 8.74656 3.35615C8.41186 3.5881 8.15593 3.91669 8.013 4.298L7 7"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.429 11L15 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.57098 11L8.99998 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
