export const decodeBridgeMessage = message => JSON.parse(message);
export const encodeBridgeMessage = message => JSON.stringify(message);

export const postMessageToNative = message => {
    const nativeWebView = window.ReactNativeWebView;
    if (nativeWebView) {
        nativeWebView.postMessage(encodeBridgeMessage(message));
    }
};

export const initJSBridgeV3 = setAccessToken => {
    if (window.ReactNativeWebView) {
        window.postMessageToWeb = encodedMessage => {
            const message = decodeBridgeMessage(encodedMessage);
            const {type, data} = message;

            switch (type) {
                case 'auth': {
                    const {auth} = data;
                    // Promise with callback because ReactNativeWebView
                    // does not support the return of a promise for injected JS.
                    setAccessToken({
                        access_token: auth.accessToken,
                        refresh_token: auth.refreshToken,
                        expires_in: auth.expiresIn,
                    });
                    setTimeout(() => {
                        postMessageToNative({
                            type: 'auth_received',
                            data: {},
                        });
                    }, 1);
                    break;
                }
                default:
                    break;
            }
        };
        return true;
    }
    return undefined;
};

export const initJSBridgeV2 = setAccessToken => {
    document.addEventListener('message', event => {
        const {auth} = JSON.parse(event.data);
        if (auth) {
            setAccessToken({
                access_token: auth.accessToken,
                refresh_token: auth.refreshToken,
                expires_in: auth.expiresIn,
            });
        }
    });
};

export const initJSBridges = setAccessToken => {
    // JS bridge for app V3
    initJSBridgeV3(setAccessToken);
    // JS bridge for app V2
    initJSBridgeV2(setAccessToken);
};
