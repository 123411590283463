import {CALL_API} from '../../constants/api';
import {GET, POST, PUT, DELETE} from '../services';

const createSegmentUrl = (groupId, segmentId) =>
    `/segment-groups/${groupId || ''}${segmentId ? `/segments/${segmentId}` : ''}`;

export const GET_SEGMENT_GROUPS_REQUEST = 'GET_SEGMENT_GROUPS_REQUEST';
export const GET_SEGMENT_GROUPS_SUCCESS = 'GET_SEGMENT_GROUPS_SUCCESS';
export const GET_SEGMENT_GROUPS_FAILURE = 'GET_SEGMENT_GROUPS_FAILURE';

export const getSegmentGroups = () => ({
    [CALL_API]: {
        endpoint: '/segment-groups/all',
        method: GET,
        types: [
            GET_SEGMENT_GROUPS_REQUEST,
            GET_SEGMENT_GROUPS_SUCCESS,
            GET_SEGMENT_GROUPS_FAILURE,
        ],
    },
});

export const GET_SEGMENT_GROUP_REQUEST = 'GET_SEGMENT_GROUP_REQUEST';
export const GET_SEGMENT_GROUP_SUCCESS = 'GET_SEGMENT_GROUP_SUCCESS';
export const GET_SEGMENT_GROUP_FAILURE = 'GET_SEGMENT_GROUP_FAILURE';

export const getSegmentGroup = groupId => ({
    [CALL_API]: {
        endpoint: createSegmentUrl(groupId),
        method: GET,
        types: [
            GET_SEGMENT_GROUP_REQUEST,
            GET_SEGMENT_GROUP_SUCCESS,
            GET_SEGMENT_GROUP_FAILURE,
        ],
    },
});

export const CREATE_SEGMENT_GROUP_REQUEST = 'CREATE_SEGMENT_GROUP_REQUEST';
export const CREATE_SEGMENT_GROUP_SUCCESS = 'CREATE_SEGMENT_GROUP_SUCCESS';
export const CREATE_SEGMENT_GROUP_FAILURE = 'CREATE_SEGMENT_GROUP_FAILURE';

export const createSegment = (segment, groupId) => ({
    [CALL_API]: {
        endpoint: `/segment-groups/${groupId ? `${groupId}/segments` : ''}`,
        method: POST,
        body: segment,
        types: [
            CREATE_SEGMENT_GROUP_REQUEST,
            CREATE_SEGMENT_GROUP_SUCCESS,
            CREATE_SEGMENT_GROUP_FAILURE,
        ],
    },
});

export const UPDATE_SEGMENT_GROUP_REQUEST = 'UPDATE_SEGMENT_GROUP_REQUEST';
export const UPDATE_SEGMENT_GROUP_SUCCESS = 'UPDATE_SEGMENT_GROUP_SUCCESS';
export const UPDATE_SEGMENT_GROUP_FAILURE = 'UPDATE_SEGMENT_GROUP_FAILURE';

export const updateSegment = (segment, groupId, segmentId) => ({
    [CALL_API]: {
        endpoint: createSegmentUrl(groupId, segmentId),
        method: PUT,
        body: segment,
        types: [
            UPDATE_SEGMENT_GROUP_REQUEST,
            UPDATE_SEGMENT_GROUP_SUCCESS,
            UPDATE_SEGMENT_GROUP_FAILURE,
        ],
    },
});

export const DELETE_SEGMENT_GROUP_REQUEST = 'DELETE_SEGMENT_GROUP_REQUEST';
export const DELETE_SEGMENT_GROUP_SUCCESS = 'DELETE_SEGMENT_GROUP_SUCCESS';
export const DELETE_SEGMENT_GROUP_FAILURE = 'DELETE_SEGMENT_GROUP_FAILURE';

export const deleteSegment = (groupId, segmentId) => ({
    [CALL_API]: {
        endpoint: createSegmentUrl(groupId, segmentId),
        method: DELETE,
        types: [
            DELETE_SEGMENT_GROUP_REQUEST,
            DELETE_SEGMENT_GROUP_SUCCESS,
            DELETE_SEGMENT_GROUP_FAILURE,
        ],
    },
});

export default {
    getSegmentGroups,
    getSegmentGroup,
    createSegment,
    updateSegment,
    deleteSegment,
};
