import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M17.941 5.781a.305.305 0 00.305-.304.278.278 0 00-.082-.2h.031a.3.3 0 00.301-.3.308.308 0 00-.082-.215.3.3 0 00.23-.356.283.283 0 00-.093-.156.294.294 0 00.199-.285.3.3 0 00-.3-.3h-.962a.16.16 0 01-.152-.15 2.53 2.53 0 00.152-.96.302.302 0 00-.293-.313.302.302 0 00-.312.293v.02c0 .765-.895 1.199-.906 1.207v1.617c.355 0 .382.402 1.058.402zm0 0M15.672 5.43V3.715a.202.202 0 00-.203-.203h-.754v2.117h.754a.2.2 0 00.203-.2zm0 0M4.941 11.313a.305.305 0 00.305-.305.278.278 0 00-.082-.2h.031c.168 0 .301-.136.301-.304a.305.305 0 00-.082-.211.303.303 0 00.23-.355.307.307 0 00-.093-.16.29.29 0 00.199-.282.3.3 0 00-.3-.3h-.962a.16.16 0 01-.152-.15 2.53 2.53 0 00.152-.96.302.302 0 00-.293-.313.302.302 0 00-.312.293v.02c0 .766-.895 1.2-.906 1.207v1.617c.355 0 .382.402 1.058.402zm0 0M2.672 10.96V9.247a.202.202 0 00-.203-.203h-.754v2.117h.754a.2.2 0 00.203-.2zm0 0M3.125 13.531h.75v.75h-.75zm0 0M3.125 12.031h.75v.75h-.75zm0 0M3.125 16.531h.75v.75h-.75zm0 0M3.125 15.031h.75v.75h-.75zm0 0M7.375 10.281h.75v.75h-.75zm0 0M7.375 11.781h.75v.75h-.75zm0 0M7.375 7.281h.75v.75h-.75zm0 0M7.375 8.781h.75v.75h-.75zm0 0M12.875 13.781h.75v.75h-.75zm0 0M12.875 12.281h.75v.75h-.75zm0 0M16.125 12.531h.75v.75h-.75zm0 0M16.125 9.531h.75v.75h-.75zm0 0M16.125 11.031h.75v.75h-.75zm0 0M16.125 6.531h.75v.75h-.75zm0 0M16.125 8.031h.75v.75h-.75zm0 0M20.125 12.031h.75v.75h-.75zm0 0M20.125 13.531h.75v.75h-.75zm0 0M20.125 16.531h.75v.75h-.75zm0 0M20.125 15.031h.75v.75h-.75zm0 0M20.125 10.531h.75v.75h-.75zm0 0" />
            <path d="M22 20.531V18h-3v1.281h-1.25v-5.25h-2.5v3.5h-.75v-2.25H12v4h-1v-2H9.25v-4h-3v6H5v-1.25H2v2.5H1v1.5h22v-1.5zm0 0M13.25 11.531s1.5-1.148 1.5-2.074c0-.617-.945-1.277-1.5-.176-.555-1.101-1.5-.441-1.5.176 0 .926 1.5 2.074 1.5 2.074zm0 0M20.5 9.781S22 8.633 22 7.707c0-.617-.945-1.277-1.5-.176-.555-1.101-1.5-.441-1.5.176 0 .926 1.5 2.074 1.5 2.074zm0 0M7.75 6.531s1.5-1.148 1.5-2.074c0-.617-.945-1.277-1.5-.176-.555-1.101-1.5-.441-1.5.176 0 .926 1.5 2.074 1.5 2.074zm0 0" />
        </g>
    );
}
