import {schema} from 'normalizr';
import {CALL_API} from '../../../constants/api';
import {POST, GET} from '../../services';

export const EXTERNAL_MEDIA_ITEM_SCHEMA = new schema.Entity(
    'externalMediaItem',
);

const getMediaSource = (
    type,
    {page, limit, filters = {}, filters: {query} = {}} = {},
    types,
) => ({
    [CALL_API]: {
        endpoint: `/media/sources/${type}`,
        method: GET,
        query: {
            search: query,
            page,
            limit,
        },
        filters,
        types,
        listType: type,
        schema: [EXTERNAL_MEDIA_ITEM_SCHEMA],
    },
});

const postMediaSource = (type, item, types) => ({
    [CALL_API]: {
        endpoint: `/media/sources/${type}`,
        method: POST,
        body: item,
        types,
    },
});

export const GET_UNSPLASH_REQUEST = 'GET_UNSPLASH_REQUEST';
export const GET_UNSPLASH_SUCCESS = 'GET_UNSPLASH_SUCCESS';
export const GET_UNSPLASH_FAILURE = 'GET_UNSPLASH_FAILURE';

export const fetchUnsplashItems = params =>
    getMediaSource('unsplash', params, [
        GET_UNSPLASH_REQUEST,
        GET_UNSPLASH_SUCCESS,
        GET_UNSPLASH_FAILURE,
    ]);

export const POST_UNSPLASH_REQUEST = 'POST_UNSPLASH_REQUEST';
export const POST_UNSPLASH_SUCCESS = 'POST_UNSPLASH_SUCCESS';
export const POST_UNSPLASH_FAILURE = 'POST_UNSPLASH_FAILURE';

export const importUnsplashItem = item =>
    postMediaSource('unsplash', item, [
        POST_UNSPLASH_REQUEST,
        POST_UNSPLASH_SUCCESS,
        POST_UNSPLASH_FAILURE,
    ]);

export default {
    fetchUnsplashItems,
    importUnsplashItem,
};
