import React from 'react';
import {ErrorBoundary} from '@sentry/react';
import AppFailure from './AppFailure';

const AppErrorBoundary = ({error, children}) => {
    const page = <AppFailure error={error} />;

    return (
        <ErrorBoundary fallback={page} showDialog>
            {children}
        </ErrorBoundary>
    );
};

export default AppErrorBoundary;
