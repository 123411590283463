import getYear from 'date-fns/get_year/';
import getMonth from 'date-fns/get_month/';
import getWeek from 'date-fns/get_iso_week/';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

export const LAST_30 = 'last30days';
export const MONTHLY = 'MONTHLY';
export const WEEKLY = 'WEEKLY';
export const DATE_FORMAT = 'YYYY/MM/DD';

export const getMonthData = (data, date) => {
    if (date === LAST_30) {
        return data.find(item => item.type === LAST_30);
    }

    const selectedDate = parse(date || new Date());
    const year = getYear(selectedDate);
    const month = getMonth(selectedDate) + 1;

    return data.find(item => item.year === year && item.month === month);
};

export const getWeekData = (data, date) => {
    const selectedDate = date || new Date();
    const year = getYear(selectedDate);
    const week = getWeek(selectedDate);

    return data.find(item => item.year === year && item.week === week);
};

export const getMonthUsage = (appDomains, adminUsage, date) => {
    const monthUsage = getMonthData(adminUsage, date);

    if (!monthUsage) {
        return [];
    }

    return appDomains.map(domain => ({
        slug: domain.slug,
        value: monthUsage[domain.slug],
        color: domain.color,
        icon: domain.icon,
        name: domain.name,
    }));
};

export const parseDate = ({year, month}) =>
    format(new Date(year, month - 1, 1), DATE_FORMAT);

export const parseOptions = (array, dateLocale, last30DaysLabelRenderer) =>
    array.map(period => {
        if (period.type === LAST_30) {
            return {
                label: last30DaysLabelRenderer(),
                value: LAST_30,
            };
        }

        const date = parseDate(period);
        return {
            label: format(date, 'MMMM YYYY', dateLocale),
            value: format(date, DATE_FORMAT),
        };
    });

export const parseChartData = (
    array,
    key,
    dateLocale,
    last30DaysLabelRenderer,
) =>
    array
        .map(period => {
            let id;
            let name = '';
            const value = key ? period[key] : 0;

            if (period.type === LAST_30) {
                id = LAST_30;
                name = last30DaysLabelRenderer();
            } else {
                const date = parseDate(period);
                id = format(date, DATE_FORMAT);
                name = format(date, 'MMM', dateLocale).toUpperCase();
            }

            return {
                ...period,
                id,
                name,
                value,
            };
        })
        .reverse();
