import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M10.5 6.75h4V6c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v6.75c0 .55.45 1 1 1h1.5v2.5l2.5-2.5h1.5v-5c0-1.102.898-2 2-2zm0 0" />
            <path d="M21 7.75H10.5c-.55 0-1 .45-1 1v6.75c0 .55.45 1 1 1H17l2.5 2.5v-2.5H21c.55 0 1-.45 1-1V8.75c0-.55-.45-1-1-1zm0 0" />
        </g>
    );
}
