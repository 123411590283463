export const SET_INPUT_DATA = 'SET_INPUT_DATA';

export const setData = data => ({
    type: SET_INPUT_DATA,
    payload: {
        data,
    },
});

export default {
    setData,
};
