const list = ({listActions}) => {
    const INITIAL_STATE = {
        isFetching: false,
        isFailed: false,
        hasFetched: false,
        entries: [],
    };

    return (state = INITIAL_STATE, action) => {
        const [listRequestType, listSuccessType, listFailureType] = listActions;
        switch (action.type) {
            case listRequestType:
                return {
                    ...state,
                    isFetching: true,
                    isFailed: false,
                    hasFetched: false,
                    entries: [],
                };
            case listSuccessType:
                return {
                    ...state,
                    entries: action.payload.data,
                    isFetching: false,
                    hasFetched: true,
                    isFailed: false,
                };
            case listFailureType:
                return {
                    ...state,
                    isFetching: false,
                    isFailed: true,
                    hasFetched: false,
                    entries: [],
                };
            default:
                return state;
        }
    };
};

export default list;
