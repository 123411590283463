import React from 'react';
import {Route} from 'react-router-dom';
import UnauthorizedPage from 'components/pages/UnauthorizedPage';

const UnauthorizedRoute = ({
    component: Component,
    isAuthenticated,
    userRole,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => (
            <UnauthorizedPage
                isAuthenticated={isAuthenticated}
                userRole={userRole}>
                <Component {...props} />
            </UnauthorizedPage>
        )}
    />
);

export default UnauthorizedRoute;
