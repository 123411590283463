import {CALL_API} from '../../constants/api';

let activeRefreshTokenRequestPromise = null;

const isRefreshAccessTokenAction = (action, exampleAction) =>
    action[CALL_API].endpoint === exampleAction[CALL_API].endpoint;

const isAccessToken = accessToken =>
    typeof accessToken === 'string' && accessToken.length > 0;

const attempRefreshAccessToken =
    ({
        accessToken,
        refreshToken,
        currentAction,
        successActionCreator,
        failureActionCreator,
        refreshAccessTokenCreator,
        refreshAccessTokenSuccessType,
        refreshAccessTokenFailureType,
    }) =>
    store =>
    () =>
    async response => {
        const refreshAccessTokenAction =
            refreshAccessTokenCreator(refreshToken);

        if (
            response &&
            !response.ok &&
            response.status === 401 &&
            !isRefreshAccessTokenAction(
                currentAction,
                refreshAccessTokenAction,
            ) &&
            isAccessToken(accessToken)
        ) {
            // We are clearly in a fail scenario that can be handled by refreshing the token.
            if (!activeRefreshTokenRequestPromise) {
                activeRefreshTokenRequestPromise = store
                    .dispatch(refreshAccessTokenAction)
                    .then(resultAction => {
                        activeRefreshTokenRequestPromise = null;
                        if (
                            resultAction.type === refreshAccessTokenSuccessType
                        ) {
                            store.dispatch(
                                successActionCreator(resultAction.payload),
                            );
                        }

                        return resultAction;
                    });
            }

            const retryPromise = new Promise((resolve, reject) => {
                activeRefreshTokenRequestPromise.then(
                    resultAction => {
                        switch (resultAction.type) {
                            case refreshAccessTokenSuccessType:
                                store.dispatch(currentAction).then(
                                    result => {
                                        resolve(result);
                                    },
                                    result => {
                                        reject(result);
                                    },
                                );
                                break;
                            case refreshAccessTokenFailureType:
                            default:
                                reject(response);
                                store.dispatch(failureActionCreator());
                        }
                    },
                    () => {
                        reject(response);
                        store.dispatch(failureActionCreator());
                    },
                );
            });

            return retryPromise;
        }

        return response;
    };

export default attempRefreshAccessToken;
