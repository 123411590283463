import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M13 13H11C10.4696 13 9.96086 13.2107 9.58579 13.5858C9.21071 13.9609 9 14.4696 9 15V21H15V15C15 14.4696 14.7893 13.9609 14.4142 13.5858C14.0391 13.2107 13.5304 13 13 13Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 9.63158L12 3L20 9.63158V21H4V9.63158Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </g>
    );
}
