import {
    TYPE_HAPPINESS_POLL,
    TYPE_MULTIPLE_CHOICE,
    TYPE_OPINION_SCALE,
    TYPE_RATING,
    TYPE_RSVP,
    TYPE_YES_NO_QUESTION,
} from './surveys';

export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE';
export const SET_CONFIRM_MODAL_DATA = 'SET_CONFIRM_MODAL_DATA';

export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';

export const MODAL_CONFIRM = 'MODAL_CONFIRM';
export const MODAL_INPUT = 'MODAL_INPUT';

export const MODAL_HAPPINESS_POLL = 'MODAL_HAPPINESS_POLL';
export const MODAL_MULTIPLE_CHOICE = 'MODAL_MULTIPLE_CHOICE';
export const MODAL_OPINION_SCALE = 'MODAL_OPINION_SCALE';
export const MODAL_RATING = 'MODAL_RATING';
export const MODAL_RSVP = 'MODAL_RSVP';
export const MODAL_YES_NO_QUESTION = 'MODAL_YES_NO_QUESTION';

export const MODAL_USER_DETAIL = 'MODAL_USER_DETAIL';
export const MODAL_USER_DETAIL_ADMIN = 'MODAL_USER_DETAIL_ADMIN';
export const SET_USER_DETAIL_DATA = 'SET_USER_DETAIL_DATA';
export const MODAL_IMPORT_ERRORS = 'MODAL_IMPORT_ERRORS';
export const MODAL_CHANNEL = 'MODAL_CHANNEL';
export const MODAL_USER_LIST = 'MODAL_USER_LIST';
export const MODAL_NEW_MESSAGE = 'MODAL_NEW_MESSAGE';
export const MODAL_AUDIENCE = 'MODAL_AUDIENCE';
export const MODAL_LOCALE = 'MODAL_LOCALE';
export const MODAL_DIRECTORY_NAME = 'MODAL_DIRECTORY_NAME';
export const MODAL_DIRECTORY_AUDIENCE = 'MODAL_DIRECTORY_AUDIENCE';
export const MODAL_FILE = 'MODAL_FILE';
export const MODAL_FILE_AUDIENCE = 'MODAL_FILE_AUDIENCE';
export const MODAL_SELECT_APP_DOMAIN = 'MODAL_SELECT_APP_DOMAIN';

export const setModalVisibility = (modal, visibility) => ({
    type: SET_MODAL_VISIBLE,
    payload: {
        modal,
        visibility,
    },
});

export const setModalLoading = (modal, loading) => ({
    type: SET_MODAL_LOADING,
    payload: {
        modal,
        loading,
    },
});

export const setConfirmModalData = data => ({
    type: SET_CONFIRM_MODAL_DATA,
    payload: {
        data,
    },
});

export const setUserDetailModalData = data => ({
    type: SET_USER_DETAIL_DATA,
    payload: {
        data,
    },
});

export const setInfoModalData = data => ({
    type: SET_CONFIRM_MODAL_DATA,
    payload: {
        data,
    },
});

export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const setModalData = (modal, data) => ({
    type: SET_MODAL_DATA,
    payload: {
        modal,
        data,
    },
});

export const modalForSurveyType = surveyType => {
    switch (surveyType) {
        case TYPE_HAPPINESS_POLL:
            return MODAL_HAPPINESS_POLL;
        case TYPE_MULTIPLE_CHOICE:
            return MODAL_MULTIPLE_CHOICE;
        case TYPE_OPINION_SCALE:
            return MODAL_OPINION_SCALE;
        case TYPE_RATING:
            return MODAL_RATING;
        case TYPE_RSVP:
            return MODAL_RSVP;
        case TYPE_YES_NO_QUESTION:
            return MODAL_YES_NO_QUESTION;
        default:
            return undefined;
    }
};

export default {
    setModalVisibility,
    setModalLoading,
    setConfirmModalData,
    modalForSurveyType,
    setUserDetailModalData,
    setModalData,
};
