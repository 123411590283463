import {CALL_API} from '../../constants/api';

export const SMART_TAGS_LIST_REQUEST = 'SMART_TAGS_LIST_REQUEST';
export const SMART_TAGS_LIST_SUCCESS = 'SMART_TAGS_LIST_SUCCESS';
export const SMART_TAGS_LIST_FAILURE = 'SMART_TAGS_LIST_FAILURE';

export const fetchSmartTags = () => ({
    [CALL_API]: {
        endpoint: '/smart-tags',
        method: 'GET',
        query: {
            limit: 100,
        },
        types: [
            SMART_TAGS_LIST_REQUEST,
            SMART_TAGS_LIST_SUCCESS,
            SMART_TAGS_LIST_FAILURE,
        ],
    },
});

export default {
    fetchSmartTags,
};
