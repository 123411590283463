import React from 'react';
import classNames from 'classnames';
import * as theme from 'styles/theme';

const TableRow = ({
    row,
    onClick,
    active,
    isList,
    columns,
    rowRef,
    cellWidths,
    widthToPercent,
    useFixed,
}) => (
    <tr
        ref={rowRef}
        className={classNames(row.className, {
            'tr--clickable': onClick,
            active,
        })}
        onClick={() => {
            if (onClick) {
                onClick(row.key);
            }
        }}>
        {!isList && (
            // THis is some kind of padding for the first cell for
            <td
                style={{
                    width: useFixed ? '1rem' : widthToPercent(cellWidths[0]),
                }}
            />
        )}
        {row.data.map(({className = '', style, content, data}, key) => {
            const {renderer, nowrap} = columns[key];
            const isEmpty =
                content === '' ||
                typeof content === 'undefined' ||
                content === null;
            const {grow} = columns[key];

            return (
                <td
                    key={key}
                    style={{
                        ...style,
                        width: useFixed
                            ? cellWidths[key]
                            : widthToPercent(cellWidths[key + 1]),
                    }}
                    className={classNames(className, {
                        isEmpty,
                        grow,
                        nowrap,
                    })}>
                    {renderer ? renderer(content, data) : content}
                </td>
            );
        })}
        {!isList && (
            <td
                style={{
                    width: useFixed
                        ? '1rem'
                        : widthToPercent(cellWidths[cellWidths.length - 1]),
                }}
            />
        )}

        <style jsx>
            {`
                tr:nth-child(even) {
                    background: ${theme.colorGrey50};
                }

                .tr--clickable {
                    cursor: pointer;
                }

                .isEmpty {
                    min-width: 8rem;
                }

                .nowrap {
                    white-space: nowrap;
                }
            `}
        </style>
    </tr>
);

export default TableRow;
