import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    AUTOMATED_MESSAGES_LIST_REQUEST,
    AUTOMATED_MESSAGES_LIST_SUCCESS,
    AUTOMATED_MESSAGES_LIST_FAILURE,
    AUTOMATED_MESSAGES_DELETE_REQUEST,
    AUTOMATED_MESSAGES_DELETE_SUCCESS,
    AUTOMATED_MESSAGES_DELETE_FAILURE,
    AUTOMATED_MESSAGES_VIEW_REQUEST,
    AUTOMATED_MESSAGES_VIEW_SUCCESS,
    AUTOMATED_MESSAGES_VIEW_FAILURE,
    AUTOMATED_MESSAGES_UPDATE_REQUEST,
    AUTOMATED_MESSAGES_UPDATE_SUCCESS,
    AUTOMATED_MESSAGES_UPDATE_FAILURE,
    AUTOMATED_MESSAGE_ACTIVATE_REQUEST,
    AUTOMATED_MESSAGE_ACTIVATE_SUCCESS,
    AUTOMATED_MESSAGE_ACTIVATE_FAILURE,
    AUTOMATED_MESSAGE_DEACTIVATE_REQUEST,
    AUTOMATED_MESSAGE_DEACTIVATE_SUCCESS,
    AUTOMATED_MESSAGE_DEACTIVATE_FAILURE,
} from '../../actions/messages/automated';

const listActions = [
    AUTOMATED_MESSAGES_LIST_REQUEST,
    AUTOMATED_MESSAGES_LIST_SUCCESS,
    AUTOMATED_MESSAGES_LIST_FAILURE,
];
const deleteActions = [
    AUTOMATED_MESSAGES_DELETE_REQUEST,
    AUTOMATED_MESSAGES_DELETE_SUCCESS,
    AUTOMATED_MESSAGES_DELETE_FAILURE,
];

const messages = combineReducers({
    byId: createById({
        listActions,
        viewActions: [
            [
                AUTOMATED_MESSAGES_VIEW_REQUEST,
                AUTOMATED_MESSAGES_VIEW_SUCCESS,
                AUTOMATED_MESSAGES_VIEW_FAILURE,
            ],
            [
                AUTOMATED_MESSAGE_ACTIVATE_REQUEST,
                AUTOMATED_MESSAGE_ACTIVATE_SUCCESS,
                AUTOMATED_MESSAGE_ACTIVATE_FAILURE,
            ],
            [
                AUTOMATED_MESSAGE_DEACTIVATE_REQUEST,
                AUTOMATED_MESSAGE_DEACTIVATE_SUCCESS,
                AUTOMATED_MESSAGE_DEACTIVATE_FAILURE,
            ],
        ],
        updateActions: [
            AUTOMATED_MESSAGES_UPDATE_REQUEST,
            AUTOMATED_MESSAGES_UPDATE_SUCCESS,
            AUTOMATED_MESSAGES_UPDATE_FAILURE,
        ],
        deleteActions,
    }),
    paginated: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
    }),
    paginatedSearchMessages: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'search',
    }),
});

export default messages;
