import React from 'react';

const Empty = ({title, children}) => (
    <div className="empty">
        <h2 className="empty__title">{title}</h2>
        <p className="empty__content">{children}</p>
        <style jsx>
            {`
                @import '../../lib/sass/kit';

                .empty {
                    display: flex;
                    flex: 1;
                    width: 90%;
                    max-width: 46rem;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: lighten($text-color, 20%);
                    margin: 0 auto;
                    text-align: center;

                    &__title {
                        text-transform: uppercase;
                        margin-bottom: 1.1rem;
                        font-weight: $fw-bold;
                        font-size: 2.4rem;
                    }

                    &__content {
                        font-size: 1.6rem;
                    }
                }
            `}
        </style>
    </div>
);

export default Empty;
