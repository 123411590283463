import React, {Fragment, useEffect, useState} from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Empty from 'components/util/Empty';
import moment from 'moment';
import Widget from './Widget';

export const MESSAGE_READS_DASHBOARD_COLORS = [
    '#014463',
    '#4fc881',
    '#ff7937',
    '#017cff',
    '#763bd5',
    '#0dc5d3',
    '#d8545b',
];

const MessagesReadByWeekDay = ({
    translate,
    isFetching,
    data,
    ...otherProps
}) => {
    // pie data should be {name: value}
    const [pieData, setPieData] = useState([]);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        const mappedData = [];
        let dataFound = false;

        if (data.length > 0) {
            data.map((item, index) => {
                const now = moment().day(index);
                let totalCount = 0;
                item.hours.forEach(hour => (totalCount += hour.count));
                if (totalCount > 0) dataFound = true;

                mappedData.push({
                    name: now.format('ddd'),
                    value: totalCount,
                    color: MESSAGE_READS_DASHBOARD_COLORS[index],
                });

                return null;
            });

            // offset sunday to last in array
            mappedData.push(mappedData.shift());
            setPieData(mappedData);
            setHasData(dataFound);
        }
    }, [data]);

    return (
        <Widget
            {...otherProps}
            title={translate('dashboard.message_reads_by_weekday')}
            className="message-reads-widget"
            fetchingExport={false}>
            <LoadingWrapper loading={isFetching}>
                {pieData.length > 0 && hasData ? (
                    <Fragment>
                        <div className="chart_message_reads">
                            <PieChart width={174} height={174}>
                                <Pie
                                    data={pieData}
                                    dataKey="value"
                                    innerRadius={40}
                                    outerRadius={80}
                                    animationDuration={600}
                                    strokeWidth={0}>
                                    {pieData.map((day, index) => (
                                        <Cell fill={day.color} key={index} />
                                    ))}
                                    <Tooltip />
                                </Pie>
                            </PieChart>
                        </div>
                        <div className="labels_message_reads">
                            {pieData.map((day, index) => (
                                <div
                                    className="chart_message_reads__label"
                                    style={{color: day.color}}
                                    key={index}>
                                    <div
                                        className="chart_message_reads__label__icon"
                                        style={{
                                            backgroundColor: day.color,
                                        }}
                                    />

                                    <span className="chart_message_reads__label__text">
                                        <strong>{day.name}:</strong> {day.value}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </Fragment>
                ) : (
                    <Empty title={translate('dashboard.month_no_data')}>
                        {translate('dashboard.click_for_more')}
                    </Empty>
                )}
            </LoadingWrapper>

            <style jsx>
                {`
                    @import '../../lib/sass/kit';

                    :global(.message-reads-widget) {
                        :global(div.empty) {
                            color: $text-color;
                        }

                        :global(.content) {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            justify-content: space-between;
                        }
                    }

                    .chart_message_reads {
                        margin: 0 auto 1.5rem;

                        &__label {
                            display: inline-block;
                            text-align: center;
                            margin: 0.5rem 0.5rem;
                        }

                        &__label__text {
                            color: $brand-900;
                            font-size: 1.2rem;
                            margin-right: 0.5rem;
                            margin-left: 0.5rem;
                        }

                        &__label__icon {
                            width: 20px;
                            height: 20px;
                            border-radius: 3px;
                            margin: auto;
                        }
                    }

                    .labels_message_reads {
                        width: 100%;
                        text-align: center;
                        padding-bottom: 0.5rem;

                        :global(& svg) {
                            flex-shrink: 0;
                        }
                    }
                `}
            </style>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    data: state.analytics.messages.messageReadsByWeekdayAndHour,
    isfetching: state.analytics.messages.isFetchingMessageReadsByWeekdayAndHour,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessagesReadByWeekDay);
