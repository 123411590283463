import React from 'react';

export default function () {
    return (
        <path
            fill="currentColor"
            d="M.75 16.5h22.5v3H.75zM.047.562h.656V.61H.047zM.75 0h22.5v3H.75zM.047.047h.656v.047H.047zM.75 21h22.5v3H.75zM.047.703h.656V.75H.047zM5.25 4.5h13.5V15H5.25zm0 0"
        />
    );
}
