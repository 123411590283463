import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import actions from 'store/actions';
import getUserRoleHelper, {isRogerStaff} from 'util/getUserRoleHelper';
import PropTypes from 'prop-types';
import UserModal from './UserModal';

const UserModalWithState = ({selectedUser, onSuccess, onClose}) => {
    const [user, setUser] = useState(selectedUser);
    const [isSaving, setIsSaving] = useState(false);
    const [isSendingInvite, setIsSendingInvite] = useState(false);
    const [isSavingAvatar, setIsSavingAvatar] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector(state => state.users.byId[state.users.me]);
    if (!selectedUser) {
        return null;
    }

    const selectedUserRole =
        (selectedUser && selectedUser.role) || getUserRoleHelper(selectedUser);
    const handleSaveUser = newUser => {
        if (newUser.id) {
            return dispatch(actions.users.updateUser(newUser));
        }
        return dispatch(actions.users.createUser(newUser.role, newUser));
    };

    const sendPasswordResetLink = email =>
        dispatch(actions.auth.sendPasswordResetLink(email, true));

    const sendUserLink = email => dispatch(actions.auth.sendUserLink(email));

    const handleInvite = async (newUser, userHasAdminAccess) => {
        setIsSendingInvite(true);
        if (userHasAdminAccess) {
            await sendPasswordResetLink(newUser.work_email || newUser.email);
        } else {
            await sendUserLink(newUser.work_email || newUser.email);
        }
        onClose();
        setIsSendingInvite(false);
    };

    const handleImpersonate = async usr => {
        const response = await dispatch(actions.auth.impersonateUser(usr.id));
        dispatch(actions.auth.setAccessToken(response.payload));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const handleEditAvatarComplete = async avatar => {
        setIsSavingAvatar(true);
        const formData = new FormData();
        formData.append('avatar_file', avatar, 'avatar');
        const {payload} = await dispatch(
            actions.users.updateUserAvatar(formData, user.id),
        );
        setUser(payload.data[user.id]);
        setIsSavingAvatar(false);
    };

    return (
        <UserModal
            selectedUser={user}
            selectedUserRole={selectedUserRole}
            setIsSaving={setIsSaving}
            isSaving={isSaving}
            isSendingInvite={isSendingInvite}
            onSuccess={onSuccess}
            onSaveUser={handleSaveUser}
            onInvite={handleInvite}
            onImpersonate={handleImpersonate}
            onCloseModal={onClose}
            onEditAvatarComplete={handleEditAvatarComplete}
            isSavingAvatar={isSavingAvatar}
            isRogerStaff={isRogerStaff(getUserRoleHelper(me))}
        />
    );
};

UserModalWithState.propTypes = {
    selectedUser: PropTypes.shape({role: PropTypes.string}).isRequired,
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default UserModalWithState;
