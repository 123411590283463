import {createEditorState} from 'medium-draft';

const fixNullTextInRawEditorState = rawEditorState => {
    const editorState = {...rawEditorState};
    editorState.blocks = rawEditorState.blocks.map(block => {
        const newBlock = {...block};
        if (block.text === null) {
            newBlock.text = '';
        }
        return newBlock;
    });

    return editorState;
};

export const createRogerEditorState = (initialContents, decorators) => {
    if (
        initialContents &&
        initialContents.blocks &&
        initialContents.blocks.length
    ) {
        return createEditorState(
            fixNullTextInRawEditorState(initialContents),
            decorators,
        );
    }
    return createEditorState(null, decorators);
};

export default {
    createRogerEditorState,
};
