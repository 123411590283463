import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M16.747 6.187C17.4404 6.88038 17.9904 7.70354 18.3657 8.60948C18.741 9.51543 18.9341 10.4864 18.9341 11.467C18.9341 12.4476 18.741 13.4186 18.3657 14.3245C17.9904 15.2305 17.4404 16.0536 16.747 16.747C16.0537 17.4404 15.2305 17.9904 14.3246 18.3657C13.4186 18.7409 12.4476 18.934 11.467 18.934C10.4865 18.934 9.51548 18.7409 8.60953 18.3657C7.70359 17.9904 6.88043 17.4404 6.18705 16.747C4.7867 15.3467 4 13.4474 4 11.467C4 9.48662 4.7867 7.58734 6.18705 6.187C7.58739 4.78666 9.48666 3.99995 11.467 3.99995C13.4474 3.99995 15.3467 4.78666 16.747 6.187"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.93103 12.888V11.888"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.25 12.888V10.888"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5691 12.888V9.888"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.0001 20L16.7471 16.747"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
