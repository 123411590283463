import React from 'react';

export default function () {
    return (
        <>
            <path
                d="M7.07102 0.995328C2.46176 3.09045 0 6.96641 0 12.0471C0 22.3655 11.6279 27.7605 19.4322 21.0037C27.7603 13.8803 22.6273 -0.0522307 11.6803 0.000147266C10.3185 0.000147266 8.22333 0.471549 7.07102 0.995328ZM16.08 1.62386C16.9704 1.93813 18.6465 3.1952 19.7989 4.39989C23.8843 8.79964 24.0938 14.5088 20.375 19.0133C15.9229 24.4083 7.90907 24.513 3.24743 19.1705C-0.733291 14.666 -0.26189 8.11873 4.34737 3.71898C7.38529 0.838194 11.2613 0.157281 16.08 1.62386Z"
                fill="currentColor"
            />
            <path
                d="M10.8422 6.49501C9.63754 8.27586 9.68992 8.38062 11.785 8.38062C13.8802 8.38062 13.9849 8.11873 12.6755 6.39026L11.7327 5.13319L10.8422 6.49501Z"
                fill="currentColor"
            />
            <path
                d="M8.0662 10.5805C7.64718 11.3662 7.33291 12.0995 7.33291 12.309C7.33291 12.4661 9.32327 12.5709 11.785 12.5709C14.2468 12.5709 16.2372 12.4137 16.2372 12.1518C16.2372 11.9423 15.8705 11.2614 15.4515 10.6329C14.8753 9.79482 14.0373 9.48055 11.7327 9.32342C9.009 9.16629 8.74711 9.27104 8.0662 10.5805Z"
                fill="currentColor"
            />
            <path
                d="M5.60444 14.6136C5.13304 15.4516 4.71401 16.2373 4.71401 16.3421C4.71401 16.3944 7.90907 16.4992 11.785 16.4992C15.661 16.4992 18.8561 16.3944 18.8561 16.3421C18.8561 16.2373 18.437 15.4516 17.9656 14.6136C17.0752 13.0946 17.0752 13.0946 11.785 13.0946C6.49486 13.0946 6.49486 13.0946 5.60444 14.6136Z"
                fill="currentColor"
            />
        </>
    );
}
