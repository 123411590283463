export const colorGrey50 = '#eef1f4';
export const colorGrey100 = '#E5E8F1';
export const colorGrey200 = '#E2E8F1';
export const colorGrey300 = '#CFD8E3';
export const colorGrey400 = '#97A6BA';
export const colorGrey500 = '#64748B';
export const colorGrey600 = '#475569';
export const colorGrey700 = '#364152';
export const colorGrey800 = '#27303F';
export const colorGrey900 = '#1A202E';

export const colorPrimary = '#0072FF';
export const colorWhite = '#ffffff';
export const colorBlack = colorGrey900;
export const colorText = colorGrey900;
export const colorTextGrey = colorGrey500;
export const colorTextLight = colorGrey400;
export const colorBorder = colorGrey200;
export const colorBackground = colorGrey100;
export const colorWhatsapp = '#25D366';
export const colorRed = '#F05252';

export const colors = {
    grey: [
        colorGrey50, // 0
        colorGrey100, // 1
        colorGrey200, // 2
        colorGrey300, // 3
        colorGrey400, // 4
        colorGrey500, // 5
        colorGrey600, // 6
        colorGrey700, // 7
        colorGrey800, // 8
        colorGrey900, // 9
    ],
    primary: colorPrimary,
    white: colorWhite,
    black: colorBlack,
    text: colorText,
    textGrey: colorTextGrey,
    textLight: colorTextLight,
    background: colorBackground,
    border: colorBorder,
    whatsapp: colorWhatsapp,
    red: colorRed,
};

// Spacing
export const spacingTiny = 0.2;
export const spacingSmallest = 0.5;
export const spacingSmaller = 0.8;
export const spacingSmall = 1;
export const spacing = 1.2;
export const spacingLarge = 1.5;
export const spacingLarger = 2;
export const spacingLargest = 3;
export const spacingHuge = 6.4;

// Font weights
export const fwExtraLight = 100;
export const fwLight = 200;
export const fwBook = 300;
export const fwNormal = 400;
export const fwMedium = 500;
export const fwSemiBold = 600;
export const fwBold = 700;
export const fwBlack = 800;
export const fwExtraBlack = 900;

export const fontFamily = '"Inter", "Helvetica Neue", Arial, sans-serif';

// Font sizes
export const fontSizeSmallest = 1;
export const fontSizeSmaller = 1.2;
export const fontSizeSmall = 1.4;
export const fontSize = 1.6;
export const fontSizeLarge = 1.8;
export const fontSizeLarger = 2;
export const fontSizeLargest = 2.4;
export const fontSizeHuge = 3.2;

// Other
export const defaultTransition = '.4s';
export const defaultShadow =
    '0px 4px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.03)';

export const borderRadiusSmall = 0.4;
export const borderRadius = 1;
export const borderRadiusLarge = 1.5;

// Z Index variables
export const zNotice = 7;
export const zEnvironment = 6;
export const zModal = 5;
export const zScrollbar = 4;
export const zHeader = 3;
export const zClose = 2;
export const zAbove = 1;
export const zDefault = 0;
export const zBelow = -1;

// Widths / Heights
export const editorHeaderHeight = 8;

export const inlineTooltipWidth = 4;

export const addBtnWidth = 4;

export const containerWidth = 140;

export const messagesWidth = 64;

// Padding
export const modalVerticalPadding = 5;
export const modalHorizontalPadding = 1;

export const modalVerticalPaddingDesktop = 8;
export const modalHorizontalPaddingDesktop = 3;
export const editorTopPadding = 7;
export const editorSidePadding = 10;

export const dashboardGutter = 3;
