import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Translate} from 'react-localize-redux';
import {
    STATUS_PUBLISHED,
    STATUS_SCHEDULED,
} from 'lib/store/actions/messages/index';
import {ALL} from 'util/constants';
import actions from 'store/actions';
import * as routes from 'routes/index';
import useEditorState from 'components/hooks/useEditorState';
import getLastRootLocation from 'lib/helpers/getLastRootLocation';
import useRoles from 'components/hooks/useRoles';
import useTranslate from 'components/hooks/useTranslate';
import EditorMenu from './EditorMenu';

const MESSAGE_FOR_TYPE = routes.editorForMessageType();
const MESSAGE_FOR_ID = routes.editorForMessageId();
const ANSWERS = routes.messageAnswers();
const FOLLOW_UP = routes.messageFollowUp();
const COMMENTS = routes.messageComments();

const EditorMenuWithState = ({activeTab, messageId, isLoading}) => {
    const history = useHistory();
    const {editor, hasSurveys} = useEditorState();
    const {isSuperUser} = useRoles();
    const {activeLanguage} = useTranslate();
    const {status, lastSavedAt, contentType} = editor;

    const dispatch = useDispatch();
    const setActiveSidebar = newSideBarType =>
        dispatch(actions.editor.setActiveSidebar(newSideBarType));

    const defaultMenuKey =
        typeof messageId === 'undefined' ? MESSAGE_FOR_TYPE : MESSAGE_FOR_ID;
    const menu = [
        {
            key: defaultMenuKey,
            label: <Translate id="editor.message_layout" />,
        },
    ];

    // Add items to menu if message is published
    if (status === STATUS_PUBLISHED || status === STATUS_SCHEDULED) {
        menu.push({
            key: COMMENTS,
            label: <Translate id="global.comments" />,
        });
        // Add survey tab if surveys are present
        if (hasSurveys) {
            menu.push({
                key: ANSWERS,
                label: <Translate id="editor.answers" />,
            });
        }
        menu.push({
            key: FOLLOW_UP,
            label: <Translate id="editor.follow_up.title" />,
        });
    }

    const links = new Map([
        [defaultMenuKey, routes.editorForMessageId(contentType, messageId)],
        [COMMENTS, routes.messageComments(messageId)],
        [ANSWERS, routes.messageAnswers(messageId)],
        [FOLLOW_UP, routes.messageFollowUp(messageId, ALL)],
    ]);

    const onCloseEditor = async () => {
        const {lastRootLocation} = history;
        if (lastRootLocation) {
            history.push(lastRootLocation);
        } else {
            history.push(getLastRootLocation(contentType, status, isSuperUser));
        }
    };

    return (
        <EditorMenu
            menu={menu}
            onTabChange={key => {
                setActiveSidebar('');
                history.push(links.get(key));
            }}
            isLoading={isLoading}
            langCode={activeLanguage}
            onCloseEditor={onCloseEditor}
            lastSavedAt={lastSavedAt}
            activeTab={
                typeof activeTab === 'undefined' ? defaultMenuKey : activeTab
            }
        />
    );
};

export default EditorMenuWithState;
