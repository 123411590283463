import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {MONTHS} from 'util/constants';
import Select from 'components/util/Select';

const RepeatYearlyOn = ({mode, on, hasMoreModes, handleChange, translate}) => {
    const daysInMonth = moment(on.month, 'MMM').daysInMonth();
    let daysInMonthArray = [];
    if (daysInMonth) {
        const keys = Array(daysInMonth).keys();
        daysInMonthArray = Array.from(keys);
    }

    const isActive = mode === 'on';

    return (
        <div
            className={`form-group react-rule__flex ${!isActive ? 'opacity-50' : ''}`}>
            <div className="react-rule__col">
                {hasMoreModes && (
                    <input
                        type="radio"
                        name="repeat.yearly.mode"
                        aria-label="Repeat yearly on"
                        value="on"
                        checked={isActive}
                        onChange={handleChange}
                    />
                )}
            </div>

            <div className="react-rule__col">
                {translate('editor.recurrence.on')}
            </div>

            <div className="react-rule__col fullWidth">
                <Select
                    name="repeat.yearly.on.day"
                    aria-label="Repeat yearly on a day"
                    value={on.day}
                    isDisabled={!isActive}
                    onChange={handleChange}
                    options={daysInMonthArray.map(day => ({
                        value: day + 1,
                        label: day + 1,
                    }))}
                />
            </div>

            <div className="react-rule__col fullWidth">
                <Select
                    name="repeat.yearly.on.month"
                    aria-label="Repeat yearly on month"
                    value={on.month}
                    isDisabled={!isActive}
                    onChange={handleChange}
                    options={MONTHS.map(month => ({
                        value: month,
                        label: translate(`editor.recurrence.${month}`),
                    }))}
                />
            </div>
        </div>
    );
};
RepeatYearlyOn.propTypes = {
    mode: PropTypes.oneOf(['on', 'on the']).isRequired,
    on: PropTypes.shape({
        month: PropTypes.oneOf(MONTHS).isRequired,
        day: PropTypes.number.isRequired,
    }).isRequired,
    hasMoreModes: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatYearlyOn);
