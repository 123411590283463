import {MONTHS} from 'util/constants';

const computeYearlyOnMonth = (data, rruleObj) => {
    if (rruleObj.freq !== 0 || !rruleObj.bymonthday) {
        return data.repeat.yearly.on.month;
    }
    return MONTHS[rruleObj.bymonth - 1];
};

export default computeYearlyOnMonth;
