import React from 'react';
import styled from 'styled-components';
import Icon, {Icons as IconsMap} from '../../../lib/components/Icon';

const size = 60;

const Icons = () => (
    <Container>
        {Array.from(IconsMap.keys()).map(icon => (
            <Wrap>
                <Icon name={icon} size={size} />
                {icon}
            </Wrap>
        ))}
    </Container>
);

const Container = styled.div`
    padding: 50px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
`;

const Wrap = styled.div`
    width: 12%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #ebeef3;
    border-radius: 10px;
    padding: 10px;
`;

export default Icons;
