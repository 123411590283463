import {combineReducers} from 'redux';

import {
    SET_MODAL_VISIBLE,
    SET_CONFIRM_MODAL_DATA,
    MODAL_CONFIRM,
    SET_USER_DETAIL_DATA,
    MODAL_USER_DETAIL,
    SET_MODAL_DATA,
    MODAL_USER_LIST,
    SET_MODAL_LOADING,
} from '../actions/modals';

const INITIAL_VISIBILITY_STATE = {};
const visibility = (state = INITIAL_VISIBILITY_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_VISIBLE:
            return Object.assign({}, state, {
                [action.payload.modal]: action.payload.visibility,
            });
        default:
            return state;
    }
};

const INITIAL_LOADING_STATE = {};
const loading = (state = INITIAL_LOADING_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_LOADING:
            return Object.assign({}, state, {
                [action.payload.modal]: action.payload.loading,
            });
        default:
            return state;
    }
};

// @TODO refactor this to use one generic reducer
const data = (
    state = {
        [MODAL_CONFIRM]: {},
        [MODAL_USER_DETAIL]: {},
        [MODAL_USER_LIST]: {},
    },
    action,
) => {
    switch (action.type) {
        case SET_MODAL_DATA:
            return {...state, [action.payload.modal]: {...action.payload.data}};
        case SET_MODAL_VISIBLE:
            if (action.payload.visibility === false) {
                return {...state, [action.payload.modal]: {}};
            }
            return state;
        case SET_CONFIRM_MODAL_DATA:
            return {...state, [MODAL_CONFIRM]: {...action.payload.data}};
        case SET_USER_DETAIL_DATA:
            return {...state, [MODAL_USER_DETAIL]: {...action.payload.data}};
        default:
            return state;
    }
};

export default combineReducers({
    visibility,
    loading,
    data,
});
