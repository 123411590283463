import React from 'react';

export default function () {
    return (
        <path
            d="M6.5 9.5L11.5 14.5L16.5 9.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    );
}
