import {parse} from 'date-fns';
import {rawBodyToEditorBody} from 'util/editorBody';
import {
    SET_TITLE,
    SET_SCHEDULED_AT,
    SET_EDITOR_STATE,
    SET_AUDIENCE_FILTERS,
    SET_APP_DOMAIN,
    SET_ACCEPT_ANSWERS,
    SET_AVAILABLE_LANGUAGES,
    SET_CURRENT_LANGUAGE,
    SET_ENABLE_COMMENTS,
    SET_TRIGGER,
    SET_DELAY,
    SET_SEND_REMINDERS,
    EDITOR_LOAD_MESSAGE,
    EDITOR_LOAD_TEMPLATE,
    EDITOR_LOAD_AUTOMATED_MESSAGE,
    CLEAR_EDITOR,
    SET_ACTIVE_SIDEBAR,
    SET_ACCESSIBLE_FOR,
    CONTENT_TYPE_MESSAGES,
    CONTENT_TYPE_TEMPLATES,
    CONTENT_TYPE_AUTOMATED_MESSAGES,
    START_NEW_AUTOMATED_MESSAGE,
    SET_VISIBILITY,
    SET_STATUS,
    SET_EDITOR,
} from '../actions/editor';
import {
    STATUS_NEW,
    VISIBILITY_PRIVATE,
    MESSAGES_PUBLISH_SUCCESS,
} from '../actions/messages/index';

export const INITIAL_APP_DOMAIN = {
    slug: 'news',
};

export const INITIAL_TRIGGER = {
    parameters: {},
};

const INITIAL_STATE = {
    sidebarType: '',
    sidebarData: null,
    contentType: CONTENT_TYPE_MESSAGES,
    contentId: null,
    status: STATUS_NEW,
    body: {},
    title: {},
    appDomain: INITIAL_APP_DOMAIN,
    acceptAnswers: true,
    enableComments: false,
    audienceFilters: [],
    visibility: VISIBILITY_PRIVATE,
    lastSavedAt: null,
    scheduledAt: null,
    publishedAt: null,
    trigger: INITIAL_TRIGGER,
    delay: null,
    sendReminders: false,
    availableLanguages: [],
    currentLanguage: '',
};

export const mapAudienceFilters = audienceFilters =>
    audienceFilters.map(filter => {
        if (filter.filter === 'users') {
            return {
                ...filter,
                value: filter.value.map(item => item.id || item),
            };
        }

        return filter;
    });

export default (state = {...INITIAL_STATE}, action) => {
    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                title: action.payload.title || '',
            };
        case SET_APP_DOMAIN:
            return {
                ...state,
                appDomain: action.payload.appDomain || {...INITIAL_APP_DOMAIN},
            };
        case SET_ACCEPT_ANSWERS: {
            const {acceptAnswers} = action.payload;
            return {
                ...state,
                acceptAnswers: acceptAnswers !== null ? acceptAnswers : true,
            };
        }
        case SET_AVAILABLE_LANGUAGES: {
            const {availableLanguages} = action.payload;
            return {
                ...state,
                availableLanguages,
            };
        }
        case SET_CURRENT_LANGUAGE: {
            const {currentLanguage} = action.payload;

            return {
                ...state,
                currentLanguage,
            };
        }
        case SET_ENABLE_COMMENTS: {
            const {enableComments} = action.payload;
            return {
                ...state,
                enableComments: enableComments !== null ? enableComments : true,
            };
        }
        case SET_VISIBILITY:
            return {
                ...state,
                visibility: action.payload.visibility || VISIBILITY_PRIVATE,
            };
        case SET_AUDIENCE_FILTERS:
            return {
                ...state,
                audienceFilters: action.payload.audienceFilters,
            };
        case SET_EDITOR_STATE:
            return {
                ...state,
                body: action.payload.editorState,
            };
        case SET_SCHEDULED_AT:
            return {
                ...state,
                scheduledAt: action.payload.scheduledAt,
            };
        case SET_STATUS:
            return {
                ...state,
                status: action.payload.status,
            };
        case SET_TRIGGER:
            return {
                ...state,
                trigger: action.payload.trigger,
            };
        case SET_DELAY:
            return {
                ...state,
                delay: action.payload.delay,
            };
        case SET_ACCESSIBLE_FOR:
            return {
                ...state,
                accessibleFor: action.payload.accessibleFor,
            };
        case SET_SEND_REMINDERS:
            return {
                ...state,
                sendReminders: action.payload.sendReminders,
            };
        case MESSAGES_PUBLISH_SUCCESS:
        case EDITOR_LOAD_MESSAGE: {
            let {message} = action.payload;

            // If message is not found maybe it's a published message and data is represented differently
            if (!message) {
                const id = action.payload.ids;
                message = action.payload.data[id];
            }

            // If message still isn't set something went wrong and the reducer should just return the current state
            if (!message) {
                return state;
            }

            let {body, title} = state || {};

            if (action.type === EDITOR_LOAD_MESSAGE) {
                body = rawBodyToEditorBody(message.body);
                title = message.title || '';
            }

            return {
                ...state,
                contentId: message.id || null,
                contentType: CONTENT_TYPE_MESSAGES,
                status: message.status,
                title,
                body,
                appDomain: message.app_domain || {...INITIAL_APP_DOMAIN},
                enableComments: message.enable_comments,
                acceptAnswers:
                    message.accept_answers === null
                        ? true
                        : message.accept_answers,
                visibility: message.visibility,
                lastSavedAt: message.updated_at
                    ? parse(message.updated_at)
                    : null,
                scheduledAt: message.scheduled_at
                    ? parse(message.scheduled_at)
                    : null,
                publishedAt: message.published_at
                    ? parse(message.published_at)
                    : null,
                sendReminders: message.send_reminders || false,
                audienceFilters: mapAudienceFilters(message.audience_filters),
                shareUrl:
                    message.source === null ? null : message.source.canonical,
                availableLanguages: message.available_languages,
            };
        }
        case EDITOR_LOAD_AUTOMATED_MESSAGE: {
            const {message} = action.payload;
            return {
                ...state,
                contentId: message.id,
                contentType: CONTENT_TYPE_AUTOMATED_MESSAGES,
                status: message.status,
                title: message.title || '',
                trigger: message.trigger,
                body: rawBodyToEditorBody(message.body),
                appDomain: message.app_domain || {...INITIAL_APP_DOMAIN},
                acceptAnswers: message.accept_answers,
                enableComments: message.enable_comments,
                visibility: message.visibility,
                lastSavedAt: message.updated_at
                    ? parse(message.updated_at)
                    : null,
                delay: message.delay ? message.delay : null,
                sendReminders: message.send_reminders || false,
                audienceFilters: mapAudienceFilters(message.audience_filters),
                accessibleFor: message.accessible_for.map(({id}) => id),
                availableLanguages: message.available_languages,
            };
        }
        case EDITOR_LOAD_TEMPLATE: {
            const {template} = action.payload;
            return {
                ...state,
                contentId: template.id,
                contentType: CONTENT_TYPE_TEMPLATES,
                title: template.title || '',
                body: rawBodyToEditorBody(template.body),
                appDomain: template.app_domain || {...INITIAL_APP_DOMAIN},
                lastSavedAt: template.updated_at
                    ? parse(template.updated_at)
                    : null,
                audienceFilters: mapAudienceFilters(template.audience_filters),
                acceptAnswers: template.accept_answers,
                enableComments: template.enable_comments,
                availableLanguages: template.available_languages,
            };
        }
        case START_NEW_AUTOMATED_MESSAGE: {
            const {body} = action.payload;
            return {
                ...INITIAL_STATE,
                ...state,
                body,
                accessibleFor: [],
                contentType: CONTENT_TYPE_AUTOMATED_MESSAGES,
            };
        }
        case CLEAR_EDITOR:
            return {
                ...INITIAL_STATE,
                title: {},
            };
        case SET_ACTIVE_SIDEBAR:
            return {
                ...state,
                sidebarType: action.payload.sidebarType,
                sidebarData: action.payload.sidebarData,
            };
        case SET_EDITOR:
            return {
                ...state,
                ...action.payload.editor,
            };
        default:
            return state;
    }
};
