import './AdminMessageReadsByWeekdayAndHour.css';
import React from 'react';
import moment from 'moment';
import {
    CartesianGrid,
    Label,
    Legend,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
} from 'recharts';
import {MESSAGE_READS_DASHBOARD_COLORS} from 'components/Widgets/MessagesReadByWeekDay';

const AdminMessageReads = ({data, translate}) => {
    const scatters = data.map((day, index) => {
        const dayLabel = moment().day(index).format('ddd');
        const color = MESSAGE_READS_DASHBOARD_COLORS[index];
        let dayData = day.hours.map((item, hourIndex) => ({
            day: dayLabel,
            hour: hourIndex,
            value: item.count,
            color,
        }));
        dayData = dayData.filter(hour => hour.value > 0);

        return (
            <Scatter key={index} name={dayLabel} data={dayData} fill={color} />
        );
    });

    scatters.push(scatters.shift());

    return (
        <div className="admin-message-reads-modal">
            <div className="row admin-message-reads-modal-container">
                <div
                    className="admin-message-reads-weekday-modal-container"
                    style={{margin: 'auto'}}>
                    <ScatterChart width={600} height={300} margin={{right: 25}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                            dataKey="value"
                            name={translate('dashboard.message_reads')}
                            type="number"
                            allowDecimals={false}
                            label={{
                                value: translate('dashboard.message_reads'),
                                angle: -90,
                                position: 'insideLeft',
                                offset: 10,
                            }}
                        />
                        <XAxis
                            dataKey="hour"
                            domain={[0, 23]}
                            name={translate('dashboard.message_hour_of_day')}
                            type="number"
                            interval={2}
                            tickCount={24}>
                            <Label
                                value={translate(
                                    'dashboard.message_hour_of_day',
                                )}
                                offset={-10}
                                position="insideBottom"
                            />
                        </XAxis>
                        <ZAxis
                            dataKey="day"
                            name={translate('dashboard.message_day_of_week')}
                            type="category"
                        />
                        <Tooltip cursor={{strokeDasharray: '3 3'}} />
                        <Legend
                            wrapperStyle={{
                                paddingTop: '40px',
                                paddingLeft: '25px',
                            }}
                        />
                        {scatters}
                    </ScatterChart>
                </div>
            </div>
        </div>
    );
};

export default AdminMessageReads;
