import {NavLink, withRouter} from 'react-router-dom';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import ImageWrapper from 'lib/components/Images/ImageWrapper';
import User from 'components/User';
import {actions} from 'store/index';
import {MODAL_USER_DETAIL, MODAL_NEW_MESSAGE} from 'lib/store/actions/modals';
import RogerLogo from 'assets/logo.svg';
import Icon from 'lib/components/Icon';
import * as routes from 'routes/index';
import getUrlBySize, {THUMB, REGULAR} from 'lib/helpers/sizeHelper';
import Modal from 'modals/Modal/ModalWithState';
import NewMessage from 'modals/NewMessage';
import styled from 'styled-components';
import useRoles from 'components/hooks/useRoles';
import Button from './Button';

const DASHBOARD = {
    to: routes.home(),
    label: 'dashboard',
    icon: 'dashboard',
    exact: true,
};
const PLANNING = {
    to: routes.planning(),
    label: 'planning',
    icon: 'calendar',
};
const DRAFTS = {
    to: routes.drafts(),
    label: 'drafts',
    icon: 'draft',
};
const AUTOMATEDMESSAGES = {
    to: routes.automatedMessages(),
    label: 'automated_messages',
    icon: 'clockBack',
};
const SENTMESSAGES = {
    to: routes.sentMessages(),
    label: 'sent_messages',
    icon: 'sendMail',
};
const ARCHIVEDMESSAGES = {
    to: routes.archivedMessages(),
    label: 'archived_messages',
    icon: 'archive',
};
const USERS = {
    to: routes.users(),
    label: 'users',
    icon: 'users',
};
const SETTINGS = {
    to: routes.settings(),
    label: 'settings',
    icon: 'settings',
};
const SEGMENTATION = {
    to: routes.segmentation(),
    label: 'segmentation',
    icon: 'segmentation',
};
const DOCUMENTS = {
    to: routes.documents(),
    label: 'documents',
    icon: 'folders',
};
const ADMIN_INBOX = {
    to: routes.adminInbox(),
    label: 'admin_inbox',
    icon: 'adminInbox',
};

export const SideBarComponent = () => {
    const {me: meId, byId: users} = useSelector(state => state.users);
    const branding = useSelector(({company}) => company.data.branding);
    const hasAdminInbox = useSelector(
        state => state.company.data?.settings?.admin_inbox?.can_list,
    );
    const dispatch = useDispatch();
    const setModalVisibility = (modal, visibility) =>
        dispatch(actions.modals.setModalVisibility(modal, visibility));
    const setUserDetailModalData = data =>
        dispatch(actions.modals.setUserDetailModalData(data));
    const {isSuperUser, isAdmin} = useRoles();

    const me = users[meId];

    if (typeof me === 'undefined') {
        return null;
    }
    const avatarUrl = getUrlBySize(me.avatar, THUMB);

    let links = [];

    if (isAdmin) {
        links = [
            [DASHBOARD],
            [
                DRAFTS,
                PLANNING,
                AUTOMATEDMESSAGES,
                SENTMESSAGES,
                ARCHIVEDMESSAGES,
            ],
            [USERS, SEGMENTATION],
            [DOCUMENTS, ...(hasAdminInbox ? [ADMIN_INBOX] : []), SETTINGS],
        ];
    }

    if (isSuperUser) {
        links = [[DRAFTS, PLANNING, SENTMESSAGES]];
    }

    const logo = getUrlBySize(branding.logo, REGULAR);

    return (
        <Container>
            <Top>
                <Logo>
                    <ImageWrapper
                        src={logo || RogerLogo}
                        alt="logo"
                        id="logo"
                        disableProgressiveLoading
                        retryIfNotFound={!!logo}
                    />
                </Logo>

                {(isAdmin || isSuperUser) && (
                    <Group>
                        <Button
                            onClick={() =>
                                setModalVisibility(MODAL_NEW_MESSAGE, true)
                            }
                            fullWidth>
                            <Translate id="global.new_message.add" />
                        </Button>
                    </Group>
                )}

                <nav>
                    {links.map(group => (
                        <Group key={group.label}>
                            {group.map(({to, exact, label, icon}) => (
                                <NavItem key={to}>
                                    <StyledLink
                                        to={to}
                                        exact={exact}
                                        activeClassName="active">
                                        <IconWrap>
                                            <Icon name={icon} size={20} />
                                        </IconWrap>
                                        <Translate id={`global.${label}`} />
                                    </StyledLink>
                                </NavItem>
                            ))}
                        </Group>
                    ))}
                </nav>
            </Top>
            <Bottom>
                <User
                    name={
                        me ? (
                            `${me.first_name} ${me.last_name}`
                        ) : (
                            <Translate id="global.logout" />
                        )
                    }
                    image={avatarUrl}
                    title={
                        me && me.roles && me.roles.length && me.roles[0].label
                    }
                    onClick={() => {
                        setModalVisibility(MODAL_USER_DETAIL, true);
                        setUserDetailModalData({userId: meId, editable: true});
                    }}
                />
            </Bottom>
            <Modal big name={MODAL_NEW_MESSAGE} flex>
                <NewMessage />
            </Modal>
        </Container>
    );
};

const Container = styled.div(
    ({theme}) => `
    background-color: ${theme.colorWhite};
    width: 27rem;
    padding: 5rem 2.5rem ${theme.spacingLargest}rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
    flex-shrink: 0;
`,
);

const Logo = styled.div(
    ({theme}) => `
    margin-bottom: ${theme.spacingLargest}rem;

    img {
        max-height: 16rem;
        margin: 0 auto;
        display: block;
    }
`,
);

const Top = styled.div``;

const Bottom = styled.div(
    ({theme}) => `
    padding-top: ${theme.spacingLarger}rem;
`,
);

export const Group = styled.div(
    ({theme}) => `
    margin-bottom: ${theme.spacingLargest}rem;
`,
);

export const StyledLink = styled(NavLink)(
    ({theme}) => `
     a{
        text-decoration: none !important;
    }
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 1.4rem;
    padding: ${theme.spacing}rem ${theme.spacingLarger}rem;
    margin: ${theme.spacingSmallest}rem 0;
    color: ${theme.colorTextGrey};
    border-radius: ${theme.borderRadius}rem;
    font-weight: 500;

    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        color: ${theme.colorText};
        background: ${theme.colorGrey100};
    }
   
`,
);

const IconWrap = styled.div(
    ({theme}) => `
    margin-right: ${theme.spacingLarger}rem;

    ${StyledLink}:hover &,
    ${StyledLink}:focus &,
    .active & {
        color: ${theme.colorPrimary};
    }
`,
);

const NavItem = styled.div`
    position: relative;
`;

export default withRouter(SideBarComponent);
