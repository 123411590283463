import React from 'react';
import classNames from 'classnames';
import Icon from 'lib/components/Icon';
import {BrandingContext as Branding} from './providers/BrandingProvider';

const IconButton = ({className, onClick, children, icon}) => (
    <Branding.Consumer>
        {colors => (
            <button
                className={classNames('icon-button', className)}
                onClick={onClick}
                type="button">
                <span className="icon">
                    <Icon name={icon} size="16" />
                </span>

                {children}

                <style jsx>
                    {`
                        .icon-button {
                            background-color: ${colors.pureWhite};
                            width: 100%;
                            box-shadow: ${colors.defaultShadow};
                            border: 0;
                            border-radius: 0.4rem;
                            padding: 1.3rem;
                            padding-left: 5.6rem;
                            position: relative;
                            margin-bottom: 3rem;
                            color: ${colors.text.secondary};
                            text-transform: uppercase;
                            font-size: 1.6rem;
                            text-align: left;
                            cursor: pointer;

                            &:focus {
                                outline: none;
                            }
                        }

                        .icon {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 4.5rem;
                            height: 3rem;
                            line-height: 3rem;
                            text-align: center;
                            border-right: 0.1rem solid ${colors.grey[200]};
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    `}
                </style>
            </button>
        )}
    </Branding.Consumer>
);

export default IconButton;
