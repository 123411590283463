import color from 'tinycolor2';

export const chart = {
    blue: '#007CFF',
    cyan: '#0EC5D2',
    green: {
        base: '#47E305',
        secondary: '#1FE192',
    },
    purple: '#773AD5',
    yellow: '#E7AF03',
    orange: {
        base: '#FF7936',
        dark: '#E74F03',
    },
};

export const grey = {
    100: '#EBEEF3',
    150: '#DBE0E9',
    200: '#D8D8D8',
    300: '#CCC',
    350: '#BCBCC8',
    400: '#9B9BAA',
    450: '#979797',
    500: '#888',
    700: '#6B6B6B',
    900: '#1A202E',
};

export const border = grey[300];

export const background = '#F4F4F9';

export const pureWhite = '#FFF';
export const pureBlack = '#000';

export const brand = {
    base: '#007CFF',

    200: '#D5DDEE',

    500: '#0184FF',
    550: '#0C5DB3',
    575: '#0246BB',
    600: '#0235A8',
    900: '#004463',
    1000: '#023147',
};

export const success = {
    base: '#1EE292',
    dark: '#17b675',
};

export const warning = {
    base: '#FFC107',
    dark: '#D39E00',
};

export const danger = {
    base: '#D5424A',
    dark: '#BB2931',
};

export const text = {
    base: grey[900],
    light: '#FFF',
    dark: grey[900],
    secondary: '#505A6F',
    editor: '#CCC',
};

export const editorPlaceholder = '#505A6F';
export const defaultShadow = 'rgba(0, 0, 0, .25) 0 .1rem .3rem';

export const brandingDefaults = {
    grey,
    pureWhite,
    pureBlack,
    brand,
    success,
    warning,
    danger,
    text,
    editorPlaceholder,
    defaultShadow,
};

export const parseColors = colors => {
    if (colors) {
        const {base, dark} = colors.brand;
        const brandIsDark = color(base).getLuminance() * 100 < 20;
        const parsedColors = {...colors};

        parsedColors.brand = {
            darkContrast: brandIsDark
                ? color(colors.brand.base).lighten(10)
                : colors.brand.base,
            base,
            600: color(base)
                .darken(brandIsDark ? 4 : 15)
                .desaturate(2.35)
                .toHexString(),
            900:
                dark ||
                color(base)
                    .darken(brandIsDark ? 6 : 20)
                    .toHexString(),
        };

        return parsedColors;
    }
    return {};
};

export default {
    chart,
    brandingDefaults,
    parseColors,
};
