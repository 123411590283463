// Sizes that are actually in use
export const THUMB = 'thumb';
export const SMALL = 'small';
export const REGULAR = 'regular';
export const FULL = 'full';
// Deprecated sizes still circulating in old messages
export const NORMAL = 'normal';
export const EXPAND = 'expand';

const getUrlBySize = (object, size) => {
    if (!object || typeof !object === 'object') {
        return '';
    }

    let urlSize = size;
    if (typeof urlSize === 'undefined' || urlSize === NORMAL) {
        urlSize = REGULAR;
    }
    if (urlSize === EXPAND) {
        urlSize = FULL;
    }

    if (object.sizes && object.sizes[urlSize]) {
        return object.sizes[urlSize];
    }
    return '';
};

export default getUrlBySize;
