import React from 'react';
import {useDispatch} from 'react-redux';
import {Translate} from 'react-localize-redux';
import actions from 'store/actions';
import useEditorState from 'components/hooks/useEditorState';
import {WARNING} from 'lib/store/actions/notifications';
import useContentSaver from 'components/hooks/useContentSaver';
import EditorSidebarSwitch from './EditorSidebarSwitch';

const EditorSidebarCommentToggleWithState = ({canEdit}) => {
    const {editor} = useEditorState();
    const {saveContent} = useContentSaver();

    const dispatch = useDispatch();
    const setEnableComments = enableComments =>
        dispatch(actions.editor.setEnableComments(enableComments));

    const onChangeEnableComments = async enableComments => {
        await setEnableComments(enableComments);
        saveContent({...editor, enableComments});

        if (enableComments) {
            dispatch(
                actions.notifications.addNotification({
                    type: WARNING,
                    content: {
                        key: 'notifications.enable_comments_warning',
                    },
                }),
            );
        }
    };

    return (
        <EditorSidebarSwitch
            canEdit={canEdit}
            onChange={onChangeEnableComments}
            value={editor.enableComments}
            label={<Translate id="Enable comments" />}
        />
    );
};

export default EditorSidebarCommentToggleWithState;
