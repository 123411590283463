import React from 'react';
import {Translate} from 'react-localize-redux';
import CustomBlock from '../CustomBlock/CustomBlock';

const Survey404 = ({survey, onDelete, interactive, canEdit}) =>
    !interactive && (
        <CustomBlock
            onDelete={() => {
                onDelete(survey);
            }}
            disableActions={interactive || !canEdit}>
            <div className={`${interactive ? '--interactive' : ''} `}>
                <h1>
                    <Translate id="editor.survey-not-found" />
                </h1>
            </div>
        </CustomBlock>
    );

export default Survey404;
