import React from 'react';

const Icon = () => (
    <path
        d="M15.643 11.3021L9.012 7.09814C8.556 6.80914 8 7.19314 8 7.79614V16.2031C8 16.8071 8.556 17.1901 9.012 16.9011L15.643 12.6971C16.119 12.3971 16.119 11.6031 15.643 11.3021Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
);

export default Icon;
