import React from 'react';
import {Translate} from 'react-localize-redux';
import Modal from 'modals/Modal/ModalWithState';
import AdminActivityWithState from './AdminActivityWithState';

const AdminActivityModal = () => (
    <Modal
        name="adminActivity"
        medium
        noPadding
        menu={[{label: <Translate id="dashboard.admin_activity" />}]}>
        <AdminActivityWithState />
    </Modal>
);

export default AdminActivityModal;
