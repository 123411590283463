import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M12 15.222C13.3807 15.222 14.5 14.1027 14.5 12.722C14.5 11.3413 13.3807 10.222 12 10.222C10.6193 10.222 9.5 11.3413 9.5 12.722C9.5 14.1027 10.6193 15.222 12 15.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.33099 19.778C8.49799 18.844 10.21 18.222 12 18.222C13.79 18.222 15.502 18.845 16.669 19.778"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.486 9.222C18.8667 9.222 19.986 8.10271 19.986 6.722C19.986 5.34129 18.8667 4.222 17.486 4.222C16.1053 4.222 14.986 5.34129 14.986 6.722C14.986 8.10271 16.1053 9.222 17.486 9.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 12.222C19.533 12.222 21 12.755 22 13.555"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.51399 9.222C7.8947 9.222 9.01399 8.10271 9.01399 6.722C9.01399 5.34129 7.8947 4.222 6.51399 4.222C5.13328 4.222 4.01399 5.34129 4.01399 6.722C4.01399 8.10271 5.13328 9.222 6.51399 9.222Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 13.555C3 12.755 4.467 12.222 6 12.222"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
