import React from 'react';

const Add = () => (
    <polygon
        fill="currentColor"
        points="23.039062 10.078125 13.921875 10.078125 13.921875 0.960938 10.078125 0.960938 10.078125 10.078125 0.960938 10.078125 0.960938 13.921875 10.078125 13.921875 10.078125 23.039062 13.921875 23.039062 13.921875 13.921875 23.039062 13.921875"
    />
);

export default Add;
