import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import initLocale from 'locale/index';
import api from 'lib/store/middleware/api';
import {
    loadState,
    saveState,
    removeState,
} from 'lib/store/helpers/stateStorage';
import reducer from '../reducers';

const configureStore = () => {
    const store = createStore(
        reducer,
        loadState(),
        applyMiddleware(thunk, api),
    );

    store.subscribe(
        throttle(() => {
            const {accessToken, refreshToken, expiresIn, remember} =
                store.getState().auth;
            if (remember && refreshToken) {
                saveState({
                    auth: {
                        accessToken,
                        refreshToken,
                        expiresIn,
                        remember,
                    },
                });
            } else {
                removeState();
            }
        }, 1000),
    );

    initLocale(store);

    return store;
};

export default configureStore;
