import {CALL_API} from '../../../constants/api';
import {GET} from '../../services';

export const FETCH_MESSAGES_ANALYTICS_REQUEST =
    'FETCH_MESSAGES_ANALYTICS_REQUEST';
export const FETCH_MESSAGES_ANALYTICS_SUCCESS =
    'FETCH_MESSAGES_ANALYTICS_SUCCESS';
export const FETCH_MESSAGES_ANALYTICS_FAILURE =
    'FETCH_MESSAGES_ANALYTICS_FAILURE';

export const fetchMessages = () => ({
    [CALL_API]: {
        endpoint: '/analytics/sent-messages?interval=month',
        method: GET,
        version: 'v2',
        types: [
            FETCH_MESSAGES_ANALYTICS_REQUEST,
            FETCH_MESSAGES_ANALYTICS_SUCCESS,
            FETCH_MESSAGES_ANALYTICS_FAILURE,
        ],
    },
});

export const FETCH_POPULARITY_ANALYTICS_REQUEST =
    'FETCH_POPULARITY_ANALYTICS_REQUEST';
export const FETCH_POPULARITY_ANALYTICS_SUCCESS =
    'FETCH_POPULARITY_ANALYTICS_SUCCESS';
export const FETCH_POPULARITY_ANALYTICS_FAILURE =
    'FETCH_POPULARITY_ANALYTICS_FAILURE';

export const fetchPopularity = () => ({
    [CALL_API]: {
        endpoint: '/analytics/popular-messages/read',
        method: GET,
        version: 'v2',
        types: [
            FETCH_POPULARITY_ANALYTICS_REQUEST,
            FETCH_POPULARITY_ANALYTICS_SUCCESS,
            FETCH_POPULARITY_ANALYTICS_FAILURE,
        ],
    },
});

export const FETCH_MESSAGES_WEEKLY_ANALYTICS_REQUEST =
    'FETCH_MESSAGES_WEEKLY_ANALYTICS_REQUEST';
export const FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS =
    'FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS';
export const FETCH_MESSAGES_WEEKLY_ANALYTICS_FAILURE =
    'FETCH_MESSAGES_WEEKLY_ANALYTICS_FAILURE';

export const fetchMessagesPerWeek = () => ({
    [CALL_API]: {
        endpoint: '/analytics/sent-messages?interval=week',
        method: GET,
        version: 'v2',
        types: [
            FETCH_MESSAGES_WEEKLY_ANALYTICS_REQUEST,
            FETCH_MESSAGES_WEEKLY_ANALYTICS_SUCCESS,
            FETCH_MESSAGES_WEEKLY_ANALYTICS_FAILURE,
        ],
    },
});

export const FETCH_ADMIN_ACTIVITY_EXPORT_REQUEST =
    'FETCH_ADMIN_ACTIVITY_EXPORT_REQUEST';
export const FETCH_ADMIN_ACTIVITY_EXPORT_SUCCESS =
    'FETCH_ADMIN_ACTIVITY_EXPORT_SUCCESS';
export const FETCH_ADMIN_ACTIVITY_EXPORT_FAILURE =
    'FETCH_ADMIN_ACTIVITY_EXPORT_FAILURE';

export const fetchAdminActivityExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/sent-messages/export',
        method: GET,
        version: 'v2',
        types: [
            FETCH_ADMIN_ACTIVITY_EXPORT_REQUEST,
            FETCH_ADMIN_ACTIVITY_EXPORT_SUCCESS,
            FETCH_ADMIN_ACTIVITY_EXPORT_FAILURE,
        ],
    },
});

export const FETCH_CONTENT_POPULARITY_EXPORT_REQUEST =
    'FETCH_CONTENT_POPULARITY_EXPORT_REQUEST';
export const FETCH_CONTENT_POPULARITY_EXPORT_SUCCESS =
    'FETCH_CONTENT_POPULARITY_EXPORT_SUCCESS';
export const FETCH_CONTENT_POPULARITY_EXPORT_FAILURE =
    'FETCH_CONTENT_POPULARITY_EXPORT_FAILURE';

export const fetchContentPopularityExport = () => ({
    [CALL_API]: {
        endpoint: '/analytics/popular-messages/export',
        method: GET,
        version: 'v2',
        types: [
            FETCH_CONTENT_POPULARITY_EXPORT_REQUEST,
            FETCH_CONTENT_POPULARITY_EXPORT_SUCCESS,
            FETCH_CONTENT_POPULARITY_EXPORT_FAILURE,
        ],
    },
});

export const FETCH_MOST_READ_REQUEST = 'FETCH_MOST_READ_REQUEST';
export const FETCH_MOST_READ_SUCCESS = 'FETCH_MOST_READ_SUCCESS';
export const FETCH_MOST_READ_FAILURE = 'FETCH_MOST_READ_FAILURE';

export const fetchMostRead = ({query, page, limit}, listType = null) => ({
    [CALL_API]: {
        endpoint: '/analytics/popular-messages/most-read',
        method: GET,
        version: 'v2',
        query: {
            query,
            page,
            limit,
        },
        types: [
            FETCH_MOST_READ_REQUEST,
            FETCH_MOST_READ_SUCCESS,
            FETCH_MOST_READ_FAILURE,
        ],
        listType,
    },
});

export const FETCH_MOST_CHEERED_REQUEST = 'FETCH_MOST_CHEERED_REQUEST';
export const FETCH_MOST_CHEERED_SUCCESS = 'FETCH_MOST_CHEERED_SUCCESS';
export const FETCH_MOST_CHEERED_FAILURE = 'FETCH_MOST_CHEERED_FAILURE';

export const fetchMostCheered = ({query, page, limit}, listType = null) => ({
    [CALL_API]: {
        endpoint: '/analytics/popular-messages/most-cheered',
        method: GET,
        version: 'v2',
        query: {
            query,
            page,
            limit,
        },
        types: [
            FETCH_MOST_CHEERED_REQUEST,
            FETCH_MOST_CHEERED_SUCCESS,
            FETCH_MOST_CHEERED_FAILURE,
        ],
        listType,
    },
});

export const FETCH_MESSAGE_READS_BY_HOUR_REQUEST =
    'FETCH_MESSAGE_READS_BY_HOUR_REQUEST';
export const FETCH_MESSAGE_READS_BY_HOUR_SUCCESS =
    'FETCH_MESSAGE_READS_BY_HOUR_SUCCESS';
export const FETCH_MESSAGE_READS_BY_HOUR_FAILURE =
    'FETCH_MESSAGE_READS_BY_HOUR_FAILURE';

export const fetchMessageReadsByHour = messageId => ({
    [CALL_API]: {
        endpoint: `/analytics/messages/${messageId}/reads-by-hour`,
        method: GET,
        version: 'v2',
        query: {},
        types: [
            FETCH_MESSAGE_READS_BY_HOUR_REQUEST,
            FETCH_MESSAGE_READS_BY_HOUR_SUCCESS,
            FETCH_MESSAGE_READS_BY_HOUR_FAILURE,
        ],
    },
});

export const FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_REQUEST =
    'FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_REQUEST';
export const FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS =
    'FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS';
export const FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_FAILURE =
    'FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_FAILURE';

export const fetchMessageReadsByWeekDayAndHour = () => ({
    [CALL_API]: {
        endpoint: '/analytics/messages/reads-by-weekday-and-hour',
        method: GET,
        version: 'v2',
        query: {},
        types: [
            FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_REQUEST,
            FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_SUCCESS,
            FETCH_MESSAGE_READS_BY_WEEKDAY_AND_HOUR_FAILURE,
        ],
    },
});

export default {
    fetchMessages,
    fetchMessagesPerWeek,
    fetchAdminActivityExport,
    fetchContentPopularityExport,
    fetchMostRead,
    fetchMostCheered,
    fetchPopularity,
    fetchMessageReadsByHour,
    fetchMessageReadsByWeekDayAndHour,
};
