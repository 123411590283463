import React from 'react';
import PropTypes from 'prop-types';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {MONTHS, DAYS} from 'util/constants';
import Select from 'components/util/Select';

const RepeatYearlyOnThe = ({
    mode,
    onThe,
    hasMoreModes,
    handleChange,
    translate,
}) => {
    const isActive = mode === 'on the';

    return (
        <div
            className={`form-group react-rule__flex ${!isActive ? 'opacity-50' : ''}`}>
            <div className="react-rule__col">
                {hasMoreModes && (
                    <input
                        type="radio"
                        aria-label="Repeat yearly on the"
                        name="repeat.yearly.mode"
                        checked={isActive}
                        value="on the"
                        onChange={handleChange}
                    />
                )}
            </div>
            <div className="react-rule__col fullWidth">
                <div className="react-rule__flex form-group fullWidth">
                    <div className="react-rule__col noWrap">
                        {translate('editor.recurrence.on_the')}
                    </div>

                    <div className="react-rule__col fullWidth">
                        <Select
                            name="repeat.yearly.onThe.which"
                            aria-label="Repeat yearly on the which"
                            value={onThe.which}
                            isDisabled={!isActive}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'first',
                                    label: translate('editor.recurrence.first'),
                                },
                                {
                                    value: 'second',
                                    label: translate(
                                        'editor.recurrence.second',
                                    ),
                                },
                                {
                                    value: 'third',
                                    label: translate('editor.recurrence.third'),
                                },
                                {
                                    value: 'fourth',
                                    label: translate(
                                        'editor.recurrence.fourth',
                                    ),
                                },
                                {
                                    value: 'last',
                                    label: translate('editor.recurrence.last'),
                                },
                            ]}
                        />
                    </div>

                    <div className="react-rule__col fullWidth">
                        <Select
                            name="repeat.yearly.onThe.day"
                            aria-label="Repeat yearly on the day"
                            value={onThe.day}
                            isDisabled={!isActive}
                            onChange={handleChange}
                            options={DAYS.map(day => ({
                                value: day,
                                label: translate(`editor.recurrence.${day}`),
                            }))}
                        />
                    </div>
                </div>

                <div className="react-rule__flex form-group fullWidth">
                    <div className="react-rule__col noWrap">
                        {translate('editor.recurrence.of')}
                    </div>

                    <div className="react-rule__col fullWidth">
                        <Select
                            name="repeat.yearly.onThe.month"
                            aria-label="Repeat yearly on the month"
                            value={onThe.month}
                            isDisabled={!isActive}
                            onChange={handleChange}
                            options={MONTHS.map(month => ({
                                value: month,
                                label: translate(`editor.recurrence.${month}`),
                            }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
RepeatYearlyOnThe.propTypes = {
    mode: PropTypes.oneOf(['on', 'on the']).isRequired,
    onThe: PropTypes.shape({
        which: PropTypes.oneOf(['first', 'second', 'third', 'fourth', 'last'])
            .isRequired,
        month: PropTypes.oneOf(MONTHS).isRequired,
        day: PropTypes.oneOf(DAYS).isRequired,
    }).isRequired,
    hasMoreModes: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(RepeatYearlyOnThe);
