import React from 'react';

export default function () {
    return (
        <g fill="currentColor">
            <path d="M15.121 10.5c.785 0 1.543.3 2.121.836.004-.027.008-.055.008-.086V2.5c0-.691-.559-1.25-1.25-1.25s-1.25.559-1.25 1.25v8zm0 0M10.625 10.5h.625V7.598c0-.692-.559-1.25-1.25-1.25s-1.25.558-1.25 1.25v3c0 .336.137.656.379.89a1.635 1.635 0 011.496-.988zm0 0" />
            <path d="M13.527 13.762a2.004 2.004 0 00-1.765 2.21l-.496.055a2.487 2.487 0 01.988-2.277h-1.629A1.624 1.624 0 019 12.125c0-.031.004-.063.004-.094a1.736 1.736 0 01-.754-1.433V8.383L7.629 4.44A1.257 1.257 0 007.3 3.75a1.235 1.235 0 00-1.34-.316 1.285 1.285 0 00-.797 1.418l.93 5.906c.133 2.101.578 5.656 2.406 6.61V22h6.902v-4.242c.157-.09.313-.188.457-.293a4.73 4.73 0 001.891-3.836A2.63 2.63 0 0015.121 11h-4.445a1.16 1.16 0 00-1.172 1.012 1.127 1.127 0 001.008 1.234c.039 0 .074.004.113.004h3.125v.5zm0 0" />
            <path d="M14.25 7.598c0-.692-.559-1.25-1.25-1.25s-1.25.558-1.25 1.25V10.5h2.5zm0 0" />
        </g>
    );
}
