import React from 'react';

export default function () {
    return (
        <g>
            <path
                d="M9.04797 4V15C9.04797 17.75 11.246 20 13.933 20H13.903H19.048"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0959 8.048L9.04795 4L4.99995 8.048"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    );
}
