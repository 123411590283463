import {combineReducers} from 'redux';
import createById from '../helpers/byId';
import paginate from '../helpers/paginate';
import {
    USERS_LIST_REQUEST,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAILURE,
    USERS_VIEW_REQUEST,
    USERS_VIEW_SUCCESS,
    USERS_VIEW_FAILURE,
    USERS_CREATE_REQUEST,
    USERS_CREATE_SUCCESS,
    USERS_CREATE_FAILURE,
    USERS_UPDATE_REQUEST,
    USERS_UPDATE_SUCCESS,
    USERS_UPDATE_FAILURE,
    USERS_DELETE_REQUEST,
    USERS_DELETE_SUCCESS,
    USERS_DELETE_FAILURE,
    USERS_UPDATE_AVATAR_REQUEST,
    USERS_UPDATE_AVATAR_SUCCESS,
    USERS_UPDATE_AVATAR_FAILURE,
} from '../../actions/users';
import {
    USERS_ME_REQUEST,
    USERS_ME_SUCCESS,
    USERS_ME_FAILURE,
    USERS_ME_UPDATE_REQUEST,
    USERS_ME_UPDATE_SUCCESS,
    USERS_ME_UPDATE_FAILURE,
} from '../../actions/users/me';
import {
    USERS_ME_AVATAR_REQUEST,
    USERS_ME_AVATAR_SUCCESS,
    USERS_ME_AVATAR_FAILURE,
} from '../../actions/users/me/avatar';
import me from './me';
import flatList from '../helpers/flatList';
import list from '../helpers/list';

const listActions = [
    USERS_LIST_REQUEST,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAILURE,
];
const deleteActions = [
    USERS_DELETE_REQUEST,
    USERS_DELETE_SUCCESS,
    USERS_DELETE_FAILURE,
];
const createActions = [
    USERS_CREATE_REQUEST,
    USERS_CREATE_SUCCESS,
    USERS_CREATE_FAILURE,
];
const updateActions = [
    USERS_UPDATE_REQUEST,
    USERS_UPDATE_SUCCESS,
    USERS_UPDATE_FAILURE,
];

const users = combineReducers({
    me,
    byId: createById({
        listActions,
        viewActions: [
            [USERS_VIEW_REQUEST, USERS_VIEW_SUCCESS, USERS_VIEW_FAILURE],
            [USERS_ME_REQUEST, USERS_ME_SUCCESS, USERS_ME_FAILURE],
            [
                USERS_ME_AVATAR_REQUEST,
                USERS_ME_AVATAR_SUCCESS,
                USERS_ME_AVATAR_FAILURE,
            ],
            [
                USERS_UPDATE_AVATAR_REQUEST,
                USERS_UPDATE_AVATAR_SUCCESS,
                USERS_UPDATE_AVATAR_FAILURE,
            ],
            [
                USERS_ME_UPDATE_REQUEST,
                USERS_ME_UPDATE_SUCCESS,
                USERS_ME_UPDATE_FAILURE,
            ],
        ],
        createActions,
        updateActions,
        deleteActions,
    }),
    paginated: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'view-users',
    }),
    paginatedViewUsers: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'view-users',
    }),
    paginatedAudienceUsers: paginate({
        listActions,
        deleteActions,
        requestFilterKey: 'filters',
        listType: 'audience-users',
    }),
    superUsers: flatList({
        listActions,
        listType: 'super-user',
    }),
    admins: list({
        listActions: listActions.map(v => `ADMIN_${v}`),
        listType: 'admin',
    }),
});

export default users;
