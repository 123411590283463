import React from 'react';
import {getTranslate, getActiveLanguage} from 'react-localize-redux';
import {connect, useDispatch} from 'react-redux';
import {ResponsiveContainer, LineChart, Line} from 'recharts';
import format from 'date-fns/format';
import * as colors from 'lib/styles/colors';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import {getDateLocale} from 'lib/helpers/getDateLocale';
import {LAST_30, getMonthData} from 'util/analytics';
import actions from 'store/actions';
import PropTypes from 'prop-types';
import Widget from './Widget';
import Count from '../Count';
import {
    showFailureByKey,
    showSuccessByKey,
} from '../../lib/store/actions/notifications';

const UsersActivity = ({
    translate,
    isLoading,
    userActivity,
    langCode,
    fetchUserActivityExport,
    isFetchingUserActivityExport,
    showNotification,
    ...otherProps
}) => {
    const color = colors.chart.green.secondary;

    // Map activity data
    const data = userActivity.map(activity => activity.total_active).reverse();
    const offset = Math.max(...data) / 10;

    const monthActivity = getMonthData(userActivity, LAST_30);

    const newData = data.map(item => ({value: item + offset}));
    const dispatch = useDispatch();

    return (
        <Widget
            {...otherProps}
            title={translate('dashboard.users_activity')}
            className="l-users-activity"
            onArrowClick={async () => {
                try {
                    await fetchUserActivityExport();
                    dispatch(showSuccessByKey('global.userExportIsStarted'));
                } catch (e) {
                    dispatch(showFailureByKey('global.general_error'));
                }
            }}
            fetchingExport={isFetchingUserActivityExport}>
            <LoadingWrapper loading={isLoading}>
                {monthActivity ? (
                    <Count
                        color={color}
                        number={
                            monthActivity &&
                            Math.round(monthActivity.total_active)
                        }
                        text={translate('dashboard.active_users_last_30_days', {
                            month: format(
                                new Date(),
                                'MMMM',
                                getDateLocale(langCode),
                            ),
                        })}
                    />
                ) : (
                    <p>{translate('global.no_data_available')}</p>
                )}

                <ResponsiveContainer width="100%" height={155}>
                    <LineChart
                        data={newData}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 10,
                            bottom: 0,
                        }}>
                        <Line
                            dataKey="value"
                            stroke={color}
                            strokeWidth={3}
                            dot={{strokeWidth: 2, r: 7, fill: '#EBEEF3'}}
                            animationDuration={600}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </LoadingWrapper>
        </Widget>
    );
};

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    isLoading: state.analytics.userActivity.isLoading,
    userActivity: state.analytics.userActivity.activity,
    langCode: getActiveLanguage(state.localize).code,
    isFetchingUserActivityExport:
        state.analytics.userActivity.isFetchingUserActivityExport
});

const mapDispatchToProps = dispatch => ({
    fetchUserActivityExport: () =>
        dispatch(actions.analytics.userActivity.fetchUserActivityExport()),
    showNotification: notification =>
        dispatch(actions.notifications.addNotification(notification)),
});

UsersActivity.propTypes = {
    translate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userActivity: PropTypes.arrayOf(PropTypes.any).isRequired,
    langCode: PropTypes.string.isRequired,
    fetchUserActivityExport: PropTypes.func.isRequired,
    isFetchingUserActivityExport: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersActivity);
