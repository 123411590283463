import React from 'react';

const YesNo = () => (
    <g transform="translate(0.000000, 1.000000)" fill="currentColor">
        <path
            d="M0.138461538,5.15307692 C0.0461538462,5.05938461 0,4.91884615 0,4.82515385 C0,4.73146154 0.0461538462,4.59092308 0.138461538,4.49723077 L0.784615385,3.84138461 C0.969230772,3.654 1.24615385,3.654 1.43076923,3.84138461 L1.47692307,3.88823077 L4.01538462,6.65215385 C4.10769231,6.74584615 4.24615385,6.74584615 4.33846154,6.65215385 L10.5230769,0.140538461 L10.5692307,0.140538461 C10.7538462,-0.0468461539 11.0307693,-0.0468461539 11.2153846,0.140538461 L11.8615385,0.796384615 C12.0461538,0.983769234 12.0461538,1.26484615 11.8615385,1.45223077 L4.47692307,9.22869234 C4.38461538,9.3223846 4.29230769,9.36923081 4.15384615,9.36923081 C4.01538462,9.36923081 3.92307693,9.3223846 3.83076923,9.22869234 L0.230769231,5.29361539 L0.138461538,5.15307692 Z"
            id="Shape"
        />
        <g transform="translate(15.063988, 13.300377) rotate(45.000000) translate(-15.063988, -13.300377) translate(9.063988, 7.300377)">
            <polygon
                transform="translate(0.990518, -0.006538)"
                points="10.7586207 4.51043478 6.31484258 4.51043478 6.31484258 0 4.44377811 0 4.44377811 4.51043478 0 4.51043478 0 6.40956522 4.44377811 6.40956522 4.44377811 10.92 6.31484258 10.92 6.31484258 6.40956522 10.7586207 6.40956522"
            />
        </g>
    </g>
);

export default YesNo;
