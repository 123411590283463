import React from 'react';
import {Translate} from 'react-localize-redux';

import * as theme from 'styles/theme';
import OnBoardingWithState from 'components/Widgets/OnBoarding/OnBoardingWithState';
import AdminActivity from 'components/Widgets/AdminActivity';
import AdminUsage from 'components/Widgets/AdminUsage';
import UsersActivity from 'components/Widgets/UsersActivity';
import ContentPopularity from 'components/Widgets/ContentPopularity';
import LastHappinessPoll from 'components/Widgets/LastHappinessPoll/LastHappinessPollWithRedux';
import SurveyListWithState from 'components/SurveyList/SurveyListWithState';

import AdminActivityModal from 'modals/Analytics/AdminActivityModal/AdminActivityModal';
import ContentPopularityModal from 'modals/Analytics/ContentPopularityModal/ContentPopularityModal';
import UsersActivityModal from 'modals/Analytics/UsersActivityModal/UsersActivityModal';
import AdminUsageModal from 'modals/Analytics/AdminUsageModal/AdminUsageModal';
import ContentView from 'components/util/ContentView';
import MessagesReadByWeekDay from 'components/Widgets/MessagesReadByWeekDay';
import AdminMessageReadsByWeekdayAndHourModal from 'modals/Analytics/AdminMessageReadsByWeekdayAndHourModal/AdminMessageReadsByWeekdayAndHourModalModal';

const Dashboard = ({openModal}) => (
    <ContentView
        title={<Translate id="global.dashboard" />}
        hasOverflow
        wrapHasOverflow>
        <div className="dashboard">
            <div className="content">
                <div className="row">
                    <div className="col col-12 col-lg-6 widget">
                        <OnBoardingWithState />
                    </div>
                    <div className="col col-12 col-lg-6 widget">
                        <AdminActivity
                            onClick={() => openModal('adminActivity')}
                        />
                    </div>
                    <div className="col col-12 col-lg-6 widget">
                        <AdminUsage onClick={() => openModal('adminUsage')} />
                    </div>
                    <div className="col col-12 col-lg-6 widget">
                        <ContentPopularity
                            onClick={() => openModal('contentPopularity')}
                        />
                    </div>
                    <div className="col col-12 col-lg-6 widget">
                        <UsersActivity
                            onClick={() => openModal('usersActivity')}
                        />
                    </div>
                    <div className="col col-12 col-lg-6 widget">
                        <MessagesReadByWeekDay
                            onClick={() =>
                                openModal('adminMessagesReadByWeekdayAndHour')
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="sidebar">
                <div className="mood">
                    <div className="row">
                        <div className="col">
                            <LastHappinessPoll />
                        </div>
                    </div>
                </div>
                <div className="list">
                    <SurveyListWithState />
                </div>
            </div>
        </div>
        <AdminActivityModal />
        <AdminUsageModal />
        <UsersActivityModal />
        <ContentPopularityModal />
        <AdminMessageReadsByWeekdayAndHourModal />

        <style jsx>
            {`
                .dashboard {
                    display: flex;
                    flex: 1;
                    height: 100%;
                    width: 100%;
                    flex-direction: row;

                    .widget {
                        @media (min-width: 1800px) {
                            max-width: 33.33%;
                            flex-basis: 33.33%;
                        }
                    }

                    :global(.row) {
                        margin-left: -${theme.dashboardGutter / 2}rem;
                        margin-right: -${theme.dashboardGutter / 2}rem;
                    }

                    :global(.col) {
                        padding-left: ${theme.dashboardGutter / 2}rem;
                        padding-right: ${theme.dashboardGutter / 2}rem;
                        margin-bottom: ${theme.dashboardGutter}rem;
                    }

                    @media (max-width: 1140px) {
                        max-width: 96rem;
                    }
                }

                .content {
                    flex: 1;
                    width: 66.66%;
                    padding-right: ${theme.dashboardGutter / 2}rem;
                    overflow: auto;
                }

                .sidebar {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;
                    padding-left: ${theme.dashboardGutter / 2}rem;

                    @media (min-width: 1800px) {
                        width: 25%;
                    }
                }

                .list {
                    flex: 1;
                    overflow: auto;
                    margin-right: -${theme.dashboardGutter}rem;
                    padding-right: ${theme.dashboardGutter}rem;

                    :global(.widget) {
                        height: auto;
                    }
                }
            `}
        </style>
    </ContentView>
);

export default Dashboard;
