import React from 'react';
import {Translate} from 'react-localize-redux';
import Modal from 'modals/Modal/ModalWithState';
import ContentPopularityWithRedux from './ContentPopularityWithRedux';
import MostReadWithRedux from './MostReadWithRedux';
import MostCheeredWithRedux from './MostCheeredWithRedux';

const ContentPopularityModal = () => (
    <Modal
        name="contentPopularity"
        noPadding
        medium
        menu={[
            {label: <Translate id="dashboard.articles_read" />},
            {label: <Translate id="dashboard.most_read" />},
            {label: <Translate id="dashboard.most_cheered" />},
        ]}>
        {({activeTab}) => (
            <div className="content">
                {(() => {
                    switch (activeTab) {
                        case 0:
                            return <ContentPopularityWithRedux />;
                        case 1:
                            return <MostReadWithRedux />;
                        case 2:
                            return <MostCheeredWithRedux />;
                        default:
                            return null;
                    }
                })()}
                <style jsx>
                    {`
                        .content :global(.list) {
                            height: 50rem;
                        }
                    `}
                </style>
            </div>
        )}
    </Modal>
);

export default ContentPopularityModal;
