/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import Datetime from 'react-datetime';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import {MODAL_RSVP} from 'lib/store/actions/modals';
import Modal from 'modals/Modal/ModalWithState';
import {DATE_WITHOUT_TIMEZONE} from 'util/constants';
import useSurveyEditor from 'components/hooks/useSurveyEditor';

const RSVPModal = () => {
    const {
        survey,
        onSave,
        baseForm,
        isSaving,
        language,
        translate,
        headerTitleKey,
        buttonTitleKey,
        onContentChange,
    } = useSurveyEditor();
    const {title, location, dateTime} = survey.content;
    const [, descriptionInput] = baseForm;

    return (
        <Modal
            name={MODAL_RSVP}
            icon="event"
            title={`${translate(headerTitleKey, {name: translate('editor.survey.rsvp')})} (${language})`}
            cancelable
            buttonActions={[
                {
                    label: translate(buttonTitleKey),
                    loading: isSaving,
                    callBack: onSave,
                    classes: 'success',
                },
            ]}>
            <div className="form">
                <div className="form__group">
                    <label className="label" htmlFor="rsvp_title">
                        {translate('editor.form.rsvp.title.label')}
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="rsvp_title"
                        placeholder={translate(
                            'editor.form.rsvp.title.placeholder',
                        )}
                        value={(title && title[language]) || ''}
                        onChange={e => {
                            const updatedTitle = {...title};
                            updatedTitle[language] = e.target.value;
                            onContentChange({title: updatedTitle});
                        }}
                    />
                </div>
                {descriptionInput}
                <div className="form__group">
                    <label className="label" htmlFor="rsvp_location">
                        {translate('editor.form.rsvp.location.label')}
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="rsvp_location"
                        placeholder={translate(
                            'editor.form.rsvp.location.placeholder',
                        )}
                        value={
                            location && location[language]
                                ? location[language]
                                : ''
                        }
                        onChange={e => {
                            const updatedLocation = {...location};
                            updatedLocation[language] = e.target.value;
                            onContentChange({location: updatedLocation});
                        }}
                    />
                </div>
                <div className="form__group">
                    <label className="label" htmlFor="rsvp_location">
                        {translate('editor.form.rsvp.date_time.label')}
                    </label>
                    <Datetime
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm"
                        inputProps={{
                            placeholder: translate(
                                'editor.form.rsvp.date_time.placeholder',
                            ),
                        }}
                        initialValue={dateTime ? parse(dateTime) : null}
                        onChange={value => {
                            onContentChange({
                                dateTime: format(value, DATE_WITHOUT_TIMEZONE),
                            });
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default RSVPModal;
