import React from 'react';
import styled from 'styled-components';
import RSVPModal from 'modals/RSVPModal';
import RatingPollModal from 'modals/RatingPollModal';
import EmbedModal from 'modals/EmbedModal/EmbedModal';
import OpinionScaleModal from 'modals/OpinionScaleModal';
import HappinessPollModal from 'modals/HappinessPollModal';
import YesNoQuestionModal from 'modals/YesNoQuestionModal';
import MultipleChoiceModal from 'modals/MultipleChoiceModal';
import AudienceModal from 'modals/AudienceModal/AudienceModalWithData';
import TabMenu from '../../TabMenu/TabMenuWithRedux';
import MediaSidebar from '../../EditorSidebar/MediaSidebar';
import SurveySidebar from '../../EditorSidebar/SurveySidebar';
import EditorConfigurationSidebar from './EditorConfigurationSidebar';

const EditorSidebar = ({
    canEdit,
    messageId,
    sidebarType,
    sidebarData,
    contentType,
    containerRef,
}) => {
    let content;
    let menu;
    switch (sidebarType) {
        case 'media':
            menu = sidebarData && sidebarData.subType === 'image' && (
                <TabMenu menuKey="media" />
            );
            content = <MediaSidebar />;
            break;
        case 'survey':
            menu = <TabMenu menuKey="survey" />;
            content = <SurveySidebar />;
            break;
        default:
            content = (
                <EditorConfigurationSidebar
                    canEdit={canEdit}
                    messageId={messageId}
                    contentType={contentType}
                />
            );
            break;
    }

    return (
        <>
            <Container ref={containerRef}>
                <div className="editor-sidebar__menu">{menu}</div>
                {content}
            </Container>

            <HappinessPollModal />
            <MultipleChoiceModal />
            <YesNoQuestionModal />
            <OpinionScaleModal />
            <RatingPollModal />
            <RSVPModal />
            <EmbedModal />
            <AudienceModal />
        </>
    );
};

const Container = styled.div(
    ({theme}) => `
    display: block;
    height: 100vh;
    width: 36rem;
    position: fixed;
    background: ${theme.colorWhite};
    padding: 2rem;
    padding-bottom: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .media-sidebar {
        flex: 1;
    }

    .search-input {
        margin-bottom: 3rem;
    }

    .l-sidebar-form__actions {
        padding: 3rem 0;
    }

    .label {
        text-transform: none;
        font-weight: 500;
    }
`,
);

export default EditorSidebar;
