import React from 'react';

const MultipleChoice = () => (
    <g fill="currentColor" strokeWidth="1" fillRule="evenodd">
        <path
            transform="translate(4.000000, 7.000000)"
            d="M0.1875,6.28571429 C0,6.0952381 0,6.0952381 0,5.9047619 C0,5.71428571 0,5.52380952 0.1875,5.52380952 L0.9375,4.76190476 C1.125,4.57142857 1.5,4.57142857 1.6875,4.76190476 L4.875,8.19047619 C5.0625,8.38095238 5.25,8.38095238 5.25,8.19047619 L13.125,0.19047619 C13.5,1.38777878e-17 13.875,1.38777878e-17 14.0625,0.19047619 L14.8125,0.952380952 C15,1.14285714 15,1.52380952 14.8125,1.71428571 L5.625,11.2380952 C5.4375,11.4285714 5.4375,11.4285714 5.25,11.4285714 C5.0625,11.4285714 4.875,11.4285714 4.875,11.2380952 L0.1875,6.28571429 Z"
        />
        <path
            fillRule="nonzero"
            d="M15.6923077,24 L8.30769231,24 C3.69230769,24 0,20.3076923 0,15.6923077 L0,8.30769231 C0,3.69230769 3.69230769,0 8.30769231,0 L15.6923077,0 C20.3076923,0 24,3.69230769 24,8.30769231 L24,15.6923077 C24,20.3076923 20.3076923,24 15.6923077,24 Z M8.30769231,1.84615385 C4.8,1.84615385 1.84615385,4.8 1.84615385,8.30769231 L1.84615385,15.6923077 C1.84615385,19.2 4.8,22.1538462 8.30769231,22.1538462 L15.6923077,22.1538462 C19.2,22.1538462 22.1538462,19.2 22.1538462,15.6923077 L22.1538462,8.30769231 C22.1538462,4.8 19.2,1.84615385 15.6923077,1.84615385 L8.30769231,1.84615385 Z"
        />
    </g>
);

export default MultipleChoice;
