import React from 'react';
import SidebarForm from 'components/layout/SidebarForm';
import Audience from 'components/EditorSidebar/Audience';
import EditorSidebarCommentToggleWithState from './EditorSidebarCommentToggleWithState';
import EditorSidebarLanguageSelectWithState from './EditorSidebarLanguageSelectWithState';
import EditorSidebarAppDomainSelectWithState from './EditorSidebarAppDomainSelectWithState';
import EditorSidebarAcceptAnswersToggleWithState from './EditorSidebarAcceptAnswersToggleWithState';
import TemplateConfigurationSidebarButtonsWithState from './TemplateConfigurationSidebarButtonsWithState';

const TemplateConfigurationSidebar = ({canEdit, messageId}) => (
    <SidebarForm>
        <SidebarForm.Content>
            <EditorSidebarLanguageSelectWithState />
            <EditorSidebarCommentToggleWithState canEdit={canEdit} />
            <EditorSidebarAcceptAnswersToggleWithState canEdit={canEdit} />
            <Audience canEdit={canEdit} messageId={messageId} />
            <EditorSidebarAppDomainSelectWithState canEdit={canEdit} />
        </SidebarForm.Content>
        <SidebarForm.Actions>
            <TemplateConfigurationSidebarButtonsWithState
                canEdit={canEdit}
                messageId={messageId}
            />
        </SidebarForm.Actions>
    </SidebarForm>
);

export default TemplateConfigurationSidebar;
