import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {cloneDeep, set} from 'lodash';
import styled from 'styled-components';
import computeRRuleToString from 'util/computeRRule/toString/computeRRule';
import computeRRuleFromString from 'util/computeRRule/fromString/computeRRule';
import configureInitialState from 'util/configureInitialState';
import End from './End';
import Repeat from './Repeat';

const RRuleGenerator = ({
    config,
    defaultValue,
    onChange,
    calendarComponent,
}) => {
    const initialState = useMemo(
        () => configureInitialState(config, calendarComponent),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const [rrule, setRRule] = useState(defaultValue || initialState.rrule);
    const [data, setData] = useState(
        configureInitialState(config, calendarComponent).data,
    );

    useEffect(() => {
        if (rrule) {
            setData(computeRRuleFromString(data, rrule));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rrule]);

    const handleChange = params => {
        const newData = cloneDeep(data);
        let name;
        let value;
        if (typeof params[0].target !== 'undefined') {
            ({name, value} = params[0].target);
        } else {
            [{value}, {name}] = params;
        }
        set(newData, name, value);
        const newRRule = computeRRuleToString(newData);
        setRRule(newRRule);
        onChange(newRRule);
    };

    const {repeat, end, options = {}} = data;
    if (!repeat || !end) return null;
    return (
        <Container>
            <div>
                <Repeat
                    repeat={repeat}
                    handleChange={(...params) => {
                        handleChange(params);
                    }}
                />

                {!options.hideEnd && (
                    <End
                        end={end}
                        handleChange={(...params) => {
                            handleChange(params);
                        }}
                    />
                )}
            </div>
        </Container>
    );
};

const Container = styled.div(
    ({theme}) => `
        .rdt .form-control {
                background-color: white;
        }

        .react-rule__block {
                border-bottom: solid .1rem ${theme.colorGrey300};
                padding-bottom: ${theme.spacing}rem;
                margin-bottom: ${theme.spacingLarge}rem;
        }

        .react-rule__flex {
                display: flex;
                align-items: center;
        }

        .react-rule__col {
                margin-right: 2rem;

                &:last-child {
                        margin-right: 0;
                }
        }

        .form-group {
                &:last-child {
                        margin-bottom: 0;
                }
        }

        .btn-group {
                width: 100%;

                > .btn {
                        flex-grow: 1;
                }

                input[type="checkbox"] {
                        width: 1.5rem;
                        margin: 0 auto;
                }
        }
`,
);

RRuleGenerator.propTypes = {
    config: PropTypes.shape({
        frequency: PropTypes.arrayOf(
            PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly']),
        ),
        yearly: PropTypes.oneOf(['on', 'on the']),
        monthly: PropTypes.oneOf(['on', 'on the']),
        end: PropTypes.arrayOf(PropTypes.oneOf(['Never', 'After', 'On date'])),
        hideEnd: PropTypes.bool,
        weekStartsOnSunday: PropTypes.bool,
    }),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    calendarComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

RRuleGenerator.defaultProps = {
    defaultValue: '',
    config: {},
    calendarComponent: null,
};

export default RRuleGenerator;
