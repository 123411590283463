import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-localize-redux';
import PercentageChart from 'components/util/PercentageChart';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

// Note: unboarded means 'unOnboarded', not a typo, just the wrong word in this context https://en.wiktionary.org/wiki/unboard

const AudienceResult = ({audience, isCalculatingAudience}) => (
    <Result>
        <Chart>
            <PercentageChart
                loading={isCalculatingAudience}
                total={audience && audience.segment_users_total}
                percentage={audience && audience.segment_percent}
            />
        </Chart>
        {!isCalculatingAudience && <Translate id="segmentation.users" />}
        {audience && audience.unboarded_users_total > 0 && (
            <Notice>
                <Translate
                    id="segmentation.unboardedUsersWarning"
                    data={{total: audience.unboarded_users_total}}
                />
                <p style={{marginTop: '1rem'}}>
                    <Link to="/users?onboarded=0">
                        {' '}
                        <Translate id="segmentation.unboardedUsersCTA" />{' '}
                    </Link>{' '}
                </p>
            </Notice>
        )}
    </Result>
);

const Result = styled.div(
    ({theme}) => `
    text-align: center;
    margin-bottom: ${theme.spacingLarger}rem;
    font-size: ${theme.fontSizeSmall}rem;
    color: ${theme.colorTextLight};
`,
);

const Chart = styled.div`
    width: 180px; // Should be pixels because PercentageChart works with pixels
    height: 150px;
    margin: auto;
`;

export const Notice = styled.div`
    margin-top: 1rem;
    font-size: 1.2rem;
`;

AudienceResult.propTypes = {
    audience: PropTypes.shape({
        segment_users_total: PropTypes.number,
        segment_percent: PropTypes.number,
        unboarded_users_total: PropTypes.number,
    }),
    isCalculatingAudience: PropTypes.bool,
};

AudienceResult.defaultProps = {
    audience: {},
    isCalculatingAudience: false,
};
export default AudienceResult;
